import styles from './BasicDetails1.module.css'

const BasicDetails1 = (props: any) => {
  const { pricingPlanData } = props
  return (
    <>
      <div className={styles.row}>
        <div>DEAL NAME</div>
        <div>{pricingPlanData.engagementName}</div>
      </div>
      <div className={styles.row}>
        <div>CLIENT NAME</div>
        <div>{pricingPlanData.client}</div>
      </div>
    </>
  )
}

export default BasicDetails1
