import './EYView.css'
import EYViewBox from './EYViewBox'

interface EYViewProps {
  selectedColumn: number
  setSelectedColumn: Function
  formValues: any
  expensesValues: any
  resourcesValues: any
  eyViewValues: any
  setEyViewValues: Function
  inputResources: any
  inputExpenses: any
  planCurrency: string
  planUnit: string
}

const EYView = (props: EYViewProps) => {
  const {
    selectedColumn,
    setSelectedColumn,
    formValues,
    expensesValues,
    resourcesValues,
    eyViewValues,
    setEyViewValues,
    inputResources,
    inputExpenses,
    planCurrency,
    planUnit
  } = props

  return (
    <div className='eyview-container'>
      <div className='eyview-categories'>
        <p className='body-text'>Total Billing Amount</p>
        <p className='body-text'>Total Expenses</p>
        <p className='body-text'>Billings Excluding Expenses</p>
        <p className='body-text'>Total Labor & CT Cost</p>
        <p className='body-text'>Cost of Subcontractors</p>
        <p className='body-text'>EY Margin %</p>
        <p className='body-text'>EY Margin % using DCR</p>
        <p className='body-text'>
          EY Margin % <br></br>Excluding Partner Costs
        </p>
        <p className='body-text'>
          EY Margin % using DCR, <br></br>Excluding Partner Costs
        </p>
        <p className='body-text'>Rate / Day</p>
        <p className='body-text'>Rate / Hour</p>
      </div>
      <div className='eyview-boxes-container'>
        {/* <EYViewBox
          title='Base'
          selectedColumn={selectedColumn}
          setSelectedColumn={setSelectedColumn}
          columnNum={0}
          formValues={formValues}
          expensesValues={expensesValues}
          resourcesValues={resourcesValues}
          eyViewValues={eyViewValues}
          setEyViewValues={setEyViewValues}
          inputResources={inputResources}
          inputExpenses={inputExpenses}
          planCurrency={planCurrency}
          planUnit={planUnit}
        /> */}
        <EYViewBox
          title='Market Rate Card'
          selectedColumn={selectedColumn}
          setSelectedColumn={setSelectedColumn}
          columnNum={0}
          formValues={formValues}
          expensesValues={expensesValues}
          resourcesValues={resourcesValues}
          eyViewValues={eyViewValues}
          setEyViewValues={setEyViewValues}
          inputResources={inputResources}
          inputExpenses={inputExpenses}
          planCurrency={planCurrency}
          planUnit={planUnit}
        />
        {/* <EYViewBox
          title='Target Margin %'
          selectedColumn={selectedColumn}
          setSelectedColumn={setSelectedColumn}
          columnNum={0}
          formValues={formValues}
          expensesValues={expensesValues}
          resourcesValues={resourcesValues}
          eyViewValues={eyViewValues}
          setEyViewValues={setEyViewValues}
          inputResources={inputResources}
          inputExpenses={inputExpenses}
          planCurrency={planCurrency}
          planUnit={planUnit}
        /> */}
        <EYViewBox
          title='Target Margin %'
          selectedColumn={selectedColumn}
          setSelectedColumn={setSelectedColumn}
          columnNum={1}
          formValues={formValues}
          expensesValues={expensesValues}
          resourcesValues={resourcesValues}
          eyViewValues={eyViewValues}
          setEyViewValues={setEyViewValues}
          inputResources={inputResources}
          inputExpenses={inputExpenses}
          planCurrency={planCurrency}
          planUnit={planUnit}
        />
        <EYViewBox
          title='Fixed Fee/Price'
          selectedColumn={selectedColumn}
          setSelectedColumn={setSelectedColumn}
          columnNum={2}
          formValues={formValues}
          expensesValues={expensesValues}
          resourcesValues={resourcesValues}
          eyViewValues={eyViewValues}
          setEyViewValues={setEyViewValues}
          inputResources={inputResources}
          inputExpenses={inputExpenses}
          planCurrency={planCurrency}
          planUnit={planUnit}
        />
        <EYViewBox
          title='Client Rate Card'
          selectedColumn={selectedColumn}
          setSelectedColumn={setSelectedColumn}
          columnNum={3}
          formValues={formValues}
          expensesValues={expensesValues}
          resourcesValues={resourcesValues}
          eyViewValues={eyViewValues}
          setEyViewValues={setEyViewValues}
          inputResources={inputResources}
          inputExpenses={inputExpenses}
          planCurrency={planCurrency}
          planUnit={planUnit}
        />

        {/* <EYViewBox
          title='Market Rate Card'
          selectedColumn={selectedColumn}
          setSelectedColumn={setSelectedColumn}
          columnNum={3}
          formValues={formValues}
          expensesValues={expensesValues}
          resourcesValues={resourcesValues}
          eyViewValues={eyViewValues}
          setEyViewValues={setEyViewValues}
          inputResources={inputResources}
          inputExpenses={inputExpenses}
          planCurrency={planCurrency}
          planUnit={planUnit}
        /> */}
      </div>
    </div>
  )
}

export default EYView
