import React, { ReactElement, useRef, useState } from 'react';
import './PopupModal.css';
  
  interface PopupModalProps {
    show:boolean;
    duplicateCount: number;
    setDuplicateCount: (count: number) => void;
    duplicateResource: () => void;
    close:()=>void
  }
  
  const PopupModal: React.FC<PopupModalProps> = ({
    show,
    duplicateCount,setDuplicateCount, duplicateResource,close
  }) => {

    const [errorMessage, setErrorMessage] = useState<string>('');
    const inputRef = useRef<HTMLInputElement>(null);
    if (!show) {
      return null;
    }
 
  
    const increment = () => {
      if (duplicateCount < 50) {
        setDuplicateCount(duplicateCount + 1);
        setErrorMessage('');
      } else {
        setErrorMessage('Please enter a value between 1 and 50');
      }
    };
  
    const decrement = () => {
      if (duplicateCount > 1) {
        setDuplicateCount(duplicateCount - 1);
        setErrorMessage('');
      } else {
        setErrorMessage('Please enter a value between 1 and 50');
      }
    };
  
    const Close = () => {
        const deleteModal = document.getElementById('modal') as HTMLElement;
        deleteModal.style.display = 'none';
        setDuplicateCount(1); 
        setErrorMessage('');
        close();
      };
      
    const disableIncrementButton = duplicateCount >= 50 || !!errorMessage;
    const disableDecrementButton = duplicateCount <= 1 || !!errorMessage;
  
    const duplicate = () => {
      if (errorMessage) {
        return; 
      }
    
      if (duplicateCount <= 50) {
        for (let i = 0; i < duplicateCount; i++) {
          duplicateResource();
        }
      } else {
        setErrorMessage('Please enter a value between 1 and 50');
      }
      if (inputRef.current) {
          inputRef.current.value = '';
        }
      Close();
    };
    
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
      
        const numberPattern = /^[0-9]*$/;
      
        if (!numberPattern.test(value)) {
          setErrorMessage('Please enter a valid number');
        } else {
          const numericValue = parseInt(value, 10); 
          if (isNaN(numericValue) || numericValue < 1 || numericValue > 50) {
            setErrorMessage('Please enter a value between 1 and 50');
          } else {
            setDuplicateCount(numericValue);
            setErrorMessage('');
          }
        }
      };
      
    
    return (
      <div id="modal">
        <div className="popup">
          <div className="popup-content">
            <p className="heading-three">
              Please input the number of times you'd like to duplicate this resource. Enter a value between 1 and 50.
            </p>
            <div className="input-container">
            <input
              ref={inputRef}
              className="box"
              type="text"
              pattern="^[0-9]*$"
              onChange={handleInputChange}
              value={errorMessage ? inputRef.current?.value || '' : duplicateCount}
              />
             <div className="button-container">
              <button type="button" className="vertical-button" onClick={increment} disabled={disableIncrementButton}>
                +
              </button>
              <button type="button" className="vertical-button" onClick={decrement} disabled={disableDecrementButton}>
                -
              </button>
            </div>
            </div>
            {errorMessage && <div className="error-message">{errorMessage}</div>}
            <div className="popup-buttons">
              <button type="button" className="secondary-button heading-three" onClick={Close}>
                Cancel
              </button>
              <button className="primary-button heading-three" onClick={duplicate} disabled={!!errorMessage || duplicateCount > 50}>
                Duplicate
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  export default PopupModal;