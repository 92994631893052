import React from 'react'
import { useFormikContext } from 'formik'
import './Toggle.css'

type Props = {}

const InputUnitToggle = (props: Props) => {
  const { values, setFieldValue }: any = useFormikContext()
  const { inputUnit } = values
  return (
    <div>
      <div
        className='toggle-container'
        onClick={() => setFieldValue('inputUnit', inputUnit === 'Hours' ? 'Days' : 'Hours')}
      >
        <div className='toggle-item muted'>Hours</div>
        <div className='toggle-item muted'>Days</div>
        <div className={`dialog-button ${inputUnit === 'Hours' ? 'disabled' : ''}`}>
          <div className='toggle-item'>{inputUnit === 'Hours' ? 'Hours' : 'Days'}</div>
        </div>
      </div>
    </div>
  )
}

export default InputUnitToggle
