import React, { useState, useEffect, ReactElement, useContext } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { logoutUser } from '../src/components/utils/fetchHandler'
import { useMsal } from '@azure/msal-react'
export default function ReactTimer(): ReactElement {
  const timeout = 3600000
  const [remaining, setRemaining] = useState(timeout)
  const { instance } = useMsal()
  const userIdVal = localStorage.getItem('CurrentUserID')
  const HandleOnIdle = async () => {
    let userId = userIdVal != undefined && userIdVal != '' ? userIdVal.toString() : ''
    if (userId !== undefined && userId !== '') {
      await logoutUser(userId)
      sessionStorage.clear()
      localStorage.clear()
      instance.logoutRedirect().catch((e: any) => {
        console.error(e)
      })
    }
  }
  const handleOnAction = () => {
    setRemaining(timeout)
    console.log('user did something')
  }
  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout,
    onIdle: HandleOnIdle,
    onAction: handleOnAction
  })
  useEffect(() => {
    setRemaining(getRemainingTime())
    setInterval(() => {
      setRemaining(getRemainingTime())
    }, 3600000)
  }, [])
  return <div></div>
}
