import { Field, getIn } from 'formik'
import Select, { components } from 'react-select'
import './Select.css'

const customStyles = (isError: boolean) => {
  return {
    option: (provided: any, state: any) => ({
      ...provided,
      color: '#000',
      ...(state.isSelected && { backgroundColor: '#d0d0e2' }),
      cursor: 'pointer',
      fontSize: '14px',
      fontFamily: 'EYInterstate'
    }),
    menu: (provided: any, state: any) => ({
      ...provided,
      zIndex: 1070,
      margin: '0px',
      borderRadius: 0,
      backgroundColor: '#eaeaf2'
    }),
    indicatorSeparator: () => ({ display: 'none' }),
    control: (provided: any, state: any) => {
      const borderStyle = isError
        ? { border: '1px solid #b9251c', boxShadow: 'inset 0 0 1px #b9251c' }
        : state.isFocused
        ? { border: '1px solid #082c65', boxShadow: 'inset 0 0 1px #082c65' }
        : {
            border: '1px solid transparent',
            boxShadow: 'inset 0 0 1px transparent'
          }

      const backgroundColor = state.isDisabled ? 'rgba(234, 234, 242, 0.7)' : '#eaeaf2'
      return {
        ...provided,
        outline: 'none',
        backgroundColor,
        borderRadius: '2px',
        ...borderStyle
      }
    },
    singleValue: (provided: any, state: any) => {
      return {
        ...provided,
        fontSize: '14px',
        fontFamily: 'EYInterstate',
        opacity: state.isDisabled ? 0.5 : 1,
        transition: 'opacity 300ms'
      }
    },
    placeholder: (provided: any) => {
      return {
        ...provided,
        fontSize: '14px',
        fontFamily: 'EYInterstate'
      }
    }
  }
}

interface Option {
  value: string | number | boolean
  label: string
}

interface CustomSelectProps {
  options: Option[]
  name: string
  isDisabled?: boolean
  placeholder?: string
  fromExpenses?: boolean
  onChange?: (option: Option) => void
}

const CustomSelect = (props: CustomSelectProps) => {
  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <span className={props.selectProps.menuIsOpen ? 'icon-up' : 'icon-down'}></span>
      </components.DropdownIndicator>
    )
  }

  const getValue = (options: any, defaultValue: any) => {
    return options ? options.find((option: any) => option.value === defaultValue) : ''
  }

  const selectionChange = (form: any, field: any, option: any) => {
    form.setFieldValue(props.name, option?.value)

    const fieldArray = field.name.split('.')

    if (field.name.includes('resource')) {
      const optionArray = option.value.split('||')
      if (optionArray[1] === '') {
        form.setFieldValue(fieldArray[0] + '.workstream', 'unspecified')
      } else {
        form.setFieldValue(fieldArray[0] + '.workstream', optionArray[1])
      }
    }

    if (option.value === 'otherLumpsum') {
      form.setFieldValue(fieldArray[0] + '.resource', 'n/a')
    }
  }

  return (
    <Field name={props.name}>
      {({ form, field }: any) => {
        return (
          <Select
            id={props.name}
            value={getValue(props.options, field.value) || ''}
            onChange={(option) => {
              if (props.fromExpenses === true) selectionChange(form, field, option)
              else {
                form.setFieldValue(props.name, option?.value)
                props.onChange && props.onChange(option)
              }
            }}
            onBlur={() => {
              form.setFieldTouched(props.name)
            }}
            options={props.options}
            components={{ DropdownIndicator }}
            isSearchable={true}
            isDisabled={props.isDisabled ? true : false}
            styles={customStyles(getIn(form.errors, props.name) && getIn(form.touched, props.name) ? true : false)}
            placeholder={props.placeholder}
          />
        )
      }}
    </Field>
  )
}

export default CustomSelect
