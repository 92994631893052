import loaderGif from '../../../assets/LoaderGif.gif'
import './LoadingChanges.css'

const LoadingChanges = () => {
  return (
    <div className='loading-changes-container'>
      <div className='loading-changes-inner'>
        <p className='body-text'>Updating changes, please wait.</p>
        <img src={loaderGif} alt='Page Loading...' />
      </div>
    </div>
  )
}

export default LoadingChanges
