import { useEffect, useRef, useState } from 'react'
import moment from 'moment-timezone'
import './Footer.css'
import { Formik } from 'formik'

const Footer = (props: any) => {
  const {
    isSummary,
    nextPage,
    onChangeWorksheet,
    onClickBackButton,
    onClickSaveButton,
    worksheetData,
    isSaveButtonDisabled = false,
    isNextPageButtonDisabled = false
  } = props
  const [isWorksheetNameEditing, setIsWorksheetNameEditing] = useState(false)
  const [isNextbuttonDisabled, setIsNextButtonDisabled] = useState(false)
  const [isEditable, setIsEditable] = useState(true);
 
  const onClickEdit = () => {
    setIsWorksheetNameEditing(true)
  }

  let lastSaved = null
  if (worksheetData.lastSaved) {
    lastSaved = moment(moment.utc(worksheetData.lastSaved)).local().format('DD/MM/YYYY HH:mm')
  }
  const zone_name = moment.tz.guess()
  const timezone = moment.tz(zone_name).zoneAbbr()

  const determineAccessibility = (data: { planStatus: any; isDelegated: any; isCheckedIn: any; delegateAccess: any }) => {
    const { planStatus, isDelegated, isCheckedIn, delegateAccess } = data;
 
    if (planStatus === 'Owned') {
      if (isDelegated) {
        return isCheckedIn;
      } else {
        return true;
      }
    } else{
      return isCheckedIn;
    }
  };
 
  // Close the worksheet name input field if clicking outside of it
  const ref = useRef<HTMLInputElement>(null)
  useEffect(() => {
    setIsEditable(determineAccessibility(worksheetData))
    const checkIfClickedOutside = (e: any) => {
      // If the input is open and the clicked target is not within it
      if (isWorksheetNameEditing && ref.current && !ref.current.contains(e.target) && ref.current.checkValidity()) {
        setIsWorksheetNameEditing(false)
      }
    }

    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [isWorksheetNameEditing])

  return (
    <div className='footer-container'>
      <div className='col-footer'>
        <button className='secondary-button back-button' type='button' onClick={onClickBackButton}>
          <h3 className='heading-three progress-button-text'>Back</h3>
        </button>
        <div className='worksheet-name-container'>
          {isWorksheetNameEditing ? (
            <input
              type='text'
              className='edit-worksheet-name-field'
              name='worksheetName'
              value={worksheetData.worksheetName}
              onChange={onChangeWorksheet}
              maxLength={30}
              required
              placeholder='Required'
              ref={ref}
            />
          ) : !isSummary ? (
            <>
            <div className={`inputs-main `} style={isEditable ? { } : { pointerEvents: 'none' }}>
              <span className='worksheet-name-text' onClick={onClickEdit}>
                {worksheetData.worksheetName}
              </span>

              <span className='material-icons worksheet-name-edit-icon' onClick={onClickEdit}>
                edit
              </span>
              </div>
            </>
          ) : (
            <>
              <span className='worksheet-name-text'>{worksheetData.worksheetName}</span>
            </>
          )}
        </div>
      </div>

      {!isSummary && (
        <div className='col-footer'>
          {worksheetData.lastSaved && (
            <p className='last-saved-text'>
              Last saved: {lastSaved} {timezone}
            </p>
          )}
          <button
            className='secondary-button back-button'
            type='button'
            onClick={() => {
              setIsWorksheetNameEditing(false)
              onClickSaveButton()
            }}
            disabled={isSaveButtonDisabled}
          >
            <h3 className='heading-three progress-button-text'>Save</h3>
          </button>
          <button
            className='next-button primary-button'
            type='button'
            onClick={(e) => {
              if (e.detail == 1) props.onClickNextButton()
            }}
            disabled={isNextPageButtonDisabled}
          >
            <h2 className='heading-three next-button-text'>{nextPage}</h2>
            <span className='material-icons next-icon'>navigate_next</span>
          </button>
        </div>
      )}

      {isSummary && (
        <div className='col-footer'>
          {worksheetData.lastSaved && (
            <p className='last-saved-text'>
              Last saved: {lastSaved} {timezone}
            </p>
          )}
          <button className='next-button primary-button' type='button' onClick={() => props.exportPDF()}>
            <h2 className='heading-three next-button-text-summary'>Export as PDF</h2>
          </button>
          <button className='next-button primary-button' type='button' onClick={() => props.exportExcel()}>
            <h2 className='heading-three next-button-text-summary'>Export as Excel</h2>
          </button>
        </div>
      )}
    </div>
  )
}

export default Footer
