import { useContext, useEffect, useReducer, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Footer from '../footer/Footer'
import Header from '../header/Header'
import NavMenu from '../navigation/NavMenu'
import { getCCTLabelSettings, getPricingPlan, getRanks } from '../utils/fetchHandler'
import { RouteParamTypes } from '../global-inputs/GlobalInputTypes'
import DetailedSummary from './detailed-summary/DetailedSummary'
import PriceBreakdown from './price-breakdown/PriceBreakdown'
import WorkstreamSummary from './workstream-summary/WorkstreamSummary'
import PhasesSummary from './phases-summary/PhasesSummary'
import MobilityExpenses from './mobility-expenses/MobilityExpenses'
import TechnologyExpenses from './technology-expenses/TechnologyExpenses'
import Loading from '../loading/Loading'
import BasicDetails1 from './basic-details/BasicDetails1'
import BasicDetails2 from './basic-details/BasicDetails2'
import ResourcesByRank from './resources-by-rank/ResourcesByRank'
import LeverageBreakdown from './leverage-breakdown/LeverageBreakdown'
import VendorOrSubcontractor from './vendor-or-subcontractor/VendorOrSubcontractor'
import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas'
import moment from 'moment'
import HorizontalLogo from '../../assets/EY_Logo_Beam_Tag_Horizontal_RGB_EN.jpg'
import SquareLogo from '../../assets/EY_Logo_Beam_RGB.jpg'
import '../../assets/fonts/EYInterstate-Regular-normal.js'
import './Summary.css'
import * as FileSaver from 'file-saver'
import ExcelJS from 'exceljs'
import { UserContext } from '../../App'
import DetailedScenario from './Detailed-Scenario/Detailed-Scenario'

export const SCENARIOS = {
  base: {
    label: 'Base'
  },
  targetMargin: {
    label: 'Target Margin'
  },
  fixedFee: {
    label: 'Fixed Fee'
  },
  client: {
    label: 'Client'
  },
  marketRate: {
    label: 'Market Rate'
  }
}

export interface LocationState {
  eyViewValues: any
  clientViewValues: any
  expensesValues: any
  resourcesValues: any
  selectedColumn: any
  contingency: number
}

const initialState = {
  isLoading: true,
  pricingPlanData: {},
  ranks: []
}

const reducer = (state: any, payload: any) => {
  return { ...state, ...payload }
}
const Summary = () => {
  const [state, setState] = useReducer(reducer, initialState)
  const { pricingPlanData, isLoading, ranks } = state
  const history = useHistory()
  const { pricingPlanId } = useParams<RouteParamTypes>()
  const [highestAccessedPage, setHighestAccessedPage] = useState<string>('')
  const [WNote, WorkNote] = useState([null])
  const [PNote, PhaseNote] = useState([null])
  const userId = useContext(UserContext)[1]

  useEffect(() => {
    const init = async () => {
      //Get ranks
      try {
        const ranks = await getRanks()
        setState({ ranks })
      } catch (error) {}
      try {
        await fetchPricingPlan()
        await GetWorkPhase()
        setState({ isLoading: false })
      } catch (error) {
        history.push('/')
      }
    }
    init()
  }, [])

  const fetchPricingPlan = async () => {
    let pricingPlanData = await getPricingPlan(pricingPlanId, userId)
    setHighestAccessedPage(pricingPlanData.highestAccessedPage)
    setState({ pricingPlanData })
  }
  const GetWorkPhase = async () => {
    let note = await getCCTLabelSettings()
    for (const item of note) {
      if (item.labelName === 'PhNote') {
        PhaseNote(item.labelDescription)
      } else if (item.labelName === 'WSNote') {
        WorkNote(item.labelDescription)
      }
    }
  }

  const scenarioName: 'marketRate' | 'targetMargin' | 'fixedFee' | 'client' =
    pricingPlanData.summaryViewModel?.selectedScenario

  const exportPDF = async () => {
    const pageWidth = 1580
    const pageHeight = 2200
    const pagePadding = 75
    const pdf = new jsPDF({ unit: 'px', format: [pageWidth, pageHeight], hotfixes: ['px_scaling'] })

    const toDataUrl = async (url: any) => {
      return new Promise((resolve, reject) => {
        let xhr = new XMLHttpRequest()
        xhr.onload = function () {
          var reader = new FileReader()
          reader.onloadend = function () {
            resolve(reader.result)
          }
          reader.readAsDataURL(xhr.response)
        }
        xhr.open('GET', url)
        xhr.responseType = 'blob'
        xhr.send()
      })
    }

    const addHeader = async () => {
      const HeaderLogo: any = await toDataUrl(HorizontalLogo)
      const x = pagePadding
      const y = pagePadding
      const logoWidth = 220
      const logoHeight = 105
      if (HeaderLogo) pdf.addImage(HeaderLogo, 'JPEG', x, y, logoWidth, logoHeight)
      // Adding Horizontal Line
      const x1 = pagePadding
      const vGap = 20
      const y1 = y + logoHeight + vGap
      const x2 = pageWidth - pagePadding
      const y2 = y1
      pdf.setDrawColor(225, 225, 230)
      pdf.line(x1, y1, x2, y2)
    }

    const pagecount = async () => {
      let WorkstreamVisible = document.getElementsByClassName('col workstream-summary')
      let PhaseVisible = document.getElementsByClassName('col phases-summary')
      return WorkstreamVisible.length > 0 || PhaseVisible.length > 0 ? 2 : 1
    }

    const addFooter = async (pageNumber: number) => {
      pdf.setFont('EYInterstate-Regular')
      pdf.setFontSize(10.5)
      const today = moment().format('DD/MM/YYYY')
      let x = pagePadding
      let y = pageHeight - pagePadding
      pdf.text(`Exported from Consulting Costing Tool on ${today}`, x, y)
      // Add Logo
      const FooterLogo: any = await toDataUrl(SquareLogo)
      const logoWidth = 75
      const logoHeight = 75
      x = pageWidth - pagePadding - logoWidth
      y = pageHeight - pagePadding - 75
      if (FooterLogo) pdf.addImage(FooterLogo, 'JPEG', x, y, logoWidth, logoHeight)
      //Get Page count
      const TotalPages = await pagecount()
      // Add page number
      const hGap = 100
      x = pageWidth - pagePadding - logoWidth - hGap
      y = pageHeight - pagePadding
      pdf.text(`Page ${pageNumber} of ${TotalPages}`, x, y)
    }

    const addHTMLContent = async (element: HTMLElement, x: number, y: number) => {
      const elementHeight = element.clientHeight
      const elementWidth = element.clientWidth
      const elementCanvas = await html2canvas(element)
      const imgData = elementCanvas.toDataURL('image/jpeg', 1.0)
      pdf.addImage(imgData, 'JPEG', x, y, elementWidth, elementHeight)
    }

    // Add Pag1 Header
    await addHeader()
    // Adding Page1 HTML
    const page1HTMLElement = document.getElementById('pdf-page1')
    if (page1HTMLElement) await addHTMLContent(page1HTMLElement, pagePadding, 240)
    // Add Page1 Footer
    await addFooter(1)
    let pagecountnumber = await pagecount()
    if (pagecountnumber > 1) {
      // Add Page 2
      pdf.addPage()
      pdf.setPage(2)
      // Add Page2 Header
      await addHeader()
      // Adding Page2 HTML
      const page2HTMLElement = document.getElementById('pdf-page2')
      if (page2HTMLElement) await addHTMLContent(page2HTMLElement, pagePadding, 240)
      // Add Page2 Footer
      await addFooter(2)
    }
    // CS0487232 - PDF name default to dealname
    const fileName = `${pricingPlanData.engagementName || pricingPlanData.worksheetName} - CCT.pdf` // downloaded pdf name with deal name
    pdf.save(fileName)
  }
  const generateWeeklyTimeUnits = async () => {
    let result = []
    let dealStartDate = pricingPlanData.dealStartDate
    let dealEndDate = pricingPlanData.dealEndDate
    if (dealStartDate && dealEndDate) {
      const start = moment(dealStartDate).day(1)
      const end = moment(dealEndDate)
      result.push({
        startDate: moment(start).format('YYYY-MM-DDT00:00:00'),
        timeUnits: 0
      })
      let tmp = start.add(7, 'days')
      while (tmp.isSameOrBefore(end)) {
        result.push({
          startDate: moment(tmp).format('YYYY-MM-DDT00:00:00'),
          timeUnits: 0
        })
        tmp.add(7, 'days')
      }
    }
    return result
  }

  const exportExcel = async () => {
    let curentPlanDtls = await getPricingPlan(pricingPlanId, userId)
    let weeksData = await generateWeeklyTimeUnits()
    let elem = document.createElement('div') as HTMLDivElement
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExtension = '.xlsx'
    const Excel = require('exceljs')
    const workbook = new Excel.Workbook()
    const worksheetGI = workbook.addWorksheet('Global Inputs')

    worksheetGI.columns = [
      { header: 'Global Inputs', key: 'globalInputs', width: 25 },
      { header: '', key: '', width: 25 },
      { header: '', key: 'dealCurrency', width: 25 },
      { header: '', key: 'tempGI', width: 15 }
    ]
    worksheetGI.addRow([
      'Please complete global inputs below. Required section(s) are indicated below with an asterisk (*)',
      '',
      '',
      ''
    ])
    worksheetGI.addRow([
      'Please do not enter any unnecessary personal information or any sensitive personal data (including tax file numbers or other government identifiers), client confidential information, audit secrets, state secrets,commercial secrets, or anything that would violate professional secrecy or confidentiality rules or that would be considered abusive/irrelevant.',
      '',
      '',
      ''
    ])
    worksheetGI.addRow(['', '', '', ''])

    worksheetGI.addRow(['Deal Details', '', '', ''])
    worksheetGI.addRow(['', '', '', ''])

    worksheetGI.addRow(['Deal Name', 'Client Name', 'Deal Currency', ''])

    worksheetGI.addRow([pricingPlanData.engagementName, pricingPlanData.client, pricingPlanData.planCurrency])
    worksheetGI.addRow(['', '', '', ''])
    worksheetGI.addRow(['Area/Region', 'Country', 'Deal Start Date', 'Deal End Date'])
    worksheetGI.addRow([
      pricingPlanData.region,
      pricingPlanData.country,
      moment(pricingPlanData.dealStartDate).format('DD/MM/YYYY'),
      moment(pricingPlanData.dealEndDate).format('DD/MM/YYYY')
    ])
    worksheetGI.addRow(['', '', '', ''])
    // worksheetGI.addRow(['Opportunity ID', '', '', ''])
    // worksheetGI.addRow([pricingPlanData.opportunityId, '', '', ''])
    worksheetGI.addRow(['Opportunity ID', 'Sector', '', ''])
    worksheetGI.addRow([pricingPlanData.opportunityId, pricingPlanData.sectorName, '', '']) //For the new sector field
    worksheetGI.addRow(['', '', '', ''])
    worksheetGI.getRow(1).font = { bold: true }
    worksheetGI.getRow(5).font = { bold: true }
    worksheetGI.getRow(7).font = { bold: true }
    worksheetGI.getRow(10).font = { bold: true }
    worksheetGI.getRow(13).font = { bold: true }
    if (pricingPlanData.phases.length > 0) {
      worksheetGI.addRow([
        'Phases (Optional) Note: Please add up to 6 Phases and Phases breakdown on the Summary page is available',
        '',
        '',
        ''
      ])
      worksheetGI.addRow(['Phase Title', 'Phase Start Date', 'Phase End Date', ''])
      var rowcnt1 = worksheetGI.actualRowCount
      worksheetGI.getRow(rowcnt1 - 1).getCell(1).font = { bold: true }
      worksheetGI.getRow(rowcnt1).font = { bold: true }
      for (var phaseIndex = 0; phaseIndex < pricingPlanData.phases.length; phaseIndex++) {
        worksheetGI.addRow([
          pricingPlanData.phases[phaseIndex].title,
          moment(pricingPlanData.phases[phaseIndex].startDate).format('DD/MM/YYYY'),
          moment(pricingPlanData.phases[phaseIndex].endDate).format('DD/MM/YYYY'),
          ''
        ])
      }
    }
    worksheetGI.addRow(['', '', '', ''])
    if (pricingPlanData.workstreams.length > 0) {
      worksheetGI.addRow([
        'Workstreams (Optional) Note: Please add up to 6 Workstreams and Workstreams breakdown on the Summary page is available.',
        '',
        '',
        ''
      ])

      worksheetGI.addRow(['Workstream Title', '', '', ''])
      var rowcnt = worksheetGI.actualRowCount
      worksheetGI.getRow(rowcnt - 1).getCell(1).font = { bold: true }
      worksheetGI.getRow(rowcnt).getCell(1).font = { bold: true }
      for (var wsIndex = 0; wsIndex < pricingPlanData.workstreams.length; wsIndex++) {
        worksheetGI.addRow([pricingPlanData.workstreams[wsIndex].title])
      }
    }
    worksheetGI.mergeCells('A2:D2')
    worksheetGI.mergeCells('A3:D3')
    const worksheet1 = workbook.addWorksheet('Resources')

    const columns = []
    if (curentPlanDtls.workstreams.length > 0) {
      columns.push({
        header: 'Workstreams',
        key: 'ws',
        width: 25
      })
    }
    columns.push({
      header: 'Role',
      key: 'role',
      width: 20
    })
    columns.push({
      header: 'Resource/Cost Center',
      key: 'rsc',
      width: 30
    })
    columns.push({
      header: 'Rank',
      key: 'ec1',
      width: 20
    })
    columns.push({
      header: 'Promotion',
      key: 'ec2',
      width: 10
    })
    columns.push({
      header: 'Hours',
      key: 'ec3',
      width: 10
    })

    // for (var j = 0; j < curentPlanDtls.resources[0].weeklyTimeUnits.length; j++) {
    //   columns.push({
    //     header: moment(curentPlanDtls.resources[0].weeklyTimeUnits[j].startDate).format('MM-DD-YYYY'),
    //     key: '_id' + j,
    //     width: 10
    //   })
    // }

    for (var j = 0; j < weeksData.length; j++) {
      columns.push({
        header: moment(weeksData[j].startDate).format('MM-DD-YYYY'),
        key: '_id' + j,
        width: 10
      })
    }

    columns.push({
      header: 'CostRate',
      key: 'ec4',
      width: 15
    })
    columns.push({
      header: 'MarketScope',
      key: 'ec5',
      width: 15
    })
    columns.push({
      header: 'MarketRate',
      key: 'ec6',
      width: 15
    })
    columns.push({
      header: 'ClientBillRate',
      key: 'ec7',
      width: 15
    })
    columns.push({
      header: 'TotalLabourCost',
      key: 'ec8',
      width: 15
    })
    worksheet1.Columns = columns

    let firstRow = []

    firstRow.push('Resources')

    for (var k = 1; k < worksheet1.Columns.length; k++) {
      firstRow.push('')
    }
    worksheet1.addRow(firstRow)
    let secondRow = []
    secondRow.push(
      'Add resources that will be deployed, along with related information on role, efforts, and client billing rate (if applicable). Resources may be selected by name, if known.'
    )

    for (var k = 1; k < worksheet1.Columns.length; k++) {
      secondRow.push('')
    }
    worksheet1.addRow(secondRow)
    let thirdRow = []
    thirdRow.push(
      'Please do not enter any unnecessary personal information or any sensitive personal data (including tax file numbers or other government identifiers), client confidential information, audit secrets, state secrets,commercial secrets, or anything that would violate professional secrecy or confidentiality rules or that would be considered abusive/irrelevant.'
    )

    for (var k = 1; k < worksheet1.Columns.length; k++) {
      thirdRow.push('')
    }
    worksheet1.addRow(thirdRow)
    let fourthRow = []
    for (var k = 0; k < worksheet1.Columns.length; k++) {
      fourthRow.push('')
    }
    worksheet1.addRow(fourthRow)
    worksheet1.mergeCells('A1:G1')
    worksheet1.mergeCells('A2:S2')
    worksheet1.mergeCells('A3:S3')
    const rowsHeader = []

    if (curentPlanDtls.workstreams.length > 0) {
      rowsHeader.push('Workstreams')
    }
    rowsHeader.push('Role')
    rowsHeader.push('Resource/Cost Center')
    rowsHeader.push('Rank')
    rowsHeader.push('Promotion')
    rowsHeader.push('Hours')
    // for (var j = 0; j < curentPlanDtls.resources[0].weeklyTimeUnits.length; j++) {
    //   rowsHeader.push(moment(curentPlanDtls.resources[0].weeklyTimeUnits[j].startDate).format('MM-DD-YYYY'))
    // }
    for (var j = 0; j < weeksData.length; j++) {
      rowsHeader.push(moment(weeksData[j].startDate).format('MM-DD-YYYY'))
    }
    rowsHeader.push('Cost Rate/hr' + '(' + pricingPlanData.planCurrency + ')')
    rowsHeader.push('Market Scope' + '')
    rowsHeader.push('Market Rate/hr' + '(' + pricingPlanData.planCurrency + ')')
    rowsHeader.push('Client Bill Rate/hr' + '(' + pricingPlanData.planCurrency + ')')
    rowsHeader.push('Total Labour Cost' + '(' + pricingPlanData.planCurrency + ')')
    worksheet1.addRow(rowsHeader)
    for (var i = 0; i < curentPlanDtls.resources.length; i++) {
      let resourceData = curentPlanDtls.resources[i]

      const rows = []

      if (curentPlanDtls.workstreams.length > 0) {
        rows.push(curentPlanDtls.resources[i].workstream)
      }
      rows.push(curentPlanDtls.resources[i].role)
      rows.push(
        curentPlanDtls.resources[i].source !== 'gdsOffshore'
          ? curentPlanDtls.resources[i].employee + ' ' + curentPlanDtls.resources[i].costCentre
          : curentPlanDtls.resources[i].employee + ' ' + 'GDS'
      )
      rows.push(curentPlanDtls.resources[i].rank)

      rows.push(curentPlanDtls.resources[i].promotedResource ? 'Yes' : '')
      rows.push(curentPlanDtls.resources[i].totalHours)
      for (var preWeeks = 0; preWeeks < weeksData.length; preWeeks++) {
        if (weeksData[preWeeks].startDate < curentPlanDtls.resources[i].weeklyTimeUnits[0].startDate) {
          rows.push('')
        }
      }
      for (var j = 0; j < curentPlanDtls.resources[i].weeklyTimeUnits.length; j++) {
        rows.push(curentPlanDtls.resources[i].weeklyTimeUnits[j].timeUnits)
      }
      for (var postWeeks = 0; postWeeks < weeksData.length; postWeeks++) {
        if (
          weeksData[postWeeks].startDate >
          curentPlanDtls.resources[i].weeklyTimeUnits[curentPlanDtls.resources[i].weeklyTimeUnits.length - 1].startDate
        ) {
          rows.push('')
        }
      }
      rows.push(curentPlanDtls.resources[i].costRate)

      let marketScopeValue: any
      curentPlanDtls.resources[i]?.availableMarketScopes?.forEach((scope: any) => {
        if (scope.marketScopeId === curentPlanDtls.resources[i].marketScopeId) {
          marketScopeValue = scope.marketScopeValue
        }
      })
      rows.push(marketScopeValue)
      rows.push(Math.round(curentPlanDtls.resources[i].marketRate))

      rows.push(curentPlanDtls.resources[i].clientRateHour)
      rows.push(curentPlanDtls.resources[i].totalStandardCost)
      worksheet1.addRow(rows)

      if (curentPlanDtls.resources[i].promotedResource) {
        const promotedRows = []

        if (curentPlanDtls.workstreams.length > 0) {
          promotedRows.push('')
        }
        promotedRows.push('')
        promotedRows.push('')
        promotedRows.push(curentPlanDtls.resources[i].promotedResource.rank)

        promotedRows.push('')
        promotedRows.push(curentPlanDtls.resources[i].promotedResource.totalHours)

        for (var j = 0; j < curentPlanDtls.resources[i].weeklyTimeUnits.length; j++) {
          if (
            moment(curentPlanDtls.resources[i].weeklyTimeUnits[j].startDate).isBefore(
              moment(curentPlanDtls.resources[i].promotedResource.weeklyTimeUnits[0].startDate)
            )
          ) {
            promotedRows.push('')
          }
        }

        for (
          var promoIndex = 0;
          promoIndex < curentPlanDtls.resources[i].promotedResource.weeklyTimeUnits.length;
          promoIndex++
        ) {
          promotedRows.push(curentPlanDtls.resources[i].promotedResource.weeklyTimeUnits[promoIndex].timeUnits)
        }
        promotedRows.push(curentPlanDtls.resources[i].promotedResource.costRate)
        let marketScopeValue: any
        curentPlanDtls.resources[i]?.promotedResource.availableMarketScopes?.forEach((scope: any) => {
          if (scope.marketScopeId === curentPlanDtls.resources[i].promotedResource.marketScopeId) {
            marketScopeValue = scope.marketScopeValue
          }
        })
        rows.push(marketScopeValue)
        promotedRows.push(Math.round(curentPlanDtls.resources[i].marketRate))

        promotedRows.push(curentPlanDtls.resources[i].promotedResource.clientRateHour)
        promotedRows.push(curentPlanDtls.resources[i].promotedResource.totalStandardCost)

        worksheet1.addRow(promotedRows)
      }
    }
    worksheet1.addRow(['', '', '', '', '', '', '', ''])
    worksheet1.getRow(1).font = { bold: true }
    worksheet1.getRow(5).font = { bold: true }

    //Resource -end

    const worksheetExpenses = workbook.addWorksheet('Expenses')

    worksheetExpenses.addRow('Expenses')
    worksheetExpenses.addRow(' ')
    worksheetExpenses.columns = [
      { header: 'Expenses', key: 'category', width: 25 },
      { header: '', key: 'ws', width: 25 },
      { header: '', key: 'cost', width: 15 },
      { header: '', key: 'revenue', width: 15 },
      { header: '', key: 'recoverable', width: 15 }
    ]
    worksheetExpenses.addRow(['Add Technology, Travel-Related, and Other Expenses', '', '', '', ''])
    worksheetExpenses.addRow([
      'Please do not enter any unnecessary personal information or any sensitive personal data (including tax file numbers or other government identifiers), client confidential information,audit secrets, state secrets, commercial secrets, or anything that would violate professional secrecy or confidentiality rules or that would be considered abusive/irrelevant.',
      '',
      '',
      '',
      ''
    ])

    worksheetExpenses.addRow(['', '', '', '', ''])
    worksheetExpenses.addRow(['CT Product Expenses', '', '', '', ''])
    worksheetExpenses.addRow([
      'Category',
      'Workstream',
      'Cost' + '(' + pricingPlanData.planCurrency + ')',
      'Revenue',
      'Recoverable',
      'Comment'
    ])
    const techExpenseRows = pricingPlanData.expenses.filter((expense: any) => expense.expenseType === 'Technology')
    for (var techExpenseCnt = 0; techExpenseCnt < techExpenseRows.length; techExpenseCnt++) {
      worksheetExpenses.addRow([
        techExpenseRows[techExpenseCnt].category,
        techExpenseRows[techExpenseCnt].workstream,
        techExpenseRows[techExpenseCnt].cost,
        techExpenseRows[techExpenseCnt].revenue,
        techExpenseRows[techExpenseCnt].recoverable === true ? 'Yes' : 'No',
        techExpenseRows[techExpenseCnt].comment
      ])
    }
    worksheetExpenses.addRow(['', '', '', '', '', ''])
    worksheetExpenses.addRow(['Travel and Other Related Expenses', '', '', '', '', ''])

    var mobExpensesRowCnt = worksheetExpenses.rowCount
    worksheetExpenses.addRow([
      'Category',
      'Resource',
      'Workstream',
      'Cost' + '(' + pricingPlanData.planCurrency + ')',
      ,
      'Recoverable'
    ])
    const mobExpenseRows = pricingPlanData.expenses.filter((expense: any) => expense.expenseType !== 'Technology')
    for (var mobExpenseCnt = 0; mobExpenseCnt < mobExpenseRows.length; mobExpenseCnt++) {
      const resourceDtls = pricingPlanData.resources.filter(
        (resource: any) => resource.id === parseInt(mobExpenseRows[mobExpenseCnt].resource.split('||')[0])
      )
      let resourceData =
        resourceDtls[0] === undefined
          ? mobExpenseRows[mobExpenseCnt].resource
          : resourceDtls[0].employee + ' - ' + resourceDtls[0].rank + ' - ' + resourceDtls[0].costCentre
      worksheetExpenses.addRow([
        mobExpenseRows[mobExpenseCnt].category,
        resourceData,
        mobExpenseRows[mobExpenseCnt].workstream,
        mobExpenseRows[mobExpenseCnt].cost,
        mobExpenseRows[mobExpenseCnt].recoverable === true ? 'Yes' : 'No'
      ])
    }
    worksheetExpenses.getRow(1).font = { bold: true }
    worksheetExpenses.getRow(6).font = { bold: true }
    worksheetExpenses.getRow(7).font = { bold: true }
    worksheetExpenses.getRow(mobExpensesRowCnt).font = { bold: true }
    worksheetExpenses.getRow(mobExpensesRowCnt + 1).font = { bold: true }
    //worksheetExpenses.getRow(4).height = '50'
    worksheetExpenses.mergeCells('A3:E3')
    worksheetExpenses.mergeCells('A4:E4')

    const worksheet = workbook.addWorksheet('Summary')
    worksheet.columns = [
      { header: 'Management Summary', key: 'id', width: 25 },
      { header: SCENARIOS[scenarioName]?.label.toUpperCase(), key: 'name', width: 15 },
      { header: '', key: 'ec1', width: 10 },
      { header: '', key: 'ec2', width: 10 },
      { header: '', key: 'ec3', width: 25 },
      { header: '', key: 'ec4', width: 15 },
      { header: '', key: 'ec5', width: 15 },
      //{ header: '', key: 'ec6', width: 15 },
      { header: '', key: 'ec6', width: 15 },
      { header: '', key: 'ec7', width: 15 },
      { header: '', key: 'ec10', width: 20 }
    ]
    // worksheet.addRow([
    //   'Management Summary',
    //   SCENARIOS[scenarioName].label.toUpperCase(),
    //   '', //,
    //   '',
    //   '',
    //   '',
    //   '',
    //   ''
    // ])

    worksheet.addRow(['', '', '', '', '', '', '', '', ''])
    worksheet.addRow([
      'DEAL NAME',
      pricingPlanData.engagementName,
      '',
      '',
      'AREA / REGION',
      'COUNTRY',
      'DEAL DATES',
      'OPPORTUNITY ID'
    ])

    worksheet.addRow([
      'CLIENT NAME',
      pricingPlanData.client,
      '',
      '',
      pricingPlanData.region,
      pricingPlanData.country,
      moment(pricingPlanData.dealStartDate).format('DD/MM/YY') +
        '-' +
        moment(pricingPlanData.dealEndDate).format('DD/MM/YY'),
      pricingPlanData.opportunityId
    ])

    worksheet.addRow(['', '', '', '', '', '', '', '', ''])

    const laborfees = document.getElementsByClassName('cost')[0] as HTMLElement
    const ctproduct = document.getElementsByClassName('cost')[1] as HTMLElement
    const feeServicesVal = document.getElementsByClassName('cost')[2] as HTMLElement
    const feeForSubcontractor = document.getElementsByClassName('cost')[3] as HTMLElement
    const mobilityandOtherExpenses = document.getElementsByClassName('cost')[4] as HTMLElement
    const technologyPriceBreakdown = document.getElementsByClassName('cost')[5] as HTMLElement
    const totalBilling = document.getElementsByClassName('cost')[6] as HTMLElement

    let eyMarginValIncl = document.getElementsByClassName('td including')[0] as HTMLElement
    let eyMarginValExcl = document.getElementsByClassName('td excluding')[0] as HTMLElement

    let eyMarginValDCRIncl = document.getElementsByClassName('td including')[2] as HTMLElement
    let eyMarginValDCRExcl = document.getElementsByClassName('td excluding')[1] as HTMLElement

    let eyMarginExclPartnerIncl = document.getElementsByClassName('td including')[4] as HTMLElement
    let eyMarginExclPartnerExcl = document.getElementsByClassName('td excluding')[2] as HTMLElement

    let eyMarginExclPartnerUsingDCRIncl = document.getElementsByClassName('td including')[6] as HTMLElement
    let eyMarginExclPartnerUsingDCRExcl = document.getElementsByClassName('td excluding')[3] as HTMLElement

    let totalLaborCostIncl = document.getElementsByClassName('td including')[8] as HTMLElement
    let totalLaborCostExcl = document.getElementsByClassName('td excluding')[4] as HTMLElement

    let costOfSubIncl = document.getElementsByClassName('td including')[10] as HTMLElement
    let costOfSubExcl = document.getElementsByClassName('td excluding')[5] as HTMLElement

    let totalExpensesIncl = document.getElementsByClassName('td including')[12] as HTMLElement
    let totalExpensesExcl = document.getElementsByClassName('td excluding')[6] as HTMLElement

    let ratePerDayIncl = document.getElementsByClassName('td including')[14] as HTMLElement
    let ratePerDayExcl = document.getElementsByClassName('td excluding')[7] as HTMLElement

    let ratePerHourIncl = document.getElementsByClassName('td including')[16] as HTMLElement
    let ratePerHourExcl = document.getElementsByClassName('td excluding')[8] as HTMLElement

    let totalBillingAmountIncl = document.getElementsByClassName('td including')[18] as HTMLElement
    let totalBillingAmountExcl = document.getElementsByClassName('td excluding')[9] as HTMLElement
    let contingency = document.getElementsByClassName('contingency')[0] as HTMLElement

    let eyMarginValDCRIncl1 = document.getElementsByClassName('td including')[1] as HTMLElement
    let eyMarginExclPartnerIncl1 = document.getElementsByClassName('td including')[2] as HTMLElement
    let eyMarginExclPartnerUsingDCRIncl1 = document.getElementsByClassName('td including')[3] as HTMLElement
    let totalLaborCostIncl1 = document.getElementsByClassName('td including')[4] as HTMLElement
    let costOfSubIncl1 = document.getElementsByClassName('td including')[5] as HTMLElement
    let totalExpensesIncl1 = document.getElementsByClassName('td including')[6] as HTMLElement
    let ratePerDayIncl1 = document.getElementsByClassName('td including')[7] as HTMLElement
    let ratePerHourIncl1 = document.getElementsByClassName('td including')[8] as HTMLElement
    let totalBillingAmountIncl1 = document.getElementsByClassName('td including')[9] as HTMLElement

    let eyMarginInclForMrktRt = document.getElementsByClassName('td including marketRate')[0] as HTMLElement
    let eyMarginInclDcrForMrktRt = document.getElementsByClassName('td including marketRate')[1] as HTMLElement
    let eyMarginInclForMrktRtExcPrtnrCost = document.getElementsByClassName('td including marketRate')[2] as HTMLElement
    let eyMarginInclDcrForMrktRtExcPrtnrCost = document.getElementsByClassName(
      'td including marketRate'
    )[3] as HTMLElement
    let totalLaborCostForMrktRt = document.getElementsByClassName('td including marketRate')[4] as HTMLElement
    let subcontractorCostMrktRt = document.getElementsByClassName('td including marketRate')[5] as HTMLElement
    let totalExpensesMrktRt = document.getElementsByClassName('td including marketRate')[6] as HTMLElement
    let ratePerDayMrktRt = document.getElementsByClassName('td including marketRate')[7] as HTMLElement
    let ratePerHrMrktRt = document.getElementsByClassName('td including marketRate')[8] as HTMLElement
    let totalBillingMrktRt = document.getElementsByClassName('td including marketRate')[9] as HTMLElement

    worksheet.addRow(['Price Breakdown', '', '', '', 'Detailed Summary', contingency.innerText, '', '', ''])
    // worksheet.addRow(['', '', '', '', '', '', 'Including Contingency', 'Excluding Contingency', 'Market Rate Incl Contingency'])

    if (scenarioName !== 'marketRate') {
      worksheet.addRow([
        '',
        '',
        '',
        '',
        '',
        '',
        'Including Contingency',
        'Excluding Contingency',
        'Market Rate Incl Contingency',
        ''
      ])
      worksheet.addRow([
        'Labor Fees',
        '',
        laborfees.innerText, // Include labor fees here
        '',
        'EY Margin %',
        '',
        eyMarginValIncl.innerText,
        eyMarginValExcl.innerText,
        eyMarginInclForMrktRt.innerText
      ])

      worksheet.addRow([
        'CT Products',
        '',
        ctproduct.innerText, // Include CT products here
        '',
        'EY Margin % using DCR',
        '',
        eyMarginValDCRIncl.innerText,
        eyMarginValDCRExcl.innerText,
        eyMarginInclDcrForMrktRt.innerText
      ])

      worksheet.addRow([
        'Fees for Services',
        '',
        feeServicesVal.innerText,
        '',
        'EY Margin % Excluding Partner Costs',
        '',
        eyMarginExclPartnerIncl.innerText,
        eyMarginExclPartnerExcl.innerText,
        eyMarginInclForMrktRtExcPrtnrCost.innerText
      ])

      worksheet.addRow([
        'Fees for Subcontractors',
        '',
        feeForSubcontractor.innerText,
        '',
        'EY Margin % using DCR, Excluding Partner Costs',
        '',
        eyMarginExclPartnerUsingDCRIncl.innerText,
        eyMarginExclPartnerUsingDCRExcl.innerText,
        eyMarginInclDcrForMrktRtExcPrtnrCost.innerText
      ])

      worksheet.addRow([
        'Mobility & Other Expenses',
        '',
        mobilityandOtherExpenses.innerText,
        '',
        'Total Labor & CT Cost',
        '',
        totalLaborCostIncl.innerText,
        totalLaborCostExcl.innerText,
        totalLaborCostForMrktRt.innerText
      ])

      worksheet.addRow([
        'Technology',
        '',
        technologyPriceBreakdown.innerText,
        '',
        'Cost of Subcontractors',
        '',
        costOfSubIncl.innerText,
        costOfSubExcl.innerText,
        subcontractorCostMrktRt.innerText
      ])

      worksheet.addRow([
        'TOTAL BILLING',
        '',
        totalBilling.innerText,
        '',
        'Total Expenses',
        '',
        totalExpensesIncl.innerText,
        totalExpensesExcl.innerText,
        totalExpensesMrktRt.innerText
      ])
      worksheet.addRow([
        '',
        '',
        '',
        '',
        'Rate / Day',
        '',
        ratePerDayIncl.innerText,
        ratePerDayExcl.innerText,
        ratePerDayMrktRt.innerText
      ])
      worksheet.addRow([
        '',
        '',
        '',
        '',
        'Rate / Hour',
        '',
        ratePerHourIncl.innerText,
        ratePerHourExcl.innerText,
        ratePerHrMrktRt.innerText
      ])
      worksheet.addRow([
        '',
        '',
        '',
        '',
        'Total Billing Amount',
        '',
        totalBillingAmountIncl.innerText,
        totalBillingAmountExcl.innerText,
        totalBillingMrktRt.innerText
      ])
    } else {
      worksheet.addRow(['', '', '', '', '', '', 'Including Contingency', 'Excluding Contingency', ''])
      worksheet.addRow([
        'Labor Fees',
        '',
        laborfees.innerText, // Include labor fees here
        '',
        'EY Margin %',
        '',
        eyMarginValIncl.innerText,
        eyMarginValExcl.innerText
      ])

      worksheet.addRow([
        'CT Products',
        '',
        ctproduct.innerText, // Include CT products here
        '',
        'EY Margin % using DCR',
        '',
        eyMarginValDCRIncl1.innerText,
        eyMarginValDCRExcl.innerText
      ])

      worksheet.addRow([
        'Fees for Services',
        '',
        feeServicesVal.innerText,
        '',
        'EY Margin % Excluding Partner Costs',
        '',
        eyMarginExclPartnerIncl1.innerText,
        eyMarginExclPartnerExcl.innerText
      ])

      worksheet.addRow([
        'Fees for Subcontractors',
        '',
        feeForSubcontractor.innerText,
        '',
        'EY Margin % using DCR, Excluding Partner Costs',
        '',
        eyMarginExclPartnerUsingDCRIncl1.innerText,
        eyMarginExclPartnerUsingDCRExcl.innerText
      ])

      worksheet.addRow([
        'Mobility & Other Expenses',
        '',
        mobilityandOtherExpenses.innerText,
        '',
        'Total Labor & CT Cost',
        '',
        totalLaborCostIncl1.innerText,
        totalLaborCostExcl.innerText
      ])

      worksheet.addRow([
        'Technology',
        '',
        technologyPriceBreakdown.innerText,
        '',
        'Cost of Subcontractors',
        '',
        costOfSubIncl1.innerText,
        costOfSubExcl.innerText
      ])

      worksheet.addRow([
        'TOTAL BILLING',
        '',
        totalBilling.innerText,
        '',
        'Total Expenses',
        '',
        totalExpensesIncl1.innerText,
        totalExpensesExcl.innerText
      ])
      worksheet.addRow(['', '', '', '', 'Rate / Day', '', ratePerDayIncl1.innerText, ratePerDayExcl.innerText])
      worksheet.addRow(['', '', '', '', 'Rate / Hour', '', ratePerHourIncl1.innerText, ratePerHourExcl.innerText])
      worksheet.addRow([
        '',
        '',
        '',
        '',
        'Total Billing Amount',
        '',
        totalBillingAmountIncl1.innerText,
        totalBillingAmountExcl.innerText
      ])
    }

    const nodeList = document.querySelectorAll('[class*="ResourcesByRank_tr"]')

    let partnerHrs = nodeList[1] !== undefined ? (nodeList[1].childNodes[1] as HTMLElement) : elem
    let partnerCost = nodeList[1] !== undefined ? (nodeList[1].childNodes[2] as HTMLElement) : elem

    let executiveHrs = nodeList[2] !== undefined ? (nodeList[2].childNodes[1] as HTMLElement) : elem
    let executiveCost = nodeList[2] !== undefined ? (nodeList[2].childNodes[2] as HTMLElement) : elem

    let seniorMgrHrs = nodeList[3] !== undefined ? (nodeList[3].childNodes[1] as HTMLElement) : elem
    let seniorMgrCost = nodeList[3] !== undefined ? (nodeList[3].childNodes[2] as HTMLElement) : elem

    let MgrHrs = nodeList[4] !== undefined ? (nodeList[4].childNodes[1] as HTMLElement) : elem
    let MgrCost = nodeList[4] !== undefined ? (nodeList[4].childNodes[2] as HTMLElement) : elem

    let seniorHrs = nodeList[5] !== undefined ? (nodeList[5].childNodes[1] as HTMLElement) : elem
    let seniorCost = nodeList[5] !== undefined ? (nodeList[5].childNodes[2] as HTMLElement) : elem

    let staffHrs = nodeList[6] !== undefined ? (nodeList[6].childNodes[1] as HTMLElement) : elem
    let staffCost = nodeList[6] !== undefined ? (nodeList[6].childNodes[2] as HTMLElement) : elem

    let internHrs = nodeList[7] !== undefined ? (nodeList[7].childNodes[1] as HTMLElement) : elem
    let internCost = nodeList[7] !== undefined ? (nodeList[7].childNodes[2] as HTMLElement) : elem

    let cscHrs = nodeList[8] !== undefined ? (nodeList[8].childNodes[1] as HTMLElement) : elem
    let cscCost = nodeList[8] !== undefined ? (nodeList[8].childNodes[2] as HTMLElement) : elem

    let subHrs = nodeList[9] !== undefined ? (nodeList[9].childNodes[1] as HTMLElement) : elem
    let subCost = nodeList[9] !== undefined ? (nodeList[9].childNodes[2] as HTMLElement) : elem

    let totHrs = nodeList[10] !== undefined ? (nodeList[10].childNodes[1] as HTMLElement) : elem
    let totCost = nodeList[10] !== undefined ? (nodeList[10].childNodes[2] as HTMLElement) : elem

    const DetailedScenarioNodeList = document.querySelectorAll('[class*="bold-text"]')
    let TotalFeesEyOnshore =
      DetailedScenarioNodeList[0] !== undefined ? (DetailedScenarioNodeList[0].childNodes[0] as HTMLElement) : elem
    let TotalFeesGds =
      DetailedScenarioNodeList[1] !== undefined ? (DetailedScenarioNodeList[1].childNodes[0] as HTMLElement) : elem
    let TotalFeesSub =
      DetailedScenarioNodeList[2] !== undefined ? (DetailedScenarioNodeList[2].childNodes[0] as HTMLElement) : elem
    let TotalFeesTotalResource =
      DetailedScenarioNodeList[3] !== undefined ? (DetailedScenarioNodeList[3].childNodes[0] as HTMLElement) : elem
    let TotalinclFullyloadedGDSCost =
      DetailedScenarioNodeList[4] !== undefined ? (DetailedScenarioNodeList[4].childNodes[0] as HTMLElement) : elem
    let LaborCostEyOnshore =
      DetailedScenarioNodeList[5] !== undefined ? (DetailedScenarioNodeList[5].childNodes[0] as HTMLElement) : elem
    let LaborCostGds =
      DetailedScenarioNodeList[6] !== undefined ? (DetailedScenarioNodeList[6].childNodes[0] as HTMLElement) : elem
    let LaborCostSub =
      DetailedScenarioNodeList[7] !== undefined ? (DetailedScenarioNodeList[7].childNodes[0] as HTMLElement) : elem
    let LaborCostTotalResources =
      DetailedScenarioNodeList[8] !== undefined ? (DetailedScenarioNodeList[8].childNodes[0] as HTMLElement) : elem
    let LaborCostTotalinclFullyloadedGDSCost =
      DetailedScenarioNodeList[9] !== undefined ? (DetailedScenarioNodeList[9].childNodes[0] as HTMLElement) : elem 
    let TechCostEyOnshore =
      DetailedScenarioNodeList[10] !== undefined ? (DetailedScenarioNodeList[10].childNodes[0] as HTMLElement) : elem
    let TechCostGds =
      DetailedScenarioNodeList[11] !== undefined ? (DetailedScenarioNodeList[11].childNodes[0] as HTMLElement) : elem
    let TechCostSub =
      DetailedScenarioNodeList[12] !== undefined ? (DetailedScenarioNodeList[12].childNodes[0] as HTMLElement) : elem
    let TechCostTotalResource =
      DetailedScenarioNodeList[13] !== undefined ? (DetailedScenarioNodeList[13].childNodes[0] as HTMLElement) : elem
    let TechCostTotalinclFullyloadedGDSCost =
      DetailedScenarioNodeList[14] !== undefined ? (DetailedScenarioNodeList[14].childNodes[0] as HTMLElement) : elem
    let ContingencyEyOnshore = DetailedScenarioNodeList[15] !== undefined ? (DetailedScenarioNodeList[15].childNodes[0] as HTMLElement) : elem
    let ContingencyGds = DetailedScenarioNodeList[16] !== undefined ? (DetailedScenarioNodeList[16].childNodes[0] as HTMLElement) : elem
    let ContingencySub = DetailedScenarioNodeList[17] !== undefined ? (DetailedScenarioNodeList[17].childNodes[0] as HTMLElement) : elem
    let ContingencyTotalResource = DetailedScenarioNodeList[18] !== undefined ? (DetailedScenarioNodeList[18].childNodes[0] as HTMLElement) : elem
    let ContingencyTotalinclFullyloadedGDSCost= DetailedScenarioNodeList[19] !== undefined ? (DetailedScenarioNodeList[19].childNodes[0] as HTMLElement) : elem   
    let MarginInclContiEyOnshore =DetailedScenarioNodeList[20] !== undefined ? (DetailedScenarioNodeList[20].childNodes[0] as HTMLElement) : elem
    let MarginInclContiGds =DetailedScenarioNodeList[21] !== undefined ? (DetailedScenarioNodeList[21].childNodes[0] as HTMLElement) : elem
    let MarginInclContiSub =DetailedScenarioNodeList[22] !== undefined ? (DetailedScenarioNodeList[22].childNodes[0] as HTMLElement) : elem
    let MarginInclContiTotalRes =DetailedScenarioNodeList[23] !== undefined ? (DetailedScenarioNodeList[23].childNodes[0] as HTMLElement) : elem
    let MarginInclContiTotalinclFullyloadedGDSCost =DetailedScenarioNodeList[24] !== undefined ? (DetailedScenarioNodeList[24].childNodes[0] as HTMLElement) : elem
    let MarginExclContiEyOnshore = DetailedScenarioNodeList[25] !== undefined ? (DetailedScenarioNodeList[25].childNodes[0] as HTMLElement) : elem
    let MarginExclContiGds = DetailedScenarioNodeList[26] !== undefined ? (DetailedScenarioNodeList[26].childNodes[0] as HTMLElement) : elem
    let MarginExclContiSub = DetailedScenarioNodeList[27] !== undefined ? (DetailedScenarioNodeList[27].childNodes[0] as HTMLElement) : elem
    let MarginExclContiTotalRes = DetailedScenarioNodeList[28] !== undefined ? (DetailedScenarioNodeList[28].childNodes[0] as HTMLElement) : elem
    let MarginExclContiTotalinclFullyloadedGDSCost = DetailedScenarioNodeList[29] !== undefined ? (DetailedScenarioNodeList[29].childNodes[0] as HTMLElement) : elem

    worksheet.addRow(['', '', '', '', '', '', '', '','','','',])
    worksheet.addRow(['Resources by Rank', '', '', '', 'Detailed Scenarios: Resources', '', '', '','','','',])
    worksheet.addRow(['', 'Hours', 'Cost', '', '', 'EY Onshore', 'GDS', 'Subcontractors','Total Resources','Total incl fully loaded GDS cost',''])
    worksheet.addRow([
      'Partner',
      partnerHrs.innerText,
      partnerCost.innerText,
      '',
      'Total Fees',
      TotalFeesEyOnshore.textContent,
      TotalFeesGds.textContent,
      TotalFeesSub.textContent,
      TotalFeesTotalResource.textContent,
      TotalinclFullyloadedGDSCost.textContent,
    ]);
    worksheet.addRow([
      'Executive Director',
      executiveHrs.innerText,
      executiveCost.innerText,
      '',
      'Labor Cost',
      LaborCostEyOnshore.textContent,
      LaborCostGds.textContent,
      LaborCostSub.textContent,
      LaborCostTotalResources.textContent,
      LaborCostTotalinclFullyloadedGDSCost.textContent,   
    ]);
    worksheet.addRow([
      'Senior Manager',
      seniorMgrHrs.innerText,
      seniorMgrCost.innerText,
      '',
      'Tech Uplift Cost',
      TechCostEyOnshore.textContent,
      TechCostGds.textContent,
      TechCostSub.textContent,
      TechCostTotalResource.textContent,
      TechCostTotalinclFullyloadedGDSCost.textContent,
    ]);
    worksheet.addRow([
      'Manager',
      MgrHrs.innerText,
      MgrCost.innerText,
      '',
      'Contingency',
      ContingencyEyOnshore.textContent,
      ContingencyGds.textContent,
      ContingencySub.textContent,
      ContingencyTotalResource.textContent,
      ContingencyTotalinclFullyloadedGDSCost.textContent,
    ]);
    worksheet.addRow([
      'Senior',
      seniorHrs.innerText,
      seniorCost.innerText,
      '',
      'EY Margin Incl. Conti.%', 
      MarginInclContiEyOnshore.textContent,
      MarginInclContiGds.textContent,
      MarginInclContiSub.textContent,
      MarginInclContiTotalRes.textContent,
      MarginInclContiTotalinclFullyloadedGDSCost.textContent,
    ]);
    worksheet.addRow([
      'Staff',
      staffHrs.innerText,
      staffCost.innerText,
      '',
      'EY Margin Excl. Conti.%', 
      MarginExclContiEyOnshore.textContent,
      MarginExclContiGds.textContent,
      MarginExclContiSub.textContent,
      MarginExclContiTotalRes.textContent,
      MarginExclContiTotalinclFullyloadedGDSCost.textContent,
    ]);
    worksheet.addRow([
      'Intern',
      internHrs.innerText,
      internCost.innerText,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
    ]);
    worksheet.addRow([
      'Client Serving Contractor',
      cscHrs.innerText,
      cscCost.innerText,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
    ]);
    worksheet.addRow([
      'Subcontractor',
      subHrs.innerText,
      subCost.innerText,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
    ]);
    worksheet.addRow(['Total', totHrs.innerText, totCost.innerText, '', '', '', '','','','',])
    worksheet.addRow(['', '', '', '', '', '', '', '','','',])

    const vendorNodeList = document.querySelectorAll('[class*="VendorOrSubcontractor_tr"]')
    let professionalCon = vendorNodeList[1] !== undefined ? (vendorNodeList[1].childNodes[1] as HTMLElement) : elem
    let thirdPartyCon = vendorNodeList[2] !== undefined ? (vendorNodeList[2].childNodes[1] as HTMLElement) : elem

    let alliancePartyCon = vendorNodeList[3] !== undefined ? (vendorNodeList[3].childNodes[1] as HTMLElement) : elem
    let ifms = vendorNodeList[4] !== undefined ? (vendorNodeList[4].childNodes[1] as HTMLElement) : elem
    let totalPartyCon = vendorNodeList[5] !== undefined ? (vendorNodeList[5].childNodes[1] as HTMLElement) : elem

      const leverageNodeList = document.querySelectorAll('[class*="LeverageBreakdown_tr"]')
    let partnerAllocation =
      leverageNodeList[1] !== undefined ? (leverageNodeList[1].childNodes[2] as HTMLElement) : elem
    let executiveAllocation =
      leverageNodeList[2] !== undefined ? (leverageNodeList[2].childNodes[2] as HTMLElement) : elem
    let seniorMgrAllocation =
      leverageNodeList[3] !== undefined ? (leverageNodeList[3].childNodes[2] as HTMLElement) : elem

    let mgrAllocation = leverageNodeList[4] !== undefined ? (leverageNodeList[4].childNodes[2] as HTMLElement) : elem

    let seniorAllocation = leverageNodeList[5] !== undefined ? (leverageNodeList[5].childNodes[2] as HTMLElement) : elem
    let staffAllocation = leverageNodeList[6] !== undefined ? (leverageNodeList[6].childNodes[2] as HTMLElement) : elem

    let internAllocation = leverageNodeList[7] !== undefined ? (leverageNodeList[7].childNodes[2] as HTMLElement) : elem

    let cscAllocation = leverageNodeList[8] !== undefined ? (leverageNodeList[8].childNodes[2] as HTMLElement) : elem

    let subAllocation = leverageNodeList[9] !== undefined ? (leverageNodeList[9].childNodes[2] as HTMLElement) : elem
    let totAllocation = leverageNodeList[10] !== undefined ? (leverageNodeList[10].childNodes[2] as HTMLElement) : elem


    worksheet.addRow(['Vendor / Subcontractor', '', '', '', 'Hour Breakdown', '', '', ''])
    worksheet.addRow(['', '', 'Cost', '', '', '', '', 'Allocation of hours'])

    worksheet.addRow([
      'Professional Consultants',
      '',
      professionalCon.innerText,
      '',
      'Partner',
      '',
      '',
      partnerAllocation.innerText
    ])
    worksheet.addRow([
      'Third Party Subcontractors',
      '',
      thirdPartyCon.innerText,
      '',
      'Executive Director',
      '',
      '',
      executiveAllocation.innerText
    ])
    worksheet.addRow([
      'Alliance Partner Fees', 
      '', 
      alliancePartyCon.innerText, 
      '', 
      'Senior Manager',
      '',
      '',
      seniorMgrAllocation.innerText
    ])
    worksheet.addRow([
      'IFMs', 
      '', 
      ifms.innerText, 
      '', 
      'Manager', 
      '', 
      '', 
      mgrAllocation.innerText
    ])
    worksheet.addRow([
      'Total', 
      '', 
      totalPartyCon.innerText, 
      '', 
      'Senior',
      '',
      '',
      seniorAllocation.innerText
    ])
    worksheet.addRow([
      '', 
      '', 
      '', 
      '', 
      'Staff', 
      '', 
      '', 
      staffAllocation.innerText
    ])
    worksheet.addRow([
      '', 
      '', 
      '', 
      '', 
      'Intern',
      '',
      '',
      internAllocation.innerText
    ])
    worksheet.addRow([
      '', 
      '', 
      '', 
      '', 
      'Client Serving Contractor',
      '',
      '',
      cscAllocation.innerText
    ])
    worksheet.addRow([
      '', 
      '', 
      '', 
      '', 
      'Subcontractor',
      '',
      '',
      subAllocation.innerText
    ])
    worksheet.addRow([
      '', 
      '', 
      '', 
      '', 
      '% of Total', 
      '', 
      '', 
      totAllocation.innerText
    ])
    worksheet.addRow(['', '', '', '', '', '', '', ''])

    let hotelCost = document.getElementsByClassName('td cost')[0] as HTMLElement
    let perDiemCost = document.getElementsByClassName('td cost')[1] as HTMLElement
    let flightCost = document.getElementsByClassName('td cost')[2] as HTMLElement
    let otherCost = document.getElementsByClassName('td cost')[3] as HTMLElement
    let otherTech = document.getElementsByClassName('td cost')[4] as HTMLElement
    let totalCost = document.getElementsByClassName('td cost')[5] as HTMLElement

    let ctProductCost = document.getElementsByClassName('bold technology-expense-item cost')[0] as HTMLElement
    let ctProductRevenue = document.getElementsByClassName('bold technology-expense-item')[1] as HTMLElement
    // let otherTechCost = document.getElementsByClassName('bold technology-expense-item cost')[1] as HTMLElement
    // let otherTechRevenue = document.getElementsByClassName('bold technology-expense-item')[1] as HTMLElement
    let totalTechCost = document.getElementsByClassName('bold technology-expense-item cost')[1] as HTMLElement
    let totalTechRevenue = document.getElementsByClassName('bold technology-expense-item')[1] as HTMLElement

    worksheet.addRow(['Travel and Other Related Expenses', '', '', '', 'CT Product Expenses', '', '', ''])
    worksheet.addRow(['', '', 'Cost', '', '', '','Cost', 'Revenue'])
    worksheet.addRow([
      'Hotel, Meals & Entertainment',
      '', 
      hotelCost.innerText, 
      '', 
      'CT Products (Direct Cost)',
      '',
      ctProductCost.innerText,
      ctProductRevenue.innerText
    ])
    worksheet.addRow([
      'Air/Ground Transportation', 
      '', 
      perDiemCost.innerText, 
      '', 
      'Total',
      '',
      totalTechCost.innerText,
      totalTechRevenue.innerText
    ])
    worksheet.addRow(['Other Travel Related', '', flightCost.innerText, '', '', '', '', ''])
    worksheet.addRow(['Other Lumpsum', '', otherCost.innerText, '', '', '', '', ''])
    worksheet.addRow(['Other Technology Expenses', '', otherTech.innerText, '', '', '', '', ''])
    worksheet.addRow(['Total', '', totalCost.innerText, '', '', '', '', ''])
    worksheet.addRow(['', '', '', '', '', '', '', ''])

    const workstreamList = document.querySelectorAll('[class="workstream-summary-table"]')

    //if (workstreamList[0].getElementsByClassName('th').length > 1) {
    if (
      pricingPlanData.summaryViewModel?.selectedScenario !== 'base' &&
      document.getElementsByClassName('col workstream-summary').length > 0
    ) {
      worksheet.addRow([
        'Workstream Summary',
        'Please note that the financial breakdown is an estimated ‘project’ view and does not reflect an EY internal revenue recognition ‘fee sharing’ view that Mercury provides.',
        '',
        '',
        '',
        '',
        '',
        '',
        ''
      ])
      let wsHeader1 =
        workstreamList[0].getElementsByClassName('th')[0] !== undefined
          ? (workstreamList[0].getElementsByClassName('th')[0] as HTMLElement)
          : elem
      let wsHeader2 =
        workstreamList[0].getElementsByClassName('th')[1] !== undefined
          ? (workstreamList[0].getElementsByClassName('th')[1] as HTMLElement)
          : elem
      let wsHeader3 =
        workstreamList[0].getElementsByClassName('th')[2] !== undefined
          ? (workstreamList[0].getElementsByClassName('th')[2] as HTMLElement)
          : elem
      let wsHeader4 =
        workstreamList[0].getElementsByClassName('th')[3] !== undefined
          ? (workstreamList[0].getElementsByClassName('th')[3] as HTMLElement)
          : elem

      let wsHeader5 =
        workstreamList[0].getElementsByClassName('th')[4] !== undefined
          ? (workstreamList[0].getElementsByClassName('th')[4] as HTMLElement)
          : elem
      let wsHeader6 =
        workstreamList[0].getElementsByClassName('th')[5] !== undefined
          ? (workstreamList[0].getElementsByClassName('th')[5] as HTMLElement)
          : elem
      let wsHeader7 =
        workstreamList[0].getElementsByClassName('th')[6] !== undefined
          ? (workstreamList[0].getElementsByClassName('th')[6] as HTMLElement)
          : elem
      let wsHeader8 =
        workstreamList[0].getElementsByClassName('th')[7] !== undefined
          ? (workstreamList[0].getElementsByClassName('th')[7] as HTMLElement)
          : elem

      let wsHeader9 =
        workstreamList[0].getElementsByClassName('th')[8] !== undefined
          ? (workstreamList[0].getElementsByClassName('th')[8] as HTMLElement)
          : elem
      //}

      let wsDivs = document.getElementsByClassName('workstream-summary-table')
      let wsDivTr =
        wsDivs[0].getElementsByClassName('tr')[1] !== undefined
          ? (wsDivs[0].getElementsByClassName('tr')[1] as HTMLElement)
          : elem
      let wsTdTotBillingAmt1 = wsDivTr.childNodes[1] !== undefined ? (wsDivTr.childNodes[1] as HTMLElement) : elem
      let wsTdTotBillingAmt2 = wsDivTr.childNodes[2] !== undefined ? (wsDivTr.childNodes[2] as HTMLElement) : elem
      let wsTdTotBillingAmt3 = wsDivTr.childNodes[3] !== undefined ? (wsDivTr.childNodes[3] as HTMLElement) : elem
      let wsTdTotBillingAmt4 = wsDivTr.childNodes[4] !== undefined ? (wsDivTr.childNodes[4] as HTMLElement) : elem
      let wsTdTotBillingAmt5 = wsDivTr.childNodes[5] !== undefined ? (wsDivTr.childNodes[5] as HTMLElement) : elem
      let wsTdTotBillingAmt6 = wsDivTr.childNodes[6] !== undefined ? (wsDivTr.childNodes[6] as HTMLElement) : elem
      let wsTdTotBillingAmt7 = wsDivTr.childNodes[7] !== undefined ? (wsDivTr.childNodes[7] as HTMLElement) : elem
      let wsTdTotBillingAmt8 = wsDivTr.childNodes[8] !== undefined ? (wsDivTr.childNodes[8] as HTMLElement) : elem

      let wsDivTr2 =
        wsDivs[0].getElementsByClassName('tr')[2] !== undefined
          ? (wsDivs[0].getElementsByClassName('tr')[2] as HTMLElement)
          : elem
      let wsTdTotExpenses1 = wsDivTr2.childNodes[1] !== undefined ? (wsDivTr2.childNodes[1] as HTMLElement) : elem
      let wsTdTotExpenses2 = wsDivTr2.childNodes[2] !== undefined ? (wsDivTr2.childNodes[2] as HTMLElement) : elem
      let wsTdTotExpenses3 = wsDivTr2.childNodes[3] !== undefined ? (wsDivTr2.childNodes[3] as HTMLElement) : elem
      let wsTdTotExpenses4 = wsDivTr2.childNodes[4] !== undefined ? (wsDivTr2.childNodes[4] as HTMLElement) : elem
      let wsTdTotExpenses5 = wsDivTr2.childNodes[5] !== undefined ? (wsDivTr2.childNodes[5] as HTMLElement) : elem
      let wsTdTotExpenses6 = wsDivTr2.childNodes[6] !== undefined ? (wsDivTr2.childNodes[6] as HTMLElement) : elem
      let wsTdTotExpenses7 = wsDivTr2.childNodes[7] !== undefined ? (wsDivTr2.childNodes[7] as HTMLElement) : elem
      let wsTdTotExpenses8 = wsDivTr2.childNodes[8] !== undefined ? (wsDivTr2.childNodes[8] as HTMLElement) : elem

      let wsDivTr3 =
        wsDivs[0].getElementsByClassName('tr')[3] !== undefined
          ? (wsDivs[0].getElementsByClassName('tr')[3] as HTMLElement)
          : elem
      let wsTdBillExclExpenses1 = wsDivTr3.childNodes[1] !== undefined ? (wsDivTr3.childNodes[1] as HTMLElement) : elem
      let wsTdBillExclExpenses2 = wsDivTr3.childNodes[2] !== undefined ? (wsDivTr3.childNodes[2] as HTMLElement) : elem
      let wsTdBillExclExpenses3 = wsDivTr3.childNodes[3] !== undefined ? (wsDivTr3.childNodes[3] as HTMLElement) : elem
      let wsTdBillExclExpenses4 = wsDivTr3.childNodes[4] !== undefined ? (wsDivTr3.childNodes[4] as HTMLElement) : elem
      let wsTdBillExclExpenses5 = wsDivTr3.childNodes[5] !== undefined ? (wsDivTr3.childNodes[5] as HTMLElement) : elem
      let wsTdBillExclExpenses6 = wsDivTr3.childNodes[6] !== undefined ? (wsDivTr3.childNodes[6] as HTMLElement) : elem
      let wsTdBillExclExpenses7 = wsDivTr3.childNodes[7] !== undefined ? (wsDivTr3.childNodes[7] as HTMLElement) : elem
      let wsTdBillExclExpenses8 = wsDivTr3.childNodes[8] !== undefined ? (wsDivTr3.childNodes[8] as HTMLElement) : elem

      let wsDivTr4 =
        wsDivs[0].getElementsByClassName('tr')[4] !== undefined
          ? (wsDivs[0].getElementsByClassName('tr')[4] as HTMLElement)
          : elem
      let wsTdTotLaborCost1 = wsDivTr4.childNodes[1] !== undefined ? (wsDivTr4.childNodes[1] as HTMLElement) : elem
      let wsTdTotLaborCost2 = wsDivTr4.childNodes[2] !== undefined ? (wsDivTr4.childNodes[2] as HTMLElement) : elem
      let wsTdTotLaborCost3 = wsDivTr4.childNodes[3] !== undefined ? (wsDivTr4.childNodes[3] as HTMLElement) : elem
      let wsTdTotLaborCost4 = wsDivTr4.childNodes[4] !== undefined ? (wsDivTr4.childNodes[4] as HTMLElement) : elem
      let wsTdTotLaborCost5 = wsDivTr4.childNodes[5] !== undefined ? (wsDivTr4.childNodes[5] as HTMLElement) : elem
      let wsTdTotLaborCost6 = wsDivTr4.childNodes[6] !== undefined ? (wsDivTr4.childNodes[6] as HTMLElement) : elem
      let wsTdTotLaborCost7 = wsDivTr4.childNodes[7] !== undefined ? (wsDivTr4.childNodes[7] as HTMLElement) : elem
      let wsTdTotLaborCost8 = wsDivTr4.childNodes[8] !== undefined ? (wsDivTr4.childNodes[8] as HTMLElement) : elem

      let wsDivTr5 =
        wsDivs[0].getElementsByClassName('tr')[5] !== undefined
          ? (wsDivs[0].getElementsByClassName('tr')[5] as HTMLElement)
          : elem
      let wsTdSubCost1 = wsDivTr5.childNodes[1] !== undefined ? (wsDivTr5.childNodes[1] as HTMLElement) : elem
      let wsTdSubCost2 = wsDivTr5.childNodes[2] !== undefined ? (wsDivTr5.childNodes[2] as HTMLElement) : elem
      let wsTdSubCost3 = wsDivTr5.childNodes[3] !== undefined ? (wsDivTr5.childNodes[3] as HTMLElement) : elem
      let wsTdSubCost4 = wsDivTr5.childNodes[4] !== undefined ? (wsDivTr5.childNodes[4] as HTMLElement) : elem
      let wsTdSubCost5 = wsDivTr5.childNodes[5] !== undefined ? (wsDivTr5.childNodes[5] as HTMLElement) : elem
      let wsTdSubCost6 = wsDivTr5.childNodes[6] !== undefined ? (wsDivTr5.childNodes[6] as HTMLElement) : elem
      let wsTdSubCost7 = wsDivTr5.childNodes[7] !== undefined ? (wsDivTr5.childNodes[7] as HTMLElement) : elem
      let wsTdSubCost8 = wsDivTr5.childNodes[8] !== undefined ? (wsDivTr5.childNodes[8] as HTMLElement) : elem

      let wsDivTr6 =
        wsDivs[0].getElementsByClassName('tr')[6] !== undefined
          ? (wsDivs[0].getElementsByClassName('tr')[6] as HTMLElement)
          : elem
      let wsTdEYMargin1 = wsDivTr6.childNodes[1] !== undefined ? (wsDivTr6.childNodes[1] as HTMLElement) : elem
      let wsTdEYMargin2 = wsDivTr6.childNodes[2] !== undefined ? (wsDivTr6.childNodes[2] as HTMLElement) : elem
      let wsTdEYMargin3 = wsDivTr6.childNodes[3] !== undefined ? (wsDivTr6.childNodes[3] as HTMLElement) : elem
      let wsTdEYMargin4 = wsDivTr6.childNodes[4] !== undefined ? (wsDivTr6.childNodes[4] as HTMLElement) : elem
      let wsTdEYMargin5 = wsDivTr6.childNodes[5] !== undefined ? (wsDivTr6.childNodes[5] as HTMLElement) : elem
      let wsTdEYMargin6 = wsDivTr6.childNodes[6] !== undefined ? (wsDivTr6.childNodes[6] as HTMLElement) : elem
      let wsTdEYMargin7 = wsDivTr6.childNodes[7] !== undefined ? (wsDivTr6.childNodes[7] as HTMLElement) : elem
      let wsTdEYMargin8 = wsDivTr6.childNodes[8] !== undefined ? (wsDivTr6.childNodes[8] as HTMLElement) : elem

      let wsDivTr7 =
        wsDivs[0].getElementsByClassName('tr')[7] !== undefined
          ? (wsDivs[0].getElementsByClassName('tr')[7] as HTMLElement)
          : elem
      let wsTdEYMarginDcr1 = wsDivTr7.childNodes[1] !== undefined ? (wsDivTr7.childNodes[1] as HTMLElement) : elem
      let wsTdEYMarginDcr2 = wsDivTr7.childNodes[2] !== undefined ? (wsDivTr7.childNodes[2] as HTMLElement) : elem
      let wsTdEYMarginDcr3 = wsDivTr7.childNodes[3] !== undefined ? (wsDivTr7.childNodes[3] as HTMLElement) : elem
      let wsTdEYMarginDcr4 = wsDivTr7.childNodes[4] !== undefined ? (wsDivTr7.childNodes[4] as HTMLElement) : elem
      let wsTdEYMarginDcr5 = wsDivTr7.childNodes[5] !== undefined ? (wsDivTr7.childNodes[5] as HTMLElement) : elem
      let wsTdEYMarginDcr6 = wsDivTr7.childNodes[6] !== undefined ? (wsDivTr7.childNodes[6] as HTMLElement) : elem
      let wsTdEYMarginDcr7 = wsDivTr7.childNodes[7] !== undefined ? (wsDivTr7.childNodes[7] as HTMLElement) : elem
      let wsTdEYMarginDcr8 = wsDivTr7.childNodes[8] !== undefined ? (wsDivTr7.childNodes[8] as HTMLElement) : elem

      let wsDivTr8 =
        wsDivs[0].getElementsByClassName('tr')[8] !== undefined
          ? (wsDivs[0].getElementsByClassName('tr')[8] as HTMLElement)
          : elem
      let wsTdEYMarginPtnr1 = wsDivTr8.childNodes[1] !== undefined ? (wsDivTr8.childNodes[1] as HTMLElement) : elem
      let wsTdEYMarginPtnr2 = wsDivTr8.childNodes[2] !== undefined ? (wsDivTr8.childNodes[2] as HTMLElement) : elem
      let wsTdEYMarginPtnr3 = wsDivTr8.childNodes[3] !== undefined ? (wsDivTr8.childNodes[3] as HTMLElement) : elem
      let wsTdEYMarginPtnr4 = wsDivTr8.childNodes[4] !== undefined ? (wsDivTr8.childNodes[4] as HTMLElement) : elem
      let wsTdEYMarginPtnr5 = wsDivTr8.childNodes[5] !== undefined ? (wsDivTr8.childNodes[5] as HTMLElement) : elem
      let wsTdEYMarginPtnr6 = wsDivTr8.childNodes[6] !== undefined ? (wsDivTr8.childNodes[6] as HTMLElement) : elem
      let wsTdEYMarginPtnr7 = wsDivTr8.childNodes[7] !== undefined ? (wsDivTr8.childNodes[7] as HTMLElement) : elem
      let wsTdEYMarginPtnr8 = wsDivTr8.childNodes[8] !== undefined ? (wsDivTr8.childNodes[8] as HTMLElement) : elem

      let wsDivTr9 =
        wsDivs[0].getElementsByClassName('tr')[9] !== undefined
          ? (wsDivs[0].getElementsByClassName('tr')[9] as HTMLElement)
          : elem
      let wsTdEYMarginDcrPtnr1 = wsDivTr9.childNodes[1] !== undefined ? (wsDivTr9.childNodes[1] as HTMLElement) : elem
      let wsTdEYMarginDcrPtnr2 = wsDivTr9.childNodes[2] !== undefined ? (wsDivTr9.childNodes[2] as HTMLElement) : elem
      let wsTdEYMarginDcrPtnr3 = wsDivTr9.childNodes[3] !== undefined ? (wsDivTr9.childNodes[3] as HTMLElement) : elem
      let wsTdEYMarginDcrPtnr4 = wsDivTr9.childNodes[4] !== undefined ? (wsDivTr9.childNodes[4] as HTMLElement) : elem
      let wsTdEYMarginDcrPtnr5 = wsDivTr9.childNodes[5] !== undefined ? (wsDivTr9.childNodes[5] as HTMLElement) : elem
      let wsTdEYMarginDcrPtnr6 = wsDivTr9.childNodes[6] !== undefined ? (wsDivTr9.childNodes[6] as HTMLElement) : elem
      let wsTdEYMarginDcrPtnr7 = wsDivTr9.childNodes[7] !== undefined ? (wsDivTr9.childNodes[7] as HTMLElement) : elem
      let wsTdEYMarginDcrPtnr8 = wsDivTr9.childNodes[8] !== undefined ? (wsDivTr9.childNodes[8] as HTMLElement) : elem

      let wsDivTr10 =
        wsDivs[0].getElementsByClassName('tr')[10] !== undefined
          ? (wsDivs[0].getElementsByClassName('tr')[10] as HTMLElement)
          : elem
      let wsTdRateDay1 = wsDivTr10.childNodes[1] !== undefined ? (wsDivTr10.childNodes[1] as HTMLElement) : elem
      let wsTdRateDay2 = wsDivTr10.childNodes[2] !== undefined ? (wsDivTr10.childNodes[2] as HTMLElement) : elem
      let wsTdRateDay3 = wsDivTr10.childNodes[3] !== undefined ? (wsDivTr10.childNodes[3] as HTMLElement) : elem
      let wsTdRateDay4 = wsDivTr10.childNodes[4] !== undefined ? (wsDivTr10.childNodes[4] as HTMLElement) : elem
      let wsTdRateDay5 = wsDivTr10.childNodes[5] !== undefined ? (wsDivTr10.childNodes[5] as HTMLElement) : elem
      let wsTdRateDay6 = wsDivTr10.childNodes[6] !== undefined ? (wsDivTr10.childNodes[6] as HTMLElement) : elem
      let wsTdRateDay7 = wsDivTr10.childNodes[7] !== undefined ? (wsDivTr10.childNodes[7] as HTMLElement) : elem
      let wsTdRateDay8 = wsDivTr10.childNodes[8] !== undefined ? (wsDivTr10.childNodes[8] as HTMLElement) : elem

      let wsDivTr11 =
        wsDivs[0].getElementsByClassName('tr')[11] !== undefined
          ? (wsDivs[0].getElementsByClassName('tr')[11] as HTMLElement)
          : elem
      let wsTdRateHr1 = wsDivTr11.childNodes[1] !== undefined ? (wsDivTr11.childNodes[1] as HTMLElement) : elem
      let wsTdRateHr2 = wsDivTr11.childNodes[2] !== undefined ? (wsDivTr11.childNodes[2] as HTMLElement) : elem
      let wsTdRateHr3 = wsDivTr11.childNodes[3] !== undefined ? (wsDivTr11.childNodes[3] as HTMLElement) : elem
      let wsTdRateHr4 = wsDivTr11.childNodes[4] !== undefined ? (wsDivTr11.childNodes[4] as HTMLElement) : elem
      let wsTdRateHr5 = wsDivTr11.childNodes[5] !== undefined ? (wsDivTr11.childNodes[5] as HTMLElement) : elem
      let wsTdRateHr6 = wsDivTr11.childNodes[6] !== undefined ? (wsDivTr11.childNodes[6] as HTMLElement) : elem
      let wsTdRateHr7 = wsDivTr11.childNodes[7] !== undefined ? (wsDivTr11.childNodes[7] as HTMLElement) : elem
      let wsTdRateHr8 = wsDivTr11.childNodes[8] !== undefined ? (wsDivTr11.childNodes[8] as HTMLElement) : elem
      worksheet.addRow([
        wsHeader1.innerText,
        wsHeader2.innerText,
        wsHeader3.innerText,
        wsHeader4.innerText,
        wsHeader5.innerText,
        wsHeader6.innerText,
        wsHeader7.innerText,
        wsHeader8.innerText,
        wsHeader9.innerText
      ])
      worksheet.addRow([
        'Total Billing Amount',
        wsTdTotBillingAmt1.innerText,
        wsTdTotBillingAmt2.innerText,
        wsTdTotBillingAmt3.innerText,
        wsTdTotBillingAmt4.innerText,
        wsTdTotBillingAmt5.innerText,
        wsTdTotBillingAmt6.innerText,
        wsTdTotBillingAmt7.innerText,
        wsTdTotBillingAmt8.innerText
      ])

      worksheet.addRow([
        'Total Expenses',
        wsTdTotExpenses1.innerText,
        wsTdTotExpenses2.innerText,
        wsTdTotExpenses3.innerText,
        wsTdTotExpenses4.innerText,
        wsTdTotExpenses5.innerText,
        wsTdTotExpenses6.innerText,
        wsTdTotExpenses7.innerText,
        wsTdTotExpenses8.innerText
      ])

      worksheet.addRow([
        'Billing Excluding Expenses',
        wsTdBillExclExpenses1.innerText,
        wsTdBillExclExpenses2.innerText,
        wsTdBillExclExpenses3.innerText,
        wsTdBillExclExpenses4.innerText,
        wsTdBillExclExpenses5.innerText,
        wsTdBillExclExpenses6.innerText,
        wsTdBillExclExpenses7.innerText,
        wsTdBillExclExpenses8.innerText
      ])

      worksheet.addRow([
        'Total Labor & CT Cost',
        wsTdTotLaborCost1.innerText,
        wsTdTotLaborCost2.innerText,
        wsTdTotLaborCost3.innerText,
        wsTdTotLaborCost4.innerText,
        wsTdTotLaborCost5.innerText,
        wsTdTotLaborCost6.innerText,
        wsTdTotLaborCost7.innerText,
        wsTdTotLaborCost8.innerText
      ])

      worksheet.addRow([
        'Cost of Subcontractors',
        wsTdSubCost1.innerText,
        wsTdSubCost2.innerText,
        wsTdSubCost3.innerText,
        wsTdSubCost4.innerText,
        wsTdSubCost5.innerText,
        wsTdSubCost6.innerText,
        wsTdSubCost7.innerText,
        wsTdSubCost8.innerText
      ])

      worksheet.addRow([
        'EY Margin %',
        wsTdEYMargin1.innerText,
        wsTdEYMargin2.innerText,
        wsTdEYMargin3.innerText,
        wsTdEYMargin4.innerText,
        wsTdEYMargin5.innerText,
        wsTdEYMargin6.innerText,
        wsTdEYMargin7.innerText,
        wsTdEYMargin8.innerText
      ])

      worksheet.addRow([
        'EY Margin % using DCR',
        wsTdEYMarginDcr1.innerText,
        wsTdEYMarginDcr2.innerText,
        wsTdEYMarginDcr3.innerText,
        wsTdEYMarginDcr4.innerText,
        wsTdEYMarginDcr5.innerText,
        wsTdEYMarginDcr6.innerText,
        wsTdEYMarginDcr7.innerText,
        wsTdEYMarginDcr8.innerText
      ])

      worksheet.addRow([
        'EY Margin % Excl. Partner Costs',
        wsTdEYMarginPtnr1.innerText,
        wsTdEYMarginPtnr2.innerText,
        wsTdEYMarginPtnr3.innerText,
        wsTdEYMarginPtnr4.innerText,
        wsTdEYMarginPtnr5.innerText,
        wsTdEYMarginPtnr6.innerText,
        wsTdEYMarginPtnr7.innerText,
        wsTdEYMarginPtnr8.innerText
      ])

      worksheet.addRow([
        'EY Margin % using DCR Excl. Partner Costs',
        wsTdEYMarginDcrPtnr1.innerText,
        wsTdEYMarginDcrPtnr2.innerText,
        wsTdEYMarginDcrPtnr3.innerText,
        wsTdEYMarginDcrPtnr4.innerText,
        wsTdEYMarginDcrPtnr5.innerText,
        wsTdEYMarginDcrPtnr6.innerText,
        wsTdEYMarginDcrPtnr7.innerText,
        wsTdEYMarginDcrPtnr8.innerText
      ])

      worksheet.addRow([
        'Rate / Day',
        wsTdRateDay1.innerText,
        wsTdRateDay2.innerText,
        wsTdRateDay3.innerText,
        wsTdRateDay4.innerText,
        wsTdRateDay5.innerText,
        wsTdRateDay6.innerText,
        wsTdRateDay7.innerText,
        wsTdRateDay8.innerText
      ])

      worksheet.addRow([
        'Rate / Hour',
        wsTdRateHr1.innerText,
        wsTdRateHr2.innerText,
        wsTdRateHr3.innerText,
        wsTdRateHr4.innerText,
        wsTdRateHr5.innerText,
        wsTdRateHr6.innerText,
        wsTdRateHr7.innerText,
        wsTdRateHr8.innerText
      ])
    }

    worksheet.addRow(['', '', '', '', '', '', '', ''])

    const phasesList = document.querySelectorAll('[class="phases-summary-table"]')

    //if (workstreamList[0].getElementsByClassName('th').length > 1) {

    if (pricingPlanData.summaryViewModel?.selectedScenario === 'base') {
      worksheet.addRow(['Workstream Summary', '', '', '', '', '', '', ''])
      worksheet.addRow(['This section is in finalisation and will be available shortly.', '', '', '', '', '', '', ''])
      worksheet.addRow(['', '', '', '', '', '', '', ''])
      worksheet.addRow(['Phases Summary', '', '', '', '', '', '', ''])
      worksheet.addRow(['This section is in finalisation and will be available shortly.', '', '', '', '', '', '', ''])
    }
    if (
      pricingPlanData.summaryViewModel?.selectedScenario !== 'base' &&
      document.getElementsByClassName('col phases-summary').length > 0
    ) {
      worksheet.addRow([
        'Phases Summary',
        'Please note that the financial breakdown is an estimated ‘project’ view and does not reflect an EY internal revenue recognition ‘fee sharing’ view that Mercury provides.',
        '',
        '',
        '',
        '',
        '',
        ''
      ])
      let phaseHeader1 =
        phasesList[0].getElementsByClassName('th')[0] !== undefined
          ? (phasesList[0].getElementsByClassName('th')[0] as HTMLElement)
          : elem
      let phaseHeader2 =
        phasesList[0].getElementsByClassName('th')[1] !== undefined
          ? (phasesList[0].getElementsByClassName('th')[1] as HTMLElement)
          : elem
      let phaseHeader3 =
        phasesList[0].getElementsByClassName('th')[2] !== undefined
          ? (phasesList[0].getElementsByClassName('th')[2] as HTMLElement)
          : elem
      let phaseHeader4 =
        phasesList[0].getElementsByClassName('th')[3] !== undefined
          ? (phasesList[0].getElementsByClassName('th')[3] as HTMLElement)
          : elem

      let phaseHeader5 =
        phasesList[0].getElementsByClassName('th')[4] !== undefined
          ? (phasesList[0].getElementsByClassName('th')[4] as HTMLElement)
          : elem
      let phaseHeader6 =
        phasesList[0].getElementsByClassName('th')[5] !== undefined
          ? (phasesList[0].getElementsByClassName('th')[5] as HTMLElement)
          : elem
      let phaseHeader7 =
        phasesList[0].getElementsByClassName('th')[6] !== undefined
          ? (phasesList[0].getElementsByClassName('th')[6] as HTMLElement)
          : elem
      let phaseHeader8 =
        phasesList[0].getElementsByClassName('th')[7] !== undefined
          ? (phasesList[0].getElementsByClassName('th')[7] as HTMLElement)
          : elem
      let phaseHeader9 =
        phasesList[0].getElementsByClassName('th')[8] !== undefined
          ? (phasesList[0].getElementsByClassName('th')[8] as HTMLElement)
          : elem
      let phaseDivs = document.getElementsByClassName('phases-summary-table')
      let phaseDivTr =
        phaseDivs[0].getElementsByClassName('tr')[1] !== undefined
          ? (phaseDivs[0].getElementsByClassName('tr')[1] as HTMLElement)
          : elem
      let phaseTdTotBillingAmt1 =
        phaseDivTr.childNodes[1] !== undefined ? (phaseDivTr.childNodes[1] as HTMLElement) : elem
      let phaseTdTotBillingAmt2 =
        phaseDivTr.childNodes[2] !== undefined ? (phaseDivTr.childNodes[2] as HTMLElement) : elem
      let phaseTdTotBillingAmt3 =
        phaseDivTr.childNodes[3] !== undefined ? (phaseDivTr.childNodes[3] as HTMLElement) : elem
      let phaseTdTotBillingAmt4 =
        phaseDivTr.childNodes[4] !== undefined ? (phaseDivTr.childNodes[4] as HTMLElement) : elem
      let phaseTdTotBillingAmt5 =
        phaseDivTr.childNodes[5] !== undefined ? (phaseDivTr.childNodes[5] as HTMLElement) : elem
      let phaseTdTotBillingAmt6 =
        phaseDivTr.childNodes[6] !== undefined ? (phaseDivTr.childNodes[6] as HTMLElement) : elem
      let phaseTdTotBillingAmt7 =
        phaseDivTr.childNodes[7] !== undefined ? (phaseDivTr.childNodes[7] as HTMLElement) : elem
      let phaseTdTotBillingAmt8 =
        phaseDivTr.childNodes[8] !== undefined ? (phaseDivTr.childNodes[8] as HTMLElement) : elem

      let phaseDivTr2 =
        phaseDivs[0].getElementsByClassName('tr')[2] !== undefined
          ? (phaseDivs[0].getElementsByClassName('tr')[2] as HTMLElement)
          : elem
      let phaseTdTotExpenses1 =
        phaseDivTr2.childNodes[1] !== undefined ? (phaseDivTr2.childNodes[1] as HTMLElement) : elem
      let phaseTdTotExpenses2 =
        phaseDivTr2.childNodes[2] !== undefined ? (phaseDivTr2.childNodes[2] as HTMLElement) : elem
      let phaseTdTotExpenses3 =
        phaseDivTr2.childNodes[3] !== undefined ? (phaseDivTr2.childNodes[3] as HTMLElement) : elem
      let phaseTdTotExpenses4 =
        phaseDivTr2.childNodes[4] !== undefined ? (phaseDivTr2.childNodes[4] as HTMLElement) : elem
      let phaseTdTotExpenses5 =
        phaseDivTr2.childNodes[5] !== undefined ? (phaseDivTr2.childNodes[5] as HTMLElement) : elem
      let phaseTdTotExpenses6 =
        phaseDivTr2.childNodes[6] !== undefined ? (phaseDivTr2.childNodes[6] as HTMLElement) : elem
      let phaseTdTotExpenses7 =
        phaseDivTr2.childNodes[7] !== undefined ? (phaseDivTr2.childNodes[7] as HTMLElement) : elem
      let phaseTdTotExpenses8 =
        phaseDivTr2.childNodes[8] !== undefined ? (phaseDivTr2.childNodes[8] as HTMLElement) : elem

      let phaseDivTr3 =
        phaseDivs[0].getElementsByClassName('tr')[3] !== undefined
          ? (phaseDivs[0].getElementsByClassName('tr')[3] as HTMLElement)
          : elem
      let phaseTdBillExclExpenses1 =
        phaseDivTr3.childNodes[1] !== undefined ? (phaseDivTr3.childNodes[1] as HTMLElement) : elem
      let phaseTdBillExclExpenses2 =
        phaseDivTr3.childNodes[2] !== undefined ? (phaseDivTr3.childNodes[2] as HTMLElement) : elem
      let phaseTdBillExclExpenses3 =
        phaseDivTr3.childNodes[3] !== undefined ? (phaseDivTr3.childNodes[3] as HTMLElement) : elem
      let phaseTdBillExclExpenses4 =
        phaseDivTr3.childNodes[4] !== undefined ? (phaseDivTr3.childNodes[4] as HTMLElement) : elem
      let phaseTdBillExclExpenses5 =
        phaseDivTr3.childNodes[5] !== undefined ? (phaseDivTr3.childNodes[5] as HTMLElement) : elem
      let phaseTdBillExclExpenses6 =
        phaseDivTr3.childNodes[6] !== undefined ? (phaseDivTr3.childNodes[6] as HTMLElement) : elem
      let phaseTdBillExclExpenses7 =
        phaseDivTr3.childNodes[7] !== undefined ? (phaseDivTr3.childNodes[7] as HTMLElement) : elem
      let phaseTdBillExclExpenses8 =
        phaseDivTr3.childNodes[8] !== undefined ? (phaseDivTr3.childNodes[8] as HTMLElement) : elem

      let phaseDivTr4 =
        phaseDivs[0].getElementsByClassName('tr')[4] !== undefined
          ? (phaseDivs[0].getElementsByClassName('tr')[4] as HTMLElement)
          : elem
      let phaseTdTotLaborCost1 =
        phaseDivTr4.childNodes[1] !== undefined ? (phaseDivTr4.childNodes[1] as HTMLElement) : elem
      let phaseTdTotLaborCost2 =
        phaseDivTr4.childNodes[2] !== undefined ? (phaseDivTr4.childNodes[2] as HTMLElement) : elem
      let phaseTdTotLaborCost3 =
        phaseDivTr4.childNodes[3] !== undefined ? (phaseDivTr4.childNodes[3] as HTMLElement) : elem
      let phaseTdTotLaborCost4 =
        phaseDivTr4.childNodes[4] !== undefined ? (phaseDivTr4.childNodes[4] as HTMLElement) : elem
      let phaseTdTotLaborCost5 =
        phaseDivTr4.childNodes[5] !== undefined ? (phaseDivTr4.childNodes[5] as HTMLElement) : elem
      let phaseTdTotLaborCost6 =
        phaseDivTr4.childNodes[6] !== undefined ? (phaseDivTr4.childNodes[6] as HTMLElement) : elem
      let phaseTdTotLaborCost7 =
        phaseDivTr4.childNodes[7] !== undefined ? (phaseDivTr4.childNodes[7] as HTMLElement) : elem
      let phaseTdTotLaborCost8 =
        phaseDivTr4.childNodes[8] !== undefined ? (phaseDivTr4.childNodes[8] as HTMLElement) : elem

      let phaseDivTr5 =
        phaseDivs[0].getElementsByClassName('tr')[5] !== undefined
          ? (phaseDivs[0].getElementsByClassName('tr')[5] as HTMLElement)
          : elem
      let phaseTdSubCost1 = phaseDivTr5.childNodes[1] !== undefined ? (phaseDivTr5.childNodes[1] as HTMLElement) : elem
      let phaseTdSubCost2 = phaseDivTr5.childNodes[2] !== undefined ? (phaseDivTr5.childNodes[2] as HTMLElement) : elem
      let phaseTdSubCost3 = phaseDivTr5.childNodes[3] !== undefined ? (phaseDivTr5.childNodes[3] as HTMLElement) : elem
      let phaseTdSubCost4 = phaseDivTr5.childNodes[4] !== undefined ? (phaseDivTr5.childNodes[4] as HTMLElement) : elem
      let phaseTdSubCost5 = phaseDivTr5.childNodes[5] !== undefined ? (phaseDivTr5.childNodes[5] as HTMLElement) : elem
      let phaseTdSubCost6 = phaseDivTr5.childNodes[6] !== undefined ? (phaseDivTr5.childNodes[6] as HTMLElement) : elem
      let phaseTdSubCost7 = phaseDivTr5.childNodes[7] !== undefined ? (phaseDivTr5.childNodes[7] as HTMLElement) : elem
      let phaseTdSubCost8 = phaseDivTr5.childNodes[8] !== undefined ? (phaseDivTr5.childNodes[8] as HTMLElement) : elem

      let phaseDivTr6 =
        phaseDivs[0].getElementsByClassName('tr')[6] !== undefined
          ? (phaseDivs[0].getElementsByClassName('tr')[6] as HTMLElement)
          : elem
      let phaseTdEYMargin1 = phaseDivTr6.childNodes[1] !== undefined ? (phaseDivTr6.childNodes[1] as HTMLElement) : elem
      let phaseTdEYMargin2 = phaseDivTr6.childNodes[2] !== undefined ? (phaseDivTr6.childNodes[2] as HTMLElement) : elem
      let phaseTdEYMargin3 = phaseDivTr6.childNodes[3] !== undefined ? (phaseDivTr6.childNodes[3] as HTMLElement) : elem
      let phaseTdEYMargin4 = phaseDivTr6.childNodes[4] !== undefined ? (phaseDivTr6.childNodes[4] as HTMLElement) : elem
      let phaseTdEYMargin5 = phaseDivTr6.childNodes[5] !== undefined ? (phaseDivTr6.childNodes[5] as HTMLElement) : elem
      let phaseTdEYMargin6 = phaseDivTr6.childNodes[6] !== undefined ? (phaseDivTr6.childNodes[6] as HTMLElement) : elem
      let phaseTdEYMargin7 = phaseDivTr6.childNodes[7] !== undefined ? (phaseDivTr6.childNodes[7] as HTMLElement) : elem
      let phaseTdEYMargin8 = phaseDivTr6.childNodes[8] !== undefined ? (phaseDivTr6.childNodes[8] as HTMLElement) : elem

      let phaseDivTr7 =
        phaseDivs[0].getElementsByClassName('tr')[7] !== undefined
          ? (phaseDivs[0].getElementsByClassName('tr')[7] as HTMLElement)
          : elem
      let phaseTdEYMarginDcr1 =
        phaseDivTr7.childNodes[1] !== undefined ? (phaseDivTr7.childNodes[1] as HTMLElement) : elem
      let phaseTdEYMarginDcr2 =
        phaseDivTr7.childNodes[2] !== undefined ? (phaseDivTr7.childNodes[2] as HTMLElement) : elem
      let phaseTdEYMarginDcr3 =
        phaseDivTr7.childNodes[3] !== undefined ? (phaseDivTr7.childNodes[3] as HTMLElement) : elem
      let phaseTdEYMarginDcr4 =
        phaseDivTr7.childNodes[4] !== undefined ? (phaseDivTr7.childNodes[4] as HTMLElement) : elem
      let phaseTdEYMarginDcr5 =
        phaseDivTr7.childNodes[5] !== undefined ? (phaseDivTr7.childNodes[5] as HTMLElement) : elem
      let phaseTdEYMarginDcr6 =
        phaseDivTr7.childNodes[6] !== undefined ? (phaseDivTr7.childNodes[6] as HTMLElement) : elem
      let phaseTdEYMarginDcr7 =
        phaseDivTr7.childNodes[7] !== undefined ? (phaseDivTr7.childNodes[7] as HTMLElement) : elem
      let phaseTdEYMarginDcr8 =
        phaseDivTr7.childNodes[8] !== undefined ? (phaseDivTr7.childNodes[8] as HTMLElement) : elem

      let phaseDivTr8 =
        phaseDivs[0].getElementsByClassName('tr')[8] !== undefined
          ? (phaseDivs[0].getElementsByClassName('tr')[8] as HTMLElement)
          : elem
      let phaseTdEYMarginPtnr1 =
        phaseDivTr8.childNodes[1] !== undefined ? (phaseDivTr8.childNodes[1] as HTMLElement) : elem
      let phaseTdEYMarginPtnr2 =
        phaseDivTr8.childNodes[2] !== undefined ? (phaseDivTr8.childNodes[2] as HTMLElement) : elem
      let phaseTdEYMarginPtnr3 =
        phaseDivTr8.childNodes[3] !== undefined ? (phaseDivTr8.childNodes[3] as HTMLElement) : elem
      let phaseTdEYMarginPtnr4 =
        phaseDivTr8.childNodes[4] !== undefined ? (phaseDivTr8.childNodes[4] as HTMLElement) : elem
      let phaseTdEYMarginPtnr5 =
        phaseDivTr8.childNodes[5] !== undefined ? (phaseDivTr8.childNodes[5] as HTMLElement) : elem
      let phaseTdEYMarginPtnr6 =
        phaseDivTr8.childNodes[6] !== undefined ? (phaseDivTr8.childNodes[6] as HTMLElement) : elem
      let phaseTdEYMarginPtnr7 =
        phaseDivTr8.childNodes[7] !== undefined ? (phaseDivTr8.childNodes[7] as HTMLElement) : elem
      let phaseTdEYMarginPtnr8 =
        phaseDivTr8.childNodes[8] !== undefined ? (phaseDivTr8.childNodes[8] as HTMLElement) : elem

      let phaseDivTr9 =
        phaseDivs[0].getElementsByClassName('tr')[9] !== undefined
          ? (phaseDivs[0].getElementsByClassName('tr')[9] as HTMLElement)
          : elem
      let phaseTdEYMarginDcrPtnr1 =
        phaseDivTr9.childNodes[1] !== undefined ? (phaseDivTr9.childNodes[1] as HTMLElement) : elem
      let phaseTdEYMarginDcrPtnr2 =
        phaseDivTr9.childNodes[2] !== undefined ? (phaseDivTr9.childNodes[2] as HTMLElement) : elem
      let phaseTdEYMarginDcrPtnr3 =
        phaseDivTr9.childNodes[3] !== undefined ? (phaseDivTr9.childNodes[3] as HTMLElement) : elem
      let phaseTdEYMarginDcrPtnr4 =
        phaseDivTr9.childNodes[4] !== undefined ? (phaseDivTr9.childNodes[4] as HTMLElement) : elem
      let phaseTdEYMarginDcrPtnr5 =
        phaseDivTr9.childNodes[5] !== undefined ? (phaseDivTr9.childNodes[5] as HTMLElement) : elem
      let phaseTdEYMarginDcrPtnr6 =
        phaseDivTr9.childNodes[6] !== undefined ? (phaseDivTr9.childNodes[6] as HTMLElement) : elem
      let phaseTdEYMarginDcrPtnr7 =
        phaseDivTr9.childNodes[7] !== undefined ? (phaseDivTr9.childNodes[7] as HTMLElement) : elem
      let phaseTdEYMarginDcrPtnr8 =
        phaseDivTr9.childNodes[8] !== undefined ? (phaseDivTr9.childNodes[8] as HTMLElement) : elem

      let phaseDivTr10 =
        phaseDivs[0].getElementsByClassName('tr')[10] !== undefined
          ? (phaseDivs[0].getElementsByClassName('tr')[10] as HTMLElement)
          : elem
      let phaseTdRateDay1 =
        phaseDivTr10.childNodes[1] !== undefined ? (phaseDivTr10.childNodes[1] as HTMLElement) : elem
      let phaseTdRateDay2 =
        phaseDivTr10.childNodes[2] !== undefined ? (phaseDivTr10.childNodes[2] as HTMLElement) : elem
      let phaseTdRateDay3 =
        phaseDivTr10.childNodes[3] !== undefined ? (phaseDivTr10.childNodes[3] as HTMLElement) : elem
      let phaseTdRateDay4 =
        phaseDivTr10.childNodes[4] !== undefined ? (phaseDivTr10.childNodes[4] as HTMLElement) : elem
      let phaseTdRateDay5 =
        phaseDivTr10.childNodes[5] !== undefined ? (phaseDivTr10.childNodes[5] as HTMLElement) : elem
      let phaseTdRateDay6 =
        phaseDivTr10.childNodes[6] !== undefined ? (phaseDivTr10.childNodes[6] as HTMLElement) : elem
      let phaseTdRateDay7 =
        phaseDivTr10.childNodes[7] !== undefined ? (phaseDivTr10.childNodes[7] as HTMLElement) : elem
      let phaseTdRateDay8 =
        phaseDivTr10.childNodes[8] !== undefined ? (phaseDivTr10.childNodes[8] as HTMLElement) : elem

      let phaseDivTr11 =
        phaseDivs[0].getElementsByClassName('tr')[11] !== undefined
          ? (phaseDivs[0].getElementsByClassName('tr')[11] as HTMLElement)
          : elem
      let phaseTdRateHr1 = phaseDivTr11.childNodes[1] !== undefined ? (phaseDivTr11.childNodes[1] as HTMLElement) : elem
      let phaseTdRateHr2 = phaseDivTr11.childNodes[2] !== undefined ? (phaseDivTr11.childNodes[2] as HTMLElement) : elem
      let phaseTdRateHr3 = phaseDivTr11.childNodes[3] !== undefined ? (phaseDivTr11.childNodes[3] as HTMLElement) : elem
      let phaseTdRateHr4 = phaseDivTr11.childNodes[4] !== undefined ? (phaseDivTr11.childNodes[4] as HTMLElement) : elem
      let phaseTdRateHr5 = phaseDivTr11.childNodes[5] !== undefined ? (phaseDivTr11.childNodes[5] as HTMLElement) : elem
      let phaseTdRateHr6 = phaseDivTr11.childNodes[6] !== undefined ? (phaseDivTr11.childNodes[6] as HTMLElement) : elem
      let phaseTdRateHr7 = phaseDivTr11.childNodes[7] !== undefined ? (phaseDivTr11.childNodes[7] as HTMLElement) : elem
      let phaseTdRateHr8 = phaseDivTr11.childNodes[8] !== undefined ? (phaseDivTr11.childNodes[8] as HTMLElement) : elem

      worksheet.addRow([
        phaseHeader1.innerText,
        phaseHeader2.innerText,
        phaseHeader3.innerText,
        phaseHeader4.innerText,
        phaseHeader5.innerText,
        phaseHeader6.innerText,
        phaseHeader7.innerText,
        phaseHeader8.innerText,
        phaseHeader9.innerText
      ])
      worksheet.addRow([
        'Total Billing Amount',
        phaseTdTotBillingAmt1.innerText,
        phaseTdTotBillingAmt2.innerText,
        phaseTdTotBillingAmt3.innerText,
        phaseTdTotBillingAmt4.innerText,
        phaseTdTotBillingAmt5.innerText,
        phaseTdTotBillingAmt6.innerText,
        phaseTdTotBillingAmt7.innerText,
        phaseTdTotBillingAmt8.innerText
      ])

      worksheet.addRow([
        'Total Expenses',
        phaseTdTotExpenses1.innerText,
        phaseTdTotExpenses2.innerText,
        phaseTdTotExpenses3.innerText,
        phaseTdTotExpenses4.innerText,
        phaseTdTotExpenses5.innerText,
        phaseTdTotExpenses6.innerText,
        phaseTdTotExpenses7.innerText,
        phaseTdTotExpenses8.innerText
      ])

      worksheet.addRow([
        'Billing Excluding Expenses',
        phaseTdBillExclExpenses1.innerText,
        phaseTdBillExclExpenses2.innerText,
        phaseTdBillExclExpenses3.innerText,
        phaseTdBillExclExpenses4.innerText,
        phaseTdBillExclExpenses5.innerText,
        phaseTdBillExclExpenses6.innerText,
        phaseTdBillExclExpenses7.innerText,
        phaseTdBillExclExpenses8.innerText
      ])

      worksheet.addRow([
        'Total Labor & CT Cost',
        phaseTdTotLaborCost1.innerText,
        phaseTdTotLaborCost2.innerText,
        phaseTdTotLaborCost3.innerText,
        phaseTdTotLaborCost4.innerText,
        phaseTdTotLaborCost5.innerText,
        phaseTdTotLaborCost6.innerText,
        phaseTdTotLaborCost7.innerText,
        phaseTdTotLaborCost8.innerText
      ])

      worksheet.addRow([
        'Cost of Subcontractors',
        phaseTdSubCost1.innerText,
        phaseTdSubCost2.innerText,
        phaseTdSubCost3.innerText,
        phaseTdSubCost4.innerText,
        phaseTdSubCost5.innerText,
        phaseTdSubCost6.innerText,
        phaseTdSubCost7.innerText,
        phaseTdSubCost8.innerText
      ])

      worksheet.addRow([
        'EY Margin %',
        phaseTdEYMargin1.innerText,
        phaseTdEYMargin2.innerText,
        phaseTdEYMargin3.innerText,
        phaseTdEYMargin4.innerText,
        phaseTdEYMargin5.innerText,
        phaseTdEYMargin6.innerText,
        phaseTdEYMargin7.innerText,
        phaseTdEYMargin8.innerText
      ])

      worksheet.addRow([
        'EY Margin % using DCR',
        phaseTdEYMarginDcr1.innerText,
        phaseTdEYMarginDcr2.innerText,
        phaseTdEYMarginDcr3.innerText,
        phaseTdEYMarginDcr4.innerText,
        phaseTdEYMarginDcr5.innerText,
        phaseTdEYMarginDcr6.innerText,
        phaseTdEYMarginDcr7.innerText,
        phaseTdEYMarginDcr8.innerText
      ])

      worksheet.addRow([
        'EY Margin % Excl. Partner Costs',
        phaseTdEYMarginPtnr1.innerText,
        phaseTdEYMarginPtnr2.innerText,
        phaseTdEYMarginPtnr3.innerText,
        phaseTdEYMarginPtnr4.innerText,
        phaseTdEYMarginPtnr5.innerText,
        phaseTdEYMarginPtnr6.innerText,
        phaseTdEYMarginPtnr7.innerText,
        phaseTdEYMarginPtnr8.innerText
      ])

      worksheet.addRow([
        'EY Margin % using DCR Excl. Partner Costs',
        phaseTdEYMarginDcrPtnr1.innerText,
        phaseTdEYMarginDcrPtnr2.innerText,
        phaseTdEYMarginDcrPtnr3.innerText,
        phaseTdEYMarginDcrPtnr4.innerText,
        phaseTdEYMarginDcrPtnr5.innerText,
        phaseTdEYMarginDcrPtnr6.innerText,
        phaseTdEYMarginDcrPtnr7.innerText,
        phaseTdEYMarginDcrPtnr8.innerText
      ])

      worksheet.addRow([
        'Rate / Day',
        phaseTdRateDay1.innerText,
        phaseTdRateDay2.innerText,
        phaseTdRateDay3.innerText,
        phaseTdRateDay4.innerText,
        phaseTdRateDay5.innerText,
        phaseTdRateDay6.innerText,
        phaseTdRateDay7.innerText,
        phaseTdRateDay8.innerText
      ])

      worksheet.addRow([
        'Rate / Hour',
        phaseTdRateHr1.innerText,
        phaseTdRateHr2.innerText,
        phaseTdRateHr3.innerText,
        phaseTdRateHr4.innerText,
        phaseTdRateHr5.innerText,
        phaseTdRateHr6.innerText,
        phaseTdRateHr7.innerText,
        phaseTdRateHr8.innerText
      ])
    }
    worksheet.getRow(1).getCell('id').font = { bold: true }
    worksheet.getRow(6).getCell('id').font = { bold: true }
    worksheet.getRow(6).getCell('ec3').font = { bold: true }
    worksheet.getRow(19).getCell('id').font = { bold: true }
    worksheet.getRow(19).getCell('ec3').font = { bold: true }
    worksheet.getRow(32).getCell('id').font = { bold: true }
    worksheet.getRow(32).getCell('ec3').font = { bold: true }
    worksheet.getRow(39).getCell('id').font = { bold: true }
    worksheet.getRow(45).getCell('id').font = { bold: true }
    worksheet.getRow(45).getCell('ec3').font = { bold: true }
    worksheet.getRow(54).getCell('id').font = { bold: true }
    worksheet.getRow(68).getCell('id').font = { bold: true }
    let buffer = await workbook.xlsx.writeBuffer()
    const blob = new Blob([buffer], { type: fileType })
    const fileName = `${pricingPlanData.engagementName || pricingPlanData.worksheetName} - CCT.xlsx` // downloaded pdf name with deal name

    FileSaver.saveAs(blob, fileName) //'test5.xlsx')
  }

  if (isLoading) return <Loading />

  return (
    <div className='summary-page'>
      <Header isFixedWidth={true} />
      <NavMenu
        worksheetData={pricingPlanData}
        selectedTab={4}
        highestAccessedPage={highestAccessedPage}
        onNavSaveClick={(tabnum) => {
          if (tabnum == 0) history.push(`/pricing-plan/${pricingPlanId}/global-input`)
          if (tabnum == 1) history.push(`/pricing-plan/${pricingPlanId}/resources`)
          if (tabnum == 2) history.push(`/pricing-plan/${pricingPlanId}/expenses`)
          if (tabnum == 3) history.push(`/pricing-plan/${pricingPlanId}/scenarios`)
          if (tabnum == 4) history.push(`/pricing-plan/${pricingPlanId}/summary`)
        }}
      />
      <div className='summary-container'>
        <div id='pdf-page1'>
          <div className='title-container'>
            <h2 className='heading-two title'>Management Summary</h2>
            {scenarioName && <div className='scenario-tag'>{SCENARIOS[scenarioName].label.toUpperCase()}</div>}
          </div>

          <div className='row'>
            <div className='col basic-details1'>
              <BasicDetails1 pricingPlanData={pricingPlanData} />
            </div>
            <div className='col basic-details2'>
              <BasicDetails2 pricingPlanData={pricingPlanData} />
            </div>
          </div>

          <div className='row'>
            <div className='col price-breakdown'>
              <PriceBreakdown pricingPlanData={pricingPlanData} />
            </div>
            <div className='col detailed-summary'>
              <DetailedSummary pricingPlanData={pricingPlanData} />
            </div>
          </div>

          <div className='row1'>
            <div>
            <section className='col resources-by-rank'>
              <ResourcesByRank pricingPlanData={pricingPlanData} ranks={ranks} />
            </section>
            <section className='vendor-or-subcontractor'>
                <VendorOrSubcontractor pricingPlanData={pricingPlanData} />
              </section>
              <section className='mobility-expense'>
                <MobilityExpenses pricingPlanData={pricingPlanData} />
              </section>
            </div>
            <div>
            <section className='col detailed-summary'>
              <DetailedScenario 
                selectedColumn={pricingPlanData.summaryViewModel.selectedScenario}
                formValues={pricingPlanData.summaryViewModel}
                inputResources={pricingPlanData.resources}
                planCurrency={pricingPlanData.planCurrency}
                clientViewValues={pricingPlanData.summaryViewModel.priceBreakdown}
                />
            </section>
            <section className='col leverage-breakdown'>
                <LeverageBreakdown pricingPlanData={pricingPlanData} ranks={ranks} />
              </section>
              <section className='technology-expense'>
              <TechnologyExpenses pricingPlanData={pricingPlanData} />
            </section>
            </div>
             
          </div>
        </div>
        <div id='pdf-page2'>
          <div className='row'>
            <div
              className={
                pricingPlanData.workstreams.length > 0 ? 'col workstream-summary' : 'col workstream-summary-invisible'
              }
            >
              <WorkstreamSummary pricingPlanData={pricingPlanData} WNote={WNote} />
            </div>
          </div>

          <div className='row'>
            <div className={pricingPlanData.phases.length > 0 ? 'col phases-summary' : 'col phases-summary-invisible'}>
              <PhasesSummary pricingPlanData={pricingPlanData} PNote={PNote} />
            </div>
          </div>
        </div>
      </div>

      <div className='sticky-footer'>
        <Footer
          isSummary={true}
          exportPDF={exportPDF}
          exportExcel={exportExcel}
          onClickBackButton={() => history.push(`/pricing-plan/${pricingPlanId}/scenarios`)}
          worksheetData={pricingPlanData} // TODO: Replace this with the actual worksheet name
        />
      </div>
    </div>
  )
}

export default Summary
