import { useEffect, useState } from 'react'
import Modal from 'react-modal'
import styles from './CostCentreModal.module.css'

type Props = {
  availableCostCentres: any[]
  isOpen: boolean
  onCancel: () => void
  onConfirm: (value: any) => void
}

let marketScopeData: any

const CostCenterModal = (props: Props) => {
  const { availableCostCentres: _availableCostCentres } = props
  const [selectedCostCenter, setSecletedCostCenter] = useState<any>(null)
  const [availableCostCentres, setCostCentres] = useState<any[]>([])
  const [searchInput, setSearchInput] = useState('')

  useEffect(() => {
    setCostCentres(_availableCostCentres)
  }, [_availableCostCentres])

  
  useEffect(() => {
    if (searchInput) {
      setCostCentres(
        _availableCostCentres.filter((costCentre) =>
          `${costCentre.costCenterDescription} ${costCentre.costCenterId}`
            .toLowerCase()
            .includes(searchInput.toLowerCase())
        )
      )
    } else {
      setCostCentres(_availableCostCentres)
    }
  }, [searchInput])

  return (
    <Modal
      isOpen={props.isOpen}
      contentLabel='Cost Center Modal'
      className={styles.costCenterModal}
      overlayClassName={styles.costCenterModalOverlay}
    >
      <h3 className='heading-three m-0'>Select Cost Centre</h3>

      <p className={styles.description}>
        The unnamed resource inputs have multiple cost centres associated. Please select the most appropriate cost
        centre for accurate costing.
      </p>

      <div className={styles.inputFieldWrapper}>
        <input
          type='text'
          className='input-field'
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
        />
        <span className={`material-icons-outlined ${styles.searchIcon}`}>search</span>
      </div>

      <div style={{ textAlign: 'right', marginTop: '10px' }}>
        <span className={styles.resultsCountText}>
          {availableCostCentres?.length > 0 ? `${availableCostCentres.length} Results` : 'No Results Found'}
        </span>
      </div>

      <div className={styles.resultsContainer}>
        {availableCostCentres?.map((costCentre, index) => {
          return (
            <div
              key={index}
              className={`${styles.resultItem} ${
                selectedCostCenter && costCentre.costCenterId === selectedCostCenter.costCenterId ? styles.selected : ''
              }`}
              onClick={() => setSecletedCostCenter(costCentre)}
            >
              <span className={styles.costCentreText}>
                {costCentre.costCenterDescription} {costCentre.costCenterId}
              </span>
            </div>
          )
        })}
      </div>

      <div className={styles.footerContainer}>
        <button
          className='secondary-button'
          type='button'
          onClick={() => {
            setSearchInput('')
            setSecletedCostCenter('')
            props.onCancel()
          }}
        >
          <span className='heading-three'>Cancel</span>
        </button>
        <button
          className='primary-button'
          type='button'
          onClick={() => {
            if (selectedCostCenter) {
              setSearchInput('')
              props.onConfirm(selectedCostCenter)
              setSecletedCostCenter('')
            }
          }}
        >
          <span className='heading-three'>Confirm</span>
        </button>
      </div>
    </Modal>
  )
}

export default CostCenterModal
