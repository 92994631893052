import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import moment from 'moment'
import './PhasesSummary.css'
import { getInflationRates } from '../../utils/fetchHandler'
import { numberWithCommas, StartDate } from '../../resources/ResourceTableComponents'
import Phases from '../../global-inputs/Phases'
import { title } from 'process'
import { resourceLimits } from 'worker_threads'
import { debug } from 'util'
import { string } from 'yup'
import { parse } from 'node:path/win32'

const generateColumns = (phases: any[]) => {
  if (phases?.length === 0) return []

  let columns = phases.map((phase) => {
    return { title: phase.title, path: _.camelCase(phase.title), startDate: phase.startDate, endDate: phase.endDate }
  })
  if (columns?.length > 8) columns = columns.slice(0, 9)
  if (columns.length > 0) {
    columns.push({ title: 'Unallocated', path: '_unKnown', startDate: '01/01/2020', endDate: '01/01/9999' })
    columns.push({
      title: 'Total Phases',
      path: '_totalWorkstreams',
      startDate: '01/01/2020',
      endDate: '01/01/9999'
    })
  }
  return columns
}

export const getTotalHours = (rowData: any) => {
  const hours =
    rowData?.weeklyTimeUnits?.length > 0 &&
    rowData.weeklyTimeUnits.reduce((result: number, item: any) => {
      if (item.timeUnits && !isNaN(item.timeUnits)) {
        return result + item.timeUnits
      }
      return result
    }, 0)
  return hours
}

export const getTotalDays = (rowData: any) => {
  const hours = getTotalHours(rowData)
  const isGDS = rowData.isGDS
  let days = 0
  if (hours && hours > 0) {
    if (isGDS) days = hours / 9
    else days = hours / 7.5
  }
  return days
}

export const getRounded = (num: number, n: number = 0) => {
  const d = Math.pow(10, n)
  return Math.round((num + Number.EPSILON) * d) / d
}

const getPhaseResources = (resources: any[], phaseStartDate: string, phaseEndDate: string) => {
  let daysCnt = moment(phaseStartDate).day()
  if (daysCnt > 1 && daysCnt <= 5) {
    phaseStartDate = moment(phaseStartDate).add(-daysCnt, 'days').toString()
  }

  return resources.map((resource: any) => {
    return {
      ...resource,
      weeklyTimeUnits: resource.weeklyTimeUnits.filter(
        (item: any) =>
          moment(item.startDate).isSameOrAfter(phaseStartDate) && moment(item.startDate).isSameOrBefore(phaseEndDate)
      )
    }
  })
}

const getResourcesExclSubcontractors = (resources: any[]) => {
  return resources.filter(
    (resource: any) =>
      !(
        resource.source === 'subcontractor' ||
        resource.rank === 'Client Serving Contractor' ||
        resource.rank === 'Subcontractor'
      )
  )
}

const getSubcontractorResources = (resources: any[]) => {
  return resources.filter(
    (resource: any) =>
      resource.source === 'subcontractor' ||
      resource.rank === 'Client Serving Contractor' ||
      resource.rank === 'Subcontractor'
  )
}

const getPartnerResources = (resources: any[]) => {
  return resources.filter((resource: any) => resource.rank?.includes('Partner'))
}
const generateResources = (resources: any[]) => {
  return resources.reduce((result: any[], resource: any) => {
    let promotedResource = null
    if (resource.promotedResource) {
      promotedResource = resource.promotedResource
    }
    //delete resource.promotedResource
    result.push(resource)
    if (promotedResource) result.push(promotedResource)
    return result
  }, [])
}

const getRange = (start: number, end: number) => {
  return Array(end - start + 1)
    .fill(0)
    .map((_, idx) => start + idx)
}

const calculateTotalLabourCost = (
  rowData: any,
  dealStartDate: Date | null,
  dealEndDate: Date | null,
  rate: number = 0,
  inflationRate: number = 0
) => {
  const yearsArray = getRange(moment(dealStartDate).year(), moment(dealEndDate).year())
  const weeklyTimeUnitsPerYear = yearsArray?.reduce((result: any[], year: any, index: any) => {
    if (index === 0) {
      result.push([
        ...rowData.weeklyTimeUnits.filter((item: any) => moment(item.startDate).isSameOrBefore(`${year}-06-30`))
      ])
    }
    result.push([
      ...rowData.weeklyTimeUnits.filter(
        (item: any) =>
          moment(item.startDate).isSameOrAfter(`${year}-07-01`) &&
          moment(item.startDate).isSameOrBefore(`${year + 1}-06-30`)
      )
    ])
    return result
  }, [])
  const totalLabourCost = weeklyTimeUnitsPerYear.reduce((result: number, item: any[], index: number) => {
    const hours = item.reduce((_result: number, _item: any) => {
      _result += _item.timeUnits
      return _result
    }, 0)
    const standardCost = hours * rate //rowData.totalStandardCost / hours
    const inflationCost = 0 //hours * (rate * inflationRate * index)

    result += standardCost + inflationCost
    return result
  }, 0)
  return totalLabourCost
}

const calculateTotalClientBillRate = (
  rowData: any,
  dealStartDate: Date | null,
  dealEndDate: Date | null,
  rate: number = 0,
  inflationRate: number = 0
) => {
  const yearsArray = getRange(moment(dealStartDate).year(), moment(dealEndDate).year())
  const weeklyTimeUnitsPerYear = yearsArray?.reduce((result: any[], year: any, index: any) => {
    if (index === 0) {
      result.push([
        ...rowData.weeklyTimeUnits.filter((item: any) => moment(item.startDate).isSameOrBefore(`${year}-06-30`))
      ])
    }
    result.push([
      ...rowData.weeklyTimeUnits.filter(
        (item: any) =>
          moment(item.startDate).isSameOrAfter(`${year}-07-01`) &&
          moment(item.startDate).isSameOrBefore(`${year + 1}-06-30`)
      )
    ])
    return result
  }, [])
  const totalClientRate = weeklyTimeUnitsPerYear.reduce((result: number, item: any[], index: number) => {
    const hours = item.reduce((_result: number, _item: any) => {
      _result += _item.timeUnits
      return _result
    }, 0)
    const standardCost = hours * rate
    const inflationCost = 0 //hours * (rate * inflationRate * index)
    result += standardCost + inflationCost
    return result
  }, 0)
  return totalClientRate
}

const calculateTotalMarginRate = (
  rowData: any,
  summaryViewModel: any,
  inputMargin: number,
  dealStartDate: Date | null,
  dealEndDate: Date | null,
  rate: number = 0,
  inflationRate: number = 0
) => {
  const yearsArray = getRange(moment(dealStartDate).year(), moment(dealEndDate).year())
  const weeklyTimeUnitsPerYear = yearsArray?.reduce((result: any[], year: any, index: any) => {
    if (index === 0) {
      result.push([
        ...rowData.weeklyTimeUnits.filter((item: any) => moment(item.startDate).isSameOrBefore(`${year}-06-30`))
      ])
    }
    result.push([
      ...rowData.weeklyTimeUnits.filter(
        (item: any) =>
          moment(item.startDate).isSameOrAfter(`${year}-07-01`) &&
          moment(item.startDate).isSameOrBefore(`${year + 1}-06-30`)
      )
    ])
    return result
  }, [])
  const totalLaborCT = weeklyTimeUnitsPerYear.reduce((result: number, item: any[], index: number) => {
    const hours = item.reduce((_result: number, _item: any) => {
      _result += _item.timeUnits
      return _result
    }, 0)
    const standardCost = hours * rate
    const inflationCost = hours * (rate * inflationRate * index)

    result += standardCost + inflationCost
    return result
  }, 0)
  const marginpct = inputMargin / 100
  const totalMarginRate = totalLaborCT / (1 - marginpct)
  return totalMarginRate
}

const getResourceSTCost = (
  resources: any[],
  dealStartDate: any,
  dealEndDate: any,
  inflationRate: number,
  pricingPlanData: any
) => {
  return resources.reduce((result: number, resource: any) => {
    const rate = resource.totalStandardCost
    const hours =
      resource?.weeklyTimeUnits?.length > 0 &&
      resource.weeklyTimeUnits.reduce((resultHour: number, item: any) => {
        let timeunits = 0
        if (
          moment(dealEndDate).diff(resource.startDate, 'days') >= 0 &&
          moment(resource.endDate).diff(dealStartDate, 'days') >= 0 &&
          moment(item.startDate).diff(dealStartDate, 'days') >= 0 &&
          moment(dealEndDate).diff(item.startDate, 'days') > 5
          // &&
          //moment(item.startDate).diff(dealEndDate, 'days') <= 0
        ) {
          timeunits = item.timeUnits
        } else if (
          moment(dealEndDate).diff(resource.startDate, 'days') >= 0 &&
          moment(resource.endDate).diff(dealStartDate, 'days') >= 0 &&
          moment(dealEndDate).diff(item.startDate, 'days') <= 5
          // ||
          //moment(dealEndDate).diff(item.startDate, 'days') <= 5
        ) {
          let noDays =
            moment(dealEndDate).diff(item.startDate, 'days') < 5
              ? moment(dealEndDate).diff(item.startDate, 'days') + 1
              : moment(dealEndDate).diff(item.startDate, 'days')
          let perDayunits = resource.timeUnitsPerWeek / 5
          //if (noDays <= 5) {
          let deductedUnits = item.timeUnits >= resource.timeUnitsPerWeek ? perDayunits * noDays : item.timeUnits
          timeunits = deductedUnits
          //}
        } else if (
          moment(dealEndDate).diff(resource.startDate, 'days') >= 0 &&
          moment(resource.endDate).diff(dealStartDate, 'days') >= 0 &&
          moment(item.startDate).diff(dealStartDate, 'days') < 0
          // ||
          //moment(dealEndDate).diff(item.startDate, 'days') <= 5
        ) {
          if (moment(item.startDate).diff(resource.startDate, 'days') < 0) {
            timeunits = item.timeUnits
          } else {
            if (item.timeUnits >= resource.timeUnitsPerWeek) {
              let perDayunits = item.timeUnits / 5
              //if (moment(item.startDate).diff(dealStartDate, 'days') < 0) {
              let noDays = moment(dealStartDate).diff(item.startDate, 'days')
              if (noDays <= 5) {
                let deductedUnits = perDayunits * noDays
                timeunits = item.timeUnits - deductedUnits
              } else timeunits = item.timeUnits
            }
          }
        } else timeunits = 0

        if (timeunits && !isNaN(item.timeUnits)) {
          return resultHour + timeunits
        }
        return resultHour
      }, 0)

    let singleResourceHours = hours > 0 ? (rate * hours) / resource.totalHours : 0

    //add contingency
    let onshore
    let gds
    if (pricingPlanData?.summaryViewModel?.contingencyTableValues?.resources) {
      onshore = hours > 0 ? pricingPlanData.summaryViewModel.contingencyTableValues.resources.onshore : 0
      gds = hours > 0 ? pricingPlanData.summaryViewModel.contingencyTableValues.resources.gds : 0
    }
    let rscOnshore = pricingPlanData.resources.filter((resourcedtl: any) => resourcedtl.source === 'onshore')
    let rscgdsOffshore = pricingPlanData.resources.filter((resourcedtl: any) => resourcedtl.source === 'gdsOffshore')

    const onShoreTotHrs =
      rscOnshore?.length > 0 &&
      rscOnshore.reduce((totLaborCost: number, item: any) => {
        if (item.totalStandardCost && !isNaN(item.totalStandardCost)) {
          return totLaborCost + item.totalStandardCost
        }
        return totLaborCost
      }, 0)

    const gdsOffshoreTotHrs =
      rscgdsOffshore?.length > 0 &&
      rscgdsOffshore.reduce((totLaborCost: number, item: any) => {
        if (item.totalStandardCost && !isNaN(item.totalStandardCost)) {
          return totLaborCost + item.totalStandardCost
        }
        return totLaborCost
      }, 0)

    let EY_STOnshore = onshore / onShoreTotHrs //pricingPlanData.summaryViewModel.detailedSummary.totalLaborCost.exclContingency
    let EY_GDS = gds / gdsOffshoreTotHrs // pricingPlanData.summaryViewModel.detailedSummary.totalLaborCost.e
    if (resource.source === 'onshore') {
      singleResourceHours = singleResourceHours * EY_STOnshore + singleResourceHours
    } else if (resource.source === 'gdsOffshore') {
      singleResourceHours = singleResourceHours * EY_GDS + singleResourceHours
    }
    result += singleResourceHours //hours > 0 ? (rate * hours) / resource.totalHours : 0
    // result += calculateTotalLabourCost(resource, dealStartDate, dealEndDate, rate, inflationRate)
    return result
  }, 0)
}

const getResourceTotDedicatedCostRow = (
  resources: any[],
  dealStartDate: any,
  dealEndDate: any,
  inflationRate: number,
  pricingPlanData: any
) => {
  return resources.reduce((result: number, resource: any) => {
    let perDayHours = 0
    perDayHours = GetBusinessWorkingHours(resource, resource.costCountry)

    let isDCRCriteria = CheckDCRCriteria(resources, perDayHours)

    const rate =
      isDCRCriteria === true && resource.dcrRate > 0 ? resource.totalDedicatedCost : resource.totalStandardCost

    const hours =
      resource?.weeklyTimeUnits?.length > 0 &&
      resource.weeklyTimeUnits.reduce((resultHour: number, item: any) => {
        let timeunits = 0
        if (
          moment(dealEndDate).diff(resource.startDate, 'days') >= 0 &&
          moment(resource.endDate).diff(dealStartDate, 'days') >= 0 &&
          moment(item.startDate).diff(dealStartDate, 'days') >= 0 &&
          moment(dealEndDate).diff(item.startDate, 'days') > 5
          // &&
          //moment(item.startDate).diff(dealEndDate, 'days') <= 0
        ) {
          timeunits = item.timeUnits
        } else if (
          moment(dealEndDate).diff(resource.startDate, 'days') >= 0 &&
          moment(resource.endDate).diff(dealStartDate, 'days') >= 0 &&
          moment(dealEndDate).diff(item.startDate, 'days') <= 5
          // ||
          //moment(dealEndDate).diff(item.startDate, 'days') <= 5
        ) {
          let noDays =
            moment(dealEndDate).diff(item.startDate, 'days') < 5
              ? moment(dealEndDate).diff(item.startDate, 'days') + 1
              : moment(dealEndDate).diff(item.startDate, 'days')
          let perDayunits = resource.timeUnitsPerWeek / 5
          //if (noDays <= 5) {
          let deductedUnits = item.timeUnits >= resource.timeUnitsPerWeek ? perDayunits * noDays : item.timeUnits
          timeunits = deductedUnits
          //}
        } else if (
          moment(dealEndDate).diff(resource.startDate, 'days') >= 0 &&
          moment(resource.endDate).diff(dealStartDate, 'days') >= 0 &&
          moment(item.startDate).diff(dealStartDate, 'days') < 0
          // ||
          //moment(dealEndDate).diff(item.startDate, 'days') <= 5
        ) {
          if (moment(item.startDate).diff(resource.startDate, 'days') < 0) {
            timeunits = item.timeUnits
          } else {
            if (item.timeUnits >= resource.timeUnitsPerWeek) {
              let perDayunits = item.timeUnits / 5
              //if (moment(item.startDate).diff(dealStartDate, 'days') < 0) {
              let noDays = moment(dealStartDate).diff(item.startDate, 'days')
              if (noDays <= 5) {
                let deductedUnits = perDayunits * noDays
                timeunits = item.timeUnits - deductedUnits
              } else timeunits = item.timeUnits
            }
          }
        } else timeunits = 0

        if (timeunits && !isNaN(item.timeUnits)) {
          return resultHour + timeunits
        }
        return resultHour
      }, 0)

    let singleResourceHours = hours > 0 ? rate : 0
    //result += hours > 0 ? (rate * hours) / resource.totalHours : 0
    let onshore
    let gds
    if (pricingPlanData?.summaryViewModel?.contingencyTableValues?.resources) {
      onshore = hours > 0 ? pricingPlanData.summaryViewModel.contingencyTableValues.resources.onshore : 0
      gds = hours > 0 ? pricingPlanData.summaryViewModel.contingencyTableValues.resources.gds : 0
      //sumOfResourceContingency = onshore + gds
    }

    let rscOnshore = pricingPlanData.resources.filter((resourcedtl: any) => resourcedtl.source === 'onshore')
    let rscgdsOffshore = pricingPlanData.resources.filter((resourcedtl: any) => resourcedtl.source === 'gdsOffshore')

    const onShoreTotHrs =
      rscOnshore?.length > 0 &&
      rscOnshore.reduce((totLaborCost: number, item: any) => {
        if (item.totalStandardCost && !isNaN(item.totalStandardCost)) {
          return totLaborCost + item.totalStandardCost
        }
        return totLaborCost
      }, 0)

    const gdsOffshoreTotHrs =
      rscgdsOffshore?.length > 0 &&
      rscgdsOffshore.reduce((totLaborCost: number, item: any) => {
        if (item.totalStandardCost && !isNaN(item.totalStandardCost)) {
          return totLaborCost + item.totalStandardCost
        }
        return totLaborCost
      }, 0)

    let EY_STOnshore = onshore / onShoreTotHrs //pricingPlanData.summaryViewModel.detailedSummary.totalLaborCost.exclContingency
    let EY_GDS = gds / gdsOffshoreTotHrs // pricingPlanData.summaryViewModel.detailedSummary.totalLaborCost.exclContingency
    if (resource.source === 'onshore') {
      singleResourceHours = singleResourceHours * EY_STOnshore + singleResourceHours
    } else if (resource.source === 'gdsOffshore') {
      singleResourceHours = singleResourceHours * EY_GDS + singleResourceHours
    }
    result += singleResourceHours //hours > 0 ? (rate * hours) / resource.totalHours : 0
    // result += calculateTotalLabourCost(resource, dealStartDate, dealEndDate, rate, inflationRate)
    return result
  }, 0)
}

const gerResourceClientBillRate = (resources: any[], dealStartDate: any, dealEndDate: any, inflationRate: number) => {
  return resources.reduce((result: number, resource: any) => {
    const rate = resource.clientRateHour

    const hours =
      resource?.weeklyTimeUnits?.length > 0 &&
      resource.weeklyTimeUnits.reduce((resultHour: number, item: any) => {
        let timeunits = 0
        if (
          moment(dealEndDate).diff(resource.startDate, 'days') >= 0 &&
          moment(resource.endDate).diff(dealStartDate, 'days') >= 0 &&
          moment(item.startDate).diff(dealStartDate, 'days') >= 0 &&
          moment(dealEndDate).diff(item.startDate, 'days') > 5
          // &&
          //moment(item.startDate).diff(dealEndDate, 'days') <= 0
        ) {
          timeunits = item.timeUnits
        } else if (
          moment(dealEndDate).diff(resource.startDate, 'days') >= 0 &&
          moment(resource.endDate).diff(dealStartDate, 'days') >= 0 &&
          moment(dealEndDate).diff(item.startDate, 'days') <= 5
          // ||
          //moment(dealEndDate).diff(item.startDate, 'days') <= 5
        ) {
          let noDays =
            moment(dealEndDate).diff(item.startDate, 'days') < 5
              ? moment(dealEndDate).diff(item.startDate, 'days') + 1
              : moment(dealEndDate).diff(item.startDate, 'days')
          let perDayunits = resource.timeUnitsPerWeek / 5
          //if (noDays <= 5) {
          let deductedUnits = item.timeUnits >= resource.timeUnitsPerWeek ? perDayunits * noDays : item.timeUnits
          timeunits = deductedUnits
          //}
        } else if (
          moment(dealEndDate).diff(resource.startDate, 'days') >= 0 &&
          moment(resource.endDate).diff(dealStartDate, 'days') >= 0 &&
          moment(item.startDate).diff(dealStartDate, 'days') < 0
          // ||
          //moment(dealEndDate).diff(item.startDate, 'days') <= 5
        ) {
          if (moment(item.startDate).diff(resource.startDate, 'days') < 0) {
            timeunits = item.timeUnits
          } else {
            if (item.timeUnits >= resource.timeUnitsPerWeek) {
              let perDayunits = item.timeUnits / 5
              //if (moment(item.startDate).diff(dealStartDate, 'days') < 0) {
              let noDays = moment(dealStartDate).diff(item.startDate, 'days')
              if (noDays <= 5) {
                let deductedUnits = perDayunits * noDays
                timeunits = item.timeUnits - deductedUnits
              } else timeunits = item.timeUnits
            }
          }
        } else timeunits = 0

        if (timeunits && !isNaN(item.timeUnits)) {
          return resultHour + timeunits
        }
        return resultHour
      }, 0)

    let clientRateVal = hours > 0 ? hours * rate : 0
    result += clientRateVal
    //result += calculateTotalClientBillRate(resource, dealStartDate, dealEndDate, rate, inflationRate)
    return result
  }, 0)
}

const gerTolResourcesClientBillRate = (
  resources: any[],
  dealStartDate: any,
  dealEndDate: any,
  inflationRate: number
) => {
  return resources.reduce((result: number, resource: any) => {
    // const rate = resource.clientRateHour

    result += resource.totalClientRate
    //result += calculateTotalClientBillRate(resource, dealStartDate, dealEndDate, rate, inflationRate)
    return result
  }, 0)
}

const gerResourceTotalMargin = (
  resources: any[],
  summaryViewModel: any,
  dealStartDate: any,
  dealEndDate: any,
  inflationRate: number,
  pricingPlanData: any,
  standardCost: number
) => {
  return resources.reduce((result: number, resource: any) => {
    const rate = resource.costRate

    let inputMarginVal = 0
    if (resource.source === 'onshore') {
      inputMarginVal = summaryViewModel.targetMargin.margin.eyOnShore
    } else if (resource.source === 'gdsOffshore') {
      inputMarginVal = summaryViewModel.targetMargin.margin.gdsOffshore
    } else if (resource.source === 'subcontractor') {
      inputMarginVal = summaryViewModel.targetMargin.margin.subcontractors
    }

    const hours =
      resource?.weeklyTimeUnits?.length > 0 &&
      resource.weeklyTimeUnits.reduce((resultVal: number, item: any) => {
        if (item.timeUnits && !isNaN(item.timeUnits)) {
          return resultVal + item.timeUnits
        }
        return resultVal
      }, 0)
    let totSTCost = hours > 0 ? (hours * resource.totalStandardCost) / resource.totalHours : 0

    const marginpct = inputMarginVal / 100
    const totalMarginRate = standardCost / (1 - marginpct)
    result = totalMarginRate
    return result
  }, 0)
}

const GetBusinessWorkingHours = (inputResource: any, costCountry: string) => {
  let perDayHours = 0
  if (inputResource.isGDS) {
    if (['United Kingdom'].includes(costCountry)) {
      perDayHours = 7
    } else if (['India'].includes(costCountry)) {
      perDayHours = 9
    } else perDayHours = 8
  } else {
    if (['Australia', 'New Zealand'].includes(costCountry)) {
      perDayHours = 7.5
    } else if (['United Kingdom'].includes(costCountry)) {
      perDayHours = 7
    }else {
      perDayHours = 8
    }
  }
  return perDayHours
}

const CheckDCRCriteria = (inputResource: any, perDayHours: number) => {
  let consecutiveDaysCnt = 0

  let isPromotedResourceExists = false
  if (inputResource[0].promotedResource) {
    isPromotedResourceExists = true
  }
  consecutiveDaysCnt = CalculateDCRConsecutivedays(inputResource[0], perDayHours, isPromotedResourceExists)

  if (inputResource[0].promotedResource) {
    let PromotedPerDayHours = GetBusinessWorkingHours(inputResource[0].promotedResource, inputResource[0].costCountry)
    consecutiveDaysCnt += CalculateDCRConsecutivedays(
      inputResource[0].promotedResource,
      PromotedPerDayHours,
      isPromotedResourceExists
    )
  }
  return consecutiveDaysCnt >= 227 ? true : false
}
const CalculateDCRConsecutivedays = (inputResource: any, perDayHours: number, isPromotedResourceExists: boolean) => {
  let consecutiveDaysCnt = 0

  let totalPerYearHours = 0
  let dcrEligible

  dcrEligible = Test5(inputResource, perDayHours, isPromotedResourceExists)
  let dcrTest5 = 0
  dcrTest5 = dcrEligible !== undefined ? dcrEligible : 0

  return dcrTest5
}
const Test5 = (inputResource: any, perDayHours: number, isPromotedResourceExists: boolean) => {
  let totalPerYearHours = 0
  let dcrEligible = 0
  let consecutiveDaysCnt = 0
  if (isPromotedResourceExists || inputResource.totalDays >= 227) {
    for (let wi = 0; wi < inputResource.weeklyTimeUnits.length; wi++) {
      totalPerYearHours = 0
      if (dcrEligible === 0) {
        let currentWeekDate = moment(inputResource.weeklyTimeUnits[wi].startDate)
        let cnt = 0
        for (let i = wi; i < inputResource.weeklyTimeUnits.length; i++) {
          cnt = cnt + 1
          if (cnt <= 53) {
            totalPerYearHours = totalPerYearHours + inputResource.weeklyTimeUnits[i].timeUnits
            consecutiveDaysCnt = totalPerYearHours / perDayHours
            if (consecutiveDaysCnt >= 227) {
              let daysCnt =
                moment(inputResource.weeklyTimeUnits[i].startDate).diff(
                  moment(inputResource.weeklyTimeUnits[wi].startDate),
                  'days'
                ) + 1
              dcrEligible = daysCnt <= 365 ? 1 : 0
              return consecutiveDaysCnt
              //break
            }
          } else {
            dcrEligible = 0
            // return consecutiveDaysCnt
            break
          }
          if (i === inputResource.weeklyTimeUnits.length - 1) {
            return consecutiveDaysCnt
          }
        }
      }
    }
  }
}

const getResourceLTCost = (
  resources: any[],
  dealStartDate: any,
  dealEndDate: any,
  inflationRate: number,
  allResources: any[],
  pricingPlanData: any
) => {
  return resources.reduce((result: number, resource: any) => {
    let perDayHours = 0
    perDayHours = GetBusinessWorkingHours(resource, resource.costCountry)
    let rsc = allResources.filter(
      (resourcedtl: any) =>
        resourcedtl.source === resource.source &&
        resourcedtl.costCentre === resource.costCentre &&
        resourcedtl.clientRateHour === resource.clientRateHour &&
        resourcedtl.totalStandardCost === resource.totalStandardCost &&
        resourcedtl.totalDedicatedCost === resource.totalDedicatedCost &&
        resourcedtl.totalHours === resource.totalHours
    )
    let isDCRCriteria = CheckDCRCriteria(rsc, perDayHours)

    // const rate = isDCRCriteria === true && resource.dcrRate > 0 ? resource.dcrRate : resource.costRate
    const rate =
      isDCRCriteria === true && resource.dcrRate > 0 ? resource.totalDedicatedCost : resource.totalStandardCost
    // result += resource.dcrRate //calculateTotalLabourCost(resource, dealStartDate, dealEndDate, rate, inflationRate)

    const hours =
      resource?.weeklyTimeUnits?.length > 0 &&
      resource.weeklyTimeUnits.reduce((resultHour: number, item: any) => {
        let timeunits = 0
        if (
          moment(dealEndDate).diff(resource.startDate, 'days') >= 0 &&
          moment(resource.endDate).diff(dealStartDate, 'days') >= 0 &&
          moment(item.startDate).diff(dealStartDate, 'days') >= 0 &&
          moment(dealEndDate).diff(item.startDate, 'days') > 5
          // &&
          //moment(item.startDate).diff(dealEndDate, 'days') <= 0
        ) {
          timeunits = item.timeUnits
        } else if (
          moment(dealEndDate).diff(resource.startDate, 'days') >= 0 &&
          moment(resource.endDate).diff(dealStartDate, 'days') >= 0 &&
          moment(dealEndDate).diff(item.startDate, 'days') <= 5
          // ||
          //moment(dealEndDate).diff(item.startDate, 'days') <= 5
        ) {
          let noDays =
            moment(dealEndDate).diff(item.startDate, 'days') < 5
              ? moment(dealEndDate).diff(item.startDate, 'days') + 1
              : moment(dealEndDate).diff(item.startDate, 'days')
          let perDayunits = resource.timeUnitsPerWeek / 5
          //if (noDays <= 5) {
          let deductedUnits = item.timeUnits >= resource.timeUnitsPerWeek ? perDayunits * noDays : item.timeUnits
          timeunits = deductedUnits
          //}
        } else if (
          moment(dealEndDate).diff(resource.startDate, 'days') >= 0 &&
          moment(resource.endDate).diff(dealStartDate, 'days') >= 0 &&
          moment(item.startDate).diff(dealStartDate, 'days') < 0
          // ||
          //moment(dealEndDate).diff(item.startDate, 'days') <= 5
        ) {
          if (moment(item.startDate).diff(resource.startDate, 'days') < 0) {
            timeunits = item.timeUnits
          } else {
            if (item.timeUnits >= resource.timeUnitsPerWeek) {
              let perDayunits = item.timeUnits / 5
              //if (moment(item.startDate).diff(dealStartDate, 'days') < 0) {
              let noDays = moment(dealStartDate).diff(item.startDate, 'days')
              if (noDays <= 5) {
                let deductedUnits = perDayunits * noDays
                timeunits = item.timeUnits - deductedUnits
              } else timeunits = item.timeUnits
            }
          }
        } else timeunits = 0

        if (timeunits && !isNaN(item.timeUnits)) {
          return resultHour + timeunits
        }
        return resultHour
      }, 0)

    let singleResourceHours = hours > 0 ? (rate * hours) / resource.totalHours : 0
    //result += hours > 0 ? (rate * hours) / resource.totalHours : 0
    let onshore
    let gds
    if (pricingPlanData?.summaryViewModel?.contingencyTableValues?.resources) {
      onshore = hours > 0 ? pricingPlanData.summaryViewModel.contingencyTableValues.resources.onshore : 0
      gds = hours > 0 ? pricingPlanData.summaryViewModel.contingencyTableValues.resources.gds : 0
      //sumOfResourceContingency = onshore + gds
    }

    let rscOnshore = pricingPlanData.resources.filter((resourcedtl: any) => resourcedtl.source === 'onshore')
    let rscgdsOffshore = pricingPlanData.resources.filter((resourcedtl: any) => resourcedtl.source === 'gdsOffshore')

    const onShoreTotHrs =
      rscOnshore?.length > 0 &&
      rscOnshore.reduce((totLaborCost: number, item: any) => {
        if (item.totalStandardCost && !isNaN(item.totalStandardCost)) {
          return totLaborCost + item.totalStandardCost
        }
        return totLaborCost
      }, 0)

    const gdsOffshoreTotHrs =
      rscgdsOffshore?.length > 0 &&
      rscgdsOffshore.reduce((totLaborCost: number, item: any) => {
        if (item.totalStandardCost && !isNaN(item.totalStandardCost)) {
          return totLaborCost + item.totalStandardCost
        }
        return totLaborCost
      }, 0)

    let EY_STOnshore = onshore / onShoreTotHrs //pricingPlanData.summaryViewModel.detailedSummary.totalLaborCost.exclContingency
    let EY_GDS = gds / gdsOffshoreTotHrs // pricingPlanData.summaryViewModel.detailedSummary.totalLaborCost.e

    //let EY_STOnshore = onshore / pricingPlanData.summaryViewModel.detailedSummary.totalLaborCost.exclContingency
    //let EY_GDS = gds / pricingPlanData.summaryViewModel.detailedSummary.totalLaborCost.exclContingency
    if (resource.source === 'onshore') {
      singleResourceHours = singleResourceHours * EY_STOnshore + singleResourceHours
    } else if (resource.source === 'gdsOffshore') {
      singleResourceHours = singleResourceHours * EY_GDS + singleResourceHours
    }
    result += singleResourceHours //hours > 0 ? (rate * hours) / resource.totalHours : 0
    // result += calculateTotalLabourCost(resource, dealStartDate, dealEndDate, rate, inflationRate)
    return result
  }, 0)
}

const getSubcontractorExpenses = (expenses: any[]) => {
  return expenses.filter(
    (expense: any) =>
      expense.expenseType === 'Other' &&
      (expense.category === 'consultants' || expense.category === 'subcontractors' || expense.category === 'alliance')
  )
}

const getExpenseTotalCost = (expenses: any[]) => {
  return expenses.reduce((result: number, expense: any) => {
    result += expense.cost
    return result
  }, 0)
}

const getOtherLumpsumExpenses = (expenses: any[]) => {
  return expenses.filter((expense: any) => expense.expenseType === 'Other' && expense.category === 'otherLumpsum')
}

const getTechnologyExpenses = (expenses: any[]) => {
  return expenses.filter((expense: any) => expense.expenseType === 'Technology' && expense.category === 'otherProducts')
}

const getMobilityExpenses = (expenses: any[]) => {
  return expenses.filter(
    (expense: any) =>
      expense.expenseType === 'Other' &&
      !['otherLumpsum', 'consultants', 'subcontractors', 'alliance'].includes(expense.category)
  )
}

const calculateStandardCost = (
  pricingPlanData: any,
  columns: any[],
  dealStartDate: any,
  dealEndDate: any,
  inflationRate: number,
  rows: any[]
) => {
  const resources = generateResources(pricingPlanData.resources)
  let sumOfResourceContingency = 0

  let onshore = 0
  let gds = 0
  if (pricingPlanData?.summaryViewModel?.contingencyTableValues?.resources) {
    onshore = pricingPlanData.summaryViewModel.contingencyTableValues.resources.onshore || 0
    gds = pricingPlanData.summaryViewModel.contingencyTableValues.resources.gds || 0
    sumOfResourceContingency = onshore + gds
  }
  let standardCost: any = {},
    totStandardHours = 0
  const standardCostRow = rows.find((row) => row.name === 'Total Labor & CT Cost')
  columns.forEach((column: any) => {
    const resourcesExclSubcontractor = getResourcesExclSubcontractors(resources)
    const phaseResources = getPhaseResources(resourcesExclSubcontractor, column.startDate, column.endDate)
    // Calculate resources ST cost for phase

    let phaseResourceSTCost = getResourceSTCost(
      phaseResources,
      column.startDate, //dealStartDate,
      column.endDate, //dealEndDate,
      inflationRate,
      pricingPlanData
    )

    let OnShorersc = phaseResources.filter((resourcedtl: any) => resourcedtl.source === 'onshore')
    let EY_STOnshore = onshore / pricingPlanData.summaryViewModel.detailedSummary.totalLaborCost.inclContingency
    // let phaseResourceSTCost =
    //   OnShorersc.length > 0 && onshore > 0 ? phaseResourceSTCost5 * 0.1 + phaseResourceSTCost5 : phaseResourceSTCost5
    const phaseResourceDCCost = 0

    let EY_ST = sumOfResourceContingency / phaseResourceSTCost // * 100
    //let EY_ST = resourceSTCost / sumOfResourceContingency // * 100
    EY_ST = isFinite(EY_ST) ? EY_ST : 0
    // Calculating total
    const total = phaseResourceSTCost + phaseResourceDCCost // * (1 + EY_ST)
    //const total = phaseResourceSTCost + phaseResourceDCCost + EY_ST
    let totalAllocated = 0

    if (standardCostRow !== undefined) {
      if (column.title !== 'Unallocated' && column.title !== 'Total Phases') {
        totStandardHours += standardCostRow[column.path]
      }
      if (column.title === 'Unallocated') {
        totalAllocated =
          pricingPlanData.summaryViewModel.detailedSummary.totalLaborCost.inclContingency - totStandardHours
        if (totalAllocated <= 2 && totalAllocated >= -2) {
          standardCost[columns[0].path] += totalAllocated
          totalAllocated = 0
        }
        //summaryViewModel?.detailedSummary?.standardMargin?.inclContingency
      }
      if (column.title === 'Total Phases') {
        totalAllocated =
          totStandardHours +
          (pricingPlanData.summaryViewModel.detailedSummary.totalLaborCost.inclContingency - totStandardHours)
        //pricingPlanData.summaryViewModel.detailedSummary.totalLaborCost.inclContingency
        //summaryViewModel?.detailedSummary?.standardMargin?.inclContingency
      }
    }
    if (column.title !== 'Unallocated' && column.title !== 'Total Phases') {
      standardCost[column.path] = getRounded(total)
    } else standardCost[column.path] = getRounded(totalAllocated)
  })
  return { ...standardCost, name: 'Total Labor & CT Cost', cost: true }
}

const calculateDedicatedCost = (
  pricingPlanData: any,
  columns: any[],
  dealStartDate: any,
  dealEndDate: any,
  inflationRate: number,
  rows: any[]
) => {
  const resources = generateResources(pricingPlanData.resources)
  let sumOfResourceContingency = 0
  if (pricingPlanData?.summaryViewModel?.contingencyTableValues?.resources) {
    const onshore = pricingPlanData.summaryViewModel.contingencyTableValues.resources.onshore || 0
    const gds = pricingPlanData.summaryViewModel.contingencyTableValues.resources.gds || 0
    sumOfResourceContingency = onshore + gds
  }
  let dedicatedCost: any = {}

  let totDedicatedHours = 0
  columns.forEach((column: any) => {
    const resourcesExclSubcontractor = getResourcesExclSubcontractors(resources)
    const dedicatedCostRow = rows.find((row) => row.name === 'Dedicated Cost')
    const phaseResources = getPhaseResources(resourcesExclSubcontractor, column.startDate, column.endDate)

    // Calculate resources ST cost for phase
    const phaseResourceLTCost = getResourceLTCost(
      phaseResources,
      column.startDate,
      column.endDate,
      inflationRate,
      resourcesExclSubcontractor,
      pricingPlanData
    )
    // TODO: DC cost is not available yet, Do value is hardcoded now. Need to calculate when values available
    const phaseResourceDCCost = 0
    let totalAllocated = 0

    // let EY_LT = sumOfResourceContingency / phaseResourceLTCost // * 100
    // EY_LT = isFinite(EY_LT) ? EY_LT : 0
    // Calculating total
    const total = phaseResourceLTCost + phaseResourceDCCost //* (1 + EY_LT)
    if (dedicatedCostRow !== undefined) {
      let ctProductExpenses = pricingPlanData.expenses.filter(
        (expense: any) =>
          expense.expenseType === 'Technology' && expense.category === 'ctProducts' && expense.recoverable === true
      )
      const totCTProductExpenses = ctProductExpenses.reduce((result: number, ctProduct: any) => {
        return result + ctProduct.cost
      }, 0)
      if (column.title !== 'Unallocated' && column.title !== 'Total Phases') {
        totDedicatedHours += dedicatedCostRow[column.path]
      }

      if (column.title === 'Unallocated') {
        //let CTDirectCost = pricingPlanData.expenses.filter.category === 'ctProducts'
        let test3 = getResourceTotDedicatedCostRow(
          resources,
          column.startDate,
          column.endDate,
          inflationRate,
          pricingPlanData
        )
        test3 += totCTProductExpenses

        totalAllocated = test3 - totDedicatedHours
      }
      if (column.title === 'Total Phases') {
        let test32 = getResourceTotDedicatedCostRow(
          resources,
          column.startDate,
          column.endDate,
          inflationRate,
          pricingPlanData
        ) //* (1 + EY_LT)
        test32 += totCTProductExpenses

        totalAllocated = test32
      }
    }
    if (column.title !== 'Unallocated' && column.title !== 'Total Phases') {
      dedicatedCost[column.path] = getRounded(total)
    } else dedicatedCost[column.path] = getRounded(totalAllocated)
  })
  return { ...dedicatedCost, name: 'Dedicated Cost', cost: true }
}

const calculateCostOfSubcontractors = (
  pricingPlanData: any,
  columns: any[],
  dealStartDate: any,
  dealEndDate: any,
  inflationRate: number
) => {
  // const resources = generateResources(pricingPlanData.resources)
  // const expenses = pricingPlanData.expenses
  // const subcontracorContingency =
  //   pricingPlanData?.summaryViewModel?.contingencyTableValues?.resources?.subcontractors || 0
  // let vendorOrSubcontractorExpenseContingency =
  //   pricingPlanData?.summaryViewModel?.contingencyTableValues?.expenses?.externalVendorSubcontractor || 0
  // const sumOfOtherExpenseContingency = pricingPlanData?.summaryViewModel?.contingencyTableValues?.expenses?.other || 0
  let costOfSubcontractors: any = {}
  columns.forEach((column: any, columnIndex: number) => {
    let total = 0
    if (column.path === '_unKnown' || column.path === '_totalWorkstreams') {
      total = pricingPlanData?.summaryViewModel?.detailedSummary?.costOfSubcontractors?.inclContingency
    }

    costOfSubcontractors[column.path] = getRounded(total)
  })
  return { ...costOfSubcontractors, name: 'Cost of Subcontractors', cost: true }
}

const calculateEYStandardMargin = (summaryViewModel: any, columns: any, rows: any[]) => {
  let eyStandardMargin: any = {}
  columns.forEach((column: any) => {
    // get Total Labor & CT Cost
    const standardCostRow = rows.find((row) => row.name === 'Total Labor & CT Cost')
    const subContractorCostRow = rows.find((row) => row.name === 'Cost of Subcontractors')
    const billingExclExpenses = rows.find((row) => row.name === 'Billing Excluding Expenses')

    const standardCost =
      billingExclExpenses[column.path] != 0
        ? (billingExclExpenses[column.path] - standardCostRow[column.path] - subContractorCostRow[column.path]) /
          (billingExclExpenses[column.path] - subContractorCostRow[column.path])
        : 0
    //(summaryViewModel?.?.billingExclExpenses?.inclContingency -
    //summaryViewModel?.?.costdetailedSudetailedSummarymmaryOfSubcontractors?.inclContingency)
    // standardCostRow[column.path]
    // calculate total
    const total = standardCost === -Infinity ? 0 : standardCost //= standardCost === 0 ? 0 : summaryViewModel?.detailedSummary?.standardMargin?.inclContingency || 0
    eyStandardMargin[column.path] = isFinite(total) ? total : 0
  })
  return { ...eyStandardMargin, name: 'EY Margin %', cost: false }
}

const calculateBillingExcludingExpenses = (
  summaryViewModel: any,
  pricingPlanData: any,
  columns: any[],
  dealStartDate: any,
  dealEndDate: any,
  inflationRate: number,
  rows: any[]
) => {
  const billingExcludingExpenses: any = {}
  const resources = generateResources(pricingPlanData.resources)
  columns.forEach((column: any) => {
    // get Total Labor & CT Cost
    const standardCostRow = rows.find((row) => row.name === 'Total Labor & CT Cost')
    const totalExpensesRow = rows.find((row) => row.name === 'Total Expenses')
    const totalBillingRow = rows.find((row) => row.name === 'Total Billing Amount ')
    const subContractorCostRow = rows.find((row) => row.name === 'Cost of Subcontractors')
    const standardCost = standardCostRow[column.path]

    let total = 0

    if (summaryViewModel.selectedScenario === 'client') {
      const phaseResourcesClient = getPhaseResources(resources, column.startDate, column.endDate)
      const phaseResourceClientBillRate = gerResourceClientBillRate(
        phaseResourcesClient,
        column.startDate,
        column.endDate,
        inflationRate
      )
      total = phaseResourceClientBillRate
    } else if (summaryViewModel.selectedScenario === 'targetMargin') {
      const resourcesExclSubcontractor = getResourcesExclSubcontractors(resources)
      const phaseResources = getPhaseResources(resourcesExclSubcontractor, column.startDate, column.endDate)
      const phaseResourceMarginRate = gerResourceTotalMargin(
        phaseResources,
        summaryViewModel,
        column.startDate,
        column.endDate,
        inflationRate,
        pricingPlanData,
        standardCost
      )
      total = phaseResourceMarginRate
    } else if (summaryViewModel.selectedScenario === 'fixedFee') {
      let eyStandardMargin =
        (summaryViewModel?.detailedSummary?.billingExclExpenses?.inclContingency -
          summaryViewModel?.detailedSummary?.totalLaborCost?.inclContingency) /
        //-
        // summaryViewModel?.detailedSummary?.costOfSubcontractors?.inclContingency
        summaryViewModel?.detailedSummary?.billingExclExpenses?.inclContingency
      eyStandardMargin = isFinite(eyStandardMargin) ? eyStandardMargin : 0
      total = eyStandardMargin === 0 ? 0 : standardCost / (1 - eyStandardMargin)
    }
    if (column.path === '_unKnown') {
      let unAllocatedTot = 0
      columns.forEach((columnVal: any) => {
        if (columnVal.path !== '_unKnown' && columnVal.path !== '_totalWorkstreams') {
          unAllocatedTot += billingExcludingExpenses[columnVal.path]
        }
      })

      let unAllocBillExcl =
        summaryViewModel?.detailedSummary?.billingExclExpenses?.inclContingency > 0 && isFinite(unAllocatedTot)
          ? parseInt((summaryViewModel?.detailedSummary?.billingExclExpenses?.inclContingency - unAllocatedTot).toFixed())
          : 0
      if (unAllocBillExcl <= 2 && unAllocBillExcl >= -2) {
        billingExcludingExpenses[columns[0]] += unAllocBillExcl
        billingExcludingExpenses[column.path] = 0
      } else billingExcludingExpenses[column.path] = unAllocBillExcl
    } else if (column.path === '_totalWorkstreams') {
      let unallocated =
        summaryViewModel?.detailedSummary?.billingExclExpenses?.inclContingency > 0 && isFinite(total)
          ? summaryViewModel?.detailedSummary?.billingExclExpenses?.inclContingency - total
          : 0
      billingExcludingExpenses[column.path] = unallocated + total
    } else billingExcludingExpenses[column.path] = getRounded(total)
  })
  return { ...billingExcludingExpenses, name: 'Billing Excluding Expenses', cost: true }
}

const calculateEyMarginUsingDCR = (summaryViewModel: any, columns: any[], rows: any[]) => {
  const eyMarginUsingDCR: any = {}
  columns.forEach((column: any) => {
    // get Billing Excluding Expenses
    const billingExcludingExpensesRow = rows.find((row) => row.name === 'Billing Excluding Expenses')
    const subContractorCostRow = rows.find((row) => row.name === 'Cost of Subcontractors')
    const billingExcludingExpenses = billingExcludingExpensesRow[column.path]

    if (billingExcludingExpenses === 0) {
      eyMarginUsingDCR[column.path] = 0
    } else {
      const dedicatedCostRow = rows.find((row) => row.name === 'Dedicated Cost')
      const dedicatedCost = dedicatedCostRow[column.path]
      const subcontractorCost = subContractorCostRow[column.path]

      const total =
        billingExcludingExpenses != 0
          ? ((billingExcludingExpenses - dedicatedCost - subcontractorCost) /
              (billingExcludingExpenses - subcontractorCost)) *
            100
          : 0
      //  (summaryViewModel?.detailedSummary?.billingExclExpenses?.inclContingency -
      //  summaryViewModel?.detailedSummary?.costOfSubcontractors?.inclContingency)) *
      // 100
      eyMarginUsingDCR[column.path] = isFinite(total) ? getRounded(total, 1) : 0
    }
  })
  return { ...eyMarginUsingDCR, name: 'EY Margin % using DCR', cost: false }
}

const calculateEyStandardMarginExclPC = (
  pricingPlanData: any,
  columns: any[],
  rows: any[],
  dealStartDate: any,
  dealEndDate: any,
  inflationRate: number
) => {
  const resources = generateResources(pricingPlanData.resources)
  let sumOfResourceContingency = 0
  if (pricingPlanData?.summaryViewModel?.contingencyTableValues?.resources) {
    const onshore = pricingPlanData.summaryViewModel.contingencyTableValues.resources.onshore || 0
    const gds = pricingPlanData.summaryViewModel.contingencyTableValues.resources.gds || 0
    sumOfResourceContingency = onshore + gds
  }
  let eyStandardMarginExclPC: any = {}
  columns.forEach((column: any) => {
    // get Billing Excluding Expenses
    const billingExcludingExpensesRow = rows.find((row) => row.name === 'Billing Excluding Expenses')
    const subContractorCostRow = rows.find((row) => row.name === 'Cost of Subcontractors')
    const billingExcludingExpenses = billingExcludingExpensesRow[column.path]
    // get Total Labor & CT Cost
    const standardCostRow = rows.find((row) => row.name === 'Total Labor & CT Cost')
    const standardCost = standardCostRow[column.path]
    const subContractorCost = subContractorCostRow[column.path]
    // calculate sum of partner ST cost in phase
    const phaseResources = getPhaseResources(resources, column.startDate, column.endDate)
    const partnerResources = getPartnerResources(phaseResources)

    const sumOfPartnerSTCost = getResourceSTCost(
      partnerResources,
      dealStartDate,
      dealEndDate,
      inflationRate,
      pricingPlanData
    )
    // calculate EY_ST
    const resourcesExclSubcontractor = getResourcesExclSubcontractors(resources)
    const resourceSTCost = getResourceSTCost(
      resourcesExclSubcontractor,
      dealStartDate,
      dealEndDate,
      inflationRate,
      pricingPlanData
    )
    let EY_ST = sumOfResourceContingency / resourceSTCost
    EY_ST = isFinite(EY_ST) ? EY_ST : 0

    let test5 = standardCost - sumOfPartnerSTCost //* (1 + EY_ST)
    // claculate total
    const total =
      billingExcludingExpenses != 0
        ? ((billingExcludingExpenses - test5 - subContractorCost) / (billingExcludingExpenses - subContractorCost)) *
          100
        : 0
    //(pricingPlanData?.summaryViewModel?.detailedSummary?.billingExclExpenses?.inclContingency -
    //          pricingPlanData?.summaryViewModel?.detailedSummary?.costOfSubcontractors?.inclContingency)) *
    //100
    eyStandardMarginExclPC[column.path] = isFinite(total) ? getRounded(total, 1) : 0 //: 0
  })
  return { ...eyStandardMarginExclPC, name: 'EY Margin % Excl. Partner Costs', cost: false }
}

const calculateEyMarginUsingDCRExclPC = (
  pricingPlanData: any,
  columns: any[],
  rows: any[],
  dealStartDate: any,
  dealEndDate: any,
  inflationRate: number
) => {
  const resources = generateResources(pricingPlanData.resources)
  let sumOfResourceContingency = 0
  if (pricingPlanData?.summaryViewModel?.contingencyTableValues?.resources) {
    const onshore = pricingPlanData.summaryViewModel.contingencyTableValues.resources.onshore || 0
    const gds = pricingPlanData.summaryViewModel.contingencyTableValues.resources.gds || 0
    sumOfResourceContingency = onshore + gds
  }
  let eyMarginUsingDCRExclPC: any = {}
  columns.forEach((column: any) => {
    // get Billing Excluding Expenses
    const billingExcludingExpensesRow = rows.find((row) => row.name === 'Billing Excluding Expenses')
    const subContractorCostRow = rows.find((row) => row.name === 'Cost of Subcontractors')
    let billingExcludingExpenses = billingExcludingExpensesRow[column.path]
    // get Dedicated Cost
    const dedicatedCostRow = rows.find((row) => row.name === 'Dedicated Cost')
    const dedicatedCost = dedicatedCostRow[column.path]
    const subContractorCost = subContractorCostRow[column.path]
    // calculate sum of partner LT cost in phase
    const phaseResources = getPhaseResources(resources, column.startDate, column.endDate)
    const partnerResources = getPartnerResources(phaseResources)
    const sumOfPartnerLTCost = getResourceLTCost(
      partnerResources,
      dealStartDate,
      dealEndDate,
      inflationRate,
      resources,
      pricingPlanData
    )
    // calculate EY_LT
    const resourcesExclSubcontractor = getResourcesExclSubcontractors(resources)
    const resourceLTCost = getResourceLTCost(
      resourcesExclSubcontractor,
      dealStartDate,
      dealEndDate,
      inflationRate,
      resources,
      pricingPlanData
    )
    let EY_LT = sumOfResourceContingency / resourceLTCost
    EY_LT = isFinite(EY_LT) ? EY_LT : 0
    let temp5 = dedicatedCost - sumOfPartnerLTCost //* (1 + EY_LT)

    // calculate total
    // let total = 1 - ((dedicatedCost - sumOfPartnerLTCost) * (1 + EY_LT)) / billingExcludingExpenses
    let total =
      billingExcludingExpenses != 0
        ? ((billingExcludingExpenses - temp5 - subContractorCost) / (billingExcludingExpenses - subContractorCost)) *
          100
        : 0
    //  (pricingPlanData?.summaryViewModel?.detailedSummary?.billingExclExpenses?.inclContingency -
    //  pricingPlanData?.summaryViewModel?.detailedSummary?.costOfSubcontractors?.inclContingency)) *
    //100

    eyMarginUsingDCRExclPC[column.path] = isFinite(total) ? getRounded(total, 1) : 0
  })
  return { ...eyMarginUsingDCRExclPC, name: 'EY Margin % using DCR Excl. Partner Costs', cost: false }
}

const calculateTotalExpenses = (pricingPlanData: any, columns: any[], rows: any[]) => {
  const expenses = pricingPlanData.expenses
  const sumOfMobilitycontingency =
    pricingPlanData?.summaryViewModel?.contingencyTableValues?.expenses?.travelRelated || 0
  const sumOfOtherExpenseContingency = pricingPlanData?.summaryViewModel?.contingencyTableValues?.expenses?.other || 0
  let totalExpense: any = {}
  columns.forEach((column: any, columnIndex: number) => {
    const total = pricingPlanData?.summaryViewModel?.detailedSummary?.totalExpenses?.inclContingency

    totalExpense[column.path] = isFinite(total)
      ? column.path === '_unKnown' || column.path === '_totalWorkstreams'
        ? getRounded(total)
        : 0
      : 0
  })
  return { ...totalExpense, name: 'Total Expenses', cost: true }
}

const calculateRatePerHour = (pricingPlanData: any, columns: any[], rows: any[]) => {
  const resources = generateResources(pricingPlanData.resources)
  const totResourceHours = resources.reduce((result: number, rsc: any) => {
    return result + rsc.totalHours
  }, 0)
  let ratePerHour: any = {}
  columns.forEach((column: any) => {
    if (column.path === '_totalWorkstreams') {
      ratePerHour[column.path] = pricingPlanData.summaryViewModel.detailedSummary.ratePerHour.inclContingency
    } else {
      // calcualting sum of resource hours in phase
      //const resourcesExclSubcontractors = getResourcesExclSubcontractors(resources)
      //const phaseResources = getPhaseResources(resourcesExclSubcontractors, column.startDate, column.endDate)
      const phaseResources = getPhaseResources(resources, column.startDate, column.endDate)
      // const hours = phaseResources.reduce((result: number, resource: any) => {
      //   const totalHours = getTotalHours(resource)
      //   result += totalHours
      //   return result
      // }, 0)

      let onshoreHours = 0
      let gdsHours = 0
      let subcontractorHours = 0
      for (let i = 0; i < phaseResources.length; i++) {
        if (phaseResources[i].source === 'onshore') {
          onshoreHours += getTotalHours(phaseResources[i])
        } else if (phaseResources[i].source === 'gdsOffshore') {
          gdsHours += getTotalHours(phaseResources[i])
        } else if (phaseResources[i].source === 'subcontractor') {
          subcontractorHours += getTotalHours(phaseResources[i])
        }
      }
      let onshoreContingency
      let gdsContingency
      let subContingency
      if (pricingPlanData?.summaryViewModel?.contingencyTableValues?.resources) {
        onshoreContingency =
          onshoreHours > 0 ? pricingPlanData.summaryViewModel.contingencyTableValues.resources.onshore : 0
        gdsContingency = gdsHours > 0 ? pricingPlanData.summaryViewModel.contingencyTableValues.resources.gds : 0
        subContingency =
          subcontractorHours > 0 ? pricingPlanData.summaryViewModel.contingencyTableValues.resources.subcontractor : 0
      }

      let rscOnshore = pricingPlanData.resources.filter((resourcedtl: any) => resourcedtl.source === 'onshore')
      let rscgdsOffshore = pricingPlanData.resources.filter((resourcedtl: any) => resourcedtl.source === 'gdsOffshore')
      const onShoreTotHrs =
        rscOnshore?.length > 0 &&
        rscOnshore.reduce((totLaborCost: number, item: any) => {
          if (item.totalStandardCost && !isNaN(item.totalStandardCost)) {
            return totLaborCost + item.totalStandardCost
          }
          return totLaborCost
        }, 0)

      const gdsOffshoreTotHrs =
        rscgdsOffshore?.length > 0 &&
        rscgdsOffshore.reduce((totLaborCost: number, item: any) => {
          if (item.totalStandardCost && !isNaN(item.totalStandardCost)) {
            return totLaborCost + item.totalStandardCost
          }
          return totLaborCost
        }, 0)

      let EY_STOnshore = onshoreContingency / onShoreTotHrs
      EY_STOnshore = !isNaN(EY_STOnshore) ? EY_STOnshore : 0
      let EY_GDS = gdsContingency / gdsOffshoreTotHrs
      EY_GDS = !isNaN(EY_GDS) ? EY_GDS : 0
      //    let EY_SUB = subContingency / subcontractorHours
      let onshoreVal = onshoreHours * EY_STOnshore
      let gdsVal = gdsHours * EY_GDS
      const onshoreHoursContingency = onshoreHours + onshoreVal
      const gdsHoursContingency = gdsHours + gdsVal
      //  const subcontractorHoursContingency = subcontractorHours + subcontractorHours * (EY_SUB / 100)

      const totalResourceHoursContingency = onshoreHoursContingency + gdsHoursContingency + subcontractorHours //+ subcontractorHoursContingency

      // get Billing Excluding Expenses
      const billingExcludingExpensesRow = rows.find((row) => row.name === 'Billing Excluding Expenses')
      const billingExcludingExpenses = billingExcludingExpensesRow[column.path]
      //calculate total
      //const total = billingExcludingExpenses / getRounded(hours, 1)
      const total = Math.ceil(billingExcludingExpenses / totalResourceHoursContingency)
      // (hours * pricingPlanData?.summaryViewModel?.detailedSummary?.ratePerHour?.inclContingency) / totResourceHours
      ratePerHour[column.path] = getRounded(isFinite(total) ? total : 0)
    }
  })
  return { ...ratePerHour, name: 'Rate / Hour', cost: true }
}

const calculateRatePerDay = (pricingPlanData: any, columns: any[], rows: any[]) => {
  const resources = generateResources(pricingPlanData.resources)

  const totResourceDays = resources.reduce((result: number, rsc: any) => {
    return result + rsc.totalDays
  }, 0)

  let ratePerDay: any = {}
  columns.forEach((column: any) => {
    if (column.path === '_totalWorkstreams') {
      ratePerDay[column.path] = pricingPlanData.summaryViewModel.detailedSummary.ratePerDay.inclContingency
    } else {
      const phaseResources = getPhaseResources(resources, column.startDate, column.endDate)
      // const days = phaseResources.reduce((result: number, resource: any) => {
      //   const totalDays = getTotalDays(resource)
      //   result += totalDays
      //   return result
      // }, 0)

      let onshoreDays = 0
      let gdsDays = 0
      let subcontractorDays = 0
      for (let i = 0; i < phaseResources.length; i++) {
        if (phaseResources[i].source === 'onshore') {
          onshoreDays += getTotalDays(phaseResources[i])
        } else if (phaseResources[i].source === 'gdsOffshore') {
          gdsDays += getTotalDays(phaseResources[i])
        } else if (phaseResources[i].source === 'subcontractor') {
          subcontractorDays += getTotalDays(phaseResources[i])
        }
      }
      let onshoreContingency
      let gdsContingency
      let subContingency
      if (pricingPlanData?.summaryViewModel?.contingencyTableValues?.resources) {
        onshoreContingency =
          onshoreDays > 0 ? pricingPlanData.summaryViewModel.contingencyTableValues.resources.onshore : 0
        gdsContingency = gdsDays > 0 ? pricingPlanData.summaryViewModel.contingencyTableValues.resources.gds : 0
      }

      let rscOnshore = pricingPlanData.resources.filter((resourcedtl: any) => resourcedtl.source === 'onshore')
      let rscgdsOffshore = pricingPlanData.resources.filter((resourcedtl: any) => resourcedtl.source === 'gdsOffshore')
      const onShoreTotHrs =
        rscOnshore?.length > 0 &&
        rscOnshore.reduce((totLaborCost: number, item: any) => {
          if (item.totalStandardCost && !isNaN(item.totalStandardCost)) {
            return totLaborCost + item.totalStandardCost
          }
          return totLaborCost
        }, 0)

      const gdsOffshoreTotHrs =
        rscgdsOffshore?.length > 0 &&
        rscgdsOffshore.reduce((totLaborCost: number, item: any) => {
          if (item.totalStandardCost && !isNaN(item.totalStandardCost)) {
            return totLaborCost + item.totalStandardCost
          }
          return totLaborCost
        }, 0)

      let EY_STOnshore = onshoreContingency / onShoreTotHrs //pricingPlanData.summaryViewModel.detailedSummary.totalLaborCost.exclContingency
      EY_STOnshore = !isNaN(EY_STOnshore) ? EY_STOnshore : 0
      let EY_GDS = gdsContingency / gdsOffshoreTotHrs
      EY_GDS = !isNaN(EY_GDS) ? EY_GDS : 0
      const onshoreDaysContingency = onshoreDays * EY_STOnshore + onshoreDays
      const gdsDaysContingency = gdsDays * EY_GDS + gdsDays
      //const subcontractorDaysContingency = subcontractorDays + subcontractorDays * (EY_SUB / 100)

      const totalResourceDaysContingency = onshoreDaysContingency + gdsDaysContingency + subcontractorDays //+ subcontractorDaysContingency

      // get Billing Excluding Expenses
      const billingExcludingExpensesRow = rows.find((row) => row.name === 'Billing Excluding Expenses')
      const billingExcludingExpenses = billingExcludingExpensesRow[column.path]
      //calculate total
      //const total = billingExcludingExpenses / getRounded(days, 1)
      const total =
        //(days * pricingPlanData?.summaryViewModel?.detailedSummary?.ratePerDay?.inclContingency) / totResourceDays
        Math.ceil(billingExcludingExpenses / totalResourceDaysContingency)
      ratePerDay[column.path] = getRounded(isFinite(total) ? total : 0)
    }
  })
  return { ...ratePerDay, name: 'Rate / Day', cost: true }
}

const calculateTotalBillings = (columns: any[], rows: any[], pricingPlanData: any) => {
  const totalBillings: any = {}
  let totBillingAmt = 0
  columns.forEach((column: any) => {
    const totalBillingAmountRow = rows.find((row) => row.name === 'Total Billing Amount ')
    // get Billing Excluding Expenses

    const billingExcludingExpensesRow = rows.find((row) => row.name === 'Billing Excluding Expenses')
    const billingExcludingExpenses = billingExcludingExpensesRow[column.path]
    //get Cost of Subcontractors
    const costOfSubcontractorsRow = rows.find((row) => row.name === 'Cost of Subcontractors')
    const costOfSubcontractors = costOfSubcontractorsRow[column.path]
    // get Total Expenses
    const totalExpensesRow = rows.find((row) => row.name === 'Total Expenses')
    const totalExpenses = totalExpensesRow[column.path]
    // const total = billingExcludingExpenses + costOfSubcontractors + totalExpenses
    let total = billingExcludingExpenses + totalExpenses
    if (column.path === '_unKnown') {
      total = parseInt(billingExcludingExpenses) + parseInt(totalExpenses)
    }
    totalBillings[column.path] = getRounded(total)
  })

  return { ...totalBillings, name: 'Total Billing Amount ', cost: true }
}

type Props = {
  pricingPlanData: any
  PNote:any
}

const PhasesSummary = (props: Props) => {
  const { pricingPlanData } = props
  const{PNote}=props

  const { planCurrency, dealStartDate, dealEndDate } = pricingPlanData

  const [inflationRate, setinflationRate] = useState(0)

  useEffect(() => {
    ;(async () => {
      try {
        const inflationRate = await getInflationRates(pricingPlanData.country)
        setinflationRate(inflationRate)
      } catch (error) {}
    })()
  }, [])

  let columns: any = generateColumns(pricingPlanData.phases)
  let rows: any = [],
    rows1: any = [],
    rowsunknown: any = []

  const standardCost1 = calculateStandardCost(pricingPlanData, columns, dealStartDate, dealEndDate, inflationRate, rows)
  rows1 = [...rows, standardCost1]

  const standardCost = calculateStandardCost(pricingPlanData, columns, dealStartDate, dealEndDate, inflationRate, rows1)

  rows = [...rows, standardCost]

  const dedicatedCost1 = calculateDedicatedCost(
    pricingPlanData,
    columns,
    dealStartDate,
    dealEndDate,
    inflationRate,
    rows
  )
  rows1 = [...rows, dedicatedCost1]

  const dedicatedCost = calculateDedicatedCost(
    pricingPlanData,
    columns,
    dealStartDate,
    dealEndDate,
    inflationRate,
    rows1
  )
  rows = [...rows, dedicatedCost]

  const costOfSubcontractors = calculateCostOfSubcontractors(
    pricingPlanData,
    columns,
    dealStartDate,
    dealEndDate,
    inflationRate
  )
  rows = [...rows, costOfSubcontractors]

  const totalExpenses = calculateTotalExpenses(pricingPlanData, columns, rows)
  rows = [...rows, totalExpenses]

  const billingExcludingExpenses = calculateBillingExcludingExpenses(
    pricingPlanData.summaryViewModel,
    pricingPlanData,
    columns,
    dealStartDate,
    dealEndDate,
    inflationRate,
    rows
  )
  rows = [...rows, billingExcludingExpenses]

  const eyStandardMargin = calculateEYStandardMargin(pricingPlanData.summaryViewModel, columns, rows)
  rows = [...rows, eyStandardMargin]

  const eyMarginUsingDCR = calculateEyMarginUsingDCR(pricingPlanData.summaryViewModel, columns, rows)
  rows = [...rows, eyMarginUsingDCR]

  const eyStandardMarginExclPC = calculateEyStandardMarginExclPC(
    pricingPlanData,
    columns,
    rows,
    dealStartDate,
    dealEndDate,
    inflationRate
  )
  rows = [...rows, eyStandardMarginExclPC]

  const eyMarginUsingDCRExclPC = calculateEyMarginUsingDCRExclPC(
    pricingPlanData,
    columns,
    rows,
    dealStartDate,
    dealEndDate,
    inflationRate
  )
  rows = [...rows, eyMarginUsingDCRExclPC]

  const ratePerHour = calculateRatePerHour(pricingPlanData, columns, rows)
  rows = [...rows, ratePerHour]

  const ratePerDay = calculateRatePerDay(pricingPlanData, columns, rows)
  rows = [...rows, ratePerDay]

  // const totalBillings1 = calculateTotalBillings(columns, rows, pricingPlanData)
  // rows = [...rows, totalBillings1]

  const totalBillings = calculateTotalBillings(columns, rows, pricingPlanData)
  rows = [...rows, totalBillings]

  const _rows = [
    totalBillings,
    totalExpenses,
    billingExcludingExpenses,
    standardCost,
    //dedicatedCost,
    costOfSubcontractors,
    eyStandardMargin,
    eyMarginUsingDCR,
    eyStandardMarginExclPC,
    eyMarginUsingDCRExclPC,
    //totalExpenses,
    ratePerDay,
    ratePerHour
  ]


  columns = [{ title: 'name', path: 'name' }, ...columns]

  return (
    <>
      <header>
        <div className='title'>
          Phases Summary
          <span className='title-note'>{PNote}</span>
        </div>
      </header>
      {pricingPlanData.phases?.length > 0 &&
        (pricingPlanData.summaryViewModel.selectedScenario === 'client' ||
          pricingPlanData.summaryViewModel.selectedScenario === 'fixedFee' ||
          pricingPlanData.summaryViewModel.selectedScenario === 'targetMargin') && (
          <div className='phases-summary-table'>
            <div className='thead'>
              <div className='tr'>
                {columns.map((column: any) => (
                  <div className='th' key={column.path}>
                    <span>{column.path === 'name' ? '' : column.title}</span>
                  </div>
                ))}
              </div>
            </div>
            <div className='tbody'>
              {_rows.map((item: any) => (
                <div className='tr' key={item.name}>
                  {columns.map(({ path }: any) => (
                    <div className='td' key={path}>
                      {item.name === 'EY Margin %'
                        ? path === 'name'
                          ? item[path]
                          : `${getRounded(item[path] * 100, 1).toFixed(1)} %`
                        : `${item.cost || path === 'name' ? numberWithCommas(item[path]) : item[path].toFixed(1)} ${
                            path === 'name' ? '' : item.cost ? planCurrency : '%'
                          }`}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        )}
      {pricingPlanData.phases?.length <= 0 ||
        (pricingPlanData.summaryViewModel.selectedScenario === 'base' && (
          <div className='phasesComment'>This section is in finalisation and will be available shortly.</div>
        ))}
    </>
  )
}

export default PhasesSummary
