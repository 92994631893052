import React, { useReducer, useEffect, useRef, useState, useMemo, useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Formik, Form, FieldArray } from 'formik'
import moment from 'moment'
import NavMenu from '../navigation/NavMenu'
import Resources from './Resources'
import Footer from '../footer/Footer'
import Header from '../header/Header'
import AddResourceModal from './modal/AddResourceModal'
import { MarketScopeType, RouteParamTypes } from './ResourceTypes'
import {
  getInflationRates,
  getPricingPlan,
  updatePricingPlan,
  getAllInflationRates,
  getgdsinflation
} from '../utils/fetchHandler'
import Loading from '../loading/Loading'
import {
  getRounded,
  getTotalLabourCost,
  getTotalStandardCost,
  getTotalDedicatedCost,
  getTotalClientRate,
  getTotalTimeUnits,
  numberWithCommas,
  getTotalMarketCost
} from './ResourceTableComponents'
import { validationSchema } from './validations'
import { UserContext } from '../../App'

type stateType = {
  isLoading: boolean
  view: 'edit' | 'list'
  isOpenAddResourceModal: boolean
  initialValues: any
  viewedColumns: any
  inflationRate: any
  countryInflationRate: any
  gdsInflation: any //temporary
}

const initialState = {
  isLoading: true,
  view: 'edit' as stateType['view'],
  isOpenAddResourceModal: false,
  initialValues: {},
  viewedColumns: ['workstream', 'role'],
  inflationRate: {},
  countryInflationRate: {},
  gdsInflation: {} // temporary
}

const reducer = (state: stateType, payload: any) => {
  return { ...state, ...payload }
}

const ResourcesContainer = (props: any) => {
  const formikRef = useRef<any>()
  const arrayHelpersRef = useRef<any>()
  const [state, setState] = useReducer(reducer, initialState)
  const { isLoading, inflationRate, countryInflationRate, gdsInflation } = state

  const { pricingPlanId } = useParams<RouteParamTypes>()
  const [nextButtonPressed, setNextButtonPressed] = useState(false)
  const [highestAccessedPage, setHighestAccessedPage] = useState<string>('')
  const userId = useContext(UserContext)[1]
  const history = useHistory()
  const [isEditable, setIsEditable] = useState(true)

  const generateInitialValues = async (pricingPlanData: any) => {
    const convertDate = (date: Date) => new Date(date)
    let initialValues = {
      ...pricingPlanData,
      ...(pricingPlanData?.dealStartDate && {
        dealStartDate: convertDate(pricingPlanData.dealStartDate)
      }),
      ...(pricingPlanData?.dealEndDate && {
        dealEndDate: convertDate(pricingPlanData.dealEndDate)
      }),
      ...(pricingPlanData.workstreams?.length > 0 && {
        workstreams: pricingPlanData.workstreams.map((workstream: any) => {
          return {
            ...workstream,
            ...(workstream?.startDate && {
              startDate: convertDate(workstream.startDate)
            }),
            ...(workstream?.endDate && {
              endDate: convertDate(workstream.endDate)
            })
          }
        })
      }),
      ...(pricingPlanData.resources?.length > 0 && {
        resources: pricingPlanData.resources.map((resource: any) => {
          return {
            ...resource,
            ...(resource.startDate && {
              startDate: convertDate(resource.startDate)
            }),
            ...(resource.endDate && {
              endDate: convertDate(resource.endDate)
            }),
            ...(resource.promotedResource && {
              promotedResource: {
                ...resource.promotedResource,
                ...(resource.promotedResource.startDate && {
                  startDate: convertDate(resource.promotedResource.startDate)
                }),
                ...(resource.promotedResource.endDate && {
                  endDate: convertDate(resource.promotedResource.endDate)
                })
              }
            })
          }
        })
      })
    }
    return initialValues
  }

  const determineAccessibility = (data: {
    planStatus: any
    isDelegated: any
    isCheckedIn: any
    delegateAccess: any
  }) => {
    const { planStatus, isDelegated, isCheckedIn, delegateAccess } = data

    if (planStatus === 'Owned') {
      if (isDelegated) {
        return isCheckedIn
      } else {
        return true
      }
    } else {
      return isCheckedIn
    }
  }

  useEffect(() => {
    ;(async () => {
      setState({ isLoading: true })
      if (pricingPlanId === 'new') {
        history.push(`/pricing-plan/new/global-input`)
      } else {
        try {
          const pricingPlanData = await getPricingPlan(pricingPlanId, userId)
          setIsEditable(determineAccessibility(pricingPlanData))
          setHighestAccessedPage(pricingPlanData.highestAccessedPage)
          const initialValues = await generateInitialValues(pricingPlanData)

          const inflationRate = await getInflationRates(pricingPlanData.country)
          const countryInflationRate = await getAllInflationRates()
          const gdsInflation = await getgdsinflation()

          setState({
            ...state,
            isLoading: false,
            initialValues,
            inflationRate,
            countryInflationRate,
            gdsInflation
          })
        } catch (error) {}
      }
    })()
  }, [pricingPlanId])

  const toggleView = () => {
    setState({
      view: state.view === 'edit' ? 'list' : 'edit'
    })
  }

  const generateWeeklyTimeUnits = (dealStartDate: Date, dealEndDate: Date) => {
    let result = []
    if (dealStartDate && dealEndDate) {
      const start = moment(dealStartDate).day(1)
      const end = moment(dealEndDate)
      result.push({
        startDate: moment(start).format('YYYY-MM-DDT00:00:00'),
        timeUnits: 0
      })
      let tmp = start.add(7, 'days')
      while (tmp.isSameOrBefore(end)) {
        result.push({
          startDate: moment(tmp).format('YYYY-MM-DDT00:00:00'),
          timeUnits: 0
        })
        tmp.add(7, 'days')
      }
    }
    return result
  }
  const addResource = async (
    isNamed: boolean = true,
    resource: any,
    marketScopes: any,
    countries: any,
    countryId: any,
    marketScopeId: any,
    availableMarketScopes: any,
    marketRate: any
  ) => {
    const {
      initialValues: { dealStartDate, dealEndDate }
    } = state
    let newResource: any = {
      workstream: '',
      role: '',
      rank: '',
      employee: resource.employee,
      clientRateHour: 0,
      clientRateDay: 0,
      totalTimeUnits: 0,
      startDate: null,
      endDate: null,
      costCentre: '',
      costRate: 0,
      dcrRate: 0,
      timeUnitsPerWeek: null,
      weeklyTimeUnits: [],
      isGDS: null,
      totalCost: 0,
      totalStandardCost: 0,
      totalDedicatedCost: 0,
      location: '',
      marketScopeData: [],
      marketScopeId: -1,
      availableMarketScopes: [],
      availableCountryTier: [],
      countryId: -1,
      marketRate: 0,
      targetCurrencyId: -1,
      sectorId: -1,
      competencyId: -1,
      applicationId: -1,
      roleId: -1,
      totalMarketRate: 0,
      marketRateByUser: 0,
      isMarketRateNull: false,
      nsrRate: 0
    }
    if (isNamed) {
      let isConfigState = false
      let isUK = false
      isConfigState = ['Australia', 'New Zealand'].includes(resource.costCountry)
      isUK = ['United Kingdom'].includes(resource.costCountry)
      newResource = {
        ...newResource,
        source: 'onshore',
        costCentre: resource.costCentre,
        costCountry: resource.costCountry,
        isGDS: false,
        timeUnitsPerWeek: isUK ? 35 : isConfigState ? 37.5 : 40,
        costRate: getRounded(resource.costRate, 2),
        nsrRate: getRounded(resource.nsrRate, 2),
        dcrRate: getRounded(resource.dcrRate, 2),
        rank: resource.rankName,
        marketScopeData: marketScopes,
        marketScopeId: marketScopeId,
        availableCountryTier: countries,
        countryId: countryId,
        availableMarketScopes: availableMarketScopes,
        marketRate: marketRate,
        targetCurrencyId: marketScopes?.targetCurrencyId,
        sectorId: marketScopes?.sectorId,
        applicationId: marketScopes?.applicationId,
        competencyId: marketScopes?.marketScopes[0]?.competencyId
      }
    } else {
      const isGDS = ['gdsLanded', 'gdsOffshore'].includes(resource.source)
      const isGDSconfig = ['India'].includes(resource.area)
      let isGDSconfig1 = false
      let isGDSconfig2 = false
      isGDSconfig2 = ['United Kingdom'].includes(resource.serviceLine.split('/')[0])

      if (isGDS) {
        isGDSconfig1 = ['United Kingdom'].includes(resource.area)
      } else {
        isGDSconfig1 = ['Australia', 'New Zealand'].includes(resource.serviceLine.split('/')[0])
      }

      newResource = {
        ...newResource,
        isGDS,
        source: resource.source,
        timeUnitsPerWeek: isGDS && isGDSconfig ? 45 : isGDSconfig2 ? 35 : isGDSconfig1 ? 37.5 : 40,
        dropdownOptions: {
          area: resource.area,
          region: resource.region,
          serviceLine: resource.serviceLine,
          subServiceLine: resource.subServiceLine,
          subServiceLineGDS: resource.subServiceLineGDS,
          competency: resource.competency
        },

        location: isGDS ? resource.area : resource.serviceLine.split('/')[0],
        costCountry: isGDS ? resource.area : resource.serviceLine.split('/')[0],
        serviceLine: resource.serviceLine,
        subServiceLine: resource.subServiceLine,
        subServiceLineGDS: resource.subServiceLineGDS,
        competency: resource.competency,
        competencyId: marketScopes?.marketScopes[0]?.competencyId,
        targetCurrencyId: marketScopes?.targetCurrencyId,
        sectorId: marketScopes?.sectorId,
        applicationId: marketScopes?.applicationId,
        roleId: marketScopes?.roleId,
        marketScopeId: marketScopeId,
        countryId: countryId,
        availableMarketScopes: availableMarketScopes,
        availableCountryTier: countries,
        marketRate: marketRate,
        marketScopeData: marketScopes
      }
    }
    if (dealStartDate && dealEndDate) {
      newResource.startDate = dealStartDate
      newResource.endDate = dealEndDate
      newResource.weeklyTimeUnits = generateWeeklyTimeUnits(dealStartDate, dealEndDate)
    }

    if (arrayHelpersRef?.current) {
      //Check duplicate resource entry
      let _employeeVal = arrayHelpersRef?.current?.form?.values?.resources.find(
        (_resource: any) =>
          _resource.costCentre === newResource.costCentre &&
          _resource.employee === newResource.employee &&
          _resource.costRate === newResource.costRate &&
          _resource.costCountry === newResource.costCountry &&
          _resource.source === newResource.source &&
          newResource.source !== 'subcontractor' //CS0525769 - Multiple Subcontractors adding issue
      )?.employee
      if (_employeeVal != undefined) {
        //Can show popup message here if required
      } else arrayHelpersRef.current.push(newResource)
    }
  }

  const toggleAddResourceModal = (control: boolean) => {
    setState({
      isOpenAddResourceModal: control
    })
  }

  const generateValuesToSave = (values: any) => {
    let pricingPlanData = values
    const convertDate = (date: Date) => moment(date).format('YYYY-MM-DDT00:00:00')
    let generatedValues = {
      ...pricingPlanData,
      ...(pricingPlanData?.dealStartDate && {
        dealStartDate: convertDate(pricingPlanData.dealStartDate)
      }),
      ...(pricingPlanData?.dealEndDate && {
        dealEndDate: convertDate(pricingPlanData.dealEndDate)
      }),
      ...(pricingPlanData.workstreams?.length > 0 && {
        workstreams: pricingPlanData.workstreams.map((workstream: any) => {
          return {
            ...workstream,
            ...(workstream?.startDate && {
              startDate: convertDate(workstream.startDate)
            }),
            ...(workstream?.endDate && {
              endDate: convertDate(workstream.endDate)
            })
          }
        })
      }),
      ...(pricingPlanData.resources?.length > 0 && {
        resources: pricingPlanData.resources.map((resource: any) => {
          return {
            ...resource,
            ...(resource.startDate && {
              startDate: convertDate(resource.startDate)
            }),
            ...(resource.endDate && {
              endDate: convertDate(resource.endDate)
            }),
            ...(resource.isGDS &&
              resource.dropdownOptions && {
                costCentre:
                  resource.dropdownOptions.serviceLine +
                  '||~' +
                  resource.dropdownOptions.subServiceLineGDS +
                  '||~' +
                  resource.dropdownOptions.competency
              }),
            ...(resource.isGDS &&
              !resource.dropdownOptions &&
              resource.costCentreData && {
                costCentre: resource.costCentreData
              }),
            ...(resource.promotedResource && {
              promotedResource: {
                ...resource.promotedResource,
                ...(resource.promotedResource.startDate && {
                  startDate: convertDate(resource.promotedResource.startDate)
                }),
                ...(resource.promotedResource.endDate && {
                  endDate: convertDate(resource.promotedResource.endDate)
                })
              }
            })
          }
        })
      })
    }
    generatedValues = generateResourcesToSave(generatedValues)
    if (nextButtonPressed) {
      // Update the highest accessed page when next page button pressed
      if (generatedValues.highestAccessedPage === 'Resources') {
        // Only update highestAccessedPage if next page is greater
        generatedValues.highestAccessedPage = 'Expenses'
      }
    }
    return generatedValues
  }

  const generateAvailableCostCentresToSave = (availableCostCentres: any) => {
    if (!availableCostCentres) return []
    return availableCostCentres.map((cs: any) => ({
      costCenterId: cs.costCenterId,
      costCenterDescription: cs.costCenterDescription
    }))
  }

  const ValidateMarketRate = (resource: any) => {
    
    if(resource.marketRate != undefined && !isNaN(resource.marketRate))
      {
        return resource.marketRate
      }
      return 0
  }

  const getNSRRate = (resource: any) => {
    if (resource.nsrRate != undefined && !isNaN(resource.nsrRate)) {
      return resource.nsrRate
    }
    return 0
  }

  const generateResourcesToSave = (values: any) => {
    const { inputUnit, dealStartDate, dealEndDate } = values
    const generatedValues = {
      ...values,
      ...(values.resources?.length > 0 && {
        resources: values.resources.map((resource: any) => {
          let _resource = {
            ...resource,
            isMarketRateNull: false,
            marketRate: ValidateMarketRate(resource),
            nsrRate: getNSRRate(resource),
            totalCost: getTotalLabourCost(
              resource,
              dealStartDate,
              dealEndDate,
              inflationRate,
              countryInflationRate,
              resource.costCountry,
              gdsInflation //temporary
            ),
            totalStandardCost: getTotalStandardCost(
              resource,
              dealStartDate,
              dealEndDate,
              inflationRate,
              countryInflationRate,
              gdsInflation //temporary
            ),
            totalDedicatedCost: getTotalDedicatedCost(
              resource,
              dealStartDate,
              dealEndDate,
              inflationRate,
              countryInflationRate,
              gdsInflation //temporary
            ),
            totalClientRate: getTotalClientRate(
              resource,
              dealStartDate,
              dealEndDate,
              inflationRate,
              countryInflationRate,
              gdsInflation //temporary
            ),
            totalMarketRate: getTotalMarketCost(resource, dealStartDate, dealEndDate),
            ...(resource.weeklyTimeUnits?.length > 0 && {
              totalTimeUnits: getTotalTimeUnits(resource, inputUnit)
            }),
            ...(resource.availableCostCentres && {
              availableCostCentres: generateAvailableCostCentresToSave(resource.availableCostCentres)
            }),
            ...(resource.promotedResource && {
              promotedResource: {
                ...resource.promotedResource,
                marketRate: ValidateMarketRate(resource.promotedResource),
                nsrRate: getNSRRate(resource.promotedResource),
                totalCost: getTotalLabourCost(
                  resource.promotedResource,
                  dealStartDate,
                  dealEndDate,
                  inflationRate,
                  countryInflationRate,
                  resource.costCountry,
                  gdsInflation //temp
                ),
                totalStandardCost: getTotalStandardCost(
                  resource.promotedResource,
                  dealStartDate,
                  dealEndDate,
                  inflationRate,
                  countryInflationRate,
                  gdsInflation //temporary
                ),
                totalDedicatedCost: getTotalDedicatedCost(
                  resource.promotedResource,
                  dealStartDate,
                  dealEndDate,
                  inflationRate,
                  countryInflationRate,
                  gdsInflation //temporary
                ),
                totalClientRate: getTotalClientRate(
                  resource.promotedResource,
                  dealStartDate,
                  dealEndDate,
                  inflationRate,
                  countryInflationRate,
                  gdsInflation //temporary
                ),
                totalMarketRate: getTotalMarketCost(resource.promotedResource, dealStartDate, dealEndDate),
                workstream: resource.workstream,
                role: resource.role,
                source: resource.source,
                costCentre: resource.costCentre,
                employee: resource.employee,
                ...(resource.promotedResource.weeklyTimeUnits?.length > 0 && {
                  totalTimeUnits: getTotalTimeUnits(resource.promotedResource, inputUnit)
                })
              }
            })
          }
          return _resource
        })
      })
    }
    return generatedValues
  }

  const onSubmitForm = async (values: any, actions: any) => {
    let generatedValues = generateValuesToSave(values)
    if (pricingPlanId === 'new') {
    } else {
      try {
        if (isEditable) {
          await updatePricingPlan(generatedValues)
          const pricingPlanData = await getPricingPlan(pricingPlanId, userId)
          const newValues = await generateInitialValues(pricingPlanData)
          actions.resetForm({ values: newValues })
        }
      } catch (error) {}
    }
  }

  const handleChangeViewedColums = (checked: boolean, value: string) => {
    const { viewedColumns } = state
    if (checked && viewedColumns.length === 3) {
    } else {
      if (!checked) {
        const updatedColums = viewedColumns.filter((item: any) => item !== value)
        setState({ viewedColumns: updatedColums })
      } else {
        setState({ viewedColumns: [...viewedColumns, value] })
      }
    }
  }

  const generateTotalCostForSummary = (resources: any) => {
    const {
      initialValues: { dealStartDate, dealEndDate }
    } = state
    const total =
      resources?.length > 0 &&
      resources.reduce((result: number, resource: any, index: number) => {
        result += getTotalLabourCost(
          resource,
          dealStartDate,
          dealEndDate,
          inflationRate,
          countryInflationRate,
          resource.costCountry,
          gdsInflation //temp
        )
        if (resource.promotedResource) {
          result += getTotalLabourCost(
            resource.promotedResource,
            dealStartDate,
            dealEndDate,
            inflationRate,
            countryInflationRate,
            resource.costCountry,
            gdsInflation //temp
          )
        }
        return result
      }, 0)

    return getRounded(total)
  }

  const generateTotalCostForResourceSummary = (resources: any) => {
    const {
      initialValues: { dealStartDate, dealEndDate }
    } = state
    const total =
      resources?.length > 0 &&
      resources.reduce((result: number, resource: any, index: number) => {
        result += resource.totalStandardCost
        if (resource.promotedResource) {
          if (
            resource.promotedResource.totalStandardCost !== undefined &&
            resource.promotedResource.totalStandardCost !== 'undefined' &&
            resource.promotedResource.totalStandardCost !== null
          )
            result += resource.promotedResource.totalCost !== undefined ? resource.promotedResource.totalCost : 0
        }
        return result
      }, 0)

    return getRounded(total)
  }

  const generateTotalMarketRate = (resources: any) => {
    const {
      initialValues: { dealStartDate, dealEndDate }
    } = state
    const total =
      resources?.length > 0 &&
      resources.reduce((result: number, resource: any, index: number) => {
        result += resource.totalMarketRate
        if (resource.promotedResource) {
          if (
            resource.promotedResource.totalMarketCost !== undefined &&
            resource.promotedResource.totalMarketCost !== 'undefined' &&
            resource.promotedResource.totalMarketCost !== null
          )
            result += resource.promotedResource.totalCost !== undefined ? resource.promotedResource.totalCost : 0
        }
        return result
      }, 0)

    return getRounded(total)
  }

  if (isLoading) return <Loading />

  const scrollToError = () => {
    if (document.getElementsByClassName('input-field error')[0]) {
      const errorLoc = document.getElementsByClassName('input-field error')[0].getBoundingClientRect()
      const tableLoc = document.getElementsByClassName('resource-table-wrapper hours-table')[0].getBoundingClientRect()
      // tableDimensions = [widthMin, widthMax, heightMin, heightMax]
      const tableDimensions = [375, window.screen.availWidth - 75, tableLoc.y + 40 + 12, tableLoc.y + tableLoc.height]
      let scrollH = document.querySelector('.resource-table-wrapper.hours-table')?.scrollLeft
      let scrollV = document.querySelector('.resource-table-wrapper.hours-table')?.scrollTop
      if (errorLoc.left < tableDimensions[0] || errorLoc.right + 10 > tableDimensions[1]) {
        if (scrollH !== undefined) scrollH += errorLoc.left - tableDimensions[0] - 10
      }
      if (errorLoc.top < tableDimensions[2] || errorLoc.bottom + 15 + 12 > tableDimensions[3]) {
        if (scrollV !== undefined) scrollV += errorLoc.top - tableDimensions[2]
      }
      document
        .querySelector('.resource-table-wrapper.hours-table')
        ?.scrollTo({ top: scrollV, left: scrollH, behavior: 'smooth' })
    }
  }

  return (
    <>
      <Header isFixedWidth={true} />
      <div className='resources-container-wrapper'>
        <Formik
          initialValues={state.initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => onSubmitForm(values, actions)}
        >
          {(formik) => {
            formikRef.current = formik

            return (
              <Form>
                <NavMenu
                  worksheetData={formik.values}
                  selectedTab={1}
                  highestAccessedPage={highestAccessedPage}
                  onNavSaveClick={(tabnum) => {
                    if (formik.isValid) {
                      formik.submitForm().then(() => {
                        if (tabnum == 0) history.push(`/pricing-plan/${pricingPlanId}/global-input`)
                        if (tabnum == 1) history.push(`/pricing-plan/${pricingPlanId}/resources`)
                        if (tabnum == 2) history.push(`/pricing-plan/${pricingPlanId}/expenses`)
                        if (tabnum == 3) history.push(`/pricing-plan/${pricingPlanId}/scenarios`)
                        if (tabnum == 4) history.push(`/pricing-plan/${pricingPlanId}/summary`)
                      })
                    }
                  }}
                />
                <div className={`inputs-main `} style={isEditable ? {} : { pointerEvents: 'none' }}>
                  <FieldArray name='resources'>
                    {(arrayHelpers) => {
                      arrayHelpersRef.current = arrayHelpers

                      return (
                        <Resources
                          view={state.view}
                          viewedColumns={state.viewedColumns}
                          handleChangeViewedColums={handleChangeViewedColums}
                          toggleView={toggleView}
                          toggleAddResourceModal={toggleAddResourceModal}
                          deleteResource={arrayHelpers.remove}
                          duplicateResource={arrayHelpers.push}
                          inflationRate={inflationRate}
                          countryInflationRate={countryInflationRate}
                          gdsInflation={gdsInflation} //temporary
                        />
                      )
                    }}
                  </FieldArray>
                </div>
                <div className='resource-sticky-footer'>
                  <div className='resources-summary-container'>
                    <p className='body-text'>Total Resources: {formik.values.resources?.length || 0}</p>
                    <p className='body-text'>
                      Total Hours:{' '}
                      {formik.values.resources?.reduce((result: number, resource: any, index: number) => {
                        let hours =
                          resource?.weeklyTimeUnits?.length > 0 &&
                          resource.weeklyTimeUnits.reduce((result: number, item: any) => {
                            if (item.timeUnits && !isNaN(item.timeUnits)) {
                              return result + item.timeUnits
                            }
                            return result
                          }, 0)

                        //result += resource.totalHours
                        result += hours
                        if (resource.promotedResource) {
                          // if (
                          //   resource.promotedResource.totalHours !== undefined &&
                          //   resource.promotedResource.totalHours !== 'undefined' &&
                          //   resource.promotedResource.totalHours !== null
                          // )
                          //   result += resource.promotedResource.totalHours
                          let hours =
                            resource?.promotedResource?.weeklyTimeUnits?.length > 0 &&
                            resource.promotedResource.weeklyTimeUnits.reduce((result: number, item: any) => {
                              if (item.timeUnits && !isNaN(item.timeUnits)) {
                                return result + item.timeUnits
                              }
                              return result
                            }, 0)
                          result += hours
                        }
                        return result
                      }, 0)}
                    </p>
                    <p className='body-text'>
                      Total Labor Cost: {numberWithCommas(generateTotalCostForResourceSummary(formik.values.resources))}{' '}
                    </p>
                  </div>

                  <Footer
                    worksheetData={formik.values}
                    onChangeWorksheet={(e: React.ChangeEvent<HTMLInputElement>) => {
                      formik.setFieldValue('worksheetName', e.target.value)
                    }}
                    nextPage={'Expenses'}
                    onClickSaveButton={() => {
                      formikRef && formikRef.current.handleSubmit()
                      scrollToError()
                    }}
                    onClickNextButton={() => {
                      setNextButtonPressed(true)
                      formik.submitForm().then(() => {
                        if (formik.isValid) {
                          history.push(`/pricing-plan/${pricingPlanId}/expenses`)
                        }
                      })
                    }}
                    onClickBackButton={() => {
                      formik.submitForm().then(() => {
                        // Does NOT perform validation
                        history.push(`/pricing-plan/${pricingPlanId}/global-input`)
                      })
                    }}
                    isSaveButtonDisabled={!(formik.dirty && formik.isValid)}
                  />
                </div>
              </Form>
            )
          }}
        </Formik>

        <AddResourceModal
          isOpen={state.isOpenAddResourceModal}
          toggleAddResourceModal={toggleAddResourceModal}
          addResource={addResource}
          planCurrency={state.initialValues.planCurrency}
        />
      </div>
    </>
  )
}

export default ResourcesContainer
