import './ClientView.css'
import ClientViewBox from './ClientViewBox'

interface ClientViewProps {
  selectedColumn: number
  setSelectedColumn: Function
  formValues: any
  expensesValues: any
  resourcesValues: any
  clientViewValues: any
  setClientViewValues: Function
  planCurrency: string
  inputResources: any
  inputExpenses: any
}

const ClientView = (props: ClientViewProps) => {
  const {
    selectedColumn,
    setSelectedColumn,
    formValues,
    expensesValues,
    resourcesValues,
    clientViewValues,
    setClientViewValues,
    planCurrency,
    inputResources,
    inputExpenses
  } = props

  return (
    <div className='client-view-container'>
      <div className='client-view-categories'>
        <p className='body-text'>Fees for Services</p>
        <p className='body-text'>Cost for Subcontractors</p>
        <p className='body-text'>Total Expenses</p>
        <p className='body-text'>Total Billing Amount</p>
      </div>
      <div className='eyview-boxes-container'>
        {/* <ClientViewBox
          title='Base'
          selectedColumn={selectedColumn}
          setSelectedColumn={setSelectedColumn}
          columnNum={0}
          formValues={formValues}
          expensesValues={expensesValues}
          resourcesValues={resourcesValues}
          clientViewValues={clientViewValues}
          setClientViewValues={setClientViewValues}
          planCurrency={planCurrency}
          inputResources={inputResources}
          inputExpenses={inputExpenses}
        /> */}
        <ClientViewBox
          title='Market Rate Card'
          selectedColumn={selectedColumn}
          setSelectedColumn={setSelectedColumn}
          columnNum={0}
          formValues={formValues}
          expensesValues={expensesValues}
          resourcesValues={resourcesValues}
          clientViewValues={clientViewValues}
          setClientViewValues={setClientViewValues}
          planCurrency={planCurrency}
          inputResources={inputResources}
          inputExpenses={inputExpenses}
        />
        {/* <ClientViewBox
          title='Target Margin %'
          selectedColumn={selectedColumn}
          setSelectedColumn={setSelectedColumn}
          columnNum={0}
          formValues={formValues}
          expensesValues={expensesValues}
          resourcesValues={resourcesValues}
          clientViewValues={clientViewValues}
          setClientViewValues={setClientViewValues}
          planCurrency={planCurrency}
          inputResources={inputResources}
          inputExpenses={inputExpenses}
        /> */}
        <ClientViewBox
          title='Target Margin %'
          selectedColumn={selectedColumn}
          setSelectedColumn={setSelectedColumn}
          columnNum={1}
          formValues={formValues}
          expensesValues={expensesValues}
          resourcesValues={resourcesValues}
          clientViewValues={clientViewValues}
          setClientViewValues={setClientViewValues}
          planCurrency={planCurrency}
          inputResources={inputResources}
          inputExpenses={inputExpenses}
        />
        <ClientViewBox
          title='Fixed Fee/Price'
          selectedColumn={selectedColumn}
          setSelectedColumn={setSelectedColumn}
          columnNum={2}
          formValues={formValues}
          expensesValues={expensesValues}
          resourcesValues={resourcesValues}
          clientViewValues={clientViewValues}
          setClientViewValues={setClientViewValues}
          planCurrency={planCurrency}
          inputResources={inputResources}
          inputExpenses={inputExpenses}
        />
        <ClientViewBox
          title='Client Rate Card'
          selectedColumn={selectedColumn}
          setSelectedColumn={setSelectedColumn}
          columnNum={3}
          formValues={formValues}
          expensesValues={expensesValues}
          resourcesValues={resourcesValues}
          clientViewValues={clientViewValues}
          setClientViewValues={setClientViewValues}
          planCurrency={planCurrency}
          inputResources={inputResources}
          inputExpenses={inputExpenses}
        />

        {/* <ClientViewBox
          title='Market Rate Card'
          selectedColumn={selectedColumn}
          setSelectedColumn={setSelectedColumn}
          columnNum={3}
          formValues={formValues}
          expensesValues={expensesValues}
          resourcesValues={resourcesValues}
          clientViewValues={clientViewValues}
          setClientViewValues={setClientViewValues}
          planCurrency={planCurrency}
          inputResources={inputResources}
          inputExpenses={inputExpenses}
        /> */}
      </div>
    </div>
  )
}

export default ClientView
