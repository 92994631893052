import React from 'react'
import './TechnologyExpenses.css'
import { numberWithCommas } from '../../utils/helperFunctions'

const TechnologyExpenses = (props: any) => {
  const { pricingPlanData } = props
  const technologyExpenses = pricingPlanData.expenses?.filter((expense: any) => expense.expenseType === 'Technology')

  let rows = [
    { label: 'CT Products (Direct Cost)', name: 'ctProducts', cost: 0, revenue: 0 }
    // { label: 'Other Technology (Expense)', name: 'otherProducts', cost: 0, revenue: 0 },
  ]

  rows =
    technologyExpenses?.length > 0
      ? rows.map((row: any) => {
          const items = technologyExpenses.filter((item: any) => item.category === row.name)
          const cost = items?.reduce((total: any, item: any) => {
            return (total += item.cost || 0)
          }, 0)
          const revenue = items?.reduce((total: any, item: any) => {
            return (total += item.revenue || 0)
          }, 0)
          return { ...row, cost, revenue }
        })
      : rows

  const totalCost = rows.reduce((result: number, row: any) => {
    return (result += row.cost || 0)
  }, 0)
  const totalRevenue = rows.reduce((result: number, row: any) => {
    return (result += row.revenue || 0)
  }, 0)

  rows = [...rows, { label: 'Total', name: 'total', cost: totalCost, revenue: totalRevenue }]

  return (
    <>
      <header>
        <div className='title'>CT Product Expenses</div>
      </header>

      <div className='technology-expense-table'>
        <div className='technology-expense-item'></div>
        <div className='technology-expense-item cost'>Cost</div>
        <div className='technology-expense-item'>Revenue</div>

        <div className='technology-expense-item category'>CT Products (Direct Cost)</div>
        <div className='bold technology-expense-item cost'>
          {numberWithCommas(rows[0].cost) + ' ' + pricingPlanData.planCurrency}
        </div>
        <div className='bold technology-expense-item'>
          {numberWithCommas(rows[0].revenue) + ' ' + pricingPlanData.planCurrency}
        </div>

        {/* <div className='technology-expense-item category'>Other Technology (Expense)</div>
        <div className='bold technology-expense-item cost'>
          {numberWithCommas(rows[1].cost) + ' ' + pricingPlanData.planCurrency}
        </div>
        <div className='bold technology-expense-item'>
          {numberWithCommas(rows[1].revenue) + ' ' + pricingPlanData.planCurrency}
        </div> */}

        <div className='bold technology-expense-item category'>Total</div>
        <div className='bold technology-expense-item cost'>
          {numberWithCommas(rows[1].cost) + ' ' + pricingPlanData.planCurrency}
        </div>
        <div className='bold technology-expense-item'>
          {numberWithCommas(rows[1].revenue) + ' ' + pricingPlanData.planCurrency}
        </div>
      </div>
    </>
  )
}

export default TechnologyExpenses
