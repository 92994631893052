import { ReactElement, useEffect, useState, useContext } from 'react'
import './PrivacyModal.css'
import NextIcon from '../../assets/next_icon.svg'
import { getAcceptPrivacyStatement, setAcceptPrivacyStatement } from '../utils/fetchHandler'
import { UserContext } from '../../App'

interface PrivacyModalProps {
  isAccepted: boolean
}

const PrivacyModal = (props: PrivacyModalProps): ReactElement => {
  const { isAccepted } = props
  const [privacyStatus, setPrivacyStatus] = useState<boolean>(false) // true === accepted
  const userInfo = useContext(UserContext)

  const handlePrivacy = async () => {
    try {
      if (userInfo[1].length > 1) {
        if (privacyStatus !== true) {
          await setAcceptPrivacyStatement({ id: userInfo[1] })
        }
      }
    } catch (error) {
      console.error('Failed to accept privacy statement')
    }
    const privacyModal = document.getElementById('privacy-modal') as HTMLElement
    privacyModal.style.display = 'none'
  }

  useEffect(() => {
    ;(async () => {
      try {
        if (userInfo[1].length > 1 && isAccepted === false) {
          const _privacyStatus = await getAcceptPrivacyStatement(userInfo[1])
          if (_privacyStatus !== 'Promise') {
            setPrivacyStatus(_privacyStatus)
          }
        }
      } catch (error) {
        console.error('Failed to check privacy statement status (modal)')
      }
    })()
  }, [userInfo])

  return (
    <div
      id='privacy-modal'
      className={privacyStatus === true ? 'privacy-modal-accepted' : 'privacy-modal-not-accepted'}
    >
      <div className='privacy-modal-content'>
        <p className='privacy-modal-heading heading-two'>Privacy Statement</p>
        <div className='privacy-modal-text-container'>
          <p className='heading-three'>1. Introduction</p>
          <p className='body-text'>
            This Privacy Notice is intended to describe the practices EY follows in relation to the Consulting Costing
            Tool (“Tool”) with respect to the privacy of all individuals whose personal data is processed and stored in
            the Tool. This Privacy Notice should be read together with the{' '}
            <a href='https://www.ey.com/en_gl/privacy-statement'>ey.com Privacy Statement</a>, and in case of any
            conflict with the <a href='https://www.ey.com/en_gl/privacy-statement'>ey.com Privacy Statement</a>, the
            terms of this Privacy Notice will prevail. Please read this Privacy Notice carefully.
          </p>
          <p className='heading-three'>2. Who manages the Tool?</p>
          <p className='body-text'>
            “EY” refers to one or more of the member firms of Ernst & Young Global Limited (“EYG”), each of which is a
            separate legal entity and can determine the purposes and means for data processing in its own right (i.e.
            act as a data controller or in a similar capacity). The entity that is acting as data controller (or similar
            capacity) by providing this Tool on which your personal data will be processed and stored is EYGM Limited,
            an EY global entity.
          </p>
          <p className='body-text'>
            The personal data in the Tool is shared by EYGM Limited with one or more member firms of EYG (see “Who can
            access your personal data” section 6 below).
          </p>
          <p className='body-text'>
            The Tool is hosted on servers in EY Managed MS Azure Data Centres in Sydney, Australia and Frankfurt,
            Germany.
          </p>
          <p className='heading-three'>3. Why do we need your personal data?</p>
          <p className='body-text'>
            The Tool enables Consulting engagement teams to cost and price opportunities. The Tool is moving away from
            the current Microsoft excel-based formula/macro-driven system to a dedicated digital solution.
          </p>
          <br />
          <p className='body-text'>
            Your personal data processed in the Tool is used as follows:
            <ul>
              <li className='body-text'>Login data is processed for user authentication using Active Directory.</li>
              <li className='body-text'>
                Consulting Resource data is ingested from EDW and used for costing an opportunity:
              </li>
              <ul>
                <li className='body-text'>Cost center data is used to prepare a detailed pricing plan.</li>
                <li className='body-text'>Billing rate data is used to prepare a detailed pricing plan.</li>
              </ul>
              <li className='body-text'>
                EY client entity names are used to link the correct pricing plan to the correct EY client.
              </li>
            </ul>
          </p>
          <p className='body-text'>
            EY relies on the following basis to legitimize the processing of your personal data in the Tool:
            <p className='body-text'>
              Processing of your personal data is necessary for the purposes of the legitimate interests pursued by the
              data controller or by a third party, except where such interests are overridden by the interests or
              fundamental rights and freedoms of the data subject which require protection of personal data. The
              specific legitimate interests are:
              <ul>
                <li className='body-text'>Conducting client engagements</li>
                <li className='body-text'>
                  Quality & Risk Management, including complying with EY policies (such as EY Pricing directions based
                  on size of potential engagement
                </li>
                <li className='body-text'>Internal administration, including mergers & acquisitions and finance</li>
              </ul>
              <p className='body-text'>
                You have the right to object at any time, on grounds relating to your particular situation, to the
                processing of personal data concerning you based on the above legitimate interest(s).
              </p>
            </p>
          </p>
          <p className='heading-three'>4. What type of personal data is processed in the Tool?</p>
          <p className='body-text'>
            The Tool processes these personal data categories:
            <ul>
              <li className='body-text'>
                Names, and emails of “Users” (EY project manager/senior manager/developer consultant) for the purpose of
                User authentication via the EY Active Directory.
              </li>
              <li className='body-text'>
                Names, geographic location, associated cost centres and associated ranks of EY resources (EY Consulting
                Partners and employees) for the purpose of creating pricing plans.
              </li>
              <li className='body-text'>EY client entity names as ingested from EDW</li>
              <li className='body-text'>
                Log data: admin identifiers for any admin user who triggers a manual reference data update/upload
                through the admin panel
              </li>
            </ul>
          </p>
          <p className='body-text'>
            This data is sourced as follows:
            <ul>
              <li className='body-text'>
                Provided directly by EY Partners, employees or contractors (data input directly into the Tool)
              </li>
              <li className='body-text'>A feed from other EY systems:</li>
              <ul>
                <li className='body-text'>GHRDB (Active Directory for User login data)</li>
                <li className='body-text'>EDW (Consulting Resource data)</li>
                <li className='body-text'>GFIS/Mercury (EY client entity names)</li>
              </ul>
            </ul>
          </p>
          <p className='heading-three'>5. Sensitive personal data</p>
          <p className='body-text'>
            Sensitive personal data reveals your racial or ethnic origin, political opinions, religious or philosophical
            beliefs, trade union membership, genetic data, biometric data, data concerning health or data concerning sex
            life or sexual orientation.
          </p>
          <p className='body-text'>
            EY does not intentionally collect any sensitive personal data from you via the Tool. The Tool’s intention is
            not to process such information. As such, please do not enter any unnecessary personal information or any
            sensitive personal data (including tax file numbers or other government identifiers), client confidential
            information, audit secrets, state secrets, commercial secrets, or anything that would violate professional
            secrecy or confidentiality rules or that would be considered abusive/irrelevant.
          </p>
          <p className='heading-three'>6. Who can access your personal data?</p>
          <p className='body-text'>
            Your personal data is accessed in the Tool by the following persons/teams:
            <ul>
              <li className='body-text'>
                Regular Users will have access to only the records they create within the system
              </li>
              <li className='body-text'>Admin Users will have access to read records created within the system</li>
              <li className='body-text'>Developers will have access to infrastructure</li>
            </ul>
          </p>
          <table className='privacy-modal-table'>
            <tr className='privacy-modal-table-highlight'>
              <th className='privacy-modal-table heading-four'>USER GROUP</th>
              <th className='privacy-modal-table heading-four'>LOCATION</th>
              <th className='privacy-modal-table heading-four'>PURPOSE</th>
              <th className='privacy-modal-table heading-four'>ACCESS</th>
              <th className='privacy-modal-table heading-four'>AMOUNT</th>
            </tr>
            <tr>
              <td className='privacy-modal-table body-text'>
                <b>Group name</b>
              </td>
              <td className='privacy-modal-table body-text'>
                <b>Identify country</b>
              </td>
              <td className='privacy-modal-table body-text'>
                <b>The action that the group performs</b>
              </td>
              <td className='privacy-modal-table body-text'>
                <b>Identify whether access is read, update, add</b>
              </td>
              <td className='privacy-modal-table body-text'>
                <b>Provide the approx. number of users with access</b>
              </td>
            </tr>
            <tr>
              <td className='privacy-modal-table body-text'>Regular Users</td>
              <td className='privacy-modal-table body-text'>Global (EY Member firms)</td>
              <td className='privacy-modal-table body-text'>Create pricing plans.</td>
              <td className='privacy-modal-table body-text'>Read, Update, Add</td>
              <td className='privacy-modal-table body-text'>~10000</td>
            </tr>
            <tr>
              <td className='privacy-modal-table body-text'>Admin Users</td>
              <td className='privacy-modal-table body-text'>US, APAC</td>
              <td className='privacy-modal-table body-text'>
                Extract pricing plans for review; update reference data.
              </td>
              <td className='privacy-modal-table body-text'>Read, Update, Add</td>
              <td className='privacy-modal-table body-text'>4</td>
            </tr>
            <tr>
              <td className='privacy-modal-table body-text'>Developers</td>
              <td className='privacy-modal-table body-text'>Australia, India</td>
              <td className='privacy-modal-table body-text'>Develop the solution and infrastructure.</td>
              <td className='privacy-modal-table body-text'>Read, Update, Add</td>
              <td className='privacy-modal-table body-text'>6</td>
            </tr>
          </table>
          <br />
          <p className='body-text'>
            The access rights detailed above involves transferring personal data in various jurisdictions (including
            jurisdictions outside the European Union) in which EY operates (EY office locations are listed at{' '}
            <a href='www.ey.com/ourlocations'>www.ey.com/ourlocations</a>). An overview of EY network entities providing
            services to external clients is accessible <a href='https://www.ey.com/en_uk/legal-statement'>here</a> (See
            Section 1 (About EY) - “View a list of EY member firms and affiliates”). EY will process your personal data
            in the Tool in accordance with applicable law and professional regulations in your jurisdiction. Transfers
            of personal data within the EY network are governed by EY’s{' '}
            <a href='https://www.ey.com/en_gl/data-protection-binding-corporate-rules-program'>
              Binding Corporate Rules
            </a>
            .
          </p>
          <p className='body-text'>
            We transfer or disclose the personal data we collect to third-party service providers (and their
            subsidiaries and affiliates) who are engaged by us to support our internal ancillary processes. For example,
            we engage service providers to provide, run and support our IT infrastructure (such as identity management,
            hosting, data analysis, back-up, security and cloud storage services) and for the storage and secure
            disposal of our hard copy files. It is our policy to only use third-party service providers that are bound
            to maintain appropriate levels of data protection, security and confidentiality, and that comply with any
            applicable legal requirements for transferring personal data outside the jurisdiction in which it was
            originally collected.{' '}
          </p>
          <p className='body-text'>
            To the extent that personal data has been rendered anonymous in such a way that you or your device are no
            longer reasonably identifiable, such information will be treated as non-personal data and the terms of this
            Privacy Notice will not apply.
          </p>
          <p className='body-text'>
            For data collected in the European Economic Area (EEA) or which relates to individuals in the EEA, EY
            requires an appropriate transfer mechanism as necessary to comply with applicable law. The transfer of
            personal data from the Tool to Microsoft Azure is governed by an agreement between EY and the service
            provider that includes standard data protection clauses adopted by the European Commission.
          </p>
          <p className='heading-three'>7. Data retention</p>
          <p className='body-text'>
            Our policy is to retain personal data only for as long as it is needed for the purposes described in the
            section “Why do we need your personal data”. Retention periods vary in different jurisdictions and are set
            in accordance with local regulatory and professional retention requirements.
          </p>
          <p className='body-text'>
            In order to meet our professional and legal requirements, to establish, exercise or defend our legal rights
            and for archiving and historical purposes, we need to retain information for significant periods of time.
          </p>
          <p className='body-text'>
            The policies and/or procedures for the retention of personal data in the Tool are:
            <ul>
              <li className='body-text'>
                The total retention period is defined and will be implemented in accordance with the EY Records
                Retention Global Policy and the relevant Country Retention Schedule (CRS).
              </li>
              <li className='body-text'>
                Log Data will be retained in accordance with the{' '}
                <a href='https://sites.ey.com/sites/informationsecurity/Policy Library/Logging_Policy.pdf'>
                  EY IT Logging Policy
                </a>
                .
              </li>
            </ul>
            <p className='body-text'>After the end of the data retention period, your personal data will be deleted.</p>
          </p>
          <p className='heading-three'>8. Security</p>
          <p className='body-text'>
            EY protects the confidentiality and security of information it obtains in the course of its business. Access
            to such information is limited, and policies and procedures are in place that are designed to safeguard the
            information from loss, misuse and improper disclosure. Additional information regarding our approach to data
            protection and information security is available in our{' '}
            <a href='https://www.ey.com/publication/vwLUAssets/ey-protecting-your-data/$FILE/ey-protecting-your-data.pdf'>
              Protecting your data
            </a>{' '}
            brochure.
          </p>
          <p className='heading-three'>9. Controlling your personal data</p>
          <p className='body-text'>
            EY will not transfer your personal data to third parties (other than any external parties referred to in
            section 6 above) unless we have your permission or are required by law to do so.
          </p>
          <p className='body-text'>You are legally entitled to request details of EY’s personal data about you.</p>
          <p className='body-text'>
            To confirm whether your personal data is processed in the Tool or to access your personal data in the Tool
            or (where applicable) to withdraw your consent, contact your usual EY representative or email your request
            to <a href='mailto:global.data.protection@ey.com'>global.data.protection@ey.com</a>.
          </p>
          <p className='heading-three'>
            10. Object, rectification, erasure, restriction of processing or data portability
          </p>
          <p className='body-text'>
            You can confirm your personal data is accurate and current. You can object to the processing of your
            personal data or request rectification, erasure, restriction of processing or a readily portable copy of
            your personal data by contacting your usual EY representative or by sending an e-mail to{' '}
            <a href='mailto:global.data.protection@ey.com'>global.data.protection@ey.com</a>.
          </p>
          <p className='heading-three'>11. Complaints</p>
          <p className='body-text'>
            If you are concerned about an alleged breach of privacy law or any other regulation, contact EY’s Global
            Privacy Leader, Office of the General Counsel, 6 More London Place, London, SE1 2DA, United Kingdom or via
            email at <a href='mailto:global.data.protection@ey.com'>global.data.protection@ey.com</a> or via your usual
            EY representative. An EY Privacy Leader will investigate your complaint and provide information about how it
            will be handled and resolved.
          </p>
          <p className='body-text'>
            If you are not satisfied with how EY resolved your complaint, you have the right to complain to your
            country’s data protection authority. You can also refer the matter to a court of competent jurisdiction.{' '}
          </p>
          <p className='body-text'>
            Certain EY member firms in countries outside the European Union (EU) have appointed a representative in the
            EU to act on their behalf if, and when, they undertake data processing activities to which the EU General
            Data Protection Regulation (GDPR) applies. Further information and the contact details of these
            representatives are available <a href='https://www.ey.com/en_gl/data-protection-representative'>here</a>.
          </p>
          <p className='heading-three'>12. Contact us</p>
          <p className='body-text'>
            If you have additional questions or concerns, contact your usual EY representative or email{' '}
            <a href='mailto:global.data.protection@ey.com'>global.data.protection@ey.com</a>.
          </p>
        </div>
        <div className='privacy-modal-buttons'>
          {!isAccepted && (
            <button className='primary-button heading-three' onClick={handlePrivacy}>
              Accept
              <img className='privacy-arrow' src={NextIcon} alt='Arrow pointing to the right' />
            </button>
          )}

          {isAccepted && (
            <button className='primary-button heading-three' onClick={handlePrivacy}>
              Accept
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default PrivacyModal
