import React from 'react'
import { Field } from 'formik'
import Checkbox from 'rc-checkbox'
import 'rc-checkbox/assets/index.css'
import './CheckBox.css'

interface Props {
  name: string
  onChange?: (checked: boolean) => void
  checked: boolean
}

const CheckBox = (props: Props) => {
  return (
    <Field name={props.name}>
      {({ form, field }: any) => {
        return (
          <Checkbox
            checked={props.checked}
            onChange={(e: any) => {
              props.onChange && props.onChange(e.target.checked)
            }}
          />
        )
      }}
    </Field>
  )
}

export default CheckBox
