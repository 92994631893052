import { Field, FieldArray, getIn } from 'formik'
import { useEffect, useRef, useState } from 'react'
import './EditableBox.css'
import { numberWithCommas } from './../utils/helperFunctions'

interface EditableBoxProps {
  title: string
  contingency: number
  columnNum: number
  selectedColumn: number
  setSelectedColumn: Function
  isSelected: boolean
  formValues: any
  setFormValues: Function
  resourcesValues: any
  expensesValues: any
  planCurrency: string
  eyViewValues: any
}

const EditableBox = (props: EditableBoxProps) => {
  const {
    title,
    contingency,
    columnNum,
    selectedColumn,
    setSelectedColumn,
    isSelected,
    formValues,
    setFormValues,
    resourcesValues,
    expensesValues,
    planCurrency,
    eyViewValues
  } = props
  const [scenarioName, setScenarioName] = useState<string>('')
  const [totalBilling, setTotalBilling] = useState<number>(0)

  const arrayHelpersRef = useRef<any>()

  useEffect(() => {
    DisplayScenarioValues()
  }, [scenarioName, formValues, totalBilling])
  //[formValues, resourcesValues, expensesValues, totalBilling])

  useEffect(() => {
    // if (title === 'Base') {
    //   setScenarioName('base')
    //   setTotalBilling(
    //     resourcesValues.base.totalResources &&
    //       Math.ceil(
    //         resourcesValues.base.totalResources.totalFees + expensesValues.base.totalExpenses.totalExpenseBillings
    //       )
    //   )
    //   if (isNaN(totalBilling)) {
    //     formValues.base.totalBilling = 0
    //   } else {
    //     formValues.base.totalBilling = totalBilling
    //   }
    // }
    if (title === 'Target Margin %') {
      setScenarioName('targetMargin')
      //change here
      setTotalBilling(
        resourcesValues.targetMargin.totalResources !== undefined
          ? resourcesValues.targetMargin.totalResources.totalFees
          : eyViewValues.targetMargin.totalBillings.inclContingency
      )
      formValues.targetMargin.totalBilling =
        resourcesValues.targetMargin.totalResources !== undefined
          ? resourcesValues.targetMargin.totalResources.totalFees
          : eyViewValues.targetMargin.totalBillings.inclContingency
    } else if (title === 'Fixed Fee/Price') {
      setScenarioName('fixedFee')
      // Prevent overriding of saved input value
      if (
        formValues.fixedFee.totalBilling <= 0 &&
        formValues.fixedFee.totalBilling !== '' &&
        formValues.base.totalBilling > 0
      ) {
        formValues.fixedFee.totalBilling = formValues.base.totalBilling
      }
    } else if (title === 'Client Rate Card') {
      setScenarioName('client')
      setTotalBilling(eyViewValues.client.totalBillings.inclContingency)
      formValues.client.totalBilling = totalBilling
    } else if (title === 'Market Rate Card') {
      setScenarioName('marketRate')
      setTotalBilling(eyViewValues.marketRate.totalBillings.inclContingency)
      formValues.marketRate.totalBilling = totalBilling
    }
    checkHighlighted()
  }, [resourcesValues])

  const DisplayScenarioValues = () => {
    // if (title === 'Base') {
    //   setScenarioName('base')
    //   setTotalBilling(
    //     resourcesValues.base.totalResources &&
    //       Math.ceil(
    //         resourcesValues.base.totalResources.totalFees + expensesValues.base.totalExpenses.totalExpenseBillings
    //       )
    //   )
    //   if (isNaN(totalBilling)) {
    //     formValues.base.totalBilling = 0
    //   } else {
    //     formValues.base.totalBilling = totalBilling
    //   }
    // }
    if (title === 'Target Margin %') {
      setScenarioName('targetMargin')
      //change here
      setTotalBilling(eyViewValues.targetMargin.totalBillings.inclContingency)
      formValues.targetMargin.totalBilling = eyViewValues.targetMargin.totalBillings.inclContingency
    } else if (title === 'Fixed Fee/Price') {
      setScenarioName('fixedFee')
      // Prevent overriding of saved input value
      if (
        formValues.fixedFee.totalBilling <= 0 &&
        formValues.fixedFee.totalBilling !== '' &&
        formValues.base.totalBilling > 0
      ) {
        formValues.fixedFee.totalBilling = formValues.base.totalBilling
      }
    } else if (title === 'Client Rate Card') {
      setScenarioName('client')
      setTotalBilling(eyViewValues.client.totalBillings.inclContingency)
      formValues.client.totalBilling = totalBilling
    } else if (title === 'Market Rate Card') {
      setScenarioName('marketRate')
      setTotalBilling(eyViewValues.marketRate.totalBillings.inclContingency)
      formValues.marketRate.totalBilling = totalBilling
    }
    checkHighlighted()
  }

  // Calculate margin formulas for Fixed Fee and Client scenarios
  // TODO KY - margins and contingency values shouldn't need to be calculated, they should be flat values for the editable boxes (supposedly)
  // Commented out as no values should be calculated
  // useEffect(() => {
  //   if (resourcesValues.fixedFee.totalResources) { // Checks if the resourcesValues are filled
  //     // Calculate Fixed Fee Margins
  //     if (resourcesValues.fixedFee.totalResources.totalFees) {
  //       let newFixedFeeOnShore = Math.ceil(
  //         (resourcesValues.fixedFee.eyOnShore.ltMargin / (resourcesValues.fixedFee.eyOnShore.totalFees - resourcesValues.fixedFee.eyOnShore.contingency)) * 100
  //       )
  //       let newFixedFeeOffshore = Math.ceil(
  //         (resourcesValues.fixedFee.gdsOffshore.ltMargin / (resourcesValues.fixedFee.gdsOffshore.totalFees - resourcesValues.fixedFee.gdsOffshore.contingency)) * 100
  //       )
  //       let newFixedFeeLanded = Math.ceil(
  //         (resourcesValues.fixedFee.gdsLanded.ltMargin / (resourcesValues.fixedFee.gdsLanded.totalFees - resourcesValues.fixedFee.gdsLanded.contingency)) * 100
  //       )

  //       // If result is NaN - default to 0
  //       if (isNaN(newFixedFeeOnShore)) {
  //         newFixedFeeOnShore = 0;
  //       }
  //       if (isNaN(newFixedFeeOffshore)) {
  //         newFixedFeeOffshore = 0;
  //       }
  //       if (isNaN(newFixedFeeLanded)) {
  //         newFixedFeeLanded = 0;
  //       }

  //       let gdsFixedFeeMargin = 0;
  //       if (newFixedFeeOffshore === 0 && newFixedFeeLanded === 0) {
  //         gdsFixedFeeMargin = 0;
  //       } else if (newFixedFeeOffshore > 0 && newFixedFeeLanded === 0) {
  //         gdsFixedFeeMargin = newFixedFeeOffshore;
  //       } else if (newFixedFeeOffshore === 0 && newFixedFeeLanded > 0) {
  //         gdsFixedFeeMargin = newFixedFeeLanded;
  //       } else if (newFixedFeeOffshore > 0 && newFixedFeeLanded > 0) {
  //         gdsFixedFeeMargin = (newFixedFeeLanded + newFixedFeeOffshore) / 2;
  //       }

  //       //TODO KY apparently always 0 at the start? these should be set by business
  //       newFixedFeeOnShore = 0
  //       gdsFixedFeeMargin = 0
  //       //let newFixedFeeSubcontractors = 0
  //       // Note: GDS values are now merged
  //       setFormValues({
  //         ...formValues,
  //         fixedFee: {
  //           ...formValues.fixedFee,
  //           margin: {
  //             ...formValues.fixedFee.margin,
  //             eyOnShore: newFixedFeeOnShore,
  //             gdsOffshore: gdsFixedFeeMargin,
  //             gdsLanded: gdsFixedFeeMargin
  //           }
  //         }
  //       })

  //       formValues.fixedFee.margin.eyOnShore = newFixedFeeOnShore
  //       formValues.fixedFee.margin.gdsOffshore = gdsFixedFeeMargin
  //       formValues.fixedFee.margin.gdsLanded = gdsFixedFeeMargin
  //       //formValues.fixedFee.margin.subcontractors = newFixedFeeSubcontractors
  //     }

  //     // Calculate Client Margins
  //     if (resourcesValues.client.totalResources.totalFees) {
  //       let newClientOnShore = Math.ceil(
  //         (resourcesValues.client.eyOnShore.ltMargin / (resourcesValues.client.eyOnShore.totalFees - resourcesValues.client.eyOnShore.contingency)) * 100
  //       )
  //       let newClientOffshore = Math.ceil(
  //         (resourcesValues.client.gdsOffshore.ltMargin / (resourcesValues.client.gdsOffshore.totalFees - resourcesValues.client.gdsOffshore.contingency)) * 100
  //       )
  //       let newClientLanded = Math.ceil(
  //         (resourcesValues.client.gdsLanded.ltMargin / (resourcesValues.client.gdsLanded.totalFees - resourcesValues.client.gdsLanded.contingency)) * 100
  //       )
  //       let newClientSubcontractors = Math.ceil(
  //         (resourcesValues.client.subcontractors.ltMargin / (resourcesValues.client.subcontractors.totalFees - resourcesValues.client.subcontractors.contingency)) * 100
  //       )

  //       if (isNaN(newClientOnShore)) {
  //         newClientOnShore = 0;
  //       }
  //       if (isNaN(newClientOffshore)) {
  //         newClientOffshore = 0;
  //       }
  //       if (isNaN(newClientLanded)) {
  //         newClientLanded = 0;
  //       }
  //       if (isNaN(newClientSubcontractors)) {
  //         newClientSubcontractors = 0;
  //       }

  //       let gdsClientMargin = 0;
  //       if (newClientOffshore === 0 && newClientLanded === 0) {
  //         gdsClientMargin = 0;
  //       } else if (newClientOffshore > 0 && newClientLanded === 0) {
  //         gdsClientMargin = newClientOffshore;
  //       } else if (newClientOffshore === 0 && newClientLanded > 0) {
  //         gdsClientMargin = newClientLanded;
  //       } else if (newClientOffshore > 0 && newClientLanded > 0) {
  //         gdsClientMargin = (newClientLanded + newClientOffshore) / 2;
  //       }

  //       //TODO KY apparently always 0 at the start?
  //       newClientOnShore = 0
  //       gdsClientMargin = 0
  //       newClientSubcontractors = 0
  //       // Note: GDS values have been merged
  //       setFormValues({
  //         ...formValues,
  //         client: {
  //           ...formValues.client,
  //           margin: {
  //             ...formValues.client.margin,
  //             eyOnShore: newClientOnShore,
  //             gdsOffshore: gdsClientMargin,
  //             gdsLanded: gdsClientMargin,
  //             subcontractors: newClientSubcontractors
  //           }
  //         }
  //       })

  //       formValues.client.margin.eyOnShore = newClientOnShore
  //       formValues.client.margin.gdsOffshore = gdsClientMargin
  //       formValues.client.margin.gdsLanded = gdsClientMargin
  //       formValues.client.margin.subcontractors = newClientSubcontractors
  //     }
  //   }
  // }, [totalBilling])

  const displayRows = [
    'eyOnShore',
    'gdsOffshore',
    'subcontractors',
    'mobilityEYOnshore',
    'technologyOtherProducts',
    'vendorSubcontractorFF',
    'otherLumpSums'
  ]

  const onBoxClick = () => {
    setSelectedColumn(columnNum)
  }

  const setMarginColours = (i: number, j: number, marginFields: Array<HTMLInputElement>) => {
    if (marginFields[i].value < formValues.base.margin[displayRows[i - j]]) {
      marginFields[i].style.backgroundColor = '#ff9a91'
    } else if (marginFields[i].value > formValues.base.margin[displayRows[i - j]]) {
      marginFields[i].style.backgroundColor = '#8de8ad'
    } else {
      marginFields[i].style.backgroundColor = '#eaeaf2'
    }
  }

  const setContingencyColours = (i: number, j: number, contingencyFields: Array<HTMLInputElement>) => {
    if (contingencyFields[i].value < formValues.base.contingency[displayRows[i - j]]) {
      contingencyFields[i].style.backgroundColor = '#ff9a91'
    } else if (contingencyFields[i].value > formValues.base.contingency[displayRows[i - j]]) {
      contingencyFields[i].style.backgroundColor = '#8de8ad'
    } else {
      contingencyFields[i].style.backgroundColor = '#eaeaf2'
    }
  }

  useEffect(() => {
    checkHighlighted()

    setFormValues({
      ...formValues,
      [scenarioName]: {
        ...formValues[scenarioName],
        totalBilling: [totalBilling]
      }
    })
  }, [selectedColumn, contingency, isSelected, totalBilling])

  const checkHighlighted = () => {
    const marginFields = Array.from(
      document.getElementsByClassName('editable-box-margin-input') as HTMLCollectionOf<HTMLInputElement>
    )
    const contingencyFields = Array.from(
      document.getElementsByClassName('editable-box-contingency-input') as HTMLCollectionOf<HTMLInputElement>
    )

    if (selectedColumn === columnNum) {
      // if (scenarioName === 'targetMargin') {
      if (scenarioName === 'marketRate') {
        for (let i = 0; i < 7; i++) {
          if (i < 2) {
            setMarginColours(i, 0, marginFields)
          }
          setContingencyColours(i, 0, contingencyFields)
        }
      } else if (scenarioName === 'targetMargin') {
        let m = 7
        for (let i = 7; i < 14; i++) {
          if (i >= 7 && i < 9) {
            setMarginColours(i - m, 7, marginFields)
          }
          setContingencyColours(i, 7, contingencyFields)
        }
      } else if (scenarioName === 'fixedFee') {
        // for (let i = 7; i < 10; i++) {
        //   setMarginColours(i, 3, marginFields)
        // }

        for (let i = 14; i < 21; i++) {
          setContingencyColours(i, 14, contingencyFields)
        }
      }
      // else if (scenarioName === 'marketRate') {
      else if (scenarioName === 'client') {
        // for (let i = 7; i < 10; i++) {
        //   setMarginColours(i, 3, marginFields)
        // }

        for (let i = 21; i < 28; i++) {
          setContingencyColours(i, 14, contingencyFields)
        }
      }
    } else {
      // if (scenarioName === 'targetMargin') {
      if (scenarioName === 'marketRate') {
        for (let i = 0; i < 7; i++) {
          if (i < 2) {
            marginFields[i].style.backgroundColor = '#ffffff'
          }
          contingencyFields[i].style.backgroundColor = '#ffffff'
        }
      } else if (scenarioName === 'targetMargin') {
        for (let i = 7; i < 14; i++) {
          contingencyFields[i].style.backgroundColor = '#ffffff'
        }
      } else if (scenarioName === 'fixedFee') {
        // for (let i = 7; i < 10; i++) {
        //   marginFields[i].style.backgroundColor = '#ffffff'
        // }
        for (let i = 14; i < 21; i++) {
          contingencyFields[i].style.backgroundColor = '#ffffff'
        }
      }
      // else if (scenarioName === 'marketRate') {
      else if (scenarioName === 'client') {
        // for (let i = 7; i < 10; i++) {
        //   marginFields[i].style.backgroundColor = '#ffffff'
        // }
        for (let i = 21; i < 28; i++) {
          contingencyFields[i].style.backgroundColor = '#ffffff'
        }
      }
    }
  }

  const marginChange = (event: any) => {
    const fieldChanged = event.target.name.split('.')
    const row = fieldChanged[2].slice(1, -1)

    if (event.target.value > formValues.base.margin[row]) {
      event.target.style.backgroundColor = '#8de8ad'
    } else if (event.target.value < formValues.base.margin[row]) {
      event.target.style.backgroundColor = '#ff9a91'
    } else {
      event.target.style.backgroundColor = '#eaeaf2'
    }
  }

  const contingencyChange = (event: any) => {
    const fieldChanged = event.target.name.split('.')
    const row = fieldChanged[2].slice(1, -1)

    if (event.target.value > formValues.base.contingency[row]) {
      event.target.style.backgroundColor = '#8de8ad'
    } else if (event.target.value < formValues.base.contingency[row]) {
      event.target.style.backgroundColor = '#ff9a91'
    } else {
      event.target.style.backgroundColor = '#eaeaf2'
    }
    setTotalBilling(eyViewValues.targetMargin.totalBillings.inclContingency)
    formValues.targetMargin.totalBilling = totalBilling
  }

  const totalBillingChange = (event: any) => {
    setTotalBilling(Number(event.target.value))
    const input = event.target.value
    if (input < 0) {
      event.target.value = 0
    } else if (input.substr(0, 1) === '0') {
      event.target.value = 0
    }
  }

  const restrictValues = (event: any) => {
    const input = event.target.value
    if (input < 0 || input.includes('-')) {
      event.target.value = 0
    } else if (input.includes('.')) {
      event.target.value = Math.trunc(event.target.value)
    } else if (input.substr(0, 1) === '0') {
      event.target.value = 0
    } else if (input.length > 2) {
      event.target.value = event.target.value.substr(0, 2)
    }

    if (event.target.name.includes('margin')) {
      marginChange(event)
    } else if (event.target.name.includes('contingency')) {
      contingencyChange(event)
    }
  }

  const renderError = (errors: any, touch: any, objectName: string, type: string, fieldName: string) => {
    const error = getIn(errors, `${objectName}.${type}.${fieldName}`)
    const touched = getIn(touch, `${objectName}.${type}.${fieldName}`)
    return error && touched ? error : null
  }

  return (
    <div
      className={selectedColumn === columnNum ? 'editable-box-container-selected' : 'editable-box-container'}
      onClick={onBoxClick}
    >
      {selectedColumn === columnNum && (
        <div className='editable-box-selected-heading'>
          <p className='body-text editable-box-selected-text'>Selected</p>
        </div>
      )}
      <div className='editable-box-content'>
        <h3 className='heading-three'>{title}</h3>
        {scenarioName === 'fixedFee' && selectedColumn === columnNum ? (
          <div className='editable-box-cost-container '>
            <h3 className='heading-three editable-box-cost'></h3>
            <Field
              name={`[${scenarioName}].totalBilling`}
              className={`editable-box-cost-input editable-box-cost heading-three 
                        ${
                          formValues.fixedFee.totalBilling.toString() === '' ||
                          formValues.fixedFee.totalBilling.toString().includes('.')
                            ? 'editable-box-error'
                            : ''
                        }`}
              type='number'
              onInput={totalBillingChange}
            />
            <h3 className='heading-three editable-box-cost-symbol'>{' ' + planCurrency}</h3>
            <div className='editable-box-input-error editable-box-cost-error body-text'>
              {totalBilling.toString() === '' && <p>Required.</p>}
              {totalBilling.toString().includes('.') && <p>Integer only.</p>}
            </div>
          </div>
        ) : (
          <h3 className='heading-three editable-box-cost'>
            {scenarioName === 'fixedFee'
              ? numberWithCommas(formValues.fixedFee.totalBilling) + ' ' + planCurrency
              : numberWithCommas(totalBilling) + ' ' + planCurrency}
          </h3>
        )}
        <form>
          <table>
            <thead>
              <tr>
                <th className='body-text editable-box-header'>Margin %</th>
                <th className='body-text editable-box-header'>Contingency %</th>
              </tr>
            </thead>

            <FieldArray name={`${scenarioName}`}>
              {(arrayHelpers) => {
                if (!arrayHelpersRef.current) {
                  arrayHelpers.form.setValues(formValues)
                  arrayHelpersRef.current = arrayHelpers
                }
                const { form } = arrayHelpers
                return (
                  <tbody>
                    {displayRows.map((row: string, index: number) => {
                      setFormValues(form.values)
                      return (
                        <tr key={index}>
                          <td>
                            <div
                              className={`editable-box-input-container ${
                                row === 'vendorSubcontractorFF' && 'large-box'
                              }`}
                            >
                              {scenarioName === 'fixedFee' ||
                              scenarioName === 'base' ||
                              (scenarioName === 'targetMargin' &&
                                (row === 'subcontractors' ||
                                  row === 'mobilityEYOnshore' ||
                                  row === 'technologyOtherProducts' ||
                                  row === 'vendorSubcontractorFF' ||
                                  row === 'otherLumpSums')) ||
                              scenarioName === 'client' ||
                              (scenarioName === 'marketRate' &&
                                (row === 'eyOnShore' ||
                                  row === 'gdsOffshore' ||
                                  row === 'subcontractors' ||
                                  row === 'mobilityEYOnshore' ||
                                  row === 'technologyOtherProducts' ||
                                  row === 'vendorSubcontractorFF' ||
                                  row === 'otherLumpSums')) ? (
                                <Field
                                  name={`[${scenarioName}].margin.[${row}]`}
                                  className='editable-box-readonly'
                                  type='number'
                                  readOnly
                                />
                              ) : (
                                <Field
                                  name={`[${scenarioName}].margin.[${row}]`}
                                  className={`editable-box-margin-input 
                                              ${
                                                renderError(form.errors, form.touched, scenarioName, 'margin', row)
                                                  ? 'editable-box-error'
                                                  : ''
                                              }`}
                                  type='number'
                                  onInput={restrictValues}
                                />
                              )}
                              <p className='body-text'>%</p>
                              <div className='editable-box-input-error body-text'>
                                {renderError(form.errors, form.touched, scenarioName, 'margin', row) && (
                                  <p>{renderError(form.errors, form.touched, scenarioName, 'margin', row)}</p>
                                )}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div
                              className={`editable-box-input-container ${
                                row === 'vendorSubcontractorFF' && 'large-box'
                              }`}
                            >
                              {scenarioName === 'base' ? (
                                <Field
                                  name={`[${scenarioName}].contingency.[${row}]`}
                                  className='editable-box-readonly'
                                  type='number'
                                  readOnly
                                />
                              ) : (
                                <Field
                                  name={`[${scenarioName}].contingency.[${row}]`}
                                  className={`editable-box-contingency-input 
                                            ${
                                              renderError(form.errors, form.touched, scenarioName, 'contingency', row)
                                                ? 'editable-box-error'
                                                : ''
                                            }`}
                                  type='number'
                                  onInput={restrictValues}
                                />
                              )}
                              <p className='body-text'>%</p>
                              <div className='editable-box-input-error body-text'>
                                {renderError(form.errors, form.touched, scenarioName, 'contingency', row) && (
                                  <p>{renderError(form.errors, form.touched, scenarioName, 'contingency', row)}</p>
                                )}
                              </div>
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                )
              }}
            </FieldArray>
          </table>
        </form>
      </div>
    </div>
  )
}

export default EditableBox
