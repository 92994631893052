import React, { useState, useRef, useEffect, useMemo } from 'react'
import { useFormikContext, Field, FieldArray, ErrorMessage } from 'formik'
import moment from 'moment'
import loaderGif from '../../assets/LoaderGif.gif'
import _, { includes } from 'lodash'
import {
  Workstreams,
  Role,
  Employee,
  Rank,
  Promotion,
  Hours,
  CostRateHour,
  ClientRateHour,
  ClientRateHour1,
  TotalLabourCost,
  TotalLabourCost5,
  EndDate,
  StartDate,
  HoursPerWeek,
  renderError,
  restrictDecimals,
  Actions,
  getRounded,
  checkResourceIsSubcontractor,
  MarketScope,
  MarketRateHour,
  getCountryInformation,
  getCountryInformationByScope
} from './ResourceTableComponents'
import useUpdateEffect from '../utils/useUpdateEffect'
import './ResourceTable.css'
import TextError from '../formik-controls/TextError'
import {
  getCostCentresData,
  getRanks,
  getRatesByCostCentre,
  getCostRatesForGDSResource1,
  getMarketScopes,
  updatePricingPlan
} from '../utils/fetchHandler'
import CostCenterModal from './modal/CostCentreModal'
import { MarketScopeType, RankType, RouteParamTypes } from './ResourceTypes'
import { updateInputStyling } from '../utils/updateStyling'
import { format } from 'path'
import { string } from 'yup'
import Worksheet from '../global-inputs/GlobalInputsContainer'
import CountryModal from './modal/CountryModal'
import { useParams } from 'react-router-dom'

const generateColumsToShow = (columns: any, paths: any) => {
  return columns.map((column: any) => {
    const show = paths.find((path: any) => column.path === path)
    if (show) {
      return {
        ...column,
        isShow: true
      }
    } else {
      return {
        ...column,
        isShow: false
      }
    }
  })
}

export let isMarketRateEditable: any
export let marketRateResourceIndex: any

const getColumns = (values: any) => {
  const isShowWorkstream = values.workstreams?.length > 0
  const cols: any = [
    { path: 'workstream', name: 'Workstream', isShow: isShowWorkstream },
    { path: 'role', name: 'Role', isShow: true },
    { path: 'employee', name: 'Resource / Cost Center', isShow: true },
    { path: 'rank', name: 'Rank', isShow: true },
    { path: 'isPromotion', name: 'Promotion', isShow: true },
    { path: 'hours', name: 'Hours', isShow: true },
    {
      path: 'costRateHour',
      name: `Cost Rate/hr ${values.planCurrency ? `${' '}(${values.planCurrency})` : ''}`,
      isShow: true
    },
    { path: 'marketScope', name: 'Market Scope', isShow: true },
    {
      path: 'marketRateHour',
      name: `Market Rate/hr ${values.planCurrency ? `${' '}(${values.planCurrency})` : ''}`,
      isShow: true
    },
    { path: 'clientRateHour', name: 'Client Rate/hr', isShow: true },
    { path: 'clientRateDay', name: 'Client Rate/day', isShow: false },
    {
      path: 'totalCost',
      name: `Total Labor Cost ${values.planCurrency ? `${' '}(${values.planCurrency})` : ''}`,
      isShow: true
    },

    { path: 'startDate', name: 'Start Date', isShow: false },
    { path: 'endDate', name: 'End Date', isShow: false },
    { path: 'timeUnitsPerWeek', name: 'Hours Per Week', isShow: false },
    { path: 'actions', name: '', isShow: true }
  ]
  const { dealStartDate, dealEndDate } = values
  let result = []
  if (dealStartDate && dealEndDate) {
    const start = moment(dealStartDate).day(1)
    const end = moment(dealEndDate)
    result.push({
      path: moment(start).format('YYYY-MM-DDT00:00:00'),
      name: moment(start).format('DD/MM/YYYY')
    })
    let tmp = start.add(7, 'days')
    while (tmp.isSameOrBefore(end)) {
      result.push({
        path: moment(tmp).format('YYYY-MM-DDT00:00:00'),
        name: moment(tmp).format('DD/MM/YYYY')
      })
      tmp.add(7, 'days')
    }
  }
  if (result?.length > 0) {
    cols.push({
      path: 'weeklyTimeUnits',
      isShow: false,
      innerColumns: result
    })
  }
  return cols
}

function Table(props: any) {
  const { pricingPlanId } = useParams<RouteParamTypes>()
  const { view, viewedColumns, inflationRate, countryInflationRate, gdsInflation } = props
  const { values, errors, touched, setFieldValue, setTouched }: any = useFormikContext()
  const [showHours, setShowHours] = useState(false)
  const weeklyTimeUnitsArrayHelpersRef = useRef<any>([])
  const promotionWeeklyTimeUnitsArrayHelpersRef = useRef<any>([])
  const [columns, setColumns] = useState<any>(getColumns(values))
  const [isOpenCostCentreSelectionModal, setOpenCostCentreSelectionModal] = useState(false)
  const [selectedResourceIndex, setSelectedResourceIndex] = useState<number | null>(null)
  const [selectedResource, setSelectedResource] = useState<any>(null)
  const [availableCostCentres, setAvailableCostCentres] = useState<any[]>([])
  const [ranks, setRanks] = useState<RankType[]>([] as RankType[])
  const [marketScopes, setMarketScopes] = useState<MarketScopeType[]>([] as MarketScopeType[])
  const [availableCountries, setAvailableCountries] = useState<any[]>([])
  const [isCountryModalOpen, setCountryModalOpen] = useState<any>()
  const [selectedCountry, setSelectedCountry] = useState<any>()
  const [isLoading, setIsLoading] = useState<any>()

  const [resourceRank, setResourceRank] = useState<any>()

  // Get ranks
  useEffect(() => {
    ;(async () => {
      try {
        const ranks = await getRanks()
        const ranksSorted = _.sortBy(ranks, 'abbreviation')
        const ranksSortedByLevelsDesc = sortByLevelsInRankDesc(ranksSorted)
        const ranksSortedByPriority = sortByRanksPriority(ranksSortedByLevelsDesc)
        const ranksGroupedbyLevelValues = _.values(ranksSortedByPriority)
        const ranksGroupedbyLevelFlatted = _.flattenDeep(ranksGroupedbyLevelValues)
        setRanks(ranksGroupedbyLevelFlatted)
        updateInputStyling()
      } catch (error) {}
    })()
  }, [])

  useEffect(() => {
    updateInputStyling()
  }, [values])

  const sortByLevelsInRankDesc = (data: any) => {
    const sortedArray = data.sort(function (a: RankType, b: RankType): number {
      let aUppercaseName = a.abbreviation.toUpperCase()
      let bUppercaseName = b.abbreviation.toUpperCase()
      return bUppercaseName < aUppercaseName ? -1 : 1
    })
    return sortedArray
  }
  const sortByRanksPriority = (data: any) => {
    const sortedArray = data.sort(function (a: RankType, b: RankType): number {
      let aPriority = getPriorityLevels(a.abbreviation.toUpperCase())
      let bPriority = getPriorityLevels(b.abbreviation.toUpperCase())
      return bPriority > aPriority ? -1 : 1
    })
    return sortedArray
  }
  const getPriorityLevels = (a: string) => {
    let priorityNo = 999
    if (a.includes('SM')) {
      //'SENIOR MANAGER'
      priorityNo = 3
    } else if (a.includes('ED')) {
      //'EXECUTIVE DIRECTOR'
      priorityNo = 2
    } else if (a.includes('MG')) {
      //'MANAGER'
      priorityNo = 4
    } else if (a.includes('PT')) {
      //'PARTNER'
      priorityNo = 1
    } else if (a.includes('SN')) {
      //'SENIOR'
      priorityNo = 5
    } else if (a.includes('ST')) {
      //'STAFF'
      priorityNo = 6
    } else if (a.includes('IN')) {
      //'STAFF'
      priorityNo = 7
    } else if (a.includes('CSC')) {
      //'Contractor'
      priorityNo = 8
    } else if (a.includes('SUB')) {
      //'Subcontractor'
      priorityNo = 9
    }
    return priorityNo
  }
  const workstreamOptions =
    values.workstreams?.length > 0
      ? values.workstreams.map((workstream: any) => {
          return { value: workstream.title, label: workstream.title }
        })
      : []

  useUpdateEffect(() => {
    if (view === 'list') {
      let paths = ['employee', 'rank', 'hours', 'totalCost', 'actions']
      paths = [...paths, ...props.viewedColumns]
      const newColumns = generateColumsToShow(columns, paths)
      setShowHours(false)
      setColumns(newColumns)
    } else if (showHours) {
      const paths = ['employee', 'rank', 'startDate', 'endDate', 'timeUnitsPerWeek', 'weeklyTimeUnits']
      const newColumns = generateColumsToShow(columns, paths)
      setColumns(newColumns)
    } else {
      let columns = getColumns(values)
      setColumns(columns)
    }
  }, [view, showHours, viewedColumns])

  const handleClickApply = (resourceIndex: number, rowData: any, isPromotion: boolean = false) => {
    const name = `resources[${resourceIndex}]${isPromotion ? '.promotedResource' : ''}.timeUnitsPerWeek`
    const isError = renderError(name, errors, touched)
    if (!isError) {
      const setWeeklyTimeUnits = (weeklyTimeUnits: any, name: string, value: number) => {
        const _weeklyTimeUnits = weeklyTimeUnits.map((weeklyTimeUnit: any, index: number) => {
          let numOfHoursPerDay = 0
          numOfHoursPerDay = rowData.timeUnitsPerWeek / 5
          if (index === 0 || index === weeklyTimeUnits.length - 1) {
            if (index === 0) {
              // First Week
              const startDate = isPromotion ? rowData.promotedResource.startDate : rowData.startDate
              const endDate = isPromotion ? rowData.promotedResource.endDate : rowData.endDate

              const resourceStartDay = moment(startDate).day()
              const resourceEndDay = moment(endDate).day()
              let remainingDays = 5 - resourceStartDay + 1
              remainingDays = remainingDays > 5 ? 5 : remainingDays
              if (
                moment(startDate).format('w') === moment(endDate).format('w') &&
                moment(startDate).year() === moment(endDate).year()
              ) {
                remainingDays = resourceEndDay - resourceStartDay + 1
              }

              const newTimeUnits = Number((remainingDays * numOfHoursPerDay).toFixed(2))
              if (value >= newTimeUnits) {
                return {
                  ...weeklyTimeUnit,
                  timeUnits: newTimeUnits
                }
              }
            }

            // Last Week
            let resourceEndDay = isPromotion
              ? moment(rowData.promotedResource.endDate).day()
              : moment(rowData.endDate).day()
            resourceEndDay = resourceEndDay === 6 || resourceEndDay === 0 ? 5 : resourceEndDay
            const newTimeUnits = Number((resourceEndDay * numOfHoursPerDay).toFixed(2))
            if (value >= newTimeUnits)
              return {
                ...weeklyTimeUnit,
                timeUnits: newTimeUnits
              }
          }
          return {
            ...weeklyTimeUnit,
            timeUnits: value
          }
        })

        const _weeklyTimeUnitsTouched = weeklyTimeUnits.map(() => ({
          timeUnits: true
        }))
        setFieldValue(name, _weeklyTimeUnits, true)
        setTouched(_.set(touched, name, _weeklyTimeUnitsTouched))
      }
      if (isPromotion) {
        const weeklyTimeUnits = values?.resources[resourceIndex]?.promotedResource?.weeklyTimeUnits
        if (weeklyTimeUnits?.length > 0) {
          const name = `resources[${resourceIndex}].promotedResource.weeklyTimeUnits`
          setWeeklyTimeUnits(weeklyTimeUnits, name, values.resources[resourceIndex].promotedResource.timeUnitsPerWeek)
        }
      } else {
        const weeklyTimeUnits = values?.resources[resourceIndex]?.weeklyTimeUnits
        if (weeklyTimeUnits?.length > 0) {
          const name = `resources[${resourceIndex}].weeklyTimeUnits`
          setWeeklyTimeUnits(weeklyTimeUnits, name, values.resources[resourceIndex].timeUnitsPerWeek)
        }
      }
    }
  }

  const checkIsValidDate = (
    dealStartDate: Date,
    dealEndDate: Date,
    resourceStartDate: Date | null,
    resourceEndDate: Date | null
  ) => {
    if (dealStartDate && dealEndDate && resourceStartDate && resourceEndDate) {
      const isResourceStartDateSameOrAfterDealStartDate = moment(resourceStartDate).isSameOrAfter(dealStartDate)
      const isResourceStartDateSameOrBeforeDealEndDate = moment(resourceStartDate).isSameOrBefore(dealEndDate)
      const isResourceEndDateSameOrAfterDealStartDate = moment(resourceEndDate).isSameOrAfter(dealStartDate)
      const isResourceEndDateSameOrBeforeDealEndDate = moment(resourceEndDate).isSameOrBefore(dealEndDate)
      const isResourceEndDateIsAfterResourceStartDate = moment(resourceEndDate).isSameOrAfter(resourceStartDate)
      return (
        isResourceStartDateSameOrAfterDealStartDate &&
        isResourceStartDateSameOrBeforeDealEndDate &&
        isResourceEndDateSameOrAfterDealStartDate &&
        isResourceEndDateSameOrBeforeDealEndDate &&
        isResourceEndDateIsAfterResourceStartDate
      )
    }
    return false
  }

  const generateWeeklyTimeUnits = (startDate: Date | null, endDate: Date | null, weeklyTimeUnits?: any) => {
    let result: any = []
    const addItem = (date: string) => {
      const existingItem = weeklyTimeUnits?.find((item: any) => item.startDate === date)
      if (existingItem) {
        result.push(existingItem)
      } else {
        result.push({
          startDate: date,
          timeUnits: 0
        })
      }
    }
    if (startDate && endDate) {
      const start = moment(startDate).day(1)
      const end = moment(endDate)
      addItem(moment(start).format('YYYY-MM-DDT00:00:00'))
      let tmp = start.add(7, 'days')
      while (tmp.isSameOrBefore(end)) {
        addItem(moment(tmp).format('YYYY-MM-DDT00:00:00'))
        tmp.add(7, 'days')
      }
    }
    return result
  }

  const checkIsDatesNotOverlapResourceDate = (promotionStartDate: Date | null, resourceEndDate: Date | null) => {
    if (promotionStartDate && resourceEndDate) {
      const isPromotionStartDateAfterResourceEndDate = moment(promotionStartDate).isAfter(resourceEndDate)
      return isPromotionStartDateAfterResourceEndDate
    }
    return true
  }

  const handleChangeDate = (
    control: string,
    selectedDate: Date,
    resourceIndex: number,
    isPromotion: boolean = false
  ) => {
    const { dealStartDate, dealEndDate, resources } = values
    let resourceStartDate, resourceEndDate

    if (isPromotion) {
      if (control === 'startDate') {
        resourceStartDate = selectedDate
        resourceEndDate = resources[resourceIndex].promotedResource.endDate
      } else {
        resourceStartDate = resources[resourceIndex].promotedResource.startDate
        resourceEndDate = selectedDate
      }
      const isValid = checkIsValidDate(dealStartDate, dealEndDate, resourceStartDate, resourceEndDate)
      const isValidPromotionDate = checkIsDatesNotOverlapResourceDate(
        resourceStartDate,
        resources[resourceIndex].endDate
      )
      if (isValid && isValidPromotionDate) {
        setFieldValue(
          `resources[${resourceIndex}].promotedResource.weeklyTimeUnits`,
          generateWeeklyTimeUnits(
            resourceStartDate,
            resourceEndDate,
            resources[resourceIndex].promotedResource.weeklyTimeUnits
          )
        )
      } else {
        setFieldValue(`resources[${resourceIndex}].promotedResource.weeklyTimeUnits`, [])
      }
    } else {
      if (control === 'startDate') {
        resourceStartDate = selectedDate
        resourceEndDate = resources[resourceIndex].endDate
      } else {
        resourceStartDate = resources[resourceIndex].startDate
        resourceEndDate = selectedDate
      }
      const isValid = checkIsValidDate(dealStartDate, dealEndDate, resourceStartDate, resourceEndDate)
      if (isValid) {
        setFieldValue(
          `resources[${resourceIndex}].weeklyTimeUnits`,
          generateWeeklyTimeUnits(resourceStartDate, resourceEndDate, resources[resourceIndex].weeklyTimeUnits)
        )
      } else {
        setFieldValue(`resources[${resourceIndex}].weeklyTimeUnits`, [])
      }
    }
  }

  const onChangePromotion = (checked: boolean, rowData: any, resourceIndex: number) => {
    if (checked) {
      const promotedResource = {
        workstream: '',
        role: '',
        employee: rowData.employee,
        costCentre: rowData.costCentre,
        isGDS: rowData.isGDS,
        rank: '',
        clientRateHour: 0,
        clientRateDay: 0,
        totalTimeUnits: 0,
        startDate: null,
        endDate: null,
        timeUnitsPerWeek: rowData.timeUnitsPerWeek,
        weeklyTimeUnits: [],
        costRate: 0,
        dcrRate: 0,
        marketRate: 0,
        marketScopeId: 0
      }

      setFieldValue(`resources[${resourceIndex}].promotedResource`, promotedResource)
    } else {
      setFieldValue(`resources[${resourceIndex}].promotedResource`)
    }
  }

  const deletePromotion = (resourceIndex: number) => {
    setFieldValue(`resources[${resourceIndex}].promotedResource`)
  }

  const onChangeRank = async (option: any, isPromotion: boolean, resourceIndex: number, rowData: any) => {
    setIsLoading(true)
    if (rowData.employee === 'Unnamed') {
      setResourceRank(option.value)
      let availableCostCentres = null
      if (rowData.dropdownOptions && !rowData.isGDS) {
        const costCentreOptions = {
          rank: option.value,
          area: rowData.dropdownOptions.area,
          region: rowData.dropdownOptions.region,
          costCountry: rowData.dropdownOptions.serviceLine.split('/')[0],
          serviceLine: rowData.dropdownOptions.serviceLine.split('/')[1],
          subServiceLine: rowData.dropdownOptions.subServiceLine.split('/')[0],
          competency: rowData.dropdownOptions.subServiceLine.split('/')[1]
        }
        try {
          availableCostCentres = await getCostCentresData(costCentreOptions, { currency: values.planCurrency })
        } catch (error) {}
      } else if (rowData.isGDS) {
        if (rowData.dropdownOptions && !rowData.costCentre.includes('||~')) {
          rowData.costCentre = rowData.serviceLine + '||~' + rowData.subServiceLineGDS + '||~' + rowData.competency
        }
        const costCentreOptions = rowData.dropdownOptions
          ? {
              resourceType: 'GDS',
              costCountry: rowData.dropdownOptions.area,
              serviceLine: rowData.dropdownOptions.serviceLine,
              subServiceLine: rowData.dropdownOptions.subServiceLineGDS,
              competency: rowData.dropdownOptions.competency,
              rank: option.value
            }
          : {
              resourceType: 'GDS',
              costCountry: rowData.costCountry,
              serviceLine: rowData.costCentreData.includes('||~') ? rowData.costCentreData.split('||~')[0] : '',
              subServiceLine: rowData.costCentreData.includes('||~') ? rowData.costCentreData.split('||~')[1] : '',
              competency: rowData.costCentreData.includes('||~') ? rowData.costCentreData.split('||~')[2] : '',
              rank: option.value
            }
        const result = await getCostRatesForGDSResource1('GetCostRate', values.planCurrency, costCentreOptions)
        setFieldValue(`resources[${resourceIndex}].costRate`, getRounded(result.localCostRate))
        setFieldValue(`resources[${resourceIndex}].nsrRate`, getRounded(result.nsrRate))
        availableCostCentres = [
          {
            costCenterId: result.costCenterId,
            costCenterDescription: result.costCenterDescription,
            localCostRate: result.localCostRate,
            dcrRate: result.localCostRate
          }
        ]
      } else if (rowData.costCentre && rowData.isGDS === true && rowData.source === 'gdsOffshore') {
        const ratesByCostCentreParams = {
          costCenterId: rowData.costCentre.match(/(?<=\()(.*?)(?=\))/g).reverse()[0],
          costCenterDescription: rowData.costCentre.replace(/\((?<=\()(.*?)(?=\))\)/g, ''),
          rank: option.value,
          currency: values.planCurrency
        }
        try {
          const result = await getRatesByCostCentre(ratesByCostCentreParams)
          setFieldValue(`resources[${resourceIndex}].costRate`, getRounded(result.localCostRate, 2))
          setFieldValue(`resources[${resourceIndex}].dcrRate`, getRounded(result.localCostRate, 2))
          setFieldValue(`resources[${resourceIndex}].nsrRate`, getRounded(result.nsrRate, 2))
        } catch (error) {}
      } else if (rowData.costCentre) {
        const ratesByCostCentreParams = {
          costCenterId: rowData.costCentre.match(/(?<=\()(.*?)(?=\))/g).reverse()[0],
          costCenterDescription: rowData.costCentre.replace(/\((?<=\()(.*?)(?=\))\)/g, ''),
          rank: option.value,
          currency: values.planCurrency
        }
        try {
          const result = await getRatesByCostCentre(ratesByCostCentreParams)
          const costCentreOptions = {
            rank: option.value,
            area: result.area,
            region: result.region,
            costCountry: result.costCountry,
            serviceLine: result.serviceLine,
            subServiceLine: result.subServiceLine,
            competency: result.competency
          }
          availableCostCentres = await getCostCentresData(costCentreOptions, { currency: values.planCurrency })
        } catch (error) {}
      }

      if (availableCostCentres?.length === 1 && rowData.isGDS === true && rowData.source === 'gdsOffshore') {
        setFieldValue(`resources[${resourceIndex}].costRate`, getRounded(availableCostCentres[0].localCostRate, 2))
        setFieldValue(`resources[${resourceIndex}].dcrRate`, getRounded(availableCostCentres[0].dcrRate, 2))
        setFieldValue(`resources[${resourceIndex}].nsrRate`, getRounded(availableCostCentres[0].nsrRate, 2))
        // setFieldValue(
        //   `resources[${resourceIndex}].costCentre`,
        //   `${availableCostCentres[0].costCenterDescription} ${availableCostCentres[0]?.costCenterId}`
        // )
      } else if (availableCostCentres?.length === 1) {
        setFieldValue(
          `resources[${resourceIndex}].costCentre`,
          `${availableCostCentres[0].costCenterDescription} ${availableCostCentres[0]?.costCenterId}`
        )
        setFieldValue(`resources[${resourceIndex}].costRate`, getRounded(availableCostCentres[0].localCostRate, 2))
        setFieldValue(`resources[${resourceIndex}].dcrRate`, getRounded(availableCostCentres[0].dcrRate, 2))
        setFieldValue(`resources[${resourceIndex}].nsrRate`, getRounded(availableCostCentres[0].nsrRate, 2))
      } else if (availableCostCentres?.length > 1) {
        setFieldValue(`resources[${resourceIndex}].availableCostCentres`, availableCostCentres)
        setAvailableCostCentres(availableCostCentres)
        setSelectedResourceIndex(resourceIndex)
        setSelectedResource(rowData)
        setOpenCostCentreSelectionModal(true)
      }
      setIsLoading(true)
      let roleId: any
      let role = option.value.split(' ')
      let roleName: any = ''
      let rankLevel: any = ''
      if (option.label !== 'ED') {
        role.forEach((value: any, index: any) => {
          if (index < role.length - 1) {
            roleName += value + ' '
          }
        })
        roleName = roleName.trim()
        roleId = parseInt(role[role.length - 1], 10)
        rankLevel = roleName + 'Level ' + role[role.length - 1]
      } else {
        rankLevel = option.value + 'Level 1'
      }

      if (rowData.marketScopeData != undefined && rowData.marketScopeData?.marketScopes.length != 0) {
        rowData.marketScopeData?.marketScopes.forEach((scope: any) => {
          if (option.value != '' && scope.marketScopeId == rowData.marketScopeId && scope.rankName == rankLevel) {
            setFieldValue(`resources[${resourceIndex}].marketRate`, getRounded(scope.rate, 2))
            setFieldValue(`resources[${resourceIndex}].marketScopeId`, scope.marketScopeId)
          }
        })
      } else {
        let marketScopeApiParams = {
          competencyId: rowData.competencyId,
          pricingPlanId: pricingPlanId,
          countryName: rowData.costCountry
        }

        let result: any
        try {
          setIsLoading(true)
          result = await getMarketScopes(marketScopeApiParams)
        } catch (error) {}
        setFieldValue(`resources[${resourceIndex}].marketScopeData`, result)
        let marketScopes: any = result?.marketScopes
        marketScopes?.forEach((scope: any) => {
          if (scope?.marketScopeId == rowData.marketScopeId && option.value != '' && scope.rankName == rankLevel) {
            setFieldValue(`resources[${resourceIndex}].marketRate`, getRounded(scope.rate, 2))
            setFieldValue(`resources[${resourceIndex}].marketScopeId`, scope.marketScopeId)
          }
        })
        setSelectedResourceIndex(resourceIndex)
        setSelectedResource(rowData)
        setIsLoading(false)
      }
      setIsLoading(false)
      setSelectedResourceIndex(resourceIndex)
    } else {
      if (isPromotion) {
        const ratesByCostCentreParams = {
          costCenterId: rowData.costCentre.match(/(?<=\()(.*?)(?=\))/g).reverse()[0],
          costCenterDescription: rowData.costCentre.replace(/\((?<=\()(.*?)(?=\))\)/g, ''),
          rank: option.value,
          currency: values.planCurrency
        }
        try {
          const result = await getRatesByCostCentre(ratesByCostCentreParams)
          setFieldValue(`resources[${resourceIndex}].promotedResource.costRate`, getRounded(result.localCostRate, 2))
          setFieldValue(`resources[${resourceIndex}].promotedResource.dcrRate`, getRounded(result.dcrRate, 2))
          setFieldValue(`resources[${resourceIndex}].promotedResource.nsrRate`, getRounded(result.nsrRate, 2))
          setSelectedResourceIndex(resourceIndex)
        } catch (error) {}

        setIsLoading(true)
        let roleId: any
        let role = option.value.split(' ')
        let roleName: any = ''
        let rankLevel: any = ''
        if (option.label !== 'ED') {
          role.forEach((value: any, index: any) => {
            if (index < role.length - 1) {
              roleName += value + ' '
            }
          })
          roleName = roleName.trim()
          roleId = parseInt(role[role.length - 1], 10)
          rankLevel = roleName + 'Level ' + role[role.length - 1]
        } else {
          rankLevel = option.value + 'Level 1'
        }

        let marketScopeApiParams = {
          competencyId: rowData.competencyId,
          pricingPlanId: pricingPlanId,
          countryName: rowData.costCountry,
          roleName: rankLevel
        }

        let result: any
        try {
          setIsLoading(true)
          result = await getMarketScopes(marketScopeApiParams)
        } catch (error) {}
        let marketScopes: any = result?.marketScopes
        setFieldValue(`resources[${resourceIndex}].promotedResource.marketScopeId`, rowData.marketScopeId)
        marketScopes?.forEach((scope: any) => {
          if (scope?.marketScopeId == rowData.marketScopeId && option.value != '' && scope.rankName == rankLevel) {
            setFieldValue(`resources[${resourceIndex}].promotedResource.marketRate`, getRounded(scope.rate, 2))
          }
        })
        setSelectedResourceIndex(resourceIndex)
        setSelectedResource(rowData)
        // setIsLoading(false)
      }
    }
    setIsLoading(false)
  }

  const onChangeScope = async (option: any, resourceIndex: number, rowData: any) => {
    setIsLoading(true)
    let roleId: any
    let rankLevel: any
    let role = rowData.rank?.split(' ')
    let roleName: any = ''
    if (!rowData.rank?.includes('Executive')) {
      role.forEach((value: any, index: any) => {
        if (index < role.length - 1) {
          roleName += value + ' '
        }
      })
      roleName = roleName.trim()
      roleId = parseInt(role[role.length - 1], 10)
      rankLevel = roleName + 'Level ' + role[role.length - 1]
    } else {
      rankLevel = rowData.rank + 'Level 1'
    }

    let marketScopeApiParams = {
      pricingPlanId: pricingPlanId,
      competencyId: rowData.competencyId,
      countryName: rowData.costCountry,
      roleName: rankLevel
    }
    let marketRate: any = 0
    if (rowData.marketScopeData != undefined) {
      rowData.marketScopeData?.marketScopes.forEach((scope: any) => {
        if (
          scope.marketScopeId == option.value &&
          rowData.rank != '' &&
          rowData.rank != undefined &&
          scope.rankName == rankLevel
        ) {
          marketRate = getRounded(scope.rate, 2)
        }
      })
      setFieldValue(`resources[${resourceIndex}].marketRate`, marketRate)
      setFieldValue(`resources[${resourceIndex}].marketScopeId`, option.value)
      setIsLoading(false)
    } else {
      let result: any
      try {
        setIsLoading(true)
        result = await getMarketScopes(marketScopeApiParams)
      } catch (error) {}

      setFieldValue(`resources[${resourceIndex}].marketScopeData`, result)
      let marketScopes: any = result.marketScopes
      let marketRate: any = 0
      marketScopes.forEach((scope: any) => {
        if (
          scope.marketScopeId == option.value &&
          rowData.rank != '' &&
          rowData.rank != undefined &&
          scope.rankName == rankLevel
        ) {
          marketRate = getRounded(scope.rate, 2)
        }
      })
      setFieldValue(`resources[${resourceIndex}].marketRate`, marketRate)
      setFieldValue(`resources[${resourceIndex}].marketScopeId`, option.value)
      setIsLoading(false)
      setSelectedResourceIndex(resourceIndex)
    }
    setSelectedResourceIndex(resourceIndex)
    setIsLoading(false)
  }

  return (
    <div className='resource-table-container'>
      <div
        className={`resource-table-wrapper ${showHours ? 'hours-table' : ''} `}
        onScroll={() => {
          let name = Array.from(
            document.getElementsByClassName('react-datepicker-popper') as HTMLCollectionOf<HTMLElement>
          )
          if (name[0] != undefined) name[0].style.visibility = 'hidden'
        }}
      >
        {showHours && (
          <span className='material-icons close-hours' onClick={() => setShowHours(false)}>
            close
          </span>
        )}

        <table className={`resource-table ${showHours ? 'hours-table' : ''} `}>
          <thead>
            <tr>
              {columns.map((column: any) => {
                if (column.path === 'weeklyTimeUnits') {
                  if (column.isShow === true) {
                    return (
                      <React.Fragment key={column.path}>
                        {column.innerColumns?.length > 0 &&
                          column.innerColumns.map((innerColumn: any) => {
                            return (
                              <th className={innerColumn.path} key={innerColumn.path}>
                                {innerColumn.name}
                              </th>
                            )
                          })}
                      </React.Fragment>
                    )
                  }
                  return null
                }
                if (column.path === 'hours' && column.isShow) {
                  return (
                    <th key={column.path} className={column.path}>
                      {values.inputUnit === 'Hours' ? 'Hours' : 'Days'}
                    </th>
                  )
                }
                if (column.path === 'clientRateHour' && column.isShow) {
                  return values.inputUnit === 'Hours' ? (
                    <th key={column.path} className={column.path}>
                      Client Bill Rate/hr
                      {values.planCurrency ? `${' '}(${values.planCurrency})` : ''}
                    </th>
                  ) : (
                    <th key={column.path} className={column.path}>
                      Client Bill Rate/day
                      {values.planCurrency ? `${' '}(${values.planCurrency})` : ''}
                    </th>
                  )
                }

                if (column.isShow) {
                  return (
                    <th key={column.path} className={column.path}>
                      {column.name}
                    </th>
                  )
                }
                return null
              })}
            </tr>
          </thead>
          <tbody>
            {values.resources?.map((rowData: any, resourceIndex: number) => {
              return (
                <React.Fragment key={resourceIndex}>
                  <tr
                    className={`${
                      rowData.promotedResource && (view === 'edit' || view === 'list') ? 'hide-border-bottom' : ''
                    }`}
                  >
                    {columns.map(({ path, innerColumns, isShow }: any) => {
                      if (path === 'weeklyTimeUnits' && isShow) {
                        return (
                          <FieldArray key={path} name={`resources[${resourceIndex}].weeklyTimeUnits`}>
                            {(arrayHelpers) => {
                              weeklyTimeUnitsArrayHelpersRef.current[resourceIndex] = arrayHelpers
                              return (
                                <>
                                  {innerColumns?.map((innerColumn: any) => {
                                    const weeklyTimeUnit = rowData.weeklyTimeUnits?.find(
                                      (itm: any) => itm.startDate === innerColumn.path
                                    )
                                    if (weeklyTimeUnit) {
                                      const weeklyTimeUnitIndex = rowData.weeklyTimeUnits?.findIndex(
                                        (itm: any) => itm.startDate === innerColumn.path
                                      )
                                      const name = `resources[${resourceIndex}].weeklyTimeUnits[${weeklyTimeUnitIndex}].timeUnits`
                                      return (
                                        <td key={innerColumn.path}>
                                          <Field
                                            type='number'
                                            name={name}
                                            className={`input-field ${
                                              renderError(name, errors, touched) ? 'error' : ''
                                            }`}
                                            onInput={restrictDecimals}
                                          />
                                          <ErrorMessage name={name} component={TextError} />
                                        </td>
                                      )
                                    }
                                    return (
                                      <td key={innerColumn.path}>
                                        <input className='input-field' disabled value={0} />
                                      </td>
                                    )
                                  })}
                                </>
                              )
                            }}
                          </FieldArray>
                        )
                      }

                      if (path === 'workstream' && isShow) {
                        return (
                          <td key={path}>
                            <Workstreams
                              rowData={rowData}
                              path={path}
                              view={view}
                              resourceIndex={resourceIndex}
                              errors={errors}
                              touched={touched}
                              workstreamOptions={workstreamOptions}
                            />
                          </td>
                        )
                      }

                      if (path === 'marketScope' && isShow) {
                        return (
                          <td key={path}>
                            <MarketScope
                              selectedCountry={selectedCountry}
                              rowData={rowData}
                              view={view}
                              path={path}
                              resourceIndex={resourceIndex}
                              showHours={showHours}
                              onChangeScope={onChangeScope}
                              marketScopeValues={marketScopes}
                              onOpenModal={() => {
                                setAvailableCountries(rowData.availableCountryTier)
                                setSelectedResourceIndex(resourceIndex)
                                setSelectedResource(rowData)
                                setCountryModalOpen(true)
                              }}
                            />
                          </td>
                        )
                      }
                      if (path === 'role' && isShow) {
                        return (
                          <td key={path}>
                            <Role
                              rowData={rowData}
                              path={path}
                              view={view}
                              resourceIndex={resourceIndex}
                              errors={errors}
                              touched={touched}
                            />
                          </td>
                        )
                      }

                      if (path === 'employee' && isShow) {
                        if (rowData.isGDS === true) {
                          rowData.costCentreData =
                            rowData.costCentre !== '' ? rowData.costCentre : rowData.costCentreData
                          rowData.costCentre = rowData.costCentre.includes('||~') ? '' : rowData.costCentre
                        }
                        return (
                          <td key={path}>
                            <Employee
                              rowData={rowData}
                              path={path}
                              view={view}
                              resourceIndex={resourceIndex}
                              onOpenModal={() => {
                                setAvailableCostCentres(rowData.availableCostCentres)
                                setSelectedResourceIndex(resourceIndex)
                                setSelectedResource(rowData)
                                setOpenCostCentreSelectionModal(true)
                              }}
                            />
                          </td>
                        )
                      }

                      if (path === 'rank' && isShow) {
                        return (
                          <td key={path}>
                            <Rank
                              rowData={rowData}
                              view={view}
                              path={path}
                              resourceIndex={resourceIndex}
                              showHours={showHours}
                              onChangeRank={onChangeRank}
                              ranks={ranks}
                            />
                          </td>
                        )
                      }

                      if (path === 'isPromotion' && isShow) {
                        if (rowData.employee === 'Unnamed' || checkResourceIsSubcontractor(rowData)) {
                          return <td key={path}></td>
                        }
                        return (
                          <td key={path}>
                            <Promotion
                              rowData={rowData}
                              view={view}
                              path={path}
                              resourceIndex={resourceIndex}
                              onChange={(checked: boolean) => {
                                onChangePromotion(checked, rowData, resourceIndex)
                              }}
                            />
                          </td>
                        )
                      }

                      if (path === 'hours' && isShow) {
                        return (
                          <td key={path}>
                            <Hours
                              rowData={rowData}
                              view={view}
                              path={path}
                              resourceIndex={resourceIndex}
                              handleClick={() => setShowHours(true)}
                              inputUnit={values.inputUnit}
                            />
                          </td>
                        )
                      }

                      if (path === 'costRateHour' && isShow) {
                        return (
                          <td key={path}>
                            <CostRateHour rowData={rowData} view={view} path={path} resourceIndex={resourceIndex} />
                          </td>
                        )
                      }
                      if (path === 'marketRateHour' && isShow) {
                        return (
                          <td key={path}>
                            <MarketRateHour
                              rowData={rowData}
                              view={view}
                              path={path}
                              resourceIndex={resourceIndex}
                              errors={errors}
                              touched={touched}
                            />
                          </td>
                        )
                      }

                      if (path === 'clientRateHour' && isShow) {
                        if (values.inputUnit === 'Hours') {
                          return (
                            <td key={path}>
                              <ClientRateHour
                                rowData={rowData}
                                errors={errors}
                                touched={touched}
                                view={view}
                                path={path}
                                resourceIndex={resourceIndex}
                                inputUnit={values.inputUnit}
                              />
                            </td>
                          )
                        } else if (values.inputUnit === 'Days') {
                          return (
                            <td key={path}>
                              <ClientRateHour1
                                rowData={rowData}
                                errors={errors}
                                touched={touched}
                                view={view}
                                path={path}
                                resourceIndex={resourceIndex}
                                inputUnit={values.inputUnit}
                              />
                            </td>
                          )
                        }
                      }

                      if (path === 'totalCost' && isShow) {
                        if (resourceIndex !== selectedResourceIndex) {
                          return (
                            <td key={path}>
                              <TotalLabourCost
                                rowData={rowData}
                                view={view}
                                path={path}
                                resourceIndex={resourceIndex}
                                dealStartDate={values.dealStartDate}
                                dealEndDate={values.dealEndDate}
                                inflationRate={inflationRate}
                                countryInflationRate={countryInflationRate}
                                gdsInflation={gdsInflation} //temp
                              />
                            </td>
                          )
                        } else if (resourceIndex === selectedResourceIndex) {
                          return (
                            <td key={path}>
                              <TotalLabourCost5
                                rowData={rowData}
                                view={view}
                                path={path}
                                resourceIndex={resourceIndex}
                                dealStartDate={values.dealStartDate}
                                dealEndDate={values.dealEndDate}
                                inflationRate={inflationRate}
                                countryInflationRate={countryInflationRate}
                                gdsInflation={gdsInflation} //temp
                              />
                            </td>
                          )
                        }
                      }

                      if (path === 'startDate' && isShow) {
                        return (
                          <td key={path}>
                            <StartDate
                              rowData={rowData}
                              view={view}
                              path={path}
                              resourceIndex={resourceIndex}
                              errors={errors}
                              touched={touched}
                              onChange={(date: Date) => {
                                handleChangeDate('startDate', date, resourceIndex)
                              }}
                            />
                          </td>
                        )
                      }

                      if (path === 'endDate' && isShow) {
                        return (
                          <td key={path}>
                            <EndDate
                              rowData={rowData}
                              view={view}
                              path={path}
                              resourceIndex={resourceIndex}
                              errors={errors}
                              touched={touched}
                              onChange={(date: Date) => {
                                handleChangeDate('endDate', date, resourceIndex)
                              }}
                            />
                          </td>
                        )
                      }

                      if (path === 'timeUnitsPerWeek' && isShow) {
                        return (
                          <td key={path}>
                            <HoursPerWeek
                              rowData={rowData}
                              view={view}
                              path={path}
                              errors={errors}
                              touched={touched}
                              resourceIndex={resourceIndex}
                              handleClickApply={() => handleClickApply(resourceIndex, rowData)}
                            />
                          </td>
                        )
                      }

                      if (path === 'actions' && isShow) {
                        return (
                          <td key={path}>
                            <Actions
                              rowData={rowData}
                              resourceIndex={resourceIndex}
                              deleteResource={props.deleteResource}
                              duplicateResource={props.duplicateResource}
                            />
                          </td>
                        )
                      }

                      return null
                    })}
                  </tr>

                  {/* Promotion Row */}

                  {rowData.promotedResource && (view === 'edit' || view === 'list') && (
                    <tr>
                      {columns.map(({ path, innerColumns, isShow }: any) => {
                        if (path === 'workstream' && isShow) return <td key={path}></td>
                        if (path === 'marketScope' && isShow) return <td key={path}></td>
                        if (path === 'role' && isShow) return <td key={path}></td>
                        if (path === 'employee' && isShow) return <td key={path}></td>
                        if (path === 'isPromotion' && isShow) return <td key={path}></td>

                        if (path === 'weeklyTimeUnits' && isShow) {
                          return (
                            <FieldArray
                              key={path}
                              name={`resources[${resourceIndex}].promotedResource.weeklyTimeUnits`}
                            >
                              {(arrayHelpers) => {
                                promotionWeeklyTimeUnitsArrayHelpersRef.current[resourceIndex] = arrayHelpers
                                return (
                                  <>
                                    {innerColumns?.map((innerColumn: any) => {
                                      const weeklyTimeUnit = rowData.promotedResource.weeklyTimeUnits?.find(
                                        (itm: any) => itm.startDate === innerColumn.path
                                      )
                                      if (weeklyTimeUnit) {
                                        const weeklyTimeUnitIndex = rowData.promotedResource.weeklyTimeUnits?.findIndex(
                                          (itm: any) => itm.startDate === innerColumn.path
                                        )
                                        const name = `resources[${resourceIndex}].promotedResource.weeklyTimeUnits[${weeklyTimeUnitIndex}].timeUnits`
                                        return (
                                          <td key={innerColumn.path}>
                                            <Field
                                              type='number'
                                              name={name}
                                              className={`input-field ${
                                                renderError(name, errors, touched) ? 'error' : ''
                                              }`}
                                              onInput={restrictDecimals}
                                            />
                                            <ErrorMessage name={name} component={TextError} />
                                          </td>
                                        )
                                      }
                                      return (
                                        <td key={innerColumn.path}>
                                          <input className='input-field' disabled value={0} />
                                        </td>
                                      )
                                    })}
                                  </>
                                )
                              }}
                            </FieldArray>
                          )
                        }

                        if (path === 'timeUnitsPerWeek' && isShow) {
                          return (
                            <td key={path}>
                              <HoursPerWeek
                                isPromotion
                                rowData={rowData}
                                view={view}
                                path={path}
                                errors={errors}
                                touched={touched}
                                resourceIndex={resourceIndex}
                                handleClickApply={() => handleClickApply(resourceIndex, rowData, true)}
                              />
                            </td>
                          )
                        }

                        if (path === 'rank' && isShow) {
                          //let ranks5
                          let ranks3 = ranks
                          let bufferArray = [] as RankType[]
                          if (ranks.length > 0) {
                            let currentRank = ranks3.filter((rank: any) => [rowData.rank].includes(rank.rank))

                            if (currentRank.length > 0) {
                              let currentLevel = currentRank[0].abbreviation
                              let currentPriority = getPriorityLevels(currentRank[0].abbreviation.toUpperCase())
                              ranks3?.map((innerColumn: any) => {
                                let rankPriority = 0
                                rankPriority = getPriorityLevels(innerColumn.abbreviation.toUpperCase())

                                if (rankPriority <= currentPriority) {
                                  if (rankPriority === currentPriority && currentRank[0].level === innerColumn.level) {
                                    if (innerColumn.abbreviation > currentLevel) bufferArray.push(innerColumn)
                                  } else {
                                    bufferArray.push(innerColumn)
                                  }
                                }
                              })
                              // ranks5 = ranks3.filter((rank: any) => {
                              //   for (var i = 0; i < bufferArray.length; i++) {
                              //     if (rank.abbreviation.includes(bufferArray[i].abbreviation)) {
                              //       break
                              //     }
                              //   }
                              // })
                            } else {
                              bufferArray = ranks
                            }
                          }
                          return (
                            <td key={path}>
                              <Rank
                                rowData={rowData}
                                isPromotion
                                view={view}
                                path={path}
                                resourceIndex={resourceIndex}
                                showHours={showHours}
                                onChangeRank={onChangeRank}
                                ranks={bufferArray}
                              />
                            </td>
                          )
                        }

                        if (path === 'hours' && isShow) {
                          return (
                            <td key={path}>
                              <Hours
                                rowData={rowData}
                                isPromotion
                                view={view}
                                path={path}
                                resourceIndex={resourceIndex}
                                handleClick={() => setShowHours(true)}
                                inputUnit={values.inputUnit}
                              />
                            </td>
                          )
                        }

                        if (path === 'costRateHour' && isShow) {
                          return (
                            <td key={path}>
                              <CostRateHour
                                isPromotion
                                rowData={rowData}
                                view={view}
                                path={path}
                                resourceIndex={resourceIndex}
                              />
                            </td>
                          )
                        }
                        if (path === 'marketRateHour' && isShow) {
                          return (
                            <td key={path}>
                              <MarketRateHour
                                isPromotion
                                rowData={rowData}
                                view={view}
                                path={path}
                                resourceIndex={resourceIndex}
                                marketScopeData={marketScopes}
                                errors={errors}
                                touched={touched}
                              />
                            </td>
                          )
                        }

                        if (path === 'clientRateHour' && isShow) {
                          if (values.inputUnit === 'Hours') {
                            return (
                              <td key={path}>
                                <ClientRateHour
                                  // isPromotion
                                  // rowData={rowData}
                                  // view={view}
                                  // path={path}
                                  // resourceIndex={resourceIndex}
                                  isPromotion={true}
                                  rowData={rowData}
                                  errors={errors}
                                  touched={touched}
                                  view={view}
                                  path={path}
                                  resourceIndex={resourceIndex}
                                  inputUnit={values.inputUnit}
                                />
                              </td>
                            )
                          } else if (values.inputUnit === 'Days') {
                            return (
                              <td key={path}>
                                <ClientRateHour1
                                  // isPromotion
                                  // rowData={rowData}
                                  // view={view}
                                  // path={path}
                                  // resourceIndex={resourceIndex}
                                  isPromotion={true}
                                  rowData={rowData}
                                  errors={errors}
                                  touched={touched}
                                  view={view}
                                  path={path}
                                  resourceIndex={resourceIndex}
                                  inputUnit={values.inputUnit}
                                />
                              </td>
                            )
                          }
                        }

                        if (path === 'totalCost' && isShow) {
                          if (resourceIndex !== selectedResourceIndex) {
                            return (
                              <td key={path}>
                                <TotalLabourCost
                                  isPromotion
                                  rowData={rowData}
                                  view={view}
                                  path={path}
                                  resourceIndex={resourceIndex}
                                  dealStartDate={values.dealStartDate}
                                  dealEndDate={values.dealEndDate}
                                  inflationRate={inflationRate}
                                  countryInflationRate={countryInflationRate}
                                  gdsInflation={gdsInflation} //temp
                                />
                              </td>
                            )
                          } else {
                            return (
                              <td key={path}>
                                <TotalLabourCost5
                                  isPromotion
                                  rowData={rowData}
                                  view={view}
                                  path={path}
                                  resourceIndex={resourceIndex}
                                  dealStartDate={values.dealStartDate}
                                  dealEndDate={values.dealEndDate}
                                  inflationRate={inflationRate}
                                  countryInflationRate={countryInflationRate}
                                  gdsInflation={gdsInflation} //temp
                                />
                              </td>
                            )
                          }
                        }

                        if (path === 'startDate' && isShow) {
                          return (
                            <td key={path}>
                              <StartDate
                                isPromotion
                                rowData={rowData}
                                view={view}
                                path={path}
                                resourceIndex={resourceIndex}
                                errors={errors}
                                touched={touched}
                                onChange={(date: Date) => {
                                  handleChangeDate('startDate', date, resourceIndex, true)
                                }}
                              />
                            </td>
                          )
                        }

                        if (path === 'endDate' && isShow) {
                          return (
                            <td key={path}>
                              <EndDate
                                isPromotion
                                rowData={rowData}
                                view={view}
                                path={path}
                                resourceIndex={resourceIndex}
                                errors={errors}
                                touched={touched}
                                onChange={(date: Date) => {
                                  handleChangeDate('endDate', date, resourceIndex, true)
                                }}
                              />
                            </td>
                          )
                        }

                        if (path === 'actions' && isShow) {
                          return (
                            <td key={path}>
                              <Actions
                                isPromotion
                                rowData={rowData}
                                resourceIndex={resourceIndex}
                                deletePromotion={deletePromotion}
                              />
                            </td>
                          )
                        }

                        return null
                      })}
                    </tr>
                  )}
                </React.Fragment>
              )
            })}
          </tbody>
        </table>
      </div>
      <CostCenterModal
        availableCostCentres={availableCostCentres}
        isOpen={isOpenCostCentreSelectionModal}
        onCancel={() => {
          setOpenCostCentreSelectionModal(false)
          setSelectedResourceIndex(null)
        }}
        onConfirm={async (costCentre) => {
          setOpenCostCentreSelectionModal(false)
          //setSelectedResourceIndex(null)
          let costRate = 0
          let dcrRate = 0
          let nsrRate = 0
          if (costCentre.localCostRate && costCentre.dcrRate && costCentre.nsrRate) {
            costRate = costCentre.localCostRate
            dcrRate = costCentre.dcrRate
            nsrRate = costCentre.nsrRate
          } else {
            const ratesByCostCentreParams = {
              costCenterId: costCentre.costCenterId.match(/(?<=\()(.*?)(?=\))/)[0],
              costCenterDescription: costCentre.costCenterDescription.trim(),
              rank: _.get(values, `resources[${selectedResourceIndex}].rank`, ''),
              currency: values.planCurrency
            }
            try {
              const result = await getRatesByCostCentre(ratesByCostCentreParams)
              costRate = result.localCostRate
              dcrRate = result.dcrRate
              nsrRate = result.nsrRate
            } catch (error) {}
          }
          setFieldValue(
            `resources[${selectedResourceIndex}].costCentre`,
            `${costCentre.costCenterDescription}${costCentre.costCenterId}`
          )
          setFieldValue(`resources[${selectedResourceIndex}].costRate`, getRounded(costRate, 2))
          setFieldValue(`resources[${selectedResourceIndex}].dcrRate`, getRounded(dcrRate, 2))
          setFieldValue(`resources[${selectedResourceIndex}].nsrRate`, getRounded(nsrRate, 2))
          // let marketScopeApiParams = {
          //   costCenterName: `${costCentre.costCenterDescription}${costCentre.costCenterId}`,
          //   pricingPlanId: pricingPlanId,
          //   roleName: resourceRank.split(' ')[0]
          //   // roleName: selectedResource.rank.split(' ')[0]
          // }
          // let marketScopeData: any
          // let result: any
          // try {
          //   result = await getMarketScopes(marketScopeApiParams)
          // } catch (error) {}
          // setFieldValue(`resources[${selectedResourceIndex}].marketScopeData`, result)

          // marketScopeData = result.marketScopes
          // let defaultData: any
          // let marketScopeId: any
          // let countryId: any
          // let availableMarketScopes: any[] = []
          // let marketRate: any

          // marketScopeData.forEach((scope: any) => {
          //   let availableMarketScope = { marketScopeValue: scope.marketScopeValue, marketScopeId: scope.marketScopeId }
          //   availableMarketScopes.push(availableMarketScope)
          //   marketScopeId = scope.isDefault === true ? scope.marketScopeId : ''
          //   marketRate = scope.isDefault === true ? scope.rate : ''
          // })

          // let countryInfomartion = getCountryInformation(marketScopeData)
          // let availableCountries = countryInfomartion.availableCountries
          // countryId = countryInfomartion.defaultCountry.countryId

          // setFieldValue(`resources[${selectedResourceIndex}].availableCountryTier`, availableCountries)
          // setFieldValue(`resources[${selectedResourceIndex}].countryId`, countryId)
          // setFieldValue(`resources[${selectedResourceIndex}].availableMarketScopes`, availableMarketScopes)
          // setFieldValue(`resources[${selectedResourceIndex}].marketScopeId`, marketScopeId)
          // setFieldValue(`resources[${selectedResourceIndex}].marketRate`, marketRate)
          // setFieldValue(`resources[${selectedResourceIndex}].marketScopeData`, marketScopeData)
          // setFieldValue(`resources[${selectedResourceIndex}].targetCurrencyId`, result.targetCurrencyId)
          // setFieldValue(`resources[${selectedResourceIndex}].sectorId`, result.sectorId)
          // setFieldValue(`resources[${selectedResourceIndex}].applicationId`, result.applicationId)

          setSelectedResourceIndex(selectedResourceIndex)
        }}
      />
      <CountryModal
        isOpen={isCountryModalOpen}
        onCancel={function (): void {
          setCountryModalOpen(false)
          setSelectedResourceIndex(null)
        }}
        onConfirm={async function (selectedCountry: any) {
          let marketScopeApiParams = {
            costCenterName: selectedResource.costCentre,
            pricingPlanId: pricingPlanId,
            offeringId: selectedResource.marketScopeId,
            targetCurrencyId: selectedResource.targetCurrencyId,
            countryId: selectedCountry.countryId,
            sectorId: selectedResource.sectorId,
            applicationId: selectedResource.applicationId,
            roleId: parseInt(selectedResource.rank?.split(' ')[1], 10),
            roleName: selectedResource.rank.split(' ')[0]
          }

          let result: any
          try {
            result = await getMarketScopes(marketScopeApiParams)
          } catch (error) {}
          let marketScopes: any = result.marketScopes
          let marketScopesBasedOnCountry: any[] = []
          marketScopes.forEach((scope: any) => {
            if (scope.countryId == selectedCountry.countryId) {
              marketScopesBasedOnCountry.push(scope)
            }
          })
          if (marketScopesBasedOnCountry?.length > 0) {
            marketScopesBasedOnCountry.forEach((scope: any) => {
              if (scope.isDefault == true) {
                setFieldValue(`resources[${selectedResourceIndex}].marketScopeId`, scope.marketScopeId)
                setFieldValue(`resources[${selectedResourceIndex}].marketRate`, getRounded(scope.rate))
              } else {
                setFieldValue(`resources[${selectedResourceIndex}].marketScopeId`, -1)
                setFieldValue(`resources[${selectedResourceIndex}].marketRate`, 0.0)
              }
            })
          }
          setFieldValue(`resources[${selectedResourceIndex}].availableMarketScopes`, marketScopesBasedOnCountry)
          setFieldValue(`resources[${selectedResourceIndex}].countryId`, selectedCountry.countryId)
          setSelectedResourceIndex(selectedResourceIndex)
          setCountryModalOpen(false)
        }}
        //getAvailableCountries = {getAvailableCountries}
        availableCountries={availableCountries}
      />
    </div>
  )
}

export default Table
