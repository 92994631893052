import React, { ReactElement, useRef } from 'react'
import { useContext, useEffect, useState } from 'react'
import { updateInputStyling } from '../utils/updateStyling'
import './InflationAdjustmentModal.css'
import { getPricingPlan, updatePricingPlan } from '../utils/fetchHandler'
import { UserContext } from '../../App'
import { Form, Formik, FormikHelpers } from 'formik'
import { float, FLOAT } from 'html2canvas/dist/types/css/property-descriptors/float'
interface InflationAdjustmentModalProps {
  PricingPlan: any
  SetPricingPlan: Function
  FY1Inflation: string
  FY2Inflation: string
  FY3Inflation: string
  FY4Inflation: string
  //FY1Inflation1: number
  setFY1InflationAdj: (count: string) => void
  setFY2InflationAdj: (count: string) => void
  setFY3InflationAdj: (count: string) => void
  setFY4InflationAdj: (count: string) => void
  //FY1InflationAdj: (count: number) => void
  onConfirm: () => void
}

const InflationAdjustmentModal: // (props: InflationAdjustmentModalProps): ReactElement => {
React.FC<InflationAdjustmentModalProps> = ({
  PricingPlan,
  SetPricingPlan,
  FY1Inflation,
  FY2Inflation,
  FY3Inflation,
  FY4Inflation,
  setFY1InflationAdj,
  setFY2InflationAdj,
  setFY3InflationAdj,
  setFY4InflationAdj,
  //FY1InflationAdj,
  //FY1Inflation1,
  onConfirm
}) => {
  const userId = useContext(UserContext)[1]
  const [FY11Inflation, setFY1Inflation] = useState<number>(0)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const inputRef = useRef<HTMLInputElement>(null)
  const inputRef1 = useRef<HTMLInputElement>(null)
  const inputRef2 = useRef<HTMLInputElement>(null)
  const inputRef3 = useRef<HTMLInputElement>(null)
  //const [FY1Inflation, setFY1Inflation] = useState<number>(0)
  const hideModal = () => {
    const deleteModal = document.getElementById('Inflation-adj-modal') as HTMLElement
    deleteModal.style.display = 'none'
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    const numberPattern = /^\d{1}(\.\d{0,4})?$/
    let value1 = value
    if (value.toString().endsWith('.')) {
      value1 = value //.concat('0')
    }
    let test5 = parseFloat('1.0')
    if (!numberPattern.test(value1)) {
      setErrorMessage('Please enter a valid number')
    } else {
      const numericValue = parseFloat(value1)
      if (isNaN(numericValue) || numericValue < 0 || numericValue > 50) {
        setErrorMessage('Please enter a value between 1 and 50')
      } else {
        setFY1InflationAdj(value1)
        setErrorMessage('')
      }
    }
  }

  const handleInputChange1 = (e: React.ChangeEvent<HTMLInputElement>) => {
    // const value = e.target.value
    // debugger
    // //const numberPattern = /^[0-9]*$/
    // const numberPattern = /^\d{1,}(\.\d{0,4})?$/

    // if (!numberPattern.test(value)) {
    //   setErrorMessage('Please enter a valid number')
    // } else {
    //   const numericValue = parseFloat(value)
    //   if (isNaN(numericValue) || numericValue < 1 || numericValue > 50) {
    //     setErrorMessage('Please enter a value between 1 and 50')
    //   } else {
    //     //setDuplicateCount(numericValue);
    //     setFY2InflationAdj(numericValue)
    //     setErrorMessage('')
    //   }
    // }
    const value = e.target.value
    const numberPattern = /^\d{1}(\.\d{0,4})?$/
    if (!numberPattern.test(value)) {
      setErrorMessage('Please enter a valid number')
    } else {
      const numericValue = parseFloat(value)
      if (isNaN(numericValue) || numericValue < 0) {
        setErrorMessage('Please enter a value between 1 and 50')
      } else {
        setFY2InflationAdj(value)
        setErrorMessage('')
      }
    }
  }
  const handleInputChange2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    // const value = e.target.value
    // debugger
    // const numberPattern = /^[0-9]*$/

    // if (!numberPattern.test(value)) {
    //   setErrorMessage('Please enter a valid number')
    // } else {
    //   const numericValue = parseInt(value, 10)
    //   if (isNaN(numericValue) || numericValue < 1 || numericValue > 50) {
    //     setErrorMessage('Please enter a value between 1 and 50')
    //   } else {
    //     //setDuplicateCount(numericValue);
    //     setFY3InflationAdj(numericValue)
    //     setErrorMessage('')
    //   }
    // }
    const value = e.target.value
    const numberPattern = /^\d{1}(\.\d{0,4})?$/
    if (!numberPattern.test(value)) {
      setErrorMessage('Please enter a valid number')
    } else {
      const numericValue = parseFloat(value)
      if (isNaN(numericValue) || numericValue < 0) {
        setErrorMessage('Please enter a value between 1 and 50')
      } else {
        setFY3InflationAdj(value)
        setErrorMessage('')
      }
    }
  }
  const handleInputChange3 = (e: React.ChangeEvent<HTMLInputElement>) => {
    // const value = e.target.value
    // debugger
    // const numberPattern = /^[0-9]*$/

    // if (!numberPattern.test(value)) {
    //   setErrorMessage('Please enter a valid number')
    // } else {
    //   const numericValue = parseInt(value, 10)
    //   if (isNaN(numericValue) || numericValue < 1 || numericValue > 50) {
    //     setErrorMessage('Please enter a value between 1 and 50')
    //   } else {
    //     //setDuplicateCount(numericValue);
    //     setFY4InflationAdj(numericValue)
    //     setErrorMessage('')
    //   }
    // }

    const value = e.target.value
    const numberPattern = /^\d{1}(\.\d{0,4})?$/
    if (!numberPattern.test(value)) {
      setErrorMessage('Please enter a valid number')
    } else {
      const numericValue = parseFloat(value)
      if (isNaN(numericValue) || numericValue < 0) {
        setErrorMessage('Please enter a value between 1 and 50')
      } else {
        setFY4InflationAdj(value)
        setErrorMessage('')
      }
    }
  }
  const confirmAction = async () => {
    // debugger

    const plan = PricingPlan
    plan.summaryViewModel.fy1InflationAdj = FY1Inflation
    plan.summaryViewModel.fy2InflationAdj = FY2Inflation
    plan.summaryViewModel.fy3InflationAdj = FY3Inflation
    plan.summaryViewModel.fy4InflationAdj = FY4Inflation

    hideModal()
    onConfirm()
  }

  return (
    <div>
      <div id='Inflation-adj-modal'>
        <div className='Inflation-adj-modal-content'>
          <p className='heading-three'>Please confirm adjusted inflation rates %</p>
          <div className='test5'>
            <div className='input-container'>
              FY26{' '}
              <input
                ref={inputRef}
                className='box'
                type='text'
                name='FY1'
                value={errorMessage ? inputRef.current?.value || '' : FY1Inflation}
                onChange={handleInputChange}
              />
            </div>
            <div className='input-container'>
              <label>FY27</label>{' '}
              <input
                ref={inputRef1}
                className='box'
                type='text'
                name='FY2'
                value={errorMessage ? inputRef1.current?.value || '' : FY2Inflation}
                onChange={handleInputChange1}
              />
            </div>
            <div className='input-container'>
              <label>FY28</label>{' '}
              <input
                ref={inputRef2}
                className='box'
                type='text'
                name='FY3'
                value={errorMessage ? inputRef2.current?.value || '' : FY3Inflation}
                onChange={handleInputChange2}
              />
            </div>
            <div className='input-container'>
              <label>FY29</label>{' '}
              <input
                ref={inputRef3}
                className='box'
                type='text'
                name='FY4'
                value={errorMessage ? inputRef3.current?.value || '' : FY4Inflation}
                onChange={handleInputChange3}
              />
            </div>
          </div>

          <div className='Inflation-adj-modal-buttons'>
            <button className='primary-button heading-three' onClick={confirmAction}>
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InflationAdjustmentModal
