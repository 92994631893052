import './ClientViewBox.css'
import { useEffect, useState } from 'react'
import { numberWithCommas } from './../utils/helperFunctions'

interface ClientViewBoxProps {
  title: string
  selectedColumn: number
  setSelectedColumn: Function
  columnNum: number
  formValues: any
  expensesValues: any
  resourcesValues: any
  clientViewValues: any
  setClientViewValues: Function
  planCurrency: string
  inputResources: any
  inputExpenses: any
}

const ClientViewBox = (props: ClientViewBoxProps) => {
  const {
    title,
    selectedColumn,
    setSelectedColumn,
    columnNum,
    formValues,
    expensesValues,
    resourcesValues,
    clientViewValues,
    setClientViewValues,
    planCurrency,
    inputResources,
    inputExpenses
  } = props
  const [scenarioName, setScenarioName] = useState<string>('')
  // const scenarios = ['base', 'targetMargin', 'fixedFee', 'client','marketRate']
  //  const scenarios = ['targetMargin', 'fixedFee', 'client','marketRate']
  const scenarios = ['marketRate', 'targetMargin', 'fixedFee', 'client']

  useEffect(() => {
    // if (selectedColumn === 0) {
    //   setScenarioName('base')
    // }
    if (selectedColumn === 0) {
      //setScenarioName('targetMargin')
      setScenarioName('marketRate')
    } else if (selectedColumn === 1) {
      setScenarioName('targetMargin')
    } else if (selectedColumn === 2) {
      setScenarioName('fixedFee')
    } else if (selectedColumn === 3) {
      //setScenarioName('marketRate')
      setScenarioName('client')
    }
  }, [selectedColumn])

  // Perform calculations when a value form, resource or expenses changes
  useEffect(() => {
    if (scenarioName !== '') {
      calculateServicesFees()
      calculateSubcontractors()
      calculateExpenses()
      calculateTotalBillingAmount()
    }
  }, [scenarioName, formValues, resourcesValues, expensesValues])

  // source corresponds to a Resource's source field
  // marginRow values can be found in the EditableBox component
  const calculateResourceRevenue = (source: string, scenario: string, marginRow: string) => {
    let resourceRevenue = 0
    let costType = 'totalCost'

    if (scenario === 'client') costType = 'totalClientRate'
    //if (scenario === 'marketRate') costType = 'totalMarketRate'
    for (let i = 0; i < inputResources.length; i++) {
      if (inputResources[i].source === source) {
        let resourceMargin = formValues[scenario].margin[marginRow] / 100
        let resourceCost = inputResources[i][costType]
        if (inputResources[i].promotedResource) {
          resourceCost += inputResources[i].promotedResource[costType]
        }
        resourceRevenue += resourceCost / (1 - resourceMargin)
      }
    }
    return resourceRevenue
  }

  // Get the sum of recoverable expenses of a specified category
  // category can be found in the MobilityExpense and TechnologyExpense components
  const calculateRecoverableSum = (category: string) => {
    let recoverableSum = 0
    for (let i = 0; i < inputExpenses.length; i++) {
      if (inputExpenses[i].category === category && inputExpenses[i].recoverable === true) {
        recoverableSum += inputExpenses[i].cost
      }
    }
    return recoverableSum
  }

  // Calculation functions to determine the values in clientViewValues
  // resource revenue(except subcontractors) plus recoverable tech expenses
  const calculateServicesFees = () => {
    for (let i = 0; i < 4; i++) {
      const totalBillingNoContingency = clientViewValues[scenarios[i]].totalBillingAmount.exclContingency
      const totalExpensesNoContingency = clientViewValues[scenarios[i]].expenses.exclContingency
      const subcontractorsNoContingency = clientViewValues[scenarios[i]].subcontractors.exclContingency
      const servicesFeesNoContingency =
        totalBillingNoContingency - totalExpensesNoContingency - subcontractorsNoContingency
      clientViewValues[scenarios[i]].servicesFees.exclContingency = servicesFeesNoContingency

      const totalBillingContingency = clientViewValues[scenarios[i]].totalBillingAmount.inclContingency
      const totalExpensesContingency = clientViewValues[scenarios[i]].expenses.inclContingency
      const subcontractorsContingency = clientViewValues[scenarios[i]].subcontractors.inclContingency
      const servicesFeesContingency = totalBillingContingency - totalExpensesContingency - subcontractorsContingency
      clientViewValues[scenarios[i]].servicesFees.inclContingency = servicesFeesContingency

      setClientViewValues({
        ...clientViewValues,
        [scenarios[i]]: {
          ...clientViewValues[scenarios[i]],
          servicesFees: {
            ...clientViewValues[scenarios[i]].servicesFees,
            exclContingency: servicesFeesNoContingency,
            inclContingency: servicesFeesContingency
          }
        }
      })
    }
  }

  const calculateSubcontractors = () => {
    for (let i = 0; i < 4; i++) {
      const subcontractorNoContingency = expensesValues[scenarios[i]].subcontractors.recoverable
      clientViewValues[scenarios[i]].subcontractors.exclContingency = subcontractorNoContingency

      const subcontractorContingency = expensesValues[scenarios[i]].subcontractors.totalExpenseBillings
      clientViewValues[scenarios[i]].subcontractors.inclContingency = subcontractorContingency

      setClientViewValues({
        ...clientViewValues,
        [scenarios[i]]: {
          ...clientViewValues[scenarios[i]],
          subcontractors: {
            ...clientViewValues[scenarios[i]].subcontractors,
            exclContingency: subcontractorNoContingency,
            inclContingency: subcontractorContingency
          }
        }
      })
    }
  }

  const calculateExpenses = () => {
    for (let i = 0; i < 4; i++) {
      const totalRecoverable = expensesValues[scenarios[i]].totalExpenses.recoverable
      const subcontractorRecoverable = expensesValues[scenarios[i]].subcontractors.recoverable
      const expensesNoContingency = totalRecoverable - subcontractorRecoverable
      clientViewValues[scenarios[i]].expenses.exclContingency = expensesNoContingency

      const totalExpenses = expensesValues[scenarios[i]].totalExpenses.totalExpenseBillings
      const subcontractorTotalExpenseBillings = expensesValues[scenarios[i]].subcontractors.totalExpenseBillings
      const expensesContingency = totalExpenses - subcontractorTotalExpenseBillings
      clientViewValues[scenarios[i]].expenses.inclContingency = expensesContingency

      setClientViewValues({
        ...clientViewValues,
        [scenarios[i]]: {
          ...clientViewValues[scenarios[i]],
          expenses: {
            ...clientViewValues[scenarios[i]].expenses,
            exclContingency: expensesNoContingency,
            inclContingency: expensesContingency
          }
        }
      })
    }
  }

  const calculateTotalBillingAmount = () => {
    for (let i = 0; i < 4; i++) {
      const totalBillingAmount = formValues[scenarios[i]].totalBilling
      clientViewValues[scenarios[i]].totalBillingAmount.exclContingency = totalBillingAmount
      clientViewValues[scenarios[i]].totalBillingAmount.inclContingency = totalBillingAmount

      setClientViewValues({
        ...clientViewValues,
        [scenarios[i]]: {
          ...clientViewValues[scenarios[i]],
          totalBillingAmount: {
            ...clientViewValues[scenarios[i]].totalBillingAmount,
            exclContingency: totalBillingAmount,
            inclContingency: totalBillingAmount
          }
        }
      })
    }
  }

  const onBoxClick = () => {
    setSelectedColumn(columnNum)
  }

  return (
    <div
      className={`client-view-box-container ${selectedColumn === columnNum ? 'client-view-selected' : ''}`}
      onClick={onBoxClick}
    >
      <h3 className='heading-three'>{title}</h3>
      <table className='client-view-box-table'>
        <thead>
          <tr>
            <th className='body-text client-view-box-header'>
              <div>Including</div>
              <div>Contingency</div>
            </th>
            <th className='body-text client-view-box-header'>
              <div>Excluding</div>
              <div>Contingency</div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className='body-text'>
              {scenarioName
                ? numberWithCommas(clientViewValues[scenarios[columnNum]].servicesFees.inclContingency) +
                  ' ' +
                  planCurrency
                : '-'}
            </td>
            <td className='body-text'>
              {scenarioName
                ? numberWithCommas(clientViewValues[scenarios[columnNum]].servicesFees.exclContingency) +
                  ' ' +
                  planCurrency
                : '-'}
            </td>
          </tr>
          <tr>
            <td className='body-text'>
              {scenarioName
                ? numberWithCommas(clientViewValues[scenarios[columnNum]].subcontractors.inclContingency) +
                  ' ' +
                  planCurrency
                : '-'}
            </td>
            <td className='body-text'>
              {scenarioName
                ? numberWithCommas(clientViewValues[scenarios[columnNum]].subcontractors.exclContingency) +
                  ' ' +
                  planCurrency
                : '-'}
            </td>
          </tr>
          <tr>
            <td className='body-text'>
              {scenarioName
                ? numberWithCommas(clientViewValues[scenarios[columnNum]].expenses.inclContingency) + ' ' + planCurrency
                : '-'}
            </td>
            <td className='body-text'>
              {scenarioName
                ? numberWithCommas(clientViewValues[scenarios[columnNum]].expenses.exclContingency) + ' ' + planCurrency
                : '-'}
            </td>
          </tr>
          <tr>
            <td className='body-text'>
              {scenarioName
                ? numberWithCommas(clientViewValues[scenarios[columnNum]].totalBillingAmount.inclContingency) +
                  ' ' +
                  planCurrency
                : '-'}
            </td>
            <td className='body-text'>
              {scenarioName
                ? numberWithCommas(clientViewValues[scenarios[columnNum]].totalBillingAmount.exclContingency) +
                  ' ' +
                  planCurrency
                : '-'}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default ClientViewBox
