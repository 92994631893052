import React from 'react'
import styles from './LeverageBreakdown.module.css'

const getTotal = (totalGDS: number, totalNonGDS: number, totalAllocation: number) => {
  if (!totalAllocation || totalAllocation === 0) {
    return {
      label: '% of Total',
      name: 'total',
      GDS: 0,
      nonGDS: 0,
      allocation: 0
    }
  }
  return {
    label: '% of Total',
    name: 'total',
    GDS: +((totalGDS / totalAllocation) * 100).toFixed(0),
    nonGDS: +((totalNonGDS / totalAllocation) * 100).toFixed(0),
    allocation: 100
  }
}

const checkIsLevel = (resouce: any, ranks: any[], level: string) => {
  const rank = resouce.rank
  let _level
  if (rank.includes('Interns (CS)')) {
    _level = 'Intern'
  } else if (rank === 'Client Serving Contractor') {
    _level = 'Client Serving Contractor'
  } else if (rank.includes('Executive Director')) {
    _level = 'Executive Director'
  } else {
    _level = ranks.find((_rank) => _rank.rank === rank)?.level
  }
  return level === _level ? true : false
}

const LeverageBreakdown = (props: any) => {
  const { pricingPlanData, ranks } = props

  let rows = [
    {
      label: 'Partner',
      name: 'partner',
      GDS: 0,
      nonGDS: 0,
      allocation: 0
    },
    {
      label: 'Executive Director',
      name: 'executiveDirector',
      GDS: 0,
      nonGDS: 0,
      allocation: 0
    },
    {
      label: 'Senior Manager',
      name: 'seniorManager',
      GDS: 0,
      nonGDS: 0,
      allocation: 0
    },
    {
      label: 'Manager',
      name: 'manager',
      GDS: 0,
      nonGDS: 0,
      allocation: 0
    },
    {
      label: 'Senior',
      name: 'senior',
      GDS: 0,
      nonGDS: 0,
      allocation: 0
    },
    {
      label: 'Staff',
      name: 'staff',
      GDS: 0,
      nonGDS: 0,
      allocation: 0
    },
    {
      label: 'Intern',
      name: 'intern',
      GDS: 0,
      nonGDS: 0,
      allocation: 0
    },
    {
      label: 'Client Serving Contractor',
      name: 'clientServingContractor',
      GDS: 0,
      nonGDS: 0,
      allocation: 0
    },
    {
      label: 'Subcontractor',
      name: 'subcontractor',
      GDS: 0,
      nonGDS: 0,
      allocation: 0
    }
  ]

  const { resources } = pricingPlanData
  rows =
    resources?.length > 0
      ? rows.map((row) => {
          const level = row.label
          let GDS = 0
          let nonGDS = 0
          resources.forEach((resource: any) => {
            const addLeverage = (_resource: any) => {
              const isLevel = checkIsLevel(_resource, ranks, level)
              if (isLevel) {
                const isGDS = _resource.isGDS
                if (isGDS) GDS += _resource.totalHours
                else nonGDS += _resource.totalHours
              }
            }
            addLeverage(resource)
            if (resource.promotedResource) {
              addLeverage(resource.promotedResource)
            }
          })
          return { ...row, GDS, nonGDS, allocation: GDS + nonGDS }
        })
      : rows

  const totalGDS = rows.reduce((result: number, row: any) => {
    return (result += row.GDS)
  }, 0)

  const totalNonGDS = rows.reduce((result: number, row: any) => {
    return (result += row.nonGDS)
  }, 0)

  const totalAllocation = rows.reduce((result: number, row: any) => {
    return (result += row.allocation)
  }, 0)

  rows = rows.map((row) => {
    if (!totalAllocation || totalAllocation === 0) return row
    return {
      ...row,
      GDS: +((row.GDS / totalAllocation) * 100).toFixed(2),
      nonGDS: +((row.nonGDS / totalAllocation) * 100).toFixed(2),
      allocation: +((row.allocation / totalAllocation) * 100).toFixed(1)
    }
  })
  // Roundoff correction in Hour break down
  let cumulativesum = 0.0
  let cumulativeRounded = 0
  let prevcumulativeValue = 0
  let newroundoffvalue = 0
  let GDSRoundOffValue = 0
  let NonGDSRoundOffValue = 0
  rows = rows.map((row) => {
    if (!totalAllocation || totalAllocation === 0) return row
    cumulativesum += row.allocation
    cumulativeRounded = +cumulativesum.toFixed(0)
    newroundoffvalue = cumulativeRounded - prevcumulativeValue
    prevcumulativeValue = cumulativeRounded

    if (row.GDS >= row.nonGDS) {
      GDSRoundOffValue = +row.GDS.toFixed(0)
      if (row.nonGDS === 0) {
        NonGDSRoundOffValue = 0
      } else NonGDSRoundOffValue = newroundoffvalue - GDSRoundOffValue < 0 ? 0 : newroundoffvalue - GDSRoundOffValue
    }
    if (row.nonGDS > row.GDS) {
      NonGDSRoundOffValue = +row.nonGDS.toFixed(0)
      if (row.GDS === 0) GDSRoundOffValue = 0
      else GDSRoundOffValue = newroundoffvalue - NonGDSRoundOffValue < 0 ? 0 : newroundoffvalue - NonGDSRoundOffValue
    }
    return {
      ...row,
      GDS: +GDSRoundOffValue,
      nonGDS: +NonGDSRoundOffValue,
      allocation: +newroundoffvalue
    }
  })

  rows = [...rows, getTotal(totalGDS, totalNonGDS, totalAllocation)]

  return (
    <>
      <header className={styles.header}>
        <div className={styles.title}>Hour Breakdown</div>
        <div>
          <div>
            <span className={`material-icons ${styles.nonGDSCircleIcon}`}>circle</span>
            <div>Non GDS</div>
          </div>
          <div>
            <span className={`material-icons ${styles.GDSCircleIcon}`}>circle</span>
            <div>GDS</div>
          </div>
        </div>
      </header>

      <div className={styles['leverage-breakdown-table']}>
        <div className={styles.thead}>
          <div className={styles.tr}>
            <div className={`${styles.th} ${styles.label}`}></div>
            <div className={`${styles.th} ${styles.breakdown}`}></div>
            <div className={`${styles.th} ${styles.allocation}`}>Allocation of hours</div>
          </div>
        </div>
        <div className={styles.tbody}>
          {rows.map((row) => {
            return (
              <div className={styles.tr} key={row.name}>
                <div className={`${styles.td} ${styles.label}`}>{row.label}</div>
                <div className={`${styles.td} ${styles.breakdown}`}>
                  <div className={styles.breakdownbar}>
                    <div
                      className={styles.nonGDSBar}
                      style={{
                        width: `${row.nonGDS}%`
                      }}
                    >
                      {row.name === 'total' && (
                        <span>Non-GDS: {((row.nonGDS / row.allocation) * 100).toFixed(0)}%</span>
                      )}
                    </div>
                    <div
                      className={styles.GDSBar}
                      style={{
                        width: `${row.GDS}%`
                      }}
                    >
                      {row.name === 'total' && <span>GDS: {((row.GDS / row.allocation) * 100).toFixed(0)}%</span>}
                    </div>
                  </div>
                </div>
                <div className={`${styles.td} ${styles.allocation}`}>{row.allocation.toFixed(0)}%</div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default LeverageBreakdown
