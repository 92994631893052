import React, { useEffect } from 'react'
import './PriceBreakdown.css'
import { numberWithCommas } from '../../resources/ResourceTableComponents'

const getTotal = (rows: any) => {
  return rows.reduce((result: number, row: any) => {
    return (result += row.cost)
  }, 0)
}

const addPercentage = (rows: any, total: number) => {
  return rows.map((row: any) => {
    let percentage = (row.cost / total) * 100
    if (isNaN(percentage)) percentage = 0
    return {
      ...row,
      percentage: +percentage.toFixed(1)
    }
  })
}

const PriceBreakdown = (props: any) => {
  const { pricingPlanData } = props

  useEffect(() => {
    let items: any = document.querySelector('.percentage-bar')?.children
    if (items) {
      items = Array.from(items)
      items.forEach((elmnt: any) => {
        const containerwidth = elmnt.offsetWidth
        const textWidth = elmnt.querySelector('span').offsetWidth
        if (textWidth >= containerwidth) elmnt.querySelector('span').style.display = 'none'
      })
    }
  }, [])

  let rows = [
    {
      label: 'Labor Fees',
      name: 'laborFees',
      cost: 0,
      className: 'none'
    },
    {
      label: 'CT Products',
      name: 'ctProducts',
      cost: 0,
      className: 'none'
    },
    {
      label: 'Total Fee for Services',
      name: 'servicesFees',
      cost: 0,
      className: 'servicesFees'
    },
    {
      label: 'Subcontractors/Vendors',
      name: 'subcontractors',
      cost: 0,
      className: 'subcontractors'
    },
    {
      label: 'Travel & Other Non-Technology Expenses',
      name: 'mobexpenses',
      cost: 0,
      className: 'mobility-expense'
    },
    {
      label: 'Technology Expenses',
      name: 'techCosts',
      cost: 0,
      className: 'tech-costs'
    }
  ]

  let priceBreakdown = pricingPlanData?.summaryViewModel?.priceBreakdown

  //const technologyExpenses = pricingPlanData?.expenses?.filter((expense: any) => expense.expenseType === 'Technology')
  const OthertechnologyTotExpenseBillings = pricingPlanData?.summaryViewModel?.detailedExpenses?.otherTechnology
  let techCosts = 0
  if (!isNaN(OthertechnologyTotExpenseBillings)) techCosts = OthertechnologyTotExpenseBillings

  if (techCosts && priceBreakdown) priceBreakdown.techCosts = techCosts

  const OtherExpensesTotExpenseBillings = pricingPlanData?.summaryViewModel?.detailedExpenses?.other
  const TravelRelatedTotExpenseBillings = pricingPlanData?.summaryViewModel?.detailedExpenses?.travelRelated
  let mobCosts = 0
  if (!isNaN(OtherExpensesTotExpenseBillings) && !isNaN(TravelRelatedTotExpenseBillings))
    mobCosts = OtherExpensesTotExpenseBillings + TravelRelatedTotExpenseBillings

  if (priceBreakdown) priceBreakdown.mobexpenses = mobCosts

  const total = pricingPlanData?.summaryViewModel?.detailedSummary?.totalBillings?.inclContingency //getTotal(rows)
  priceBreakdown.servicesFees = total - priceBreakdown.subcontractors - mobCosts - techCosts

  const technologyExpenses = pricingPlanData?.expenses?.filter((expense: any) => expense.expenseType === 'Technology')
  let revenue = 0
  if (technologyExpenses && technologyExpenses.length > 0) {
    revenue = technologyExpenses.reduce((total: number, expense: any) => {
      return (total += expense.revenue || 0)
    }, 0)
  }
  priceBreakdown.ctProducts = revenue

  priceBreakdown.laborFees = priceBreakdown.servicesFees - revenue

  rows = priceBreakdown
    ? rows.map((row) => {
        const cost = priceBreakdown[row.name]
        row = cost ? { ...row, cost } : row
        return row
      })
    : rows
  rows = addPercentage(rows, total)
  return (
    <div>
      <div className='title'>Price Breakdown</div>

      <div className='percentage-bar'>
        {rows
          .filter((row) => row.name !== 'laborFees' && row.name !== 'ctProducts')
          ?.map((row: any) => (
            <div key={row.name} className={row.className} style={{ width: `${row.percentage}%` }}>
              <span>{row.percentage}%</span>
            </div>
          ))}
      </div>

      <div className='list'>
        {rows?.map((row: any) => (
          <div className='list-row' key={row.name}>
            <div>
              <span className={`material-icons ${row.className}`}>circle</span>
              <span className='label'>{row.label}</span>
            </div>
            <div>
              <span className='cost'>
                <b>{numberWithCommas(row.cost)}</b> {pricingPlanData.planCurrency}
              </span>
            </div>
          </div>
        ))}
        <div className='list-row total-billing'>
          <span className='label'>TOTAL BILLING AMOUNT</span>
          <span className='cost'>
            {numberWithCommas(total)} {pricingPlanData.planCurrency}
          </span>
        </div>
      </div>
    </div>
  )
}

export default PriceBreakdown
