import React from 'react'
import './Toggle.css'

type Props = {
  control: 'named' | 'unnamed'
  toggleNamedUnnamed: () => void
}

const NamedUnnamedToggle = (props: Props) => {
  const { control, toggleNamedUnnamed } = props
  return (
    <div>
      <div className='toggle-container' onClick={toggleNamedUnnamed}>
        <div className='toggle-item muted'>Named</div>
        <div className='toggle-item muted'>Unnamed</div>
        <div className={`dialog-button ${control === 'named' ? 'disabled' : ''}`}>
          <div className='toggle-item'>{control === 'named' ? 'Named' : 'Unnamed'}</div>
        </div>
      </div>
    </div>
  )
}

export default NamedUnnamedToggle
