import React, { ReactElement, useEffect, useRef } from 'react'
import './MobilityExpenses.css'
import { ErrorMessage, Field, FieldArray, getIn } from 'formik'
import TextError from '../formik-controls/TextError'
import Tooltip from '../common/Tooltip'
import Select from '../formik-controls/select/Select'
import { updateInputStyling } from '../utils/updateStyling'

interface MobilityExpensesProps {
  display: boolean
  totalExpenses: number
  setTotalExpenses: Function
  mobilityExpenseResources: any
  mobilityExpenseWorkstreams: any
  mobilityExpenses: any
  setMobilityExpenses: Function
  setDeletionFlag: Function
  planCurrency: string
  showWorkstream: boolean
}

const MobilityExpenses = (props: MobilityExpensesProps): ReactElement => {
  const display = props.display

  const arrayHelpersRef = useRef<any>()

  const mobilityExpenseTypes = [
    // { value: 'subcontractor', label: 'Mobility - Subcontractor' },
    // { value: 'hotelCost', label: 'Mobility - Hotel Cost' },
    // { value: 'perDiem', label: 'Mobility - Per Diem' },
    // { value: 'flightCost', label: 'Mobility - Flight Cost' },
    // { value: 'workPermit', label: 'Mobility - Work Permit' },
    // { value: 'otherMobility', label: 'Mobility - Other' },
    // { value: 'otherLumpsum', label: 'Other Lumpsum' },
    // { value: 'consultants', label: 'Vendor / Subcontractor (FF) - Professional Consultants' },
    // { value: 'subcontractors', label: 'Vendor / Subcontractor (FF) - Third Party Subcontractors' },
    // { value: 'alliance', label: 'Vendor / Subcontractor (FF) - Alliance Partner Fees' }

    // { value: 'subcontractor', label: 'Mobility - Subcontractor' },
    { value: 'flightCost', label: 'Air/Ground Transportation' },
    { value: 'hotelCost', label: 'Hotel, Meals & Entertainment' },
    { value: 'perDiem', label: 'Other Travel Related' },
    { value: 'legalandknowledgecosts', label: 'Legal and knowledge costs' },
    { value: 'marketingandadvertising', label: 'Marketing & Advertising' },
    //{ value: 'workPermit', label: 'Mobility - Work Permit' },
    // { value: 'otherMobility', label: 'Mobility - Other' },
    { value: 'otherTechExpenses', label: 'Other Technology Expenses' },
    { value: 'otherLumpsum', label: 'Other Lumpsum' },
    { value: 'IFMs', label: 'IFMs' },
    { value: 'consultants', label: 'Vendor/Subcontractor - Professional Consultants' },
    { value: 'subcontractors', label: 'Vendor/Subcontractor - Third Party Subcontractors' },
    { value: 'alliance', label: 'Vendor/Subcontractor - Alliance Partner Fees' }
  ]

  useEffect(() => {
    updateInputStyling()
  }, [])

  useEffect(() => {
    sumCosts()
    updateInputStyling()
  }, [props.mobilityExpenses])

  const sumCosts = () => {
    let sum = parseFloat('0')
    for (let i = 0; i < props.mobilityExpenses.length; i++) {
      if (props.mobilityExpenses[i].cost !== undefined) {
        sum += Number(props.mobilityExpenses[i].cost)
      }
    }
    props.setTotalExpenses(sum.toFixed(2))
  }

  const renderError = (arrayName: string, fieldName: string, index: number, errors: any, touched: any) => {
    const error = getIn(errors, `${arrayName}[${index}].${fieldName}`)
    const touch = getIn(touched, `${arrayName}[${index}].${fieldName}`)
    return touch && error ? error : null
  }

  const restrictDecimals = (event: any) => {
    const input = event.target.value
    if (input.indexOf('.') >= 0) {
      event.target.value = input.substr(0, input.indexOf('.')) + input.substr(input.indexOf('.'), 3)
    }
  }

  return (
    <div>
      {display && (
        <div>
          <div
            className={
              props.showWorkstream ? 'mobility-expenses-header' : 'mobility-expenses-header mobility-no-workstream'
            }
          >
            <p className='body-text large'>Category</p>
            <p className='body-text medium'>Resource</p>
            {props.showWorkstream && <p className='body-text large'>Workstream</p>}
            <p className='body-text medium'>{'Cost (' + props.planCurrency + ')'}</p>
            <p className='body-text small'>Recoverable</p>
          </div>
          <FieldArray name='mobilityRows'>
            {(arrayHelpers) => {
              if (!arrayHelpersRef.current) {
                arrayHelpersRef.current = arrayHelpers
              }
              const { form, remove } = arrayHelpers
              return (
                <div>
                  <div className='add-mobmargin'>
                    <div></div>
                  </div>
                  {form?.values?.mobilityRows.map((row: any, index: any) => {
                    props.setMobilityExpenses(form.values.mobilityRows)
                    return (
                      <div
                        key={index}
                        className={
                          props.showWorkstream
                            ? 'mobility-expenses-row'
                            : 'mobility-expenses-row mobility-no-workstream'
                        }
                      >
                        <div className='large'>
                          <Select
                            name={`mobilityRows[${index}].category`}
                            options={mobilityExpenseTypes}
                            isDisabled={false}
                            fromExpenses={true}
                          />
                          <ErrorMessage name={`mobilityRows[${index}].category`} component={TextError} />
                        </div>

                        <div
                          className={
                            props.showWorkstream ? 'medium dropdown' : 'medium dropdown mobility-no-workstream-medium'
                          }
                        >
                          {row.category !== 'otherLumpsum' ? (
                            <Select
                              name={`mobilityRows[${index}].resource`}
                              options={props.mobilityExpenseResources}
                              isDisabled={false}
                              fromExpenses={true}
                            />
                          ) : (
                            <Select
                              name={`mobilityRows[${index}].resource`}
                              options={[{ value: 'n/a', label: 'N/A' }]}
                              isDisabled={true}
                              placeholder={'N/A'}
                              fromExpenses={true}
                            />
                          )}
                          <ErrorMessage name={`mobilityRows[${index}].resource`} component={TextError} />
                        </div>

                        {props.showWorkstream && (
                          <div className='large'>
                            {row.resource === 'unspecified' ||
                            row.category === 'otherLumpsum' ||
                            row.resource === '' ? (
                              <Select
                                name={`mobilityRows[${index}].workstream`}
                                options={props.mobilityExpenseWorkstreams}
                                isDisabled={false}
                                fromExpenses={true}
                              />
                            ) : (
                              <Select
                                name={`mobilityRows[${index}].workstream`}
                                options={props.mobilityExpenseWorkstreams}
                                isDisabled={true}
                                fromExpenses={true}
                              />
                            )}

                            <ErrorMessage name={`mobilityRows[${index}].workstream`} component={TextError} />
                          </div>
                        )}

                        <div
                          className={
                            props.showWorkstream
                              ? 'field-error-container'
                              : 'field-error-container mobility-no-workstream-medium'
                          }
                        >
                          <Field
                            name={`mobilityRows[${index}].cost`}
                            className={`medium input-field ${
                              renderError('mobilityRows', 'cost', index, form.errors, form.touched) ? 'error' : ''
                            }`}
                            type='number'
                            onInput={restrictDecimals}
                          />
                          <ErrorMessage name={`mobilityRows[${index}].cost`} component={TextError} />
                        </div>
                        <div
                          className={
                            props.showWorkstream ? 'select-box small' : 'select-box small mobility-no-workstream-small'
                          }
                        >
                          <Select
                            name={`mobilityRows[${index}].recoverable`}
                            options={[
                              { value: true, label: 'Yes' },
                              { value: false, label: 'No' }
                            ]}
                            isDisabled={false}
                            placeholder={''}
                            fromExpenses={true}
                          />
                          <ErrorMessage name={`mobilityRows[${index}].recoverable`} component={TextError} />
                        </div>

                        <button
                          type='button'
                          onClick={() => {
                            props.setDeletionFlag(true)
                            remove(index)
                            if (form.values.mobilityRows.length === 1) {
                              form.values.mobilityRows[0].category = ''
                              form.values.mobilityRows[0].resource = ''
                              form.values.mobilityRows[0].workstream = ''
                              form.values.mobilityRows[0].cost = undefined
                              form.values.mobilityRows[0].recoverable = undefined
                              form.values.mobilityRows[0].expenseType = 'Other'
                            }
                          }}
                        >
                          <Tooltip placement='top' content='Delete'>
                            <span className='material-icons delete-icon'>delete_outline</span>
                          </Tooltip>
                        </button>
                      </div>
                    )
                  })}

                  <button
                    className='add-mobility-expense-button'
                    type='button'
                    onClick={() => {
                      if (arrayHelpersRef?.current) {
                        arrayHelpersRef.current.push({
                          category: '',
                          resource: '',
                          workstream: '',
                          cost: undefined,
                          recoverable: undefined,
                          expenseType: 'Other'
                        })
                      }
                    }}
                  >
                    <span className='material-icons'>add_circle_outline</span>
                    <p className='add-mobexpenses-text'>Add new line</p>
                  </button>
                </div>
              )
            }}
          </FieldArray>
        </div>
      )}
    </div>
  )
}

export default MobilityExpenses
