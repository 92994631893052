import React from 'react'
import './Toggle.css'

type Props = {
  view: 'edit' | 'list'
  toggleView: () => void
}

const EditListToggle = (props: Props) => {
  const { view, toggleView } = props
  return (
    <div>
      <div className='toggle-container' onClick={toggleView}>
        <div className='toggle-item muted'>
          <span className='material-icons'>edit</span>
          Edit
        </div>
        <div className='toggle-item muted'>
          <span className='material-icons'>list_alt</span>
          List
        </div>
        <div className={`dialog-button ${view === 'edit' ? 'disabled' : ''}`}>
          <div className='toggle-item'>
            {view === 'edit' ? (
              <>
                <span className='material-icons'>edit</span>
                Edit
              </>
            ) : (
              <>
                <span className='material-icons'>list_alt</span>
                List
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditListToggle
