import React, { ReactElement, useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import './Landing.css'
import Header from '../header/Header'
import ImportModal from '../modals/ImportModal'
import LandingTable from './LandingTable'
import PrivacyModal from '../modals/PrivacyModal'
import { getAcceptPrivacyStatement, getAllPricingPlans, getBanner, setUserData } from '../utils/fetchHandler'
import Loading from '../loading/Loading'
import { UserContext } from '../../App'

const Landing = (): ReactElement => {
  const [privacyStatus, setPrivacyStatus] = useState<boolean>() // true === accepted
  const [loading, setLoading] = useState(true)
  const [isAccepted, setIsAccepted] = useState<boolean>() // Used to render the correct button on privacy statement popup
  const history = useHistory()
  const userId = useContext(UserContext)[1]
  const [rateNotification, setNotificationVisible] = useState<boolean>(false)
  const [pricingPlans, setPricingPlans] = useState<any>()
  const [mdata, setMdata] = useState<Message[]>([])
  const [msgcount, MSGcount] = useState<boolean>(false)
  const [confirmed, setConfirmed] =  useState<any>()
  const [isEnabled, setIsEnabled] = useState<boolean>(true) // New state for enabling/disabling components

  interface Message {
    message: string
    flag: boolean
  }

  useEffect(() => {
    ;(async () => {
      if (userId.length > 1 && (isAccepted === false || isAccepted === undefined)) {
        try {
          const _privacyStatus = await getAcceptPrivacyStatement(userId)
          if (_privacyStatus !== 'Promise') {
            setPrivacyStatus(_privacyStatus)

          }
        } catch (error) {
          console.error('Failed to check privacy statement status (Landing)')
        }
        try {
          let response = await setUserData({ id: userId });
          setIsEnabled(response.cctAccessStatus);
          sessionStorage.setItem('isEnabled', String(response.cctAccessStatus));
          const event = new CustomEvent('isEnabledUpdated');
          window.dispatchEvent(event);
          setLoading(false)
        } catch (error) {
          console.error('Failed to store login time')
        }
      }
    })()
  }, [userId])


  useEffect(() => {
    if (!loading) {
      if (privacyStatus === true) {
        hidePrivacyModal()
      } else {
        showPrivacyModalNotAccepted()
      }
    }
  }, [loading, privacyStatus])

  useEffect(() => {
    ;(async () => {
      try {
        if (userId.length > 1) {
          const fetchedPricingPlan = await getAllPricingPlans(userId)
          MSG()     
          const fetchedPricingPlans=fetchedPricingPlan.pricingPlans;       
          setConfirmed(fetchedPricingPlan.delegatedUsers)
          setPricingPlans(fetchedPricingPlans)
          if (fetchedPricingPlans.length > 0) {
            let lastLoginDate = fetchedPricingPlans[0].lastLogin
            const lastRateUpdateDate = fetchedPricingPlans[0].rateUpdatedDate
            if (lastLoginDate <= lastRateUpdateDate) {
              setNotificationVisible(true)
            }
          }
        }
      } catch (error) {
        console.error('Error fetching All Pricing Plans on Landing Page')
      }
    })()
  }, [userId])

  const MSG = async () => {
    try {
      const response = await getBanner()
      const dataAsArray = Object.values(response) as Message[]
      setMdata(dataAsArray)
    } catch (error) {
      console.error('Error fetching messages:', error)
    }
  }

  const containsHideKeyword = (message:any) => {
    return /\[hide\]/.test(message); // to check if `[hide]` keyword is present
  };
 
  const cleanMessage = (message:any) => {
    return message.replace(/\[hide\]/, ''); // to remove `[hide]` keyword for display
  };

  const convertLinks = (text:any) => {
    // below is the reg exp to match both URL links and custom [text](url) links
    const urlPattern = /\[(.*?)\]\((https?:\/\/[^\s]+)\)|(https?:\/\/[^\s]+)/g;
   
    return text.replace(urlPattern, (match :any, linkText :any, linkUrl:any, rawUrl:any) => {
      if (linkUrl) {
        // If it matches [text](url) format, use custom text
        return `<a href="${linkUrl}" target="_blank" rel="noopener noreferrer" class="link-style">${linkText}</a>`;
      } else {
        //or use raw URL as link text
        return `<a href="${rawUrl}" target="_blank" rel="noopener noreferrer" class="link-style">${rawUrl}</a>`;
      }
    });
  };  
  

  useEffect(() => {
    //const flaggedMessagesLength = mdata.filter((item) => item.flag).length;
    const flaggedMessagesLength = mdata.filter(item =>
      item.flag && (!containsHideKeyword(item.message) || (containsHideKeyword(item.message) && !isEnabled))
    );
    MSGcount(flaggedMessagesLength.length > 0);
  }, [mdata]);
  const createNewPricingPan = () => {
    history.push('/pricing-plan/new/global-input')
  }

  // Displays privacy modal when it hasn't been accepted yet
  const showPrivacyModalNotAccepted = () => {
    setIsAccepted(false)
    const privacyModal = document.getElementById('privacy-modal') as HTMLElement
    privacyModal.style.display = 'block'
  }

  // Don't automatically display privacy modal if it's already been accepted
  const hidePrivacyModal = () => {
    setIsAccepted(true)
    const privacyModal = document.getElementById('privacy-modal') as HTMLElement
    privacyModal.style.display = 'none'
  }

  if (loading) return <Loading />

  return (
    <div>
      <Header isFixedWidth={false}/>
      <div className={msgcount || rateNotification ? 'landing-body-container-large' : 'landing-body-container'}>
        {privacyStatus === true || isAccepted === true ? (
          <PrivacyModal isAccepted={true} />
        ) : (
          <PrivacyModal isAccepted={false} />
        )}
        <div className='landing-text-container'>
          <div
            className='landing-costRate-notification'
            style={{ display: msgcount || rateNotification ? 'flex' : 'none' }}
          >
            <span className='material-icons info-symbol'>info_outline</span>
              <ul className='notification-text body-text'>
                {rateNotification && (
                  <li>New rates have been implemented. All past and new plans will reflect new rates.</li>
                )}

                {/* {mdata.map((item, index) => item.flag && <li key={index}>{item.message}</li>)} */}

                
                {mdata.map((item, index) => {
                  // visibility based on `flag`, `[hide]` keyword, and `isEnabled`
                  const isVisible =
                    item.flag &&
                    (!containsHideKeyword(item.message) || (containsHideKeyword(item.message) && !isEnabled));
          
                  return isVisible ? (
                    <li key={index} dangerouslySetInnerHTML={{ __html: convertLinks(cleanMessage(item.message)) }}></li>
                            ) : null;
                  })}
              </ul>  
            <span
              className='material-icons close-notification'
              onClick={() => {
                setNotificationVisible(false)
                MSGcount(false)
              }}
            >
              close
            </span>
          </div>
          <h2 className='landing-title heading-two'>Consulting Costing Tool (CCT)</h2>
          <div className='landing-intro-container'>
            <p className='landing-intro body-text'>
              Welcome to the Consulting Costing Tool (CCT). From this page you can choose whether to create a new plan,
              modify an existing plan, or download additional instructions for support.
            </p>
            <div className='landing-download'></div>
          </div>
          <LandingTable 
          pricingPlans={pricingPlans} 
          confirmedUsers={confirmed} 
          IsCCTAccess={isEnabled}
          />
        </div>
      </div>
      <div className='landing-footer'>
        <button className='primary-button heading-three' onClick={createNewPricingPan}
        disabled={!isEnabled }
        >
          Create New
        </button>
      </div>

      <ImportModal />
    </div>
  )
}

export default Landing
