import React, { ReactElement} from 'react';
import './TechnologyCostModel.css'
interface TechnologyCostModelProps {
    technologyCost: number;
    onConfirm: () => void;
  }
  
  const TechnologyCostModel = (props: TechnologyCostModelProps): ReactElement => {

    const hideModal = () => {
        const deleteModal = document.getElementById('technology-cost-modal') as HTMLElement
        deleteModal.style.display = 'none'
    };
  
    const confirmAction = async () => {
      props.onConfirm();
      hideModal();
    };
  
    return (
       <div>
      <div id='technology-cost-modal'>
        <div className='technology-cost-modal-content'>
        <p className='heading-three'>Please confirm there is no Technology Cost %</p>
          <div className='technology-cost-modal-buttons'>
            <button className='primary-button heading-three' onClick={confirmAction}>
              OK 
            </button>
          </div>
        </div>
      </div>
    </div>
    );
  };
  
  export default TechnologyCostModel;