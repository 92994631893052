import * as yup from 'yup'
import _ from 'lodash'
import moment from 'moment'

const validationRegEx = /^[\p{Letter}\p{Decimal_Number}!()'"./:&\s-]+$/gu
const validationText = `Letters, numbers and the following special characters [! () ' " - . / : &] are allowed.`
const requiredValidationText = 'Please complete required field.'
const validationMsg = `Opportunity ID must be 7 digits.`
const minLengthValidationText = 'Minimum 1 character.'
const maxLengthValidationText = 'Maximum 30 characters.'
const validateRegExNumbers = /^[0-9]{7}$/gu

const validatePhaseOrWorkstreamStartDate = (value: Date | undefined | null, context: any, name: string) => {
  const index = context.from.length - 1
  const dealStartDate = _.get(context, `from[${index}].value.dealStartDate`, null)
  const dealEndDate = _.get(context, `from[${index}].value.dealEndDate`, null)
  if (dealStartDate && dealEndDate && value) {
    const isSameOrAfterDealStartDate = moment(value).isSameOrAfter(dealStartDate)
    const isSameOrBeforeDealEndDate = moment(value).isSameOrBefore(dealEndDate)
    if (!(isSameOrAfterDealStartDate && isSameOrBeforeDealEndDate)) {
      return context.createError({ message: `${name} Start Date must be between Deal Start Date and Deal End Date.` })
    }

    // Validating Phase Start Date is after Previous Phase End Date
    if (name === 'Phase' && context.options.index > 0) {
      const pricingPlanData = context.from[context.from.length - 1].value
      const prevPhaseEndDate =
        pricingPlanData.phases[context.options.index - 1].endDate ||
        pricingPlanData.phases[context.options.index - 1].startDate
      if (prevPhaseEndDate) {
        const isAfterPrevPhaseEndDate = moment(value).isAfter(prevPhaseEndDate)
        if (isSameOrAfterDealStartDate && isSameOrBeforeDealEndDate && !isAfterPrevPhaseEndDate) {
          return context.createError({
            message: `${name} Start Date must be between Deal Start Date and Deal End Date and after the previous Phase End Date.`
          })
        }
      }
    }
  }
  return true
}

const validatePhaseOrWorkstreamEndDate = (value: Date | undefined | null, context: any, name: string) => {
  const index = context.from.length - 1
  const dealStartDate = _.get(context, `from[${index}].value.dealStartDate`, null)
  const dealEndDate = _.get(context, `from[${index}].value.dealEndDate`, null)
  const startDate = _.get(context, `parent.startDate`)
  if (startDate && dealEndDate && value) {
    if (!(moment(value).isSameOrAfter(startDate) && moment(value).isSameOrBefore(dealEndDate)))
      return context.createError({
        message: `${name} End Date must be between ${name} Start Date and Deal End Date.`
      })
  }
  if (dealStartDate && dealEndDate && value) {
    if (!(moment(value).isSameOrAfter(dealStartDate) && moment(value).isSameOrBefore(dealEndDate)))
      return context.createError({ message: `${name} End Date must be between Deal Start Date and Deal End Date.` })
  }
  if (startDate && value) {
    if (!moment(value).isSameOrAfter(startDate))
      return context.createError({ message: `${name} End Date must be after ${name} Start Date.` })
  }
  return true
}

export const validationSchema = yup.object({
  engagementName: yup
    .string()
    .matches(validationRegEx, validationText)
    .min(1, minLengthValidationText)
    .max(50, maxLengthValidationText)
    .required(requiredValidationText),
  client: yup
    .string()
    .matches(validationRegEx, validationText)
    .min(1, minLengthValidationText)
    .max(50, maxLengthValidationText)
    .required(requiredValidationText),
  planCurrency: yup.string().required(requiredValidationText),
  region: yup.string().required(requiredValidationText),
  country: yup.string().required(requiredValidationText),
  sectorId: yup.number().required(requiredValidationText),
  dealStartDate: yup
    .date()
    .min(moment().subtract(1, 'days'), 'Deal Start Date must be today or a later date.')
    .required(requiredValidationText)
    .nullable(),
  dealEndDate: yup
    .date()
    .when('dealStartDate', (dealStartDate, schema) => {
      return dealStartDate
        ? schema.min(moment(dealStartDate).add(1, 'days'), 'Deal End Date must be later than Deal Start Date.')
        : schema.min(moment().subtract(1, 'days'), 'Deal End Date must must be today or a later date.')
    })
    .required(requiredValidationText)
    .nullable(),
  opportunityId: yup.string().matches(validateRegExNumbers, validationMsg),
  phases: yup.array().of(
    yup
      .object()
      .shape({
        title: yup
          .string()
          .matches(validationRegEx, validationText)
          .min(1, minLengthValidationText)
          .max(30, maxLengthValidationText)
          .required(requiredValidationText),
        startDate: yup
          .date()
          .test('validatePhaseStartDate', (value, context) =>
            validatePhaseOrWorkstreamStartDate(value, context, 'Phase')
          )
          .required(requiredValidationText)
          .nullable(),
        endDate: yup
          .date()
          .test('validatePhaseEndDate', (value, context) => validatePhaseOrWorkstreamEndDate(value, context, 'Phase'))
          .required(requiredValidationText)
          .nullable()
      })
      .test('unique', 'Phase titles must be unique.', function validateUnique(currentPhase) {
        const otherPhases = this.parent.filter((phase: any) => phase !== currentPhase)
        let isDuplicateChk1 = false
        let isDuplicatePhase = false
        if (currentPhase !== undefined && currentPhase.title !== undefined) {
          let title = currentPhase.title
          // Checks for duplicates against the current workstream
          isDuplicateChk1 = otherPhases.some(
            (phase: any) => phase.title !== undefined && phase.title.toUpperCase() === title.toUpperCase()
          )
        }
        if (isDuplicateChk1 === true) {
          isDuplicatePhase = otherPhases.some(
            (phases: any) => phases.title.toUpperCase() === currentPhase.title?.toUpperCase()
          )
        }

        return isDuplicatePhase ? this.createError({ path: `${this.path}.title` }) : true
      })
  ),
  workstreams: yup.array().of(
    yup
      .object()
      .shape({
        title: yup
          .string()
          .matches(validationRegEx, validationText)
          .min(1, minLengthValidationText)
          .max(30, maxLengthValidationText)
          .required(requiredValidationText),
        //startDate: yup
          //.date()
          //.test('validateWorkstreamStartDate', (value, context) =>
            //validatePhaseOrWorkstreamStartDate(value, context, 'Workstream')
          //)
          //.required(requiredValidationText)
          //.nullable(),
        //endDate: yup
          //.date()
         // .test('validateWorkstreamEndDate', (value, context) =>
           // validatePhaseOrWorkstreamEndDate(value, context, 'Workstream')
          //)
          //.required(requiredValidationText)
          //.nullable()
      })
      .test('unique', 'Workstream titles must be unique.', function validateUnique(currentWorkstream, value) {
        // this.parent contains a list of all the inputted worksteams
        // Get all workstreams except the current one

        const otherWorkstreams = this.parent.filter((workstream: any) => workstream !== currentWorkstream)
        let isDuplicateChkWS = false
        if (currentWorkstream !== undefined && currentWorkstream.title !== undefined) {
          let title = currentWorkstream.title
          // Checks for duplicates against the current workstream
          isDuplicateChkWS = otherWorkstreams.some(
            (workstream: any) =>
              workstream.title !== undefined && workstream.title.toUpperCase() === title.toUpperCase()
          )
        }
        let isDuplicate = false
        if (isDuplicateChkWS === true) {
          isDuplicate = otherWorkstreams.some(
            (workstream: any) => workstream.title.toUpperCase() === currentWorkstream.title?.toUpperCase()
          )
        }
        return isDuplicate ? this.createError({ path: `${this.path}.title` }) : true
      })
  )
})
