import { useParams, useHistory, useLocation, Link } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import './NavMenu.css'
import { RouteParamTypes } from '../global-inputs/GlobalInputTypes'
import { Formik } from 'formik'

// Takes in prop that tells which tab is currently open (0 - global inputs up to 4 - summary)
const NavMenu = (props: Props) => {
  const { selectedTab, highestAccessedPage, worksheetData } = props
  const [tabCompleted, setTabCompleted] = useState([false, false, false, false, false])
  const location = useLocation()
  const { pricingPlanId } = useParams<RouteParamTypes>()
  const history = useHistory()
  const pageNames = ['Global Inputs', 'Resources', 'Expenses', 'Scenarios', 'Summary']
  const { onNavSaveClick } = props

  useEffect(() => {
    const tabs = Array.from(document.getElementsByClassName('progress-tab') as HTMLCollectionOf<HTMLElement>)

    let newTabCompleted = [...tabCompleted]
    for (let i = 0; i < 5; i++) {
      if (selectedTab === i) {
        newTabCompleted[i] = false
        tabs[i].className += ' current-tab'
      } else if (i < selectedTab) {
        newTabCompleted[i] = true
        tabs[i].className += ' completed-tab'
      } else {
        newTabCompleted[i] = false
      }
    }

    for (let i = 0; i < 5; i++) {
      if (pageNames[i] !== highestAccessedPage) {
        tabs[i].className += ' progress-tab-clickable'
      } else {
        tabs[i].className += ' progress-tab-clickable'
        break // Further pages are not to be accessed through nav bar
      }
    }

    setTabCompleted(newTabCompleted)
  }, [])

  //TODO progress-tab onClick functions untested for new plans due to Expenses.tsx not accounting for new plans yet
  return (
    <div className='menu-container'>
      <div className='menu-title-container'>
        <Link to='/'>
          <span className='material-icons menu-list-icon'>format_list_bulleted</span>
        </Link>
        <Link to='/'>
          <p className='menu-title'>View all CCTs</p>
        </Link>
      </div>
      <div>
        <div
          className='progress-tab'
          onClick={() => (selectedTab !== 0 && tabCompleted[0] === true ? onNavSaveClick(0) : location)}
        >
          {selectedTab === 0 && tabCompleted[0] === false && (
            <span className='material-icons progress-icon'>circle</span>
          )}
          {selectedTab !== 0 && tabCompleted[0] === true && (
            <span className='material-icons progress-check-circle'> check_circle</span>
          )}
          {selectedTab !== 0 && tabCompleted[0] === false && (
            <span className='material-icons-outlined progress-icon'>circle</span>
          )}
          <span className='progress-text'>Global Inputs</span>
        </div>
        <div
          className='progress-tab'
          onClick={() => (selectedTab !== 1 && highestAccessedPage !== 'Global Inputs' ? onNavSaveClick(1) : location)}
        >
          {selectedTab === 1 && tabCompleted[1] === false && (
            <span className='material-icons progress-icon'>circle</span>
          )}
          {selectedTab !== 1 && tabCompleted[1] === true && (
            <span className='material-icons progress-check-circle'>check_circle</span>
          )}
          {selectedTab !== 1 && tabCompleted[1] === false && (
            <span className='material-icons-outlined progress-icon'>circle</span>
          )}
          <span className='progress-text'>Resources</span>
        </div>
        <div
          className='progress-tab'
          onClick={() =>
            selectedTab !== 2 && highestAccessedPage !== 'Global Inputs' && highestAccessedPage !== 'Resources'
              ? onNavSaveClick(2)
              : location
          }
        >
          {selectedTab === 2 && tabCompleted[2] === false && (
            <span className='material-icons progress-icon'>circle</span>
          )}
          {selectedTab !== 2 && tabCompleted[2] === true && (
            <span className='material-icons progress-check-circle'>check_circle</span>
          )}
          {selectedTab !== 2 && tabCompleted[2] === false && (
            <span className='material-icons-outlined progress-icon'>circle</span>
          )}
          <span className='progress-text'>Expenses</span>
        </div>
        <div
          className='progress-tab'
          onClick={() =>
            selectedTab !== 3 &&
            highestAccessedPage !== 'Global Inputs' &&
            highestAccessedPage !== 'Resources' &&
            highestAccessedPage !== 'Expenses'
              ? onNavSaveClick(3)
              : location
          }
        >
          {selectedTab === 3 && tabCompleted[3] === false && (
            <span className='material-icons progress-icon'>circle</span>
          )}
          {selectedTab !== 3 && tabCompleted[3] === true && (
            <span className='material-icons progress-check-circle'>check_circle</span>
          )}
          {selectedTab !== 3 && tabCompleted[3] === false && (
            <span className='material-icons-outlined progress-icon'>circle</span>
          )}
          <span className='progress-text'>Scenarios</span>
        </div>
        <div
          className='progress-tab'
          onClick={() =>
            selectedTab !== 4 &&
            highestAccessedPage !== 'Global Inputs' &&
            highestAccessedPage !== 'Resources' &&
            highestAccessedPage !== 'Expenses' &&
            highestAccessedPage !== 'Scenarios'
              ? onNavSaveClick(4)
              : location
          }
        >
          <span className='material-icons-outlined'>request_quote</span>
          <span className='progress-text'>Summary</span>
        </div>
      </div>
    </div>
  )
}

interface Props {
  selectedTab: number
  highestAccessedPage: string
  onNavSaveClick: (path: number) => void
  worksheetData: string
}

export default NavMenu
