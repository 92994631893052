import React from 'react'
import ReactDOM from 'react-dom'

import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { msalConfigLocal, msalConfigProd, msalConfigUAT } from './authConfig'

import './index.css'
import App from './App'
import AppNoSSO from './AppNoSSO'

let msalInstance = new PublicClientApplication(msalConfigUAT) // Default config is for UAT
if (window.location.href.includes('cct.ey.com')) {
  msalInstance = new PublicClientApplication(msalConfigProd)
} else if (window.location.href.includes('localhost:3000')) {
  // Used for development purposes
  msalInstance = new PublicClientApplication(msalConfigLocal)
}

if (window.location.href.includes('cct-qa.ey.com') || window.location.href.includes('cct.ey.com')) {
  ReactDOM.render(
    <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    </React.StrictMode>,
    document.getElementById('root')
  )
} else if (window.location.href.includes('localhost:3000')) {
  // This is for testing purposes
  ReactDOM.render(
    <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    </React.StrictMode>,
    document.getElementById('root')
  )
} else {
  // Do not request SSO login
  ReactDOM.render(
    <React.StrictMode>
      <AppNoSSO />
    </React.StrictMode>,
    document.getElementById('root')
  )
}
