import { useEffect, useReducer } from 'react'
import Header from '../header/Header'
import './AdminDashboard.css'
import Footer from './Footer'
import ManageAdministrators from './ManageAdministrators'
import UpdateRates from './UpdateRates'
import { uploadRateFile, updateRates, getFileHistory, uploadMasterFile, updateMasterData } from '../utils/fetchHandler'
import Loading from '../loading/Loading'
import LoadingChanges from './loading/LoadingChanges'
import { Formik, Form } from 'formik'

const initialState: any = {
  isLoading: true,
  rates: [],
  uploadedCostRateFile: null,
  uploadedCostRateId: null,
  uploadedExchangeRateFile: null,
  uploadedExchangeRateId: null,
  uploadedInflationRateFile: null,
  uploadedInflationRateId: null,
  selectedRatesFile: null,
  isRatesModalOpen: false,
  isRatesFileUploading: false,
  isLoadingChanges: false,
  selectedRateType: null
}

const reducer = (state: any, payload: any) => {
  return { ...state, ...payload }
}

const AdminDashboard = () => {
  const [state, setState] = useReducer(reducer, initialState)

  const {
    isLoading,
    rates,
    uploadedCostRateFile,
    uploadedCostRateId,
    uploadedExchangeRateFile,
    uploadedExchangeRateId,
    uploadedInflationRateFile,
    uploadedInflationRateId,
    uploadedMasterCostRateFile,
    uploadedMasterCostRateId,
    uploadedMasterExchangeRateFile,
    uploadedMasterExchangeRateId,
    uploadedMasterMarketRateFile,
    uploadedMasterMarketRateId,
    uploadedMasterSectorDiscountFile,
    uploadedMasterSectorDiscountId,
    uploadedMasterOfferingCompetencyCostMappingFile,
    uploadedMasterOfferingCompetencyCostMappingId,
    selectedRatesFile,
    isRatesModalOpen,
    isRatesFileUploading,
    isLoadingChanges,
    selectedRateType
  } = state

  useEffect(() => {
    ;(async () => {
      try {
        const rates = await getFileHistory()
        setState({ isLoading: false, rates })
      } catch (error) {}
      setState({ isLoading: false })
    })()
  }, [])

  const uploadRatesFile = async () => {
    if (selectedRatesFile) {
      const data = new FormData()
      data.append('file', selectedRatesFile)
      setState({ isRatesFileUploading: true })
      if (selectedRateType === 'CostRates') {
        try {
          const { id } = await uploadRateFile(data, 'CostRates')
          setState({
            isRatesFileUploading: false,
            isRatesModalOpen: false,
            selectedRatesFile: null,
            uploadedCostRateFile: selectedRatesFile,
            uploadedCostRateId: id
          })
        } catch (error) {
          setState({ isRatesFileUploading: false, selectedRatesFile: null })
        }
      }
      if (selectedRateType === 'ExchangeRates') {
        try {
          const { id } = await uploadRateFile(data, 'ExchangeRates')
          setState({
            isRatesFileUploading: false,
            isRatesModalOpen: false,
            selectedRatesFile: null,
            uploadedExchangeRateFile: selectedRatesFile,
            uploadedExchangeRateId: id
          })
        } catch (error) {
          setState({ isRatesFileUploading: false, selectedRatesFile: null })
        }
      }
      if (selectedRateType === 'InflationRates') {
        try {
          const { id } = await uploadRateFile(data, 'InflationRates')
          setState({
            isRatesFileUploading: false,
            isRatesModalOpen: false,
            selectedRatesFile: null,
            uploadedInflationRateFile: selectedRatesFile,
            uploadedInflationRateId: id
          })
        } catch (error) {
          setState({ isRatesFileUploading: false, selectedRatesFile: null })
        }
      }

      if (selectedRateType === 'mastercostrate') {
        try {
          const { id } = await uploadMasterFile(data, 'mastercostrate')
          setState({
            isRatesFileUploading: false,
            isRatesModalOpen: false,
            selectedRatesFile: null,
            uploadedMasterCostRateFile: selectedRatesFile,
            uploadedMasterCostRateId: id
          })
        } catch (error) {
          setState({ isRatesFileUploading: false, selectedRatesFile: null })
        }
      }

      if (selectedRateType === 'masterexchangerate') {
        try {
          const { id } = await uploadMasterFile(data, 'masterexchangerate')
          setState({
            isRatesFileUploading: false,
            isRatesModalOpen: false,
            selectedRatesFile: null,
            uploadedMasterExchangeRateFile: selectedRatesFile,
            uploadedMasterExchangeRateId: id
          })
        } catch (error) {
          setState({ isRatesFileUploading: false, selectedRatesFile: null })
        }
      }

      if (selectedRateType === 'mastermarketrate') {
        try {
          const { id } = await uploadMasterFile(data, 'mastermarketrate')
          setState({
            isRatesFileUploading: false,
            isRatesModalOpen: false,
            selectedRatesFile: null,
            uploadedMasterMarketRateFile: selectedRatesFile,
            uploadedMasterMarketRateId: id
          })
        } catch (error) {
          setState({ isRatesFileUploading: false, selectedRatesFile: null })
        }
      }

      if (selectedRateType === 'mastersectordiscount') {
        try {
          const { id } = await uploadMasterFile(data, 'mastersectordiscount')
          setState({
            isRatesFileUploading: false,
            isRatesModalOpen: false,
            selectedRatesFile: null,
            uploadedMasterSectorDiscountFile: selectedRatesFile,
            uploadedMasterSectorDiscountId: id
          })
        } catch (error) {
          setState({ isRatesFileUploading: false, selectedRatesFile: null })
        }
      }

      if (selectedRateType === 'masterofferingmap') {
        try {
          const { id } = await uploadMasterFile(data, 'masterofferingmap')
          setState({
            isRatesFileUploading: false,
            isRatesModalOpen: false,
            selectedRatesFile: null,
            uploadedMasterOfferingCompetencyCostMappingFile: selectedRatesFile,
            uploadedMasterOfferingCompetencyCostMappingId: id
          })
        } catch (error) {
          setState({ isRatesFileUploading: false, selectedRatesFile: null })
        }
      }

    }
  }

  const onApplyNewRates = async () => {
    setState({ isLoadingChanges: true })
    const data = { costRateId: null, exchangeRateId: null, inflationRateId: null }
    const formData = new FormData()
    let isMasterFile = false;
    let masterData = { masterCostRateFileId: null,masterExchangeRateFileId:null,masterMarketRateFileId:null,masterSectorDiscountFileId:null,masterOfferingCompetencyCostMappingFileId:null}
    if (uploadedCostRateId && uploadedCostRateFile) {
      data.costRateId = uploadedCostRateId
      formData.append('costRateFile', uploadedCostRateFile)
    }
    if (uploadedExchangeRateFile && uploadedExchangeRateId) {
      data.exchangeRateId = uploadedExchangeRateId
      formData.append('exchangeRateFile', uploadedExchangeRateFile)
    }
    if (uploadedInflationRateFile && uploadedInflationRateId) {
      data.inflationRateId = uploadedInflationRateId
      formData.append('inflationRateFile', uploadedInflationRateFile)
    }
    if(uploadedMasterCostRateFile && uploadedMasterCostRateId){
      masterData.masterCostRateFileId = uploadedMasterCostRateId;
      isMasterFile = true;
    }
    if(uploadedMasterExchangeRateFile && uploadedMasterExchangeRateId){
      masterData.masterExchangeRateFileId = uploadedMasterExchangeRateId;
      isMasterFile = true;
    }
    if(uploadedMasterMarketRateFile && uploadedMasterMarketRateId){
      masterData.masterMarketRateFileId = uploadedMasterMarketRateId;
      isMasterFile = true;
    }
    if(uploadedMasterSectorDiscountFile && uploadedMasterSectorDiscountId){
      masterData.masterSectorDiscountFileId = uploadedMasterSectorDiscountId;
      isMasterFile = true;
    }
    if(uploadedMasterOfferingCompetencyCostMappingFile && uploadedMasterOfferingCompetencyCostMappingId){
      masterData.masterOfferingCompetencyCostMappingFileId = uploadedMasterOfferingCompetencyCostMappingId;
      isMasterFile = true;
    }

    try {
      if(!isMasterFile){
        await updateRates(data.costRateId, data.exchangeRateId, data.inflationRateId, formData)
      }
      else{
        await updateMasterData(masterData.masterCostRateFileId,
          masterData.masterExchangeRateFileId,
          masterData.masterMarketRateFileId,
          masterData.masterSectorDiscountFileId,
          masterData.masterOfferingCompetencyCostMappingFileId);
      }
      
      const rates = await getFileHistory();
      setState({
        isLoadingChanges: false,
        rates,
        uploadedCostRateFile: null,
        uploadedCostRateId: null,
        uploadedExchangeRateFile: null,
        uploadedExchangeRateId: null,
        uploadedInflationRateFile: null,
        uploadedInflationRateId: null,
        uploadedMasterCostRateFile:null,
        uploadedMasterCostRateId:null,
        uploadedMasterExchangeRateFile:null,
        uploadedMasterExchangeRateId:null,
        uploadedMasterMarketRateFile:null,
        uploadedMasterMarketRateId:null,
        uploadedMasterSectorDiscountFile:null,
        uploadedMasterSectorDiscountId: null,
        uploadedMasterOfferingCompetencyCostMappingFile:null,
        uploadedMasterOfferingCompetencyCostMappingId: null
      })
    } catch (error) {
      setState({ isLoadingChanges: false })
    }
  }

  if (isLoading) return <Loading />

  return (
    <div className='admin-dashboard-wrapper'>
      <Header isFixedWidth={false}/>
      <Formik
        initialValues={initialState}
        onSubmit={(values, actions) => {}}
      >
        {(formik) => {
          return (
            <Form>
              {isLoadingChanges ? (
                <LoadingChanges />
              ) : (
                <div className='admin-dashboard-container'>
                  <h2 className='heading-two admin-dashboard-heading'>Administrator Dashboard</h2>
                  <p
                    className='body-text'
                    style={{
                      whiteSpace: 'pre-line',
                      margin: 0
                    }}
                  >
                    {'Welcome to the Administrator Dashboard. \nSwitch to user in the header to access CCT functionalities.'}
                  </p>
                  <ManageAdministrators />
                  <UpdateRates
                    rates={rates}
                    uploadedCostRateFile={uploadedCostRateFile}
                    uploadedExchangeRateFile={uploadedExchangeRateFile}
                    uploadedInflationRateFile={uploadedInflationRateFile}
                    isRatesModalOpen={isRatesModalOpen}
                    setIsRatesModalOpen={(value: boolean) => setState({ isRatesModalOpen: value })}
                    isRatesFileUploading={isRatesFileUploading}
                    selectedRatesFile={selectedRatesFile}
                    setSelectedRatesFile={(file: any) => setState({ selectedRatesFile: file })}
                    onCancelRatesModal={() => {
                      setState({ selectedRatesFile: null, isRatesModalOpen: false })
                    }}
                    onConfirmRatesModal={uploadRatesFile}
                    setSelectedRateType={(rateType: any) => {
                      setState({ selectedRateType: rateType })
                    }}
                    selectedRateType={selectedRateType}
                  />
                </div>
              )}
              <Footer
                hasAsyncedChanges={uploadedCostRateFile || uploadedExchangeRateFile}
                onApplyNewRates={onApplyNewRates}
                isLoadingChanges={isLoadingChanges}
                rates={rates}
              />
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default AdminDashboard
