import React, { ReactElement } from 'react'
import { BrowserRouter } from 'react-router-dom'
import ScrollToTop from './components/ScrollToTop'
import Router from './Router'

// Component to be used for non-SSO sites to resolve browser error with useMsal()
// useMsal() can't be used outside of a MsalProvider
function AppNoSSO(): ReactElement {
  return (
    <div>
      <BrowserRouter>
        <ScrollToTop />
        <Router />
      </BrowserRouter>
    </div>
  )
}

export default AppNoSSO
