import React, { useState, useRef } from 'react'
import { FieldArray, Field, getIn, ErrorMessage, useFormikContext } from 'formik'
import DatePicker from '../formik-controls/date-picker/DatePicker'
import TextError from '../formik-controls/TextError'
import Tooltip from '../common/Tooltip'
import './Workstreams.css'
import { values } from 'lodash'

const Workstreams = () => {
  const arrayHelpersRef = useRef<any>()
  const { values }: any = useFormikContext()
  console.log(values)
  let maxIndex = 0

  const [showAccordion, setShowAccordion] = useState(true)

  // Limiting the no. of phases to 6
  const isAddNewWorkstreamDisabled = () => {
    return values?.workstreams?.length >= 6 ? true : false
  }

  const toggleAccordion = () => {
    setShowAccordion((prevState) => (prevState === true ? false : true))
  }

  const renderError = (arrayName: string, fieldName: string, index: number, errors: any, touched: any) => {
    const error = getIn(errors, `${arrayName}[${index}].${fieldName}`)
    const touch = getIn(touched, `${arrayName}[${index}].${fieldName}`)
    return touch && error ? error : null
  }

  return (
    <div style={{ marginTop: '30px' }} className='workstreams'>
      <button type='button' className='accordion' onClick={toggleAccordion}>
        <p className='form-heading heading-three' style={{ margin: 0 }}>
          Workstreams
          <span className='optional-text'>(Optional)</span>
          <span className='optional-comment'>
            Note: Please add up to 6 Workstreams and Workstreams breakdown on the Summary page is available.
          </span>
        </p>
        {showAccordion ? (
          <span className='material-icons'>expand_less</span>
        ) : (
          <span className='material-icons'>expand_more</span>
        )}
      </button>
      {showAccordion && (
        <div className='panel'>
          <div className='form-header'>
            <div className='row item-row'>
              <div className='col'>
                <p className='workstream-header-text'>Workstream Title</p>
              </div>
              <div className='col'>
                <div className='row'>
                  <div className='col'>{/* <p className='workstream-header-text'>Workstream Start Date</p> */}</div>
                  <div className='col'>{/* <p className='workstream-header-text'>Workstream End Date</p> */}</div>
                </div>
              </div>
              <div className='col'></div>
            </div>
          </div>
          <div style={{ marginTop: '10px' }}>
            <FieldArray name='workstreams'>
              {(arrayHelpers) => {
                if (!arrayHelpersRef.current) {
                  arrayHelpersRef.current = arrayHelpers
                }
                const { form, remove } = arrayHelpers
                const { setFieldValue } = form
                return (
                  <div>
                    {form?.values?.workstreams?.map((phase: any, index: number) => {
                      if (index > maxIndex) maxIndex = index
                      return (
                        <React.Fragment key={index}>
                          <div className='row item-row'>
                            <div className='col'>
                              <Field
                                name={`workstreams[${index}].title`}
                                maxLength={30}
                                className={`input-field ${
                                  renderError('workstreams', 'title', index, form.errors, form.touched) ? 'error' : ''
                                }`}
                              />
                              <ErrorMessage name={`workstreams[${index}].title`} component={TextError} />
                            </div>
                            <div className='col'>
                              <div className='row'>
                                <div className='col'>
                                  {/* <DatePicker
                                    name={`workstreams[${index}].startDate`}
                                    isError={renderError('workstreams', 'startDate', index, form.errors, form.touched)}
                                    onChange={(date: Date) => {
                                      if (form.values.workstreams[index].endDate < date)
                                        setFieldValue(`workstreams[${index}].endDate`, null)
                                    }}
                                  />
                                  <ErrorMessage name={`workstreams[${index}].startDate`} component={TextError} /> */}
                                </div>
                                <div className='col'>
                                  {/* <DatePicker
                                    name={`workstreams[${index}].endDate`}
                                    isError={renderError('workstreams', 'endDate', index, form.errors, form.touched)}
                                  />
                                  <ErrorMessage name={`workstreams[${index}].endDate`} component={TextError} /> */}
                                </div>
                              </div>
                            </div>
                            <div className='col col-delete'>
                              <Tooltip content='Delete' placement='top'>
                                <span className='material-icons delete-icon' onClick={() => remove(index)}>
                                  delete_outline
                                </span>
                              </Tooltip>
                            </div>
                          </div>
                          <hr style={{ border: '1px solid #E7E7EA' }} />
                        </React.Fragment>
                      )
                    })}
                  </div>
                )
              }}
            </FieldArray>
          </div>
          <div className='row'>
            <div
              className={isAddNewWorkstreamDisabled() ? 'col add-phase-col-disabled' : 'col add-phase-col'}
              //className='col add-phase-col'
              onClick={() => {
                if (arrayHelpersRef?.current) {
                  //let workstreamcount = arrayHelpersRef.current.unshift
                  //const boxes = document.getElementsByClassName('row item-row')
                  //if (maxIndex < 5) {
                  if (!isAddNewWorkstreamDisabled()) {
                    //Restricting the workstream to maximum 6
                    arrayHelpersRef.current.push({
                      title: '',
                      startDate: '01/01/7777', //null,
                      endDate: '01/12/7777' //null
                    })
                  }
                }
              }}
            >
              <span
                className={
                  isAddNewWorkstreamDisabled()
                    ? 'material-icons add-phase-icon-disabled'
                    : 'material-icons add-phase-icon'
                }
                //className='material-icons add-phase-icon'
              >
                add_circle_outline
              </span>
              <div className='add-phase-text'>Add new line</div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Workstreams
