import React from 'react'
import ShowHideChecklist from './ShowHideChecklist'
import EditListToggle from './toggles/EditListToggle'
import InputUnitToggle from './toggles/InputUnitToggle'
import './TopButtons.css'

type Props = {
  view: 'edit' | 'list'
  toggleView: () => void
  toggleAddResourceModal: (control: boolean) => void
  viewedColumns: any
  handleChangeViewedColums: any
}

const TopButtons = (props: Props) => {
  return (
    <div className='top-buttons-container'>
      <div className='left-section'>
        <EditListToggle view={props.view} toggleView={props.toggleView} />
        <InputUnitToggle />
      </div>
      {props.view === 'edit' ? (
        <div>
          <button className='primary-button' type='button' onClick={() => props.toggleAddResourceModal(true)}>
            <span className='heading-three'>Add Resource</span>
          </button>
        </div>
      ) : (
        <ShowHideChecklist
          viewedColumns={props.viewedColumns}
          handleChangeViewedColums={props.handleChangeViewedColums}
        />
      )}
    </div>
  )
}

export default TopButtons
