import React, { ReactElement, useContext, useEffect, useRef, useState } from 'react';
import './DelegateModal.css';
import Pill from '../pill';
import { DelegateUsers, getUserDetails,TransferUser } from '../utils/fetchHandler';
import { UserContext } from '../../App';
import _ from 'lodash';
import { Worksheet } from '../landing/Worksheet';
import loaderGif from '../../assets/LoaderGif.gif'

interface User {
  UserId: number;
  Email: string;
  UserName: string;
  AccessLevel:boolean;
}

interface DelegateModalProps {
  isOpen: boolean;
  onClose: () => void;
  delegateId:string;
  confirmedUsers:any;
  delegateAccess : boolean;
  isCheckedIn: boolean;
  isDelegated:boolean;
  planStatus:string;
  setWorksheets: Function;
  worksheets: Array<Worksheet>
}

const DelegateModal = (props: DelegateModalProps): ReactElement => {
// Other states and variables remain the same
const [searchTerm1, setSearchTerm1] = useState('');
const [searchTerm2, setSearchTerm2] = useState('');
const [searchTerm3, setSearchTerm3] = useState('');
const [suggestions1, setSuggestions1] = useState<User[]>([]);
const [suggestions2, setSuggestions2] = useState<User[]>([]);
const [suggestions3, setSuggestions3] = useState<User[]>([]);
const [transferDisabled, setTransferDisabled] = useState<boolean>(false);
const { confirmedUsers } = props;
const test = useContext(UserContext)[0];
const [isSearching, setIsSearching] = useState(false);
const [loadingInput, setLoadingInput] = useState<number | null>(null);
const inputRef1 = useRef<HTMLInputElement>(null);
const inputRef2 = useRef<HTMLInputElement>(null);
const inputRef3 = useRef<HTMLInputElement>(null);

const [dealUsers, setDealUsers] = useState<{ [key: string]: {
  selectedUsers1: User[],
  selectedUsers2: User[],
  selectedUsers3: User[],
  selectedUserSet1: Set<string>,
  selectedUserSet2: Set<string>,
  selectedUserSet3: Set<string>
} }>({});

// Function to update the state for a specific deal
const updateDealUsers = (dealId: string, usersUpdate: {
  selectedUsers1?: User[],
  selectedUsers2?: User[],
  selectedUsers3?: User[],
  selectedUserSet1?: Set<string>,
  selectedUserSet2?: Set<string>,
  selectedUserSet3?: Set<string>
}) => {
  setDealUsers(prevDealUsers => ({ 
    ...prevDealUsers,
    [dealId]: {
      selectedUsers1: usersUpdate.selectedUsers1 || prevDealUsers[dealId]?.selectedUsers1 || [],
      selectedUsers2: usersUpdate.selectedUsers2 || prevDealUsers[dealId]?.selectedUsers2 || [],
      selectedUsers3: usersUpdate.selectedUsers3 || prevDealUsers[dealId]?.selectedUsers3 || [],
      selectedUserSet1: usersUpdate.selectedUserSet1 || prevDealUsers[dealId]?.selectedUserSet1 || new Set(),
      selectedUserSet2: usersUpdate.selectedUserSet2 || prevDealUsers[dealId]?.selectedUserSet2 || new Set(),
      selectedUserSet3: usersUpdate.selectedUserSet3 || prevDealUsers[dealId]?.selectedUserSet3 || new Set()
    }
  }));
};


// Access the state for the current deal
const currentDealUsers = dealUsers[props.delegateId] || {
  selectedUsers1: [],
  selectedUsers2: [],
  selectedUsers3: [],
  selectedUserSet1: new Set(),
  selectedUserSet2: new Set(),
  selectedUserSet3: new Set()
};

  // Destructure the state for easier access
  const { selectedUsers1, selectedUsers2, selectedUsers3, selectedUserSet1, selectedUserSet2, selectedUserSet3 } = currentDealUsers;

  const hideModal = () => {
    const delegateModal = document.getElementById('delegate-modal') as HTMLElement;
    delegateModal.style.display = 'none';
    updateDealUsers(props.delegateId, { selectedUserSet2: new Set()});
    updateDealUsers(props.delegateId, { selectedUserSet3: new Set()});
  };

  const mapApiDataToUser = (apiData: any): User => {
    return {
      UserId: apiData.id,
      Email: apiData.email || '',
      UserName: apiData.name,
      AccessLevel: false
    };
  };

 
  useEffect(() => {
    if (confirmedUsers && props.delegateId) {
      const editAccessUsers = confirmedUsers
        .filter((user: { accessLevel: boolean; pricingPlanId: string; }) => user.accessLevel === true && user.pricingPlanId === props.delegateId)
        .map((user: { userId: any; email: any; userName: any; accessLevel: any; }) => ({
          UserId: user.userId,
          Email: "not found",
          UserName: user.userName,
          AccessLevel: user.accessLevel
        }));
   
      const viewAccessUsers = confirmedUsers
        .filter((user: { accessLevel: boolean; pricingPlanId: string; }) => user.accessLevel === false && user.pricingPlanId === props.delegateId)
        .map((user: { userId: any; email: any; userName: any; accessLevel: any; }) => ({
          UserId: user.userId,
          Email: "not found",
          UserName: user.userName, 
          AccessLevel: user.accessLevel
        })); 
      updateDealUsers(props.delegateId, { selectedUsers2: editAccessUsers });
      updateDealUsers(props.delegateId, { selectedUsers3: viewAccessUsers });
    }
  }, [confirmedUsers, props.delegateId]);
  
  const confirmAction = async () => {
    // Prepare data to send to the backend
    if (transferDisabled === false) {
      const delegationData = {
        PricingPlanId: props.delegateId,
        DelegationUsers: [
          ...selectedUsers2.map((user) => ({ ...user, AccessLevel: true })), // Edit access
          ...selectedUsers3.map((user) => ({ ...user, AccessLevel: false })), // View access
        ],
      };
      try {
        // Make an HTTP POST request using your fetch utility function
        const responseData = await DelegateUsers(delegationData);
        if(responseData){
          delegationData.DelegationUsers.forEach((delegationUser) => {
            const exists = confirmedUsers.some(
              (confirmedUser: { userId: number; pricingPlanId: string; }) => 
                confirmedUser.userId === delegationUser.UserId && 
                confirmedUser.pricingPlanId === delegationData.PricingPlanId
            );
    
            if (!exists) {
              confirmedUsers.push({
                userName: delegationUser.UserName,
                accessLevel: delegationUser.AccessLevel,
                userId: delegationUser.UserId,
                pricingPlanId: delegationData.PricingPlanId
              });
          }
        });
        }     
        // Update worksheets state after successful delegation
        const updatedWorksheets = props.worksheets.map((worksheet) => {
          if (worksheet.pricingPlanID === props.delegateId) { 
            return {
              ...worksheet,
              isDelegated: delegationData.DelegationUsers.length == 0? false :true,
            };
          }
          
          return worksheet;
        });
        props.setWorksheets(updatedWorksheets);
      } catch (error) {
        console.error('Error:', error);
        // Handle error
      }
    } else {
      try {
        const transferdata = {
          PricingPlanId: props.delegateId,
          Userid: selectedUsers1[0].UserId,
        };
        // Make an HTTP POST request using your fetch utility function
        const responseData = await TransferUser(transferdata);
        if (responseData === true) {
          let newArray = [...props.worksheets];
          let index = newArray.findIndex(
            (worksheet) => worksheet.pricingPlanID === props.delegateId
          );
          // Visually delete from table
          newArray.splice(index, 1);
          props.setWorksheets(newArray);
        }
        updateDealUsers(props.delegateId, { selectedUserSet1: new Set()});
      } catch (error) {
        console.error('Error:', error);
        // Handle error
      }
    }
    props.onClose(); // Close the modal upon successful submission
    hideModal();
  };
  

  const fetchUsers = _.debounce(async (searchTerm: string, setSuggestions: React.Dispatch<React.SetStateAction<User[]>>, signal: AbortSignal, inputNumber: number) => {
    setIsSearching(true); // Set isSearching to true when search starts
    if (searchTerm.trim() === "") {
      setSuggestions([]);
      setIsSearching(false); // Set isSearching to false when search is complete
      return;
    }
    setLoadingInput(inputNumber); // Set the loading input
    try {
      const data = await getUserDetails(searchTerm, signal);
      if (data instanceof Array) {
        const users = data.map(mapApiDataToUser);
        const combinedSelectedUsers = [...currentDealUsers.selectedUsers1, ...currentDealUsers.selectedUsers2, ...currentDealUsers.selectedUsers3];
        const filteredUsers = users.filter(user => user.UserName !== test && !combinedSelectedUsers.find(selectedUser => selectedUser.UserId === user.UserId));
        setSuggestions(filteredUsers);
      } else {
        setSuggestions([]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsSearching(false); // Set isSearching to false when search is complete
    }
  }, 500);
 
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    fetchUsers(searchTerm1, setSuggestions1, signal, 1);
    return () => {
      controller.abort();
    };
  }, [searchTerm1]);
 
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    fetchUsers(searchTerm2, setSuggestions2, signal, 2);
    return () => {
      controller.abort();
    };
  }, [searchTerm2]);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    fetchUsers(searchTerm3, setSuggestions3, signal, 3);
    return () => {
      controller.abort();
    };
  }, [searchTerm3]);
 

  const handleSelectUser = (user: User, inputNumber: number) => {
    // Create a copy of the current users for the specific input number
    const updatedSelectedUsers1 = inputNumber === 1 ? [...selectedUsers1, user] : selectedUsers1;
    const updatedSelectedUsers2 = inputNumber === 2 ? [...selectedUsers2, { ...user, AccessLevel: true }] : selectedUsers2;
    const updatedSelectedUsers3 = inputNumber === 3 ? [...selectedUsers3, { ...user, AccessLevel: false }] : selectedUsers3;
   
    // Create a copy of the current user sets for the specific input number
    const updatedSelectedUserSet1 = inputNumber === 1 ? new Set([...Array.from(selectedUserSet1), user.Email]) : selectedUserSet1;
    const updatedSelectedUserSet2 = inputNumber === 2 ? new Set([...Array.from(selectedUserSet2), user.Email]) : selectedUserSet2;
    const updatedSelectedUserSet3 = inputNumber === 3 ? new Set([...Array.from(selectedUserSet3), user.Email]) : selectedUserSet3;
   
    // Update the state for the current deal
    updateDealUsers(props.delegateId, {
      selectedUsers1: updatedSelectedUsers1,
      selectedUsers2: updatedSelectedUsers2,
      selectedUsers3: updatedSelectedUsers3,
      selectedUserSet1: updatedSelectedUserSet1,
      selectedUserSet2: updatedSelectedUserSet2,
      selectedUserSet3: updatedSelectedUserSet3,
    });
   
    // Clear the search term and suggestions for the specific input number
    if (inputNumber === 1) {
      setSearchTerm1('');
      setSuggestions1([]);
      inputRef1.current?.focus();
    } else if (inputNumber === 2) {
      setSearchTerm2('');
      setSuggestions2([]);
      inputRef2.current?.focus();
    } else if (inputNumber === 3) {
      setSearchTerm3('');
      setSuggestions3([]);
      inputRef3.current?.focus();
    }
  };
   
  const handleRemoveUser = (user: User, inputNumber: number) => {
    // Filter out the user to be removed for the specific input number
    const updatedSelectedUsers1 = inputNumber === 1 ? selectedUsers1.filter(u => u.UserId !== user.UserId) : selectedUsers1;
    const updatedSelectedUsers2 = inputNumber === 2 ? selectedUsers2.filter(u => u.UserId !== user.UserId) : selectedUsers2;
    const updatedSelectedUsers3 = inputNumber === 3 ? selectedUsers3.filter(u => u.UserId !== user.UserId) : selectedUsers3;
   
    // Remove the user email from the set for the specific input number
    const updatedSelectedUserSet1 = inputNumber === 1 ? new Set(Array.from(selectedUserSet1).filter(email => email !== user.Email)) : selectedUserSet1;
  const updatedSelectedUserSet2 = inputNumber === 2 ? new Set(Array.from(selectedUserSet2).filter(email => email !== user.Email)) : selectedUserSet2;
  const updatedSelectedUserSet3 = inputNumber === 3 ? new Set(Array.from(selectedUserSet3).filter(email => email !== user.Email)) : selectedUserSet3;
   
    // Update the state for the current deal
    updateDealUsers(props.delegateId, {
      selectedUsers1: updatedSelectedUsers1,
      selectedUsers2: updatedSelectedUsers2,
      selectedUsers3: updatedSelectedUsers3,
      selectedUserSet1: updatedSelectedUserSet1,
      selectedUserSet2: updatedSelectedUserSet2,
      selectedUserSet3: updatedSelectedUserSet3,
    });
  };

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "yes") {
      setTransferDisabled(true);
    } else {
      setTransferDisabled(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (inputRef1.current && !inputRef1.current.contains(event.target as Node)) {
        setSuggestions1([]);
      }
      if (inputRef2.current && !inputRef2.current.contains(event.target as Node)) {
        setSuggestions2([]);
      }
      if (inputRef3.current && !inputRef3.current.contains(event.target as Node)) {
        setSuggestions3([]);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  
  return (
    <div>
      <div id='delegate-modal'>
        <div className='delegate-modal-content'>
          <p className='heading-three'>Do you want to transfer full ownership of this deal?</p>
          <div className='radio-options'>
            <label>
              <input type='radio' name='ownership' value='yes' onChange={handleRadioChange} />
              Yes
            </label>
            <label>
              <input type='radio' name='ownership' value='no' onChange={handleRadioChange} defaultChecked />
              No
            </label>
          </div>
          <p className='heading-three'>If Yes, Please enter the email ID of the user, whom you want to transfer your deal to:</p>
          <div className='input-container1'>
            {selectedUsers1.map((user) => {
              return <Pill key={user.Email} text={`${user.UserName}`} onClick={() => handleRemoveUser(user, 1)} />;
            })}
            <div ref={inputRef1} style={{ position: 'relative' }}>
              <input
                type='text'
                value={searchTerm1}
                onChange={(e) => setSearchTerm1(e.target.value)}
                disabled={!transferDisabled || selectedUsers1.length === 1}
                className={!transferDisabled || selectedUsers1.length === 1 ? 'disabled-input' : ' '}
              />
              <span className='material-icons-outlined' style={{ position: 'absolute', right: '5px',top:'14px'}}>search</span>
              <div
                style={{
                  textAlign: 'right',
                  marginTop: '10px',
                  visibility:(searchTerm1 && suggestions1.length === 0) || suggestions1.length > 0 && !isSearching ? 'visible' : 'hidden',
                }}
              >
                <span>{loadingInput === 1 && suggestions1.length > 0 ? `${suggestions1.length} Results` : 'No Results Found'}</span>
              </div>
              <ul className='suggestions-list'>
                {loadingInput === 1 && isSearching ? (
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <p className='body-text' style={{ textAlign: 'center' }}>
                      Searching, please wait.
                    </p>
                    <img src={loaderGif} alt='Searching...' />
                  </div>
                ) : (
                  suggestions1.map((user, index) => {
                    return !selectedUserSet1.has(user.Email) ? (
                      <li key={user.Email} onClick={() => handleSelectUser(user, 1)}>
                        <span style={{ fontWeight: 'bold', width: '50%' }}>{user.UserName}</span>
                        <span style={{ textAlign: 'right', width: '50%' }}>{user.Email}</span>
                      </li>
                    ) : (
                      <></>
                    );
                  })
                )}
              </ul>
            </div>
          </div>
          <p className='heading-three'>If No, Do you want to grant edit access to another user or Do you want to grant view access to another user(s)?</p>
          <p className='heading-three'>Please enter Email ID of the user whom you want to grant edit access to:</p>
          <div className='input-container1'>
            {selectedUsers2.map((user) => {
              return <Pill key={user.Email} text={`${user.UserName}`} onClick={() => handleRemoveUser(user, 2)} />;
            })}
            <div ref={inputRef2} style={{ position: 'relative' }}>
              <input              
                type='text'
                value={searchTerm2}
                onChange={(e) => setSearchTerm2(e.target.value)}
                disabled={transferDisabled || selectedUsers2.length === 1}
                className={transferDisabled || selectedUsers2.length === 1 ? 'disabled-input' : ' '}
              />
              <span className='material-icons-outlined' style={{ position: 'absolute', right: '5px',top:'14px'}}>search</span>
              <div
                style={{
                  textAlign: 'right',
                  marginTop: '10px',
                  visibility:
                    (searchTerm2 && suggestions2.length === 0) || suggestions2.length > 0 && !isSearching ? 'visible' : 'hidden',
                }}
              >
                <span>{loadingInput === 2 && suggestions2.length > 0 ? `${suggestions2.length} Results` : 'No Results Found'}</span>
              </div>
              <ul className='suggestions-list'>
                {loadingInput === 2 && isSearching ? (
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <p className='body-text' style={{ textAlign: 'center' }}>
                      Searching, please wait.
                    </p>
                    <img src={loaderGif} alt='Searching...' />
                  </div>
                ) : (
                  suggestions2.map((user, index) => {
                    return !selectedUserSet2.has(user.Email) ? (
                      <li key={user.Email} onClick={() => handleSelectUser(user, 2)}>
                        <span style={{ fontWeight: 'bold', width: '50%' }}>{user.UserName}</span>
                        <span style={{ textAlign: 'right', width: '50%' }}>{user.Email}</span>
                      </li>
                    ) : (
                      <></>
                    );
                  })
                )}
              </ul>
            </div>
          </div>
          <p className='heading-three'>Please enter Email ID of the users you want to grant view access :</p>
          <div className='input-container1'>
            {selectedUsers3.map((user) => {
              return <Pill key={user.Email} text={`${user.UserName}`} onClick={() => handleRemoveUser(user, 3)} />;
            })}
            <div ref={inputRef3} style={{ position: 'relative' }}>
              <input
                type='text'
                value={searchTerm3}
                onChange={(e) => setSearchTerm3(e.target.value)}
                disabled={transferDisabled}
                className={transferDisabled ? 'disabled-input' : ' '}
              />
              <span className='material-icons-outlined' style={{ position: 'absolute', right: '5px',top:'14px'}}>search</span>
              <div
                style={{
                  textAlign: 'right',
                  marginTop: '10px',
                  visibility:
                    (searchTerm3 && suggestions3.length === 0) || suggestions3.length > 0 && !isSearching ? 'visible' : 'hidden',
                }}
              >
                <span>{loadingInput === 3 && suggestions3.length > 0 ? `${suggestions3.length} Results` : 'No Results Found'}</span>
              </div>
              <ul className='suggestions-list'>
                {loadingInput === 3 && isSearching ? (
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <p className='body-text' style={{ textAlign: 'center' }}>
                      Searching, please wait.
                    </p>
                    <img src={loaderGif} alt='Searching...' />
                  </div>
                ) : (
                  suggestions3.map((user, index) => {
                    return !selectedUserSet3.has(user.Email) ? (
                      <li key={user.Email} onClick={() => handleSelectUser(user, 3)}>
                        <span style={{ fontWeight: 'bold', width: '50%' }}>{user.UserName}</span>
                        <span style={{ textAlign: 'right', width: '50%' }}>{user.Email}</span>
                      </li>
                    ) : (
                      <></>
                    );
                  })
                )}
              </ul>
            </div>
          </div>
          <div className='delegate-modal-buttons'>
            <button className='primary-button heading-three' onClick={confirmAction}>
              {transferDisabled ? 'Transfer' : 'Delegate'}
            </button>
            <button className='secondary-button heading-three' onClick={hideModal}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DelegateModal
