import { useState } from 'react'
import _, { AnyKindOfDictionary } from 'lodash'
import { useUpdateEffect } from 'react-use'
import { getMarketScopes, getNamedResources } from '../../utils/fetchHandler'
import loaderGif from '../../../assets/LoaderGif.gif'
import './NamedResource.css'
import { RouteParamTypes, MarketScopeApiParamsType, MarketScopeType } from '../ResourceTypes'
import { useParams } from 'react-router-dom'
import { MarketScope, getCountryInformation, getRounded } from '../ResourceTableComponents'
import Resources from '../Resources'

const debouncedFetchData = _.debounce(async (params, setIsSearching, setResults, signal) => {
  setIsSearching(true)
  try {
    const resources = params.name === '' ? null : await getNamedResources(params, signal)

    setResults(resources)
    setIsSearching(false)
  } catch (error) {
    setIsSearching(false)
    setResults([])
  }
}, 500)

const NamedResource = (props: any) => {
  let result: any
  let marketScopeData: any
  let availableCountyData: any
  let availableMarketScopes: any[] = []
  let marketScopeId: any
  let countryId: any
  let marketRate: any
  const [isSearching, setIsSearching] = useState(false)
  const [selectedResource, setSelectedResource] = useState<any>(null)
  const [searchInput, setSearchInput] = useState('')
  const [results, setResults] = useState<any[] | null>(null)
  const [availableCountries, setAvailableCountries] = useState<any[]>([])

  const { pricingPlanId } = useParams<RouteParamTypes>()

  const GetMarketScopeData = async(selectedResource: any)  =>{
    
    let roleId:any
    let role = selectedResource.rankName?.split(' ')
    let roleName:any = ""
    let rankLevel:any = ""
    role.forEach((value:any, index:any) => {
      if(index < role.length-1){
        roleName += value + " "
      }
    })
    roleName = roleName.trim()
    rankLevel = roleName +"Level "+ role[role.length-1]
    //roleId = parseInt(role[role.length-1],10)
    let marketScopeApiParams ={
    competencyId: selectedResource.lookUpCompetencyId,
    pricingPlanId: pricingPlanId,
    roleName: rankLevel,
    countryName: selectedResource.costCountry
  }

    let results: any
    try {
      results = await getMarketScopes(marketScopeApiParams)
    } catch (error) {}

    const _marketScopes = results

    result = _marketScopes
    marketScopeData = _marketScopes.marketScopes
    console.log(result)

    marketScopeData?.forEach((scope:any)=>{
      let availableMarketScope = {marketScopeValue: scope.marketScopeValue, marketScopeId: scope.marketScopeId}
      availableMarketScopes.push(availableMarketScope)
      if(scope.isDefault==true){
        marketScopeId = scope.marketScopeId
        marketRate = scope.rate
      }
    })

    if(marketScopeData != undefined){
      const countryInformation = getCountryInformation(marketScopeData)
      availableCountyData = countryInformation.availableCountries
      countryId = countryInformation.defaultCountry.countryId
    }
    setAvailableCountries(availableCountyData)
    return {result: result, availableCountyData: availableCountyData, countryId: countryId, 
      availableMarketScopes: availableMarketScopes, marketScopeId: marketScopeId, marketRate: marketRate}
  }

  useUpdateEffect(() => {
    let controller: any = new AbortController()
    const { signal } = controller
    debouncedFetchData({ name: searchInput, currency: props.planCurrency }, setIsSearching, setResults, signal)
    return () => {
      debouncedFetchData.cancel()
      controller?.abort()
    }
  }, [searchInput])

  return (
    <div className='named-resource-container'>
      <div className='named-resource-input-field-wrapper'>
        <input
          type='text'
          className='input-field'
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
        />
        <span className='material-icons-outlined'>search</span>
      </div>

      <div
        style={{ textAlign: 'right', marginTop: '10px', visibility: isSearching || !results ? 'hidden' : 'visible' }}
      >
        <span className='results-count-text'>
          {results && results.length > 0 ? `${results.length} Results` : 'No Results Found'}
        </span>
      </div>

      <div className='results-container' attr-issearching={`${isSearching}`}>
        {isSearching ? (
          <div>
            <p className='body-text' style={{ textAlign: 'center' }}>
              Searching, please wait.
            </p>
            <img src={loaderGif} alt='Searching...' />
          </div>
        ) : (
          results?.map((result, index) => {
            return (
              <div
                key={index}
                className={`result-item ${
                  selectedResource && result.employeeGUIId === selectedResource.employeeGUIId ? 'selected' : ''
                }`}
                onClick={() => setSelectedResource(result)}
              >
                <span className='name'> {result.employee}</span>
                <span className='cost-centre'> {result.costCentre}</span>
              </div>
            )
          })
        )}
      </div>

      <div className='footer-bottons-container'>
        <button
          className='secondary-button'
          type='button'
          onClick={() => {
            setSearchInput('')
            setResults([])
            props.toggleAddResourceModal(false)
          }}
        >
          <span className='heading-three'>Cancel</span>
        </button>
        <button
          className='primary-button'
          type='button'
          onClick={async () => {
            let data = GetMarketScopeData(selectedResource)
            selectedResource &&
              props.addResource(
                true,
                selectedResource,
                (await data).result,
                (await data).availableCountyData,
                (await data).countryId,
                (await data).marketScopeId,
                (await data).availableMarketScopes,
                (await data).marketRate
              )
            props.toggleAddResourceModal(false)
            setSearchInput('')
            setResults([])
          }}
          disabled={selectedResource === null ? true : false}
        >
          <span className='heading-three'>Confirm</span>
        </button>
      </div>
    </div>
  )
}

export default NamedResource
