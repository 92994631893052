import React, { ReactElement, useEffect, useRef } from 'react'
import './TechnologyExpenses.css'
import { ErrorMessage, Field, FieldArray, getIn } from 'formik'
import TextError from '../formik-controls/TextError'
import Tooltip from '../common/Tooltip'
import Select from '../formik-controls/select/Select'
import { updateInputStyling } from '../utils/updateStyling'

interface TechnologyExpensesProps {
  display: boolean
  totalExpenses: number
  setTotalExpenses: Function
  totalRevenue: number
  setTotalRevenue: Function
  techExpenseWorkstreams: any
  techExpenses: any
  setTechExpenses: Function
  setDeletionFlag: Function
  planCurrency: string
  showWorkstream: boolean
}

const TechnologyExpenses = (props: TechnologyExpensesProps): ReactElement => {
  const display = props.display

  const arrayHelpersRef = useRef<any>()

  const techExpenseTypes = [
    { value: 'ctProducts', label: 'CT Products (Direct Cost)' }
    // { value: 'otherProducts', label: 'Other Technology (Expense)' }
  ]

  useEffect(() => {
    updateInputStyling()
  }, [])

  useEffect(() => {
    sumCosts()
    sumRevenue()
    updateInputStyling()
  }, [props.techExpenses])

  const sumCosts = () => {
    let sum = parseFloat('0')
    for (let i = 0; i < props.techExpenses.length; i++) {
      if (props.techExpenses[i].cost !== undefined) {
        sum += Number(props.techExpenses[i].cost)
      }
    }
    props.setTotalExpenses(sum.toFixed(2))
  }

  const sumRevenue = () => {
    let sum = parseFloat('0')
    for (let i = 0; i < props.techExpenses.length; i++) {
      if (props.techExpenses[i].revenue !== undefined) {
        sum += Number(props.techExpenses[i].revenue)
      }
    }
    props.setTotalRevenue(sum.toFixed(2))
  }

  const renderError = (arrayName: string, fieldName: string, index: number, errors: any, touched: any) => {
    const error = getIn(errors, `${arrayName}[${index}].${fieldName}`)
    const touch = getIn(touched, `${arrayName}[${index}].${fieldName}`)
    return touch && error ? error : null
  }

  const addRow = () => {
    if (arrayHelpersRef?.current) {
      if (props.showWorkstream) {
        arrayHelpersRef.current.push({
          category: '',
          workstream: '',
          cost: undefined,
          revenue: undefined,
          recoverable: undefined,
          expenseType: 'Technology'
        })
      } else {
        arrayHelpersRef.current.push({
          category: '',
          workstream: 'unspecified',
          cost: undefined,
          revenue: undefined,
          recoverable: undefined,
          expenseType: 'Technology'
        })
      }
    }
  }

  const restrictDecimals = (event: any) => {
    const input = event.target.value
    if (input.indexOf('.') >= 0) {
      event.target.value = input.substr(0, input.indexOf('.')) + input.substr(input.indexOf('.'), 3)
    }
  }

  return (
    <div>
      {display && (
        <div>
          <div
            className={
              props.showWorkstream ? 'tech-expenses-header' : 'tech-expenses-header tech-expenses-no-workstream'
            }
          >
            <p className='body-text large'> Category</p>
            {props.showWorkstream && <p className='body-text medium'>Workstream</p>}
            <p className='body-text medium'>{'Cost (' + props.planCurrency + ')'}</p>
            <p className='body-text medium'>{'Revenue (' + props.planCurrency + ')'}</p>
            <p className='body-text medium'>Comment</p>
          </div>
          <FieldArray name='techRows'>
            {(arrayHelpers) => {
              if (!arrayHelpersRef.current) {
                arrayHelpersRef.current = arrayHelpers
              }
              const { form, remove } = arrayHelpers
              return (
                <div>
                  <div className='add-margin'>
                    <div></div>
                  </div>
                  {form?.values?.techRows.map((row: any, index: number) => {
                    props.setTechExpenses(form.values.techRows)
                    return (
                      <div
                        key={index}
                        className={
                          props.showWorkstream ? 'tech-expenses-row' : 'tech-expenses-row tech-expenses-no-workstream'
                        }
                      >
                        <div className='large field-error-container'>
                          <Select name={`techRows[${index}].category`} options={techExpenseTypes} isDisabled={false} />
                          <ErrorMessage name={`techRows[${index}].category`} component={TextError} />
                        </div>

                        {props.showWorkstream && (
                          <div className='large'>
                            <Select
                              name={`techRows[${index}].workstream`}
                              options={props.techExpenseWorkstreams}
                              isDisabled={false}
                              placeholder={'Select...'}
                            />
                            <ErrorMessage name={`techRows[${index}].workstream`} component={TextError} />
                          </div>
                        )}

                        <div className={`field-error-container ${props.showWorkstream === false && 'no-workstreams'}`}>
                          <Field
                            name={`techRows[${index}].cost`}
                            className={`medium input-field ${
                              props.showWorkstream === false && 'tech-expenses-no-workstream-medium'
                            } 
                                      ${
                                        renderError('techRows', 'cost', index, form.errors, form.touched) ? 'error' : ''
                                      }`}
                            type='number'
                            onInput={restrictDecimals}
                          />
                          <ErrorMessage name={`techRows[${index}].cost`} component={TextError} />
                        </div>

                        <div className={`field-error-container ${props.showWorkstream === false && 'no-workstreams'}`}>
                          <Field
                            name={`techRows[${index}].revenue`}
                            className={`medium input-field ${
                              props.showWorkstream === false && 'tech-expenses-no-workstream-medium'
                            } 
                                      ${
                                        renderError('techRows', 'revenue', index, form.errors, form.touched)
                                          ? 'error'
                                          : ''
                                      }`}
                            type='number'
                            onInput={restrictDecimals}
                          />
                          <ErrorMessage name={`techRows[${index}].revenue`} component={TextError} />
                        </div>

                        <div
                          className={
                            props.showWorkstream
                              ? 'select-box small'
                              : 'select-box small tech-expenses-no-workstream-small'
                          }
                        >
                          <Field
                            name={`techRows[${index}].comment`}
                            rows={2}
                            className={`medium input-field ${
                              props.showWorkstream === false && 'tech-expenses-no-workstream-medium'
                            }
                                      ${
                                        renderError('techRows', 'revenue', index, form.errors, form.touched)
                                          ? 'error'
                                          : ''
                                      }`}
                            type='textArea'
                            as='textarea'
                          />

                          {/* <textarea
                            name={`techRows[${index}].comment`}
                            rows={2}
                            className={`medium input-field ${
                              props.showWorkstream === false && 'tech-expenses-no-workstream-medium'
                            }
                                      ${
                                        renderError('techRows', 'comment', index, form.errors, form.touched)
                                          ? 'error'
                                          : ''
                                      }`}
                          ></textarea> */}
                          {/* <Select
                            name={`techRows[${index}].recoverable`}
                            options={[
                              { value: true, label: 'Yes' },
                              { value: false, label: 'No' }
                            ]}
                            isDisabled={false}
                            placeholder={''}                          /> */}
                          <ErrorMessage name={`techRows[${index}].comment`} component={TextError} />
                        </div>

                        <button
                          type='button'
                          onClick={() => {
                            props.setDeletionFlag(true)
                            remove(index)
                            if (form.values.techRows.length === 1) {
                              form.values.techRows[0].category = ''
                              form.values.techRows[0].workstream = ''
                              form.values.techRows[0].cost = undefined
                              form.values.techRows[0].revenue = undefined
                              form.values.techRows[0].recoverable = undefined
                              form.values.techRows[0].expenseType = 'Technology'
                            }
                          }}
                        >
                          <Tooltip placement='top' content='Delete'>
                            <span
                              className={`material-icons delete-icon ${
                                props.showWorkstream === false && 'tech-expenses-no-workstream-medium'
                              }`}
                            >
                              delete_outline
                            </span>
                          </Tooltip>
                        </button>
                      </div>
                    )
                  })}

                  <button className='add-tech-expense-button' type='button' onClick={() => addRow()}>
                    <span className='material-icons'>add_circle_outline</span>
                    <p className='add-expenses-text'>Add new line</p>
                  </button>
                </div>
              )
            }}
          </FieldArray>
        </div>
      )}
    </div>
  )
}

export default TechnologyExpenses
