import React from 'react'
import DatePicker from 'react-datepicker'
import { Field } from 'formik'
import 'react-datepicker/dist/react-datepicker.css'
import './DatePicker.css'
interface DatePickerProps {
  name: string
  isError?: string | boolean
  minDate?: Date | null
  maxDate?: Date | null
  portalId?: string
  onChange?: (date: Date) => void
}

const DatePicker1 = (props: DatePickerProps) => {
  const CustomInput = React.useMemo(
    () =>
      React.forwardRef((props: any, ref: any) => {
        const { isError, ...fieldProps } = props
        return (
          <div className='datepicker-wrapper w-100'>
            <input {...fieldProps} ref={ref} className={`input-field ${isError ? 'error' : ''}`} />
            <span className='material-icons' onClick={props.onClick}>
              event
            </span>
          </div>
        )
      }),
    []
  )

  return (
    <Field name={props.name}>
      {({ form, field }: any) => {
        return (
          <DatePicker
            {...(props.portalId ? { portalId: props.portalId } : {})}
            id={props.name}
            selected={field.value}
            onChange={(date: Date) => {
              props.onChange && props.onChange(date)
              form.setFieldTouched(props.name)
              form.setFieldValue(props.name, date)
            }}
            onBlur={() => {
              form.setFieldTouched(props.name)
            }}
            placeholderText='DD/MM/YYYY'
            customInput={
              <CustomInput
                isError={
                  props.isError
                    ? props.isError
                    : form?.errors?.[props.name] && form?.touched?.[props.name]
                    ? true
                    : false
                }
              />
            }
            dateFormat={["dd/MM/yyyy", "ddMMyyyy"]}
            minDate={props.minDate}
            maxDate={props.maxDate}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode='select'
          />
        )
      }}
    </Field>
  )
}

export default DatePicker1
