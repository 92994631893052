import moment from 'moment'
import React from 'react'
import RatesModal from './modals/RatesModal'
import './UpdateRates.css'

const UpdateRates = (props: any) => {
  let { rates, uploadedCostRateFile, uploadedExchangeRateFile, uploadedInflationRateFile,uploadedMasterCostRateFile,uploadedMasterExchangeRateFile,uploadedMasterMarketRateFile,uploadedMasterSectorDiscountFile,uploadedMasterOfferingCompetencyCostMappingFile } = props
  const labels: any = [
    { fileType: 'CostRates', label: 'Cost Rates' },
    { fileType: 'ExchangeRates', label: 'Exchange Rates' },
    { fileType: 'InflationRates', label: 'Inflation Rates' },
    { fileType: 'mastercostrate', label: 'Master Cost Rates' },
    { fileType: 'masterexchangerate', label: 'Master Exchange Rates' },
    { fileType: 'mastermarketrate', label: 'Master Market Rates' },
    { fileType: 'mastersectordiscount', label: 'Master Sector Discount' },
    { fileType: 'masterofferingmap', label: 'Master Offering Competency Cost Mappings' },
  ]
  
  
  rates = rates?.map((rate: any) => {
    const label = labels.find((label: any) => label.fileType.toLowerCase() === rate.fileType.toLowerCase()).label    
    return {
      ...rate,
      label
    }
  })

  return (
    <div style={{ marginTop: '30px' }}>
      <h3 className='heading-three'>Update Rates</h3>
      <p className='body-text'>See the latest uploaded versions of rates used in CCT.</p>

      <div className='rates-table'>
        <div className='thead'>
          <div className='tr'>
            <div className='th'>Rate Type</div>
            <div className='th'>Current File</div>
            <div className='th'>Updated by</div>
            <div className='th'>Date Added</div>
          </div>
        </div>
        <div className='tbody'>
          {rates?.length > 0 &&
            rates.map((rate: any) => (
              <React.Fragment key={rate.label}>
                <div className='tr'>
                  <div className='td'>
                    <div className='body-text'>{rate.label}</div>
                  </div>
                  <div className='td'>
                    <div className='body-text file-name'>{rate.fileName}</div>
                  </div>
                  <div className='td'>
                    <div className='body-text'>{rate.userName}</div>
                  </div>
                  <div className='td'>
                    <div className='body-text'>{moment(rate.uploadDate).format('DD/MM/YY')}</div>
                  </div>
                </div>
                {rate.fileType.toLowerCase() === 'costrates' && uploadedCostRateFile && (
                  <div className='rates-upload-notificaion body-text'>
                    Cost rates from <span style={{ fontWeight: 'bold' }}>{props.uploadedCostRateFile.name}</span> has
                    been uploaded. To update changes, click "Apply New Rates" button.
                  </div>
                )}
                {rate.fileType.toLowerCase() === 'exchangerates' && uploadedExchangeRateFile && (
                  <div className='rates-upload-notificaion body-text'>
                    Exchange rates from{' '}
                    <span style={{ fontWeight: 'bold' }}>{props.uploadedExchangeRateFile.name}</span> has been uploaded.
                    To update changes, click "Apply New Rates" button.
                  </div>
                )}                
                {rate.fileType.toLowerCase() === 'inflationrates' && uploadedInflationRateFile && (
                  <div className='rates-upload-notificaion body-text'>
                    Inflation rates from{' '}
                    <span style={{ fontWeight: 'bold' }}>{props.uploadedInflationRateFile.name}</span> has been
                    uploaded. To update changes, click "Apply New Rates" button.
                  </div>
                )}
                 {rate.fileType.toLowerCase() === 'mastercostrate' && uploadedMasterCostRateFile && (
                  <div className='rates-upload-notificaion body-text'>
                    Master cost rates from <span style={{ fontWeight: 'bold' }}>{props.uploadedMasterCostRateFile.name}</span> has
                    been uploaded. To update changes, click "Apply New Rates" button.
                  </div>
                )}               
                {rate.fileType.toLowerCase() === 'masterexchangerate' && uploadedMasterExchangeRateFile && (
                  <div className='rates-upload-notificaion body-text'>
                    Master exchange rates from <span style={{ fontWeight: 'bold' }}>{props.uploadedMasterExchangeRateFile.name}</span> has
                    been uploaded. To update changes, click "Apply New Rates" button.
                  </div>
                )}                
                {rate.fileType.toLowerCase() === 'mastermarketrate' && uploadedMasterMarketRateFile && (
                  <div className='rates-upload-notificaion body-text'>
                    Master market rates from <span style={{ fontWeight: 'bold' }}>{props.uploadedMasterMarketRateFile.name}</span> has
                    been uploaded. To update changes, click "Apply New Rates" button.
                  </div>
                )}                
                {rate.fileType.toLowerCase() === 'mastersectordiscount' && uploadedMasterSectorDiscountFile && (
                  <div className='rates-upload-notificaion body-text'>
                    Master sector discount from <span style={{ fontWeight: 'bold' }}>{props.uploadedMasterSectorDiscountFile.name}</span> has
                    been uploaded. To update changes, click "Apply New Rates" button.
                  </div>
                )}
                {rate.fileType.toLowerCase() === 'masterofferingmap' && uploadedMasterOfferingCompetencyCostMappingFile && (
                  <div className='rates-upload-notificaion body-text'>
                    Master offering competency cost mappings from <span style={{ fontWeight: 'bold' }}>{props.uploadedMasterOfferingCompetencyCostMappingFile.name}</span> has
                    been uploaded. To update changes, click "Apply New Rates" button.
                  </div>
                )}
                <hr />
              </React.Fragment>
            ))}
        </div>
        <div className='tfooter'>
          <div className='buttons-container'>
            <button className='primary-button' type='button' onClick={() => props.setIsRatesModalOpen(true)}>
              <span className='heading-three'>Upload New</span>
            </button>
          </div>
        </div>
      </div>

      <RatesModal
        isOpen={props.isRatesModalOpen}
        isUploading={props.isRatesFileUploading}
        onCancelRatesModal={props.onCancelRatesModal}
        selectedRatesFile={props.selectedRatesFile}
        setSelectedRatesFile={props.setSelectedRatesFile}
        onConfirmRatesModal={props.onConfirmRatesModal}
        setSelectedRateType={props.setSelectedRateType}
        selectedRateType={props.selectedRateType}
      />
    </div>
  )
}

export default UpdateRates
