import React, { useState, useRef } from 'react'
import { FieldArray, Field, getIn, ErrorMessage, useFormikContext } from 'formik'
import DatePicker from '../formik-controls/date-picker/DatePicker'
import TextError from '../formik-controls/TextError'
import Tooltip from '../common/Tooltip'
import './Phases.css'
import { values } from 'lodash'
import { format } from 'path'

const Phases = () => {
  const arrayHelpersRef = useRef<any>()
  const { values }: any = useFormikContext()
  console.log(values)

  // Limiting the no. of phases to 6
  const isAddNewPhaseDisabled = () => {
    return values?.phases?.length >= 6 ? true : false
  }

  const [showAccordion, setShowAccordion] = useState(true)

  const toggleAccordion = () => {
    setShowAccordion((prevState) => (prevState === true ? false : true))
  }

  const renderError = (arrayName: string, fieldName: string, index: number, errors: any, touched: any) => {
    const error = getIn(errors, `${arrayName}[${index}].${fieldName}`)
    const touch = getIn(touched, `${arrayName}[${index}].${fieldName}`)
    return touch && error ? error : null
  }

  return (
    <div style={{ marginTop: '30px' }} className='phases'>
      <button type='button' className='accordion' onClick={toggleAccordion}>
        <p className='form-heading heading-three' style={{ margin: 0 }}>
          Phases
          <span className='optional-text'>(Optional)</span>
          <span className='optional-comment'>
            Note: Please add up to 6 Phases and Phases breakdown on the Summary page is available
          </span>
        </p>
        {showAccordion ? (
          <span className='material-icons'>expand_less</span>
        ) : (
          <span className='material-icons'>expand_more</span>
        )}
      </button>
      {showAccordion && (
        <div className='panel'>
          <div className='form-header'>
            <div className='row item-row'>
              <div className='col'>
                <p className='phase-header-text'>Phase Title</p>
              </div>
              <div className='col'>
                <div className='row'>
                  <div className='col'>
                    <p className='phase-header-text'>Phase Start Date</p>
                  </div>
                  <div className='col'>
                    <p className='phase-header-text'>Phase End Date</p>
                  </div>
                </div>
              </div>
              <div className='col'></div>
            </div>
          </div>
          <div style={{ marginTop: '10px' }}>
            <FieldArray name='phases'>
              {(arrayHelpers) => {
                if (!arrayHelpersRef.current) {
                  arrayHelpersRef.current = arrayHelpers
                }
                const { form, remove } = arrayHelpers
                const { setFieldValue } = form
                return (
                  <div>
                    {form?.values?.phases?.map((phase: any, index: number) => {
                      return (
                        <React.Fragment key={index}>
                          <div className='row item-row'>
                            <div className='col'>
                              <Field
                                name={`phases[${index}].title`}
                                maxLength={30}
                                className={`input-field ${
                                  renderError('phases', 'title', index, form.errors, form.touched) ? 'error' : ''
                                }`}
                              />
                              <ErrorMessage name={`phases[${index}].title`} component={TextError} />
                            </div>
                            <div className='col'>
                              <div className='row'>
                                <div className='col'>
                                  <DatePicker
                                    name={`phases[${index}].startDate`}
                                    isError={renderError('phases', 'startDate', index, form.errors, form.touched)}
                                    onChange={(date: Date) => {
                                      if (form.values.phases[index].endDate < date)
                                        setFieldValue(`phases[${index}].endDate`, null)
                                    }}
                                  />
                                  <ErrorMessage name={`phases[${index}].startDate`} component={TextError} />
                                </div>
                                <div className='col'>
                                  <DatePicker
                                    name={`phases[${index}].endDate`}
                                    isError={renderError('phases', 'endDate', index, form.errors, form.touched)}
                                  />
                                  <ErrorMessage name={`phases[${index}].endDate`} component={TextError} />
                                </div>
                              </div>
                            </div>
                            <div className='col col-delete'>
                              <Tooltip content='Delete' placement='top'>
                                <span className='material-icons delete-icon' onClick={() => remove(index)}>
                                  delete_outline
                                </span>
                              </Tooltip>
                            </div>
                          </div>
                          <hr style={{ border: '1px solid #E7E7EA' }} />
                        </React.Fragment>
                      )
                    })}
                  </div>
                )
              }}
            </FieldArray>
          </div>
          <div className='row'>
            <div
              className={isAddNewPhaseDisabled() ? 'col add-phase-col-disabled' : 'col add-phase-col'}
              onClick={() => {
                if (arrayHelpersRef?.current) {
                  if (!isAddNewPhaseDisabled()) {
                    //Restricting the phases to maximum 8
                    arrayHelpersRef.current.push({
                      title: '',
                      startDate: null,
                      endDate: null
                    })
                  }
                }
              }}
            >
              <span
                className={
                  isAddNewPhaseDisabled() ? 'material-icons add-phase-icon-disabled' : 'material-icons add-phase-icon'
                }
              >
                add_circle_outline
              </span>
              <div className='add-phase-text'>Add new line</div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Phases
