import './EYViewBox.css'
import { useEffect, useMemo, useState } from 'react'
import { numberWithCommas } from './../utils/helperFunctions'
import moment from 'moment'
import { timeStamp } from 'console'
import { getSystemErrorMap } from 'util'
//import { start } from 'repl'
//import { indexOf } from 'lodash'

interface EYViewBoxProps {
  title: string
  selectedColumn: number
  setSelectedColumn: Function
  columnNum: number
  formValues: any
  expensesValues: any
  resourcesValues: any
  eyViewValues: any
  setEyViewValues: Function
  inputResources: any
  inputExpenses: any
  planCurrency: string
  planUnit: string
}

const EYViewBox = (props: EYViewBoxProps) => {
  const {
    title,
    selectedColumn,
    setSelectedColumn,
    columnNum,
    formValues,
    expensesValues,
    resourcesValues,
    eyViewValues,
    setEyViewValues,
    inputResources,
    inputExpenses,
    planCurrency,
    planUnit
  } = props
  const [scenarioName, setScenarioName] = useState<string>('')
  //const scenarios = ['base', 'targetMargin', 'fixedFee', 'client','marketRate']
  //const scenarios = ['targetMargin', 'fixedFee', 'client', 'marketRate']
  const scenarios = ['marketRate', 'targetMargin', 'fixedFee', 'client']
  const [Initializedonshore, setInitializeOnshore] = useState<boolean>(false)
  const [DCRtechcost, setDCRtechcost] = useState<boolean>(false)
  const [FY1Adj, setFY1Adj] = useState<number>(0)
  const onBoxClick = () => {
    setSelectedColumn(columnNum)
  }

  useEffect(() => {
    // if (selectedColumn === 0) {
    //   setScenarioName('base')
    // }
    if (selectedColumn === 0) {
      //setScenarioName('targetMargin')
      setScenarioName('marketRate')
    } else if (selectedColumn === 1) {
      setScenarioName('targetMargin')
    } else if (selectedColumn === 2) {
      setScenarioName('fixedFee')
    } else if (selectedColumn === 3) {
      // setScenarioName('marketRate')

      setScenarioName('client')
    }
  }, [selectedColumn])
  let initialValuesEYView = {
    useTotalClientRateexclDCR: 0, //true,true
    useTotalClientRateDCR: 0, //true,false
    excluseTotalClientRateexclDCR: 0, //false,true
    excluseTotalClientRateDCR: 0, //false,false
    useTotalMarketRateexclDCR: 0,
    useTotalMarketRateDCR: 0
  }
  useEffect(() => {
    if (scenarioName !== '') {
      if (!Initializedonshore) {
        //if calling for first time
        CalculateonShoreDCRLabourCostPerfImp()
        setInitializeOnshore(true)
      }
    }
  }, [scenarioName])

  /*useEffect(() => {
    if (
      scenarioName !== '' &&
      inputResources !== undefined &&
      expensesValues[scenarioName].totalExpenses.totalExpenseBillings !== 0
    ) {
      console.log({ expensesValues })
      calculateTotalBillings()
      calculateCostOfSubcontractors()
      calculateTotalExpenses()
      calculateBillingExclExpenses()
      calculateTotalLaborCost()
      calculateDedicatedCost()
      calculateStandardMargin()
      calculateDCRMargin()
    }
  }, [expensesValues])
  */

  // Performs recalculations when values are updated
  useEffect(() => {
    if (scenarioName !== '' && inputResources !== undefined && planUnit !== '') {
      calculateTotalBillings()
      calculateCostOfSubcontractors()
      calculateTotalExpenses()
      calculateBillingExclExpenses()
      calculateTotalLaborCost()
      calculateDedicatedCost()
      calculateStandardMargin()
      calculateDCRMargin()
      calculateStandardMarginExclPNC()
      calculateDCRMarginExclPNC()
      calculateRatePerDay()
      calculateRatePerHour()
    }
  }, [scenarioName, formValues, resourcesValues, expensesValues, planUnit])

  // Calculates Resource Revenue
  // Formula: resource cost / (1- resource margin)
  // resource cost = Total Labour Cost column displayed on the Resources page
  // resource margin: from the assumptions boxes at the top of the page

  // source corresponds to a Resource's source field
  // marginRow values can be found in the EditableBox component
  /* --unused function
  const calculateResourceRevenue = (source: string, scenario: string, marginRow: string) => {
    let resourceRevenue = 0
    let costType = 'totalCost'

    if (scenario === 'client') costType = 'totalClientRate'

    for (let i = 0; i < inputResources.length; i++) {
      if (inputResources[i].source === source) {
        let resourceMargin = formValues[scenario].margin[marginRow] / 100
        let resourceCost = inputResources[i][costType]
        if (inputResources[i].promotedResource) {
          resourceCost += inputResources[i].promotedResource[costType]
        }
        resourceRevenue += resourceCost / (1 - resourceMargin)
      }
    }
    return resourceRevenue
  }
  */

  /* Unused function
  // Get the decimal representation of the contingecy percent
  // row can be found in the EditableBox component
  const getContingencyPercent = (scenario: string, row: string) => {
    const contingencyPercent = formValues[scenario].contingency[row] / 100
    return contingencyPercent
  }

  // Get the sum of recoverable expenses of a specified category
  // category can be found in the MobilityExpense and TechnologyExpense components
  const calculateRecoverableSum = (category: string) => {
    let recoverableSum = 0
    for (let i = 0; i < inputExpenses.length; i++) {
      if (inputExpenses[i].category === category && inputExpenses[i].recoverable === true) {
        recoverableSum += inputExpenses[i].cost
      }
    }
    return recoverableSum
  }
  */

  // Get the sum of all expenses of a specified category
  // category can be found in the MobilityExpense and TechnologyExpense components
  const calculateExpenseSum = (category: string) => {
    let expenseSum = 0
    for (let i = 0; i < inputExpenses.length; i++) {
      if (inputExpenses[i].category === category) {
        expenseSum += inputExpenses[i].cost
      }
    }
    return expenseSum
  }
  /*
  // Special calculation for Subcontractors row, LT Cost and ST Cost columns
  const calculateSubcontractorsCost = () => {
    const categories = ['subcontractors', 'alliance', 'consultants'] // Expense category from Expenses page
    let cost = Number(0)
    for (let i = 0; i < inputExpenses.length; i++) {
      if (categories.includes(inputExpenses[i].category)) {
        cost += inputExpenses[i].cost
      }
    }

    return Math.ceil(cost)
  }
*/
  const calculateRevenueSum = (category: string) => {
    let revenueSum = 0
    for (let i = 0; i < inputExpenses.length; i++) {
      if (inputExpenses[i].category === category) {
        if (inputExpenses[i].revenue) {
          revenueSum += inputExpenses[i].revenue
        }
      }
    }
    return revenueSum
  }

  const calculateTotalBillings = () => {
    for (let i = 0; i < 4; i++) {
      let totalBillings = 0
      if (scenarios[i] === 'fixedFee') {
        totalBillings = formValues.fixedFee.totalBilling
      } else {
        const totalResources =
          scenarios[i] === 'client' || scenarios[i] === 'marketRate'
            ? calculateClientDCRTotalResourcesFees(scenarios[i]) //calculateOnshoreDCRLaborCost(true, true)
            : resourcesValues[scenarios[i]].totalResources.totalFees
        const totalExpenses = expensesValues[scenarioName].totalExpenses.totalExpenseBillings
        const ctTechRevenue = calculateRevenueSum('ctProducts')

        totalBillings = Math.ceil(totalResources + totalExpenses + ctTechRevenue)
      }
      // Updates what is shown on the page immediately
      eyViewValues[scenarios[i]].totalBillings.inclContingency = totalBillings
      eyViewValues[scenarios[i]].totalBillings.exclContingency = totalBillings

      setEyViewValues({
        ...eyViewValues,
        [scenarios[i]]: {
          ...eyViewValues[scenarios[i]],
          totalBillings: {
            inclContingency: totalBillings,
            exclContingency: totalBillings
          }
        }
      })
    }
  }

  const calculateTotalExpenses = () => {
    for (let i = 0; i < 4; i++) {
      const totalExpenses = expensesValues[scenarios[i]].totalExpenses.ltCost
      const subcontractorExpenses = expensesValues[scenarios[i]].subcontractors.ltCost //expensesValues[scenarioName].subcontractors.ltCost
      const totalExpensesNoContingency = totalExpenses - subcontractorExpenses
      eyViewValues[scenarios[i]].totalExpenses.exclContingency = totalExpensesNoContingency

      const totalContingency = expensesValues[scenarioName].totalExpenses.contingency
      const subcontractorContingency = expensesValues[scenarioName].subcontractors.contingency
      const totalExpensesContingency =
        totalExpenses - subcontractorExpenses + totalContingency - subcontractorContingency
      eyViewValues[scenarios[i]].totalExpenses.inclContingency = totalExpensesContingency

      setEyViewValues({
        ...eyViewValues,
        [scenarios[i]]: {
          ...eyViewValues[scenarios[i]],
          totalExpenses: {
            inclContingency: totalExpensesContingency,
            exclContingency: totalExpensesNoContingency
          }
        }
      })
    }
  }

  const calculateBillingExclExpenses = () => {
    for (let i = 0; i < scenarios.length; i++) {
      const totalBillings = eyViewValues[scenarios[i]].totalBillings.exclContingency
      const totalExpenses = eyViewValues[scenarios[i]].totalExpenses.exclContingency
      const billingExclExpensesNoContingency = Math.ceil(totalBillings - totalExpenses)

      eyViewValues[scenarios[i]].billingExclExpenses.exclContingency = billingExclExpensesNoContingency

      const totalBillingsContingency = eyViewValues[scenarios[i]].totalBillings.inclContingency
      const totalExpensesContingency = eyViewValues[scenarios[i]].totalExpenses.inclContingency
      const billingExclExpensesContingency = Math.ceil(totalBillingsContingency - totalExpensesContingency)
      eyViewValues[scenarios[i]].billingExclExpenses.inclContingency = billingExclExpensesContingency

      setEyViewValues({
        ...eyViewValues,
        [scenarios[i]]: {
          ...eyViewValues[scenarios[i]],
          billingExclExpenses: {
            inclContingency: billingExclExpensesContingency,
            exclContingency: billingExclExpensesNoContingency
          }
        }
      })
    }
  }

  const calculateTotalLaborCost = () => {
    for (let i = 0; i < 4; i++) {
      let totalLaborCost = 0 // Excl. Contingency
      let totalLaborCostContingency = 0 // Incl. Contingency

      const onshoreLaborCost = resourcesValues[scenarios[i]].eyOnShore.laborCost
      const gdsLaborCost = resourcesValues[scenarios[i]].gds.laborCost
      const ctProductCost = calculateExpenseSum('ctProducts')
      const techCost = resourcesValues[scenarios[i]].totalResources.TechUpliftCost
      totalLaborCost = Math.ceil(onshoreLaborCost + gdsLaborCost + ctProductCost + techCost)

      const onshoreContingency = resourcesValues[scenarios[i]].eyOnShore.contingency
      const subcontractorContingency = resourcesValues[scenarios[i]].gds.contingency
      totalLaborCostContingency = Math.ceil(
        onshoreLaborCost + gdsLaborCost + onshoreContingency + subcontractorContingency + ctProductCost + techCost
      )

      eyViewValues[scenarios[i]].totalLaborCost.inclContingency = totalLaborCostContingency
      eyViewValues[scenarios[i]].totalLaborCost.exclContingency = totalLaborCost

      setEyViewValues({
        ...eyViewValues,
        [scenarios[i]]: {
          ...eyViewValues[scenarios[i]],
          laborCost: {
            inclContingency: totalLaborCostContingency,
            exclContingency: totalLaborCost
          }
        }
      })
    }
  }

  const calculateDedicatedCost = () => {
    for (let i = 0; i < 4; i++) {
      let dedicatedCostContingency = 0 // Incl. Contingency
      let dedicatedCost = 0 // Excl. Contingency

      const ltCostTotalResources = resourcesValues[scenarios[i]].totalResources.ltCost
      const ltCostSubcontractor = resourcesValues[scenarios[i]].subcontractors.ltCost
      dedicatedCost = Math.ceil(ltCostTotalResources - ltCostSubcontractor)

      const contingencyTotalResources = resourcesValues[scenarios[i]].totalResources.contingency
      const contingencySubcontractor = resourcesValues[scenarios[i]].subcontractors.contingency
      dedicatedCostContingency = Math.ceil(dedicatedCost + contingencyTotalResources - contingencySubcontractor)

      const onshoreDCRLaborCost = calculateOnshoreDCRLaborCost(false, false)
      // scenarios[i] === 'client' ? calculateOnshoreDCRLaborCost(true) : calculateOnshoreDCRLaborCost(false)
      const gdsDCRLaborCost = calculateGDSDCRLaborCost(false, false)
      //scenarios[i] === 'client' ? calculateGDSDCRLaborCost(true) : calculateGDSDCRLaborCost(false)
      const ctProductCost = calculateExpenseSum('ctProducts')
      const onshoreDCRContingency = calculateOnshoreDCRContingency(scenarios[i])
      const gdsDCRContingency = calculateGDSDCRContingency()
      const totalLaborContingency =
        onshoreDCRLaborCost + onshoreDCRContingency + gdsDCRLaborCost + gdsDCRContingency + ctProductCost

      eyViewValues[scenarios[i]].dedicatedCost.inclContingency = totalLaborContingency // dedicatedCostContingency
      eyViewValues[scenarios[i]].dedicatedCost.exclContingency = dedicatedCost

      setEyViewValues({
        ...eyViewValues,
        [scenarios[i]]: {
          ...eyViewValues[scenarios[i]],
          dedicatedCost: {
            inclContingency: dedicatedCostContingency,
            exclContingency: dedicatedCost
          }
        }
      })
    }
  }

  const calculateCostOfSubcontractors = () => {
    for (let i = 0; i < 4; i++) {
      let costOfSubcontractorsContingency = 0 // Incl. Contingency
      let costOfSubcontractors = 0 // Excl. Contingency

      const ltCostResourcesSubcontractors = resourcesValues[scenarios[i]].subcontractors.laborCost
      const ltCostExpensesSubcontractors = expensesValues[scenarios[i]].subcontractors.ltCost
      costOfSubcontractors = Math.ceil(ltCostResourcesSubcontractors + ltCostExpensesSubcontractors)

      const contingencyResourcesSubcontractors = resourcesValues[scenarios[i]].subcontractors.contingency
      const contingencyExpensesSubcontractors = expensesValues[scenarios[i]].subcontractors.contingency
      costOfSubcontractorsContingency = Math.ceil(
        costOfSubcontractors + contingencyExpensesSubcontractors + contingencyResourcesSubcontractors
      )

      eyViewValues[scenarios[i]].costOfSubcontractors.inclContingency = costOfSubcontractorsContingency
      eyViewValues[scenarios[i]].costOfSubcontractors.exclContingency = costOfSubcontractors

      setEyViewValues({
        ...eyViewValues,
        [scenarios[i]]: {
          ...eyViewValues[scenarios[i]],
          costOfSubcontractors: {
            inclContingency: costOfSubcontractorsContingency,
            exclContingency: costOfSubcontractors
          }
        }
      })
    }
  }

  const calculateStandardMargin = () => {
    for (let i = 0; i < 4; i++) {
      let standardMarginContingency = 0 // Incl. Contingency
      let standardMargin = 0 // Excl. Contingency

      const billingExclExpensesNoContingency = eyViewValues[scenarios[i]].billingExclExpenses.exclContingency
      const totalLaborNoContingency = eyViewValues[scenarios[i]].totalLaborCost.exclContingency
      const costOfSubsNoContingency = eyViewValues[scenarios[i]].costOfSubcontractors.exclContingency
      standardMargin =
        (billingExclExpensesNoContingency - totalLaborNoContingency - costOfSubsNoContingency) /
        (billingExclExpensesNoContingency - costOfSubsNoContingency)

      const billingExclExpensesContingency = eyViewValues[scenarios[i]].billingExclExpenses.inclContingency
      const totalLaborContingency = eyViewValues[scenarios[i]].totalLaborCost.inclContingency
      const costOfSubsContingency = eyViewValues[scenarios[i]].costOfSubcontractors.inclContingency
      standardMarginContingency =
        (billingExclExpensesContingency - totalLaborContingency - costOfSubsContingency) /
        (billingExclExpensesContingency - costOfSubsContingency)

      if (isNaN(standardMarginContingency) || !Number.isFinite(standardMarginContingency)) {
        // Prevent NaN error due to division by 0
        standardMarginContingency = 0
      }

      if (isNaN(standardMargin) || !Number.isFinite(standardMargin)) {
        // Prevent NaN error due to division by 0
        standardMargin = 0
      }

      eyViewValues[scenarios[i]].standardMargin.inclContingency = standardMarginContingency
      eyViewValues[scenarios[i]].standardMargin.exclContingency = standardMargin

      setEyViewValues({
        ...eyViewValues,
        [scenarios[i]]: {
          ...eyViewValues[scenarios[i]],
          standardMargin: {
            inclContingency: standardMarginContingency,
            exclContingency: standardMargin
          }
        }
      })
    }
  }
  const GetBusinessWorkingHours = (inputResource: any, costCountry: string) => {
    let perDayHours = 0
    if (inputResource.isGDS) {
      if (['United Kingdom'].includes(costCountry)) {
        perDayHours = 7
      } else if (['India'].includes(costCountry)) {
        perDayHours = 9
      } else perDayHours = 8
    } else {
      if (['Australia', 'New Zealand'].includes(costCountry)) {
        perDayHours = 7.5
      } else if (['United Kingdom'].includes(costCountry)) {
        perDayHours = 7
      } else {
        perDayHours = 8
      }
    }
    return perDayHours
  }

  const CalculateDCRConsecutivedays = (inputResource: any, perDayHours: number, isPromotedResourceExists: boolean) => {
    let consecutiveDaysCnt = 0

    let totalPerYearHours = 0
    let dcrEligible

    dcrEligible = Test5(inputResource, perDayHours, isPromotedResourceExists)
    let dcrTest5 = 0
    dcrTest5 = dcrEligible !== undefined ? dcrEligible : 0

    return dcrTest5
  }
  const Test5 = (inputResource: any, perDayHours: number, isPromotedResourceExists: boolean) => {
    let totalPerYearHours = 0
    let dcrEligible = 0
    let consecutiveDaysCnt = 0
    if (isPromotedResourceExists || inputResource.totalDays >= 227) {
      for (let wi = 0; wi < inputResource.weeklyTimeUnits.length; wi++) {
        totalPerYearHours = 0
        if (dcrEligible === 0) {
          let currentWeekDate = moment(inputResource.weeklyTimeUnits[wi].startDate)
          let cnt = 0
          for (let i = wi; i < inputResource.weeklyTimeUnits.length; i++) {
            cnt = cnt + 1
            if (cnt <= 53) {
              totalPerYearHours = totalPerYearHours + inputResource.weeklyTimeUnits[i].timeUnits
              consecutiveDaysCnt = totalPerYearHours / perDayHours
              if (consecutiveDaysCnt >= 227) {
                let daysCnt =
                  moment(inputResource.weeklyTimeUnits[i].startDate).diff(
                    moment(inputResource.weeklyTimeUnits[wi].startDate),
                    'days'
                  ) + 1
                dcrEligible = daysCnt <= 365 ? 1 : 0
                return consecutiveDaysCnt
                //break
              }
            } else {
              dcrEligible = 0
              // return consecutiveDaysCnt
              break
            }
            if (i === inputResource.weeklyTimeUnits.length - 1) {
              return consecutiveDaysCnt
            }
          }
        }
      }
    }
  }
  const CheckDCRCriteria = (inputResource: any, perDayHours: number) => {
    /*
    let perWeekStandardHrs = perDayHours * 5
    let consecutiveDaysCnt = 0
    let startMonth = moment(inputResource.startDate)
    let endMonth = inputResource.promotedResource ? moment(inputResource.promotedResource.endDate).add(1, 'days') : moment(inputResource.endDate).add(1, 'days')
    let monthsDiff = endMonth.diff(startMonth, 'years') * 12 + (endMonth.diff(startMonth, 'months') % 12)

    if (monthsDiff >= 12) {
      let firstWeekDays = 0,
        lastWeekDays = 0
      let resourceStartDate = moment(inputResource.startDate)
      let resourceEndDate = moment(inputResource.endDate)
      let firstWeekDataEndingDt = moment(inputResource.weeklyTimeUnits[0].startDate).add(5, 'days')
      let isFirstWeekFullAllocation = false
      firstWeekDays = firstWeekDataEndingDt.diff(resourceStartDate, 'days')
      let firstWeekTimeUnits = inputResource.weeklyTimeUnits[0].timeUnits
      isFirstWeekFullAllocation = firstWeekTimeUnits / perDayHours >= firstWeekDays ? true : false
      let lastWeekEndingIndex = inputResource.weeklyTimeUnits.length - 1
      let lastWeekDataEndingDt = moment(inputResource.weeklyTimeUnits[lastWeekEndingIndex].startDate)
      let lastweekdayscnt = resourceEndDate.diff(lastWeekDataEndingDt, 'days') + 1
      lastWeekDays = lastweekdayscnt > 5 ? 5 : lastweekdayscnt
      let lastWeekTimeUnits = inputResource.weeklyTimeUnits[lastWeekEndingIndex].timeUnits
      let isLastWeekFullAllocation = lastWeekTimeUnits / perDayHours >= lastWeekDays ? true : false

      consecutiveDaysCnt = isFirstWeekFullAllocation ? firstWeekDays : 0
      if (monthsDiff >= 12) {
        for (let wi = 1; wi < inputResource.weeklyTimeUnits.length; wi++) {
          let currentWeekDate = moment(inputResource.weeklyTimeUnits[wi].startDate)
          if (
            inputResource.weeklyTimeUnits[wi].timeUnits < perWeekStandardHrs &&
            consecutiveDaysCnt < 227 &&
            currentWeekDate.isBefore(lastWeekDataEndingDt)
          ) {
            consecutiveDaysCnt = 0
          } else if (
            inputResource.weeklyTimeUnits[wi].timeUnits >= perWeekStandardHrs ||
            currentWeekDate.isSame(lastWeekDataEndingDt)
          ) {
            if (currentWeekDate.isSame(lastWeekDataEndingDt)) {
              consecutiveDaysCnt = isLastWeekFullAllocation ? consecutiveDaysCnt + lastWeekDays : 0
            } else consecutiveDaysCnt = consecutiveDaysCnt + 5
          }
        }
      }
    }
    return monthsDiff >= 12 && consecutiveDaysCnt >= 227 ? true : false
    */
    let consecutiveDaysCnt = 0
    let startMonth = moment(inputResource.startDate)
    let endMonth = inputResource.promotedResource
      ? moment(inputResource.promotedResource.endDate).add(1, 'days')
      : moment(inputResource.endDate).add(1, 'days')
    let monthsDiff = endMonth.diff(startMonth, 'years') * 12 + (endMonth.diff(startMonth, 'months') % 12)

    let isPromotedResourceExists = false
    if (inputResource.promotedResource) {
      isPromotedResourceExists = true
    }
    consecutiveDaysCnt = CalculateDCRConsecutivedays(inputResource, perDayHours, isPromotedResourceExists)

    if (inputResource.promotedResource) {
      let PromotedPerDayHours = GetBusinessWorkingHours(inputResource.promotedResource, inputResource.costCountry)
      consecutiveDaysCnt += CalculateDCRConsecutivedays(
        inputResource.promotedResource,
        PromotedPerDayHours,
        isPromotedResourceExists
      )
    }
    if (consecutiveDaysCnt >= 227) {
      setDCRtechcost(true)
    }
    return consecutiveDaysCnt >= 227 ? true : false
  }

  const calculateOnshoreDCRLaborCost = (
    useTotalClientRate: boolean,
    useTotalMarketRate: boolean,
    exclDCR: boolean = false
  ) => {
    if (eyViewValues[scenarios[selectedColumn]].TotalonShoreDCRLabourCost !== undefined) {
      if (!useTotalClientRate && useTotalMarketRate && exclDCR) {
        if (eyViewValues[scenarios[selectedColumn]].TotalonShoreDCRLabourCost.useTotalMarketRateexclDCR !== 0)
          return eyViewValues[scenarios[selectedColumn]].TotalonShoreDCRLabourCost.useTotalMarketRateexclDCR
      } else if (!useTotalClientRate && useTotalMarketRate && !exclDCR) {
        if (eyViewValues[scenarios[selectedColumn]].TotalonShoreDCRLabourCost.useTotalMarketRateDCR !== 0)
          return eyViewValues[scenarios[selectedColumn]].TotalonShoreDCRLabourCost.useTotalMarketRateDCR
      }
      // else if (useTotalClientRate && !useTotalMarketRate && exclDCR) {
      //   if (eyViewValues[scenarios[selectedColumn]].TotalonShoreDCRLabourCost.useTotalClientRateexclDCR !== 0)
      //     return eyViewValues[scenarios[selectedColumn]].TotalonShoreDCRLabourCost.useTotalClientRateexclDCR
      // } else if (useTotalClientRate && !useTotalMarketRate && !exclDCR) {
      //   if (eyViewValues[scenarios[selectedColumn]].TotalonShoreDCRLabourCost.useTotalClientRateDCR !== 0)
      //     return eyViewValues[scenarios[selectedColumn]].TotalonShoreDCRLabourCost.useTotalClientRateDCR
      // }
      else if (!useTotalClientRate && !useTotalMarketRate && exclDCR) {
        if (eyViewValues[scenarios[selectedColumn]].TotalonShoreDCRLabourCost.excluseTotalClientRateexclDCR !== 0)
          return eyViewValues[scenarios[selectedColumn]].TotalonShoreDCRLabourCost.excluseTotalClientRateexclDCR
      } else if (!useTotalClientRate && !useTotalMarketRate && !exclDCR) {
        if (eyViewValues[scenarios[selectedColumn]].TotalonShoreDCRLabourCost.excluseTotalClientRateDCR !== 0)
          return eyViewValues[scenarios[selectedColumn]].TotalonShoreDCRLabourCost.excluseTotalClientRateDCR
      }
    }

    let newLaborCost = 0
    const fy1 = parseFloat(formValues.FY1InflationAdj)
    const fy2 = parseFloat(formValues.FY2InflationAdj)
    const fy3 = parseFloat(formValues.FY3InflationAdj)
    const fy4 = parseFloat(formValues.FY4InflationAdj)

    // Loop through each resource and check if it's of the desired source
    for (let i = 0; i < inputResources.length; i++) {
      if (inputResources[i].source === 'onshore') {
        // Check if the named resource has rank CSC or SUB (then they are a subcontractor and no longer counted as an onshore resource)
        if (
          inputResources[i].rank !== 'CSC' &&
          inputResources[i].rank !== 'SUB' &&
          inputResources[i].rank !== 'Subcontractor' &&
          inputResources[i].rank !== 'Client Serving Contractor'
        ) {
          // Check if resource has client rate inputted, it it doesn't then use the dedicated cost if applicable
          if (useTotalClientRate && !useTotalMarketRate) {
            //&& inputResources[i].totalClientRate !== 0

            if (inputResources[i].totalClientRate !== 0) {
              if (fy1 > 0 || fy2 > 0 || fy3 > 0 || fy4 > 0) {
                const onshoreCOLA = calculateTotalLabourCostDtls(
                  inputResources[i],
                  inputResources[i].startDate,
                  inputResources[i].endDate,
                  inputResources[i].clientRateHour,
                  fy1,
                  fy2,
                  fy3,
                  fy4
                )
                newLaborCost += onshoreCOLA
              } else newLaborCost += inputResources[i].totalClientRate
            } /* As per discussion with david TotalBillings should only consider Clientrate for Clientscenario
            else {
              newLaborCost += inputResources[i].totalStandardCost
            }*/
            if (inputResources[i].promotedResource) {
              // Account for promotions
              if (inputResources[i].promotedResource.totalClientRate !== 0) {
                if (fy1 > 0 || fy2 > 0 || fy3 > 0 || fy4 > 0) {
                  const promotedCOLA = calculateTotalLabourCostDtls(
                    inputResources[i].promotedResource,
                    inputResources[i].promotedResource.startDate,
                    inputResources[i].promotedResource.endDate,
                    inputResources[i].promotedResource.clientRateHour,
                    fy1,
                    fy2,
                    fy3,
                    fy4
                  )
                  newLaborCost += promotedCOLA
                } else newLaborCost += inputResources[i].promotedResource.totalClientRate
              } /* As per discussion with david TotalBillings should only consider Clientrate for Clientscenario
               else {
                newLaborCost += inputResources[i].promotedResource.totalStandardCost
              } */
            }
          } else if (!useTotalClientRate && useTotalMarketRate) {
            //&& inputResources[i].totalClientRate !== 0
            newLaborCost += inputResources[i].totalMarketRate
            // if (inputResources[i].totalMarketRate !== 0) {
            //   inputResources[i].totalMarketRate = 150
            //   newLaborCost += inputResources[i].totalMarketRate
            // }
            /* As per discussion with david TotalBillings should only consider Clientrate for Clientscenario
            else {
              newLaborCost += inputResources[i].totalStandardCost
            }*/
            if (inputResources[i].promotedResource) {
              // Account for promotions
              if (inputResources[i].promotedResource.totalMarketRate !== 0) {
                newLaborCost += inputResources[i].promotedResource.totalMarketRate
              } /* As per discussion with david TotalBillings should only consider Clientrate for Clientscenario
               else {
                newLaborCost += inputResources[i].promotedResource.totalStandardCost
              } */
            }
          } else if (!useTotalClientRate && !useTotalMarketRate) {
            let perDayHours = 0
            perDayHours = GetBusinessWorkingHours(inputResources[i], inputResources[i].costCountry)

            let isDCRCriteria = CheckDCRCriteria(inputResources[i], perDayHours)
            if (isDCRCriteria && inputResources[i].totalDedicatedCost !== 0 && !exclDCR) {
              newLaborCost += inputResources[i].totalDedicatedCost
              if (inputResources[i].promotedResource) {
                // Account for promotions
                if (inputResources[i].promotedResource.totalDedicatedCost !== 0) {
                  newLaborCost += inputResources[i].promotedResource.totalDedicatedCost
                } else {
                  newLaborCost += inputResources[i].promotedResource.totalStandardCost
                }
              }
            } else {
              newLaborCost += inputResources[i].totalStandardCost
              if (inputResources[i].promotedResource) {
                // Account for promotions
                newLaborCost += inputResources[i].promotedResource.totalStandardCost
              }
            }

            // setEyViewValues({
            //   ...eyViewValues,
            //   [inputResources[i]]: {
            //     ...eyViewValues[inputResources[i]],
            //     dcrEligible: isDCRCriteria
            //   }
            // })
          }
        }
      }
    }
    return newLaborCost
  }

  const calculateTotalLabourCostDtls = (
    rowData: any,
    dealStartDate: Date | null,
    dealEndDate: Date | null,
    rate: number = 0,
    fy1InflationRate: any,
    fy2InflationRate: any,
    fy3InflationRate: any,
    fy4InflationRate: any
  ) => {
    let IsweeklyTimeunits = rowData.hasOwnProperty('weeklyTimeUnits')
    const inflation: any = []
    inflation.push(0) //no inflation for current fy
    inflation.push(fy1InflationRate)
    inflation.push(fy2InflationRate)
    inflation.push(fy3InflationRate)
    inflation.push(fy4InflationRate)

    let yearArrayDetails: any = []
    let currentDate = new Date()

    let currentYear = new Date().getFullYear()
    let nextFYYear = currentYear + 1
    let prevYear = currentYear - 1
    let currentFYStartDate = moment(`${currentYear}-07-01`).format('YYYY-MM-DDT00:00:00')
    let currentFYEndDate = moment(`${currentYear}-07-01`).format('YYYY-MM-DDT00:00:00')

    let startYear = moment(dealStartDate).year() > currentYear ? currentYear : moment(dealStartDate).year()
    let yearRange = getRange(startYear, moment(dealEndDate).year())

    for (var i = 0; i < yearRange.length; i++) {
      if (i === 0) {
        if (moment(currentDate).isSameOrAfter(`${currentYear}-07-01`)) {
          currentFYStartDate = moment(`${currentYear}-07-01`).format('YYYY-MM-DDT00:00:00')
          currentFYEndDate = moment(`${nextFYYear}-06-30`).format('YYYY-MM-DDT00:00:00')
          yearArrayDetails = yearArrayDetails.concat(yearRange[i])
        } else {
          currentFYStartDate = moment(`${prevYear}-07-01`).format('YYYY-MM-DDT00:00:00')
          currentFYEndDate = moment(`${currentYear}-06-30`).format('YYYY-MM-DDT00:00:00')
          if (startYear >= currentYear) yearArrayDetails = yearArrayDetails.concat(yearRange[i])
          yearArrayDetails = yearArrayDetails.concat(yearRange[i])
        }
      } else yearArrayDetails = yearArrayDetails.concat(yearRange[i])
    }

    const yearsArray = yearArrayDetails //getRange(moment(dealStartDate).year(), moment(dealEndDate).year())
    const weeklyTimeUnitsPerYear = yearsArray?.reduce((result: any[], year: any, index: any) => {
      if (index === 0 && IsweeklyTimeunits) {
        /* result.push([
          ...rowData.weeklyTimeUnits.filter((item: any) => moment(item.startDate).isSameOrBefore(`${year}-06-30`))
        ]) */

        const resultINCurrentFY = [
          ...rowData.weeklyTimeUnits.filter((item: any) =>
            // moment(item.startDate).isSameOrAfter(currentFYStartDate) &&
            moment(item.startDate).isSameOrBefore(currentFYEndDate)
          )
        ]
        if (resultINCurrentFY.length > 0) {
          result.push(resultINCurrentFY)
        }
        if (resultINCurrentFY.length === 0) {
          result.push([])
        }
      }
      if (index > 0) {
        if (year > moment(currentFYStartDate).year()) {
          //year = moment(currentFYEndDate).year()
          let nextYear = parseInt(year) + 1
          let startRange = moment(`${nextYear - 1}-07-01`).format('YYYY-MM-DDT00:00:00')
          let endRange = moment(`${nextYear}-06-30`).format('YYYY-MM-DDT00:00:00')
          if (IsweeklyTimeunits && index > 0) {
            result.push([
              ...rowData.weeklyTimeUnits.filter(
                (item: any) =>
                  //moment(date).format('YYYY-MM-DDT00:00:00')
                  moment(item.startDate).isSameOrAfter(moment(startRange)) &&
                  moment(item.startDate).isSameOrBefore(moment(endRange))
              )
            ])
          }
        }
      }
      return result
    }, [])

    let a = rate
    let flag = false
    let temp = 0
    const totalLabourCost = weeklyTimeUnitsPerYear.reduce((result: number, item: any[], index: number) => {
      const hours = item.reduce((_result: number, _item: any) => {
        _result += _item.timeUnits
        return _result
      }, 0)
      //const standardCost = hours * rate

      function transferDataCurrentFY(weeklyData: any, date: any) {
        // Create a deep copy of weeklyData
        const copiedWeeklyData = JSON.parse(JSON.stringify(weeklyData))
        const targetDate = new Date(date)

        for (let i = 1; i < copiedWeeklyData.length; i++) {
          const remainingItems = []
          const itemsToTransfer = []

          for (let j = 0; j < copiedWeeklyData[i].length; j++) {
            const item = copiedWeeklyData[i][j]
            if (new Date(item.startDate) < targetDate) {
              itemsToTransfer.push(item)
            } else {
              remainingItems.push(item)
            }
          }

          // Transfer items to the first index
          copiedWeeklyData[0].push(...itemsToTransfer)

          // Update the current index with the remaining items
          copiedWeeklyData[i] = remainingItems
        }

        return copiedWeeklyData
      }

      function transferDataNextFY(weeklyData: any, targetDate: any) {
        // Create a deep copy of weeklyData
        const copiedWeeklyData = JSON.parse(JSON.stringify(weeklyData))
        const date = new Date(targetDate)
        let shouldShift = false

        // Check if the target date falls within the week range of index 0
        for (const item of copiedWeeklyData[0]) {
          const startDate = new Date(item.startDate)
          const endDate = new Date(startDate)
          endDate.setDate(endDate.getDate() + 6) // Assuming each week is 7 days long
          if (date < startDate) {
            shouldShift = true
            break
          }
        }

        const remainingItems = []
        let itemsToTransfer = []

        if (!shouldShift) {
          if (copiedWeeklyData[0].length == 0) {
            for (let i = 1; i < copiedWeeklyData.length; i++) {
              const currentItems = copiedWeeklyData[i]
              copiedWeeklyData[i] = itemsToTransfer
              itemsToTransfer = currentItems.slice() // Create a new array for itemsToTransfer
            }

            // If there are remaining items after shifting, add them as a new index
            if (itemsToTransfer.length > 0) {
              copiedWeeklyData.push(itemsToTransfer)
            }
          }
          return copiedWeeklyData // No shift needed
        }

        // Separate items in index 0 based on the target date
        for (const item of copiedWeeklyData[0]) {
          if (new Date(item.startDate) > date) {
            itemsToTransfer.push(item)
          } else {
            remainingItems.push(item)
          }
        }

        // Update index 0 with the remaining items
        copiedWeeklyData[0] = remainingItems
        for (let i = 1; i < copiedWeeklyData.length; i++) {
          const currentItems = copiedWeeklyData[i]
          copiedWeeklyData[i] = itemsToTransfer
          itemsToTransfer = currentItems.slice() // Create a new array for itemsToTransfer
        }

        // If there are remaining items after shifting, add them as a new index
        if (itemsToTransfer.length > 0) {
          copiedWeeklyData.push(itemsToTransfer)
        }

        return copiedWeeklyData
      }

      function calculateTotalHoursAtIndex(weeklyData: any, ind: number) {
        if (ind < 0 || ind >= weeklyData.length || !Array.isArray(weeklyData[ind]) || weeklyData[ind].length === 0) {
          return 0
        }

        return weeklyData[ind].reduce((totalHours: number, item: any) => {
          return totalHours + item.timeUnits
        }, 0)
      }

      let inflationRate = 0
      if (index <= 4) inflationRate = inflation[index] / 100
      else inflationRate = 0

      const standardCost = hours * rate
      let calInflationCost = index === 0 ? rate : a * (1 + inflationRate) // rowData.isGDS ? rate * Math.pow(1 + inflationRate, index):

      a = calInflationCost

      const inflationCost = inflationRate !== undefined ? hours * calInflationCost : 0 //rowData.isGDS ? hours * calInflationCost :

      const inflationCostRate = inflationCost - standardCost
      //if (rateType === 'totalClientRate') {
      //   result += standardCost
      // } else {
      result += standardCost + inflationCostRate
      // }

      return result
    }, 0)
    return totalLabourCost
  }

  const CalculateonShoreDCRLabourCostPerfImp = () => {
    let valuseTotalClientRateexclDCR = 0
    let valuseTotalClientRateDCR = 0
    let valexcluseTotalClientRateexclDCR = 0
    let valexcluseTotalClientRateDCR = 0
    let valuseTotalMarketRateexclDCR = 0
    let valuseTotalMarketRateDCR = 0

    valuseTotalClientRateexclDCR = calculateOnshoreDCRLaborCost(true, false, true)
    valuseTotalClientRateDCR = calculateOnshoreDCRLaborCost(true, false, false)
    valexcluseTotalClientRateexclDCR = calculateOnshoreDCRLaborCost(false, false, true)
    valexcluseTotalClientRateDCR = calculateOnshoreDCRLaborCost(false, false, false)
    valuseTotalMarketRateexclDCR = calculateOnshoreDCRLaborCost(false, true, true)
    valuseTotalMarketRateDCR = calculateOnshoreDCRLaborCost(false, true, false)

    let initialValuesEYView = {
      useTotalClientRateexclDCR: valuseTotalClientRateexclDCR, //true,true
      useTotalClientRateDCR: valuseTotalClientRateDCR, //true,false
      excluseTotalClientRateexclDCR: valexcluseTotalClientRateexclDCR, //false,true
      excluseTotalClientRateDCR: valexcluseTotalClientRateDCR, //false,false
      useTotalMarketRateexclDCR: valuseTotalMarketRateexclDCR,
      useTotalMarketRateDCR: valuseTotalMarketRateDCR
    }
    for (let i = 0; i < 4; i++) {
      eyViewValues[scenarios[i]].TotalonShoreDCRLabourCost = initialValuesEYView
      /*
      setEyViewValues({
        ...eyViewValues,
        [scenarios[i]]: {
          ...eyViewValues[scenarios[i]],
          TotalonShoreDCRLabourCost: {
            ...eyViewValues[scenarios[i]].TotalonshoreDCRLabourCost,
            initialValuesEYView
          }
        }
      })
      */
    }
  }

  const calculateGDSDCRLaborCost = (
    useTotalClientRate: boolean,
    useTotalMarketRate: boolean,
    exclDCR: boolean = false
  ) => {
    //DCR Logic
    const fy1 = parseFloat(formValues.FY1InflationAdj)
    const fy2 = parseFloat(formValues.FY2InflationAdj)
    const fy3 = parseFloat(formValues.FY3InflationAdj)
    const fy4 = parseFloat(formValues.FY4InflationAdj)
    let newLaborCost = 0
    // GDS

    for (let i = 0; i < inputResources.length; i++) {
      if (inputResources[i].source === 'gdsOffshore' || inputResources[i].source === 'gdsLanded') {
        if (useTotalClientRate && !useTotalMarketRate) {
          /*} && inputResources[i].totalClientRate !== 0) {
          newLaborCost += inputResources[i].totalClientRate
          if (inputResources[i].promotedResource) {
            // Account for promotions
            newLaborCost += inputResources[i].promotedResource.totalClientRate
          }
          */
          if (inputResources[i].totalClientRate !== 0) {
            if (fy1 > 0 || fy2 > 0 || fy3 > 0 || fy4 > 0) {
              const gdsCOLA = calculateTotalLabourCostDtls(
                inputResources[i],
                inputResources[i].startDate,
                inputResources[i].endDate,
                inputResources[i].clientRateHour,
                fy1,
                fy2,
                fy3,
                fy4
              )
              newLaborCost += gdsCOLA
            } else newLaborCost += inputResources[i].totalClientRate
          } /*As per discussion with david TotalBillings should only consider Clientrate for Clientscenario
          else {
            newLaborCost += inputResources[i].totalStandardCost
          }*/
          if (inputResources[i].promotedResource) {
            // Account for promotions
            if (inputResources[i].promotedResource.totalClientRate !== 0) {
              newLaborCost += inputResources[i].promotedResource.totalClientRate
            } /* As per discussion with david TotalBillings should only consider Clientrate for Clientscenario
            else {
              newLaborCost += inputResources[i].promotedResource.totalStandardCost
            } */
          }
        } else if (!useTotalClientRate && useTotalMarketRate) {
          if (inputResources[i].totalMarketRate !== 0) {
            newLaborCost += inputResources[i].totalMarketRate
          }
          if (inputResources[i].promotedResource) {
            if (inputResources[i].promotedResource.totalMarketRate !== 0) {
              newLaborCost += inputResources[i].promotedResource.totalMarketRate
            }
          }
        } else if (!useTotalClientRate && !useTotalMarketRate) {
          newLaborCost += inputResources[i].totalStandardCost
          if (inputResources[i].promotedResource) {
            newLaborCost += inputResources[i].promotedResource.totalStandardCost
          }
        }
      }
    }
    return newLaborCost
  }

  const calculateSubcontractorDCRLaborCost = (
    useTotalClientRate: boolean,
    useTotalMarketRate: boolean,
    exclDCR: boolean = false
  ) => {
    const fy1 = parseFloat(formValues.FY1InflationAdj)
    const fy2 = parseFloat(formValues.FY2InflationAdj)
    const fy3 = parseFloat(formValues.FY3InflationAdj)
    const fy4 = parseFloat(formValues.FY4InflationAdj)
    //DCR logic
    let newLaborCost = 0
    // Subcontractor
    for (let i = 0; i < inputResources.length; i++) {
      if (
        inputResources[i].source === 'subcontractor' ||
        inputResources[i].rank === 'CSC' ||
        inputResources[i].rank === 'SUB' ||
        inputResources[i].rank === 'Subcontractor' ||
        inputResources[i].rank === 'Client Serving Contractor'
      ) {
        if (useTotalClientRate && !useTotalMarketRate) {
          /*&& inputResources[i].totalClientRate !== 0) {
          newLaborCost += inputResources[i].totalClientRate
          if (inputResources[i].promotedResource) {
            // Account for promotions
            newLaborCost += inputResources[i].promotedResource.totalClientRate
          }
          */
          if (inputResources[i].totalClientRate !== 0) {
            if (fy1 > 0 || fy2 > 0 || fy3 > 0 || fy4 > 0) {
              const gdsCOLA = calculateTotalLabourCostDtls(
                inputResources[i],
                inputResources[i].startDate,
                inputResources[i].endDate,
                inputResources[i].clientRateHour,
                fy1,
                fy2,
                fy3,
                fy4
              )
              newLaborCost += gdsCOLA
            } else newLaborCost += inputResources[i].totalClientRate
          } /* As per discussion with david TotalBillings should only consider Clientrate for Clientscenario
          else {
            newLaborCost += inputResources[i].totalStandardCost
          }*/
          if (inputResources[i].promotedResource) {
            // Account for promotions
            if (inputResources[i].promotedResource.totalClientRate !== 0) {
              newLaborCost += inputResources[i].promotedResource.totalClientRate
            } /* As per discussion with david TotalBillings should only consider Clientrate for Clientscenario
            else {
              newLaborCost += inputResources[i].promotedResource.totalStandardCost
            } */
          }
        } else if (!useTotalClientRate && useTotalMarketRate) {
          if (inputResources[i].totalMarketRate !== 0) {
            newLaborCost += inputResources[i].totalMarketRate
          }
          if (inputResources[i].promotedResource) {
            // Account for promotions
            if (inputResources[i].promotedResource.totalMarketRate !== 0) {
              newLaborCost += inputResources[i].promotedResource.totalMarketRate
            }
          }
        } else if (!useTotalClientRate && !useTotalMarketRate) {
          /* For Subcontractors DCR rate checking not required
          let perDayHours
          perDayHours = GetBusinessWorkingHours(inputResources[i])
          let isDCRCriteria = CheckDCRCriteria(inputResources[i], perDayHours)
          // TODO: USE TOTALSTANDARDCOST IF TOTALDEDICATEDCOST = 0 --> assuming totalDedicatedCost = 0 if the DCR logic is not applied
          if (isDCRCriteria && inputResources[i].totalDedicatedCost !== 0 && !exclDCR) {
            //Here !exclDCR means DCR will be applicable.exclDCR = true in case even if DCR is applicable and we dont need DCR rate
            newLaborCost += inputResources[i].totalDedicatedCost
            if (inputResources[i].promotedResource) {
              if (inputResources[i].promotedResource.totalDedicatedCost !== 0) {
                newLaborCost += inputResources[i].promotedResource.totalDedicatedCost
              } else {
                newLaborCost += inputResources[i].promotedResource.totalStandardCost
              }
            }
          } else { */
          newLaborCost += inputResources[i].totalStandardCost
          if (inputResources[i].promotedResource) {
            newLaborCost += inputResources[i].promotedResource.totalStandardCost
          }
          /* } */
        }
      }
    }
    return newLaborCost
  }
  const calculateOnshoreDCRTechuplift = (scenario: string, exclDCR: boolean = false) => {
    const laborCost = calculateOnshoreDCRLaborCost(false, false, exclDCR)
    const techupliftcost = formValues.technologyCost / 100
    const dcrtechupliftcost = laborCost * techupliftcost
    return dcrtechupliftcost
  }

  const calculateGDSDCRTechuplift = (scenario: string, exclDCR: boolean = false) => {
    const laborCost = calculateGDSDCRLaborCost(false, false, exclDCR)
    const techupliftcost = formValues.technologyCost / 100
    const dcrtechupliftcost = laborCost * techupliftcost
    return dcrtechupliftcost
  }

  const calculateSubcontractorDCRTechuplift = (scenario: string, exclDCR: boolean = false) => {
    const laborCost = calculateSubcontractorDCRLaborCost(false, exclDCR)
    const techupliftcost = formValues.technologyCost / 100
    const dcrtechupliftcost = laborCost * techupliftcost
    return dcrtechupliftcost
  }

  const calculateOnshoreDCRContingency = (scenario: string, exclDCR: boolean = false) => {
    const laborCost = calculateOnshoreDCRLaborCost(false, false, exclDCR)
    /* scenario === 'client'
        ? calculateOnshoreDCRLaborCost(false, exclDCR)
        : calculateOnshoreDCRLaborCost(false, exclDCR) */
    const contingency = formValues[scenario].contingency.eyOnShore / 100 //formValues[scenarios[columnNum]].contingency.eyOnShore / 100
    const dcrContingency = laborCost * contingency
    return dcrContingency
  }

  const calculateGDSDCRContingency = (
    useTotalClientRate: boolean = false,
    useTotalMarketRate: boolean = false,
    exclDCR: boolean = false
  ) => {
    const laborCost = calculateGDSDCRLaborCost(useTotalClientRate, useTotalMarketRate, exclDCR)
    const contingency = formValues[scenarios[selectedColumn]].contingency.gdsOffshore / 100 //formValues[scenarios[columnNum]].contingency.gdsOffshore / 100 //This also may require change as above
    const dcrContingency = laborCost * contingency
    return dcrContingency
  }

  const calculateSubcontractorDCRContingency = (useTotalClientRate: boolean = false, exclDCR: boolean = false) => {
    const laborCost = calculateSubcontractorDCRLaborCost(useTotalClientRate, exclDCR)
    const contingency = formValues[scenarios[selectedColumn]].contingency.subcontractors / 100 //formValues[scenarios[columnNum]].contingency.subcontractors / 100 //This also may require change as above
    const dcrContingency = laborCost * contingency
    return dcrContingency
  }

  // Used for Target Margin scenario
  const calculateDCRTotalResourcesFees = (scenario: string) => {
    const laborCostOnshore = calculateOnshoreDCRLaborCost(false, false, true)
    const contingencyOnshore = calculateOnshoreDCRContingency(scenario, true)
    const techCostOnshore = calculateOnshoreDCRTechuplift(scenario, true)
    const marginInputOnshore = formValues[scenario].margin.eyOnShore / 100
    const totalFeesOnshore = Math.ceil(
      (laborCostOnshore + contingencyOnshore + techCostOnshore) / (1 - marginInputOnshore)
    )

    const laborCostGDS = calculateGDSDCRLaborCost(false, false, true)
    const contingencyGDS = calculateGDSDCRContingency(false, false, true) //calculateGDSDCRContingency(true)
    const techCostGDS = calculateGDSDCRTechuplift(scenario, true)
    const marginInputGDS = formValues[scenario].margin.gdsOffshore / 100
    const totalFeesGDS = Math.ceil((laborCostGDS + contingencyGDS + techCostGDS) / (1 - marginInputGDS))

    const laborCostSubcontractor = calculateSubcontractorDCRLaborCost(false, true)
    const contingencySubcontractor = calculateSubcontractorDCRContingency(false, true) //calculateSubcontractorDCRContingency(true)
    const techCostSubcontractor = calculateSubcontractorDCRTechuplift(scenario, true)
    const marginInputSubcontractor = formValues[scenario].margin.subcontractors / 100
    const totalFeesSubcontractor = Math.ceil(
      (laborCostSubcontractor + contingencySubcontractor + techCostSubcontractor) / (1 - marginInputSubcontractor)
    )

    const totalFees = totalFeesOnshore + totalFeesGDS + totalFeesSubcontractor
    return totalFees
  }

  // Use for Client Rate Card scenario for DCR Margin %
  const calculateClientDCRTotalResourcesFees = (scenario: string) => {
    const clientRateOnshore =
      scenario === 'client'
        ? calculateOnshoreDCRLaborCost(true, false, true)
        : calculateOnshoreDCRLaborCost(false, true, true)
    const clientRateGDS =
      scenario === 'client' ? calculateGDSDCRLaborCost(true, false) : calculateGDSDCRLaborCost(false, true)
    const clientRateSubcontractor =
      scenario === 'client'
        ? calculateSubcontractorDCRLaborCost(true, false)
        : calculateSubcontractorDCRLaborCost(false, true)
    const totalFees = clientRateOnshore + clientRateGDS + clientRateSubcontractor
    return totalFees
  }

  // Formula: [(Billings Excluding Expenses) - (Total Labor & CT Cost) - (Cost of Subs)] / [Billings Excluding Expenses]
  // Use DCR lower cost where applicable
  const calculateDCRMargin = () => {
    for (let i = 0; i < 4; i++) {
      let dcrMarginContingency = 0 // Incl. Contingency
      let dcrMargin = 0 // Excl. Contingency
      let totalResourcesFees = 0
      if (scenarios[i] === 'targetMargin') {
        totalResourcesFees = calculateDCRTotalResourcesFees(scenarios[i]) //resourcesValues[scenarios[i]].totalResources.totalFees
      } else if (scenarios[i] === 'client' || scenarios[i] === 'marketRate') {
        totalResourcesFees = calculateClientDCRTotalResourcesFees(scenarios[i])
      }
      const totalExpensesBillings = expensesValues[scenarios[i]].totalExpenses.totalExpenseBillings
      const ctProductRevenue = calculateRevenueSum('ctProducts')
      const techCost = resourcesValues[scenarios[i]].totalResources.TechUpliftCost
      const totalBillingAmount = totalResourcesFees + totalExpensesBillings + ctProductRevenue

      let billingExclExpensesNoContingency = 0
      if (scenarios[i] === 'fixedFee') {
        billingExclExpensesNoContingency = eyViewValues[scenarios[i]].billingExclExpenses.exclContingency
      } else {
        const totalExpenses = eyViewValues[scenarios[i]].totalExpenses.exclContingency
        billingExclExpensesNoContingency = totalBillingAmount - totalExpenses
      }

      const onshoreDCRLaborCost = calculateOnshoreDCRLaborCost(false, false)
      // scenarios[i] === 'client' ? calculateOnshoreDCRLaborCost(true) : calculateOnshoreDCRLaborCost(false)
      const gdsDCRLaborCost = calculateGDSDCRLaborCost(false, false)
      //scenarios[i] === 'client' ? calculateGDSDCRLaborCost(true) : calculateGDSDCRLaborCost(false)
      const ctProductCost = calculateExpenseSum('ctProducts')
      const techcost = !DCRtechcost ? techCost : 0
      const totalLabor = onshoreDCRLaborCost + gdsDCRLaborCost + ctProductCost + techcost

      const subcontractorDCRLaborCost = calculateSubcontractorDCRLaborCost(false, false)
      //scenarios[i] === 'client' ? calculateSubcontractorDCRLaborCost(true) : calculateSubcontractorDCRLaborCost(false)
      const subcontractorExpenses = expensesValues[scenarios[i]].subcontractors.ltCost
      const costOfSubs = subcontractorDCRLaborCost + subcontractorExpenses

      dcrMargin = Number.parseFloat(
        (
          (billingExclExpensesNoContingency - totalLabor - costOfSubs) /
          (billingExclExpensesNoContingency - costOfSubs)
        ).toFixed(5)
      )

      if (isNaN(dcrMargin) || !Number.isFinite(dcrMargin)) {
        // Prevent NaN error due to division by 0
        dcrMargin = 0
      }

      let billingExclExpensesContingency = 0
      if (scenarios[i] === 'fixedFee') {
        billingExclExpensesContingency = eyViewValues[scenarios[i]].billingExclExpenses.inclContingency
      } else {
        const totalExpenses = eyViewValues[scenarios[i]].totalExpenses.inclContingency
        billingExclExpensesContingency = totalBillingAmount - totalExpenses
      }

      const onshoreDCRContingency = calculateOnshoreDCRContingency(scenarios[i])
      const gdsDCRContingency = calculateGDSDCRContingency()
      const subcontractorDCRContingency = calculateSubcontractorDCRContingency()
      const subcontractorExpenseContingency = expensesValues[scenarios[i]].subcontractors.contingency
      const totalLaborContingency =
        onshoreDCRLaborCost + onshoreDCRContingency + gdsDCRLaborCost + gdsDCRContingency + ctProductCost + techcost
      const costOfSubsContingency =
        subcontractorDCRLaborCost +
        subcontractorDCRContingency +
        subcontractorExpenses +
        subcontractorExpenseContingency
      dcrMarginContingency = Number.parseFloat(
        (
          (billingExclExpensesContingency - totalLaborContingency - costOfSubsContingency) /
          (billingExclExpensesContingency - costOfSubsContingency)
        ).toFixed(5)
      )

      if (isNaN(dcrMarginContingency) || !Number.isFinite(dcrMarginContingency)) {
        // Prevent NaN error due to division by 0
        dcrMarginContingency = 0
      }

      eyViewValues[scenarios[i]].dcrMargin.inclContingency = dcrMarginContingency
      eyViewValues[scenarios[i]].dcrMargin.exclContingency = dcrMargin

      setEyViewValues({
        ...eyViewValues,
        [scenarios[i]]: {
          ...eyViewValues[scenarios[i]],
          dcrMargin: {
            inclContingency: dcrMarginContingency,
            exclContingency: dcrMargin
          }
        }
      })
    }
  }

  const calculateOnshoreExclPartnerLaborCost = (useTotalClientRate: boolean) => {
    let newLaborCost = 0
    // Loop through each resource and check if it's of the desired source
    for (let i = 0; i < inputResources.length; i++) {
      if (inputResources[i].source === 'onshore') {
        // Check if the named resource has rank Partner, CSC or SUB (then they are a subcontractor and no longer counted as an onshore resource)
        if (
          inputResources[i].rank !== 'CSC' &&
          inputResources[i].rank !== 'SUB' &&
          inputResources[i].rank !== 'Subcontractor' &&
          inputResources[i].rank !== 'Client Serving Contractor' &&
          inputResources[i].rank !== 'PT1' &&
          inputResources[i].rank !== 'PT2' &&
          inputResources[i].rank !== 'PT3' &&
          !inputResources[i].rank.includes('Partner')
        ) {
          // Client scenario: if no client rate then use standard cost but otherwise use client rate
          if (useTotalClientRate) {
            //&& inputResources[i].totalClientRate !== 0) {
            if (inputResources[i].totalClientRate !== 0) {
              newLaborCost += inputResources[i].totalClientRate
            } /* As per discussion with david TotalBillings should only consider Clientrate for Clientscenario
            else {
              newLaborCost += inputResources[i].totalStandardCost
            } */

            if (inputResources[i].promotedResource) {
              // Account for promotions

              if (
                inputResources[i].promotedResource.rank !== 'CSC' &&
                inputResources[i].promotedResource.rank !== 'SUB' &&
                inputResources[i].promotedResource.rank !== 'Subcontractor' &&
                inputResources[i].promotedResource.rank !== 'Client Serving Contractor' &&
                inputResources[i].promotedResource.rank !== 'PT1' &&
                inputResources[i].promotedResource.rank !== 'PT2' &&
                inputResources[i].promotedResource.rank !== 'PT3' &&
                !inputResources[i].promotedResource.rank.includes('Partner')
              ) {
                if (inputResources[i].promotedResource.totalClientRate !== 0) {
                  newLaborCost += inputResources[i].promotedResource.totalClientRate
                } /* As per discussion with david TotalBillings should only consider Clientrate for Clientscenario
                else {
                  newLaborCost += inputResources[i].promotedResource.totalStandardCost
                } */
              }
            }
          } else if (!useTotalClientRate) {
            newLaborCost += inputResources[i].totalStandardCost
            if (inputResources[i].promotedResource) {
              // Account for promotions

              if (
                inputResources[i].promotedResource.rank !== 'CSC' &&
                inputResources[i].promotedResource.rank !== 'SUB' &&
                inputResources[i].promotedResource.rank !== 'Subcontractor' &&
                inputResources[i].promotedResource.rank !== 'Client Serving Contractor' &&
                inputResources[i].promotedResource.rank !== 'PT1' &&
                inputResources[i].promotedResource.rank !== 'PT2' &&
                inputResources[i].promotedResource.rank !== 'PT3' &&
                !inputResources[i].promotedResource.rank.includes('Partner')
              ) {
                newLaborCost += inputResources[i].promotedResource.totalStandardCost
              }
            }
          }
        }
      }
    }
    return newLaborCost
  }

  const calculateOnshoreExclPartnerContingency = (useTotalClientRate: boolean = false) => {
    const laborCost = calculateOnshoreExclPartnerLaborCost(useTotalClientRate)
    const contingency = formValues[scenarios[selectedColumn]].contingency.eyOnShore / 100 //formValues[scenarios[columnNum]].contingency.eyOnShore / 100 //resourcesValues[scenarios[columnNum]].eyOnShore.contingency / 100
    const dcrContingency = laborCost * contingency
    return dcrContingency
  }
  /*
  const calculateClientOnshoreExclPartnerContingency = () => {
    const laborCost = calculateOnshoreExclPartnerLaborCost(true)
    const contingency = formValues[scenarios[selectedColumn]].contingency.eyOnShore / 100 //formValues[scenarios[columnNum]].contingency.eyOnShore / 100 //resourcesValues[scenarios[columnNum]].eyOnShore.contingency / 100
    const dcrContingency = laborCost * contingency
    return dcrContingency
  }
*/

  // Use for Target Margin Scenario
  const calculateExclPartnerTotalResourcesFees = (scenario: string) => {
    const laborCostOnshore = calculateOnshoreExclPartnerLaborCost(false)
    const contingencyOnshore = resourcesValues[scenario].eyOnShore.contingency
    const marginInputOnshore = formValues[scenario].margin.eyOnShore / 100
    const totalFeesOnshore = Math.ceil((laborCostOnshore + contingencyOnshore) / (1 - marginInputOnshore))

    const totalFeesGDS = resourcesValues[scenario].gds.totalFees

    const totalFeesSubcontractor = resourcesValues[scenario].subcontractors.totalFees

    const totalFees = totalFeesOnshore + totalFeesGDS + totalFeesSubcontractor
    return totalFees
  }

  // Use for Client Rate Card scenario for Excl Partner %
  const calculateClientExclPartnerTotalResourcesFees = () => {
    const clientRateOnshore = calculateOnshoreDCRLaborCost(true, false, true) //calculateOnshoreExclPartnerLaborCost(true) //
    const clientRateGDS = calculateGDSDCRLaborCost(true, false, true)
    const clientRateSubcontractor = calculateSubcontractorDCRLaborCost(true, false, true)
    const totalFees = clientRateOnshore + clientRateGDS + clientRateSubcontractor
    return totalFees
  }

  const calculateMarketExclPartnerTotalResourcesFees = () => {
    const clientRateOnshore = calculateOnshoreDCRLaborCost(false, true, true) //calculateOnshoreExclPartnerLaborCost(true) //
    const clientRateGDS = calculateGDSDCRLaborCost(false, true, true)
    const clientRateSubcontractor = calculateSubcontractorDCRLaborCost(false, true, true)
    const totalFees = clientRateOnshore + clientRateGDS + clientRateSubcontractor
    return totalFees
  }

  const calculateStandardMarginExclPNC = () => {
    for (let i = 0; i < 4; i++) {
      let standardMarginExclPNCContingency = 0 // Incl. Contingency
      let standardMarginExclPNC = 0 // Excl. Contingency

      let totalResourcesFees = 0
      if (scenarios[i] === 'targetMargin') {
        totalResourcesFees = calculateDCRTotalResourcesFees(scenarios[i]) //calculateExclPartnerTotalResourcesFees(scenarios[i])
      } else if (scenarios[i] === 'marketRate') {
        totalResourcesFees = calculateMarketExclPartnerTotalResourcesFees()
      } else {
        totalResourcesFees = calculateClientExclPartnerTotalResourcesFees()
      }

      const totalExpensesBillings = expensesValues[scenarios[i]].totalExpenses.totalExpenseBillings
      const ctProductRevenue = calculateRevenueSum('ctProducts')
      const techCost = resourcesValues[scenarios[i]].totalResources.TechUpliftCost
      const totalBillingAmount = totalResourcesFees + totalExpensesBillings + ctProductRevenue

      let billingExclExpensesNoContingency = 0
      if (scenarios[i] === 'fixedFee') {
        billingExclExpensesNoContingency = eyViewValues[scenarios[i]].billingExclExpenses.exclContingency
      } else {
        const totalExpenses = eyViewValues[scenarios[i]].totalExpenses.exclContingency
        // billingExclExpensesNoContingency = totalBillingAmount - totalExpenses
        billingExclExpensesNoContingency = totalBillingAmount - totalExpenses //totalBillingAmount - totalResourcesFees - totalExpenses
      }

      const onshoreLaborCost = //calculateOnshoreExclPartnerLaborCost(false)
        scenarios[i] === 'client'
          ? calculateOnshoreDCRLaborCost(false, false, true) //eyViewValues[scenarios[i]].totalLaborCost.exclContingency
          : scenarios[i] === 'marketRate'
          ? calculateOnshoreDCRLaborCost(false, false, true)
          : calculateOnshoreExclPartnerLaborCost(false)
      const gdsLaborCost = resourcesValues[scenarios[i]].gds.laborCost
      const ctProductCost = calculateExpenseSum('ctProducts')
      const totalLabor = onshoreLaborCost + gdsLaborCost + ctProductCost + techCost
      const costOfSubs = eyViewValues[scenarios[i]].costOfSubcontractors.exclContingency

      let ClientPartnerLabourCost = 0 // Explain here
      if (scenarios[i] === 'client' || scenarios[i] === 'marketRate') {
        let onshoreClientTotallaborcost = calculateOnshoreDCRLaborCost(false, false, true) //calculateOnshoreDCRLaborCost(false, false)
        let onshoreClientPartnerlabourcost = calculateOnshoreNoPartnerDCRLaborCost(false, true) //calculateOnshoreNoPartnerDCRLaborCost(false, false)
        ClientPartnerLabourCost = onshoreClientTotallaborcost - onshoreClientPartnerlabourcost
        if (ClientPartnerLabourCost === undefined) {
          ClientPartnerLabourCost = 0
        }
      }
      standardMarginExclPNC = Number.parseFloat(
        (
          (billingExclExpensesNoContingency - totalLabor - costOfSubs + ClientPartnerLabourCost) /
          (billingExclExpensesNoContingency - costOfSubs)
        ).toFixed(5)
      )

      let billingExclExpensesContingency = 0
      if (scenarios[i] === 'fixedFee') {
        billingExclExpensesContingency = eyViewValues[scenarios[i]].billingExclExpenses.inclContingency
      } else {
        const totalExpenses = eyViewValues[scenarios[i]].totalExpenses.inclContingency
        //billingExclExpensesContingency = totalBillingAmount - totalExpenses
        billingExclExpensesContingency = totalBillingAmount - totalExpenses //totalBillingAmount - totalLabor - totalExpenses
      }
      const onshoreContingency = //calculateOnshoreExclPartnerContingency()
        scenarios[i] === 'client' || scenarios[i] === 'marketRate'
          ? calculateOnshoreDCRContingency(scenarios[i], true) //eyViewValues[scenarios[i]].totalLaborCost.inclContingency
          : calculateOnshoreExclPartnerContingency()
      const gdsContingency = resourcesValues[scenarios[i]].gds.contingency //Need to check value here:Hari
      const totalLaborContingency =
        onshoreLaborCost + gdsLaborCost + onshoreContingency + gdsContingency + ctProductCost + techCost
      const costOfSubsContingency = eyViewValues[scenarios[i]].costOfSubcontractors.inclContingency

      let ClientPartnerLabourCostContingency = 0
      if (onshoreContingency !== undefined && onshoreContingency > 0)
        ClientPartnerLabourCostContingency = ClientPartnerLabourCost * (onshoreContingency / onshoreLaborCost)
      else if (gdsContingency !== undefined && gdsContingency > 0)
        ClientPartnerLabourCostContingency = ClientPartnerLabourCost * (gdsContingency / gdsLaborCost)
      standardMarginExclPNCContingency = Number.parseFloat(
        (
          (billingExclExpensesContingency -
            totalLaborContingency -
            costOfSubsContingency +
            (ClientPartnerLabourCost + ClientPartnerLabourCostContingency)) /
          (billingExclExpensesContingency - costOfSubsContingency)
        ).toFixed(5)
      )

      if (isNaN(standardMarginExclPNCContingency) || !Number.isFinite(standardMarginExclPNCContingency)) {
        // Prevent NaN error due to division by 0
        standardMarginExclPNCContingency = 0
      }

      if (isNaN(standardMarginExclPNC) || !Number.isFinite(standardMarginExclPNC)) {
        // Prevent NaN error due to division by 0
        standardMarginExclPNC = 0
      }

      eyViewValues[scenarios[i]].standardMarginExclPNC.inclContingency = standardMarginExclPNCContingency
      eyViewValues[scenarios[i]].standardMarginExclPNC.exclContingency = standardMarginExclPNC

      setEyViewValues({
        ...eyViewValues,
        [scenarios[i]]: {
          ...eyViewValues[scenarios[i]],
          standardMarginExclPNC: {
            inclContingency: standardMarginExclPNCContingency,
            exclContingency: standardMarginExclPNC
          }
        }
      })
    }
  }

  const calculateOnshoreNoPartnerDCRLaborCost = (useTotalClientRate: boolean, exclDCR: boolean = false) => {
    //DCR logic
    let newLaborCost = 0
    // Loop through each resource and check if it's of the desired source
    for (let i = 0; i < inputResources.length; i++) {
      if (inputResources[i].source === 'onshore') {
        // Check if the named resource has rank CSC or SUB (then they are a subcontractor and no longer counted as an onshore resource)
        if (
          inputResources[i].rank !== 'CSC' &&
          inputResources[i].rank !== 'SUB' &&
          inputResources[i].rank !== 'Subcontractor' &&
          inputResources[i].rank !== 'Client Serving Contractor' &&
          inputResources[i].rank !== 'PT1' &&
          inputResources[i].rank !== 'PT2' &&
          inputResources[i].rank !== 'PT3' &&
          !inputResources[i].rank.includes('Partner')
        ) {
          if (useTotalClientRate) {
            //&& ) {
            if (inputResources[i].totalClientRate !== 0) {
              newLaborCost += inputResources[i].totalClientRate
            } /* As per discussion with david TotalBillings should only consider Clientrate for Clientscenario
            else {
              newLaborCost += inputResources[i].totalStandardCost
            }*/
            if (inputResources[i].promotedResource) {
              // Account for promotions

              if (
                inputResources[i].promotedResource.rank !== 'CSC' &&
                inputResources[i].promotedResource.rank !== 'SUB' &&
                inputResources[i].promotedResource.rank !== 'Subcontractor' &&
                inputResources[i].promotedResource.rank !== 'Client Serving Contractor' &&
                inputResources[i].promotedResource.rank !== 'PT1' &&
                inputResources[i].promotedResource.rank !== 'PT2' &&
                inputResources[i].promotedResource.rank !== 'PT3' &&
                !inputResources[i].promotedResource.rank.includes('Partner')
              ) {
                if (inputResources[i].promotedResource.totalClientRate !== 0) {
                  newLaborCost += inputResources[i].promotedResource.totalClientRate
                } /* As per discussion with david TotalBillings should only consider Clientrate for Clientscenario
                else {
                  newLaborCost += inputResources[i].promotedResource.totalStandardCost
                } */
              }
            }
          } else if (!useTotalClientRate) {
            let perDayHours = 0
            perDayHours = GetBusinessWorkingHours(inputResources[i], inputResources[i].costCountry)

            let isDCRCriteria = CheckDCRCriteria(inputResources[i], perDayHours)
            // TODO: USE TOTALSTANDARDCOST IF TOTALDEDICATEDCOST = 0 --> assuming totalDedicatedCost = 0 if the DCR logic is not applied
            if (isDCRCriteria && inputResources[i].totalDedicatedCost !== 0 && !exclDCR) {
              newLaborCost += inputResources[i].totalDedicatedCost
              if (inputResources[i].promotedResource) {
                // Account for promotions

                if (
                  inputResources[i].promotedResource.rank !== 'CSC' &&
                  inputResources[i].promotedResource.rank !== 'SUB' &&
                  inputResources[i].promotedResource.rank !== 'Subcontractor' &&
                  inputResources[i].promotedResource.rank !== 'Client Serving Contractor' &&
                  inputResources[i].promotedResource.rank !== 'PT1' &&
                  inputResources[i].promotedResource.rank !== 'PT2' &&
                  inputResources[i].promotedResource.rank !== 'PT3' &&
                  !inputResources[i].promotedResource.rank.includes('Partner')
                ) {
                  if (inputResources[i].promotedResource.totalDedicatedCost !== 0) {
                    newLaborCost += inputResources[i].promotedResource.totalDedicatedCost
                  } else {
                    newLaborCost += inputResources[i].promotedResource.totalStandardCost
                  }
                }
              }
            } else {
              newLaborCost += inputResources[i].totalStandardCost
              if (inputResources[i].promotedResource) {
                // Account for promotions
                if (
                  inputResources[i].promotedResource.rank !== 'CSC' &&
                  inputResources[i].promotedResource.rank !== 'SUB' &&
                  inputResources[i].promotedResource.rank !== 'Subcontractor' &&
                  inputResources[i].promotedResource.rank !== 'Client Serving Contractor' &&
                  inputResources[i].promotedResource.rank !== 'PT1' &&
                  inputResources[i].promotedResource.rank !== 'PT2' &&
                  inputResources[i].promotedResource.rank !== 'PT3' &&
                  !inputResources[i].promotedResource.rank.includes('Partner')
                ) {
                  newLaborCost += inputResources[i].promotedResource.totalStandardCost
                }
              }
            }
          }
        }
      }
    }
    return newLaborCost
  }

  const calculateOnshoreNoPartnerDCRContingency = (useTotalClientRate: boolean = false, exclDCR: boolean = false) => {
    const laborCost = calculateOnshoreNoPartnerDCRLaborCost(useTotalClientRate, exclDCR)
    const contingency = formValues[scenarios[selectedColumn]].contingency.eyOnShore / 100 //formValues[scenarios[columnNum]].contingency.eyOnShore / 100
    const dcrContingency = laborCost * contingency
    return dcrContingency
  }

  // Used for Target Margin scenario
  const calculateDCRExclPartnerTotalResourcesFees = (scenario: string) => {
    const laborCostOnshore = calculateOnshoreNoPartnerDCRLaborCost(false, true)
    const contingencyOnshore = calculateOnshoreNoPartnerDCRContingency(true)
    const marginInputOnshore = formValues[scenario].margin.eyOnShore / 100
    const totalFeesOnshore = Math.ceil((laborCostOnshore + contingencyOnshore) / (1 - marginInputOnshore))

    const laborCostGDS = calculateGDSDCRLaborCost(false, false, true)
    const contingencyGDS = calculateGDSDCRContingency(true)
    const marginInputGDS = formValues[scenario].margin.gdsOffshore / 100
    const totalFeesGDS = Math.ceil((laborCostGDS + contingencyGDS) / (1 - marginInputGDS))

    const laborCostSubcontractor = calculateSubcontractorDCRLaborCost(false, true)
    const contingencySubcontractor = calculateSubcontractorDCRContingency(true)
    const marginInputSubcontractor = formValues[scenario].margin.subcontractors / 100
    const totalFeesSubcontractor = Math.ceil(
      (laborCostSubcontractor + contingencySubcontractor) / (1 - marginInputSubcontractor)
    )

    const totalFees = totalFeesOnshore + totalFeesGDS + totalFeesSubcontractor
    return totalFees
  }

  // Use for Client Rate Card scenario for Excl Partner %
  const calculateClientDCRExclPartnerTotalResourcesFees = () => {
    const clientRateOnshore = calculateOnshoreDCRLaborCost(true, false, true) //calculateOnshoreNoPartnerDCRLaborCost(true, true)
    const clientRateGDS = calculateGDSDCRLaborCost(true, false, true)
    const clientRateSubcontractor = calculateSubcontractorDCRLaborCost(true, false)
    const totalFees = clientRateOnshore + clientRateGDS + clientRateSubcontractor
    return totalFees
  }
  const calculateMarketDCRExclPartnerTotalResourcesFees = () => {
    const clientRateOnshore = calculateOnshoreDCRLaborCost(false, true, true) //calculateOnshoreNoPartnerDCRLaborCost(true, true)
    const clientRateGDS = calculateGDSDCRLaborCost(false, true, true)
    const clientRateSubcontractor = calculateSubcontractorDCRLaborCost(false, true)
    const totalFees = clientRateOnshore + clientRateGDS + clientRateSubcontractor
    return totalFees
  }

  const calculateDCRMarginExclPNC = () => {
    for (let i = 0; i < 4; i++) {
      let dcrMarginExclPNCContingency = 0 // Incl. Contingency
      let dcrMarginExclPNC = 0 // Excl. Contingency

      let totalResourcesFees = 0
      if (scenarios[i] === 'targetMargin') {
        totalResourcesFees = calculateDCRTotalResourcesFees(scenarios[i]) //calculateDCRExclPartnerTotalResourcesFees(scenarios[i]) //calculateExclPartnerTotalResourcesFees(scenarios[i])
      } else if (scenarios[i] === 'marketRate') {
        totalResourcesFees = calculateMarketDCRExclPartnerTotalResourcesFees()
      } else {
        totalResourcesFees = calculateClientDCRExclPartnerTotalResourcesFees() //Need to check for Clientscenario:Hari
      }
      const totalExpensesBillings = expensesValues[scenarios[i]].totalExpenses.totalExpenseBillings
      const ctProductRevenue = calculateRevenueSum('ctProducts')
      const techCost = resourcesValues[scenarios[i]].totalResources.TechUpliftCost
      const totalBillingAmount = totalResourcesFees + totalExpensesBillings + ctProductRevenue

      let billingExclExpensesNoContingency = 0
      if (scenarios[i] === 'fixedFee') {
        billingExclExpensesNoContingency = eyViewValues[scenarios[i]].billingExclExpenses.exclContingency
      } else {
        const totalExpenses = eyViewValues[scenarios[i]].totalExpenses.exclContingency
        billingExclExpensesNoContingency = totalBillingAmount - totalExpenses
      }

      const onshoreDCRLaborCost = //calculateOnshoreNoPartnerDCRLaborCost(false) //calculateOnshoreDCRLaborCost(false)
        scenarios[i] === 'client' || scenarios[i] === 'marketRate'
          ? calculateOnshoreDCRLaborCost(false, false)
          : calculateOnshoreNoPartnerDCRLaborCost(false)
      const gdsDCRLaborCost = calculateGDSDCRLaborCost(false, false)
      // scenarios[i] === 'client' ? calculateGDSDCRLaborCost(true) : calculateGDSDCRLaborCost(false)
      const ctProductCost = calculateExpenseSum('ctProducts')
      const techcost = !DCRtechcost ? techCost : 0
      const totalLabor = onshoreDCRLaborCost + gdsDCRLaborCost + ctProductCost + techcost

      const subcontractorDCRLaborCost = calculateSubcontractorDCRLaborCost(false, false)
      //scenarios[i] === 'client' ? calculateSubcontractorDCRLaborCost(true) : calculateSubcontractorDCRLaborCost(false)
      const subcontractorExpenses = expensesValues[scenarios[i]].subcontractors.ltCost
      const costOfSubs = subcontractorDCRLaborCost + subcontractorExpenses
      let ClientPartnerLabourCost = 0
      if (scenarios[i] === 'client' || scenarios[i] === 'marketRate') {
        let onshoreClientTotallaborcost = calculateOnshoreDCRLaborCost(false, false)
        let onshoreClientPartnerlabourcost = calculateOnshoreNoPartnerDCRLaborCost(false)
        ClientPartnerLabourCost = onshoreClientTotallaborcost - onshoreClientPartnerlabourcost
        if (ClientPartnerLabourCost === undefined) {
          ClientPartnerLabourCost = 0
        }
      }

      dcrMarginExclPNC = Number.parseFloat(
        (
          (billingExclExpensesNoContingency - totalLabor - costOfSubs + ClientPartnerLabourCost) /
          (billingExclExpensesNoContingency - costOfSubs)
        ).toFixed(5)
      )

      if (isNaN(dcrMarginExclPNC) || !Number.isFinite(dcrMarginExclPNC)) {
        // Prevent NaN error due to division by 0
        dcrMarginExclPNC = 0
      }

      let billingExclExpensesContingency = 0
      if (scenarios[i] === 'fixedFee') {
        billingExclExpensesContingency = eyViewValues[scenarios[i]].billingExclExpenses.inclContingency
      } else {
        const totalExpenses = eyViewValues[scenarios[i]].totalExpenses.inclContingency
        billingExclExpensesContingency = totalBillingAmount - totalExpenses
      }

      const onshoreDCRContingency = //calculateOnshoreDCRContingency(scenarios[i]) // calculateOnshoreNoPartnerDCRContingency()
        scenarios[i] === 'client' || scenarios[i] === 'marketRate'
          ? calculateOnshoreDCRContingency(scenarios[i])
          : calculateOnshoreNoPartnerDCRContingency()
      const gdsDCRContingency = calculateGDSDCRContingency()
      //scenarios[i] === 'client' ? calculateGDSDCRContingency(true) : calculateGDSDCRContingency()
      const subcontractorDCRContingency = calculateSubcontractorDCRContingency()
      // scenarios[i] === 'client' ? calculateSubcontractorDCRContingency(true) : calculateSubcontractorDCRContingency()
      const subcontractorExpenseContingency = expensesValues[scenarios[i]].subcontractors.contingency
      const totalLaborContingency =
        onshoreDCRLaborCost + onshoreDCRContingency + gdsDCRLaborCost + gdsDCRContingency + ctProductCost + techcost
      const costOfSubsContingency =
        subcontractorDCRLaborCost +
        subcontractorDCRContingency +
        subcontractorExpenses +
        subcontractorExpenseContingency

      let ClientPartnerLabourCostContingency = 0
      if (onshoreDCRContingency !== undefined && onshoreDCRContingency > 0)
        ClientPartnerLabourCostContingency = ClientPartnerLabourCost * (onshoreDCRContingency / onshoreDCRLaborCost)
      else if (gdsDCRContingency !== undefined && gdsDCRContingency > 0)
        ClientPartnerLabourCostContingency = ClientPartnerLabourCost * (gdsDCRContingency / gdsDCRLaborCost)

      dcrMarginExclPNCContingency = Number.parseFloat(
        (
          (billingExclExpensesContingency -
            totalLaborContingency -
            costOfSubsContingency +
            (ClientPartnerLabourCost + ClientPartnerLabourCostContingency)) /
          (billingExclExpensesContingency - costOfSubsContingency)
        ).toFixed(5)
      )

      if (isNaN(dcrMarginExclPNCContingency) || !Number.isFinite(dcrMarginExclPNCContingency)) {
        // Prevent NaN error due to division by 0
        dcrMarginExclPNCContingency = 0
      }

      eyViewValues[scenarios[i]].dcrMarginExclPNC.inclContingency = dcrMarginExclPNCContingency
      eyViewValues[scenarios[i]].dcrMarginExclPNC.exclContingency = dcrMarginExclPNC

      setEyViewValues({
        ...eyViewValues,
        [scenarios[i]]: {
          ...eyViewValues[scenarios[i]],
          dcrMarginExclPNC: {
            inclContingency: dcrMarginExclPNCContingency,
            exclContingency: dcrMarginExclPNC
          }
        }
      })
    }
  }

  const calculateRatePerDay = () => {
    for (let i = 0; i < 4; i++) {
      let ratePerDayContingency = 0 // Incl. Contingency
      let ratePerDay = 0 // Excl. Contingency

      let onshoreDays = 0
      let gdsDays = 0
      let subcontractorDays = 0

      for (let i = 0; i < inputResources.length; i++) {
        if (inputResources[i].source === 'onshore' || inputResources[i].employee.trim() !== 'Unnamed') {
          // Check if the named resource has rank CSC or SUB (then they are a subcontractor and no longer counted as an onshore resource)
          if (
            inputResources[i].rank !== 'CSC' &&
            inputResources[i].rank !== 'SUB' &&
            inputResources[i].rank !== 'Subcontractor' &&
            inputResources[i].rank !== 'Client Serving Contractor'
          ) {
            onshoreDays += inputResources[i].totalDays
          }
        } else if (inputResources[i].source === 'gdsOffshore' || inputResources[i].source === 'gdsLanded') {
          gdsDays += inputResources[i].totalDays
        }
        // TODO KY David's calcs seem to include contractors
        else if (
          inputResources[i].source === 'subcontractor' ||
          inputResources[i].rank === 'CSC' ||
          inputResources[i].rank === 'SUB' ||
          inputResources[i].rank === 'Subcontractor' ||
          inputResources[i].rank === 'Client Serving Contractor'
        ) {
          subcontractorDays += inputResources[i].totalDays
        }
      }

      const totalResourceDays = onshoreDays + gdsDays + subcontractorDays

      const onshoreDaysContingency = onshoreDays + onshoreDays * (formValues[scenarios[i]].contingency.eyOnShore / 100)
      const gdsDaysContingency = gdsDays + gdsDays * (formValues[scenarios[i]].contingency.gdsOffshore / 100)
      const subcontractorDaysContingency =
        subcontractorDays + subcontractorDays * (formValues[scenarios[i]].contingency.subcontractors / 100)

      const totalResourceDaysContingency = onshoreDaysContingency + gdsDaysContingency + subcontractorDaysContingency

      const billingExclExpenses = eyViewValues[scenarios[i]].billingExclExpenses.exclContingency
      const billingExclExpensesContingency = eyViewValues[scenarios[i]].billingExclExpenses.inclContingency

      ratePerDay = Math.ceil(billingExclExpenses / totalResourceDays)

      if (isNaN(ratePerDay) || !Number.isFinite(ratePerDay)) {
        ratePerDay = 0
      }

      ratePerDayContingency = Math.ceil(billingExclExpensesContingency / totalResourceDaysContingency)

      if (isNaN(ratePerDayContingency) || !Number.isFinite(ratePerDayContingency)) {
        ratePerDayContingency = 0
      }

      eyViewValues[scenarios[i]].ratePerDay.inclContingency = ratePerDayContingency
      eyViewValues[scenarios[i]].ratePerDay.exclContingency = ratePerDay

      setEyViewValues({
        ...eyViewValues,
        [scenarios[i]]: {
          ...eyViewValues[scenarios[i]],
          ratePerDay: {
            inclContingency: ratePerDayContingency,
            exclContingency: ratePerDay
          }
        }
      })
    }
  }
  const getRange = (start: number, end: number) => {
    return Array(end - start + 1)
      .fill(0)
      .map((_, idx) => start + idx)
  }

  const calculateRatePerHour = () => {
    for (let i = 0; i < 4; i++) {
      let ratePerHourContingency = 0 // Incl. Contingency
      let ratePerHour = 0 // Excl. Contingency

      let onshoreHours = 0
      let gdsHours = 0
      let subcontractorHours = 0
      for (let i = 0; i < inputResources.length; i++) {
        if (inputResources[i].source === 'onshore' || inputResources[i].employee.trim() !== 'Unnamed') {
          // Check if the named resource has rank CSC or SUB (then they are a subcontractor and no longer counted as an onshore resource)
          if (
            inputResources[i].rank !== 'CSC' &&
            inputResources[i].rank !== 'SUB' &&
            inputResources[i].rank !== 'Subcontractor' &&
            inputResources[i].rank !== 'Client Serving Contractor'
          ) {
            onshoreHours += inputResources[i].totalHours
          }
        } else if (inputResources[i].source === 'gdsOffshore' || inputResources[i].source === 'gdsLanded') {
          gdsHours += inputResources[i].totalHours
        }
        // TODO KY David's calcs seem to include contractors, put them in onshore
        else if (
          inputResources[i].source === 'subcontractor' ||
          inputResources[i].rank === 'CSC' ||
          inputResources[i].rank === 'SUB' ||
          inputResources[i].rank === 'Subcontractor' ||
          inputResources[i].rank === 'Client Serving Contractor'
        ) {
          subcontractorHours += inputResources[i].totalHours
        }
      }

      const totalResourceHours = onshoreHours + gdsHours + subcontractorHours

      const onshoreHoursContingency =
        onshoreHours + onshoreHours * (formValues[scenarios[i]].contingency.eyOnShore / 100)
      const gdsHoursContingency = gdsHours + gdsHours * (formValues[scenarios[i]].contingency.gdsOffshore / 100)
      const subcontractorHoursContingency =
        subcontractorHours + subcontractorHours * (formValues[scenarios[i]].contingency.subcontractors / 100)

      const totalResourceHoursContingency =
        onshoreHoursContingency + gdsHoursContingency + subcontractorHoursContingency

      const billingExclExpenses = eyViewValues[scenarios[i]].billingExclExpenses.exclContingency
      const billingExclExpensesContingency = eyViewValues[scenarios[i]].billingExclExpenses.inclContingency

      ratePerHourContingency = Math.ceil(billingExclExpensesContingency / totalResourceHoursContingency)

      if (isNaN(ratePerHourContingency) || !Number.isFinite(ratePerHourContingency)) {
        ratePerHourContingency = 0
      }

      ratePerHour = Math.ceil(billingExclExpenses / totalResourceHours)

      if (isNaN(ratePerHour) || !Number.isFinite(ratePerHour)) {
        ratePerHour = 0
      }

      eyViewValues[scenarios[i]].ratePerHour.inclContingency = ratePerHourContingency
      eyViewValues[scenarios[i]].ratePerHour.exclContingency = ratePerHour

      setEyViewValues({
        ...eyViewValues,
        [scenarios[i]]: {
          ...eyViewValues[scenarios[i]],
          ratePerHour: {
            inclContingency: ratePerHourContingency,
            exclContingency: ratePerHour
          }
        }
      })
    }
  }

  return (
    <div
      className={`eyview-box-container ${selectedColumn === columnNum ? 'eyview-selected' : ''}`}
      onClick={onBoxClick}
    >
      <h3 className='heading-three'>{title}</h3>
      <table className='eyview-box-table'>
        <thead>
          <tr>
            <th className='body-text eyview-box-header'>Including Contingency</th>
            <th className='body-text eyview-box-header'>Excluding Contingency</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className='body-text'>
              {numberWithCommas(eyViewValues[scenarios[columnNum]].totalBillings.inclContingency) + ' ' + planCurrency}
            </td>
            <td className='body-text'>
              {numberWithCommas(eyViewValues[scenarios[columnNum]].totalBillings.exclContingency) + ' ' + planCurrency}
            </td>
          </tr>
          <tr>
            <td className='body-text'>
              {numberWithCommas(eyViewValues[scenarios[columnNum]].totalExpenses.inclContingency) + ' ' + planCurrency}
            </td>
            <td className='body-text'>
              {numberWithCommas(eyViewValues[scenarios[columnNum]].totalExpenses.exclContingency) + ' ' + planCurrency}
            </td>
          </tr>
          <tr>
            <td className='body-text'>
              {numberWithCommas(eyViewValues[scenarios[columnNum]].billingExclExpenses.inclContingency) +
                ' ' +
                planCurrency}
            </td>
            <td className='body-text'>
              {numberWithCommas(eyViewValues[scenarios[columnNum]].billingExclExpenses.exclContingency) +
                ' ' +
                planCurrency}
            </td>
          </tr>
          <tr>
            <td className='body-text eyview-box-small-cell'>
              {numberWithCommas(eyViewValues[scenarios[columnNum]].totalLaborCost.inclContingency) + ' ' + planCurrency}
            </td>
            <td className='body-text eyview-box-small-cell'>
              {numberWithCommas(eyViewValues[scenarios[columnNum]].totalLaborCost.exclContingency) + ' ' + planCurrency}
            </td>
          </tr>
          <tr>
            <td className='body-text eyview-box-small-cell'>
              {eyViewValues[scenarios[columnNum]].costOfSubcontractors.inclContingency + ' ' + planCurrency}
            </td>
            <td className='body-text eyview-box-small-cell'>
              {eyViewValues[scenarios[columnNum]].costOfSubcontractors.exclContingency + ' ' + planCurrency}
            </td>
          </tr>
          <tr>
            <td className='body-text eyview-box-small-cell'>
              {(eyViewValues[scenarios[columnNum]].standardMargin.inclContingency * 100).toFixed(1) + '%'}
            </td>
            <td className='body-text eyview-box-small-cell'>
              {(eyViewValues[scenarios[columnNum]].standardMargin.exclContingency * 100).toFixed(1) + '%'}
            </td>
          </tr>
          <tr>
            <td className='body-text eyview-box-small-cell'>
              {(eyViewValues[scenarios[columnNum]].dcrMargin.inclContingency * 100).toFixed(1) + '%'}
            </td>
            <td className='body-text eyview-box-small-cell'>
              {(eyViewValues[scenarios[columnNum]].dcrMargin.exclContingency * 100).toFixed(1) + '%'}
            </td>
          </tr>
          <tr>
            <td className='body-text eyview-box-large-cell'>
              {(eyViewValues[scenarios[columnNum]].standardMarginExclPNC.inclContingency * 100).toFixed(1) + '%'}
            </td>
            <td className='body-text eyview-box-large-cell'>
              {(eyViewValues[scenarios[columnNum]].standardMarginExclPNC.exclContingency * 100).toFixed(1) + '%'}
            </td>
          </tr>
          <tr>
            <td className='body-text eyview-box-large-cell'>
              {(eyViewValues[scenarios[columnNum]].dcrMarginExclPNC.inclContingency * 100).toFixed(1) + '%'}
            </td>
            <td className='body-text eyview-box-large-cell'>
              {(eyViewValues[scenarios[columnNum]].dcrMarginExclPNC.exclContingency * 100).toFixed(1) + '%'}
            </td>
          </tr>
          <tr>
            <td className='body-text eyview-box-small-cell'>
              {numberWithCommas(eyViewValues[scenarios[columnNum]].ratePerDay.inclContingency) + ' ' + planCurrency}
            </td>
            <td className='body-text eyview-box-small-cell'>
              {numberWithCommas(eyViewValues[scenarios[columnNum]].ratePerDay.exclContingency) + ' ' + planCurrency}
            </td>
          </tr>
          <tr>
            <td className='body-text eyview-box-small-cell'>
              {numberWithCommas(eyViewValues[scenarios[columnNum]].ratePerHour.inclContingency) + ' ' + planCurrency}
            </td>
            <td className='body-text eyview-box-small-cell'>
              {numberWithCommas(eyViewValues[scenarios[columnNum]].ratePerHour.exclContingency) + ' ' + planCurrency}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default EYViewBox
