const { REACT_APP_API_URL: API_URL, REACT_APP_API_CODE: CODE } = process.env

const fetchHandler = async (fetchingOptions: any) => {
  const { url, method, body, signal = null } = fetchingOptions

  const accessToken = localStorage.getItem('CCT_Access_Token')
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    'x-functions-key': `${CODE}`
  }
  const res = await fetch(`${API_URL}${url}`, {
    method,
    headers,
    body,
    signal
  })

  if (!res.ok) throw new Error(`HTTP error! status: ${res.status}`)
  const contentType = res.headers.get('content-type')
  if (contentType && contentType.indexOf('application/json') !== -1) {
    return await res.json()
  } else {
    return await res.text()
  }
}

const getQueryString = (params: any) =>
  Object.entries({ ...params, code: CODE })
    .map((param) => `${param[0]}=${param[1]}`)
    .join('&')

export const getPricingPlan = (PricingPlanID: string,userId :string) => {
  const actualUserId = userId === '0' ? sessionStorage.getItem('userId') : userId;
  const requestBody = {
    PricingPlanId: PricingPlanID,
    Userid:  actualUserId 
  };
  const fetchingOptions = {
    url: `/Data/GetPricingPlanData`,
    method: 'POST',
    body: JSON.stringify(requestBody)
  }
  return fetchHandler(fetchingOptions)
}

export const updatePricingPlan = (values: any) => {
  const fetchingOptions = {
    url: `/Data/SavePricingPlanData?status=existing&SaveSummary=False`,
    method: 'POST',
    body: JSON.stringify(values)
  }
  return fetchHandler(fetchingOptions)
}

export const updatePricingPlanScenarios = (values: any) => {
  const fetchingOptions = {
    url: `/Data/SavePricingPlanData?status=existing&SaveSummary=True`,
    method: 'POST',
    body: JSON.stringify(values)
  }
  return fetchHandler(fetchingOptions)
}

export const saveNewPricingPlan = (values: any) => {
  const fetchingOptions = {
    url: `/Data/SavePricingPlanData?status=new&SaveSummary=False`,
    method: 'POST',
    body: JSON.stringify(values)
  }
  return fetchHandler(fetchingOptions)
}

export const getRegions = () => {
  const fetchingOptions = {
    url: `/Data/Location?type=region`,
    method: 'GET'
  }
  return fetchHandler(fetchingOptions)
}

export const getCountries = (values: any) => {
  const fetchingOptions = {
    url: `/Data/Location?type=country&area=${values.area}&region=${values.region}`,
    method: 'GET'
  }
  return fetchHandler(fetchingOptions)
}

export const getSectors = () => { 
  const fetchingOptions = {
    url: `/Data/Location`,
    method: 'GET'
  }
  return fetchHandler(fetchingOptions)
}

export const uploadRateFile = (body: any, fileType: string) => {
  const fetchingOptions = {
    url: `/Data/UploadFile?fileType=${fileType}`,
    method: 'POST',
    body
  }
  return fetchHandler(fetchingOptions)
}

export const uploadMasterFile = (body: any, fileType: string) => {
  const fetchingOptions = {
    url: `/Common/UploadMasterFile?fileType=${fileType}`,
    method: 'POST',
    body
  }
  return fetchHandler(fetchingOptions)
}

export const getAdminsList = () => {
  const fetchingOptions = {
    url: `/ListAdmins`,
    method: 'GET'
  }
  return fetchHandler(fetchingOptions)
}

export const updateRates = (
  costRateId: number | null,
  exchangeRateId: number | null,
  inflationRateId: number | null,
  values: FormData
) => {
  const fetchingOptions = {
    url: `/UpdateChanges?${costRateId ? `&costRateId=${costRateId}` : ''}${
      exchangeRateId ? `&exchangeRateId=${exchangeRateId}` : ''
    }${inflationRateId ? `&inflationRateId=${inflationRateId}` : ''}`,
    method: 'POST',
    body: values
  }
  return fetchHandler(fetchingOptions)
}

export const updateMasterData = (
  masterCostRateFileId: number | null,
  masterExchangeRateFileId: number | null,
  masterMarketRateFileId: number | null,
  masterSectorDiscountFileId: number| null,
  masterOfferingCompetencyCostMappingFileId: number | null
) => {
  const fetchingOptions = {
    url: `/Common/UpdateMasterData?${masterCostRateFileId ? `&masterCostRateFileId=${masterCostRateFileId}` : ''}${
      masterExchangeRateFileId ? `&masterExchangeRateFileId=${masterExchangeRateFileId}` : ''
    }${masterMarketRateFileId ? `&masterMarketRateFileId=${masterMarketRateFileId}` : ''}
    ${masterSectorDiscountFileId ? `&masterSectorDiscountFileId=${masterSectorDiscountFileId}` : ''}
    ${masterOfferingCompetencyCostMappingFileId ? `&masterOfferingCompetencyCostMappingFileId=${masterOfferingCompetencyCostMappingFileId}` : ''}`,
    method: 'POST',
    body: ""
  }
  return fetchHandler(fetchingOptions)
}

export const getDCRRate = (values: any) => {
  const fetchingOptions = {
    url: `/Data/GetDCRRate`,
    method: 'POST',
    body: JSON.stringify(values)
  }
  return fetchHandler(fetchingOptions)
}

export const getCostRate = (values: any) => {
  const fetchingOptions = {
    url: `/Data/GetCostRate`,
    method: 'POST',
    body: JSON.stringify(values)
  }
  return fetchHandler(fetchingOptions)
}

export const getCostCentresData = (body: any, params: any) => {
  const queryString = getQueryString(params)
  const fetchingOptions = {
    url: `/Data/GetCostCentres?${queryString}`,
    method: 'POST',
    body: JSON.stringify(body)
  }
  return fetchHandler(fetchingOptions)
}

export const getFileHistory = () => {
  const fetchingOptions = {
    url: `/Data/GetFileHistory`,
    method: 'GET'
  }
  return fetchHandler(fetchingOptions)
}

export const getAllPricingPlans = (UserID: string) => {
  const fetchingOptions = {
    url: `/Data/GetAllPricingPlans`,
    method: 'POST',
    body: JSON.stringify(UserID)
  }
  return fetchHandler(fetchingOptions)
}

export const deletePricingPlan = (PricingPlanID: string) => {
  const fetchingOptions = {
    url: `/Data/DeletePricingPlan`,
    method: 'POST',
    body: JSON.stringify(PricingPlanID)
  }
  return fetchHandler(fetchingOptions)
}

export const importPricingPlan = (body: any) => {
  const fetchingOptions = {
    url: `/MPP/Import`,
    method: 'POST',
    body
  }
  return fetchHandler(fetchingOptions)
}

export const getAcceptPrivacyStatement = (UserId: any) => {
  const fetchingOptions = {
    url: `/Data/CheckIfPrivacyStatementAccepted`,
    method: 'POST',
    body: JSON.stringify(UserId)
  }
  return fetchHandler(fetchingOptions)
}

export const setAcceptPrivacyStatement = (userId: any) => {
  const fetchingOptions = {
    url: `/Data/AcceptPrivacyStatement`,
    method: 'POST',
    body: JSON.stringify(userId)
  }
  return fetchHandler(fetchingOptions)
}

export const setUserData = (values: any) => {
  const fetchingOptions = {
    url: `/Data/LogUserData`,
    method: 'POST',
    body: JSON.stringify(values)
  }
  return fetchHandler(fetchingOptions)
}

export const getNamedResources = (params: string, signal: any) => {
  const queryString = getQueryString(params)
  const fetchingOptions = {
    url: `/Data/GetNamedResourceData?${queryString}`,
    method: 'GET',
    signal
  }
  return fetchHandler(fetchingOptions)
}

export const getCostCenterListOptions = (type: string, query?: string) => {
  const fetchingOptions = {
    url: `/Data/GetCostCenterListOptions?type=${type}&${query}`,
    method: 'GET'
  }
  return fetchHandler(fetchingOptions)
}

export const getCostRatesForGDSResource = (
  operation: string,
  type: string,
  resourcetypeValue: string,
  costCountryValue: string,
  serviceLineValue: string,
  subServiceLineValue: string
) => {
  let fetchingOptions

  if (type === 'CostCountry') {
    fetchingOptions = {
      url: `/Data/GetCostRatesForGDSResource?operation=${operation}&Type=${type}&ResourceType=${resourcetypeValue}`,
      method: 'POST'
    }
  } else if (type === 'serviceLine') {
    fetchingOptions = {
      url: `/Data/GetCostRatesForGDSResource?operation=${operation}&Type=${type}&ResourceType=${resourcetypeValue}&CostCountry=${costCountryValue}`,
      method: 'POST'
    }
  } else if (type === 'subServiceLine') {
    fetchingOptions = {
      url: `/Data/GetCostRatesForGDSResource?operation=${operation}&Type=${type}&ResourceType=${resourcetypeValue}&CostCountry=${costCountryValue}&ServiceLine=${serviceLineValue}`,
      method: 'POST'
    }
  } else if (type === 'competency') {
    fetchingOptions = {
      url: `/Data/GetCostRatesForGDSResource?operation=${operation}&Type=${type}&ResourceType=${resourcetypeValue}&CostCountry=${costCountryValue}&ServiceLine=${serviceLineValue}&SubServiceLine=${subServiceLineValue}`,
      method: 'POST'
    }
  }

  return fetchHandler(fetchingOptions)
}

export const getInflationRates = (country: string) => {
  const fetchingOptions = {
    url: `/Data/GetRates?type=Inflation&country=${country}`,
    method: 'GET'
  }
  return fetchHandler(fetchingOptions)
}

export const getAllInflationRates = () => {
  const fetchingOptions = {
    url: `/Data/GetRates?type=AllInflationRates&country=''`,
    method: 'GET'
  }
  return fetchHandler(fetchingOptions)
}

export const getRatesByCostCentre = (params: any) => {
  if (params.costCenterDescription !== undefined && params.costCenterDescription.includes('&')) {
    params.costCenterDescription = params.costCenterDescription.replace('&', '|~|')
  }
  const queryString = getQueryString(params)
  const fetchingOptions = {
    url: `/Data/GetCostRatesByCostCentre?${queryString}`,
    method: 'GET'
  }
  return fetchHandler(fetchingOptions)
}

export const getCostRatesForGDSResource1 = (operation: string, currencyCode: string, values: any) => {
  const fetchingOptions = {
    url: `/Data/GetCostRatesForGDSResource?operation=${operation}&currency=${currencyCode}`,
    method: 'POST',
    body: JSON.stringify(values)
  }
  return fetchHandler(fetchingOptions)
}

export const getRanks = () => {
  const fetchingOptions = {
    url: `/Data/GetRank`,
    method: 'GET'
  }
  return fetchHandler(fetchingOptions)
}

export const getMarketScopes = ( body: any) => {
  const fetchingOptions = {
    url: `/Data/GetMarketScopeAndRate`,
    method: 'POST',
    body: JSON.stringify(body)
  }
  return fetchHandler(fetchingOptions) 
}

export const logoutUser = (UserID: string) => {
  const fetchingOptions = {
    url: `/Data/Logout`,
    method: 'POST',
    body: JSON.stringify(UserID)
  }
  return fetchHandler(fetchingOptions)
}

export const duplicatePricingPlanData = (PricingPlanID: string, userID:string) => {
  const requestBody = {
    PricingPlanId: PricingPlanID,
    Userid: userID
  };
  const fetchingOptions = {
    url: `/Data/DuplicatePricingPlanData`,
    method: 'POST',
    body: JSON.stringify(requestBody)
  }
  return fetchHandler(fetchingOptions)
}

export const getReportData = () => {
  const fetchingOptions = {
    url: `/Data/GetReportData`,
    method: 'POST'
  }
  return fetchHandler(fetchingOptions)
}



export const getCCTLabelSettings = () => {
  const fetchingOptions = {
    url: `/Data/CCTLabelSettings`, 
    method: 'GET'
  }

  return fetchHandler(fetchingOptions)
}

export const getBanner = () => {
  const fetchingOptions = {
    url: `/Data/GetBanner`,
    method: 'GET'
  }

  return fetchHandler(fetchingOptions)
}

export const getUserDetails = (name: string , signal: any) => {
  const fetchingOptions = {
    url: `/Data/UserSearch?name=${name}`,
    method: 'GET',
    signal
  }
  return fetchHandler(fetchingOptions)
}

export const DelegateUsers = (value:any) => {
  const fetchingOptions = { 
    url: `/Data/Delegation`,
    method: 'POST',
    body: JSON.stringify(value)
  }
  return fetchHandler(fetchingOptions)
}

export const TransferUser = (value:any) => {
  const fetchingOptions = { 
    url: `/Data/DealTransfer`,
    method: 'POST',
    body: JSON.stringify(value)
  }
  return fetchHandler(fetchingOptions)
} 

export const CheckinCheckout= (value:any, status:string) => {
  const fetchingOptions = {
    url: `/Data/CheckInAndOut?status=${encodeURIComponent(status)}`,
    method: 'POST',
    body: JSON.stringify(value)
  };
  return fetchHandler(fetchingOptions)
} 

export const getgdsinflation = () => {
  const fetchingOptions = {
    url: `/Data/Gdsinflation`,
    method: 'GET'
  }
  return fetchHandler(fetchingOptions)
}