export const updateInputStyling = () => {
    // Get elements with ID starting with react-select
    const elems = document.querySelectorAll('*[id^="react-select-"]');
    // Get elements with type input (spans also have IDs starting with react-select)
    let inputs = []
    for (let i = 0; i < elems.length; i++) {
      if (elems[i].nodeName.toLowerCase() === "input") {
        inputs.push(elems[i])
      }
    }
    // Remove inline styling and replace with class
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].className = "react-select-input"
      if (inputs[i].hasAttribute('style')) {
        inputs[i].attributes.removeNamedItem('style');
        if (inputs[i].attributes.getNamedItem('style') !== null) {
          inputs[i].attributes.getNamedItem('style')!.value = '';
        }
      }
    }
  }