import * as yup from 'yup'
import moment from 'moment'
import _ from 'lodash'

const checkIsDateBetweenDealStartDateAndDealEndDate = (value: Date | undefined | null, context: any) => {
  const index = context.from.length - 1
  const dealStartDate = _.get(context, `from[${index}].value.dealStartDate`, null)
  const dealEndDate = _.get(context, `from[${index}].value.dealEndDate`, null)
  if (dealStartDate && dealEndDate && value) {
    const isSameOrAfterDealStartDate = moment(value).isSameOrAfter(dealStartDate)
    const isSameOrBeforeDealEndDate = moment(value).isSameOrBefore(dealEndDate)
    return isSameOrAfterDealStartDate && isSameOrBeforeDealEndDate
  }
  return true
}

const checkIsDateNeverOverlapResourceDate = (value: Date | undefined | null, context: any) => {
  const resourceEndDate = _.get(context, `from[1].value.endDate`, null)
  if (resourceEndDate && value) {
    const isAfterResourceEndDate = moment(value).isAfter(resourceEndDate)
    return isAfterResourceEndDate
  }
  return true
}

const validationRegEx = /^[\p{Letter}\p{Decimal_Number}!()'"./:&\s-]+$/gu
const validationText = `Letters, numbers and the following special characters [! () ' " - . / : &] are allowed.`
const requiredValidationText = 'Required.'
const minLengthValidationText = 'Minimum 1 character.'
const maxLengthValidationText = 'Maximum 50 characters.' //'Maximum 20 characters.'
const minValueValidationText = 'Must be 0 or greater.'
const startDateValidationText = 'Start Date must be between Deal Start Date and Deal End Date.'
const endDateValidationText = 'End Date must be between Deal Start Date and Deal End Date.'

export const validationSchema = yup.object({
  resources: yup.array().of(
    yup.object().shape({
      role: yup
        .string()
        .matches(validationRegEx, validationText)
        .min(1, minLengthValidationText)
        .max(50, maxLengthValidationText), //.max(20, maxLengthValidationText),
      startDate: yup
        .date()
        .test(
          'checkIsDateBetweenDealStartDateAndDealEndDate',
          startDateValidationText,
          (value, context) => checkIsDateBetweenDealStartDateAndDealEndDate(value, context)
        )
        .nullable(),
      endDate: yup
        .date()
        .test(
          'checkIsDateBetweenDealStartDateAndDealEndDate',
          endDateValidationText,
          (value, context) => checkIsDateBetweenDealStartDateAndDealEndDate(value, context)
        )
        .min(yup.ref('startDate'), 'End Date must be on or after Start Date')
        .nullable(),
      clientRateHour: yup 
        .number()
        .required("Number required.") // Custom message for clarity
        .min(0, minValueValidationText),
      timeUnitsPerWeek: yup
        .number()
        .required(requiredValidationText)
        .min(0, minValueValidationText),
      weeklyTimeUnits: yup
        .array().of(
          yup.object().shape({
            timeUnits: yup 
              .number()
              .required(requiredValidationText)
              .min(0, minValueValidationText),
          })
        ),
      promotedResource: yup.object().shape({
        startDate: yup
          .date()
          .test(
            'checkIsDateBetweenDealStartDateAndDealEndDate',
            startDateValidationText,
            (value, context) => checkIsDateBetweenDealStartDateAndDealEndDate(value, context)
          )
          .test('checkIsDateNeverOverlapResourceDate', 'Resource Start Date at promoted rank must be after End Date of previous rank.', (value, context) =>
            checkIsDateNeverOverlapResourceDate(value, context)
          )
          .nullable(),
        endDate: yup
          .date()
          .test(
            'checkIsDateBetweenDealStartDateAndDealEndDate',
            endDateValidationText,
            (value, context) => checkIsDateBetweenDealStartDateAndDealEndDate(value, context)
          )
          .test('checkIsDateNeverOverlapResourceDate', 'Resource Start Date at promoted rank must be after End Date of previous rank.', (value, context) =>
            checkIsDateNeverOverlapResourceDate(value, context)
          )
          .min(yup.ref('startDate'), 'End Date must be on or after Start Date.')
          .nullable()
      })
    })
  )
})
