import { useEffect, useReducer, useContext } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import moment from 'moment'
import { Formik, FormikHelpers, Form } from 'formik'
import Header from '../header/Header'
import NavMenu from '../navigation/NavMenu'
import GlobalInputs from './GlobalInputs'
import Footer from '../footer/Footer'
import { PhasesType, WorkstreamsType, RouteParamTypes } from './GlobalInputTypes'
import { getPricingPlan, updatePricingPlan, saveNewPricingPlan } from '../utils/fetchHandler'
import Loading from '../loading/Loading'
import Notifications from '../notifications/Notifications'
import { validationSchema } from './validations'
import './GlobalInputs.css'
import { UserContext } from '../../App'
import { useState } from 'react'

interface RouteLocationState {
  imported?: boolean
}
interface MyFormValues {
  worksheetName: string
  engagementName: string
  client: string
  planCurrency: string
  planCurrencyInitialValue: string
  region: string
  country: string
  dealStartDate: Date | null
  dealEndDate: Date | null
  opportunityId: string
  phases: PhasesType
  workstreams: WorkstreamsType
  resources: any
  expenses: any
  sectorId: any
}
const initialFormValues: MyFormValues = {
  worksheetName: 'Unnamed Worksheet',
  engagementName: '',
  client: '',
  planCurrency: '',
  planCurrencyInitialValue: '',
  region: '',
  country: '',
  dealStartDate: null,
  dealEndDate: null,
  opportunityId: '',
  phases: [],
  workstreams: [],
  resources: [],
  expenses: [],
  sectorId: ''
}

type stateType = {
  isLoading: boolean
  initialValues: any
}

const initialState = {
  isLoading: true,
  initialValues: {}
}

const reducer = (state: stateType, payload: stateType) => {
  return { ...state, ...payload }
}

const Worksheet = () => {
  const { pricingPlanId } = useParams<RouteParamTypes>()
  const userInfo = useContext(UserContext)
  const location = useLocation()
  const locationState = location.state as RouteLocationState
  const history = useHistory()

  const [state, dispatch] = useReducer(reducer, initialState)
  const { isLoading } = state
  const [gotNewId, setGotNewId] = useState(false) // Used to move to the Resources page after getting Pricing Plan ID
  const [nextButtonPressed, setNextButtonPressed] = useState(false)
  const [backButtonPressed, setBackButtonPressed] = useState(false)
  const [highestAccessedPage, setHighestAccessedPage] = useState<string>('Global Inputs') // Set default value in case of newly created plan
  const userId = useContext(UserContext)[1]
  const [isEditable, setIsEditable] = useState(true);
  let sectorId:any=''

  const GetDefaultSectorId = (pricingPlanData:any) =>{
    if(pricingPlanData.sectorId === 0){
      //To handle existing deals
      sectorId = ''
      setHighestAccessedPage('Global Inputs')
      return ''
    }
    sectorId = pricingPlanData.sectorId
    return pricingPlanData.sectorId
  }
  
  const generateInitialValues = (pricingPlanData: any) => {
    const convertDate = (date: Date) => new Date(date)
    const initialValues = {
      ...initialFormValues,
      ...pricingPlanData,
      ...(pricingPlanData?.dealStartDate && {
        dealStartDate: convertDate(pricingPlanData.dealStartDate)
      }),
      ...(pricingPlanData?.dealEndDate && {
        dealEndDate: convertDate(pricingPlanData.dealEndDate)
      }),
      userId: userInfo[1],
      sectorId: GetDefaultSectorId(pricingPlanData) 
    }
    if (pricingPlanData.phases && pricingPlanData.phases.length > 0) {
      const parsedPhases = pricingPlanData.phases.map((phase: any) => {
        return {
          ...phase,
          ...(phase?.startDate && { startDate: convertDate(phase.startDate) }),
          ...(phase?.endDate && { endDate: convertDate(phase.endDate) })
        }
      })
      initialValues.phases = parsedPhases
    }
    if (pricingPlanData.workstreams && pricingPlanData.workstreams.length > 0) {
      const parsedWorkstreams = pricingPlanData.workstreams.map((workstream: any) => {
        return {
          ...workstream,
          ...(workstream?.startDate && {
            startDate: convertDate(workstream.startDate)
          }),
          ...(workstream?.endDate && {
            endDate: convertDate(workstream.endDate)
          })
        }
      })
      initialValues.workstreams = parsedWorkstreams
    }
    initialValues.planCurrencyInitialValue = pricingPlanData.planCurrency
    return initialValues
  }

  useEffect(() => {
    ;(async () => {
      dispatch({ ...state, isLoading: true })
      if (pricingPlanId === 'new') {
        dispatch({
          ...state,
          isLoading: false,
          initialValues: initialFormValues
        })
      } else {
        try {
          const pricingPlanData = await getPricingPlan(pricingPlanId,userId)
          setIsEditable(determineAccessibility(pricingPlanData))
          setHighestAccessedPage(pricingPlanData.highestAccessedPage)
          const initialValues = generateInitialValues(pricingPlanData)
          dispatch({
            ...state,
            isLoading: false,
            initialValues
          })
        } catch (error) {
          history.push('/')
        }
      }
    })()
  }, [pricingPlanId])

  const generateValuesToSave = (values: any) => {
    const convertDate = (date: Date) => moment(date).format('YYYY-MM-DDT00:00:00')
    const generatedValues = {
      ...values,
      userId: userInfo[1],
      ...(values?.dealStartDate && {
        dealStartDate: convertDate(values.dealStartDate)
      }),
      ...(values?.dealEndDate && {
        dealEndDate: convertDate(values.dealEndDate)
      })
    }
    if (values.phases && values.phases.length > 0) {
      const parsedPhases = values.phases.map((phase: any) => {
        return {
          ...phase,
          ...(phase?.startDate && { startDate: convertDate(phase.startDate) }),
          ...(phase?.endDate && { endDate: convertDate(phase.endDate) })
        }
      })
      generatedValues.phases = parsedPhases
    }
    if (values.workstreams && values.workstreams.length > 0) {
      const parsedWorkstreams = values.workstreams.map((workstream: any) => {
        return {
          ...workstream,
          ...(workstream?.startDate && {
            startDate: convertDate(values.dealStartDate) //moment(values.dealStartDate).add(1, 'day') //convertDate(workstream.startDate)
          }),
          ...(workstream?.endDate && {
            endDate: convertDate(values.dealEndDate) //moment(values.dealEndDate).add(-1, 'day') //convertDate(workstream.endDate)
          })
        }
      })
      generatedValues.workstreams = parsedWorkstreams
    }
    if (values.resources && values.resources.length > 0) {
      const parsedresources = values.resources.map((resource: any) => {
        let matchworkstream = generatedValues.workstreams.find((workstream: any) => {
          if (resource.workstream === workstream.title) {
            return workstream.title
          } else {
            return ''
          }
        })

        return {
          ...resource,

          ...(resource?.workstream && {
            workstream: matchworkstream === undefined ? '' : matchworkstream.title
          })
        }
      })
      generatedValues.resources = parsedresources
    }

    if (values.expenses && values.expenses.length > 0) {
      const parsedexpenses = values.expenses.map((expense: any) => {
        let matchexpenses = generatedValues.workstreams.find((workstream: any) => {
          if (expense.workstream === workstream.title) {
            return expense.workstream
          } else {
            return ''
          }
        })
        if (expense.workstream === 'unspecified') {
          matchexpenses = 'unspecified'
        }
        return {
          ...expense,

          ...(expense?.workstream && {
            workstream:
              matchexpenses === 'unspecified' ? 'unspecified' : matchexpenses === undefined ? '' : matchexpenses.title
          })
        }
      })
      generatedValues.expenses = parsedexpenses
    }

    if (nextButtonPressed) {
      // Update the highest accessed page when next page button pressed
      if (!generatedValues.highestAccessedPage || generatedValues.highestAccessedPage === 'Global Inputs') {
        generatedValues.highestAccessedPage = 'Resources'
      }
    } else {
      // NOTE: Always add highestAccessPage to prevent save errors (500) - default value is Global Inputs
      if (!generatedValues.highestAccessedPage) {
        generatedValues.highestAccessedPage = 'Global Inputs'
      }
    }

    return generatedValues
  }

  const onSubmitForm = async (values: MyFormValues, actions: FormikHelpers<MyFormValues>) => {
    const generatedValues = generateValuesToSave(values)
    if (pricingPlanId === 'new') {
      try {
        const result = await saveNewPricingPlan(generatedValues)
        if (result?.pricingPlanId) {
          const newValues = generateInitialValues(generatedValues)
          actions.resetForm({ values: { ...initialFormValues, ...newValues } })
          history.replace(`/pricing-plan/${result.pricingPlanId}/global-input`)
          setGotNewId(true)
        }
      } catch (error) {}
    } else {
      try {
        if(isEditable){
          await updatePricingPlan(generatedValues)
          const pricingPlanData = await getPricingPlan(pricingPlanId,userId)
          const newValues = generateInitialValues(pricingPlanData)
          actions.resetForm({ values: { ...initialFormValues, ...newValues } })
        }        
      } catch (error) {}
    }
  }
  const determineAccessibility = (data: { planStatus: any; isDelegated: any; isCheckedIn: any; delegateAccess: any }) => {
    const { planStatus, isDelegated, isCheckedIn, delegateAccess } = data;

    if (planStatus === 'Owned') {
      if (isDelegated) {
        return isCheckedIn;
      } else {
        return true;
      }
    } else{
      return isCheckedIn;
    }
  }; 
    // if (planStatus === 'Owned' && !isDelegated) {
    //   return true;
    // } else if (planStatus === 'Owned' && isDelegated && isCheckedIn) {
    //   return true;
    // } else if (planStatus === 'Owned' && isDelegated && !isCheckedIn) {
    //   return false;
    // } else if (planStatus === 'delegated' && delegateAccess && isCheckedIn) {
    //   return true;
    // } else if (planStatus === 'delegated' && !delegateAccess) {
    //   return false;
    // }
 
    // return true;

  useEffect(() => {
    // Only moves to the Resources page if the pricing plan has gotten a Pricing Plan ID AND the Next Button was pressed
    if (gotNewId && nextButtonPressed) {
      // Does not go to next page if just pressing Save button
      history.push(`/pricing-plan/${pricingPlanId}/resources`)
    }

    if (gotNewId && backButtonPressed) {
      history.push('/')
    }
  }, [gotNewId])

  if (isLoading) return <Loading />

  return (
    <div className='global-inputs-wrapper'>
      <Header isFixedWidth={true} />

      <Formik
        initialValues={state.initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => onSubmitForm(values, actions)}
      >
        {(formik) => {
          return (
            <Form>
              <NavMenu
                worksheetData={formik.values}
                selectedTab={0}
                highestAccessedPage={highestAccessedPage}
                onNavSaveClick={(tabnum) =>
                  formik.submitForm().then(() => {
                    if (formik.isValid) {
                      if (pricingPlanId !== 'new') {
                        if (tabnum == 0) history.push(`/pricing-plan/${pricingPlanId}/global-input`)
                        if (tabnum == 1) history.push(`/pricing-plan/${pricingPlanId}/resources`)
                        if (tabnum == 2) history.push(`/pricing-plan/${pricingPlanId}/expenses`)
                        if (tabnum == 3) history.push(`/pricing-plan/${pricingPlanId}/scenarios`)
                        if (tabnum == 4) history.push(`/pricing-plan/${pricingPlanId}/summary`)
                      }
                    }
                  })
                }
              />
              {locationState?.imported && pricingPlanId !== 'new' && (
                <Notifications
                  control='success'
                  notificationText='Pricing Plan has been imported successfully'
                  delay={5}
                />
              )}
              <div  className={`inputs-main ${!isEditable ? 'disabled' : ''}`}>
              <GlobalInputs />
              </div>
              <div className='sticky-footer'>
                <Footer
                  worksheetData={formik.values}
                  
                  onChangeWorksheet={(e: React.ChangeEvent<HTMLInputElement>) => {
                    formik.setFieldValue('worksheetName', e.target.value)
                  }}
                  nextPage={'Resources'}
                  onClickSaveButton={() => formik.handleSubmit()}
                  onClickNextButton={() => {
                    setNextButtonPressed(true)
                    formik.submitForm().then(() => {
                      if (formik.isValid) {
                        if (pricingPlanId !== 'new' && (formik.values.sectorId !=='' && formik.values.sectorId !==0)) {
                          history.push(`/pricing-plan/${pricingPlanId}/resources`)
                        }
                        else{
                          let val ={validationSchema}
                        }
                      }
                    })
                  }}
                  onClickBackButton={() => {
                    setBackButtonPressed(true)
                    formik.submitForm().then(() => {
                      // Does NOT perform validation
                      history.push('/')
                    })
                  }}
                  isSaveButtonDisabled={!(formik.dirty && formik.isValid)}
                />
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default Worksheet
