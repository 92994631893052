import moment from 'moment'
import styles from './BasicDetails2.module.css'

const BasicDetails2 = (props: any) => {
  const { pricingPlanData } = props
  const { opportunityId, dealStartDate, dealEndDate, country, region } = pricingPlanData

  return (
    <div className={styles.wrapper}>
      <div>
        <div className={styles.header}>
          <span className={`material-icons-outlined ${styles.icon}`}>public</span>
          <div className={styles.label}>AREA / REGION</div>
        </div>
        <div className={styles.value}>{region}</div>
      </div>
      <div>
        <div className={styles.header}>
          <span className={`material-icons-outlined ${styles.icon}`}>location_on</span>
          <div className={styles.label}>COUNTRY</div>
        </div>
        <div className={styles.value}>{country}</div>
      </div>
      <div>
        <div className={styles.header}>
          <span className={`material-icons-outlined ${styles.icon}`}>calendar_today</span>
          <div className={styles.label}>DEAL DATES</div>
        </div>
        <div className={styles.value}>
          {moment(dealStartDate).format('DD/MM/YY')} - {moment(dealEndDate).format('DD/MM/YY')}
        </div>
      </div>
      <div>
        <div className={styles.header}>
          <span className={`material-icons-outlined ${styles.icon}`}>info</span>
          <div className={styles.label}>OPPORTUNITY ID</div>
        </div>
        <div className={styles.value}>{opportunityId}</div>
      </div>
    </div>
  )
}

export default BasicDetails2
