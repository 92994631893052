import './DetailedSummary.css'
import { numberWithCommas } from './../../utils/helperFunctions'
import { forEach } from 'lodash'
import { useSetState } from 'react-use'

const getContingency = (summaryViewModel: any) => {
  const scenarioName = summaryViewModel.selectedScenario
  const selectedScenario = summaryViewModel[scenarioName]
  let contingency = 0
  if (selectedScenario?.contingency) {
    let total = 0
    for (const [key, value] of Object.entries(selectedScenario.contingency)) {
      total += Number(value)
    }

    const n = Object.keys(selectedScenario.contingency).length
    contingency = +(total / n).toFixed(1)
  }
  return contingency
}

const DetailedSummary = (props: any) => {
  let rows = [
    {
      label: '	EY Margin %',
      name: 'standardMargin',
      inclContingency: 0,
      exclContingency: 0,
      mktInclContingency: 0,
      isCost: false
    },
    {
      label: 'EY Margin % using DCR',
      name: 'dcrMargin',
      inclContingency: 0,
      exclContingency: 0,
      mktInclContingency: 0,
      isCost: false
    },
    {
      label: 'EY Margin % Excluding Partner Costs',
      name: 'standardMarginExclPNC',
      inclContingency: 0,
      exclContingency: 0,
      mktInclContingency: 0,
      isCost: false
    },
    {
      label: 'EY Margin % using DCR, Excluding Partner Costs',
      name: 'dcrMarginExclPNC',
      inclContingency: 0,
      exclContingency: 0,
      mktInclContingency: 0,
      isCost: false
    },
    {
      label: 'Total Labor & CT Cost',
      name: 'totalLaborCost',
      inclContingency: 0,
      exclContingency: 0,
      mktInclContingency: 0,
      isCost: true
    },
    {
      label: 'Cost of Subcontractors',
      name: 'costOfSubcontractors',
      inclContingency: 0,
      exclContingency: 0,
      mktInclContingency: 0,
      isCost: true
    },
    {
      label: 'Total Expenses',
      name: 'totalExpenses',
      inclContingency: 0,
      exclContingency: 0,
      mktInclContingency: 0,
      isCost: true
    },
    {
      label: '	Rate / Day',
      name: 'ratePerDay',
      inclContingency: 0,
      exclContingency: 0,
      mktInclContingency: 0,
      isCost: true
    },
    {
      label: 'Rate / Hour',
      name: 'ratePerHour',
      inclContingency: 0,
      exclContingency: 0,
      mktInclContingency: 0,
      isCost: true
    },
    {
      label: 'Total Billing Amount',
      name: 'total',
      inclContingency: 0,
      exclContingency: 0,
      mktInclContingency: 0,
      isCost: true
    }
  ]

  let marketRows = [
    {
      label: '	EY Margin %',
      name: 'standardMargin',
      inclContingency: 0,
      exclContingency: 0,
      isCost: false
    },
    {
      label: 'EY Margin % using DCR',
      name: 'dcrMargin',
      inclContingency: 0,
      exclContingency: 0,
      isCost: false
    },
    {
      label: 'EY Margin % Excluding Partner Costs',
      name: 'standardMarginExclPNC',
      inclContingency: 0,
      exclContingency: 0,
      isCost: false
    },
    {
      label: 'EY Margin % using DCR, Excluding Partner Costs',
      name: 'dcrMarginExclPNC',
      inclContingency: 0,
      exclContingency: 0,
      isCost: false
    },
    {
      label: 'Total Labor & CT Cost',
      name: 'totalLaborCost',
      inclContingency: 0,
      exclContingency: 0,
      isCost: true
    },
    {
      label: 'Cost of Subcontractors',
      name: 'costOfSubcontractors',
      inclContingency: 0,
      exclContingency: 0,
      isCost: true
    },
    {
      label: 'Total Expenses',
      name: 'totalExpenses',
      inclContingency: 0,
      exclContingency: 0,
      isCost: true
    },
    {
      label: '	Rate / Day',
      name: 'ratePerDay',
      inclContingency: 0,
      exclContingency: 0,
      isCost: true
    },
    {
      label: 'Rate / Hour',
      name: 'ratePerHour',
      inclContingency: 0,
      exclContingency: 0,
      isCost: true
    },
    {
      label: 'Total Billing Amount',
      name: 'total',
      inclContingency: 0,
      exclContingency: 0,
      isCost: true
    }
  ]

  const { pricingPlanData } = props
  const selectedScenario = pricingPlanData.summaryViewModel.selectedScenario
  const scenario = pricingPlanData?.summaryViewModel?.detailedSummary
  const marketRateScenario = pricingPlanData?.summaryViewModel?.marketRateDetailedSummary

  rows = scenario
    ? rows.map((row) => {
        if (row.name === 'total') {
          row.name = 'totalBillings'
          const values = scenario[row.name]
          row = values ? { ...row, ...values } : row
        } else {
          const values = scenario[row.name]
          row = values ? { ...row, ...values } : row
        }
        return row
      })
    : rows
  marketRows = marketRateScenario
    ? marketRows.map((marketRow) => {
        if (marketRow.name === 'total') {
          marketRow.name = 'totalBillings'
          const values = marketRateScenario[marketRow.name]
          marketRow = values ? { ...marketRow, ...values } : marketRow
        } else {
          const values = marketRateScenario[marketRow.name]
          marketRow = values ? { ...marketRow, ...values } : marketRow
        }
        return marketRow
      })
    : marketRows

  for (var i = 0; i < rows.length; i++) {
    for (var j = 0; j < marketRows.length; j++) {
      if (rows[i].name === marketRows[j].name) {
        rows[i].mktInclContingency = marketRows[j].inclContingency
      }
    }
  }

  let contingency
  if (pricingPlanData.summaryViewModel) {
    contingency = getContingency(pricingPlanData.summaryViewModel)
  }

  return (
    <>
      <header>
        <div className='title'>Detailed Summary</div>
        <div className='contingency'>CONTINGENCY: {contingency}%</div>
      </header>

      <div className='detailed-summary-table'>
        <div className='thead'>
          <div className='tr'>
            <div className='th'></div>
            <div className='th'>Incl Contingency</div>
            <div className='th'>Excl Contingency</div>
            {selectedScenario !== 'marketRate' && <div className='th'>Market Rate Incl Contingency</div>}
          </div>
        </div>
        <div className='tbody'>
          {rows.map((row) => {
            return (
              <div className='tr' key={row.label}>
                <div className='td label'>{row.name === 'total' ? <b>{row.label}</b> : row.label}</div>
                <div className='td including'>
                  {row.isCost ? numberWithCommas(row.inclContingency) : (row.inclContingency * 100).toFixed(1)}{' '}
                  {row.isCost ? pricingPlanData.planCurrency : '%'}
                </div>
                <div className='td excluding'>
                  {row.isCost ? numberWithCommas(row.exclContingency) : (row.exclContingency * 100).toFixed(1)}{' '}
                  {row.isCost ? pricingPlanData.planCurrency : '%'}
                </div>
                {selectedScenario !== 'marketRate' && (
                  <div className='td including marketRate'>
                    {row.isCost ? numberWithCommas(row.mktInclContingency) : (row.mktInclContingency * 100).toFixed(1)}{' '}
                    {row.isCost ? pricingPlanData.planCurrency : '%'}
                  </div>
                )}
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default DetailedSummary
