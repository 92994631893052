import React, { useState, useEffect } from 'react'
import './Notifications.css'

interface NotificationsProps {
  control: 'success' | 'failed'
  delay?: number
  notificationText: string
}

const Notifications = (props: NotificationsProps) => {
  const [showNotification, setShowNofication] = useState(true)

  const { delay = 5 } = props

  useEffect(() => {
    let timer1 = setTimeout(() => setShowNofication(false), delay * 1000)
    return () => {
      clearTimeout(timer1)
    }
  }, [])

  if (showNotification && props.control) {
    return (
      <div className={`notification-wrapper ${props.control === 'success' ? 'success' : 'failed'}`}>
        <span className='material-icons'>{props.control === 'success' ? 'check_circle' : 'error'}</span>
        <span className='body-text notification-text'>{props.notificationText}</span>
      </div>
    )
  }

  return null
}

export default Notifications
