import React, { ReactElement } from 'react'
import './DeleteModal.css'
import { Worksheet } from '../landing/Worksheet'
import { deletePricingPlan } from '../utils/fetchHandler'

interface DeleteModalProps {
  worksheets: Array<Worksheet>
  setWorksheets: Function
  deleteId: string
  deleteName: string
}

const DeleteModal = (props: DeleteModalProps): ReactElement => {
  const hideDeleteModal = () => {
    const deleteModal = document.getElementById('delete-modal') as HTMLElement
    deleteModal.style.display = 'none'
  }

  const confirmDelete = async () => {
    let newArray = [...props.worksheets]
    let index = newArray.findIndex((worksheet) => worksheet.pricingPlanID === props.deleteId)

    // Visually delete from table
    newArray.splice(index, 1)
    props.setWorksheets(newArray)

    hideDeleteModal()
    await deletePricingPlan(props.deleteId)

  }

  return (
    <div>
      <div id='delete-modal'>
        <div className='delete-modal-content'>
          <p className='delete-modal-heading heading-three'>Delete '{props.deleteName}'?</p>
          <p className='heading-three'>Are you sure you want to delete? This plan will be permanently deleted.</p>
          <div className='delete-modal-buttons'>
            <button className='secondary-button heading-three' onClick={hideDeleteModal}>
              Cancel
            </button>
            <button className='primary-button heading-three' onClick={confirmDelete}>
              Confirm Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DeleteModal
