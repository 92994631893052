import _ from 'lodash'
import './WorkstreamSummary.css'
import { numberWithCommas } from '../../resources/ResourceTableComponents'
import React, { memo, useMemo } from 'react'
import moment from 'moment'

const generateColumns = (workstreams: any[]) => {
  if (workstreams?.length === 0) return []
  let columns = workstreams.map((workstream) => {
    return { title: workstream.title, path: _.camelCase(workstream.title) }
  })
  columns.push({ title: 'Unallocated', path: '' })
  columns.push({ title: 'Total workstream', path: 'totalw' })
  if (columns?.length > 8) columns = columns.slice(0, 9)
  return columns
}

export const getTotalHours = (rowData: any) => {
  const hours =
    rowData?.weeklyTimeUnits?.length > 0 &&
    rowData.weeklyTimeUnits.reduce((result: number, item: any) => {
      if (item.timeUnits && !isNaN(item.timeUnits)) {
        return result + item.timeUnits
      }
      return result
    }, 0)
  return hours
}

export const getTotalDays = (rowData: any) => {
  const hours = getTotalHours(rowData)
  const isGDS = rowData.isGDS
  let days = 0
  if (hours && hours > 0) {
    if (isGDS) days = hours / 9
    else days = hours / 7.5
  }
  return getRounded(days, 1)
}

export const getRounded = (num: number, n: number = 0) => {
  const d = Math.pow(10, n)
  return Math.round((num + Number.EPSILON) * d) / d
}

const getResourcesExclSubcontractors = (resources: any[]) => {
  return resources.filter(
    (resource: any) =>
      !(
        resource.source === 'subcontractor' ||
        resource.rank === 'Client Serving Contractor' ||
        resource.rank === 'Subcontractor'
      )
  )
}

const getSubcontractorResources = (resources: any[]) => {
  return resources.filter(
    (resource: any) =>
      resource.source === 'subcontractor' ||
      resource.rank === 'Client Serving Contractor' ||
      resource.rank === 'Subcontractor'
  )
}
const calculateTotalLabourCostDtls = (
  rowData: any,
  dealStartDate: Date | null,
  dealEndDate: Date | null,
  rate: number = 0,
  fy1InflationRate: any,
  fy2InflationRate: any,
  fy3InflationRate: any,
  fy4InflationRate: any
) => {
  let IsweeklyTimeunits = rowData.hasOwnProperty('weeklyTimeUnits')
  const inflation: any = []
  inflation.push(0) //no inflation for current fy
  inflation.push(fy1InflationRate)
  inflation.push(fy2InflationRate)
  inflation.push(fy3InflationRate)
  inflation.push(fy4InflationRate)

  let yearArrayDetails: any = []
  let currentDate = new Date()

  let currentYear = new Date().getFullYear()
  let nextFYYear = currentYear + 1
  let prevYear = currentYear - 1
  let currentFYStartDate = moment(`${currentYear}-07-01`).format('YYYY-MM-DDT00:00:00')
  let currentFYEndDate = moment(`${currentYear}-07-01`).format('YYYY-MM-DDT00:00:00')

  let startYear = moment(dealStartDate).year() > currentYear ? currentYear : moment(dealStartDate).year()
  let yearRange = getRange(startYear, moment(dealEndDate).year())

  for (var i = 0; i < yearRange.length; i++) {
    if (i === 0) {
      if (moment(currentDate).isSameOrAfter(`${currentYear}-07-01`)) {
        currentFYStartDate = moment(`${currentYear}-07-01`).format('YYYY-MM-DDT00:00:00')
        currentFYEndDate = moment(`${nextFYYear}-06-30`).format('YYYY-MM-DDT00:00:00')
        yearArrayDetails = yearArrayDetails.concat(yearRange[i])
      } else {
        currentFYStartDate = moment(`${prevYear}-07-01`).format('YYYY-MM-DDT00:00:00')
        currentFYEndDate = moment(`${currentYear}-06-30`).format('YYYY-MM-DDT00:00:00')
        if (startYear >= currentYear) yearArrayDetails = yearArrayDetails.concat(yearRange[i])
        yearArrayDetails = yearArrayDetails.concat(yearRange[i])
      }
    } else yearArrayDetails = yearArrayDetails.concat(yearRange[i])
  }

  const yearsArray = yearArrayDetails //getRange(moment(dealStartDate).year(), moment(dealEndDate).year())
  const weeklyTimeUnitsPerYear = yearsArray?.reduce((result: any[], year: any, index: any) => {
    if (index === 0 && IsweeklyTimeunits) {
      /* result.push([
        ...rowData.weeklyTimeUnits.filter((item: any) => moment(item.startDate).isSameOrBefore(`${year}-06-30`))
      ]) */

      const resultINCurrentFY = [
        ...rowData.weeklyTimeUnits.filter((item: any) =>
          // moment(item.startDate).isSameOrAfter(currentFYStartDate) &&
          moment(item.startDate).isSameOrBefore(currentFYEndDate)
        )
      ]
      if (resultINCurrentFY.length > 0) {
        result.push(resultINCurrentFY)
      }
      if (resultINCurrentFY.length === 0) {
        result.push([])
      }
    }
    if (index > 0) {
      if (year > moment(currentFYStartDate).year()) {
        //year = moment(currentFYEndDate).year()
        let nextYear = parseInt(year) + 1
        let startRange = moment(`${nextYear - 1}-07-01`).format('YYYY-MM-DDT00:00:00')
        let endRange = moment(`${nextYear}-06-30`).format('YYYY-MM-DDT00:00:00')
        if (IsweeklyTimeunits && index > 0) {
          result.push([
            ...rowData.weeklyTimeUnits.filter(
              (item: any) =>
                //moment(date).format('YYYY-MM-DDT00:00:00')
                moment(item.startDate).isSameOrAfter(moment(startRange)) &&
                moment(item.startDate).isSameOrBefore(moment(endRange))
            )
          ])
        }
      }
    }
    return result
  }, [])

  let a = rate
  let flag = false
  let temp = 0
  const totalLabourCost = weeklyTimeUnitsPerYear.reduce((result: number, item: any[], index: number) => {
    const hours = item.reduce((_result: number, _item: any) => {
      _result += _item.timeUnits
      return _result
    }, 0)
    const standardCost = hours * rate

    function transferDataCurrentFY(weeklyData: any, date: any) {
      // Create a deep copy of weeklyData
      const copiedWeeklyData = JSON.parse(JSON.stringify(weeklyData))
      const targetDate = new Date(date)

      for (let i = 1; i < copiedWeeklyData.length; i++) {
        const remainingItems = []
        const itemsToTransfer = []

        for (let j = 0; j < copiedWeeklyData[i].length; j++) {
          const item = copiedWeeklyData[i][j]
          if (new Date(item.startDate) < targetDate) {
            itemsToTransfer.push(item)
          } else {
            remainingItems.push(item)
          }
        }

        // Transfer items to the first index
        copiedWeeklyData[0].push(...itemsToTransfer)

        // Update the current index with the remaining items
        copiedWeeklyData[i] = remainingItems
      }

      return copiedWeeklyData
    }

    function transferDataNextFY(weeklyData: any, targetDate: any) {
      // Create a deep copy of weeklyData
      const copiedWeeklyData = JSON.parse(JSON.stringify(weeklyData))
      const date = new Date(targetDate)
      let shouldShift = false

      // Check if the target date falls within the week range of index 0
      for (const item of copiedWeeklyData[0]) {
        const startDate = new Date(item.startDate)
        const endDate = new Date(startDate)
        endDate.setDate(endDate.getDate() + 6) // Assuming each week is 7 days long
        if (date < startDate) {
          shouldShift = true
          break
        }
      }

      const remainingItems = []
      let itemsToTransfer = []

      if (!shouldShift) {
        if (copiedWeeklyData[0].length == 0) {
          for (let i = 1; i < copiedWeeklyData.length; i++) {
            const currentItems = copiedWeeklyData[i]
            copiedWeeklyData[i] = itemsToTransfer
            itemsToTransfer = currentItems.slice() // Create a new array for itemsToTransfer
          }

          // If there are remaining items after shifting, add them as a new index
          if (itemsToTransfer.length > 0) {
            copiedWeeklyData.push(itemsToTransfer)
          }
        }
        return copiedWeeklyData // No shift needed
      }

      // Separate items in index 0 based on the target date
      for (const item of copiedWeeklyData[0]) {
        if (new Date(item.startDate) > date) {
          itemsToTransfer.push(item)
        } else {
          remainingItems.push(item)
        }
      }

      // Update index 0 with the remaining items
      copiedWeeklyData[0] = remainingItems
      for (let i = 1; i < copiedWeeklyData.length; i++) {
        const currentItems = copiedWeeklyData[i]
        copiedWeeklyData[i] = itemsToTransfer
        itemsToTransfer = currentItems.slice() // Create a new array for itemsToTransfer
      }

      // If there are remaining items after shifting, add them as a new index
      if (itemsToTransfer.length > 0) {
        copiedWeeklyData.push(itemsToTransfer)
      }

      return copiedWeeklyData
    }

    function calculateTotalHoursAtIndex(weeklyData: any, ind: number) {
      if (ind < 0 || ind >= weeklyData.length || !Array.isArray(weeklyData[ind]) || weeklyData[ind].length === 0) {
        return 0
      }

      return weeklyData[ind].reduce((totalHours: number, item: any) => {
        return totalHours + item.timeUnits
      }, 0)
    }

    {
      let inflationRate = 0
      if (index <= 4) inflationRate = inflation[index] / 100
      else inflationRate = 0

      const standardCost = hours * rate
      let calInflationCost = index === 0 ? rate : a * (1 + inflationRate) // rowData.isGDS ? rate * Math.pow(1 + inflationRate, index):

      a = calInflationCost

      const inflationCost = inflationRate !== undefined ? hours * calInflationCost : 0 //rowData.isGDS ? hours * calInflationCost :

      const inflationCostRate = inflationCost - standardCost
      //if (rateType === 'totalClientRate') {
      //   result += standardCost
      // } else {
      result += standardCost + inflationCostRate
      // }
    }
    return result
  }, 0)
  return totalLabourCost
}
const getRange = (start: number, end: number) => {
  return Array(end - start + 1)
    .fill(0)
    .map((_, idx) => start + idx)
}
const getResourceSTCost = (pricingPlanData: any, resources: any[], isClientRate: boolean = false) => {
  return resources.reduce((result: number, resource: any) => {
    if (isClientRate) {
      //result += resource.totalClientRate
      if (resource.source === 'onshore') {
        if (
          pricingPlanData.summaryViewModel.fy1InflationAdj > 0 ||
          pricingPlanData.summaryViewModel.fy2InflationAdj > 0 ||
          pricingPlanData.summaryViewModel.fy3InflationAdj > 0 ||
          pricingPlanData.summaryViewModel.fy4InflationAdj > 0
        ) {
          const COLAClient = calculateTotalLabourCostDtls(
            resource,
            resource.startDate,
            resource.endDate,
            resource.clientRateHour,
            parseFloat(pricingPlanData.summaryViewModel.fy1InflationAdj),
            parseFloat(pricingPlanData.summaryViewModel.fy2InflationAdj),
            parseFloat(pricingPlanData.summaryViewModel.fy3InflationAdj),
            parseFloat(pricingPlanData.summaryViewModel.fy4InflationAdj)
          )
          result += COLAClient
        } else result += resource.totalClientRate
      } else {
        if (
          pricingPlanData.summaryViewModel.fy1InflationAdj > 0 ||
          pricingPlanData.summaryViewModel.fy2InflationAdj > 0 ||
          pricingPlanData.summaryViewModel.fy3InflationAdj > 0 ||
          pricingPlanData.summaryViewModel.fy4InflationAdj > 0
        ) {
          const COLAClientGDS = calculateTotalLabourCostDtls(
            resource,
            resource.startDate,
            resource.endDate,
            resource.clientRateHour,
            parseFloat(pricingPlanData.summaryViewModel.fy1InflationAdj),
            parseFloat(pricingPlanData.summaryViewModel.fy2InflationAdj),
            parseFloat(pricingPlanData.summaryViewModel.fy3InflationAdj),
            parseFloat(pricingPlanData.summaryViewModel.fy4InflationAdj)
          )
          result += COLAClientGDS
        } else result += resource.totalClientRate
      }

      if (resource.source === 'onshore' && resource.promotedResource) {
        if (
          pricingPlanData.summaryViewModel.fy1InflationAdj > 0 ||
          pricingPlanData.summaryViewModel.fy2InflationAdj > 0 ||
          pricingPlanData.summaryViewModel.fy3InflationAdj > 0 ||
          pricingPlanData.summaryViewModel.fy4InflationAdj > 0
        ) {
          const COLAClientPromoted = calculateTotalLabourCostDtls(
            resource.promotedResource,
            resource.promotedResource.startDate,
            resource.promotedResource.endDate,
            resource.promotedResource.clientRateHour,
            parseFloat(pricingPlanData.summaryViewModel.fy1InflationAdj),
            parseFloat(pricingPlanData.summaryViewModel.fy2InflationAdj),
            parseFloat(pricingPlanData.summaryViewModel.fy3InflationAdj),
            parseFloat(pricingPlanData.summaryViewModel.fy4InflationAdj)
          )
          result += COLAClientPromoted
        } else result += resource.promotedResource.totalClientRate
      }
      // result += resource.promotedResource.totalClientRate
    } else {
      result += resource.totalStandardCost
      if (resource.promotedResource) result += resource.promotedResource.totalStandardCost
    }
    return result
  }, 0)
}

const getResourceSTCostContigency = (resources: any[], contigency: any, isClientRate: boolean = false) => {
  return resources.reduce((result: number, resource: any) => {
    let contigencyVal = 0
    //'eyOnShore', 'gdsOffshore', 'gdsLanded', 'subcontractors
    if (resource.source === 'onshore') contigencyVal = contigency.eyOnShore
    else if (resource.source === 'gdsOffshore') contigencyVal = contigency.gdsOffshore
    else if (resource === 'gdsLanded') contigencyVal = contigency.gdsLanded
    else if (resource === 'subcontractors') contigencyVal = contigency.subcontractors

    if (isClientRate) {
      result += resource.totalClientRate * (contigencyVal / 100)
      if (resource.promotedResource) result += resource.promotedResource.totalClientRate * (contigencyVal / 100)
    } else {
      result += resource.totalStandardCost * (contigencyVal / 100)
      if (resource.promotedResource) result += resource.promotedResource.totalStandardCost * (contigencyVal / 100)
    }
    return result
  }, 0)
}

const getResourceLTCost = (resources: any[], isClientRate: boolean = false) => {
  return resources.reduce((result: number, resource: any) => {
    if (isClientRate) {
      result += resource.totalClientRate
      if (resource.promotedResource) result += resource.promotedResource.totalClientRate
    } else {
      let perDayHours = 0
      perDayHours = GetBusinessWorkingHours(resource, resource.costCountry)

      let isDCRCriteria = CheckDCRCriteria(resource, perDayHours)
      if (isDCRCriteria && resource.totalDedicatedCost !== 0) {
        result += resource.totalDedicatedCost
        if (resource.promotedResource) {
          // Account for promotions
          if (resource.promotedResource.totalDedicatedCost !== 0) {
            result += resource.promotedResource.totalDedicatedCost
          } else {
            result += resource.promotedResource.totalStandardCost
          }
        }
      } // If DCR not applicable then take standardcost
      else {
        result += resource.totalStandardCost
        if (resource.promotedResource) result += resource.promotedResource.totalStandardCost
      }
    }
    return result
  }, 0)
}

const getResourceLTCostContigency = (resources: any[], contigency: any, isClientRate: boolean = false) => {
  return resources.reduce((result: number, resource: any) => {
    let contigencyVal = 0
    //'eyOnShore', 'gdsOffshore', 'gdsLanded', 'subcontractors
    if (resource.source === 'onshore') contigencyVal = contigency.eyOnShore
    else if (resource.source === 'gdsOffshore') contigencyVal = contigency.gdsOffshore
    else if (resource === 'gdsLanded') contigencyVal = contigency.gdsLanded
    else if (resource === 'subcontractors') contigencyVal = contigency.subcontractors

    if (isClientRate) {
      result += resource.totalClientRate * (contigencyVal / 100)
      if (resource.promotedResource) result += resource.promotedResource.totalClientRate * (contigencyVal / 100)
    } else {
      let perDayHours = 0
      perDayHours = GetBusinessWorkingHours(resource, resource.costCountry)

      let isDCRCriteria = CheckDCRCriteria(resource, perDayHours)
      if (isDCRCriteria && resource.totalDedicatedCost !== 0) {
        result += resource.totalDedicatedCost * (contigencyVal / 100)
        if (resource.promotedResource) {
          // Account for promotions
          if (resource.promotedResource.totalDedicatedCost !== 0) {
            result += resource.promotedResource.totalDedicatedCost * (contigencyVal / 100)
          } else {
            result += resource.promotedResource.totalStandardCost * (contigencyVal / 100)
          }
        }
      } // If DCR not applicable then take standardcost
      else {
        result += resource.totalStandardCost * (contigencyVal / 100)
        if (resource.promotedResource) result += resource.promotedResource.totalStandardCost * (contigencyVal / 100)
      }
    }
    //result += resource.totalStandardCost * (contigencyVal / 100)
    return result
  }, 0)
}

const getSubcontractorExpenses = (expenses: any[]) => {
  return expenses.filter(
    (expense: any) =>
      expense.expenseType === 'Other' &&
      (expense.category === 'consultants' || expense.category === 'subcontractors' || expense.category === 'alliance')
  )
}

const getExpenseTotalCost = (expenses: any[]) => {
  return expenses.reduce((result: number, expense: any) => {
    result += expense.cost
    return result
  }, 0)
}

const generateResources = (resources: any[]) => {
  return resources.reduce((result: any[], resource: any) => {
    // let promotedResource = null
    // if (resource.promotedResource) {
    //   promotedResource = resource.promotedResource
    // }
    //delete resource.promotedResource
    result.push(resource)
    //if (promotedResource) result.push(promotedResource)
    return result
  }, [])
}

const getWorkstreamExpenses = (expenses: any[], workstream: string) => {
  return expenses.filter((expense: any) => expense.workstream === workstream)
}

const getMobilityExpenses = (expenses: any[]) => {
  return expenses.filter(
    (expense: any) =>
      expense.expenseType === 'Other' &&
      !['otherLumpsum', 'consultants', 'subcontractors', 'alliance'].includes(expense.category)
  )
}

const getTechnologyExpenses = (expenses: any[]) => {
  return expenses.filter((expense: any) => expense.expenseType === 'Technology' && expense.category === 'otherProducts')
}

const getOtherLumpsumExpenses = (expenses: any[]) => {
  return expenses.filter((expense: any) => expense.expenseType === 'Other' && expense.category === 'otherLumpsum')
}

const getWorkstreamResources = (resources: any[], workstream: string) => {
  if (workstream === 'Unallocated') workstream = ''
  return resources.filter((resource: any) => resource.workstream === workstream)
}

const getPartnerResources = (resources: any[]) => {
  return resources.filter((resource: any) => resource.rank?.includes('Partner'))
}

const getOnshoreResources = (resources: any[], workstream: string) => {
  if (workstream === 'Unallocated') workstream = ''
  return resources.filter((resource: any) => resource.source === 'onshore' && resource.workstream === workstream)
}

const getGDSResources = (resources: any[], workstream: string) => {
  if (workstream === 'Unallocated') workstream = ''
  return resources.filter(
    (resource: any) =>
      (resource.source === 'gdsOffshore' || resource.source === 'gdsLanded') && resource.workstream === workstream
  )
}

const getSubcontractorWorkstreamResources = (resources: any[], workstream: string) => {
  if (workstream === 'Unallocated') workstream = ''
  return resources.filter(
    (resource: any) =>
      resource.source === 'subcontractor' ||
      resource.rank === 'Client Serving Contractor' ||
      (resource.rank === 'Subcontractor' && resource.workstream === workstream)
  )
}

const calculateTotalResourceFees = (
  pricingPlanData: any,
  resources: any,
  workstream: string,
  isClientRate: boolean = false
) => {
  const resourceSTCost = getResourceSTCost(pricingPlanData, resources, isClientRate)
  // TODO: DC cost is not available yet, Do value is hardcoded now. Need to calculate when values available
  const resourceDCCost = 0
  // Calculating EY_ST value
  /*
  let EY_ST = (sumOfResourceContingency / resourceSTCost) * 100
  EY_ST = isFinite(EY_ST) ? EY_ST : 0
  */
  let contigency = pricingPlanData.summaryViewModel[pricingPlanData.summaryViewModel.selectedScenario].contingency
  const resourcecontigency = getResourceSTCostContigency(resources, contigency, isClientRate)
  // Calculating sum of ctOtherProducts expense
  const expenses = pricingPlanData.expenses
  let workstreamExpenses: any = {}
  if (workstream === 'Unallocated') {
    workstreamExpenses = getWorkstreamExpenses(expenses, 'unspecified')
  } else {
    workstreamExpenses = getWorkstreamExpenses(expenses, workstream)
  }
  //const workstreamExpenses = getWorkstreamExpenses(expenses, workstream)
  const ctProductsCost = workstreamExpenses.reduce((result: number, workstreamExpense: any) => {
    if (workstreamExpense.category === 'ctProducts') result += workstreamExpense.cost
    return result
  }, 0)

  // Calculating total
  //const total = (resourceSTCost + resourceDCCost) * (1 + EY_ST)
  const total = resourceSTCost + resourceDCCost + resourcecontigency + ctProductsCost
  return total
}
const calculateStandardCost = (pricingPlanData: any, columns: any[]) => {
  const resources = generateResources(pricingPlanData.resources)
  let sumOfResourceContingency = 0
  if (pricingPlanData?.summaryViewModel?.contingencyTableValues?.resources) {
    const onshore = pricingPlanData.summaryViewModel.contingencyTableValues.resources.onshore || 0
    const gds = pricingPlanData.summaryViewModel.contingencyTableValues.resources.gds || 0
    sumOfResourceContingency = onshore + gds
  }
  let standardCost: any = {}
  const selectedScenario = pricingPlanData.summaryViewModel.selectedScenario
  let isClientRate = false
  columns.forEach((column: any) => {
    if (column.path === 'totalw') {
      // if (selectedScenario === 'client') {
      //   standardCost[column.path] = pricingPlanData?.summaryViewModel?.priceBreakdown?.servicesFees || 0
      // } else {
      standardCost[column.path] =
        pricingPlanData?.summaryViewModel?.detailedSummary?.totalLaborCost?.inclContingency || 0
      //}
    } else {
      const workstreamResources = getWorkstreamResources(resources, column.title)
      const workstreamResourcesExclSubcontractor = getResourcesExclSubcontractors(workstreamResources)
      // Calculating sum of all resource ST Cost
      //if (selectedScenario === 'client') isClientRate = true
      const total = calculateTotalResourceFees(
        pricingPlanData,
        workstreamResourcesExclSubcontractor,
        column.title,
        isClientRate
      )
      standardCost[column.path] = getRounded(total)
    }
  })

  return { ...standardCost, name: 'Total Labor & CT Cost', cost: true } //'Standard Cost', cost: true }
}

//Calculate the DCR Logic for each resources for calculating Dedicated cost

const GetBusinessWorkingHours = (inputResource: any, costCountry: string) => {
  let perDayHours = 0
  if (inputResource.isGDS) {
    if (['United Kingdom'].includes(costCountry)) {
      perDayHours = 7
    } else if (['India'].includes(costCountry)) {
      perDayHours = 9
    } else perDayHours = 8
  } else {
    if (['Australia', 'New Zealand'].includes(costCountry)) {
      perDayHours = 7.5
    } else if (['United Kingdom'].includes(costCountry)) {
      perDayHours = 7
    } else {
      perDayHours = 8
    }
  }
  return perDayHours
}

const CalculateDCRConsecutivedays = (inputResource: any, perDayHours: number, isPromotedResourceExists: boolean) => {
  let consecutiveDaysCnt = 0

  let totalPerYearHours = 0
  let dcrEligible

  dcrEligible = Test5(inputResource, perDayHours, isPromotedResourceExists)
  let dcrTest5 = 0
  dcrTest5 = dcrEligible !== undefined ? dcrEligible : 0

  return dcrTest5
}
const Test5 = (inputResource: any, perDayHours: number, isPromotedResourceExists: boolean) => {
  let totalPerYearHours = 0
  let dcrEligible = 0
  let consecutiveDaysCnt = 0
  if (isPromotedResourceExists || inputResource.totalDays >= 227) {
    for (let wi = 0; wi < inputResource.weeklyTimeUnits.length; wi++) {
      totalPerYearHours = 0
      if (dcrEligible === 0) {
        let currentWeekDate = moment(inputResource.weeklyTimeUnits[wi].startDate)
        let cnt = 0
        for (let i = wi; i < inputResource.weeklyTimeUnits.length; i++) {
          cnt = cnt + 1
          if (cnt <= 53) {
            totalPerYearHours = totalPerYearHours + inputResource.weeklyTimeUnits[i].timeUnits
            consecutiveDaysCnt = totalPerYearHours / perDayHours
            if (consecutiveDaysCnt >= 227) {
              let daysCnt =
                moment(inputResource.weeklyTimeUnits[i].startDate).diff(
                  moment(inputResource.weeklyTimeUnits[wi].startDate),
                  'days'
                ) + 1
              dcrEligible = daysCnt <= 365 ? 1 : 0
              return consecutiveDaysCnt
              //break
            }
          } else {
            dcrEligible = 0
            return consecutiveDaysCnt
            //break
          }
          if (i === inputResource.weeklyTimeUnits.length - 1) {
            return consecutiveDaysCnt
          }
        }
      }
    }
  }
}
const CheckDCRCriteria = (inputResource: any, perDayHours: number) => {
  let consecutiveDaysCnt = 0
  let startMonth = moment(inputResource.startDate)
  let endMonth = inputResource.promotedResource
    ? moment(inputResource.promotedResource.endDate).add(1, 'days')
    : moment(inputResource.endDate).add(1, 'days')
  let monthsDiff = endMonth.diff(startMonth, 'years') * 12 + (endMonth.diff(startMonth, 'months') % 12)

  let isPromotedResourceExists = false
  if (inputResource.promotedResource) {
    isPromotedResourceExists = true
  }
  consecutiveDaysCnt = CalculateDCRConsecutivedays(inputResource, perDayHours, isPromotedResourceExists)

  if (inputResource.promotedResource) {
    let PromotedPerDayHours = GetBusinessWorkingHours(inputResource.promotedResource, inputResource.costCountry)
    consecutiveDaysCnt += CalculateDCRConsecutivedays(
      inputResource.promotedResource,
      PromotedPerDayHours,
      isPromotedResourceExists
    )
  }
  return consecutiveDaysCnt >= 227 ? true : false
}

const calculateDedicatedTotalResourceFees = (
  pricingPlanData: any,
  resources: any,
  workstream: string,
  isClientRate: boolean = false
) => {
  const resourceLTCost = getResourceLTCost(resources, isClientRate)
  // TODO: DC cost is not available yet, Do value is hardcoded now. Need to calculate when values available
  const resourceDCCost = 0
  // Calculating EY_LT value
  // let EY_LT = (sumOfResourceContingency / resourceLTCost) * 100
  // EY_LT = isFinite(EY_LT) ? EY_LT : 0
  // // Calculating total

  // const total = (resourceLTCost + resourceDCCost) * (1 + EY_LT)
  let contigency = pricingPlanData.summaryViewModel[pricingPlanData.summaryViewModel.selectedScenario].contingency
  const resourcecontigency = getResourceLTCostContigency(resources, contigency, isClientRate)
  // Calculating sum of ctOtherProducts expense
  const expenses = pricingPlanData.expenses
  let workstreamExpenses: any = {}
  if (workstream === 'Unallocated') {
    workstreamExpenses = getWorkstreamExpenses(expenses, 'unspecified')
  } else {
    workstreamExpenses = getWorkstreamExpenses(expenses, workstream)
  }
  //const workstreamExpenses = getWorkstreamExpenses(expenses, workstream)
  const ctProductsCost = workstreamExpenses.reduce((result: number, workstreamExpense: any) => {
    if (workstreamExpense.category === 'ctProducts') result += workstreamExpense.cost
    return result
  }, 0)
  // Calculating total
  //const total = (resourceSTCost + resourceDCCost) * (1 + EY_ST)
  const total = resourceLTCost + resourceDCCost + resourcecontigency + ctProductsCost

  return total
}

const calculateDedicatedCost = (pricingPlanData: any, columns: any[]) => {
  const resources = generateResources(pricingPlanData.resources)
  let sumOfResourceContingency = 0
  if (pricingPlanData?.summaryViewModel?.contingencyTableValues?.resources) {
    const onshore = pricingPlanData.summaryViewModel.contingencyTableValues.resources.onshore || 0
    const gds = pricingPlanData.summaryViewModel.contingencyTableValues.resources.gds || 0
    sumOfResourceContingency = onshore + gds
  }
  let dedicatedCost: any = {}
  const selectedScenario = pricingPlanData.summaryViewModel.selectedScenario
  let isClientRate = false

  columns.forEach((column: any) => {
    if (column.path === 'totalw') {
      // if (selectedScenario === 'client') {
      //   dedicatedCost[column.path] = pricingPlanData?.summaryViewModel?.priceBreakdown?.servicesFees || 0
      // } else {
      let totalcost = pricingPlanData?.summaryViewModel?.detailedSummary?.dedicatedCost?.inclContingency
      dedicatedCost[column.path] = getRounded(totalcost) || 0
      //}
    } else {
      const workstreamResources = getWorkstreamResources(resources, column.title)
      const workstreamResourcesExclSubcontractor = getResourcesExclSubcontractors(workstreamResources)
      // Calculating sum of all resource LT Cost
      //if (selectedScenario === 'client') isClientRate = true
      const total = calculateDedicatedTotalResourceFees(
        pricingPlanData,
        workstreamResourcesExclSubcontractor,
        column.title,
        isClientRate
      )

      dedicatedCost[column.path] = getRounded(total)
    }
  })
  return { ...dedicatedCost, name: 'Dedicated Cost', cost: true }
}

const calculateSubcontractorTotalResourceFees = (
  pricingPlanData: any,
  resources: any,
  workstream: string,
  isClientRate: boolean = false
) => {
  let selectedScenario = pricingPlanData.summaryViewModel.selectedScenario
  let contigency = pricingPlanData.summaryViewModel[selectedScenario].contingency
  const subcontracorContingency = contigency.subcontractors || 0
  const vendorOrSubcontractorExpenseContingency = contigency.vendorSubcontractorFF || 0
  // pricingPlanData?.summaryViewModel?.contingencyTableValues?.expenses?.externalVendorSubcontractor || 0
  //const sumOfOtherExpenseContingency = pricingPlanData?.summaryViewModel?.contingencyTableValues?.expenses?.other || 0
  const expenses = pricingPlanData.expenses

  //const sumOfSubcontractorLTCost = getResourceLTCost(resources, isClientRate)
  const sumOfSubcontractorLTCost = getResourceLTCost(resources, false)
  // Calculating sum of vendor/subcontractor(FF) ser expense
  let workstreamExpenses: any = {}
  if (workstream === 'Unallocated') {
    workstreamExpenses = getWorkstreamExpenses(expenses, 'unspecified')
  } else {
    workstreamExpenses = getWorkstreamExpenses(expenses, workstream)
  }
  const subcontractorsExpenses = getSubcontractorExpenses(workstreamExpenses)
  const vendorOrSubcontractorExpenseLTCost = getExpenseTotalCost(subcontractorsExpenses)
  // Calculating Subcontractor_pct
  /* let Subcontractor_pct =
    ((subcontracorContingency + vendorOrSubcontractorExpenseContingency) /
      (sumOfSubcontractorLTCost + vendorOrSubcontractorExpenseLTCost)) *
    100
  Subcontractor_pct = isFinite(Subcontractor_pct) ? Subcontractor_pct : 0
  // TODO: DC cost is not available yet, Do value is hardcoded now. Need to calculate when values available
  const sumOfSubcontractorDCCost = 0
  const sumofVendorOrSubcontractorFFExpenses = vendorOrSubcontractorExpenseLTCost
  // Calculate OtherExpensesPct
  const otherLumpsumExpenses = getOtherLumpsumExpenses(workstreamExpenses)
  const technologyExpenses = getTechnologyExpenses(workstreamExpenses)
  const sumOfExpenseLTCost = getExpenseTotalCost([...otherLumpsumExpenses, ...technologyExpenses])
  let OtherExpensesPct = (sumOfOtherExpenseContingency / sumOfExpenseLTCost) * 100
  OtherExpensesPct = isFinite(OtherExpensesPct) ? OtherExpensesPct : 0
  // Calculate total

  const total =
    sumOfSubcontractorLTCost * (1 + Subcontractor_pct) +
    sumOfSubcontractorDCCost * (1 + Subcontractor_pct) +
    sumofVendorOrSubcontractorFFExpenses * (1 + OtherExpensesPct)
*/
  let total =
    sumOfSubcontractorLTCost +
    sumOfSubcontractorLTCost * (subcontracorContingency / 100) +
    vendorOrSubcontractorExpenseLTCost +
    vendorOrSubcontractorExpenseLTCost * (vendorOrSubcontractorExpenseContingency / 100)

  return total
}

const calculateCostOfSubcontractors = (pricingPlanData: any, columns: any[]) => {
  const resources = generateResources(pricingPlanData.resources)

  let costOfSubcontractors: any = {}
  const selectedScenario = pricingPlanData.summaryViewModel.selectedScenario
  let isClientRate = false
  columns.forEach((column: any) => {
    if (column.path === 'totalw') {
      costOfSubcontractors[column.path] =
        pricingPlanData?.summaryViewModel?.detailedSummary?.costOfSubcontractors?.inclContingency || 0
    } else {
      // Calculating sum of subcontractor LT Cost
      const workstreamResources = getWorkstreamResources(resources, column.title)
      const workstreamResourcesSubcontractors = getSubcontractorResources(workstreamResources)
      if (selectedScenario === 'client') isClientRate = true
      const total = calculateSubcontractorTotalResourceFees(
        pricingPlanData,
        workstreamResourcesSubcontractors,
        column.title,
        isClientRate
      )
      //if ((column.path = 'Unspecified')) costOfSubcontractors[''] = subcontractorsExpenses //getRounded(total)
      costOfSubcontractors[column.path] = getRounded(total)
    }
  })
  return { ...costOfSubcontractors, name: 'Cost of Subcontractors', cost: true }
}

const calculateBillingExcludingExpenses = (pricingPlanData: any, columns: any[], rows: any[]) => {
  const billingExcludingExpenses: any = {}
  const resources = pricingPlanData.resources
  let isClientRate = false

  columns.forEach((column: any) => {
    if (column.path === 'totalw') {
      billingExcludingExpenses[column.path] =
        pricingPlanData?.summaryViewModel?.detailedSummary?.billingExclExpenses?.inclContingency || 0
    } else {
      let selectedScenario = pricingPlanData.summaryViewModel.selectedScenario
      let contigency = pricingPlanData.summaryViewModel[selectedScenario].contingency
      let eyOnshoreMargin = pricingPlanData.summaryViewModel[selectedScenario].margin.eyOnShore
      let gdsOffshoreMargin = pricingPlanData.summaryViewModel[selectedScenario].margin.gdsOffshore
      let gdsSubcontractorMargin = pricingPlanData.summaryViewModel[selectedScenario].margin.subcontractors
      let fixedfeeMargin = 0
      if (selectedScenario === 'fixedFee') {
        const totalLabourCTCost =
          pricingPlanData?.summaryViewModel?.detailedSummary?.totalLaborCost?.inclContingency || 0
        const totalbillingexclExpenses =
          pricingPlanData?.summaryViewModel?.detailedSummary?.billingExclExpenses?.inclContingency || 0
        let tempmargin = totalLabourCTCost / totalbillingexclExpenses
        fixedfeeMargin = 1 - tempmargin
        eyOnshoreMargin = fixedfeeMargin * 100 //pricingPlanData.summaryViewModel?.detailedSummary?.standardMargin?.inclContingency * 100
        eyOnshoreMargin = eyOnshoreMargin
        gdsOffshoreMargin = eyOnshoreMargin
        gdsSubcontractorMargin = eyOnshoreMargin
      }
      const onshoreworkstreamResources = getOnshoreResources(resources, column.title)
      if (selectedScenario === 'client') isClientRate = true

      const laborCostOnshore = getResourceSTCost(pricingPlanData, onshoreworkstreamResources, isClientRate)
      const contingencyOnshore = getResourceSTCostContigency(onshoreworkstreamResources, contigency, isClientRate)
      const marginInputOnshore = eyOnshoreMargin / 100
      const totalFeesOnshore = isClientRate
        ? laborCostOnshore / (1 - marginInputOnshore)
        : (laborCostOnshore + contingencyOnshore) / (1 - marginInputOnshore) //Math.ceil( //)

      const gdsworkstreamResources = getGDSResources(resources, column.title)

      const laborCostGDS = getResourceSTCost(pricingPlanData, gdsworkstreamResources, isClientRate)
      const contingencyGDS = getResourceSTCostContigency(gdsworkstreamResources, contigency, isClientRate) //calculateGDSDCRContingency(true)getResourceSTCostContigency(onshoreworkstreamResources,contigency)
      const marginInputGDS = gdsOffshoreMargin / 100
      const totalFeesGDS = isClientRate
        ? laborCostGDS / (1 - marginInputGDS)
        : (laborCostGDS + contingencyGDS) / (1 - marginInputGDS) //Math.ceil( //)

      const workstreamResourcesSubcontractors = getSubcontractorWorkstreamResources(resources, column.title)

      const laborCostSubcontractor = getResourceSTCost(pricingPlanData, workstreamResourcesSubcontractors)
      const contingencySubcontractor = getResourceSTCostContigency(
        workstreamResourcesSubcontractors,
        contigency,
        isClientRate
      ) //calculateSubcontractorDCRContingency(true)
      const marginInputSubcontractor = gdsSubcontractorMargin / 100
      const totalFeesSubcontractor = //Math.ceil(
        (laborCostSubcontractor + contingencySubcontractor) / (1 - marginInputSubcontractor)
      //)
      // Calculating sum of ctOtherProducts expense

      const expenses = pricingPlanData.expenses
      let workstreamExpenses: any = {}
      if (column.title === 'Unallocated') {
        workstreamExpenses = getWorkstreamExpenses(expenses, 'unspecified')
      } else {
        workstreamExpenses = getWorkstreamExpenses(expenses, column.title)
      }
      //const workstreamExpenses = getWorkstreamExpenses(expenses, column.title)
      let ctProductsCost = 0
      if (selectedScenario === 'fixedFee') {
        ctProductsCost = workstreamExpenses.reduce((result: number, workstreamExpense: any) => {
          if (workstreamExpense.category === 'ctProducts') result += workstreamExpense.cost
          return result
        }, 0)
        ctProductsCost = ctProductsCost / (1 - fixedfeeMargin)
      }

      let ctTechRevenue = 0
      if (selectedScenario !== 'fixedFee') {
        ctTechRevenue = workstreamExpenses.reduce((result: number, workstreamExpense: any) => {
          if (workstreamExpense.category === 'ctProducts') result += workstreamExpense.revenue
          return result
        }, 0)
      }
      // get Subcontractor Expenses ( This is added to compensate the scenario screen billingexclexpenses which includes cost of subcontractors)
      let subcontractorExpenses = 0
      if (selectedScenario !== 'fixedFee') {
        const subcontractorExpensesRow = rows.find((row) => row.name === 'Cost of Subcontractors')
        subcontractorExpenses = subcontractorExpensesRow[column.path]
      }

      const total =
        totalFeesOnshore +
        totalFeesGDS +
        totalFeesSubcontractor +
        ctProductsCost +
        ctTechRevenue +
        subcontractorExpenses
      /*
      // get Standard Cost
      const standardCostRow = rows.find((row) => row.name === 'Standard Cost')
      const standardCost = standardCostRow[column.path]
      // get EY Margin %
      const eyStandardMarginRow = rows.find((row) => row.name === 'EY Margin %')
      const eyStandardMargin = eyStandardMarginRow[column.path]
      */
      // Calculate total
      //const total = standardCost / (1 - eyStandardMargin)
      billingExcludingExpenses[column.path] = getRounded(total, 0)
    }
  })
  return { ...billingExcludingExpenses, name: 'Billing Excluding Expenses', cost: true }
}

const calculateEyMarginUsingDCR = (pricingPlanData: any, columns: any[], rows: any[]) => {
  const eyMarginUsingDCR: any = {}
  columns.forEach((column: any) => {
    if (column.path === 'totalw') {
      eyMarginUsingDCR[column.path] =
        pricingPlanData?.summaryViewModel?.detailedSummary?.dcrMargin?.inclContingency * 100.0 || 0
    } else {
      // get Billing Excluding Expenses
      const billingExcludingExpensesRow = rows.find((row) => row.name === 'Billing Excluding Expenses')
      const billingExcludingExpenses = billingExcludingExpensesRow[column.path]
      // set total equal to 0 if Billing Excluding Expenses equals 0
      // if (billingExcludingExpenses === 0) {
      //   eyMarginUsingDCR[column.path] = 0
      // } else {
      //   const dedicatedCostRow = rows.find((row) => row.name === 'Dedicated Cost')
      //   const dedicatedCost = dedicatedCostRow[column.path]
      //   const total = (1 - dedicatedCost) / billingExcludingExpenses
      //   eyMarginUsingDCR[column.path] = getRounded(total, 1)
      // }
      //Get Cost of Subcontractors
      const costOfSubcontractorsRow = rows.find((row) => row.name === 'Cost of Subcontractors')
      const costOfSubcontractors = costOfSubcontractorsRow[column.path]
      // get Total Expenses
      let selectedScenario = pricingPlanData.summaryViewModel.selectedScenario
      let dedicatedCost = 0
      // if (selectedScenario === 'client') {
      //   const workstreamResources = getWorkstreamResources(pricingPlanData.resources, column.title)
      //   const workstreamResourcesExclSubcontractor = getResourcesExclSubcontractors(workstreamResources)
      //   // Calculating sum of all resource LT Cost
      //   //if(selectedScenario === 'client' ) isClientRate = true
      //   const total = calculateDedicatedTotalResourceFees(
      //     pricingPlanData,
      //     workstreamResourcesExclSubcontractor,
      //     column.title,
      //     false
      //   )
      //   dedicatedCost = total
      // } else {
      const dedicatedCostRow = rows.find((row) => row.name === 'Dedicated Cost')
      dedicatedCost = dedicatedCostRow[column.path]
      //}
      // Calculating Margin
      const dcrmarginVal =
        (billingExcludingExpenses - costOfSubcontractors - dedicatedCost) /
        (billingExcludingExpenses - costOfSubcontractors)

      eyMarginUsingDCR[column.path] = isFinite(dcrmarginVal)
        ? getRounded(
            billingExcludingExpenses < 0 ? getRounded(dcrmarginVal * 100, 1) * -1 : getRounded(dcrmarginVal * 100, 1),
            1
          )
        : 0
    }
  })
  return { ...eyMarginUsingDCR, name: 'EY Margin % using DCR', cost: false }
}

const calculateEyStandardMarginExclPC = (pricingPlanData: any, columns: any[], rows: any[]) => {
  const resources = generateResources(pricingPlanData.resources)
  let sumOfResourceContingency = 0
  if (pricingPlanData?.summaryViewModel?.contingencyTableValues?.resources) {
    const onshore = pricingPlanData.summaryViewModel.contingencyTableValues.resources.onshore || 0
    const gds = pricingPlanData.summaryViewModel.contingencyTableValues.resources.gds || 0
    sumOfResourceContingency = onshore + gds
  }
  let eyStandardMarginExclPC: any = {}
  columns.forEach((column: any) => {
    if (column.path === 'totalw') {
      eyStandardMarginExclPC[column.path] =
        pricingPlanData?.summaryViewModel?.detailedSummary?.standardMarginExclPNC?.inclContingency * 100 || 0
    } else {
      // Get Billing Excluding Expenses
      const billingExcludingExpensesRow = rows.find((row) => row.name === 'Billing Excluding Expenses')
      const billingExcludingExpenses = billingExcludingExpensesRow[column.path]
      // Get Standard Cost
      let selectedScenario = pricingPlanData?.summaryViewModel.selectedScenario
      let standardCost = 0
      // if (selectedScenario === 'client') {
      //   const workstreamResources = getWorkstreamResources(pricingPlanData.resources, column.title)
      //   const workstreamResourcesExclSubcontractor = getResourcesExclSubcontractors(workstreamResources)
      //   // Calculating sum of all resource LT Cost
      //   //if(selectedScenario === 'client' ) isClientRate = true
      //   const total = calculateTotalResourceFees(
      //     pricingPlanData,
      //     workstreamResourcesExclSubcontractor,
      //     column.title,
      //     false
      //   )
      //   standardCost = total
      // } else {
      const standardCostRow = rows.find((row) => row.name === 'Total Labor & CT Cost') //'Standard Cost')
      standardCost = standardCostRow[column.path]
      //}
      //Calculating sum of partner ST Cost
      const workstreamResources = getWorkstreamResources(resources, column.title)
      const partnerResources = getPartnerResources(workstreamResources)
      const sumOfPartnerSTCost = getResourceSTCost(pricingPlanData, partnerResources)
      // Calculating EY_ST
      // const resourcesExclSubcontractor = getResourcesExclSubcontractors(workstreamResources)
      // const resourceSTCost = getResourceSTCost(resourcesExclSubcontractor)
      // let EY_ST = (sumOfResourceContingency / resourceSTCost) * 100
      // EY_ST = isFinite(EY_ST) ? EY_ST : 0

      //Get Cost of Subcontractors
      const costOfSubcontractorsRow = rows.find((row) => row.name === 'Cost of Subcontractors')
      const costOfSubcontractors = costOfSubcontractorsRow[column.path]

      // Calculating total
      const total =
        (billingExcludingExpenses - (standardCost - sumOfPartnerSTCost) - costOfSubcontractors) /
        (billingExcludingExpenses - costOfSubcontractors)
      //billingExcludingExpenses - (standardCost - sumOfPartnerSTCost * (1 + EY_ST)) / billingExcludingExpenses
      eyStandardMarginExclPC[column.path] = isFinite(total)
        ? getRounded(billingExcludingExpenses < 0 ? getRounded(total * 100, 1) * -1 : getRounded(total * 100, 1), 1)
        : 0
    }
  })
  return { ...eyStandardMarginExclPC, name: 'EY Margin % Excl. Partner Costs', cost: false }
}

const calculateEyMarginUsingDCRExclPC = (pricingPlanData: any, columns: any[], rows: any[]) => {
  const resources = generateResources(pricingPlanData.resources)
  let sumOfResourceContingency = 0
  if (pricingPlanData?.summaryViewModel?.contingencyTableValues?.resources) {
    const onshore = pricingPlanData.summaryViewModel.contingencyTableValues.resources.onshore || 0
    const gds = pricingPlanData.summaryViewModel.contingencyTableValues.resources.gds || 0
    sumOfResourceContingency = onshore + gds
  }
  let eyMarginUsingDCRExclPC: any = {}
  columns.forEach((column: any) => {
    if (column.path === 'totalw') {
      eyMarginUsingDCRExclPC[column.path] =
        pricingPlanData?.summaryViewModel?.detailedSummary?.dcrMarginExclPNC?.inclContingency * 100.0 || 0
    } else {
      // Get Billing Excluding Expenses
      const billingExcludingExpensesRow = rows.find((row) => row.name === 'Billing Excluding Expenses')
      const billingExcludingExpenses = billingExcludingExpensesRow[column.path]
      // Get Dedicated Cost
      let selectedScenario = pricingPlanData.summaryViewModel.selectedScenario
      let dedicatedCost = 0
      // if (selectedScenario === 'client') {
      //   const workstreamResources = getWorkstreamResources(pricingPlanData.resources, column.title)
      //   const workstreamResourcesExclSubcontractor = getResourcesExclSubcontractors(workstreamResources)
      //   // Calculating sum of all resource LT Cost
      //   //if(selectedScenario === 'client' ) isClientRate = true
      //   const total = calculateDedicatedTotalResourceFees(
      //     pricingPlanData,
      //     workstreamResourcesExclSubcontractor,
      //     column.title,
      //     false
      //   )
      //   dedicatedCost = total
      // } else {
      const dedicatedCostRow = rows.find((row) => row.name === 'Dedicated Cost')
      dedicatedCost = dedicatedCostRow[column.path]
      //}
      //Calculating sum of partner LT Cost
      const workstreamResources = getWorkstreamResources(resources, column.title)
      const partnerResources = getPartnerResources(workstreamResources)
      const sumOfPartnerLTCost = getResourceLTCost(partnerResources)
      // Calculating EY_LT
      //const resourcesExclSubcontractor = getResourcesExclSubcontractors(workstreamResources)
      // const resourceLTCost = getResourceLTCost(resourcesExclSubcontractor)
      // let EY_LT = (sumOfResourceContingency / resourceLTCost) * 100
      // EY_LT = isFinite(EY_LT) ? EY_LT : 0
      // // Calculating Total
      // let total = (1 - (dedicatedCost - sumOfPartnerLTCost * (1 + EY_LT))) / billingExcludingExpenses
      //Get Cost of Subcontractors
      const costOfSubcontractorsRow = rows.find((row) => row.name === 'Cost of Subcontractors')
      const costOfSubcontractors = costOfSubcontractorsRow[column.path]

      // Calculating total
      const total =
        (billingExcludingExpenses - (dedicatedCost - sumOfPartnerLTCost) - costOfSubcontractors) /
        (billingExcludingExpenses - costOfSubcontractors)

      eyMarginUsingDCRExclPC[column.path] = isFinite(total)
        ? getRounded(billingExcludingExpenses < 0 ? getRounded(total * 100, 1) * -1 : getRounded(total * 100, 1), 1)
        : 0
    }
  })

  return { ...eyMarginUsingDCRExclPC, name: 'EY Margin % using DCR Excl. Partner Costs', cost: false }
}

const calculateTotalExpenses = (pricingPlanData: any, columns: any[], rows: any[]) => {
  const expenses = pricingPlanData.expenses
  const sumOfMobilitycontingency =
    pricingPlanData?.summaryViewModel?.contingencyTableValues?.expenses?.travelRelated || 0
  const sumOfOtherExpenseContingency = pricingPlanData?.summaryViewModel?.contingencyTableValues?.expenses?.other || 0
  let totalExpense: any = {}
  columns.forEach((column: any) => {
    if (column.path === 'totalw') {
      totalExpense[column.path] =
        pricingPlanData?.summaryViewModel?.detailedSummary?.totalExpenses?.inclContingency || 0
    } else {
      //const workstreamExpenses = getWorkstreamExpenses(expenses, column.title)
      let workstreamExpenses: any = {}
      if (column.title === 'Unallocated') {
        workstreamExpenses = getWorkstreamExpenses(expenses, 'unspecified')
      } else {
        workstreamExpenses = getWorkstreamExpenses(expenses, column.title)
      }

      // Calculting sum of mobility ser values
      const mobilityExpenses = getMobilityExpenses(workstreamExpenses)
      const sumOfMobilitySerValues = getExpenseTotalCost(mobilityExpenses)
      // Calculting mobility percentage
      const sumOfMobilityLTCost = getExpenseTotalCost(mobilityExpenses)
      let MobilityPct = (sumOfMobilitycontingency / sumOfMobilityLTCost) * 100
      MobilityPct = isFinite(MobilityPct) ? MobilityPct : 0
      // Calculting sum of technology ser values
      const technologyExpenses = getTechnologyExpenses(workstreamExpenses)
      const sumOfTechnologySerValues = getExpenseTotalCost(technologyExpenses)
      // Calculting sum of other lumpsum ser values
      const otherLumpsumExpenses = getOtherLumpsumExpenses(workstreamExpenses)
      const sumOfOtherLumpsumSerValues = getExpenseTotalCost(otherLumpsumExpenses)
      // Calculting other expense percentage
      const sumOfOtherExpenseLTCost = sumOfOtherLumpsumSerValues
      let OtherExpensesPct = (sumOfOtherExpenseContingency / sumOfOtherExpenseLTCost) * 100
      OtherExpensesPct = isFinite(OtherExpensesPct) ? OtherExpensesPct : 0
      // Calculating Total
      const total =
        sumOfMobilitySerValues * (1 + MobilityPct / 100) +
        (sumOfTechnologySerValues + sumOfOtherLumpsumSerValues) * (1 + OtherExpensesPct / 100)
      totalExpense[column.path] = isFinite(total) ? getRounded(total) : 0
    }
  })
  return { ...totalExpense, name: 'Total Expenses', cost: true }
}

const calculateTotalBillings = (pricingPlanData: any, columns: any[], rows: any[]) => {
  const totalBillings: any = {}

  columns.forEach((column: any) => {
    if (column.path === 'totalw') {
      totalBillings[column.path] =
        pricingPlanData?.summaryViewModel?.detailedSummary?.totalBillings?.inclContingency || 0
    } else {
      //Get Billing Excluding Expenses
      const billingExcludingExpensesRow = rows.find((row) => row.name === 'Billing Excluding Expenses')
      const billingExcludingExpenses = billingExcludingExpensesRow[column.path]
      //Get Cost of Subcontractors
      const costOfSubcontractorsRow = rows.find((row) => row.name === 'Cost of Subcontractors')
      const costOfSubcontractors = costOfSubcontractorsRow[column.path]
      // get Total Expenses
      const totalExpensesRow = rows.find((row) => row.name === 'Total Expenses')
      const totalExpenses = totalExpensesRow[column.path]
      // Calculting total -- Here CTRevenue is added in billingExcludingExpenses
      const total = billingExcludingExpenses + totalExpenses //+ costOfSubcontractors
      totalBillings[column.path] = getRounded(total)
    }
  })
  return { ...totalBillings, name: 'Total Billing Amount ', cost: true }
}

const calculateEYStandardMargin = (pricingPlanData: any, columns: any, rows: any[]) => {
  let eyStandardMargin: any = {}
  columns.forEach((column: any) => {
    //const value = summaryViewModel?.detailedSummary?.standardMargin?.inclContingency || 0
    if (column.path === 'totalw') {
      eyStandardMargin[column.path] =
        pricingPlanData?.summaryViewModel?.detailedSummary?.standardMargin?.inclContingency || 0
    } else {
      //Get Billing Excluding Expenses
      const billingExcludingExpensesRow = rows.find((row) => row.name === 'Billing Excluding Expenses')
      const billingExcludingExpenses = billingExcludingExpensesRow[column.path]
      //Get Cost of Subcontractors
      const costOfSubcontractorsRow = rows.find((row) => row.name === 'Cost of Subcontractors')
      const costOfSubcontractors = costOfSubcontractorsRow[column.path]
      // get Total Expenses
      let selectedScenario = pricingPlanData?.summaryViewModel.selectedScenario
      let standardcost = 0
      // if (selectedScenario === 'client') {
      //   const workstreamResources = getWorkstreamResources(pricingPlanData.resources, column.title)
      //   const workstreamResourcesExclSubcontractor = getResourcesExclSubcontractors(workstreamResources)
      //   // Calculating sum of all resource LT Cost
      //   //if(selectedScenario === 'client' ) isClientRate = true
      //   const total = calculateTotalResourceFees(
      //     pricingPlanData,
      //     workstreamResourcesExclSubcontractor,
      //     column.title,
      //     false
      //   )
      //   standardcost = total
      // } else {
      const standardcostRow = rows.find((row) => row.name === 'Total Labor & CT Cost') //'Standard Cost')
      standardcost = standardcostRow[column.path]
      //}
      // Calculating Margin
      const marginVal =
        (billingExcludingExpenses - costOfSubcontractors - standardcost) /
        (billingExcludingExpenses - costOfSubcontractors)
      eyStandardMargin[column.path] = isFinite(marginVal)
        ? getRounded(billingExcludingExpenses < 0 ? marginVal * -1 : marginVal, 3)
        : 0
    }

    // eyStandardMargin[column.path] = value
  })
  return { ...eyStandardMargin, name: 'EY Margin %', cost: false }
}

const calculateRatePerHour = (pricingPlanData: any, columns: any[], rows: any[]) => {
  const resources = generateResources(pricingPlanData.resources)
  let ratePerHour: any = {}
  columns.forEach((column: any) => {
    if (column.path === 'totalw') {
      ratePerHour[column.path] = pricingPlanData?.summaryViewModel?.detailedSummary?.ratePerHour?.inclContingency || 0
    } else {
      //Get Billing Excluding Expenses
      const billingExcludingExpensesRow = rows.find((row) => row.name === 'Billing Excluding Expenses')
      const billingExcludingExpenses = billingExcludingExpensesRow[column.path]
      // Calculating total hours
      const workstreamResources = getWorkstreamResources(resources, column.title)
      const resourcesExclSubcontractors = getResourcesExclSubcontractors(workstreamResources)
      const hours = resourcesExclSubcontractors.reduce((result: number, resource: any) => {
        let totalHours = getTotalHours(resource)
        if (pricingPlanData.summaryViewModel.selectedScenario == 'client') {
          if (resource.source == 'gdsOffshore') {
            totalHours += totalHours * (pricingPlanData.summaryViewModel.client.contingency.gdsOffshore / 100)
          } else if (resource.source == 'onshore') {
            totalHours += totalHours * (pricingPlanData.summaryViewModel.client.contingency.eyOnShore / 100)
          } else {
            totalHours += totalHours * (pricingPlanData.summaryViewModel.client.contingency.subcontractors / 100)
          }
        }

        result += totalHours
        return result
      }, 0)
      // Calculating total
      const total = billingExcludingExpenses / hours
      ratePerHour[column.path] = getRounded(isFinite(total) ? total : 0)
    }
  })
  return { ...ratePerHour, name: 'Rate / Hour', cost: true }
}

const calculateRatePerDay = (pricingPlanData: any, columns: any[], rows: any[]) => {
  const resources = generateResources(pricingPlanData.resources)
  let ratePerDay: any = {}
  columns.forEach((column: any) => {
    if (column.path === 'totalw') {
      ratePerDay[column.path] = pricingPlanData?.summaryViewModel?.detailedSummary?.ratePerDay?.inclContingency || 0
    } else {
      //Get Billing Excluding Expenses
      const billingExcludingExpensesRow = rows.find((row) => row.name === 'Billing Excluding Expenses')
      const billingExcludingExpenses = billingExcludingExpensesRow[column.path]
      // Calculating total days
      const workstreamResources = getWorkstreamResources(resources, column.title)
      const resourcesExclSubcontractors = getResourcesExclSubcontractors(workstreamResources)
      const days = resourcesExclSubcontractors.reduce((result: number, resource: any) => {
        let totalDays = getTotalDays(resource)
        if (pricingPlanData.summaryViewModel.selectedScenario == 'client') {
          if (resource.source == 'gdsOffshore') {
            totalDays += totalDays * (pricingPlanData.summaryViewModel.client.contingency.gdsOffshore / 100)
          } else if (resource.source == 'onshore') {
            totalDays += totalDays * (pricingPlanData.summaryViewModel.client.contingency.eyOnShore / 100)
          } else {
            totalDays += totalDays * (pricingPlanData.summaryViewModel.client.contingency.subcontractors / 100)
          }
        }
        result += totalDays
        return result
      }, 0)
      // Calculating total
      const total = Math.ceil(billingExcludingExpenses / days)
      ratePerDay[column.path] = getRounded(isFinite(total) ? total : 0)
    }
  })
  return { ...ratePerDay, name: 'Rate / Day', cost: true }
}

type Props = {
  pricingPlanData: any
  WNote: any
}

const WorkstreamSummary = (props: Props) => {
  let { pricingPlanData } = props
  let { WNote } = props
  const { planCurrency } = pricingPlanData

  let columns = generateColumns(pricingPlanData.workstreams)

  let rows: any = []

  const standardCost = React.useMemo(() => calculateStandardCost(pricingPlanData, columns), [])
  rows = [...rows, standardCost]

  const dedicatedCost = calculateDedicatedCost(pricingPlanData, columns)
  rows = [...rows, dedicatedCost]

  const costOfSubcontractors = calculateCostOfSubcontractors(pricingPlanData, columns)
  rows = [...rows, costOfSubcontractors]

  const billingExcludingExpenses = calculateBillingExcludingExpenses(pricingPlanData, columns, rows)
  rows = [...rows, billingExcludingExpenses]

  const eyStandardMargin = calculateEYStandardMargin(pricingPlanData, columns, rows)
  rows = [...rows, eyStandardMargin]

  const eyMarginUsingDCR = calculateEyMarginUsingDCR(pricingPlanData, columns, rows)
  rows = [...rows, eyMarginUsingDCR]

  const eyStandardMarginExclPC = calculateEyStandardMarginExclPC(pricingPlanData, columns, rows)
  rows = [...rows, eyStandardMarginExclPC]

  const eyMarginUsingDCRExclPC = calculateEyMarginUsingDCRExclPC(pricingPlanData, columns, rows)
  rows = [...rows, eyMarginUsingDCRExclPC]

  const totalExpenses = calculateTotalExpenses(pricingPlanData, columns, rows)
  rows = [...rows, totalExpenses]

  const ratePerHour = calculateRatePerHour(pricingPlanData, columns, rows)
  rows = [...rows, ratePerHour]

  const ratePerDay = calculateRatePerDay(pricingPlanData, columns, rows)
  rows = [...rows, ratePerDay]

  const totalBillings = calculateTotalBillings(pricingPlanData, columns, rows)
  rows = [...rows, totalBillings]

  const _rows = [
    totalBillings,
    totalExpenses,
    billingExcludingExpenses,
    standardCost,
    //dedicatedCost,
    costOfSubcontractors,
    eyStandardMargin,
    eyMarginUsingDCR,
    eyStandardMarginExclPC,
    eyMarginUsingDCRExclPC,
    ratePerDay,
    ratePerHour
  ]

  columns = [{ title: 'name', path: 'name' }, ...columns]

  return (
    <>
      <header>
        <div className='title'>
          Workstream Summary
          <span className='title-note'>{WNote}</span>
        </div>
      </header>
      {/* <div className='wscomment'>This section is in finalisation and will be available shortly.</div> */}
      {pricingPlanData.workstreams?.length > 0 &&
        (pricingPlanData.summaryViewModel.selectedScenario === 'client' ||
          pricingPlanData.summaryViewModel.selectedScenario === 'fixedFee' ||
          pricingPlanData.summaryViewModel.selectedScenario === 'targetMargin') && (
          <div className='workstream-summary-table'>
            <div className='thead'>
              <div className='tr'>
                {columns.map((column) => (
                  <div className='th' key={column.path}>
                    <span>{column.path === 'name' ? '' : column.title}</span>
                  </div>
                ))}
              </div>
            </div>
            <div className='tbody'>
              {_rows.map((item: any) => (
                <div className='tr' key={item.name}>
                  {columns.map(({ path }: any) => (
                    <div className='td' key={path}>
                      {item.name === 'EY Margin %'
                        ? path === 'name'
                          ? item[path]
                          : `${getRounded(item[path] * 100, 1).toFixed(1)} %`
                        : `${item.cost || path === 'name' ? numberWithCommas(item[path]) : item[path].toFixed(1)} ${
                            path === 'name' ? '' : item.cost ? planCurrency : '%'
                          }`}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        )}
      {pricingPlanData.workstreams?.length <= 0 ||
        (pricingPlanData.summaryViewModel.selectedScenario === 'base' && (
          <div className='wscomment'>This section is in finalisation and will be available shortly.</div>
        ))}
    </>
  )
}

export default WorkstreamSummary
