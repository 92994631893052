import { useEffect, useState } from 'react'
import { Field, useFormikContext, ErrorMessage } from 'formik'
import Select from '../formik-controls/select/Select'
import DatePicker from '../formik-controls/date-picker/DatePicker'
import './DealDetails.css'
import TextError from '../formik-controls/TextError'
import { CURRENCY_LIST } from '../utils/constants'
import { getCountries, getRegions, getSectors } from '../utils/fetchHandler'
import { updateInputStyling } from '../utils/updateStyling'

type Props = {}

type Region = {
  area: string
  region: string
}

type Country = {
  country: string
}

type Sector = {
  sectorId: number,
  sectorName: string
}

const DealDetails = (props: Props) => {
  const { values, errors, touched, setFieldValue }: any = useFormikContext()

  const [regions, setRegions] = useState<Region[]>([] as Region[])
  const [countries, setCountries] = useState<Country[]>([] as Country[])
  const [sectors, setSectors] = useState<Sector[]>([] as Sector[])

  useEffect(() => {
    ;(async () => {
      updateInputStyling()
      try {
        try {
          const _sectors = await getSectors()
          _sectors.sort((a: { sectorName: string }, b: { sectorName: string }) => a.sectorName.localeCompare(b.sectorName))
          setSectors(_sectors)
        } catch (error) {}
        if (values.region && values.region !== '') {
          const area = values.region.split('/')[0].trim()
          const region = values.region.split('/')[1].trim()
          const promises = [ getRegions(), getCountries({ area, region })]
          const [_regions, _countries] = await Promise.allSettled(promises).then((results) =>
            results.map((result: any) => result.value)
          )
          _regions.sort((a: { region: string }, b: { region: string }) => a.region.localeCompare(b.region))
          _regions.sort((a: { area: string }, b: { area: string }) => a.area.localeCompare(b.area))
          setRegions(_regions)

          _countries.sort((a: { country: string }, b: { country: string }) => a.country.localeCompare(b.country))
          setCountries(_countries)
        } else {
          const _regions = await getRegions()
          _regions.sort((a: { region: string }, b: { region: string }) => a.region.localeCompare(b.region))
          _regions.sort((a: { area: string }, b: { area: string }) => a.area.localeCompare(b.area))
          setRegions(_regions)
        }
      } catch (error) {}
    })()
  }, [])

  const onChangeRegion = async (selectedValue: string) => {
    setFieldValue('country', '')
    const area = selectedValue.split('/')[0].trim()
    const region = selectedValue.split('/')[1].trim()
    try {
      const _countries = await getCountries({ area, region })
      _countries.sort((a: { country: string }, b: { country: string }) => a.country.localeCompare(b.country))
      setCountries(_countries)
    } catch (error) {}
  }

  const currentYear = new Date().getFullYear()
  const hasDealStartDate = values.dealStartDate && !isNaN(values.dealStartDate.getFullYear())
  const hasDealEndDate = values.dealEndDate && !isNaN(values.dealEndDate.getFullYear())
  const dealStartYear =
    values.dealStartDate !== null && values.dealStartDate.getFullYear() <= currentYear && hasDealStartDate
      ? values.dealStartDate.getFullYear()
      : currentYear
  const dealEndYear = dealStartYear !== currentYear && hasDealEndDate ? values.dealEndDate.getFullYear() : currentYear
  const dealStartDateUpdate = (date: Date) => {
    if (values.dealEndDate < date) values.dealEndDate = null
    for (let a = 0; a < values.phases.length; a++) {
      if (date > values.phases[a].startDate) values.phases[a].startDate = null
      if (date > values.phases[a].endDate) values.phases[a].endDate = null
    }
    for (let a = 0; a < values.workstreams.length; a++) {
      if (date > values.workstreams[a].startDate) values.workstreams[a].startDate = date
    //if (date > values.workstreams[a].endDate) values.workstreams[a].endDate = null
    }
  }

  const dealEndDateUpdate = (date: Date) => {
    for (let a = 0; a < values.phases.length; a++) {
      if (date < values.phases[a].startDate) values.phases[a].startDate = null
      if (date < values.phases[a].endDate) values.phases[a].endDate = null
    }
    for (let a = 0; a < values.workstreams.length; a++) {
      if (date < values.workstreams[a].startDate) values.workstreams[a].startDate = null
      if (date < values.workstreams[a].endDate) values.workstreams[a].endDate = date
    }
  }

  return (
    <div className='deal-details-container'>
      <p className='heading-three deal-details-head'>Deal Details</p>
      <div className='row'>
        <div className='col'>
          <label className='input-label required' htmlFor='engagementName'>
            Deal Name
          </label>
          <br />
          <Field
            id='engagementName'
            name='engagementName'
            maxLength={50}
            className={`input-field ${errors?.engagementName && touched?.engagementName ? 'error' : ''}`}
          />
          <ErrorMessage name='engagementName' component={TextError} />
        </div>
        <div className='col'>
          <label className='input-label required' htmlFor='client'>
            Client Name
          </label>
          <br />
          <Field
            id='client'
            name='client'
            maxLength={50}
            className={`input-field ${errors?.client && touched?.client ? 'error' : ''}`}
          />
          <ErrorMessage name='client' component={TextError} />
        </div>
        <div className='col'>
          <div className='row'>
            <div className='col'>
              <label className='input-label required' htmlFor='planCurrency'>
                Deal Currency
              </label>
              <br />
              <Select
                name='planCurrency'
                options={CURRENCY_LIST.sort((a: { label: string }, b: { label: string }) =>
                  a.label.localeCompare(b.label)
                )}
              />
              <ErrorMessage name='planCurrency' component={TextError} />
            </div>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col'>
          <label className='input-label required' htmlFor='region'>
            Area/Region
          </label>
          <br />
          <Select
            name='region'
            options={regions?.map((item) => ({
              label: `${item.area} / ${item.region}`,
              value: `${item.area} / ${item.region}`
            }))}
            onChange={(option: any) => onChangeRegion(option.value)}
          />
          <ErrorMessage name='region' component={TextError} />
        </div>
        <div className='col'>
          <label className='input-label required' htmlFor='country'>
            Country
          </label>
          <br />
          <Select name='country' options={countries?.map((item) => ({ label: item.country, value: item.country }))} />
          <ErrorMessage name='country' component={TextError} />
        </div>
        <div className='col'>
          <div className='row'>
            <div className='col'>
              <label className='input-label required' htmlFor='dealStartDate'>
                Deal Start Date
              </label>
              <br />
              <DatePicker name='dealStartDate' minDate={new Date(dealStartYear, 0, 1)} onChange={dealStartDateUpdate} />
              <ErrorMessage name='dealStartDate' component={TextError} />
            </div>
            <div className='col'>
              <label className='input-label required' htmlFor='dealEndDate'>
                Deal End Date
              </label>
              <br />
              <DatePicker name='dealEndDate' minDate={new Date(dealEndYear, 0, 1)} onChange={dealEndDateUpdate} />
              <ErrorMessage name='dealEndDate' component={TextError} />
            </div>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col'>
          <label className='input-label' htmlFor='opportunityId'>
            Opportunity ID
          </label>
          <br />
          <Field
            id='opportunityId'
            name='opportunityId'
            maxLength={7}
            className={`input-field ${errors?.opportunityId && touched?.opportunityId ? 'error' : ''}`}
          />
          <ErrorMessage name='opportunityId' component={TextError} />
        </div>
        <div className='col'>
          <label className='input-label required' htmlFor='sectorId'>
            Sector
          </label>
          <br />
          <Select name='sectorId' options={sectors?.map((item) => ({ label: item.sectorName, value: item.sectorId }))} />
          <ErrorMessage name='sectorId' component={TextError} />
        </div>
        
        <div className='col' />
      </div>
      
    </div>
  )
}

export default DealDetails
