import { useEffect, useState } from 'react'
import './DetailedExpenses.css'
import { numberWithCommas } from './../utils/helperFunctions'

interface DetailedExpensesProps {
  selectedColumn: number
  formValues: any
  expensesValues: any
  setExpensesValues: Function
  inputExpenses: any
  inputResources: any
  planCurrency: string
}
const DetailedResources = (props: DetailedExpensesProps) => {
  const { selectedColumn, formValues, expensesValues, setExpensesValues, inputExpenses, inputResources, planCurrency } =
    props
  const [scenarioName, setScenarioName] = useState<string>('')
  // scenarios = ['base', 'targetMargin', 'fixedFee', 'client','marketRate']
  //const scenarios = ['base', 'targetMargin', 'fixedFee', 'client', 'marketRate']
  const scenarios = ['marketRate', 'targetMargin', 'fixedFee', 'client']

  useEffect(() => {
    // if (selectedColumn === 0) {
    //   setScenarioName('base')
    // }

    if (selectedColumn === 0) {
      //setScenarioName('targetMargin')
      setScenarioName('marketRate')
    } else if (selectedColumn === 1) {
      setScenarioName('targetMargin')
    } else if (selectedColumn === 2) {
      setScenarioName('fixedFee')
    } else if (selectedColumn === 3) {
      //setScenarioName('marketRate')
      setScenarioName('client')
    }
  }, [selectedColumn])

  // Run calculations when a new scenario is selected (needed for when a scenario is selected without having made changes to the inputs)
  // Run calculations when inputs are adjusted
  useEffect(() => {
    if (scenarioName !== '' && inputExpenses !== undefined && inputResources !== undefined) {
      calculateCost()
      calculateRecoverable()
      calculateContingency()
      calculateTotalExpenseBillings()
      calculateLTMargin()
      calculateSTMargin()
      calculateTotalExpenses()
    }
  }, [scenarioName, formValues, inputExpenses, inputResources])

  /* Unused functions
  // Get the LT Cost & ST Cost of Mobility Onshore if boolean is false
  // Get the recoverable cost of Mobility Onshore if boolean is true
  const calculateMobilityOnshore = (recoverable: boolean) => {
    let mobilityOnshore = 0

    if (recoverable) {
      // Formula: sum of total cost for EY Onshore mobility expense marked as recoverable
      for (let i = 0; i < inputExpenses.length; i++) {
        // Find if expense is a mobility expense and check the category is not 'otherLumpsum'
        if (
          inputExpenses[i].expenseType === 'Other' &&
          inputExpenses[i].category !== 'otherLumpsum' &&
          inputExpenses[i].category !== 'consultants' &&
          inputExpenses[i].category !== 'subcontractors' &&
          inputExpenses[i].category !== 'alliance' &&
          inputExpenses[i].recoverable === true
        ) {
          const associatedResource = inputExpenses[i].resource
          // Split associatedResource along '-' to get the resource id
          const resourceArray = associatedResource.split('-')
          // Check if the associated resource is an EY onshore resource (note: all named resources are onshore)
          for (let j = 0; j < inputResources.length; j++) {
            if (
              inputResources[j].id === parseInt(resourceArray[0]) &&
              (inputResources[j].source === 'onshore' || inputResources[j].employee.trim() !== 'Unnamed')
            ) {
              // Check if the named resource has rank CSC or SUB (then they are a subcontractor and no longer counted as an onshore resource)
              if (
                inputResources[j].rank !== 'CSC' &&
                inputResources[j].rank !== 'SUB' &&
                inputResources[j].rank !== 'Subcontractor' &&
                inputResources[j].rank !== 'Client Serving Contractor'
              ) {
                mobilityOnshore += inputExpenses[i].cost
              }
            }
          }
        }
      }

      for (let i = 0; i < 4; i++) {
        // Loop through scenarios
        expensesValues[scenarios[i]].mobilityEYOnshore.recoverable = mobilityOnshore // Mobility Onshore Recoverable
      }
    } else {
      // Calculate LT and ST Costs
      for (let i = 0; i < inputExpenses.length; i++) {
        if (
          inputExpenses[i].expenseType === 'Other' &&
          inputExpenses[i].category !== 'otherLumpsum' &&
          inputExpenses[i].category !== 'consultants' &&
          inputExpenses[i].category !== 'subcontractors' &&
          inputExpenses[i].category !== 'alliance'
        ) {
          const associatedResource = inputExpenses[i].resource
          const resourceArray = associatedResource.split('-')
          for (let j = 0; j < inputResources.length; j++) {
            if (
              inputResources[j].id === parseInt(resourceArray[0]) &&
              (inputResources[j].source === 'onshore' || inputResources[j].employee.trim() !== 'Unnamed')
            ) {
              // Check if the named resource has rank CSC or SUB (then they are a subcontractor and no longer counted as an onshore resource)
              if (
                inputResources[j].rank !== 'CSC' &&
                inputResources[j].rank !== 'SUB' &&
                inputResources[j].rank !== 'Subcontractor' &&
                inputResources[j].rank !== 'Client Serving Contractor'
              ) {
                mobilityOnshore += inputExpenses[i].cost
              }
            }
          }
        }
      }

      for (let i = 0; i < 4; i++) {
        expensesValues[scenarios[i]].mobilityEYOnshore.ltCost = mobilityOnshore // Mobility Onshore LT Cost
        expensesValues[scenarios[i]].mobilityEYOnshore.stCost = mobilityOnshore // Mobility Onshore ST Cost
      }
    }

    return Math.ceil(mobilityOnshore)
  }

  */
  /*  Un used functions
  // Get the LT Cost & ST Cost of Mobility Landed if boolean is false
  // Get the recoverable cost of Mobility Landed if boolean is true
  const calculateMobilityLanded = (recoverable: boolean) => {
    let mobilityLanded = 0

    if (recoverable) {
      for (let i = 0; i < inputExpenses.length; i++) {
        if (
          inputExpenses[i].expenseType === 'Other' &&
          inputExpenses[i].category !== 'otherLumpsum' &&
          inputExpenses[i].category !== 'consultants' &&
          inputExpenses[i].category !== 'subcontractors' &&
          inputExpenses[i].category !== 'alliance' &&
          inputExpenses[i].recoverable === true
        ) {
          const associatedResource = inputExpenses[i].resource
          const resourceArray = associatedResource.split('-')
          for (let j = 0; j < inputResources.length; j++) {
            if (inputResources[j].id === parseInt(resourceArray[0]) && inputResources[j].source === 'gdsLanded') {
              mobilityLanded += inputExpenses[i].cost
            }
          }
        }
      }

      for (let i = 0; i < 4; i++) {
        expensesValues[scenarios[i]].mobilityGDSLanded.recoverable = mobilityLanded // Mobility Landed Recoverable
      }
    } else {
      for (let i = 0; i < inputExpenses.length; i++) {
        if (
          inputExpenses[i].expenseType === 'Other' &&
          inputExpenses[i].category !== 'otherLumpsum' &&
          inputExpenses[i].category !== 'consultants' &&
          inputExpenses[i].category !== 'subcontractors' &&
          inputExpenses[i].category !== 'alliance'
        ) {
          const associatedResource = inputExpenses[i].resource
          const resourceArray = associatedResource.split('-')
          for (let j = 0; j < inputResources.length; j++) {
            if (inputResources[j].id === parseInt(resourceArray[0]) && inputResources[j].source === 'gdsLanded') {
              mobilityLanded += inputExpenses[i].cost
            }
          }
        }
      }

      for (let i = 0; i < 4; i++) {
        expensesValues[scenarios[i]].mobilityGDSLanded.ltCost = mobilityLanded // Mobility Landed LT Cost
        expensesValues[scenarios[i]].mobilityGDSLanded.stCost = mobilityLanded // Mobility Landed ST Cost
      }
    }

    return Math.ceil(mobilityLanded)
  }

  */

  // Special calculation for Mobility row Expenses column
  const calculateMobilityCost = () => {
    const categories = ['subcontractor', 'hotelCost', 'perDiem', 'flightCost', 'workPermit', 'otherMobility'] // Expense category from Expenses page
    let cost = Number(0)
    for (let i = 0; i < inputExpenses.length; i++) {
      if (categories.includes(inputExpenses[i].category)) {
        cost += inputExpenses[i].cost
      }
    }

    return Math.ceil(cost)
  }

  // Special calculation for Subcontractors row, LT Cost and ST Cost columns
  const calculateSubcontractorsCost = () => {
    const categories = ['subcontractors', 'alliance', 'consultants', 'IFMs'] // Expense category from Expenses page
    let cost = Number(0)
    for (let i = 0; i < inputExpenses.length; i++) {
      if (categories.includes(inputExpenses[i].category)) {
        cost += inputExpenses[i].cost
      }
    }

    return Math.ceil(cost)
  }

  const calculateTechnologyCost = () => {
    // Sum of Other Technology (Expense) from the Technology Expenses table
    let cost = Number(0)
    for (let i = 0; i < inputExpenses.length; i++) {
      if (inputExpenses[i].category === 'otherTechExpenses') {
        cost += inputExpenses[i].cost
      }
    }
    return Math.ceil(cost)
  }

  const calculateOtherLumpsumsCost = () => {
    let cost = Number(0)
    const categories = ['otherLumpsum', 'legalandknowledgecosts', 'marketingandadvertising']
    for (let i = 0; i < inputExpenses.length; i++) {
      // if (inputExpenses[i].category === 'otherLumpsum') {
      if (categories.includes(inputExpenses[i].category)) {
        cost += inputExpenses[i].cost
      }
    }

    return Math.ceil(cost)
  }

  // Calculate values used for LT Cost and ST Cost
  const calculateCost = () => {
    const rows = ['mobility', 'technologyOtherProducts', 'subcontractors', 'otherLumpSums'] // Row in Scenarios Expenses values
    for (let i = 0; i < 4; i++) {
      // Loop through scenarios
      for (let j = 0; j < 4; j++) {
        // Loop through categories and rows
        let newValue = Number(0)

        if (rows[j] === 'mobility') {
          newValue = calculateMobilityCost()
          expensesValues[scenarios[i]].mobility.ltCost = newValue
          expensesValues[scenarios[i]].mobility.stCost = newValue
        } else if (rows[j] === 'subcontractors') {
          newValue = calculateSubcontractorsCost()
          expensesValues[scenarios[i]].subcontractors.ltCost = newValue
          expensesValues[scenarios[i]].subcontractors.stCost = newValue
        } else if (rows[j] === 'technologyOtherProducts') {
          newValue = calculateTechnologyCost()
          expensesValues[scenarios[i]].technologyOtherProducts.ltCost = newValue
          expensesValues[scenarios[i]].technologyOtherProducts.stCost = newValue
        } else if (rows[j] === 'otherLumpSums') {
          newValue = calculateOtherLumpsumsCost()
          expensesValues[scenarios[i]].otherLumpSums.ltCost = newValue
          expensesValues[scenarios[i]].otherLumpSums.stCost = newValue
        }

        setExpensesValues((prevExpensesValues: any) => ({
          ...prevExpensesValues,
          [scenarios[i]]: {
            ...prevExpensesValues[scenarios[i]],
            [rows[j]]: {
              ...prevExpensesValues[scenarios[i]][rows[j]],
              ltCost: newValue,
              stCost: newValue
            }
          }
        }))
      }
    }
  }

  // Special calculation for Mobility row Recoverable column
  const calculateMobilityRecoverable = () => {
    const categories = ['subcontractor', 'hotelCost', 'perDiem', 'flightCost', 'workPermit', 'otherMobility'] // Expense category from Expenses page
    let recoverable = Number(0)
    for (let i = 0; i < inputExpenses.length; i++) {
      if (categories.includes(inputExpenses[i].category) && inputExpenses[i].recoverable) {
        recoverable += inputExpenses[i].cost
      }
    }

    return Math.ceil(recoverable)
  }

  // Special calculation for Subcontractors row, Recoverable column
  const calculateSubcontractorsRecoverable = () => {
    const categories = ['subcontractors', 'alliance', 'consultants', 'IFMs']
    let recoverable = Number(0)
    for (let i = 0; i < inputExpenses.length; i++) {
      if (categories.includes(inputExpenses[i].category) && inputExpenses[i].recoverable) {
        recoverable += inputExpenses[i].cost
      }
    }

    return Math.ceil(recoverable)
  }

  const calculateTechnologyRecoverable = () => {
    // Sum of all CT Products and Technology Other Products from the Technology Expenses table marked as recoverable
    let recoverable = Number(0)
    for (let i = 0; i < inputExpenses.length; i++) {
      if (inputExpenses[i].category === 'otherTechExpenses' && inputExpenses[i].recoverable) {
        recoverable += inputExpenses[i].cost
      }
    }
    return Math.ceil(recoverable)
  }

  const calculateOtherLumpsumsRecoverable = () => {
    let cost = Number(0)
    const categories = ['otherLumpsum', 'legalandknowledgecosts', 'marketingandadvertising']
    for (let i = 0; i < inputExpenses.length; i++) {
      if (categories.includes(inputExpenses[i].category) && inputExpenses[i].recoverable) {
        cost += inputExpenses[i].cost
      }
    }

    return Math.ceil(cost)
  }

  const calculateRecoverable = () => {
    const rows = ['mobility', 'technologyOtherProducts', 'subcontractors', 'otherLumpSums']
    for (let i = 0; i < 4; i++) {
      // Loop through scenarios
      for (let j = 0; j <= 4; j++) {
        // Loop through categories and rows
        let newValue = Number(0)

        if (rows[j] === 'mobility') {
          newValue = calculateMobilityRecoverable()
          expensesValues[scenarios[i]].mobility.recoverable = newValue
        } else if (rows[j] === 'subcontractors') {
          newValue = calculateSubcontractorsRecoverable()
          expensesValues[scenarios[i]].subcontractors.recoverable = newValue
        } else if (rows[j] === 'technologyOtherProducts') {
          newValue = calculateTechnologyRecoverable()
          expensesValues[scenarios[i]].technologyOtherProducts.recoverable = newValue
        } else if (rows[j] === 'otherLumpSums') {
          newValue = calculateOtherLumpsumsRecoverable()
          expensesValues[scenarios[i]].otherLumpSums.recoverable = newValue
        }

        setExpensesValues((prevExpensesValues: any) => ({
          ...prevExpensesValues,
          [scenarios[i]]: {
            ...prevExpensesValues[scenarios[i]],
            [rows[j]]: {
              ...prevExpensesValues[scenarios[i]][rows[j]],
              recoverable: newValue
            }
          }
        }))
      }
    }
  }

  const calculateContingency = () => {
    const rows = ['mobility', 'technologyOtherProducts', 'subcontractors', 'otherLumpSums']

    for (let i = 0; i < 4; i++) {
      // Loop through scenarios
      for (let j = 0; j < 4; j++) {
        // Loop through categories and rows
        let newValue = Number(0)
        if (rows[j] === 'mobility') {
          const mobilityRecoverable = expensesValues[scenarios[i]].mobility.recoverable
          const contingencyInputOnshore = formValues[scenarios[i]].contingency.mobilityEYOnshore / 100
          newValue = Math.ceil(mobilityRecoverable * contingencyInputOnshore)
        } else if (rows[j] === 'subcontractors') {
          // TODO KY most likely will need a BE change, the categories don't match between BE and FE
          const subcontractorsRecoverable = expensesValues[scenarios[i]].subcontractors.recoverable
          const contingencyInput = formValues[scenarios[i]].contingency.vendorSubcontractorFF / 100
          newValue = Math.ceil(subcontractorsRecoverable * contingencyInput)
        } else {
          const recoverable = expensesValues[scenarios[i]][rows[j]].recoverable
          const contingencyInput = formValues[scenarios[i]].contingency[rows[j]] / 100
          newValue = Math.ceil(recoverable * contingencyInput)
        }

        expensesValues[scenarios[i]][rows[j]].contingency = newValue

        setExpensesValues((prevExpensesValues: any) => ({
          ...prevExpensesValues,
          [scenarios[i]]: {
            ...prevExpensesValues[scenarios[i]],
            [rows[j]]: {
              ...prevExpensesValues[scenarios[i]][rows[j]],
              contingency: newValue
            }
          }
        }))
      }
    }
  }

  const calculateLTMargin = () => {
    const rows = ['mobility', 'technologyOtherProducts', 'subcontractors', 'otherLumpSums']
    for (let i = 0; i < 4; i++) {
      // Loop through scenarios
      for (let j = 0; j < 4; j++) {
        // Loop through categories and rows
        let newValue = Number(0)
        if (rows[j] === 'mobility') {
          const totalExpenseBillingsOnshore = expensesValues[scenarios[i]].mobilityEYOnshore.totalExpenseBillings
          const ltCostOnshore = expensesValues[scenarios[i]].mobilityEYOnshore.ltCost
          const contingencyOnshore = expensesValues[scenarios[i]].mobilityEYOnshore.contingency
          const newValueOnshore = totalExpenseBillingsOnshore - ltCostOnshore - contingencyOnshore
          expensesValues[scenarioName].mobilityEYOnshore.ltMargin = newValueOnshore

          const totalExpenseBillingsLanded = expensesValues[scenarios[i]].mobilityGDSLanded.totalExpenseBillings
          const ltCostLanded = expensesValues[scenarios[i]].mobilityGDSLanded.ltCost
          const contingencyLanded = expensesValues[scenarios[i]].mobilityGDSLanded.contingency
          const newValueLanded = totalExpenseBillingsLanded - ltCostLanded - contingencyLanded
          expensesValues[scenarioName].mobilityGDSLanded.ltMargin = newValueLanded

          newValue = Math.ceil(newValueOnshore + newValueLanded)
        } else {
          const totalExpenseBillings = expensesValues[scenarios[i]][rows[j]].totalExpenseBillings
          const ltCost = expensesValues[scenarios[i]][rows[j]].ltCost
          const contingency = expensesValues[scenarios[i]][rows[j]].contingency
          newValue = Math.ceil(totalExpenseBillings - ltCost - contingency)
        }

        expensesValues[scenarios[i]][rows[j]].ltMargin = newValue

        setExpensesValues((prevExpensesValues: any) => ({
          ...prevExpensesValues,
          [scenarios[i]]: {
            ...prevExpensesValues[scenarios[i]],
            [rows[j]]: {
              ...prevExpensesValues[scenarios[i]][rows[j]],
              ltMargin: newValue
            }
          }
        }))
      }
    }
  }

  const calculateSTMargin = () => {
    const rows = ['mobility', 'technologyOtherProducts', 'subcontractors', 'otherLumpSums']
    for (let i = 0; i < 4; i++) {
      // Loop through scenarios
      for (let j = 0; j < 4; j++) {
        // Loop through categories and rows
        let newValue = Number(0)
        if (rows[j] === 'mobility') {
          const totalExpenseBillingsOnshore = expensesValues[scenarios[i]].mobilityEYOnshore.totalExpenseBillings
          const stCostOnshore = expensesValues[scenarios[i]].mobilityEYOnshore.stCost
          const contingencyOnshore = expensesValues[scenarios[i]].mobilityEYOnshore.contingency
          const newValueOnshore = totalExpenseBillingsOnshore - stCostOnshore - contingencyOnshore
          expensesValues[scenarioName].mobilityEYOnshore.stMargin = newValueOnshore

          const totalExpenseBillingsLanded = expensesValues[scenarios[i]].mobilityGDSLanded.totalExpenseBillings
          const stCostLanded = expensesValues[scenarios[i]].mobilityGDSLanded.stCost
          const contingencyLanded = expensesValues[scenarios[i]].mobilityGDSLanded.contingency
          const newValueLanded = totalExpenseBillingsLanded - stCostLanded - contingencyLanded
          expensesValues[scenarioName].mobilityGDSLanded.stMargin = newValueLanded

          newValue = Math.ceil(newValueOnshore + newValueLanded)
        } else {
          const totalExpenseBillings = expensesValues[scenarios[i]][rows[j]].totalExpenseBillings
          const stCost = expensesValues[scenarios[i]][rows[j]].stCost
          const contingency = expensesValues[scenarios[i]][rows[j]].contingency
          newValue = Math.ceil(totalExpenseBillings - stCost - contingency)
        }

        expensesValues[scenarios[i]][rows[j]].stMargin = newValue

        setExpensesValues((prevExpensesValues: any) => ({
          ...prevExpensesValues,
          [scenarios[i]]: {
            ...prevExpensesValues[scenarios[i]],
            [rows[j]]: {
              ...prevExpensesValues[scenarios[i]][rows[j]],
              stMargin: newValue
            }
          }
        }))
      }
    }
  }

  const calculateTotalExpenseBillings = () => {
    const rows = ['mobility', 'technologyOtherProducts', 'subcontractors', 'otherLumpSums']
    for (let i = 0; i < 4; i++) {
      // Loop through scenarios
      for (let j = 0; j < 4; j++) {
        // Loop through categories and rows
        let newValue = Number(0)
        const recoverable = expensesValues[scenarios[i]][rows[j]].recoverable
        const contingency = expensesValues[scenarios[i]][rows[j]].contingency
        newValue = Math.ceil(recoverable + contingency)

        expensesValues[scenarios[i]][rows[j]].totalExpenseBillings = newValue

        setExpensesValues((prevExpensesValues: any) => ({
          ...prevExpensesValues,
          [scenarios[i]]: {
            ...prevExpensesValues[scenarios[i]],
            [rows[j]]: {
              ...prevExpensesValues[scenarios[i]][rows[j]],
              totalExpenseBillings: newValue
            }
          }
        }))
      }
    }
  }

  const calculateTotalExpenses = () => {
    const columns = ['ltCost', 'recoverable', 'stCost', 'contingency', 'ltMargin', 'stMargin', 'totalExpenseBillings']

    for (let i = 0; i < 4; i++) {
      // Iterate through all scenarios
      for (let j = 0; j < 7; j++) {
        // Iterate through all columns
        const mobility = expensesValues[scenarios[i]].mobility[columns[j]]
        const technologyOtherProducts = expensesValues[scenarios[i]].technologyOtherProducts[columns[j]]
        const subcontractors = expensesValues[scenarios[i]].subcontractors[columns[j]]
        const otherLumpSums = expensesValues[scenarios[i]].otherLumpSums[columns[j]]

        const newValue = Math.ceil(mobility + technologyOtherProducts + subcontractors + otherLumpSums)
        expensesValues[scenarios[i]].totalExpenses[columns[j]] = newValue

        setExpensesValues((prevExpensesValues: any) => ({
          ...prevExpensesValues,
          [scenarios[i]]: {
            ...prevExpensesValues[scenarios[i]],
            totalExpenses: {
              ...prevExpensesValues[scenarios[i]].totalExpenses,
              [columns[j]]: newValue
            }
          }
        }))
      }
    }
  }

  return (
    <div className='detailed-expenses-container'>
      <table>
        <thead>
          <tr>
            <th className='body-text'></th>
            <th className='body-text'>Expenses</th>
            <th className='body-text'>Recoverable</th>
            <th className='body-text'>Contingency</th>
            <th className='body-text'>Total Expense Billings</th>
            <th className='body-text'></th>
            <th className='body-text'></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className='body-text'>Travel-Related</td>
            <td className='body-text'>
              {scenarioName && numberWithCommas(expensesValues[scenarioName].mobility.ltCost) + ' ' + planCurrency}
            </td>
            <td className='body-text'>
              {scenarioName && numberWithCommas(expensesValues[scenarioName].mobility.recoverable) + ' ' + planCurrency}
            </td>
            <td className='body-text'>
              {scenarioName && numberWithCommas(expensesValues[scenarioName].mobility.contingency) + ' ' + planCurrency}
            </td>
            <td className='body-text'>
              {scenarioName &&
                numberWithCommas(expensesValues[scenarioName].mobility.totalExpenseBillings) + ' ' + planCurrency}
            </td>
            <td className='body-text'></td>
            <td className='body-text'></td>
          </tr>
          <tr>
            <td className='body-text'>Other Technology</td>
            <td className='body-text'>
              {scenarioName &&
                numberWithCommas(expensesValues[scenarioName].technologyOtherProducts.ltCost) + ' ' + planCurrency}
            </td>
            <td className='body-text'>
              {scenarioName &&
                numberWithCommas(expensesValues[scenarioName].technologyOtherProducts.recoverable) + ' ' + planCurrency}
            </td>
            <td className='body-text'>
              {scenarioName &&
                numberWithCommas(expensesValues[scenarioName].technologyOtherProducts.contingency) + ' ' + planCurrency}
            </td>
            <td className='body-text'>
              {scenarioName &&
                numberWithCommas(expensesValues[scenarioName].technologyOtherProducts.totalExpenseBillings) +
                  ' ' +
                  planCurrency}
            </td>
            <td className='body-text'></td>
            <td className='body-text'></td>
          </tr>
          <tr>
            <td className='body-text'>External Vendor: Subcontractor</td>
            <td className='body-text'>
              {scenarioName &&
                numberWithCommas(expensesValues[scenarioName].subcontractors.ltCost) + ' ' + planCurrency}
            </td>
            <td className='body-text'>
              {scenarioName &&
                numberWithCommas(expensesValues[scenarioName].subcontractors.recoverable) + ' ' + planCurrency}
            </td>
            <td className='body-text'>
              {scenarioName &&
                numberWithCommas(expensesValues[scenarioName].subcontractors.contingency) + ' ' + planCurrency}
            </td>
            <td className='body-text'>
              {scenarioName &&
                numberWithCommas(expensesValues[scenarioName].subcontractors.totalExpenseBillings) + ' ' + planCurrency}
            </td>
            <td className='body-text'></td>
            <td className='body-text'></td>
          </tr>
          <tr>
            <td className='body-text'>Other</td>
            <td className='body-text'>
              {scenarioName && numberWithCommas(expensesValues[scenarioName].otherLumpSums.ltCost) + ' ' + planCurrency}
            </td>
            <td className='body-text'>
              {scenarioName &&
                numberWithCommas(expensesValues[scenarioName].otherLumpSums.recoverable) + ' ' + planCurrency}
            </td>
            <td className='body-text'>
              {scenarioName &&
                numberWithCommas(expensesValues[scenarioName].otherLumpSums.contingency) + ' ' + planCurrency}
            </td>
            <td className='body-text'>
              {scenarioName &&
                numberWithCommas(expensesValues[scenarioName].otherLumpSums.totalExpenseBillings) + ' ' + planCurrency}
            </td>
            <td className='body-text'></td>
            <td className='body-text'></td>
          </tr>
          <tr>
            <td className='body-text bold-text'>Total Expenses</td>
            <td className='body-text'>
              {scenarioName && numberWithCommas(expensesValues[scenarioName].totalExpenses.ltCost) + ' ' + planCurrency}
            </td>
            <td className='body-text'>
              {scenarioName &&
                numberWithCommas(expensesValues[scenarioName].totalExpenses.recoverable) + ' ' + planCurrency}
            </td>
            <td className='body-text'>
              {scenarioName &&
                numberWithCommas(expensesValues[scenarioName].totalExpenses.contingency) + ' ' + planCurrency}
            </td>
            <td className='body-text'>
              {scenarioName &&
                numberWithCommas(expensesValues[scenarioName].totalExpenses.totalExpenseBillings) + ' ' + planCurrency}
            </td>
            <td className='body-text'></td>
            <td className='body-text'></td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default DetailedResources
