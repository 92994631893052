import React, { ChangeEvent, ReactElement } from 'react'
import './ImportModal.css'
import XLSXIcon from '../../assets/xlsx.svg'
import { useState } from 'react'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { importPricingPlan } from '../utils/fetchHandler'

const ImportModal = (): ReactElement => {
  const [imported, setImported] = useState<boolean | null>(null) // XLSX file uploaded - controls text rendering
  const [correctType, setCorrectType] = useState<boolean | null>(null) // Indicates if type is correct according to API call
  const [fileName, setFileName] = useState('') // Stores file name to be displayed in modal
  const history = useHistory()

  useEffect(() => {
    checkCorrectType()
  }, [correctType])

  // Hides the modal and "resets" it to the original text
  const hideImportModal = () => {
    const importModal = document.getElementById('import-modal') as HTMLElement
    importModal.style.display = 'none'

    setImported(null)

    const fileUpload = document.getElementById('import-input') as HTMLInputElement
    fileUpload.value = ''
    setFileName('')
  }

  // Calls API to check if the uploaded type is correct
  const checkImportStatus = async () => {
    const data = new FormData()
    const inputFile = document.getElementById('import-input') as HTMLInputElement
    const fileData = inputFile.files![0]
    data.append('data', fileData!)

    try {
      const result = await importPricingPlan(data)
      if (result?.pricingPlanId) {
        setCorrectType(true)
        const location = {
          pathname: `/pricing-plan/${result.pricingPlanId}/global-input`,
          state: { imported: true }
        }
        history.push(location)
      }
      setCorrectType(false)
    } catch (error) {
      setCorrectType(false)
    }
  }

  const onFileChange = (e: ChangeEvent<HTMLInputElement>): void => {
    // Added typecheck to prevent drag and drop from allowing .mp4 files which are not able to be processed by the backend API
    if (
      e.target.files?.length === 1 &&
      e.target.files![0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      setFileName(e.target.files![0].name)
      setImported(true)
    } else {
      // No file uploaded
      setCorrectType(false)
      setImported(false)

      const fileUpload = document.getElementById('import-input') as HTMLInputElement
      fileUpload.value = ''
      setFileName('')
      return
    }
  }

  // Sets the import status based on whether the upload is of the correct type
  const checkCorrectType = () => {
    if (correctType === false) {
      const fileUpload = document.getElementById('import-input') as HTMLInputElement
      fileUpload.value = ''
      setFileName('')
      setImported(false)
    }

    // Reset correctType value for next file
    setCorrectType(null)
  }

  // Controls opacity of drop down area when mouse hovers above with a file
  const dragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    const dropZone = Array.from(document.getElementsByClassName('drop-zone') as HTMLCollectionOf<HTMLElement>)
    dropZone[0].style.opacity = '0.5'
  }

  // Removes opacity of drop down area once file is dropped
  const fileDropped = () => {
    const dropZone = Array.from(document.getElementsByClassName('drop-zone') as HTMLCollectionOf<HTMLElement>)
    dropZone[0].style.opacity = '1'
  }

  return (
    <div>
      <div id='import-modal'>
        <div className='import-modal-content'>
          <p className='import-modal-heading heading-three'>Import Pricing Plan</p>
          <p className='heading-three'>Please upload your Mercury Pricing Plan export in XLSX format below.</p>
          <div className='drop-zone'>
            {imported === null && (
              <label className='drop-zone-text body-text'>Drag your file here or click to browse.</label>
            )}
            {imported === false && <p className='drop-zone-failed-text body-text'>Upload failed. Please try again.</p>}
            {imported && fileName !== '' && <p className='drop-zone-filename-text body-text'>{fileName}</p>}
            {imported !== true && <img className='drop-zone-image' src={XLSXIcon} alt='XLSX icon'></img>}
            {imported === true && (
              <p className='drop-zone-replace-text body-text'>To replace, click this area or drag in a new file.</p>
            )}
          </div>
          <input
            id='import-input'
            type='file'
            name='importedFile'
            accept='.xlsx'
            onChange={onFileChange}
            onDragEnter={dragEnter}
            onDragLeave={fileDropped}
            onDrop={fileDropped}
          />
          <div className='import-modal-buttons'>
            <button className='secondary-button heading-three' onClick={hideImportModal}>
              Cancel
            </button>
            <button className='primary-button heading-three' onClick={checkImportStatus}>
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ImportModal
