import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useLocation, useHistory, Link } from 'react-router-dom'
import { useMsal } from '@azure/msal-react'
import { logoutUser } from '../utils/fetchHandler'

import './Header.css'
import ey_logo from '../../assets/EY_Logo_Beam_RGB_White.svg'
import WhiteTriangle from '../../assets/white_triangle.svg'
import YellowTriangle from '../../assets/yellow_triangle.svg'
import PrivacyModal from '../modals/PrivacyModal'
import { UserContext } from '../../App'

interface HeaderProps {
  isFixedWidth: boolean
}

const Header = (props: HeaderProps): ReactElement => {
  const { isFixedWidth } = props
  let location = useLocation()
  let history = useHistory()

  const [isSelected, setIsSelected] = useState<boolean>(false)
  const [isAdmin, setIsAdmin] = useState<boolean>(false)
  const userInfo = useContext(UserContext)
  const userId = useContext(UserContext)[1]
  const [isEnabled, setIsEnabled] = useState(sessionStorage.getItem('isEnabled') === 'true');
  localStorage.setItem('CurrentUserID', userId)


  useEffect(() => {
    const handleIsEnabledUpdate = () => {
      setIsEnabled(sessionStorage.getItem('isEnabled') === 'true');
    };

    window.addEventListener('isEnabledUpdated', handleIsEnabledUpdate);
    
    return () => {
      window.removeEventListener('isEnabledUpdated', handleIsEnabledUpdate);
    };
  }, []);

  useEffect(() => {
    try {
      const _adminGroupIds = ['67dca343-f08c-4ba0-bbc0-36a01606d9ec', '944774af-dc00-42d8-812d-f1f1f765a495']
      for (let a = 2; a < userInfo.length; a++) {
        if (userInfo[a] === _adminGroupIds[0] || userInfo[a] === _adminGroupIds[1]) {
          setIsAdmin(true)
          break
        }
      }
    } catch (error) {
      console.error('Failed to check Admin')
    }
  }, [userInfo])

  useEffect(() => {
    const name = Array.from(document.getElementsByClassName('header-user-name') as HTMLCollectionOf<HTMLElement>)
    const triangle = Array.from(document.getElementsByClassName('header-triangle') as HTMLCollectionOf<HTMLElement>)
    const dropdownContent = Array.from(
      document.getElementsByClassName('header-dropdown-content') as HTMLCollectionOf<HTMLElement>
    )

    if (isSelected) {
      name[0].style.color = '#ffe600'
      triangle[0].style.transform = 'rotateX(180deg) translateY(1px)'
      dropdownContent[0].style.display = 'block'
    } else {
      name[0].style.color = '#ffffff'
      triangle[0].style.transform = 'rotateX(0deg)'
      dropdownContent[0].style.display = 'none'
    }
  }, [isSelected])

  // Close the dropdown menu if the user clicks outside of it
  window.onclick = function (event) {
    const userText = Array.from(document.getElementsByClassName('header-user-name') as HTMLCollectionOf<HTMLElement>)
    const triangleImg = Array.from(document.getElementsByClassName('header-triangle') as HTMLCollectionOf<HTMLElement>)
    const button = Array.from(document.getElementsByClassName('header-name-button') as HTMLCollectionOf<HTMLElement>)

    const dropdownContent = Array.from(
      document.getElementsByClassName('header-dropdown-content') as HTMLCollectionOf<HTMLElement>
    )
    if (event.target !== userText[0] && event.target !== triangleImg[0] && event.target !== button[0] && isSelected) {
      if (dropdownContent[0]?.style) dropdownContent[0].style.display = 'none'
      setIsSelected(false)
    }
  }

  const showPrivacyModal = () => {
    const privacyModal = document.getElementById('privacy-modal') as HTMLElement
    privacyModal.style.display = 'block'
    privacyModal.style.backgroundColor = 'rgba(0, 0, 0, 0.5)'
  }

  const showSupportGuide = () => {
    const newWindow = window.open(
      ' https://sites.ey.com/sites/ConsultingCostingTool',
      '_blank',
      'noopener,noreferrer'
    )
    if (newWindow) newWindow.opener = null
  }

  const showCheatSheet= () => {
    const newWindow = window.open(
      'https://sites.ey.com/:x:/s/CCT-Roll-Out/EVcqAh2ldshGpOIL8gWtXHwBDj-xwFNWSNa54NY4tGKFFg',
      '_blank',
      'noopener,noreferrer'
    )
    if (newWindow) newWindow.opener = null
  }

  const { instance } = useMsal()

  const handleLogout = async (instance: any) => {
    // Call API to invalidate the token
    await logoutUser(userId)
    // Remove information stored in sessionStorage and localStorage (e.g. access tokens)
    sessionStorage.clear()
    localStorage.clear()
    instance.logoutRedirect().catch((e: any) => {
      console.error(e)
    })
  }

  return (
    <div className={isFixedWidth ? 'header header-is-fixed' : 'header'}>
      <Link to='/'>
        <div className='header-title-container'>
          <img className='header-logo' src={ey_logo} alt='EY logo' />
          <h1 className='header-title heading-one'>Consulting Costing Tool (CCT)</h1>
        </div>
      </Link>

      <div className='header-user-container'>
        <div className='dropdown-container'>
          <button className='header-name-button' 
            onClick={() => setIsSelected((isSelected) => !isSelected)}
            disabled={!isEnabled}
            style={{
              opacity: !isEnabled ? 0.5 : 1,
              pointerEvents: !isEnabled ? 'none' : 'auto'
            }}
            >
            <h3 className='heading-three header-user-name'>{useContext(UserContext)[0]}</h3>
            <img
              className='header-triangle'
              src={!isSelected ? WhiteTriangle : YellowTriangle}
              alt='Triangle depicting column as ascending or descending'
            />
          </button>
          <div className='header-dropdown-content'>
            {isAdmin ? (
              location.pathname === '/admin-dashboard' ? (
                <button className='body-text' onClick={() => history.push('/')}>
                  User View
                </button>
              ) : (
                <button className='body-text' onClick={() => history.push('/admin-dashboard')}>
                  Admin View
                </button>
              )
            ) : null}
            <button className='body-text' onClick={showPrivacyModal}>
              Privacy Statement
            </button>
            <button className='body-text' onClick={showSupportGuide}>
              CCT Support
            </button>
            <button className='body-text' onClick={showCheatSheet}>
              CCT Cheat Sheet
            </button>
          </div>
        </div>
        <button className='header-logout-button' onClick={() => handleLogout(instance)}>
          <span className='material-icons'>logout</span>
          <h3 className='heading-three header-logout-text'>Logout</h3>
        </button>
      </div>

      <PrivacyModal isAccepted={true} />
    </div>
  )
}

export default Header
