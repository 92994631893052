import moment from 'moment'

interface Props {
  hasAsyncedChanges: boolean
  isLoadingChanges: boolean
  onApplyNewRates: () => void
  rates: any[]
}

const Footer = (props: Props) => {
  let lastSyncedDate = props.rates?.reduce((result, rate) => {
    if (!result) return rate.uploadDate
    const isAfter = moment(rate.uploadDate).isAfter(result)
    if (isAfter) result = rate.uploadDate
    return result
  }, null)
  if (lastSyncedDate) lastSyncedDate = moment(moment.utc(lastSyncedDate)).local().format('DD/MM/YYYY HH:mm')
  const zone_name = moment.tz.guess()
  const timezone = moment.tz(zone_name).zoneAbbr()

  const { hasAsyncedChanges, onApplyNewRates, isLoadingChanges } = props
  return (
    <div className='admin-dashboard-footer'>
      <div className='last-synced-text'>
        {hasAsyncedChanges
          ? 'You have unsynced changes'
          : lastSyncedDate
          ? `Last synced: ${lastSyncedDate} ${timezone}`
          : null}
      </div>
      {isLoadingChanges ? (
        <button className='primary-button' type='button'>
          <span className='heading-three'>Cancel</span>
        </button>
      ) : (
        <button className='primary-button' type='button' onClick={onApplyNewRates}>
          <span className='heading-three'>Apply New Rates</span>
        </button>
      )}
    </div>
  )
}

export default Footer
