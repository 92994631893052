import { useEffect, useState } from 'react'
import Modal from 'react-modal'
import styles from './CountryModal.module.css'


type Props = {
  availableCountries: any[]
  isOpen: boolean
  onCancel: () => void
  onConfirm: (value: any) => void
}

const CountryModal = (props: Props) => {
  const { availableCountries: _availableCountries } = props
  const [selectedCostCenter, setSecletedCostCenter] = useState<any>(null)
  const [availableCostCentres, setCostCentres] = useState<any[]>([])
  const [availableCountries, setAvailableCountries] = useState<any[]>([])
  const [selectedCountry, setSelectedCountry] = useState<any>(null)
  const [selectedResource, setSelectedResource] = useState<any>(null)


  useEffect(() => {
    setAvailableCountries(_availableCountries)
  }, [_availableCountries])
  
  return (
    <Modal
      isOpen={props.isOpen}
      contentLabel='Country Modal'
      className={styles.countryModal}
      overlayClassName={styles.countryModalOverlay}
    >
      <h3 className='heading-three m-0'>Select Market Country Tier</h3>

      <p className={styles.description}>
        Multiple Market Country Tiers associated. Please select the most appropriate tier
        for accurate market rate.
      </p>


      <div className={styles.resultsContainer}>
        {availableCountries?.map((country, index) => {
          return (
            <div
              key={index}
              className={`${styles.resultItem} ${
                selectedCountry && country.countryId === selectedCountry.countryId ? styles.selected : ''
              }`}
              onClick={() => setSelectedCountry(country)}
            >
              <span className={styles.countryText}>
                {country.countryName}
              </span>
            </div>
          )
        })}
      </div>

      <div className={styles.footerContainer}>
        <button
          className='secondary-button'
          type='button'
          onClick={() => {
            //setSecletedCostCenter('')
            setSelectedCountry('')
            props.onCancel()
          }}
        >
          <span className='heading-three'>Cancel</span>
        </button>
        <button
          className='primary-button'
          type='button'
          onClick={() => {
            if (selectedCountry) {
              props.onConfirm(selectedCountry)
              //setSecletedCostCenter('')
              setSelectedCountry('')
            }
          }}
        >
          <span className='heading-three'>Confirm</span>
        </button>
      </div>
    </Modal>
  )
}

export default CountryModal
