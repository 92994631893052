import React, { useRef } from 'react'
import useClickOutside from '../utils/useClickOutside'
import Checkbox from 'rc-checkbox'
import 'rc-checkbox/assets/index.css'
import './ShowHideChecklist.css'

const options = [
  { key: 'Workstream', value: 'workstream' },
  { key: 'Role', value: 'role' },
  { key: 'Cost Rate/Hour', value: 'costRateHour' },
  { key: 'Client Rate/Hour', value: 'clientRateHour' }
]

const ColumnSelect = (props: any) => {
  const modalRef = useRef<any>()

  useClickOutside(modalRef, () => {
    var checkList = document.getElementById('list1')
    if (checkList) {
      checkList.classList.remove('visible')
    }
  })

  const onClickAnchor = (evt: any) => {
    var checkList = document.getElementById('list1')
    if (checkList && checkList.classList.contains('visible')) {
      checkList.classList.remove('visible')
    } else {
      checkList && checkList.classList.add('visible')
    }
  }

  return (
    <div>
      <span className='desc-text'>Show/Hide Columns: </span>
      <div id='list1' className='show-hide-check-list' ref={modalRef}>
        <span className='anchor' onClick={onClickAnchor}>
          {props.viewedColumns.length} Selected
        </span>
        <ul className='items'>
          <span className='title'>Select max 3 columns</span>
          {options.map((option, index) => {
            return (
              <li key={option.key}>
                <Checkbox
                  id={option.value}
                  checked={props.viewedColumns.includes(option.value)}
                  onChange={(e: any) => props.handleChangeViewedColums(e.target.checked, option.value)}
                />
                <label htmlFor={option.value} className='label'>
                  {option.key}
                </label>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default ColumnSelect
