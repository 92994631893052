import TopButtons from './TopButtons'
import ResourceTable from './ResourceTable'
import './Resources.css'
import { useState } from 'react'
import { MarketScopeType } from './ResourceTypes'

type Props = {
  toggleView: () => void
  view: 'edit' | 'list'
  toggleAddResourceModal: (control: boolean) => void
  deleteResource: (index: number) => void
  duplicateResource: (resource: any) => void
  viewedColumns: any
  handleChangeViewedColums: any
  inflationRate: any
  countryInflationRate: any
  gdsInflation: any //temporary
}

const Resources = (props: Props) => {
  const [marketScopes, setMarketScopes] = useState<any[]>([])
  function getScopes(scopes: any[]) {
    setMarketScopes(scopes)
  }
  //props.getScopes(marketScopes)
  return (
    <div className='resources-container' id='resources-container'>
      <h2 className='title heading-two'>Resources</h2>
      <p className='title-description body-text'>
        Add resources that will be deployed, along with related information on role, efforts, and client billing rate
        (if applicable). Resources may be selected by name, if known.
      </p>
      <p className='disclaimer-text'>
        Please do not enter any unnecessary personal information or any sensitive personal data (including tax file
        numbers or other government identifiers), client confidential information, audit secrets, state secrets,
        commercial secrets, or anything that would violate professional secrecy or confidentiality rules or that would
        be considered abusive/irrelevant.
      </p>
      <TopButtons
        view={props.view}
        viewedColumns={props.viewedColumns}
        handleChangeViewedColums={props.handleChangeViewedColums}
        toggleView={props.toggleView}
        toggleAddResourceModal={props.toggleAddResourceModal}
      />
      <ResourceTable
        name='resources'
        deleteResource={props.deleteResource}
        duplicateResource={props.duplicateResource}
        view={props.view}
        viewedColumns={props.viewedColumns}
        inflationRate={props.inflationRate}
        countryInflationRate={props.countryInflationRate}
        gdsInflation={props.gdsInflation} //temp
      />
    </div>
  )
}

export default Resources
