import { useCallback, useEffect } from 'react'
import Modal from 'react-modal'
import { useDropzone } from 'react-dropzone'
import XLSXIcon from '../../../assets/xlsx.svg'
import Select from '../../formik-controls/select/Select'
import loaderGif from '../../../assets/LoaderGif.gif'

import './RatesModal.css'
import { updateInputStyling } from '../../utils/updateStyling'

const RatesModal = (props: any) => {
  const { isUploading, selectedRatesFile, selectedRateType } = props

  useEffect(() => {
    updateInputStyling()
  }, [])

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles?.length > 0) {
      // Verify the file extension
      const fileName = acceptedFiles[0].name
      const fileExt = fileName.split(".").pop()
      if (fileExt === "xlsx") {
        props.setSelectedRatesFile(acceptedFiles[0])
      }
    }
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: '.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    maxFiles: 1
  })

  const selectOptions = [
    { label: 'Cost Rates', value: 'CostRates' },
    { label: 'Exchange Rates', value: 'ExchangeRates' },
    { label: 'Inflation Rates', value: 'InflationRates' },
    { label: 'Master Cost Rates', value: 'mastercostrate' },
    { label: 'Master Exchange Rates', value: 'masterexchangerate' },
    { label: 'Master Market Rates', value: 'mastermarketrate' },
    { label: 'Master Sector Discount', value: 'mastersectordiscount' },
    { label: 'Master Offering Competency Cost Mapping', value: 'masterofferingmap' }
  ]

  return (
    <Modal
      isOpen={props.isOpen}
      contentLabel='Upload New Rates'
      className='rates-modal'
      overlayClassName='rates-modal-overlay'
    >
      <>
        <h3 className='heading-three m-0'>Upload New Rates</h3>
        <p className='body-text description'>
          Please select which rates you are uploading and upload your file in XLSX format.
        </p>
        <div className='rates-select'>
          <Select
            name='ratesSelect'
            options={selectOptions}
            onChange={(option) => {
              props.setSelectedRateType(option.value)
            }}
          />
        </div>
        <div
          {...getRootProps({
            className: `rates-dropzone ${isDragActive ? 'drag-active' : ''}`
          })}
        >
          <input {...getInputProps()} />
          <div style={{ textAlign: 'center' }}>
            {isUploading ? (
              <>
                <p className='body-text m-0' style={{ marginBottom: '30px', fontWeight: 'bold' }}>
                  Uploading{' '}
                  {selectedRateType ? selectOptions.find((item) => item.value === selectedRateType)?.label : null} to
                  server...
                </p>
                <img src={loaderGif} alt='Page Loading...' />
              </>
            ) : selectedRatesFile ? (
              <>
                <p className='body-text m-0' style={{ marginBottom: '15px', fontWeight: 'bold' }}>
                  {selectedRatesFile.path}
                </p>
                <p className='body-text m-0'>To replace, click this area or drag in a new file.</p>
              </>
            ) : (
              <>
                <p className='body-text m-0' style={{ marginBottom: '20px' }}>
                  Drag your file here or click to browse.
                </p>
                <img src={XLSXIcon} height='50px' alt='XLSX Icon' />
              </>
            )}
          </div>
        </div>

        {!isUploading && (
          <div className='footer-bottons-container'>
            <button className='secondary-button' type='button' onClick={() => props.onCancelRatesModal()}>
              <span className='heading-three'>Cancel</span>
            </button>
            <button
              className='primary-button'
              type='button'
              disabled={!selectedRateType || !selectedRatesFile}
              onClick={() => props.onConfirmRatesModal()}
            >
              <span className='heading-three'>Next</span>
            </button>
          </div>
        )}
      </>
    </Modal>
  )
}

export default RatesModal
