import styles from './ManageAdministrators.module.css'
import { getReportData } from '../utils/fetchHandler'
import * as XLSX from 'xlsx'
import * as FileSaver from 'file-saver'
import moment from 'moment'

const numberWithCommas = (x: number) => {
  if (x != undefined) {
    return x != 0 ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0
  } else return undefined
}
const ManageAdministrators = (props: any) => {
  const downloadReport = async () => {
    let result = await getReportData()
    for (let a = 0; a < result.length; a++) {
      result[a].totalBillings = numberWithCommas(result[a].totalBillings.toFixed(0))
      result[a].createdDate = moment(result[a].createdDate).format('DD/MM/YY')
    }
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExtension = '.xlsx'
    const ws = XLSX.utils.json_to_sheet(result)
    const wb = { Sheets: { Data: ws }, SheetNames: ['Data'] }

    wb.Sheets.Data.A1.v = 'UserName'
    wb.Sheets.Data.B1.v = 'UserEmailId'
    wb.Sheets.Data.C1.v = 'LastAccessedPage'
    wb.Sheets.Data.D1.v = 'User'
    wb.Sheets.Data.E1.v = 'Name'
    wb.Sheets.Data.F1.v = 'Created Date'
    wb.Sheets.Data.G1.v = 'Area/Region'
    wb.Sheets.Data.H1.v = 'Country'
    wb.Sheets.Data.I1.v = 'Client Name'
    wb.Sheets.Data.J1.v = 'Deal Name'
    wb.Sheets.Data.K1.v = 'Total Billings (USD)'
    const current = new Date()
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, 'CCT Usage Report - ' + moment(current).format('YYYYMMDD') + '.xlsx')
  }

  return (
    <div>
      <table>
        <td>
          <h3 className='heading-three'>Manage Administrators</h3>
          <p className={styles.description}>
            To manage administrators, please send your request to{' '}
            <span className={styles.name}>diana.c.garcia.corredor@ey.com</span>
          </p>
        </td>
        <td>
          <div style={{ marginLeft: 80 }}></div>
        </td>
        <td>
          <h3 className='heading-three right-section'>Generate usage report</h3>
          <p className={styles.description}>
            To extract reporting data from the last twelve months, click the button below.
          </p>
          <button className='primary-button' type='button'>
            <span className='heading-three' onClick={() => downloadReport()}>
              Download Report
            </span>
          </button>
        </td>
      </table>
    </div>
  )
}

export default ManageAdministrators
