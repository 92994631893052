import React from 'react'
import styles from './ResourcesByRank.module.css'
import { numberWithCommas } from '../../utils/helperFunctions'
import { getRounded } from '../../resources/ResourceTableComponents'
const getTotal = (rows: any) => {
  return rows.reduce(
    (result: any, row: any) => {
      return {
        hours: result.hours + row.hours,
        cost: result.cost + row.cost
      }
    },
    { hours: 0, cost: 0 }
  )
}

const ResourcesByRank = (props: any) => {
  const { pricingPlanData, ranks } = props
  let rows = [
    {
      label: 'Partner',
      name: 'partner',
      hours: 0,
      cost: 0
    },
    {
      label: 'Executive Director',
      name: 'executiveDirector',
      hours: 0,
      cost: 0
    },
    {
      label: 'Senior Manager',
      name: 'seniorManager',
      hours: 0,
      cost: 0
    },
    {
      label: 'Manager',
      name: 'manager',
      hours: 0,
      cost: 0
    },
    {
      label: 'Senior',
      name: 'senior',
      hours: 0,
      cost: 0
    },
    {
      label: 'Staff',
      name: 'staff',
      hours: 0,
      cost: 0
    },
    {
      label: 'Intern',
      name: 'intern',
      hours: 0,
      cost: 0
    },
    {
      label: 'Client Serving Contractor',
      name: 'clientServingContractor',
      hours: 0,
      cost: 0
    },
    {
      label: 'Subcontractor',
      name: 'subcontractor',
      hours: 0,
      cost: 0
    }
  ]

  const { resources, inputUnit } = pricingPlanData
  rows =
    resources?.length > 0
      ? rows.map((row) => {
          const level = row.label
          let totalHours = 0
          let totalCost = 0
          resources.forEach((resource: any) => {
            // function to get hours from resource
            const getHours = (_resource: any) => {
              const rank = _resource.rank
              //const _level = ranks.find((_rank: any) => _rank.rank === rank)?.level
              let _level
              if (rank.toString().includes('Interns (CS)')) {
                _level = 'Intern'
              } else if (rank === 'Client Serving Contractor') {
                _level = 'Client Serving Contractor'
              } else if (rank.toString().includes('Executive Director')) {
                _level = 'Executive Director'
              } else {
                _level = ranks.find((_rank: any) => _rank.rank === rank)?.level
              }
              let hours = 0
              if (level === _level) {
                hours = _resource.totalTimeUnits
                if (inputUnit === 'Days') {
                  hours = _resource.totalHours
                }
              }
              return hours
            }
            // function to get cost from resoruce
            const getCost = (_resource: any) => {
              const rank = _resource.rank
              let _level

              if (rank.toString().includes('Interns (CS)')) {
                _level = 'Intern'
              } else if (rank === 'Client Serving Contractor') {
                _level = 'Client Serving Contractor'
              } else if (rank.toString().includes('Executive Director')) {
                _level = 'Executive Director'
              } else {
                _level = ranks.find((_rank: any) => _rank.rank === rank)?.level
              }
              const cost = level === _level ? _resource.totalCost : 0
              return cost
            }

            totalHours += getHours(resource)
            totalCost += getCost(resource)
            if (resource.promotedResource) {
              totalHours += getHours(resource.promotedResource)
              totalCost += getCost(resource.promotedResource)
            }
          })
          return { ...row, hours: totalHours, cost: totalCost }
        })
      : rows

  rows = [
    ...rows,
    {
      label: 'Total',
      name: 'total',
      ...getTotal(rows)
    }
  ]

  return (
    <>
      <header>
        <div className={styles.title}>Resources by Rank</div>
      </header>

      <div className={styles['resources-by-rank-table']}>
        <div className={styles.thead}>
          <div className={styles.tr}>
            <div className={styles.th}></div>
            <div className={styles.th}>Hours</div>
            <div className={styles.th}>Cost</div>
          </div>
        </div>
        <div className={styles.tbody}>
          {rows.map((row) => {
            return (
              <div className={styles.tr} key={row.name}>
                <div className={`${styles.td} ${styles.label}`}>
                  {row.name === 'total' ? <b>{row.label}</b> : row.label}
                </div>
                <div className={`${styles.td} ${styles.hours}`}>{numberWithCommas(getRounded(row.hours))}</div>
                <div className={`${styles.td} ${styles.cost}`}>
                  {numberWithCommas(row.cost)} {pricingPlanData.planCurrency}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default ResourcesByRank
