import './MobilityExpenses.css'
import { numberWithCommas } from '../../utils/helperFunctions'

const MobilityExpenses = (props: any) => {
  const { pricingPlanData } = props
  const mobilityExpenses = pricingPlanData.expenses?.filter((expense: any) => expense.expenseType === 'Other')

  let rows = [
    { label: 'Hotel, Meals & Entertainment', name: 'hotelCost', cost: 0 },
    // { label: 'Per Diem', name: 'perDiem', cost: 0 },
    { label: 'Air/Ground Transportation', name: 'flightCost', cost: 0 },
    { label: 'Other Travel Related', name: 'perDiem', cost: 0 },
    { label: 'Other Lumpsum', name: 'otherLumpsum', cost: 0 },
    { label: 'Other Technology Expenses', name: 'otherTechExpenses', cost: 0 }
  ]

  rows =
    mobilityExpenses?.length > 0
      ? rows.map((row: any) => {
          let items = []
          if (row.name === 'otherLumpsum') {
            items = mobilityExpenses.filter(
              (item: any) =>
                item.category !== 'hotelCost' &&
                item.category !== 'perDiem' &&
                item.category !== 'flightCost' &&
                item.category !== 'consultants' &&
                item.category !== 'subcontractors' &&
                item.category !== 'alliance' &&
                item.category !== 'IFMs' &&
                item.category !== 'otherTechExpenses'
            )
          } else {
            items = mobilityExpenses.filter((item: any) => item.category === row.name)
          }
          const cost =
            items?.length > 0 &&
            items.reduce((total: any, item: any) => {
              return (total += item.cost || 0)
            }, 0)
          if (cost == false) return { ...row, cost: 0 }
          else return { ...row, cost }
        })
      : rows

  const total = rows.reduce((result: number, row: any) => {
    return (result += row.cost || 0)
  }, 0)

  rows = [...rows, { label: 'Total', name: 'total', cost: total }]

  return (
    <>
      <header>
        <div className='title'>Travel and Other Related Expenses</div>
      </header>

      <div className='mobility-expense-table'>
        <div className='thead'>
          <div className='tr'>
            <div className='th'></div>
            <div className='th'>Cost</div>
          </div>
        </div>
        <div className='tbody'>
          {rows.map((item) => {
            return (
              <div className='tr' key={item.label}>
                <div className='td label'>{item.label === 'Total' ? <b>{item.label}</b> : item.label}</div>
                <div className='td cost'>
                  {numberWithCommas(item.cost)} {pricingPlanData.planCurrency}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default MobilityExpenses
