import './Scenarios.css'
import Base from './Base'
import EYView from './EYView'
import ClientView from './ClientView'
import DetailedResources from './DetailedResources'
import DetailedExpenses from './DetailedExpenses'
import Footer from '../footer/Footer'
import Header from '../header/Header'
import NavMenu from '../navigation/NavMenu'
import { getCCTLabelSettings, getPricingPlan, updatePricingPlanScenarios } from '../utils/fetchHandler'
import ApplyAll from '../../assets/apply_all.svg'

import { useHistory, useParams } from 'react-router-dom'
import { RouteParamTypes } from '../global-inputs/GlobalInputTypes'
import React, { useReducer, useContext, useEffect, useState } from 'react'
import { Form, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import _ from 'lodash'
import Loading from '../loading/Loading'
import TechnologyCostModel from '../modals/TechnologyCostModel'
import InflationAdjustmentModal from '../modals/InflationAdjustmentModal'
import { UserContext } from '../../App'

// type stateType = {
//   FY1Adj: number
// }
// const initialState = {
//   FY1Adj: 0
// }
// const reducer = (state: stateType, payload: any) => {
//   return { ...state, ...payload }
// }

const Scenario = () => {
  //const [state, setState] = useReducer(reducer, initialState)
  const [isLoading, setIsLoading] = useState(true)
  const [pricingPlan, setPricingPlan] = useState<any>({ worksheetName: 'Unnamed Worksheet' })
  const [contingency, setContingency] = useState<number>(15)
  const [selectedColumn, setSelectedColumn] = useState<number>(0)
  const [scenarioName, setScenarioName] = useState<string>('base')
  const [selectedColumnText, setSelectedColumnText] = useState<string>('')
  const [open, setOpen] = useState([true, true, true, true]) // Toggles visibility of the categories
  const [isSelected, setIsSelected] = useState<boolean>(false)
  const [isExistingPlan, setIsExistingPlan] = useState(false) // States whether the current plan exists in the database or not
  const [initialRetrieved, setInitialRetrieved] = useState(false) // Indicates if fetching values were done
  const [inputResources, setInputResources] = useState<any>()
  const [inputExpenses, setInputExpenses] = useState<any>()
  const [planCurrency, setPlanCurrency] = useState<string>('')
  const [planUnit, setPlanUnit] = useState<string>('')
  const history = useHistory()
  const { pricingPlanId } = useParams<RouteParamTypes>()
  const [nextButtonPressed, setNextButtonPressed] = useState(false)
  const [highestAccessedPage, setHighestAccessedPage] = useState<string>('')
  const [TechnologyCost, setTechnologyCost] = useState<number>(0)
  const [FY1InflationAdj, setFY1InflationAdj] = useState<string>('0')
  const [FY2InflationAdj, setFY2InflationAdj] = useState<string>('0')
  const [FY3InflationAdj, setFY3InflationAdj] = useState<string>('0')
  const [FY4InflationAdj, setFY4InflationAdj] = useState<string>('0')

  const [TechnologyCostValues, setTechnologyCostValues] = useState<number[]>([0])
  const [Msgflag, setMsgflag] = useState<boolean>(false)
  const userId = useContext(UserContext)[1]
  const [FY1Inflation1, setFY1Inflation1] = useState<number>(0)
  // const [state, setState] = useReducer(reducer, initialState)
  //setState({ FY1Adj: 0 })
  const expensesRows = {
    ltCost: 0,
    recoverable: 0,
    stCost: 0,
    contingency: 0,
    ltMargin: 0,
    stMargin: 0,
    totalExpenseBillings: 0
  }

  const expensesBoxValues = {
    mobility: expensesRows,
    mobilityEYOnshore: _.cloneDeep(expensesRows),
    mobilityGDSLanded: _.cloneDeep(expensesRows),
    technologyOtherProducts: _.cloneDeep(expensesRows),
    subcontractors: _.cloneDeep(expensesRows),
    otherLumpSums: _.cloneDeep(expensesRows),
    totalExpenses: _.cloneDeep(expensesRows)
  }

  // Copies by value rather than by reference
  const expensesBoxValues2 = _.cloneDeep(expensesBoxValues)
  const expensesBoxValues3 = _.cloneDeep(expensesBoxValues)
  const expensesBoxValues4 = _.cloneDeep(expensesBoxValues)
  const expensesBoxValues5 = _.cloneDeep(expensesBoxValues)

  const initialExpensesValues = {
    base: expensesBoxValues,
    targetMargin: expensesBoxValues2,
    fixedFee: expensesBoxValues3,
    client: expensesBoxValues4,
    marketRate: expensesBoxValues5
  }

  const [expensesValues, setExpensesValues] = useState<any>(initialExpensesValues)

  const [resourcesValues, setResourcesValues] = useState<any>({
    base: {},
    targetMargin: {},
    fixedFee: {},
    client: {},
    marketRate: {}
  })

  const initialValuesEYView = {
    totalBillings: {
      inclContingency: 0,
      exclContingency: 0
    },
    billingExclExpenses: {
      inclContingency: 0,
      exclContingency: 0
    },
    totalLaborCost: {
      inclContingency: 0,
      exclContingency: 0
    },
    dedicatedCost: {
      inclContingency: 0,
      exclContingency: 0
    },
    costOfSubcontractors: {
      inclContingency: 0,
      exclContingency: 0
    },
    standardMargin: {
      inclContingency: 0,
      exclContingency: 0
    },
    dcrMargin: {
      inclContingency: 0,
      exclContingency: 0
    },
    standardMarginExclPNC: {
      inclContingency: 0,
      exclContingency: 0
    },
    dcrMarginExclPNC: {
      inclContingency: 0,
      exclContingency: 0
    },
    totalExpenses: {
      inclContingency: 0,
      exclContingency: 0
    },
    ratePerDay: {
      inclContingency: 0,
      exclContingency: 0
    },
    ratePerHour: {
      inclContingency: 0,
      exclContingency: 0
    }
  }

  // Copies by value rather than by reference
  const initialValuesEyView2 = _.cloneDeep(initialValuesEYView)
  const initialValuesEyView3 = _.cloneDeep(initialValuesEYView)
  const initialValuesEyView4 = _.cloneDeep(initialValuesEYView)
  const initialValuesEyView5 = _.cloneDeep(initialValuesEYView)
  const [eyViewValues, setEyViewValues] = useState<any>({
    base: initialValuesEYView,
    targetMargin: initialValuesEyView2,
    fixedFee: initialValuesEyView3,
    client: initialValuesEyView4,
    marketRate: initialValuesEyView5
  })

  const initialValuesClientView = {
    servicesFees: {
      inclContingency: 0,
      exclContingency: 0
    },
    subcontractors: {
      inclContingency: 0,
      exclContingency: 0
    },
    expenses: {
      inclContingency: 0,
      exclContingency: 0
    },
    totalBillingAmount: {
      inclContingency: 0,
      exclContingency: 0
    }
  }

  const initialValuesClientView2 = _.cloneDeep(initialValuesClientView)
  const initialValuesClientView3 = _.cloneDeep(initialValuesClientView)
  const initialValuesClientView4 = _.cloneDeep(initialValuesClientView)
  const initialValuesClientView5 = _.cloneDeep(initialValuesClientView)

  const [clientViewValues, setClientViewValues] = useState<any>({
    base: initialValuesClientView,
    targetMargin: initialValuesClientView2,
    fixedFee: initialValuesClientView3,
    client: initialValuesClientView4,
    marketRate: initialValuesClientView5
  })

  interface scenarioContingencyRowsInterface {
    eyOnShore: number
    gdsOffshore: number
    gdsLanded: number
    subcontractors: number
    mobilityEYOnshore: number
    mobilityGDSLanded: number
    technologyOtherProducts: number
    vendorSubcontractorFF: number
    otherLumpSums: number
  }

  //these should be set by business
  const scenarioMarginRows = {
    eyOnShore: 0,
    gdsOffshore: 0,
    gdsLanded: 0,
    subcontractors: 0,
    mobilityEYOnshore: 0,
    mobilityGDSLanded: 0,
    technologyOtherProducts: 0,
    vendorSubcontractorFF: 0,
    otherLumpSums: 0
  }

  //these should be set by business
  const scenarioContingencyRows = {
    eyOnShore: 0,
    gdsOffshore: 0,
    gdsLanded: 0,
    subcontractors: 0,
    mobilityEYOnshore: 0,
    mobilityGDSLanded: 0,
    technologyOtherProducts: 0,
    vendorSubcontractorFF: 0,
    otherLumpSums: 0
  }

  const [formValues, setFormValues] = useState({
    base: {
      totalBilling: 0,
      margin: scenarioMarginRows,
      contingency: scenarioContingencyRows
    },
    targetMargin: {
      totalBilling: 0,
      margin: _.cloneDeep(scenarioMarginRows),
      contingency: _.cloneDeep(scenarioContingencyRows)
    },
    fixedFee: {
      totalBilling: 0,
      margin: _.cloneDeep(scenarioMarginRows),
      contingency: _.cloneDeep(scenarioContingencyRows)
    },
    client: {
      totalBilling: 0,
      margin: _.cloneDeep(scenarioMarginRows),
      contingency: _.cloneDeep(scenarioContingencyRows)
    },
    marketRate: {
      totalBilling: 0,
      margin: _.cloneDeep(scenarioMarginRows),
      contingency: _.cloneDeep(scenarioContingencyRows)
    },
    technologyCost: 0,
    FY1InflationAdj: '0',
    FY2InflationAdj: '0',
    FY3InflationAdj: '0',
    FY4InflationAdj: '0',
    msgFlag: false
  })

  // Used for disabling the save button
  const [initialFormValues, setInitialFormValues] = useState<any>({})
  const [contingencyChanged, setContingencyChanged] = useState<boolean>(false)
  const [TechnologyCostChanged, setTechnologyCostChanged] = useState<boolean>(false)
  const [InflationAdj, setInflationAdj] = useState<boolean>(false)
  const [isEditable, setIsEditable] = useState(true)

  const openTab = (tabName: string) => {
    let newOpenState = [...open]

    if (tabName === 'eyView') {
      newOpenState[0] ? (newOpenState[0] = false) : (newOpenState[0] = true)
    } else if (tabName === 'clientView') {
      newOpenState[1] ? (newOpenState[1] = false) : (newOpenState[1] = true)
    } else if (tabName === 'resources') {
      newOpenState[2] ? (newOpenState[2] = false) : (newOpenState[2] = true)
    } else if (tabName === 'expenses') {
      newOpenState[3] ? (newOpenState[3] = false) : (newOpenState[3] = true)
    }

    setOpen(newOpenState)
  }

  useEffect(() => {
    ;(async () => {
      try {
        await fetchPricingPlan()
        await GetTechnologyCostValues()
        setIsLoading(false)
        setInitialFormValues(formValues)
      } catch (error) {}
    })()
  }, [])

  useEffect(() => {
    // if (selectedColumn === 0) {
    //   setSelectedColumnText('BASE')
    //   setScenarioName('base')
    // }
    if (selectedColumn === 0) {
      setSelectedColumnText('MarketRate')
      setScenarioName('marketRate')
      // setSelectedColumnText('TARGET MARGIN')
      // setScenarioName('targetMargin')
    } else if (selectedColumn === 1) {
      setSelectedColumnText('TARGET MARGIN')
      setScenarioName('targetMargin')
    } else if (selectedColumn === 2) {
      setSelectedColumnText('FIXED FEE')
      setScenarioName('fixedFee')
    } else if (selectedColumn === 3) {
      setSelectedColumnText('CLIENT')
      setScenarioName('client')
    }
  }, [selectedColumn])
  const determineAccessibility = (data: {
    planStatus: any
    isDelegated: any
    isCheckedIn: any
    delegateAccess: any
  }) => {
    const { planStatus, isDelegated, isCheckedIn, delegateAccess } = data

    if (planStatus === 'Owned') {
      if (isDelegated) {
        return isCheckedIn
      } else {
        return true
      }
    } else {
      return isCheckedIn
    }
  }

  const fetchPricingPlan = async () => {
    if (pricingPlanId === 'new') {
      setIsExistingPlan(false)
      setPricingPlan({ worksheetName: 'Unnamed Worksheet' })
    } else {
      const worksheet = await getPricingPlan(pricingPlanId, userId)
      setIsEditable(determineAccessibility(worksheet))
      setHighestAccessedPage(worksheet.highestAccessedPage)
      // if (worksheet !== 'Failed to retrieve pricing plan with ID new')
      if (true) {
        setIsExistingPlan(true)
        setPricingPlan(worksheet)
        setPlanCurrency(worksheet.planCurrency)
        setPlanUnit(worksheet.inputUnit)
        setInputResources(worksheet.resources)
        setInputExpenses(worksheet.expenses)
        // Set Scenarios Assumptions with existing values
        if (worksheet.summaryViewModel.selectedScenario !== '') {
          // If selectedScenario is empty then it's a new plan
          const newBaseValues = {
            totalBilling: worksheet.summaryViewModel.base.totalBilling,
            margin: worksheet.summaryViewModel.base.margin,
            contingency: worksheet.summaryViewModel.base.contingency
          }
          const newTargetMarginValues = {
            totalBilling: worksheet.summaryViewModel.targetMargin.totalBilling,
            margin: worksheet.summaryViewModel.targetMargin.margin,
            contingency: worksheet.summaryViewModel.targetMargin.contingency
          }
          const newFixedFeeValues = {
            totalBilling: worksheet.summaryViewModel.fixedFee.totalBilling,
            margin: worksheet.summaryViewModel.fixedFee.margin,
            contingency: worksheet.summaryViewModel.fixedFee.contingency
          }
          const newClientValues = {
            totalBilling: worksheet.summaryViewModel.client.totalBilling,
            margin: worksheet.summaryViewModel.client.margin,
            contingency: worksheet.summaryViewModel.client.contingency
          }

          const newMarketRateValues = {
            totalBilling: worksheet.summaryViewModel.marketRate.totalBilling,
            margin: worksheet.summaryViewModel.marketRate.margin,
            contingency: worksheet.summaryViewModel.marketRate.contingency
          }
          const newTechnologyCost = worksheet.summaryViewModel.technologyCost
          const newMsgFlag = worksheet.summaryViewModel.msgflag

          const newFY1InflationAdj = worksheet.summaryViewModel.fy1InflationAdj //FY1InflationAdj //  worksheet.summaryViewModel.fy1InflationAdj
          const newFY2InflationAdj = worksheet.summaryViewModel.fy2InflationAdj // FY2InflationAdj
          const newFY3InflationAdj = worksheet.summaryViewModel.fy3InflationAdj //FY3InflationAdj
          const newFY4InflationAdj = worksheet.summaryViewModel.fy1InflationAdj //FY4InflationAdj
          // Set formValues to pre-existing saved values
          setFormValues({
            base: newBaseValues,
            targetMargin: newTargetMarginValues,
            fixedFee: newFixedFeeValues,
            client: newClientValues,
            marketRate: newMarketRateValues,
            technologyCost: newTechnologyCost,
            FY1InflationAdj: newFY1InflationAdj,
            FY2InflationAdj: newFY2InflationAdj,
            FY3InflationAdj: newFY3InflationAdj,
            FY4InflationAdj: newFY4InflationAdj,
            msgFlag: newMsgFlag
          })

          setContingency(worksheet.summaryViewModel.base.contingency.eyOnShore) // Update contingency shown in the dropdown
          setTechnologyCost(worksheet.summaryViewModel.technologyCost) // Update TechnologyCost shown in the dropdown
          //setFY1InflationAdj(worksheet.summaryViewModel.fy1InflationAdj)

          setMsgflag(worksheet.summaryViewModel.msgflag)
          setFY1InflationAdj(worksheet.summaryViewModel.fy1InflationAdj)
          setFY2InflationAdj(worksheet.summaryViewModel.fy2InflationAdj)
          setFY3InflationAdj(worksheet.summaryViewModel.fy3InflationAdj)
          setFY4InflationAdj(worksheet.summaryViewModel.fy4InflationAdj)
          //setFY1InflationAdj(worksheet.sunmmaryViewModel.FY1InflationAdj)
          // Set selected column to pre-existing value - default is "base" (i.e. 0)
          if (worksheet.summaryViewModel.selectedScenario === 'targetMargin') {
            setSelectedColumn(1)
          }
          if (worksheet.summaryViewModel.selectedScenario === 'fixedFee') {
            setSelectedColumn(2)
          } else if (worksheet.summaryViewModel.selectedScenario === 'client') {
            setSelectedColumn(3)
          } else if (worksheet.summaryViewModel.selectedScenario === 'marketRate') {
            setSelectedColumn(0)
          }
        }
      }
    }
    setInitialRetrieved(true)
  }
  const GetTechnologyCostValues = async () => {
    let TechnologyCost = await getCCTLabelSettings()
    const technologyCostValues: number[] = TechnologyCost.filter(
      (item: { labelName: string }) => item.labelName === 'TechnologyCost'
    )
      .map((item: { technologyCost: number }) => item.technologyCost)
      .sort((a: number, b: number) => a - b)
    setTechnologyCostValues(technologyCostValues)
  }
  const requiredValidationText = 'Required.'
  const integerValidationText = 'Integer only.'

  const scenarioRowsValidation = Yup.object().shape({
    eyOnShore: Yup.number().required(requiredValidationText).integer(integerValidationText),
    gdsOffshore: Yup.number().required(requiredValidationText).integer(integerValidationText),
    gdsLanded: Yup.number().required(requiredValidationText).integer(integerValidationText),
    subcontractors: Yup.number().required(requiredValidationText).integer(integerValidationText),
    mobilityEYOnshore: Yup.number().required(requiredValidationText).integer(integerValidationText),
    mobilityGDSLanded: Yup.number().required(requiredValidationText).integer(integerValidationText),
    technologyOtherProducts: Yup.number().required(requiredValidationText).integer(integerValidationText),
    vendorSubcontractorFF: Yup.number().required(requiredValidationText).integer(integerValidationText),
    otherLumpSums: Yup.number().required(requiredValidationText).integer(integerValidationText)
  })

  const validationSchema = Yup.object().shape({
    base: Yup.object().shape({
      totalBilling: Yup.number().required(requiredValidationText).integer(integerValidationText),
      margin: scenarioRowsValidation,
      contingency: scenarioRowsValidation
    }),
    targetMargin: Yup.object().shape({
      totalBilling: Yup.number().required(requiredValidationText).integer(integerValidationText),
      margin: scenarioRowsValidation,
      contingency: scenarioRowsValidation
    }),
    fixedFee: Yup.object().shape({
      totalBilling: Yup.number().required(requiredValidationText).integer(integerValidationText),
      margin: scenarioRowsValidation,
      contingency: scenarioRowsValidation
    }),
    client: Yup.object().shape({
      totalBilling: Yup.number().required(requiredValidationText).integer(integerValidationText),
      margin: scenarioRowsValidation,
      contingency: scenarioRowsValidation
    }),
    marketRate: Yup.object().shape({
      totalBilling: Yup.number().required(requiredValidationText).integer(integerValidationText),
      margin: scenarioRowsValidation,
      contingency: scenarioRowsValidation
    })
  })

  const summaryPage = {
    pathname: `/pricing-plan/${pricingPlanId}/summary`,
    state: {
      eyViewValues: eyViewValues,
      clientViewValues: clientViewValues,
      expensesValues: expensesValues,
      resourcesValues: resourcesValues,
      selectedColumn: selectedColumn,
      contingency: contingency
    }
  }

  document.onclick = function (event) {
    const buttonText = Array.from(
      document.getElementsByClassName('scenarios-contingency-button-text') as HTMLCollectionOf<HTMLElement>
    )
    const triangleIcon = Array.from(
      document.getElementsByClassName('scenarios-contingency-button-arrow') as HTMLCollectionOf<HTMLElement>
    )
    const button = Array.from(
      document.getElementsByClassName('scenarios-contingency-button') as HTMLCollectionOf<HTMLElement>
    )

    const dropdownContent = Array.from(
      document.getElementsByClassName('scenarios-contingency-dropdown-content') as HTMLCollectionOf<HTMLElement>
    )
    if (
      event.target !== buttonText[0] &&
      event.target !== triangleIcon[0] &&
      event.target !== button[0] &&
      isSelected
    ) {
      dropdownContent[0].style.display = 'none'
      setIsSelected(false)
    }
    const surchargeDropdownContent = document.querySelector('.scenarios-surcharge-dropdown-content') as HTMLElement
    const surchargeDropdownButton = document.querySelector('.scenarios-surcharge-button') as HTMLElement

    // Check if surchargeDropdownContent is not null before accessing its style property
    if (
      surchargeDropdownContent &&
      surchargeDropdownContent.style.display === 'block' &&
      !surchargeDropdownButton.contains(event.target as Node)
    ) {
      surchargeDropdownContent.style.display = 'none'
    }
  }

  const showDropdown = (index: number) => {
    if (index == 0) {
      const dropdownContent = Array.from(
        document.getElementsByClassName('scenarios-contingency-dropdown-content') as HTMLCollectionOf<HTMLElement>
      )

      if (dropdownContent[0].style.display === 'block') {
        dropdownContent[0].style.display = 'none'
      } else {
        dropdownContent[0].style.display = 'block'
      }

      setIsSelected((isSelected) => !isSelected)
    } else if (index == 1) {
      const dropdownContent = Array.from(
        document.getElementsByClassName('scenarios-surcharge-dropdown-content') as HTMLCollectionOf<HTMLElement>
      )

      if (dropdownContent[0].style.display === 'block') {
        dropdownContent[0].style.display = 'none'
      } else {
        dropdownContent[0].style.display = 'block'
      }

      setIsSelected((isSelected) => !isSelected)
    }
  }

  const contingencySelected = (value: number) => {
    setContingency(value)
    setContingencyChanged(true)
    const dropdownContent = Array.from(
      document.getElementsByClassName('scenarios-contingency-dropdown-content') as HTMLCollectionOf<HTMLElement>
    )
    dropdownContent[0].style.display = 'none'

    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      base: {
        ...prevFormValues.base,
        contingency: {
          eyOnShore: contingency,
          gdsOffshore: contingency,
          gdsLanded: contingency,
          subcontractors: contingency,
          mobilityEYOnshore: contingency,
          mobilityGDSLanded: contingency,
          technologyOtherProducts: contingency,
          vendorSubcontractorFF: contingency,
          otherLumpSums: contingency
        }
      }
    }))

    Object.entries(formValues.base.contingency).map(([key]) => {
      formValues.base.contingency[key as keyof scenarioContingencyRowsInterface] = value
    })
  }
  const surchargeSelected = (value: number) => {
    setTechnologyCost(value)
    setTechnologyCostChanged(true)

    // Hide the dropdown content
    const dropdownContent = Array.from(
      document.getElementsByClassName('scenarios-surcharge-dropdown-content') as HTMLCollectionOf<HTMLElement>
    )
    dropdownContent[0].style.display = 'none'
  }
  const applyAllContingency = () => {
    setIsSelected((isSelected) => !isSelected)
    setContingencyChanged(true)
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      targetMargin: {
        ...prevFormValues.targetMargin,
        contingency: {
          eyOnShore: contingency,
          gdsOffshore: contingency,
          gdsLanded: contingency,
          subcontractors: contingency,
          mobilityEYOnshore: contingency,
          mobilityGDSLanded: contingency,
          technologyOtherProducts: contingency,
          vendorSubcontractorFF: contingency,
          otherLumpSums: contingency
        }
      },
      fixedFee: {
        ...prevFormValues.fixedFee,
        contingency: {
          eyOnShore: contingency,
          gdsOffshore: contingency,
          gdsLanded: contingency,
          subcontractors: contingency,
          mobilityEYOnshore: contingency,
          mobilityGDSLanded: contingency,
          technologyOtherProducts: contingency,
          vendorSubcontractorFF: contingency,
          otherLumpSums: contingency
        }
      },
      client: {
        ...prevFormValues.client,
        contingency: {
          eyOnShore: contingency,
          gdsOffshore: contingency,
          gdsLanded: contingency,
          subcontractors: contingency,
          mobilityEYOnshore: contingency,
          mobilityGDSLanded: contingency,
          technologyOtherProducts: contingency,
          vendorSubcontractorFF: contingency,
          otherLumpSums: contingency
        }
      },
      marketRate: {
        ...prevFormValues.marketRate,
        contingency: {
          eyOnShore: contingency,
          gdsOffshore: contingency,
          gdsLanded: contingency,
          subcontractors: contingency,
          mobilityEYOnshore: contingency,
          mobilityGDSLanded: contingency,
          technologyOtherProducts: contingency,
          vendorSubcontractorFF: contingency,
          otherLumpSums: contingency
        }
      }
    }))

    Object.entries(formValues.targetMargin.contingency).map(([key]) => {
      formValues.targetMargin.contingency[key as keyof scenarioContingencyRowsInterface] = contingency
    })

    Object.entries(formValues.fixedFee.contingency).map(([key]) => {
      formValues.fixedFee.contingency[key as keyof scenarioContingencyRowsInterface] = contingency
    })

    Object.entries(formValues.client.contingency).map(([key]) => {
      formValues.client.contingency[key as keyof scenarioContingencyRowsInterface] = contingency
    })
    Object.entries(formValues.marketRate.contingency).map(([key]) => {
      formValues.marketRate.contingency[key as keyof scenarioContingencyRowsInterface] = contingency
    })
  }
  const applySurcharge = () => {
    setIsSelected((isSelected) => !isSelected)
    setTechnologyCostChanged(true)
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      TechnologyCost: TechnologyCost
    }))

    formValues.technologyCost = TechnologyCost
  }

  const savePage = async (values: any, actions: FormikHelpers<any>) => {
    pricingPlan.summaryViewModel = formValues

    // Save selected scenario
    pricingPlan.summaryViewModel.selectedScenario = scenarioName
    //Save technologyCost
    pricingPlan.summaryViewModel.technologyCost = TechnologyCost
    //save popup message flag
    pricingPlan.summaryViewModel.msgflag = Msgflag

    pricingPlan.summaryViewModel.fy1InflationAdj = parseFloat(FY1InflationAdj)
    pricingPlan.summaryViewModel.fy2InflationAdj = parseFloat(FY2InflationAdj)
    pricingPlan.summaryViewModel.fy3InflationAdj = parseFloat(FY3InflationAdj)
    pricingPlan.summaryViewModel.fy4InflationAdj = parseFloat(FY4InflationAdj)
    // Save all EYViewValues (as detailedSummary in JSON)
    pricingPlan.summaryViewModel.detailedSummary = eyViewValues[scenarioName]
    pricingPlan.summaryViewModel.marketRateDetailedSummary = eyViewValues['marketRate']
    // Save the first three rows of Client View Values (as priceBreakdown in JSON)
    pricingPlan.summaryViewModel.priceBreakdown = {
      servicesFees: clientViewValues[scenarioName].servicesFees.inclContingency,
      subcontractors: clientViewValues[scenarioName].subcontractors.inclContingency,
      expenses: clientViewValues[scenarioName].expenses.inclContingency
    }
    //These values are saved for use in Summary page pricebreakdown
    pricingPlan.summaryViewModel.detailedExpenses = {
      travelRelated: expensesValues[scenarioName].mobility.totalExpenseBillings,
      otherTechnology: expensesValues[scenarioName].technologyOtherProducts.totalExpenseBillings,
      other: expensesValues[scenarioName].otherLumpSums.totalExpenseBillings
    }
    // Save the contingency values from the Detailed Resources and Expenses tables
    pricingPlan.summaryViewModel.contingencyTableValues = {
      resources: {
        onshore: resourcesValues[scenarioName].eyOnShore.contingency,
        gds: resourcesValues[scenarioName].gds.contingency,
        subcontractors: resourcesValues[scenarioName].subcontractors.contingency
      },
      expenses: {
        travelRelated: expensesValues[scenarioName].mobility.contingency,
        externalVendorSubcontractor: expensesValues[scenarioName].subcontractors.contingency,
        other: expensesValues[scenarioName].otherLumpSums.contingency
      }
    }

    if (nextButtonPressed) {
      if (pricingPlan.highestAccessedPage === 'Scenarios') {
        pricingPlan.highestAccessedPage = 'Summary'
      }
    }

    if (isExistingPlan) {
      if (isEditable) {
        await updatePricingPlanScenarios(pricingPlan)
      }
    }

    actions.resetForm({ values: { ...initialFormValues, ...pricingPlan.summaryViewModel } })
    setContingencyChanged(false)
    setTechnologyCostChanged(false)
    setInflationAdj(false)
  }
  const handleConfirmation = () => {
    setMsgflag(true)
  }
  const handleConfirmation1 = async () => {
    setMsgflag(true)
    setInflationAdj(true)
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      FY1InflationAdj: FY1InflationAdj,
      FY2InflationAdj: FY2InflationAdj,
      FY3InflationAdj: FY3InflationAdj,
      FY4InflationAdj: FY4InflationAdj
    }))

    formValues.FY1InflationAdj = FY1InflationAdj
    formValues.FY2InflationAdj = FY2InflationAdj
    formValues.FY3InflationAdj = FY3InflationAdj
    formValues.FY4InflationAdj = FY4InflationAdj
    setInflationAdj(true)
    //formValues.client.totalBilling = 40
    //formValues.client.contingency.eyOnShore = 10
    //await fetchPricingPlan()
    // setInitialFormValues(formValues)
  }
  const showTechnologyCostPopup = () => {
    const modal = document.getElementById('technology-cost-modal') as HTMLElement
    modal.style.display = 'block'
  }

  const showInflationAdjustmentModal = () => {
    // setFY1InflationAdj(10)
    // setFY2InflationAdj(11)
    // setFY3InflationAdj(12)
    // setFY4InflationAdj(13)
    const modal1 = document.getElementById('Inflation-adj-modal') as HTMLElement
    modal1.style.display = 'block'

    // formValues.technologyCost = TechnologyCost
    //actions.resetForm({ values: { ...initialFormValues, ...pricingPlan.summaryViewModel } })
    // getPricingPlan(pricingPlanId, userId)
  }
  const onSubmitForm = async (values: any, actions: FormikHelpers<any>) => {
    try {
      await savePage(values, actions)
    } catch (error) {}
  }

  if (isLoading) return <Loading />

  return (
    <div className='scenarios-container'>
      <Header isFixedWidth={true} />
      {initialRetrieved && (
        <Formik
          initialValues={formValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => onSubmitForm(values, actions)}
        >
          {(formik) => {
            setFormValues(formik.values)
            return (
              <Form>
                <div className='scenarios-main-container'>
                  <NavMenu
                    worksheetData={pricingPlan}
                    selectedTab={3}
                    highestAccessedPage={highestAccessedPage}
                    onNavSaveClick={(tabnum) =>
                      formik.submitForm().then(() => {
                        if (tabnum == 0) history.push(`/pricing-plan/${pricingPlanId}/global-input`)
                        if (tabnum == 1) history.push(`/pricing-plan/${pricingPlanId}/resources`)
                        if (tabnum == 2) history.push(`/pricing-plan/${pricingPlanId}/expenses`)
                        if (tabnum == 3) history.push(`/pricing-plan/${pricingPlanId}/scenarios`)
                        if (tabnum == 4) history.push(`/pricing-plan/${pricingPlanId}/summary`)
                      })
                    }
                  />
                  <div className={`inputs-main ${!isEditable ? 'disabled' : ''}`}>
                    <div className='scenarios-content-container'>
                      <h2 className='heading-two'>Pricing Scenarios</h2>
                      <div className='scenarios-description'>
                        <p className='body-text'>
                          Please click on each relevant pricing scenario below as needed to edit assumptions. The output
                          will be displayed in the Management Summary worksheet.
                        </p>
                        <div className='technology-cost-modal-buttons'>
                          <button className='cola-button heading-three' onClick={() => showInflationAdjustmentModal()}>
                            COLA Adj% for Client Rate Sc
                          </button>
                        </div>
                        <div className='scenarios-contingency-sections'>
                          <div className='scenarios-contingency-container1'>
                            <p className='body-text'>Base Contingency: </p>
                            {/* Contingency dropdown */}
                            <div className='scenarios-contingency-dropdown'>
                              <button
                                className='scenarios-contingency-button'
                                type='button'
                                onClick={() => showDropdown(0)}
                              >
                                <p className='body-text scenarios-contingency-button-text'>{contingency + '%'}</p>
                                <span className='scenarios-contingency-button-arrow material-icons'>
                                  arrow_drop_down
                                </span>
                              </button>
                              <div className='scenarios-contingency-dropdown-content'>
                                <button
                                  className='body-text scenarios-contingency-dropdown-content-button'
                                  type='button'
                                  onClick={() => contingencySelected(0)}
                                >
                                  0
                                </button>
                                <button
                                  className='body-text scenarios-contingency-dropdown-content-button'
                                  type='button'
                                  onClick={() => contingencySelected(5)}
                                >
                                  5
                                </button>
                                <button
                                  className='body-text scenarios-contingency-dropdown-content-button'
                                  type='button'
                                  onClick={() => contingencySelected(10)}
                                >
                                  10
                                </button>
                                <button
                                  className='body-text scenarios-contingency-dropdown-content-button'
                                  type='button'
                                  onClick={() => contingencySelected(15)}
                                >
                                  15
                                </button>
                              </div>
                            </div>

                            <div className='scenarios-contingency-apply-container' onClick={applyAllContingency}>
                              <img src={ApplyAll} alt='Two arrows forming a circle'></img>
                              <p className='hyperlink-text'>Apply to all</p>
                            </div>
                          </div>
                          <div className='scenarios-contingency-container'>
                            <div className='technology-cost-modal-buttons'></div>
                            <p className='body-text'>Technology Cost %: </p>
                            {/* Contingency dropdown */}
                            <div className='scenarios-contingency-dropdown'>
                              <button
                                className='scenarios-surcharge-button'
                                type='button'
                                onClick={() => showDropdown(1)}
                              >
                                <p className='body-text scenarios-surcharge-button-text'>{TechnologyCost + '%'}</p>
                                <span className='scenarios-surcharge-button-arrow material-icons'>arrow_drop_down</span>
                              </button>
                              <div className='scenarios-surcharge-dropdown-content'>
                                {TechnologyCostValues.map((value, index) => (
                                  <button
                                    key={index}
                                    className='body-text scenarios-surcharge-dropdown-content-button'
                                    type='button'
                                    onClick={() => surchargeSelected(value)}
                                  >
                                    {value}
                                  </button>
                                ))}
                              </div>
                            </div>

                            <div className='scenarios-contingency-apply-container' onClick={applySurcharge}>
                              <img src={ApplyAll} alt='Two arrows forming a circle'></img>
                              <p className='hyperlink-text'>Apply</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <Base
                        contingency={contingency}
                        selectedColumn={selectedColumn}
                        setSelectedColumn={setSelectedColumn}
                        isSelected={isSelected}
                        formValues={formValues}
                        setFormValues={setFormValues}
                        resourcesValues={resourcesValues}
                        expensesValues={expensesValues}
                        planCurrency={planCurrency}
                        eyViewValues={eyViewValues}
                      />
                      <div className='table-header1' onClick={() => openTab('eyView')}>
                        <p className='body-text'>EY View</p>
                        {open[0] ? (
                          <span className='material-icons'>expand_less</span>
                        ) : (
                          <span className='material-icons'>expand_more</span>
                        )}
                      </div>

                      {open[0] && (
                        <EYView
                          selectedColumn={selectedColumn}
                          setSelectedColumn={setSelectedColumn}
                          formValues={formValues}
                          expensesValues={expensesValues}
                          resourcesValues={resourcesValues}
                          eyViewValues={eyViewValues}
                          setEyViewValues={setEyViewValues}
                          inputResources={inputResources}
                          inputExpenses={inputExpenses}
                          planCurrency={planCurrency}
                          planUnit={planUnit}
                        />
                      )}

                      <div className='table-header1' onClick={() => openTab('clientView')}>
                        <p className='body-text'>Client View</p>
                        {open[1] ? (
                          <span className='material-icons'>expand_less</span>
                        ) : (
                          <span className='material-icons'>expand_more</span>
                        )}
                      </div>

                      {open[1] && (
                        <ClientView
                          selectedColumn={selectedColumn}
                          setSelectedColumn={setSelectedColumn}
                          formValues={formValues}
                          expensesValues={expensesValues}
                          resourcesValues={resourcesValues}
                          clientViewValues={clientViewValues}
                          setClientViewValues={setClientViewValues}
                          planCurrency={planCurrency}
                          inputResources={inputResources}
                          inputExpenses={inputExpenses}
                        />
                      )}

                      <div className='table-header1 detailed-header' onClick={() => openTab('resources')}>
                        <span className='body-text detailed-header-name-box'>{selectedColumnText}</span>
                        <p className='body-text'>Detailed Scenarios: Resources</p>
                        {open[2] ? (
                          <span className='material-icons'>expand_less</span>
                        ) : (
                          <span className='material-icons'>expand_more</span>
                        )}
                      </div>

                      {open[2] && (
                        <DetailedResources
                          selectedColumn={selectedColumn}
                          formValues={formValues}
                          resourcesValues={resourcesValues}
                          setResourcesValues={setResourcesValues}
                          inputResources={inputResources}
                          planCurrency={planCurrency}
                          clientViewValues={clientViewValues}
                        />
                      )}

                      <div className='table-header1 detailed-header' onClick={() => openTab('expenses')}>
                        <span className='body-text detailed-header-name-box'>{selectedColumnText}</span>
                        <p className='body-text'>Detailed Scenarios: Expenses</p>
                        {open[3] ? (
                          <span className='material-icons'>expand_less</span>
                        ) : (
                          <span className='material-icons'>expand_more</span>
                        )}
                      </div>

                      {open[3] && (
                        <DetailedExpenses
                          selectedColumn={selectedColumn}
                          formValues={formValues}
                          expensesValues={expensesValues}
                          setExpensesValues={setExpensesValues}
                          inputExpenses={inputExpenses}
                          inputResources={inputResources}
                          planCurrency={planCurrency}
                        />
                      )}

                      <div className='scenarios-white-space'></div>
                    </div>
                  </div>
                </div>
                <div className='scenarios-footer'>
                  <Footer
                    nextPage={'Summary'}
                    onClickBackButton={() => {
                      formik.submitForm().then(() => {
                        // Does NOT perform validation
                        history.push(`/pricing-plan/${pricingPlanId}/expenses`)
                      })
                    }}
                    onClickNextButton={async () => {
                      if (TechnologyCost == 0 && Msgflag == false) {
                        showTechnologyCostPopup()
                      } else {
                        //showTechnologyCostPopup()
                        setNextButtonPressed(true)
                        await formik.submitForm()
                        // Needs additional checks for special case where an empty row is added, try save, get errors, then delete it and save again
                        if (formik.isValid) {
                          history.push(summaryPage)
                        }
                      }
                    }}
                    onClickSaveButton={() => formik.handleSubmit()}
                    worksheetData={pricingPlan}
                    onChangeWorksheet={(e: React.ChangeEvent<HTMLInputElement>) => {
                      formik.setFieldValue('worksheetName', e.target.value)
                      pricingPlan.worksheetName = e.target.value
                    }}
                    isSaveButtonDisabled={
                      !((formik.dirty || contingencyChanged || TechnologyCostChanged || InflationAdj) && formik.isValid)
                    }
                  />
                </div>
              </Form>
            )
          }}
        </Formik>
      )}
      <TechnologyCostModel technologyCost={TechnologyCost} onConfirm={handleConfirmation} />
      <InflationAdjustmentModal
        PricingPlan={pricingPlan}
        SetPricingPlan={setPricingPlan}
        FY1Inflation={FY1InflationAdj}
        FY2Inflation={FY2InflationAdj}
        FY3Inflation={FY3InflationAdj}
        FY4Inflation={FY4InflationAdj}
        setFY1InflationAdj={setFY1InflationAdj}
        setFY2InflationAdj={setFY2InflationAdj}
        setFY3InflationAdj={setFY3InflationAdj}
        setFY4InflationAdj={setFY4InflationAdj}
        // FY1InflationAdj={setFY1InflationAdj}
        //FY1Inflation1={FY1Inflation1}
        onConfirm={handleConfirmation1}
      ></InflationAdjustmentModal>
    </div>
  )
}

export default Scenario
