import React from 'react';
 
interface PillProps {
  text: string;
  onClick: () => void;
}

const Pill = ({ text, onClick }: PillProps) => {
    return (
      <span className="user-pill" onClick={onClick}>
        <span>{text} &times;</span>
      </span>
    );
  };
  
  export default Pill;
