import { getIn, Field, ErrorMessage } from 'formik'
import React, { memo, useEffect, useMemo, useState } from 'react'
import _, { round, isUndefined } from 'lodash'
import moment from 'moment'
import Tooltip from '../common/Tooltip'
import Select from '../formik-controls/select/Select'
import CheckBox from './checkbox/CheckBox'
import DatePicker from '../formik-controls/date-picker/DatePicker'
import TextError from '../formik-controls/TextError'
import { debug } from 'console'
import { getImpliedNodeFormatForFile } from 'typescript'
import { getInflationRates, getMarketScopes } from '../utils/fetchHandler'
import { date } from 'yup/lib/locale'
import PopupModal from './modal/PopupModal'
import sortBy from 'lodash/sortBy'
import { isMarketRateEditable, marketRateResourceIndex } from './ResourceTable'

export const numberWithCommas = (x: number) => {
  return x !== undefined ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
}

export const renderError = (name: string, errors: any, touched: any) => {
  const error = getIn(errors, name)
  const touch = getIn(touched, name)
  return touch && error ? error : null
}

export const restrictDecimals = (event: any) => {
  const input = event.target.value
  if (input.indexOf('.') >= 0) {
    event.target.value = input.substr(0, input.indexOf('.')) + input.substr(input.indexOf('.'), 3)
  }
}

export const getTotalHours = (resource: any) => {
  const hours =
    resource?.weeklyTimeUnits?.length > 0 &&
    resource.weeklyTimeUnits.reduce((result: number, item: any) => {
      if (item.timeUnits && !isNaN(item.timeUnits)) {
        return result + item.timeUnits
      }
      return result
    }, 0)
  resource.totalHours = hours
  let perDayHours = 0
  if (resource.isGDS) {
    if (['United Kingdom'].includes(resource.costCountry)) {
      perDayHours = 7.5
    } else if (['India'].includes(resource.costCountry)) {
      perDayHours = 9
    } else perDayHours = 8
  } else {
    if (['Australia', 'New Zealand', 'United Kingdom'].includes(resource.costCountry)) {
      perDayHours = 7.5
    } else {
      perDayHours = 8
    }
  }
  let perweekHours = perDayHours * 5
  let noofWeeks = 0,
    days = 0
  noofWeeks = hours > 0 && perweekHours > 0 ? hours / perweekHours : 0
  days = noofWeeks > 0 ? noofWeeks * 5 : 0
  resource.totalDays = days
  return hours
}

export const getTotalDays = (resource: any) => {
  const isGDS = resource.isGDS
  let perDayHours = 0
  if (isGDS) {
    if (['United Kingdom'].includes(resource.costCountry)) {
      perDayHours = 7.5
    } else if (['India'].includes(resource.costCountry)) {
      perDayHours = 9
    } else perDayHours = 8
  } else {
    if (['Australia', 'New Zealand', 'United Kingdom'].includes(resource.costCountry)) {
      perDayHours = 7.5
    } else {
      perDayHours = 8
    }
  }
  let perweekHours = perDayHours * 5
  const hours = getTotalHours(resource)
  resource.totalHours = hours
  let noofWeeks = 0,
    days = 0
  noofWeeks = hours > 0 && perweekHours > 0 ? hours / perweekHours : 0
  days = noofWeeks > 0 ? noofWeeks * 5 : 0
  resource.totalDays = days
  return days
}

export const getTotalTimeUnits = (resource: any, inputUnit: 'Hours' | 'Days') => {
  const totalTimeUnits = inputUnit === 'Days' ? getTotalDays(resource) : getTotalHours(resource)
  return getRounded(totalTimeUnits, 1)
}

export const getClientRate = (
  resource: any,
  inputUnit: 'Hours' | 'Days',
  isPromotion: boolean,
  costCountry: string
) => {
  const totalTimeUnits =
    inputUnit === 'Days'
      ? getTotalClientDays(resource, isPromotion, costCountry)
      : getTotalCalClientHours(resource, isPromotion, costCountry)
  return totalTimeUnits
}

export const getTotalCalClientHours = (resource: any, isPromotion: boolean, costCountry: string) => {
  // let perdayHours = 0
  // if (isPromotion) {
  //   perdayHours = resource.timeUnitsPerWeek / 5
  // } else if (resource.totalDays > 0 && resource.totalHours > 0) {
  //   perdayHours = resource.totalHours / resource.totalDays
  // } else perdayHours = resource.timeUnitsPerWeek / 5
  const isGDS = resource.isGDS
  let perDayHours = 0
  if (isGDS) {
    if (['United Kingdom'].includes(costCountry)) {
      perDayHours = 7.5
    } else if (['India'].includes(costCountry)) {
      perDayHours = 9
    } else perDayHours = 8
  } else {
    if (['Australia', 'New Zealand', 'United Kingdom'].includes(costCountry)) {
      perDayHours = 7.5
    } else {
      perDayHours = 8
    }
  }
  let hoursCount = 0

  hoursCount = perDayHours > 0 ? resource.clientRateHour * perDayHours : 0
  return hoursCount
}

export const getTotalClientDays = (resource: any, isPromotion: boolean, costCountry: string) => {
  //let perdayHours = 0
  const isGDS = resource.isGDS
  let perDayHours = 0
  if (isGDS) {
    if (['United Kingdom'].includes(costCountry)) {
      perDayHours = 7.5
    } else if (['India'].includes(costCountry)) {
      perDayHours = 9
    } else perDayHours = 8
  } else {
    if (['Australia', 'New Zealand', 'United Kingdom'].includes(costCountry)) {
      perDayHours = 7.5
    } else {
      perDayHours = 8
    }
  }
  // if (isPromotion) {
  //   perdayHours = resource.timeUnitsPerWeek / 5
  // } else if (resource.totalDays > 0 && resource.totalHours > 0) {
  //   perdayHours = resource.totalHours / resource.totalDays
  // } else perdayHours = resource.timeUnitsPerWeek / 5
  let noofDays = 0,
    days = 0

  let clientDays = 0
  if (resource.clientRateDay !== undefined) {
    if (resource.clientRateDay === '') clientDays = 0
    else clientDays = resource.clientRateDay
    noofDays = perDayHours > 0 ? clientDays : 0
  } else noofDays = perDayHours > 0 ? perDayHours * resource.clientRateHour : 0
  return getRounded(noofDays, 1)
}

const getRange = (start: number, end: number) => {
  return Array(end - start + 1)
    .fill(0)
    .map((_, idx) => start + idx)
}

const calculateTotalLabourCost = (
  rowData: any,
  dealStartDate: Date | null,
  dealEndDate: Date | null,
  rate: number = 0,
  inflationRate: any,
  rateType: string = '',
  countryInflationRate: any,
  costCountry: string,
  gdsInflation: any
) => {
  let IsweeklyTimeunits = rowData.hasOwnProperty('weeklyTimeUnits')

  let yearArrayDetails: any = []
  let currentDate = new Date()

  let currentYear = new Date().getFullYear()
  let nextFYYear = currentYear + 1
  let prevYear = currentYear - 1
  let currentFYStartDate = moment(`${currentYear}-07-01`).format('YYYY-MM-DDT00:00:00')
  let currentFYEndDate = moment(`${currentYear}-07-01`).format('YYYY-MM-DDT00:00:00')

  let startYear = moment(dealStartDate).year() > currentYear ? currentYear : moment(dealStartDate).year()
  let yearRange = getRange(startYear, moment(dealEndDate).year())

  for (var i = 0; i < yearRange.length; i++) {
    if (i === 0) {
      if (moment(currentDate).isSameOrAfter(`${currentYear}-07-01`)) {
        currentFYStartDate = moment(`${currentYear}-07-01`).format('YYYY-MM-DDT00:00:00')
        currentFYEndDate = moment(`${nextFYYear}-06-30`).format('YYYY-MM-DDT00:00:00')
        yearArrayDetails = yearArrayDetails.concat(yearRange[i])
      } else {
        currentFYStartDate = moment(`${prevYear}-07-01`).format('YYYY-MM-DDT00:00:00')
        currentFYEndDate = moment(`${currentYear}-06-30`).format('YYYY-MM-DDT00:00:00')
        if (startYear >= currentYear) yearArrayDetails = yearArrayDetails.concat(yearRange[i])
        yearArrayDetails = yearArrayDetails.concat(yearRange[i])
      }
    } else yearArrayDetails = yearArrayDetails.concat(yearRange[i])
  }

  const yearsArray = yearArrayDetails //getRange(moment(dealStartDate).year(), moment(dealEndDate).year())
  const weeklyTimeUnitsPerYear = yearsArray?.reduce((result: any[], year: any, index: any) => {
    if (index === 0 && IsweeklyTimeunits) {
      /* result.push([
        ...rowData.weeklyTimeUnits.filter((item: any) => moment(item.startDate).isSameOrBefore(`${year}-06-30`))
      ]) */

      const resultINCurrentFY = [
        ...rowData.weeklyTimeUnits.filter((item: any) =>
          // moment(item.startDate).isSameOrAfter(currentFYStartDate) &&
          moment(item.startDate).isSameOrBefore(currentFYEndDate)
        )
      ]
      if (resultINCurrentFY.length > 0) {
        result.push(resultINCurrentFY)
      }
      if (resultINCurrentFY.length === 0) {
        result.push([])
      }
    }
    if (index > 0) {
      if (year > moment(currentFYStartDate).year()) {
        //year = moment(currentFYEndDate).year()
        let nextYear = parseInt(year) + 1
        let startRange = moment(`${nextYear - 1}-07-01`).format('YYYY-MM-DDT00:00:00')
        let endRange = moment(`${nextYear}-06-30`).format('YYYY-MM-DDT00:00:00')
        if (IsweeklyTimeunits && index > 0) {
          result.push([
            ...rowData.weeklyTimeUnits.filter(
              (item: any) =>
                //moment(date).format('YYYY-MM-DDT00:00:00')
                moment(item.startDate).isSameOrAfter(moment(startRange)) &&
                moment(item.startDate).isSameOrBefore(moment(endRange))
            )
          ])
        }
      }
    }
    return result
  }, [])

  let a = rate
  let flag = false
  let temp = 0
  const totalLabourCost = weeklyTimeUnitsPerYear.reduce((result: number, item: any[], index: number) => {
    const hours = item.reduce((_result: number, _item: any) => {
      _result += _item.timeUnits
      return _result
    }, 0)
    const standardCost = hours * rate

    function transferDataCurrentFY(weeklyData: any, date: any) {
      // Create a deep copy of weeklyData
      const copiedWeeklyData = JSON.parse(JSON.stringify(weeklyData))
      const targetDate = new Date(date)

      for (let i = 1; i < copiedWeeklyData.length; i++) {
        const remainingItems = []
        const itemsToTransfer = []

        for (let j = 0; j < copiedWeeklyData[i].length; j++) {
          const item = copiedWeeklyData[i][j]
          if (new Date(item.startDate) < targetDate) {
            itemsToTransfer.push(item)
          } else {
            remainingItems.push(item)
          }
        }

        // Transfer items to the first index
        copiedWeeklyData[0].push(...itemsToTransfer)

        // Update the current index with the remaining items
        copiedWeeklyData[i] = remainingItems
      }

      return copiedWeeklyData
    }

    function transferDataNextFY(weeklyData: any, targetDate: any) {
      // Create a deep copy of weeklyData
      const copiedWeeklyData = JSON.parse(JSON.stringify(weeklyData))
      const date = new Date(targetDate)
      let shouldShift = false

      // Check if the target date falls within the week range of index 0
      for (const item of copiedWeeklyData[0]) {
        const startDate = new Date(item.startDate)
        const endDate = new Date(startDate)
        endDate.setDate(endDate.getDate() + 6) // Assuming each week is 7 days long
        if (date < startDate) {
          shouldShift = true
          break
        }
      }

      const remainingItems = []
      let itemsToTransfer = []

      if (!shouldShift) {
        if (copiedWeeklyData[0].length == 0) {
          for (let i = 1; i < copiedWeeklyData.length; i++) {
            const currentItems = copiedWeeklyData[i]
            copiedWeeklyData[i] = itemsToTransfer
            itemsToTransfer = currentItems.slice() // Create a new array for itemsToTransfer
          }

          // If there are remaining items after shifting, add them as a new index
          if (itemsToTransfer.length > 0) {
            copiedWeeklyData.push(itemsToTransfer)
          }
        }
        return copiedWeeklyData // No shift needed
      }

      // Separate items in index 0 based on the target date
      for (const item of copiedWeeklyData[0]) {
        if (new Date(item.startDate) > date) {
          itemsToTransfer.push(item)
        } else {
          remainingItems.push(item)
        }
      }

      // Update index 0 with the remaining items
      copiedWeeklyData[0] = remainingItems
      for (let i = 1; i < copiedWeeklyData.length; i++) {
        const currentItems = copiedWeeklyData[i]
        copiedWeeklyData[i] = itemsToTransfer
        itemsToTransfer = currentItems.slice() // Create a new array for itemsToTransfer
      }

      // If there are remaining items after shifting, add them as a new index
      if (itemsToTransfer.length > 0) {
        copiedWeeklyData.push(itemsToTransfer)
      }

      return copiedWeeklyData
    }

    function calculateTotalHoursAtIndex(weeklyData: any, ind: number) {
      if (ind < 0 || ind >= weeklyData.length || !Array.isArray(weeklyData[ind]) || weeklyData[ind].length === 0) {
        return 0
      }

      return weeklyData[ind].reduce((totalHours: number, item: any) => {
        return totalHours + item.timeUnits
      }, 0)
    }

    if (rowData.isGDS) {
      const countryData = gdsInflation.find((country: { country: any }) => country.country === rowData.costCountry)
      if (countryData) {
        if (currentFYEndDate == '2024-06-30T00:00:00') {
          const updatedWeeklyTimeUnitsPerYear = transferDataCurrentFY(weeklyTimeUnitsPerYear, countryData.enddate)
          const totalHours = calculateTotalHoursAtIndex(updatedWeeklyTimeUnitsPerYear, index)
          let calInflatCost = rate * Math.pow(1 + countryData.inflation, index)
          result += calInflatCost * totalHours
        } else {
          const updatedWeeklyTimeUnitsPerYear = transferDataNextFY(weeklyTimeUnitsPerYear, countryData.enddate)
          if (!flag) {
            for (i = 0; i < updatedWeeklyTimeUnitsPerYear.length; i++) {
              const totalHours = calculateTotalHoursAtIndex(updatedWeeklyTimeUnitsPerYear, i)
              let calInflatCost = rate * Math.pow(1 + countryData.inflation, i)
              temp += calInflatCost * totalHours
            }
            flag = true
          }
          result = temp
        }
      } else result += standardCost // If GDS, only add standard cost without inflation
    } else {
      let resourceInflationRate
      if (countryInflationRate !== undefined) {
        resourceInflationRate = countryInflationRate.find((ele: any) => {
          return ele.country === costCountry
        })
      }
      const inflationField =
        index <= 1 ? 'confirmedInflationFY1' : index >= 10 ? 'confirmedInflationFY10' : `confirmedInflationFY${index}`

      const standardCost = hours * rate
      let calInflationCost = // rowData.isGDS ? rate * Math.pow(1 + inflationRate, index):
        resourceInflationRate !== undefined
          ? index === 0
            ? rate
            : a * (1 + resourceInflationRate[inflationField])
          : rate

      a = calInflationCost

      const inflationCost = resourceInflationRate !== undefined ? hours * calInflationCost : 0 //rowData.isGDS ? hours * calInflationCost :

      const inflationCostRate = inflationCost - standardCost
      if (rateType === 'totalClientRate') {
        result += standardCost
      } else {
        result += standardCost + inflationCostRate
      }
    }
    return result
  }, 0)
  return totalLabourCost
}

export const getTotalLabourCost = (
  resource: any,
  dealStartDate: Date | null,
  dealEndDate: Date | null,
  inflationRate: any,
  countryInflationRate: any,
  costCountry: string,
  gdsInflation: any
) => {
  const totalLabourCost = calculateTotalLabourCost(
    resource,
    dealStartDate,
    dealEndDate,
    resource.costRate,
    inflationRate,
    '',
    countryInflationRate,
    costCountry,
    gdsInflation //temp
  )
  return getRounded(totalLabourCost)
}

export const getTotalStandardCost = (
  resource: any,
  dealStartDate: Date | null,
  dealEndDate: Date | null,
  inflationRate: any,
  countryInflationRate: any,
  gdsInflation: any //temp
) => {
  const rate = resource.costRate
  const totalStandardCost = calculateTotalLabourCost(
    resource,
    dealStartDate,
    dealEndDate,
    rate,
    inflationRate,
    '',
    countryInflationRate,
    resource.costCountry,
    gdsInflation //temp
  )
  return getRounded(totalStandardCost)
}

export const getTotalDedicatedCost = (
  resource: any,
  dealStartDate: Date | null,
  dealEndDate: Date | null,
  inflationRate: any,
  countryInflationRate: any,
  gdsInflation: any //temp
) => {
  const rate = resource.dcrRate
  const totalDedicatedCost = calculateTotalLabourCost(
    resource,
    dealStartDate,
    dealEndDate,
    rate,
    inflationRate,
    '',
    countryInflationRate,
    resource.costCountry,
    gdsInflation //temp
  )
  return getRounded(totalDedicatedCost)
}

export const getTotalClientRate = (
  resource: any,
  dealStartDate: Date | null,
  dealEndDate: Date | null,
  inflationRate: any,
  countryInflationRate: any,
  gdsInflation: any // temp
) => {
  const rate = resource.clientRateHour
  const totalClientRate = calculateTotalLabourCost(
    resource,
    dealStartDate,
    dealEndDate,
    rate,
    inflationRate,
    'totalClientRate',
    countryInflationRate,
    resource.costCountry,
    gdsInflation //temp
  )
  return getRounded(totalClientRate)
}

export const getRounded = (num: number, n: number = 0) => {
  const d = Math.pow(10, n)
  return Math.round((num + Number.EPSILON) * d) / d
}

export const FormatspaceForEvery3DigitNumber = (num: string) => {
  var parts = num.split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}

export const checkResourceIsSubcontractor = (resource: any) => {
  if (!(resource.source || resource.rank)) return false
  if (
    resource.source === 'subcontractor' ||
    resource.rank === 'Client Serving Contractor' ||
    resource.rank === 'Subcontractor'
  )
    return true
  else return false
}

export const HoursPerWeek = (props: any) => {
  const { resourceIndex, errors, touched, isPromotion } = props
  const name = `resources[${resourceIndex}]${isPromotion ? '.promotedResource' : ''}.timeUnitsPerWeek`
  return (
    <>
      <div className='hours-per-week-container'>
        <Field
          type='number'
          name={name}
          className={`input-field hours-per-week-field ${renderError(name, errors, touched) ? 'error' : ''}`}
          onInput={restrictDecimals}
        />
        <div className='apply-container' onClick={props.handleClickApply}>
          <span className='material-icons apply-icon'>sync</span>
          <span className='apply-text'>Apply</span>
        </div>
      </div>
      <ErrorMessage name={name} component={TextError} />
    </>
  )
}

export const StartDate = (props: any) => {
  const { resourceIndex, errors, touched, isPromotion, minDate, maxDate } = props
  const name = `resources[${resourceIndex}]${isPromotion ? '.promotedResource' : ''}.startDate`
  return (
    <>
      <DatePicker
        name={name}
        isError={renderError(name, errors, touched)}
        onChange={props.onChange}
        portalId='resources-container'
        minDate={minDate}
        maxDate={maxDate}
      />
      <ErrorMessage name={name} component={TextError} />
    </>
  )
}

export const EndDate = (props: any) => {
  const { resourceIndex, errors, touched, isPromotion, minDate, maxDate } = props
  const name = `resources[${resourceIndex}]${isPromotion ? '.promotedResource' : ''}.endDate`
  return (
    <>
      <DatePicker
        name={name}
        isError={renderError(name, errors, touched)}
        onChange={props.onChange}
        portalId='resources-container'
        minDate={minDate}
        maxDate={maxDate}
      />
      <ErrorMessage name={name} component={TextError} />
    </>
  )
}

export const TotalLabourCost = (props: any) => {
  const { rowData, isPromotion, dealStartDate, dealEndDate, inflationRate, countryInflationRate, gdsInflation } = props

  let totalLabourCost
  totalLabourCost = useMemo(
    () =>
      getTotalLabourCost(
        isPromotion ? rowData.promotedResource : rowData,
        dealStartDate,
        dealEndDate,
        inflationRate,
        countryInflationRate,
        rowData.costCountry,
        gdsInflation //temp
      ),
    []
  )
  return <span className='list-text'>{numberWithCommas(totalLabourCost)}</span>
}

export const TotalLabourCost5 = (props: any) => {
  const { rowData, isPromotion, dealStartDate, dealEndDate, inflationRate, countryInflationRate, gdsInflation } = props

  let totalLabourCost

  totalLabourCost = getTotalLabourCost(
    isPromotion ? rowData.promotedResource : rowData,
    dealStartDate,
    dealEndDate,
    inflationRate,
    countryInflationRate,
    rowData.costCountry,
    gdsInflation //temp
  )
  return <span className='list-text'>{numberWithCommas(totalLabourCost)}</span>
}

export const getTotalMarketCost = (resource: any, dealStartDate: Date | null, dealEndDate: Date | null) => {
  const totalMarketRate = calculateTotalMarketCost(
    resource,
    dealStartDate,
    dealEndDate,
    resource.marketRate !== undefined && !Number.isNaN(resource.marketRate) && resource.marketRate > 0
      ? round(resource.marketRate)
      : round(resource.nsrRate)
  )
  if ((totalMarketRate != undefined && totalMarketRate && null) || !Number.isNaN(totalMarketRate)) {
    return getRounded(totalMarketRate)
  }
  return 0
}

const calculateTotalMarketCost = (rowData: any, dealStartDate: Date | null, dealEndDate: Date | null, rate: number) => {
  let IsweeklyTimeunits = rowData.hasOwnProperty('weeklyTimeUnits')

  let yearArrayDetails: any = []
  let currentDate = new Date()

  let currentYear = new Date().getFullYear()
  let nextFYYear = currentYear + 1
  let prevYear = currentYear - 1
  let currentFYStartDate = moment(`${currentYear}-07-01`).format('YYYY-MM-DDT00:00:00')
  let currentFYEndDate = moment(`${currentYear}-07-01`).format('YYYY-MM-DDT00:00:00')

  let startYear = moment(dealStartDate).year() > currentYear ? currentYear : moment(dealStartDate).year()
  let yearRange = getRange(startYear, moment(dealEndDate).year())

  for (var i = 0; i < yearRange.length; i++) {
    if (i === 0) {
      if (moment(currentDate).isSameOrAfter(`${currentYear}-07-01`)) {
        currentFYStartDate = moment(`${currentYear}-07-01`).format('YYYY-MM-DDT00:00:00')
        currentFYEndDate = moment(`${nextFYYear}-06-30`).format('YYYY-MM-DDT00:00:00')
        yearArrayDetails = yearArrayDetails.concat(yearRange[i])
      } else {
        currentFYStartDate = moment(`${prevYear}-07-01`).format('YYYY-MM-DDT00:00:00')
        currentFYEndDate = moment(`${currentYear}-06-30`).format('YYYY-MM-DDT00:00:00')
        if (startYear >= currentYear) yearArrayDetails = yearArrayDetails.concat(yearRange[i])
        yearArrayDetails = yearArrayDetails.concat(yearRange[i])
      }
    } else yearArrayDetails = yearArrayDetails.concat(yearRange[i])
  }

  const yearsArray = yearArrayDetails //getRange(moment(dealStartDate).year(), moment(dealEndDate).year())
  const weeklyTimeUnitsPerYear = yearsArray?.reduce((result: any[], year: any, index: any) => {
    if (index === 0 && IsweeklyTimeunits) {
      const resultINCurrentFY = [
        ...rowData.weeklyTimeUnits.filter((item: any) => moment(item.startDate).isSameOrBefore(currentFYEndDate))
      ]
      if (resultINCurrentFY.length > 0) {
        result.push(resultINCurrentFY)
      }
      if (resultINCurrentFY.length === 0) {
        result.push([])
      }
    }
    if (index > 0) {
      if (year === moment(currentFYStartDate).year()) {
        year = moment(currentFYEndDate).year()
      }
      let nextYear = parseInt(year) + 1
      let startRange = moment(`${nextYear - 1}-07-01`).format('YYYY-MM-DDT00:00:00')
      let endRange = moment(`${nextYear}-06-30`).format('YYYY-MM-DDT00:00:00')
      if (IsweeklyTimeunits && index > 0) {
        result.push([
          ...rowData.weeklyTimeUnits.filter(
            (item: any) =>
              //moment(date).format('YYYY-MM-DDT00:00:00')
              moment(item.startDate).isSameOrAfter(moment(startRange)) &&
              moment(item.startDate).isSameOrBefore(moment(endRange))
          )
        ])
      }
    }

    return result
  }, [])

  const totalMarketCost = weeklyTimeUnitsPerYear.reduce((result: number, item: any[], index: number) => {
    const hours = item.reduce((_result: number, _item: any) => {
      _result += _item.timeUnits
      return _result
    }, 0)
    const marketCost = hours * rate
    result += marketCost
    return result
  }, 0)
  return totalMarketCost
}

export const ClientRateHour = (props: any) => {
  const { rowData, errors, touched, path, view, resourceIndex, isPromotion, inputUnit } = props
  if (inputUnit === 'Hours') {
    const test = getClientRate(
      isPromotion ? rowData.promotedResource : rowData,
      inputUnit,
      isPromotion,
      rowData.costCountry
    )

    let perdayHours = 0
    if (rowData.isGDS) {
      if (['United Kingdom'].includes(rowData.costCountry)) {
        perdayHours = 7.5
      } else if (['India'].includes(rowData.costCountry)) {
        perdayHours = 9
      } else perdayHours = 8
    } else {
      if (['Australia', 'New Zealand', 'United Kingdom'].includes(rowData.costCountry)) {
        perdayHours = 7.5
      } else {
        perdayHours = 8
      }
    }
    let totalDays = isPromotion
      ? perdayHours * rowData.promotedResource.clientRateHour
      : perdayHours * rowData.clientRateHour
    if (isPromotion) rowData.promotedResource.clientRateDay = getRounded(totalDays, 1) // getRounded(totalDays)
    else rowData.clientRateDay = getRounded(totalDays, 1) // getRounded(totalDays)
    if (
      test > 0 &&
      ((!isPromotion &&
        (rowData.clientRateHour === undefined || rowData.clientRateHour <= 0 || rowData.clientRateHour === test)) ||
        (isPromotion &&
          (rowData.promotedResource.clientRateHour === undefined ||
            rowData.promotedResource.clientRateHour <= 0 ||
            rowData.promotedResource.clientRateHour.toFixed(2) === test.toFixed(2))))
    ) {
      if (isPromotion) rowData.promotedResource.clientRateHour = getRounded(test, 2)
      else rowData.clientRateHour = getRounded(test, 2)
    }
  }

  const nameHours = `resources[${resourceIndex}]${isPromotion ? '.promotedResource' : ''}.clientRateHour`

  return (
    <div>
      {view === 'list' ? (
        <span className='list-text'>{rowData[path]}</span>
      ) : (
        <>
          <Field
            type='number'
            name={nameHours}
            className={`input-field client-rate-input-field ${renderError(nameHours, errors, touched) ? 'error' : ''}`}
          />
          <ErrorMessage name={nameHours} component={TextError} />
        </>
      )}
    </div>
  )
}

export const ClientRateHour1 = (props: any) => {
  const { rowData, errors, touched, path, view, resourceIndex, isPromotion, inputUnit } = props
  if (inputUnit === 'Days') {
    const daysCount = getClientRate(
      isPromotion ? rowData.promotedResource : rowData,
      inputUnit,
      isPromotion,
      rowData.costCountry
    )

    let perdayHours = 0
    if (rowData.isGDS) {
      if (['United Kingdom'].includes(rowData.costCountry)) {
        perdayHours = 7.5
      } else if (['India'].includes(rowData.costCountry)) {
        perdayHours = 9
      } else perdayHours = 8
    } else {
      if (['Australia', 'New Zealand', 'United Kingdom'].includes(rowData.costCountry)) {
        perdayHours = 7.5
      } else {
        perdayHours = 8
      }
    }
    let totalDays = isPromotion
      ? perdayHours * rowData.promotedResource.clientRateHour
      : perdayHours * rowData.clientRateHour
    let newclientHours = isPromotion
      ? rowData.promotedResource.clientRateDay / perdayHours
      : rowData.clientRateDay / perdayHours

    let totalHours = daysCount / perdayHours
    if (isPromotion) {
      if (
        rowData.promotedResource.clientRateDay === undefined ||
        rowData.promotedResource.clientRateDay <= 0 ||
        rowData.promotedResource.clientRateDay.toFixed(2) === daysCount.toFixed(2) ||
        newclientHours.toFixed(2) === rowData.promotedResource.clientRateHour.toFixed(2) ||
        (newclientHours.toFixed(2) !== rowData.promotedResource.clientRateHour.toFixed(2) &&
          rowData.promotedResource.clientRateDay.toFixed(2) === daysCount.toFixed(2) &&
          newclientHours.toFixed(2) !== totalHours.toFixed(2)) ||
        (rowData.promotedResource.clientRateDay.toFixed(2) !== daysCount.toFixed(2) &&
          newclientHours.toFixed(2) === totalHours.toFixed(2)) ||
        rowData.promotedResource.clientRateHour === undefined ||
        rowData.promotedResource.clientRateHour <= 0
      ) {
        rowData.promotedResource.clientRateDay = daysCount > 0 ? getRounded(daysCount, 1) : ''
        if (daysCount > 0 && newclientHours > 0) newclientHours = daysCount / perdayHours
      }
    } else if (
      rowData.clientRateDay === undefined ||
      rowData.clientRateDay <= 0 ||
      rowData.clientRateDay === daysCount ||
      newclientHours === rowData.clientRateHour ||
      (newclientHours !== rowData.clientRateHour &&
        rowData.clientRateDay === daysCount &&
        newclientHours !== totalHours) ||
      (rowData.clientRateDay !== daysCount && newclientHours === totalHours) ||
      rowData.clientRateHour === undefined ||
      rowData.clientRateHour <= 0
    ) {
      if (isPromotion) rowData.promotedResource.clientRateDay = daysCount > 0 ? daysCount : ''
      else rowData.clientRateDay = daysCount > 0 ? daysCount : ''
      if (daysCount > 0 && newclientHours > 0) newclientHours = daysCount / perdayHours
    }
    if (isPromotion) rowData.promotedResource.clientRateHour = getRounded(newclientHours, 2)
    else rowData.clientRateHour = getRounded(newclientHours, 2)
  }
  const nameDays = `resources[${resourceIndex}]${isPromotion ? '.promotedResource' : ''}.clientRateDay`

  return (
    <div>
      {view === 'list' ? (
        <span className='list-text'>{rowData[path]}</span>
      ) : (
        <>
          <Field
            type='number'
            name={nameDays}
            className={`input-field client-rate-day-input-field ${
              renderError(nameDays, errors, touched) ? 'error' : ''
            }`}
          />
          <ErrorMessage name={nameDays} component={TextError} />
        </>
      )}
    </div>
  )
}

export const CostRateHour = (props: any) => {
  const { rowData, isPromotion } = props
  const costRate = isPromotion ? rowData.promotedResource.costRate : rowData.costRate
  return <span className='list-text'>{costRate}</span>
}

export const MarketRateHour = (props: any) => {
  const { isPromotion, rowData, view, path, resourceIndex, errors, touched } = props
  let marketRate = 0
  marketRate =
    (isPromotion != undefined) == true
      ? isPromotion
        ? rowData.promotedResource.marketRate
        : rowData.marketRate
      : rowData.marketRate
  let name: any = `resources[${resourceIndex}].marketRate`
  if (
    (rowData.availableMarketScopes == undefined || rowData.availableMarketScopes?.length == 0) &&
    (rowData.marketRate == 0 || rowData.marketRate == undefined)
  ) {
    if (isPromotion) {
      marketRate = rowData.promotedResource.nsrRate
    } else {
      marketRate = rowData.nsrRate
    }
  } else if (rowData.availableMarketScopes == undefined || rowData.availableMarketScopes?.length == 0) {
    if (isPromotion) {
      if (rowData.promotedResource.marketRate !== 0 && rowData.promotedResource.nsrRate === 0) {
        marketRate = rowData.promotedResource.marketRate
      } else if (rowData.promotedResource.marketRate != 0 && rowData.promotedResource.nsrRate != 0) {
        //High Priority for NSR Rates in case of absence of Market scopes
        marketRate = rowData.promotedResource.nsrRate
      } else if (rowData.promotedResource.marketRate == 0 && rowData.promotedResource.nsrRate != 0) {
        marketRate = rowData.promotedResource.nsrRate
      }
    } else {
      marketRate = rowData.nsrRate
    }
  } else if (rowData.rank != undefined && rowData.rank != '') {
    if (rowData.marketRate != undefined && rowData.marketRate == 0) {
      if (isPromotion) {
        marketRate = rowData.promotedResource.nsrRate
      } else {
        marketRate = rowData.nsrRate
      }
    }
  }
  if (isNaN(marketRate)) {
    marketRate = 0
  }
  return <span className='list-text'>{Math.round(marketRate)}</span>
  // return (editable ? <>
  //     <Field
  //         type='number'
  //         style={{ width: "86px" }}
  //         maxLength={50}
  //         name={name}
  //         className={`input-field ${renderError(`resources[${resourceIndex}].marketRateByUser`, errors, touched) ? 'error' : ''}`}
  //     />
  //     <ErrorMessage name={name} component={TextError} />
  // </> :
  //     (<span className='list-text'>{Math.round(marketRate)}</span>)
  // )
}
export const Hours = (props: any) => {
  const { rowData, isPromotion, view, inputUnit } = props
  const hours = getTotalHours(isPromotion ? rowData.promotedResource : rowData)
  const totalTimeUnits = getTotalTimeUnits(isPromotion ? rowData.promotedResource : rowData, inputUnit)

  if (view === 'list') {
    return <span className='list-text'>{totalTimeUnits}</span>
  }
  return (
    <div className='hours-container' onClick={props.handleClick}>
      {hours && hours > 0 ? (
        <>
          <span className='material-icons-outlined edit-calendar'>edit_calendar</span>
          <Tooltip placement='bottom-start' content='Edit Hours' className='edit-hours'>
            <span className='list-text hours-text'>{totalTimeUnits}</span>
          </Tooltip>
        </>
      ) : (
        <>
          <span className='material-icons add_circle'>add_circle_outline</span>
          <span className='list-text hours-text'>Add</span>
        </>
      )}
    </div>
  )
}

export const Promotion = (props: any) => {
  const { resourceIndex, rowData } = props
  return (
    <div style={{ textAlign: 'center' }}>
      <CheckBox
        name={`resources[${resourceIndex}].isPromotion`}
        onChange={props.onChange}
        checked={rowData['promotedResource'] !== undefined ? true : false}
      />
    </div>
  )
}

export const Rank = (props: any) => {
  const { rowData, path, view, resourceIndex, showHours, isPromotion, onChangeRank, ranks } = props
  if (view === 'list' || showHours) {
    return <span className='list-text'>{isPromotion ? rowData.promotedResource[path] : rowData[path]}</span>
  }
  let rankOptions: any = checkResourceIsSubcontractor(rowData)
    ? ranks.filter((rank: any) => ['Subcontractor', 'Client Serving Contractor'].includes(rank.rank))
    : ranks.filter((rank: any) => !['Subcontractor', 'Client Serving Contractor'].includes(rank.rank))
  rankOptions = rankOptions.map((rank: any) => ({
    label: rank.abbreviation,
    value: rank.rank
  }))

  if (rankOptions.length > 0) {
    if (
      rowData !== undefined &&
      rowData !== 'undefined' &&
      rowData.rank !== undefined &&
      rowData.rank !== 'undefined' &&
      rowData.rank.toString().toUpperCase().includes('EXECUTIVE DIRECTOR')
    ) {
      if (rowData.rank.split(' ').length > 2) {
        rankOptions.push({ label: 'ED', value: rowData.rank })
      }
    }
  }
  const name = `resources[${resourceIndex}]${isPromotion ? '.promotedResource' : ''}.rank`
  const isDisabled = !(isPromotion || rowData.employee === 'Unnamed')
  return (
    <Select
      name={name}
      options={rankOptions}
      onChange={(option) => onChangeRank(option, !!isPromotion, resourceIndex, rowData)}
      isDisabled={isDisabled}
    />
  )
}

export const MarketScope = (props: any) => {
  const {
    rowData,
    path,
    view,
    resourceIndex,
    showHours,
    isPromotion,
    onChangeScope,
    marketScopeValues,
    onOpenModal,
    setAvailableCountries,
    selectedCountry
  } = props
  if (view === 'list' || showHours) {
    return <span className='list-text'>{isPromotion ? rowData.promotedResource[path] : rowData[path]}</span>
  }
  let scopeOptions: any
  let countryName: any

  rowData.availableCountryTier?.map((country: any) => {
    if (country.countryId == rowData.countryId) {
      countryName = country.countryName
    }
  })
  if (rowData.availableMarketScopes != undefined) {
    scopeOptions = getMarketScopOptions(rowData.availableMarketScopes)
  }

  const name = `resources[${resourceIndex}]${isPromotion ? '.promotedResource' : ''}.marketScopeId`
  //const isDisabled = !(isPromotion || rowData.employee === 'Unnamed')
  //console.log(countryName)
  return (
    <>
      <Select
        name={name}
        options={scopeOptions}
        onChange={(option) => onChangeScope(option, resourceIndex, rowData)}
        isDisabled={false}
      />
      <Tooltip
        placement='bottom-start'
        content={<span>{countryName}</span>}
        disabled={rowData.availableCountryTier?.length > 1 ? false : true}
      >
        <span
          className={`cost-center update-cost-centre-link ${
            rowData.availableCountryTier?.length > 1 ? 'clickable' : ''
          }`}
          onClick={() => rowData.availableCountryTier?.length > 1 && onOpenModal()}
          //rowData.availableCostCentres?.length > 0 &&
        >
          {countryName}
        </span>
      </Tooltip>
    </>
  )
}

function getMarketScopOptions(marketScopeData: any) {
  let scopeOptions: any
  let distinctMarkescopes: any
  let data = new Map()
  for (let item of marketScopeData) {
    data.set(item.marketScopeValue, item)
  }
  distinctMarkescopes = Array.from(data.values())
  scopeOptions = distinctMarkescopes?.sort((a: { marketScopeValue: string }, b: { marketScopeValue: string }) => {
    let sa = a.marketScopeValue.toLowerCase()
    let sb = b.marketScopeValue.toLowerCase()
    if (sa < sb) return -1
    else if (sa > sb) return 1
    return 0
  })
  scopeOptions = scopeOptions?.map((scope: any) => ({
    label: scope.marketScopeValue,
    value: scope.marketScopeId
  }))
  return scopeOptions
}

export function getCountryInformation(marketScopeData: any) {
  let distinctCountries: any
  let countryNames: any
  let countryName: any
  let data = new Map()
  for (let item of marketScopeData) {
    data.set(item.countryName, item)
  }
  distinctCountries = Array.from(data.values())
  if (distinctCountries?.length > 1) {
    countryNames = distinctCountries.filter((x: any) => x.countryName.includes('Tier 2'))
  } else {
    countryNames = [{ countryName: distinctCountries[0]?.countryName, countryId: distinctCountries[0]?.countryId }]
  }
  const availableCountries: any[] = []
  distinctCountries?.forEach((element: any) => {
    let countries = { countryId: element.countryId, countryName: element.countryName }
    availableCountries.push(countries)
  })
  let defaultCountry = { countryName: countryNames[0].countryName, countryId: countryNames[0].countryId }
  return { defaultCountry, availableCountries }
}

export function getCountryInformationByScope(marketScopeData: any, marketScopeId: any) {
  let availableCountriTiers: any[] = []
  let defaultCountry: any
  marketScopeData?.forEach((scope: any) => {
    if (scope.marketScopeId == marketScopeId) {
      let country = { countryId: scope.countryId, countryName: scope.countryName }
      availableCountriTiers.push(country)
    }
  })
  if (availableCountriTiers?.length > 1) {
    availableCountriTiers.forEach((country: any) => {
      if (country.countryName.includes('Tier 2')) {
        defaultCountry = { countryId: country.countryId, countryName: country.countryName }
      }
    })
  } else {
    defaultCountry = {
      countryId: availableCountriTiers[0]?.countryId,
      countryName: availableCountriTiers[0]?.countryName
    }
  }
  return { defaultCountry, availableCountriTiers }
}

export const Employee = (props: any) => {
  const { rowData, view, onOpenModal } = props
  if (view === 'list') {
    return (
      <div>
        <span className='employee'>{rowData['employee']}</span>
        {rowData.promotedResource && <span className='promotion-tag'>PROMOTION</span>}
        {rowData.isGDS && <span className='gds-tag'>GDS</span>}
      </div>
    )
  }
  return (
    <>
      <span className='employee'>{rowData['employee']}</span>
      <br />
      {rowData.employee === 'Unnamed' ? (
        <Tooltip placement='bottom-start' content={<span>{rowData['costCentre']}</span>} disabled={!rowData.costCentre}>
          <span
            className={`cost-center update-cost-centre-link ${
              rowData.availableCostCentres?.length > 1 ? 'clickable' : ''
            }`}
            onClick={() => rowData.availableCostCentres?.length > 0 && onOpenModal()}
          >
            {rowData.costCentre
              ? rowData.costCentre
              : rowData.availableCostCentres?.length > 0
              ? 'Update cost centre'
              : ''}
          </span>
        </Tooltip>
      ) : (
        <Tooltip placement='bottom-start' content={<span>{rowData['costCentre']}</span>}>
          <span className='cost-center'>{rowData['costCentre']}</span>
        </Tooltip>
      )}
    </>
  )
}

export const Role = (props: any) => {
  const { rowData, path, view, resourceIndex, errors, touched } = props
  if (view === 'list') {
    return <span className='list-text'>{rowData[path]}</span>
  }
  return (
    <>
      <Field
        type='text'
        maxLength={50}
        name={`resources[${resourceIndex}].role`}
        className={`input-field ${renderError(`resources[${resourceIndex}].role`, errors, touched) ? 'error' : ''}`}
      />
      <ErrorMessage name={`resources[${resourceIndex}].role`} component={TextError} />
    </>
  )
}

export const Workstreams = (props: any) => {
  const { rowData, path, view, resourceIndex, workstreamOptions } = props
  if (view === 'list') {
    return <span className='list-text'>{rowData[path]}</span>
  }
  return <Select name={`resources[${resourceIndex}].workstream`} options={workstreamOptions} fromExpenses={false} />
}

export const Actions = (props: any) => {
  const { rowData, resourceIndex, isPromotion } = props
  const [showDuplicateDialog, setShowDuplicateDialog] = useState(false)
  const [duplicateCount, setDuplicateCount] = useState(1)

  const Closepopup = () => {
    setShowDuplicateDialog(false)
  }

  useEffect(() => {
    if (showDuplicateDialog) {
      const modal = document.getElementById('modal') as HTMLElement
      modal.style.display = 'block'
    }
  }, [showDuplicateDialog])

  const handleDuplicate = () => {
    setShowDuplicateDialog(false)
    const data = {
      ...rowData,
      ...(rowData.weeklyTimeUnits?.length > 0 && {
        weeklyTimeUnits: rowData.weeklyTimeUnits.map((timeUnit: any) => _.omit(timeUnit, ['id']))
      })
    }
    const duplicatedData = _.omit(data, ['id'])

    props.duplicateResource(duplicatedData)
  }

  return (
    <div className='actions-container'>
      {rowData.employee === 'Unnamed' && (
        <>
          <Tooltip placement='top' content='Duplicate'>
            <span
              className='material-icons duplicate-icon'
              onClick={() => {
                setShowDuplicateDialog(true)
              }}
            >
              content_copy
            </span>
          </Tooltip>
        </>
      )}

      <Tooltip placement='top' content={<span>Delete</span>}>
        <span
          className='material-icons delete-icon'
          onClick={() => {
            isPromotion ? props.deletePromotion(resourceIndex) : props.deleteResource(resourceIndex)
          }}
        >
          delete_outline
        </span>
      </Tooltip>

      <PopupModal
        show={showDuplicateDialog}
        duplicateCount={duplicateCount}
        setDuplicateCount={setDuplicateCount}
        duplicateResource={handleDuplicate}
        close={Closepopup}
      />
    </div>
  )
}
