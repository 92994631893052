import React, { useState } from 'react'
import Modal from 'react-modal'
import NamedUnnamedToggle from '../toggles/NamedUnnamedToggle'
import UnnamedForm from './UnnamedForm'
import { MarketScopeType, Resource } from '../ResourceTypes'
import './AddResourceModal.css'
import NamedResource from './NamedResource'

Modal.setAppElement('#root')

type Props = {
  isOpen: boolean
  toggleAddResourceModal: (control: boolean) => void
  addResource: (isNamed: boolean, resource: Resource, marketScope?: any, countris?: any, countryId?: any,marketScopeId?: any, availableMarketScopes?:any[],marketRate?:any) => void
  planCurrency: string
}

const AddResourceModal = (props: Props) => {
  const [toggleControl, setToggleControl] = useState<'named' | 'unnamed'>('named')

  const toggleNamedUnnamed = () => {
    setToggleControl((prevState) => (prevState === 'named' ? 'unnamed' : 'named'))
  }

  return (
    <div>
      <Modal
        isOpen={props.isOpen}
        contentLabel='Add Resource Modal'
        className='add-resource-modal'
        overlayClassName='add-resource-modal-overlay'
      >
        <h3 className='heading-three m-0'>Add Resource</h3>

        <div style={{ marginTop: '15px' }}>
          <NamedUnnamedToggle control={toggleControl} toggleNamedUnnamed={toggleNamedUnnamed} />
        </div>

        <div className='notification-container'>
          <span className='material-icons-outlined'>info</span>
          <span className='notification-text'>
            Add GDS team members as unnamed resources to reflect accurate pricing.
          </span>
        </div>

        {toggleControl === 'named' ? (
          <NamedResource
            toggleAddResourceModal={props.toggleAddResourceModal}
            addResource={props.addResource}
            planCurrency={props.planCurrency}
          />
        ) : (
          <UnnamedForm toggleAddResourceModal={props.toggleAddResourceModal} addResource={props.addResource} />
        )}
      </Modal>
    </div>
  )
}

export default AddResourceModal
