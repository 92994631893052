import Tippy, { TippyProps } from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import './Tooltip.css'

type Props = {
  className?: string
} & TippyProps

const Tooltip = ({ children, className, ...rest }: Props) => {
  return (
    <Tippy {...rest} className={`tippy-tooltip ${className ? className : ''}`}>
      {children}
    </Tippy>
  )
}

export default Tooltip
