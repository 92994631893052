import styles from './VendorOrSubcontractor.module.css'
import { numberWithCommas } from '../../utils/helperFunctions'

const VendorOrSubcontractor = (props: any) => {
  const { pricingPlanData } = props
  const mobilityExpenses = pricingPlanData.expenses?.filter((expense: any) => expense.expenseType === 'Other')

  let rows = [
    { label: 'Professional Consultants', name: 'consultants', cost: 0 },
    { label: 'Third Party Subcontractors', name: 'subcontractors', cost: 0 },
    { label: 'Alliance Partner Fees', name: 'alliance', cost: 0 },
    { label: 'IFMs', name: 'IFMs', cost: 0 }
  ]

  rows =
    mobilityExpenses?.length > 0
      ? rows.map((row: any) => {
          const items = mobilityExpenses.filter((item: any) => item.category === row.name)
          const cost =
            items?.length > 0 &&
            items.reduce((total: any, item: any) => {
              return (total += item.cost || 0)
            }, 0)
          if (cost == false) return { ...row, cost: 0 }
          else return { ...row, cost }
        })
      : rows

  const total = rows.reduce((result: number, row: any) => {
    return (result += row.cost || 0)
  }, 0)

  rows = [...rows, { label: 'Total', name: 'total', cost: total }]

  return (
    <>
      <header>
        <div className={styles.title}>Vendor / Subcontractor</div>
      </header>

      <div className={styles.table}>
        <div className={styles.thead}>
          <div className={styles.tr}>
            <div className={styles.th}></div>
            <div className={styles.th}>Cost</div>
          </div>
        </div>
        <div className={styles.tbody}>
          {rows.map((item) => {
            return (
              <div className={styles.tr} key={item.label}>
                <div className={`${styles.td} ${styles.label}`}>
                  {item.label === 'Total' ? <b>{item.label}</b> : item.label}
                </div>
                <div className={`${styles.td} ${styles.cost}`}>
                  {numberWithCommas(item.cost)} {pricingPlanData.planCurrency}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default VendorOrSubcontractor
