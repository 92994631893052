import { Form, Formik, FormikHelpers } from 'formik'
import { useContext, useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import * as Yup from 'yup'

import './Expenses.css'
import Footer from '../footer/Footer'
import Header from '../header/Header'
import NavMenu from '../navigation/NavMenu'
import MobilityExpenses from './MobilityExpenses'
import TechnologyExpenses from './TechnologyExpenses'
import { RouteParamTypes } from '../global-inputs/GlobalInputTypes'
import { getPricingPlan, saveNewPricingPlan, updatePricingPlan } from '../utils/fetchHandler'
import Loading from '../loading/Loading'
import { UserContext } from '../../App'

const Expenses = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [open, setOpen] = useState([true, true]) // Toggles visibility of the tables

  const [totalTechExpense, setTotalTechExpense] = useState(0) // Cost of the Tech Expenses
  const [totalMobilityExpense, setTotalMobilityExpense] = useState(0) // Cost of the Mobility and Other Expenses
  const [totalRevenue, setTotalRevenue] = useState(0)
  const [expensesIncurred, setExpensesIncurred] = useState(0) // Expenses Incurred value shown in the footer
  const [billedToClient, setBilledToClient] = useState(0) // Expenses Billed to Client value shown in the footer

  const { pricingPlanId } = useParams<RouteParamTypes>() // Access the URL route parameter to obtain pricing plan ID

  const [expenseWorkstreams, setExpenseWorkstreams] = useState<any[]>([])
  const [mobilityExpenseResources, setMobilityExpenseResources] = useState<any[]>([])
  const [pricingPlan, setPricingPlan] = useState<any>()
  const [detailsFetched, setDetailsFetched] = useState(false)

  const [techExpenses, setTechExpenses] = useState<any>([]) // List of all the Tech Expenses rows
  const [mobilityExpenses, setMobilityExpenses] = useState<any>([]) // List of all the Mobility and Other Expenses rows

  const [initialRetrieved, setInitialRetrieved] = useState(false) // Only display after existing expenses are retrieved
  const [isExistingPlan, setIsExistingPlan] = useState(false)

  const [planCurrency, setPlanCurrency] = useState<string>('')
  const [showWorkstream, setShowWorkstream] = useState<boolean>(true) // Determine if the workstreams dropdown should be displayed

  const [nextButtonPressed, setNextButtonPressed] = useState(false)
  const [highestAccessedPage, setHighestAccessedPage] = useState<string>('')
  const userId = useContext(UserContext)[1]
  const [isEditable, setIsEditable] = useState(true);

  const history = useHistory()

  const openTab = (tabName: string) => {
    let newOpenState = [...open]

    if (tabName === 'tech') {
      newOpenState[0] ? (newOpenState[0] = false) : (newOpenState[0] = true)
    } else if (tabName === 'mobility') {
      newOpenState[1] ? (newOpenState[1] = false) : (newOpenState[1] = true)
    }

    setOpen(newOpenState)
  }

  const requiredValidationText = 'Please complete required field.'
  const shortRequiredValidationText = 'Required.'
  const integerValidationText = 'Must be an integer.'
  const positiveValidationText = 'Must be greater than 0.'

  const validationSchema = Yup.object({
    techRows: Yup.array().of(
      Yup.object().shape({
        category: Yup.string().required(requiredValidationText),
        workstream: Yup.string(),
        cost: Yup.number()
          .required(requiredValidationText)
          .positive(positiveValidationText)
          .integer(integerValidationText),
        revenue: Yup.number().min(0, 'Must be 0 or greater.').integer(integerValidationText),
        recoverable: Yup.boolean().when('category', {
          is: 'otherTechExpenses',
          then: Yup.boolean().required(shortRequiredValidationText)
        })
      })
    ),
    mobilityRows: Yup.array().of(
      Yup.object().shape({
        category: Yup.string().required(requiredValidationText),
        resource: Yup.string().required(requiredValidationText),
        workstream: Yup.string()
          .when('category', {
            is: (value: string) => showWorkstream === true,
            then: Yup.string().required(requiredValidationText)
          })
          .when('category', {
            is: (value: string) => showWorkstream === false,
            then: Yup.string()
          }),
        cost: Yup.number()
          .required(requiredValidationText)
          .positive(positiveValidationText)
          .integer(integerValidationText),
        recoverable: Yup.boolean().required(shortRequiredValidationText)
      })
    )
  })

  useEffect(() => {
    ;(async () => {
      await fetchPricingPlan()
      setIsLoading(false)
    })()
  }, [])

  const [initialFormValues, setInitialFormValues] = useState({
    techRows: [
      {
        category: '',
        workstream: 'unspecified',
        cost: undefined,
        revenue: undefined,
        recoverable: undefined,
        pricingPlanId: pricingPlanId,
        expenseType: 'Technology'
      }
    ],
    mobilityRows: [
      {
        category: '',
        resource: '',
        workstream: '',
        cost: undefined,
        recoverable: undefined,
        pricingPlanId: pricingPlanId,
        expenseType: 'Other'
      }
    ]
  })

  const determineAccessibility = (data: { planStatus: any; isDelegated: any; isCheckedIn: any; delegateAccess: any }) => {
    const { planStatus, isDelegated, isCheckedIn, delegateAccess } = data;
 
    if (planStatus === 'Owned') {
      if (isDelegated) {
        return isCheckedIn;
      } else {
        return true;
      }
    } else{
      return isCheckedIn;
    }
  };

  // Fetches existing data from a worksheet where the route contains the pricingPlanId
  const fetchPricingPlan = async () => {
    try {
      const worksheet = await getPricingPlan(pricingPlanId,userId)
      setIsEditable(determineAccessibility(worksheet))
      setHighestAccessedPage(worksheet.highestAccessedPage)
      if (worksheet.workstreams.length === 0) {
        setShowWorkstream(false)
      }
      if (worksheet !== 'Failed to retrieve pricing plan with ID new') {
        setIsExistingPlan(true)
        setPricingPlan(worksheet)
        setPlanCurrency(worksheet.planCurrency)
      } else {
        setPricingPlan({ worksheetName: 'Unnamed Worksheet' })
      }

      if (!worksheet.hasOwnProperty('expenses')) {
        setInitialRetrieved(true)
      } else if (worksheet.expenses.length === 0) {
        const existingValues = {
          techRows: [],
          mobilityRows: []
        }
        setInitialFormValues(existingValues)
        setInitialRetrieved(true)
      }

      if (worksheet.hasOwnProperty('expenses') && worksheet.expenses.length > 0) {
        const existingTechExpenses = []
        const existingMobilityExpenses = []

        for (let i = 0; i < worksheet.expenses.length; i++) {
          if (worksheet.expenses[i].expenseType === 'Technology') {
            existingTechExpenses.push(worksheet.expenses[i])
          } else if (worksheet.expenses[i].expenseType === 'Other') {
            existingMobilityExpenses.push(worksheet.expenses[i])
          }
        }

        setTechExpenses(existingTechExpenses)
        setMobilityExpenses(existingMobilityExpenses)

        if (!initialRetrieved) {
          const existingValues = {
            techRows: existingTechExpenses,
            mobilityRows: existingMobilityExpenses
          }

          setInitialFormValues(existingValues)
        }
      }

      // Workstreams to be used in both tables
      const newWorkstreamArray = []
      if (worksheet.hasOwnProperty('workstreams')) {
        for (let i = 0; i < worksheet.workstreams.length; i++) {
          let newWorkstream = {
            value: worksheet.workstreams[i].title,
            label: worksheet.workstreams[i].title
          }
          newWorkstreamArray.push(newWorkstream)
        }
      }

      newWorkstreamArray.push({ value: 'unspecified', label: 'Unspecified' })
      setExpenseWorkstreams(newWorkstreamArray)

      // Resources to be used in the Mobility Expenses Table
      const newResourcesArray = []
      if (worksheet.hasOwnProperty('resources')) {
        for (let i = 0; i < worksheet.resources.length; i++) {
          let newResource = null
          if (worksheet.resources[i].employee === '(Unnamed)') {
            newResource = {
              value: worksheet.resources[i].id.toString() + '||' + worksheet.resources[i].workstream,
              label: worksheet.resources[i].rank + ' - ' + worksheet.resources[i].costCentre
            }
          } else {
            newResource = {
              value: worksheet.resources[i].id.toString() + '||' + worksheet.resources[i].workstream,
              label:
                worksheet.resources[i].employee +
                ' - ' +
                worksheet.resources[i].rank +
                ' - ' +
                worksheet.resources[i].costCentre
            }
          }
          newResourcesArray.push(newResource)
        }
      }
      newResourcesArray.push({ value: 'unspecified', label: 'Unspecified' })
      setMobilityExpenseResources(newResourcesArray)

      setDetailsFetched(true)
      setInitialRetrieved(true)
    } catch (error) {
      console.error('Failed to retrieve pricingPlan')
    }
  }

  const onSubmitForm = async (values: any, actions: FormikHelpers<any>) => {
    const mobilityExpenses = values.mobilityRows
    const technologyExpenses = values.techRows

    pricingPlan.expenses = [...technologyExpenses, ...mobilityExpenses]
    if (nextButtonPressed) {
      if (pricingPlan.highestAccessedPage === 'Expenses') {
        pricingPlan.highestAccessedPage = 'Scenarios'
      }
    }
    if (isExistingPlan) {
      if(isEditable){
        await updatePricingPlan(pricingPlan)
      }
    } else {
      await saveNewPricingPlan(pricingPlan)
    }

    // Need to reset the form and pass in the new values (prevents it from resetting the saved changes)
    actions.resetForm({ values: { ...initialFormValues, ...pricingPlan } })
  }

  useEffect(() => {
    calculateExpensesIncurred()
    calculateBilledToClient()
  }, [techExpenses, mobilityExpenses])

  // Expenses Incurred = All costs except CT Products, regardless of recoverable
  const calculateExpensesIncurred = () => {
    let newExpensesIncurred = 0
    for (let i = 0; i < techExpenses.length; i++) {
      if (techExpenses[i].category !== 'ctProducts') {
        if (isNaN(techExpenses[i].cost)) {
          techExpenses[i].cost = 0
        }
        newExpensesIncurred += techExpenses[i].cost
      }
    }

    for (let j = 0; j < mobilityExpenses.length; j++) {
      if (isNaN(mobilityExpenses[j].cost)) {
        mobilityExpenses[j].cost = 0
      }
      newExpensesIncurred += mobilityExpenses[j].cost
    }
    setExpensesIncurred(newExpensesIncurred)
  }

  // Billed To Client = All costs except CT Products, that are recoverable
  const calculateBilledToClient = () => {
    let newBilledToClient = 0
    for (let i = 0; i < techExpenses.length; i++) {
      if (techExpenses[i].category !== 'ctProducts' && techExpenses[i].recoverable === true) {
        if (isNaN(techExpenses[i].cost)) {
          techExpenses[i].cost = 0
        }
        newBilledToClient += techExpenses[i].cost
      }
    }

    for (let j = 0; j < mobilityExpenses.length; j++) {
      if (mobilityExpenses[j].recoverable) {
        if (isNaN(mobilityExpenses[j].cost)) {
          mobilityExpenses[j].cost = 0
        }
        newBilledToClient += mobilityExpenses[j].cost
      }
    }
    setBilledToClient(newBilledToClient)
  }

  const [deletionFlag, setDeletionFlag] = useState(false) // Re-renders table when deletion occurs to avoid bug where deleted rows would visually merge values

  useEffect(() => {
    if (deletionFlag) {
      setDeletionFlag(false)
    }
  }, [deletionFlag])

  const checkErrors = (errors: any) => {
    let hasErrors = false
    if (errors.techRows) {
      for (let i = 0; i < errors.techRows.length; i++) {
        if (errors.techRows[i] !== undefined) {
          hasErrors = true
        }
      }
    }

    if (errors.mobilityRows) {
      for (let i = 0; i < errors.mobilityRows.length; i++) {
        if (errors.mobilityRows[i] !== undefined) {
          hasErrors = true
        }
      }
    }

    return hasErrors
  }

  if (isLoading) return <Loading />

  return (
    <div>
      <div className='expenses-page-container'>
        <Header isFixedWidth={true} />

        {initialRetrieved && (
          <Formik
            initialValues={initialFormValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => onSubmitForm(values, actions)}
          >
            {(formik) => {
              setInitialFormValues(formik.values)
              return (
                <Form>
                  <NavMenu
                    worksheetData={pricingPlan}
                    selectedTab={2}
                    highestAccessedPage={highestAccessedPage}
                    onNavSaveClick={(tabnum) => {
                      if (formik.isValid) {
                        formik.submitForm().then(() => {
                          if (tabnum == 0) history.push(`/pricing-plan/${pricingPlanId}/global-input`)
                          if (tabnum == 1) history.push(`/pricing-plan/${pricingPlanId}/resources`)
                          if (tabnum == 2) history.push(`/pricing-plan/${pricingPlanId}/expenses`)
                          if (tabnum == 3) history.push(`/pricing-plan/${pricingPlanId}/scenarios`)
                          if (tabnum == 4) history.push(`/pricing-plan/${pricingPlanId}/summary`)
                        })
                      }
                    }}
                  />
                  <div  className={`inputs-main ${!isEditable ? 'disabled' : ''}`}>
                  <div className='expenses-body-container'>
                    <div className='expenses-text-container'>
                      <h2 className='heading-two'>Expenses</h2>
                      <p className='body-text'>Add Technology, Travel-Related, and Other Expenses</p>
                      <p className='disclaimer-text'>
                        Please do not enter any unnecessary personal information or any sensitive personal data
                        (including tax file numbers or other government identifiers), client confidential information,
                        audit secrets, state secrets, commercial secrets, or anything that would violate professional
                        secrecy or confidentiality rules or that would be considered abusive/irrelevant.
                      </p>
                    </div>
                    <div>
                      <div className='table-header' onClick={() => openTab('tech')}>
                        <p className='body-text'>CT Product Expenses</p>
                        {open[0] ? (
                          <span className='material-icons'>expand_less</span>
                        ) : (
                          <span className='material-icons'>expand_more</span>
                        )}
                      </div>
                      {detailsFetched && !deletionFlag && (
                        <TechnologyExpenses
                          display={open[0]}
                          totalExpenses={totalTechExpense}
                          setTotalExpenses={setTotalTechExpense}
                          totalRevenue={totalRevenue}
                          setTotalRevenue={setTotalRevenue}
                          techExpenseWorkstreams={expenseWorkstreams}
                          techExpenses={techExpenses}
                          setTechExpenses={setTechExpenses}
                          setDeletionFlag={setDeletionFlag}
                          planCurrency={planCurrency}
                          showWorkstream={showWorkstream}
                        />
                      )}
                      <div className='table-header table-header-mobility' onClick={() => openTab('mobility')}>
                        <p className='body-text'>Travel and Other Related Expenses</p>
                        {open[1] ? (
                          <span className='material-icons'>expand_less</span>
                        ) : (
                          <span className='material-icons'>expand_more</span>
                        )}
                      </div>
                      {detailsFetched && !deletionFlag && (
                        <MobilityExpenses
                          display={open[1]}
                          totalExpenses={totalMobilityExpense}
                          setTotalExpenses={setTotalMobilityExpense}
                          mobilityExpenseResources={mobilityExpenseResources}
                          mobilityExpenseWorkstreams={expenseWorkstreams}
                          mobilityExpenses={mobilityExpenses}
                          setMobilityExpenses={setMobilityExpenses}
                          setDeletionFlag={setDeletionFlag}
                          planCurrency={planCurrency}
                          showWorkstream={showWorkstream}
                        />
                      )}
                    </div>
                  </div>
                  </div>
                  <div className='expenses-footer'>
                    <div className='expenses-summary-container'>
                      <div className='expenses-summary-text'>
                        <p className='body-text'>
                          Expenses Incurred: {Math.ceil(expensesIncurred).toLocaleString() + ' ' + planCurrency}
                        </p>
                        <p className='body-text'>
                          Expenses Billed to Client: {Math.ceil(billedToClient).toLocaleString() + ' ' + planCurrency}
                        </p>
                      </div>
                    </div>
                    <Footer
                      worksheetData={pricingPlan}
                      onChangeWorksheet={(e: React.ChangeEvent<HTMLInputElement>) => {
                        formik.setFieldValue('worksheetName', e.target.value)
                        pricingPlan.worksheetName = e.target.value
                      }}
                      nextPage={'Scenarios'}
                      onClickBackButton={() => {
                        // Display loading screen immediately (since no need to show validation/errors)
                        setIsLoading(true)
                        isLoading && <Loading />
                        formik.submitForm().then(() => {
                          // Does NOT perform validation
                          history.push(`/pricing-plan/${pricingPlanId}/resources`)
                        })
                      }}
                      onClickNextButton={async () => {
                        setNextButtonPressed(true)
                        await formik.submitForm()
                        let hasErrors = checkErrors(formik.errors)
                        if (formik.isValid || hasErrors === false) {
                          history.push(`/pricing-plan/${pricingPlanId}/scenarios`)
                        }
                      }}
                      onClickSaveButton={() => formik.handleSubmit()}
                      isSaveButtonDisabled={!(formik.dirty && formik.isValid)}
                    />
                  </div>
                </Form>
              )
            }}
          </Formik>
        )}
      </div>
    </div>
  )
}

export default Expenses
