export const CURRENCY_LIST = [
  {
    label: 'UAE Dirham (AED)',
    value: 'AED'
  },
  {
    label: 'Afghani (AFN)',
    value: 'AFN'
  },
  {
    label: 'Lek (ALL)',
    value: 'ALL'
  },
  {
    label: 'Armenian Dram (AMD)',
    value: 'AMD'
  },
  {
    label: 'Netherlands Antillian Guilder (ANG)',
    value: 'ANG'
  },
  {
    label: 'Kwanza (AOA)',
    value: 'AOA'
  },
  {
    label: 'Argentine Peso (ARP)',
    value: 'ARP'
  },
  {
    label: 'Australian Dollar (AUD)',
    value: 'AUD'
  },
  {
    label: 'Aruban Guilder (AWG)',
    value: 'AWG'
  },
  {
    label: 'Azerbaijanian Manat (AZN)',
    value: 'AZN'
  },
  {
    label: 'Convertible Marks (BAM)',
    value: 'BAM'
  },
  {
    label: 'Barbados Dollar (BBD)',
    value: 'BBD'
  },
  {
    label: 'Taka (BDT)',
    value: 'BDT'
  },
  {
    label: 'Bulgarian Lev (BGN)',
    value: 'BGN'
  },
  {
    label: 'Bahraini Dinar (BHD)',
    value: 'BHD'
  },
  {
    label: 'Burundi Franc (BIF)',
    value: 'BIF'
  },
  {
    label: 'Bermudian Dollar (BMD) (customarily known as Bermuda Dollar)',
    value: 'BMD'
  },
  {
    label: 'Brunei Dollar (BND)',
    value: 'BND'
  },
  {
    label: 'Boliviano (BOB)',
    value: 'BOB'
  },
  {
    label: 'Brazilian Real (BRL)',
    value: 'BRL'
  },
  {
    label: 'Bahamian Dollar (BSD)',
    value: 'BSD'
  },
  {
    label: 'Ngultrum (BTN)',
    value: 'BTN'
  },
  {
    label: 'Pula (BWP)',
    value: 'BWP'
  },
  {
    label: 'Belarusian Ruble (BYR/BYN)',
    value: 'BYR/BYN'
  },
  {
    label: 'Belize Dollar (BZD)',
    value: 'BZD'
  },
  {
    label: 'Canadian Dollar (CAD)',
    value: 'CAD'
  },
  {
    label: 'Congolese Franc (CDF)',
    value: 'CDF'
  },
  {
    label: 'Swiss Franc (CHF)',
    value: 'CHF'
  },
  {
    label: 'Unidad de Fomento (CLF)',
    value: 'CLF'
  },
  {
    label: 'Chilean Peso (CLP)',
    value: 'CLP'
  },
  {
    label: 'Yuan Renminbi (CNY)',
    value: 'CNY'
  },
  {
    label: 'Colombian Peso (COP)',
    value: 'COP'
  },
  {
    label: 'Costa Rican Colon (CRC)',
    value: 'CRC'
  },
  {
    label: 'Cuban Peso (CUP)',
    value: 'CUP'
  },
  {
    label: 'Cabo Verde Escudo (CVE)',
    value: 'CVE'
  },
  {
    label: 'Old Cyrpus Pound (CYP) (replaced by Euro EUR)',
    value: 'CYP'
  },
  {
    label: 'Czech Koruna (CZK)',
    value: 'CZK'
  },
  {
    label: 'Djibouti Franc (DJF)',
    value: 'DJF'
  },
  {
    label: 'Danish Krone (DKK)',
    value: 'DKK'
  },
  {
    label: 'Dominican Peso (DOP)',
    value: 'DOP'
  },
  {
    label: 'Algerian Dinar (DZD)',
    value: 'DZD'
  },
  {
    label: 'Kroon (EEK) (replaced by Euro EUR)',
    value: 'EEK'
  },
  {
    label: 'Egyptian Pound  (EGP)',
    value: 'EGP'
  },
  {
    label: 'Ethiopian Birr (ETB)',
    value: 'ETB'
  },
  {
    label: 'Euro (EUR)',
    value: 'EUR'
  },
  {
    label: 'Fiji Dollar (FJD)',
    value: 'FJD'
  },
  {
    label: 'Falkland Islands Pound (FKP)',
    value: 'FKP'
  },
  {
    label: 'Pound Sterling (GBP)',
    value: 'GBP'
  },
  {
    label: 'Lari (GEL)',
    value: 'GEL'
  },
  {
    label: 'Ghana Cedi (GHC)',
    value: 'GHC'
  },
  {
    label: 'Gibraltar Pound (GIP)',
    value: 'GIP'
  },
  {
    label: 'Dalasi (GMD)',
    value: 'GMD'
  },
  {
    label: 'Guinea Franc (GNF)',
    value: 'GNF'
  },
  {
    label: 'Quetzal (GTQ)',
    value: 'GTQ'
  },
  {
    label: 'Guyana Dollar (GYD)',
    value: 'GYD'
  },
  {
    label: 'Hong Kong Dollar (HKD)',
    value: 'HKD'
  },
  {
    label: 'Lempira (HNL)',
    value: 'HNL'
  },
  {
    label: 'Kuna (HRK)',
    value: 'HRK'
  },
  {
    label: 'Gourde (HTG)',
    value: 'HTG'
  },
  {
    label: 'Forint (HUF)',
    value: 'HUF'
  },
  {
    label: 'Indonesian Rupiah (IDR)',
    value: 'IDR'
  },
  {
    label: 'New Israeli Sheqel (ILS)',
    value: 'ILS'
  },
  {
    label: 'Indian Rupee (INR)',
    value: 'INR'
  },
  {
    label: 'Iraqi Dinar (IQD)',
    value: 'IQD'
  },
  {
    label: 'Iranian Rial (IRR)',
    value: 'IRR'
  },
  {
    label: 'Iceland Krona (ISK)',
    value: 'ISK'
  },
  {
    label: 'Jamaican Dollar (JMD)',
    value: 'JMD'
  },
  {
    label: 'Jordanian Dinar (JOD)',
    value: 'JOD'
  },
  {
    label: 'Yen (JPY)',
    value: 'JPY'
  },
  {
    label: 'Kenyan Shilling (KES)',
    value: 'KES'
  },
  {
    label: 'Som (KGS)',
    value: 'KGS'
  },
  {
    label: 'Riel (KHR)',
    value: 'KHR'
  },
  {
    label: 'Comoro Franc (KMF)',
    value: 'KMF'
  },
  {
    label: 'North Korean Won (KPW)',
    value: 'KPW'
  },
  {
    label: 'Won (KRW)',
    value: 'KRW'
  },
  {
    label: 'Kuwaiti Dinar (KWD)',
    value: 'KWD'
  },
  {
    label: 'Cayman Islands Dollar (KYD)',
    value: 'KYD'
  },
  {
    label: 'Tenge (KZT)',
    value: 'KZT'
  },
  {
    label: 'Kip (LAK)',
    value: 'LAK'
  },
  {
    label: 'Lebanese Pound (LBP)',
    value: 'LBP'
  },
  {
    label: 'Sri Lanka Rupee (LKR)',
    value: 'LKR'
  },
  {
    label: 'Liberian Dollar (LRD)',
    value: 'LRD'
  },
  {
    label: 'Loti (LSL)',
    value: 'LSL'
  },
  {
    label: 'Lithuanian Litas (LTL) (replaced by Euro EUR as of 01.01.2015)',
    value: 'LTL'
  },
  {
    label: 'Old Latvian Lats (LVL) (replaced by Euro EUR)',
    value: 'LVL'
  },
  {
    label: 'Libyan Dinar (LYD)',
    value: 'LYD'
  },
  {
    label: 'Moroccan Dirham (MAD)',
    value: 'MAD'
  },
  {
    label: 'Moldovan Leu (MDL)',
    value: 'MDL'
  },
  {
    label: 'Malagascy Ariary (MGA)',
    value: 'MGA'
  },
  {
    label: 'Denar (MKD)',
    value: 'MKD'
  },
  {
    label: 'Kyat (MMK)',
    value: 'MMK'
  },
  {
    label: 'Tugrik (MNT)',
    value: 'MNT'
  },
  {
    label: 'Pataca (MOP)',
    value: 'MOP'
  },
  {
    label: 'Ouguiya (MRU)',
    value: 'MRU'
  },
  {
    label: 'Old Maltese Lira (MTL) (replaced by Euro EUR)',
    value: 'MTL'
  },
  {
    label: 'Mauritius Rupee (MUR)',
    value: 'MUR'
  },
  {
    label: 'Rufiyaa (MVR)',
    value: 'MVR'
  },
  {
    label: 'Kwacha (ZMW)',
    value: 'ZMW'
  },
  {
    label: 'Mexican Peso (MXN)',
    value: 'MXN'
  },
  {
    label: 'Malaysian Ringgit (MYR)',
    value: 'MYR'
  },
  {
    label: 'Mozambique Metical (MZN)',
    value: 'MZN'
  },
  {
    label: 'Namibia Dollar (NAD)',
    value: 'NAD'
  },
  {
    label: 'Naira (NGN)',
    value: 'NGN'
  },
  {
    label: 'Cordoba Oro (NIO)',
    value: 'NIO'
  },
  {
    label: 'Norwegian Krone (NOK)',
    value: 'NOK'
  },
  {
    label: 'Nepalese Rupee (NPR)',
    value: 'NPR'
  },
  {
    label: 'New Zealand Dollar (NZD)',
    value: 'NZD'
  },
  {
    label: 'Rial Omani (OMR)',
    value: 'OMR'
  },
  {
    label: 'Balboa (PAB)',
    value: 'PAB'
  },
  {
    label: 'Nuevo Sol (PEN)',
    value: 'PEN'
  },
  {
    label: 'Kina (PGK)',
    value: 'PGK'
  },
  {
    label: 'Philippine Peso (PHP)',
    value: 'PHP'
  },
  {
    label: 'Pakistan Rupee (PKR)',
    value: 'PKR'
  },
  {
    label: 'Zloty (PLN)',
    value: 'PLN'
  },
  {
    label: 'Guarani (PYG)',
    value: 'PYG'
  },
  {
    label: 'Qatari Rial (QAR)',
    value: 'QAR'
  },
  {
    label: 'Romanian Leu (RON)',
    value: 'RON'
  },
  {
    label: 'Serbian Dinar (RSD)',
    value: 'RSD'
  },
  {
    label: 'Russian Ruble (RUB)',
    value: 'RUB'
  },
  {
    label: 'Rwana Franc (RWF)',
    value: 'RWF'
  },
  {
    label: 'Saudi Riyal (SAR)',
    value: 'SAR'
  },
  {
    label: 'Solomon Islands Dollar (SBD)',
    value: 'SBD'
  },
  {
    label: 'Seychelles Rupee (SCR)',
    value: 'SCR'
  },
  {
    label: 'Sudanese Pound (SDG)',
    value: 'SDG'
  },
  {
    label: 'Swedish Krona (SEK)',
    value: 'SEK'
  },
  {
    label: 'Singapore Dollar (SGD)',
    value: 'SGD'
  },
  {
    label: 'Saint Helena Pound (SHP)',
    value: 'SHP'
  },
  {
    label: 'Old Tolar (SIT) (replaced by Euro EUR)',
    value: 'SIT'
  },
  {
    label: 'Old Slovak Koruna (SKK) (replaced by euro EUR)',
    value: 'SKK'
  },
  {
    label: 'Leone (SLL)',
    value: 'SLL'
  },
  {
    label: 'Somali Shilling (SOS)',
    value: 'SOS'
  },
  {
    label: 'Surinam Dollar (SRD)',
    value: 'SRD'
  },
  {
    label: 'South Sudanese Pound (SSP)',
    value: 'SSP'
  },
  {
    label: 'Dobra (STD)',
    value: 'STD'
  },
  {
    label: 'El Salvador Colon (SVC)',
    value: 'SVC'
  },
  {
    label: 'Syrian Pound (SYP)',
    value: 'SYP'
  },
  {
    label: 'Lilangeni (SZL)',
    value: 'SZL'
  },
  {
    label: 'Baht (THB)',
    value: 'THB'
  },
  {
    label: 'Somoni (TJS)',
    value: 'TJS'
  },
  {
    label: 'Turkmenistan New Manat (TMT)',
    value: 'TMT'
  },
  {
    label: 'Tunisian Dinar (TND)',
    value: 'TND'
  },
  {
    label: "Pa'anga (TOP)",
    value: 'TOP'
  },
  {
    label: 'Turkish Lira (TRY)',
    value: 'TRY'
  },
  {
    label: 'Trinidad and Tobago Dollar (TTD)',
    value: 'TTD'
  },
  {
    label: 'New Taiwan Dollar (TWD)',
    value: 'TWD'
  },
  {
    label: 'Tanzanian Shilling (TZS)',
    value: 'TZS'
  },
  {
    label: 'Hryvnia (UAH)',
    value: 'UAH'
  },
  {
    label: 'Uganda Shilling (UGX)',
    value: 'UGX'
  },
  {
    label: 'US Dollar (USD)',
    value: 'USD'
  },
  {
    label: 'Peso Uruguayo (UYU)',
    value: 'UYU'
  },
  {
    label: 'Uzbekistan Sum (UZS)',
    value: 'UZS'
  },
  {
    label: 'Bolivar (VEB)',
    value: 'VEB'
  },
  {
    label: 'Bolivar Soberano (VES)',
    value: 'VES'
  },
  {
    label: 'Dong (VND)',
    value: 'VND'
  },
  {
    label: 'Vatu (VUV)',
    value: 'VUV'
  },
  {
    label: 'Tala (WST)',
    value: 'WST'
  },
  {
    label: 'CFA Franc BEAC (XAF)',
    value: 'XAF'
  },
  {
    label: 'East Caribbean Dollar (XCD)',
    value: 'XCD'
  },
  {
    label: 'Special Drawing Right (SDR)',
    value: 'SDR'
  },
  {
    label: 'CFA France BCEAO (XOF)',
    value: 'XOF'
  },
  {
    label: 'CFP Franc (XPF)',
    value: 'XPF'
  },
  {
    label: 'Yemeni Rial (YER)',
    value: 'YER'
  },
  {
    label: 'Rand (ZAR)',
    value: 'ZAR'
  },
  {
    label: 'Zambian Kwacha (ZMW)',
    value: 'ZMW'
  },
  {
    label: 'Zimbabwe Dollar (ZWD)',
    value: 'ZWD'
  }
]
