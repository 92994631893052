import React, { useState, useEffect, useReducer } from 'react'
import { ErrorMessage, Formik, Form } from 'formik'
import Select from '../../formik-controls/select/Select'
import TextError from '../../formik-controls/TextError'
import { Resource, RouteParamTypes } from '../ResourceTypes'
import './UnnamedForm.css'
import { getCostCenterListOptions, getMarketScopes } from '../../utils/fetchHandler'
import { getCostRatesForGDSResource } from '../../utils/fetchHandler'
import { updateInputStyling } from '../../utils/updateStyling'
import * as Yup from 'yup'
import { useParams } from 'react-router-dom'
import { getCountryInformation } from '../ResourceTableComponents'

type Props = {
  toggleAddResourceModal: (control: boolean) => void
  //addResource: (isNamed: boolean, resource: Resource) => void
  addResource: (isNamed: boolean, resource: Resource, marketScope?: any, countris?: any, countryId?: any,marketScopeId?: any, availableMarketScopes?:any[],marketRate?:any) => void
}
const initialValues = {
  employee: 'Unnamed',
  source: '',
  area: '',
  region: '',
  serviceLine: '',
  subServiceLine: '',
  subServiceLineGDS: '',
  competency: ''
}

const initialState = {
  areaOptions: [],
  regionOptions: [],
  serviceLineOptions: [],
  subServiceLineOptions: [],
  competencyOptions: [],
  isGDSSourceSelected: false
}

const reducer = (state: any, payload: any) => {
  return { ...state, ...payload }
}

let optionState = {
  area: '',
  region: '',
  costCountry: '',
  serviceLine: '',
  subServiceLine: ''
}

const requiredValidationText = 'Please complete required field.'

const validationSchema = Yup.object({
  source: Yup.string().required(requiredValidationText),
  area: Yup.string().required(requiredValidationText),
  region: Yup.string().when('source', {
    is: 'onshore',
    then: Yup.string().required(requiredValidationText)
  }).when('source', {
    is: 'subcontractor',
    then: Yup.string().required(requiredValidationText)
  }),
  serviceLine: Yup.string().required(requiredValidationText),
  subServiceLine: Yup.string().when('source', {
    is: 'onshore',
    then: Yup.string().required(requiredValidationText)
  }).when('source', {
    is: 'subcontractor',
    then: Yup.string().required(requiredValidationText)
  }),
  subServiceLineGDS: Yup.string().when('source', {
    is: 'gdsOffshore',
    then: Yup.string().required(requiredValidationText)
  }),
  competency: Yup.string().when('source', {
    is: 'gdsOffshore',
    then: Yup.string().required(requiredValidationText)
  })
})

const UnnamedForm = (props: Props) => {
  let result: any
  let marketScopeData: any
  let availableCountyData: any
  let availableMarketScopes: any[]=[]
  let marketScopeId: any
  let countryId: any
  let marketRate: any
  let competencyId:any
  let targetCurrencyId:any
  let sectorId:any
  let applicationId:any
  let lookupRoleId:any
  const [state, setState] = useReducer(reducer, initialState)
  const [isGDSSourceSelected, setIsGDSSourceSelected] = useState(false)
  const { pricingPlanId } = useParams<RouteParamTypes>()

  useEffect(() => {
    ;(async () => {
      updateInputStyling()
      try {
        const _areaOptions = await getCostCenterListOptions('area')
        setState({ areaOptions: _areaOptions.map((area: string) => ({ value: area, label: area })) })
      } catch (error) {}
    })()
  }, [])

  const onchangeDropdown = async (control: string, query: string, selectedOption: any) => {
    let tempData = false
    try {
      let costCentreQuery = query + selectedOption.value
      if (control === 'area') {
        if (selectedOption.value === 'gdsOffshore') {
          tempData = false
          setIsGDSSourceSelected(false)
          setState({ isGDSSourceSelected: false })
        } else {
          tempData = true
          setIsGDSSourceSelected(true)
          setState({ isGDSSourceSelected: true })
        }
      }
      if (control !== 'area') {
        tempData = isGDSSourceSelected
      }

      if (control === 'region') {
        optionState.area = selectedOption.value
      } else if (control === 'serviceLine') {
        optionState.region = selectedOption.value
      } else if (control === 'serviceLineGDS') {
        optionState.area = selectedOption.value
      } else if (control === 'subServiceLine') {
        let optionArray = selectedOption.value.split('/')
        optionState.costCountry = optionArray[0]
        optionState.serviceLine = optionArray[1]
        costCentreQuery = query + '&costCountry=' + optionState.costCountry + '&serviceLine=' + optionState.serviceLine
      } else if (control === 'subServiceLineGDS') {
        optionState.serviceLine = selectedOption.value
      } else if (control === 'competency') {
        optionState.subServiceLine = selectedOption.value
      }

      if (!tempData && control === 'area') {
        const options = await getCostRatesForGDSResource('GetListOptions', 'CostCountry', 'gds', '', '', '')
        options.sort()
        setState({ [`${control}Options`]: options.map((option: string) => ({ value: option, label: option })) })
      } else if (tempData) {
        const options = await getCostCenterListOptions(control, costCentreQuery)
        options.sort()
        if (control === 'serviceLine' || control === 'subServiceLine') {
          options.sort((a: { value: string }, b: { value: string }) => a.value.localeCompare(b.value))
          options.sort((a: { key: string }, b: { key: string }) => a.key.localeCompare(b.key))
          setState({
            [`${control}Options`]: options.map((option: any) => ({
              value: option.key + '/' + option.value,
              label: option.key + '/' + option.value
            }))
          })
        }
        else {
          setState({ [`${control}Options`]: options.map((option: string) => ({ value: option, label: option })) })
        }
      } else if (!tempData && control !== 'area') {
        let gdscontrol = 'ResourceType'
        let gdsvalue = 'gds'
        if (control === 'serviceLineGDS') {
          gdscontrol = 'CostCountry'
          gdsvalue = selectedOption.value
          control = 'serviceLine'
        } else if (control === 'subServiceLineGDS') {
          gdscontrol = 'ServiceLine'
          gdsvalue = selectedOption.value
          control = 'subServiceLine'
        } else if (control === 'competency') {
          gdscontrol = 'SubServiceLine'
          gdsvalue = selectedOption.value
        }

        const options = await getCostRatesForGDSResource(
          'GetListOptions',
          control,
          'gds',
          optionState.area,
          optionState.serviceLine,
          optionState.subServiceLine
        )
        options.sort()
        setState({ [`${control}Options`]: options.map((option: string) => ({ value: option, label: option })) })
      }
    } catch (error) {}
  }

  const getMarketScopeValues = async(values:any) => {
    let marketScopeApiParams = {
      competencyName: values.source != 'gdsOffshore' ? values.subServiceLine.split('/')[1] : values.competency,
      pricingPlanId: pricingPlanId,
      countryName: values.source != 'gdsOffshore' ? values.serviceLine.split('/')[0] : values.area
    }

    let result:any
    try {
      result = await getMarketScopes(marketScopeApiParams)
    } catch (error) {}
  
    let marketScopes: any = result?.marketScopes
    marketScopes?.forEach((scope:any)=>{
      let availableMarketScope = {marketScopeValue: scope.marketScopeValue, marketScopeId: scope.marketScopeId}
      availableMarketScopes.push(availableMarketScope)
      if(scope.isDefault==true){
        marketScopeId = scope.marketScopeId
      }
    })
    if(marketScopes != undefined && marketScopes?.length != 0){
      const countryInformation = getCountryInformation(marketScopes)
      availableCountyData = countryInformation.availableCountries
      countryId = countryInformation.defaultCountry.countryId
    }
    return {result: result, availableCountyData: availableCountyData, countryId: countryId, 
      availableMarketScopes: availableMarketScopes, marketScopeId: marketScopeId, marketRate:0}
  }
  return (
    <div style={{ margin: '20px 0px 0px' }}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, actions) => {
          let data = getMarketScopeValues(values)
          props.addResource(false, values, (await data).result, (await data).availableCountyData, (await data).countryId, 
          (await data).marketScopeId, (await data).availableMarketScopes, (await data).marketRate)
          props.toggleAddResourceModal(false)
        }}
      >
        {(formik) => {
          return (
            <Form className='unnamed-form'>
              <div className='form-control'>
                <label className='input-label' htmlFor='source'>
                  Sources*
                </label>
                <br />
                <Select
                  name='source'
                  options={[
                    { value: 'onshore', label: 'EY Onshore' },
                    { value: 'gdsOffshore', label: 'GDS' },
                    { value: 'subcontractor', label: 'Subcontractor' }
                  ]}
                  onChange={(option) => {
                    // Resets all form fields (except the selected source field) 
                    formik.setFieldValue('area', '')
                    formik.setFieldValue('region', '')
                    formik.setFieldValue('serviceLine', '')
                    formik.setFieldValue('subServiceLine', '')
                    formik.setFieldValue('subServiceLineGDS', '')
                    formik.setFieldValue('competency', '')
                    formik.setTouched({})
                    formik.setErrors({})
                    onchangeDropdown('area', 'resource=', option)
                  }}
                />
                <ErrorMessage name='source' component={TextError} />
              </div>
              {isGDSSourceSelected ? (
                <div className='form-control'>
                  <label className='input-label' htmlFor='area'>
                    Area*
                  </label>
                  <br />
                  <Select
                    name='area'
                    options={state.areaOptions}
                    onChange={(option) => onchangeDropdown('region', 'area=', option)}
                  />
                  <ErrorMessage name='area' component={TextError} />
                </div>
              ) : (
                <div className='form-control'>
                  <label className='input-label' htmlFor='area'>
                    Cost Country*
                  </label>
                  <br />
                  <Select
                    name='area'
                    options={state.areaOptions}
                    onChange={(option) => onchangeDropdown('serviceLineGDS', 'area=', option)}
                  />
                  <ErrorMessage name='area' component={TextError} />
                </div>
              )}
              {isGDSSourceSelected && (
                <div className='form-control'>
                  <label className='input-label' htmlFor='region'>
                    Region*
                  </label>
                  <br />
                  <Select
                    name='region'
                    options={state.regionOptions}
                    onChange={(option) =>
                      onchangeDropdown('serviceLine', 'area=' + optionState.area + '&region=', option)
                    }
                  />
                  <ErrorMessage name='region' component={TextError} />
                </div>
              )}
              {isGDSSourceSelected ? (
                <div className='form-control'>
                  <label className='input-label' htmlFor='serviceLine'>
                    Location / Service Line*
                  </label>
                  <br />
                  <Select
                    name='serviceLine'
                    options={state.serviceLineOptions}
                    onChange={(option) =>
                      onchangeDropdown(
                        'subServiceLine',
                        'area=' + optionState.area + '&region=' + optionState.region,
                        option
                      )
                    }
                  />
                  <ErrorMessage name='serviceLine' component={TextError} />
                </div>
              ) : (
                <div className='form-control'>
                  <label className='input-label' htmlFor='serviceLine'>
                    Service Line*
                  </label>
                  <br />
                  <Select
                    name='serviceLine'
                    options={state.serviceLineOptions}
                    onChange={(option) =>
                      onchangeDropdown(
                        'subServiceLineGDS',
                        'area=' + optionState.area + '&region=' + optionState.region,
                        option
                      )
                    }
                  />
                  <ErrorMessage name='serviceLine' component={TextError} />
                </div>
              )}
              {isGDSSourceSelected ? (
                <div className='form-control'>
                  <label className='input-label' htmlFor='subServiceLine'>
                    Sub Service Line / Competency*
                  </label>
                  <br />
                  <Select name='subServiceLine' options={state.subServiceLineOptions} />
                  <ErrorMessage name='subServiceLine' component={TextError} />
                </div>
              ) : (
                <div className='form-control'>
                  <label className='input-label' htmlFor='subServiceLine'>
                    Sub Service Line*
                  </label>
                  <br />
                  <Select
                    name='subServiceLineGDS'
                    options={state.subServiceLineOptions}
                    onChange={(option) => onchangeDropdown('competency', '', option)}
                  />
                  <ErrorMessage name='subServiceLineGDS' component={TextError} />
                </div>
              )}
              {!isGDSSourceSelected && (
                <div className='form-control'>
                  <label className='input-label' htmlFor='competency'>
                    Competency*
                  </label>
                  <br />
                  <Select name='competency' options={state.competencyOptions} />
                  <ErrorMessage name='competency' component={TextError} />
                </div>
              )}
              <div className='footer-bottons-container'>
                <button
                  className='secondary-button'
                  type='button'
                  onClick={() => {
                    formik.resetForm()
                    props.toggleAddResourceModal(false)
                  }}
                >
                  <span className='heading-three'>Cancel</span>
                </button>
                <button
                  className='primary-button'
                  type='button'
                  onClick={() => {
                    formik.handleSubmit()
                  }}
                  disabled={!(formik.dirty && formik.isValid)}
                >
                  <span className='heading-three'>Confirm</span>
                </button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default UnnamedForm
