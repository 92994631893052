import './Base.css'

import EditableBox from './EditableBox'

interface BaseProps {
  contingency: number
  selectedColumn: number
  setSelectedColumn: Function
  isSelected: boolean
  formValues: any
  setFormValues: Function
  resourcesValues: any
  expensesValues: any
  planCurrency: string
  eyViewValues: any
}

const Base = (props: BaseProps) => {
  const {
    contingency,
    selectedColumn,
    setSelectedColumn,
    isSelected,
    formValues,
    setFormValues,
    resourcesValues,
    expensesValues,
    planCurrency,
    eyViewValues
  } = props

  return (
    <div className='base-container'>
      <div className='base-body-container'>
        <div className='base-categories'>
          <p className='body-text'>Total Billing Amount</p>
          <p className='body-text'>Labor Cost: EY Onshore</p>
          <p className='body-text'>Labor Cost: GDS</p>
          <p className='body-text'>Labor Cost: Subcontractors</p>
          <p className='body-text'>Expenses: Travel-Related</p>
          <p className='body-text'>Expenses: Other Technology</p>
          <p className='body-text'>Expenses: External Vendor / Subcontractor</p>
          <p className='body-text'>Expenses: Other</p>
        </div>
        {/* <EditableBox
          title={'Base'}
          contingency={contingency}
          columnNum={0}
          selectedColumn={selectedColumn}
          setSelectedColumn={setSelectedColumn}
          isSelected={isSelected}
          formValues={formValues}
          setFormValues={setFormValues}
          resourcesValues={resourcesValues}
          expensesValues={expensesValues}
          planCurrency={planCurrency}
          eyViewValues={eyViewValues}
        /> */}
        <EditableBox
          title={'Market Rate Card'}
          contingency={contingency}
          columnNum={0}
          selectedColumn={selectedColumn}
          setSelectedColumn={setSelectedColumn}
          isSelected={isSelected}
          formValues={formValues}
          setFormValues={setFormValues}
          resourcesValues={resourcesValues}
          expensesValues={expensesValues}
          planCurrency={planCurrency}
          eyViewValues={eyViewValues}
        />
        {/* <EditableBox
          title={'Target Margin %'}
          contingency={contingency}
          columnNum={0}
          selectedColumn={selectedColumn}
          setSelectedColumn={setSelectedColumn}
          isSelected={isSelected}
          formValues={formValues}
          setFormValues={setFormValues}
          resourcesValues={resourcesValues}
          expensesValues={expensesValues}
          planCurrency={planCurrency}
          eyViewValues={eyViewValues}
        /> */}
        <EditableBox
          title={'Target Margin %'}
          contingency={contingency}
          columnNum={1}
          selectedColumn={selectedColumn}
          setSelectedColumn={setSelectedColumn}
          isSelected={isSelected}
          formValues={formValues}
          setFormValues={setFormValues}
          resourcesValues={resourcesValues}
          expensesValues={expensesValues}
          planCurrency={planCurrency}
          eyViewValues={eyViewValues}
        />
        <EditableBox
          title={'Fixed Fee/Price'}
          contingency={contingency}
          columnNum={2}
          selectedColumn={selectedColumn}
          setSelectedColumn={setSelectedColumn}
          isSelected={isSelected}
          formValues={formValues}
          setFormValues={setFormValues}
          resourcesValues={resourcesValues}
          expensesValues={expensesValues}
          planCurrency={planCurrency}
          eyViewValues={eyViewValues}
        />
        <EditableBox
          title={'Client Rate Card'}
          contingency={contingency}
          columnNum={3}
          selectedColumn={selectedColumn}
          setSelectedColumn={setSelectedColumn}
          isSelected={isSelected}
          formValues={formValues}
          setFormValues={setFormValues}
          resourcesValues={resourcesValues}
          expensesValues={expensesValues}
          planCurrency={planCurrency}
          eyViewValues={eyViewValues}
        />

        {/* <EditableBox
          title={'Market Rate Card'}
          contingency={contingency}
          columnNum={3}
          selectedColumn={selectedColumn}
          setSelectedColumn={setSelectedColumn}
          isSelected={isSelected}
          formValues={formValues}
          setFormValues={setFormValues}
          resourcesValues={resourcesValues}
          expensesValues={expensesValues}
          planCurrency={planCurrency}
          eyViewValues={eyViewValues}
        /> */}
      </div>
      <div className='base-legend'>
        <span className='base-red-circle'></span>
        <p className='body-text'>Above Base Assumptions</p>
        <span className='base-green-circle'></span>
        <p className='body-text'>Below Base Assumptions</p>
      </div>
    </div>
  )
}

export default Base
