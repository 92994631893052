import { Switch, Route, Redirect } from 'react-router-dom'
import { AuthenticatedTemplate } from '@azure/msal-react'
import GlobalInputs from './components/global-inputs/GlobalInputsContainer'
import Landing from './components/landing/Landing'
import Expenses from './components/expenses/Expenses'
import Resources from './components/resources/ResourcesContainer'
import Scenarios from './components/scenarios/Scenarios'
import Summary from './components/summary/Summary'
import AdminDashboard from './components/admin-dashboard/AdminDashboard'
import { useEffect, useState } from 'react'

const Router = () => {
  const [isEnabled, setIsEnabled] = useState(sessionStorage.getItem('isEnabled') === 'true');
  
  useEffect(() => {
    const handleIsEnabledUpdate = () => {
      setIsEnabled(sessionStorage.getItem('isEnabled') === 'true');
    };
  
    // listen for custom event
    window.addEventListener('isEnabledUpdated', handleIsEnabledUpdate);
  
    // event listener clean
    return () => {
      window.removeEventListener('isEnabledUpdated', handleIsEnabledUpdate);
    };
  }, []);
  
  return (
    <Switch>
      <Route exact path='/pricing-plan/:pricingPlanId/global-input'>
        {window.location.href.includes('react-cct-test-ky.azurewebsites.net') && <GlobalInputs />}
        {isEnabled ? (
          <AuthenticatedTemplate>
          <GlobalInputs />
          </AuthenticatedTemplate>
                  ) : (
          <Redirect to="/" /> // redirect to landing if isenabled is false
                  )}
      </Route>
      <Route exact path='/pricing-plan/:pricingPlanId/resources'>
        {window.location.href.includes('react-cct-test-ky.azurewebsites.net') && <Resources />}
        {/* <AuthenticatedTemplate>
          <Resources />
        </AuthenticatedTemplate> */}
        {isEnabled ? (
          <AuthenticatedTemplate>
          <Resources />
          </AuthenticatedTemplate>
                  ) : (
          <Redirect to="/" /> // redirect to resource if isenabled is false
                  )}
      </Route>
      <Route path='/pricing-plan/:pricingPlanId/expenses'>
        {window.location.href.includes('react-cct-test-ky.azurewebsites.net') && <Expenses />}
        {/* <AuthenticatedTemplate>
          <Expenses />
        </AuthenticatedTemplate> */}
        {isEnabled ? (
          <AuthenticatedTemplate>
          <Expenses />
          </AuthenticatedTemplate>
                  ) : (
          <Redirect to="/" /> // redirect to expense if isenabled is false
                  )}
      </Route>
      <Route path='/pricing-plan/:pricingPlanId/scenarios'>
        {window.location.href.includes('react-cct-test-ky.azurewebsites.net') && <Scenarios />}
        {/* <AuthenticatedTemplate>
          <Scenarios />
        </AuthenticatedTemplate> */}
        {isEnabled ? (
          <AuthenticatedTemplate>
          <Scenarios />
          </AuthenticatedTemplate>
                  ) : (
          <Redirect to="/" /> // redirect to scenario if isenabled is false
                  )}
      </Route>
      <Route path='/pricing-plan/:pricingPlanId/summary'>
        {window.location.href.includes('react-cct-test-ky.azurewebsites.net') && <Summary />}
        {/* <AuthenticatedTemplate>
          <Summary />
        </AuthenticatedTemplate> */}
        {isEnabled ? (
          <AuthenticatedTemplate>
          <Summary />
          </AuthenticatedTemplate>
                  ) : (
          <Redirect to="/" /> // redirect to summary if isenabled is false
                  )}
      </Route>
      <Route path='/admin-dashboard'>
        {window.location.href.includes('react-cct-test-ky.azurewebsites.net') && <AdminDashboard />}
        {/* <AuthenticatedTemplate>
          <AdminDashboard />
        </AuthenticatedTemplate> */}
        {isEnabled ? (
          <AuthenticatedTemplate>
          <AdminDashboard />
          </AuthenticatedTemplate>
                  ) : (
          <Redirect to="/" /> // redirect to admin if isenabled is false
                  )}
      </Route>
      <Route path='/'>
        {window.location.href.includes('react-cct-test-ky.azurewebsites.net') && <Landing />}
        <AuthenticatedTemplate>
          <Landing />
        </AuthenticatedTemplate>
      </Route>
    </Switch>
  )
}

export default Router
