import { Configuration, RedirectRequest } from '@azure/msal-browser'

// MSAL configuration for localhost
export const msalConfigLocal: Configuration = {
  auth: {
    clientId: 'e7eeebef-4428-42bf-803e-bacd7373d3dc',
    authority: 'https://login.microsoftonline.com/5b973f99-77df-4beb-b27d-aa0c70b8482c',
    postLogoutRedirectUri: 'http://localhost:3000/'
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false // Set this to "true" if having issues on IE11 or Edge
  }
}

// MSAL configuration for UAT
export const msalConfigUAT: Configuration = {
  auth: {
    clientId: 'e7eeebef-4428-42bf-803e-bacd7373d3dc',
    authority: 'https://login.microsoftonline.com/5b973f99-77df-4beb-b27d-aa0c70b8482c',
    postLogoutRedirectUri: 'https://cct-qa.ey.com/'
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false // Set this to "true" if having issues on IE11 or Edge
  }
}

// MSAL configuration for Prod
export const msalConfigProd: Configuration = {
  auth: {
    clientId: '5fc1e9a3-7397-4ac0-98d4-d5e1183773fb',
    authority: 'https://login.microsoftonline.com/5b973f99-77df-4beb-b27d-aa0c70b8482c',
    postLogoutRedirectUri: 'https://cct.ey.com/'
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false // Set this to "true" if having issues on IE11 or Edge
  }
}

export const loginRequest: RedirectRequest = {
  scopes: ['User.Read']
}

export const accessTokenScope = 'e7eeebef-4428-42bf-803e-bacd7373d3dc/.default'
export const accessTokenScopeProd = '5fc1e9a3-7397-4ac0-98d4-d5e1183773fb/.default'
