import React, { createContext, ReactElement, useState } from 'react'
import { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import ScrollToTop from './components/ScrollToTop'
import Router from './Router'
import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { accessTokenScope, accessTokenScopeProd, loginRequest } from './authConfig'
import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import ReactTimer from './IdleTimerContainer'

export const UserContext = createContext(['John Smith', 'd16f08bd-c1ce-4621-8a0a-66d97bf95ad0', ''])

function App(): ReactElement {
  const [username, setUsername] = useState<string>('John Smith')
  const [userId, setUserId] = useState<string>('0')
  const [userGroup, setUserGroup] = useState([''])

  const { instance, inProgress, accounts } = useMsal() // useMsal can't be used outside of a MsalProvider
  const isAuthenticated = useIsAuthenticated()

  const cache = createCache({
    key: 'jss-class-prefix-key',
    nonce: 'gAv38FIo14S9Z5ftS2h9jA=='
  })

  // Get tokens for BE APIs
  useEffect(() => {
    // Trigger initial log in request on page load
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      instance.loginRedirect(loginRequest).catch((e: any) => {
        console.error(e)
      })
    }

    let accessTokenRequest = {
      scopes: [accessTokenScope],
      account: accounts[0]
    }

    if (window.location.href.includes('cct.ey.com')) {
      accessTokenRequest = {
        scopes: [accessTokenScopeProd],
        account: accounts[0]
      }
    }

    if (inProgress === InteractionStatus.None) {
      // Get token
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse: any) => {
          localStorage.setItem('CCT_Access_Token', accessTokenResponse.accessToken)
          setUserId(accessTokenResponse.account.idTokenClaims.oid)
          sessionStorage.setItem('userId', accessTokenResponse.account.idTokenClaims.oid);
          setUsername(accessTokenResponse.account.name) // User name to be used in the header
          setUserGroup(accessTokenResponse.account.idTokenClaims.groups)
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(accessTokenRequest)
          }
          console.log(error)
        })
    }
  }, [instance, accounts, inProgress, isAuthenticated])

  return (
    <CacheProvider value={cache}>
      <div>
        <UserContext.Provider value={[username, userId].concat(userGroup)}>
          <BrowserRouter>
            <ScrollToTop />
            <Router />
          </BrowserRouter>
        </UserContext.Provider>
        <ReactTimer></ReactTimer>
      </div>
    </CacheProvider>
  )
}

export default App
