import React from 'react'
import ey_logo from '../../assets/ey_logo_black.png'
import loaderGif from '../../assets/LoaderGif.gif'
import './Loading.css'

const Loading = () => {
  return (
    <div className='loading-container'>
      <div className='loading-inner'>
        <img src={ey_logo} className='eyLogo' alt='EY Logo' />
        <img src={loaderGif} alt='Page Loading...' />
      </div>
    </div>
  )
}

export default Loading
