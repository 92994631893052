import { useEffect, useState } from 'react'
import './Detailed-Scenario.css'
import _ from 'lodash'
import { numberWithCommas } from './../../utils/helperFunctions'
import moment from 'moment'

interface DetailedResourcesProps {
  selectedColumn: any
  formValues: any
  inputResources: any
  planCurrency: string
  clientViewValues: any
}

const DetailedScenario = (props: DetailedResourcesProps) => {
  const { selectedColumn, formValues, inputResources, planCurrency, clientViewValues } = props
  const [scenarioName, setScenarioName] = useState<string>('')
  // const scenarios = ['base', 'targetMargin', 'fixedFee', 'client','marketRate']
  //const scenarios = ['targetMargin', 'fixedFee', 'client', 'marketRate']
  const scenarios = ['marketRate', 'targetMargin', 'fixedFee', 'client']

  useEffect(() => {
    setResourcesValues(initialValues)
  }, [])

  useEffect(() => {
    setScenarioName(selectedColumn)
  }, [selectedColumn])

  useEffect(() => {
    if (scenarioName !== '' && inputResources !== undefined) {
      calculateLTCost()
      calculateLaborCost()
    }
  }, [inputResources, scenarioName])

  // Run calculations when a new scenario is selected (needed for when a scenario is selected without having made changes to the inputs)
  // Run calculations when inputs are adjusted
  useEffect(() => {
    if (scenarioName !== '' && inputResources !== undefined) {
      //calculateLTCost()
      //calculateLaborCost()
      calculateTechUpliftCost()
      calculateContingency()
      calculateTotalFees()
      calculateLTMargin()
      calculateSTMargin()
      calculateMarginPercent()
      calculateMarginPercentExclCont()
      calculateGDS()
      calculateTotalResources()
      calculateTotalFullyLoadedGDSCost()
    }
  }, [
    scenarioName,
    clientViewValues.servicesFees,
    formValues.fixedFee.totalBilling,
    formValues.technologyCost,
    formValues.base.contingency,
    formValues.targetMargin.contingency,
    formValues.fixedFee.contingency,
    formValues.client.contingency,
    formValues.targetMargin.margin,
    formValues.marketRate.contingency,
    formValues.fy1InflationAdj,
    formValues.fy2InflationAdj,
    formValues.fy3InflationAdj,
    formValues.fy4InflationAdj,
    inputResources
  ])

  // ! THE NON-ZERO VALUES BELOW ARE HARDCODED
  const resourcesBoxValues = {
    eyOnShore: {
      ltCost: 0,
      recoverable: 0,
      laborCost: 0,
      TechUpliftCost: 0,
      contingency: 0,
      ltMargin: 0,
      stMargin: 0,
      marginPercent: 0,
      marginexclcont: 0,
      totalFees: 0
    },
    gdsOffshore: {
      ltCost: 0,
      recoverable: 0,
      laborCost: 0,
      TechUpliftCost: 0,
      contingency: 0,
      ltMargin: 0,
      stMargin: 0,
      marginPercent: 0,
      marginexclcont: 0,
      totalFees: 0
    },
    gdsLanded: {
      ltCost: 0,
      recoverable: 0,
      laborCost: 0,
      TechUpliftCost: 0,
      contingency: 0,
      ltMargin: 0,
      stMargin: 0,
      marginPercent: 0,
      marginexclcont: 0,
      totalFees: 0
    },
    subcontractors: {
      ltCost: 0,
      recoverable: 0,
      laborCost: 0,
      TechUpliftCost: 0,
      contingency: 0,
      ltMargin: 0,
      stMargin: 0,
      marginPercent: 0,
      marginexclcont: 0,
      totalFees: 0
    },
    gds: {
      ltCost: 0,
      recoverable: 0,
      laborCost: 0,
      TechUpliftCost: 0,
      contingency: 0,
      ltMargin: 0,
      stMargin: 0,
      marginPercent: 0,
      marginexclcont: 0,
      totalFees: 0
    },
    totalResources: {
      ltCost: 0,
      recoverable: 0,
      laborCost: 0,
      TechUpliftCost: 0,
      contingency: 0,
      ltMargin: 0,
      stMargin: 0,
      marginPercent: 0,
      marginexclcont: 0,
      totalFees: 0
    },
    totalFullyLoadedGDSCost: {
      ltCost: 0,
      recoverable: 0,
      laborCost: 0,
      TechUpliftCost: 0,
      contingency: 0,
      ltMargin: 0,
      stMargin: 0,
      marginPercent: 0,
      marginexclcont: 0,
      totalFees: 0
    }
  }

  // Copies by value rather than by reference
  const resourcesBoxValues2 = _.cloneDeep(resourcesBoxValues)
  const resourcesBoxValues3 = _.cloneDeep(resourcesBoxValues)
  const resourcesBoxValues4 = _.cloneDeep(resourcesBoxValues)
  const resourcesBoxValues5 = _.cloneDeep(resourcesBoxValues)

  const initialValues = {
    base: resourcesBoxValues,
    targetMargin: resourcesBoxValues2,
    fixedFee: resourcesBoxValues3,
    client: resourcesBoxValues4,
    marketRate: resourcesBoxValues5
  }

  const expensesRows = {
    ltCost: 0,
    recoverable: 0,
    stCost: 0,
    contingency: 0,
    ltMargin: 0,
    stMargin: 0,
    totalExpenseBillings: 0
  }

  const expensesBoxValues = {
    mobility: expensesRows,
    mobilityEYOnshore: _.cloneDeep(expensesRows),
    mobilityGDSLanded: _.cloneDeep(expensesRows),
    technologyOtherProducts: _.cloneDeep(expensesRows),
    subcontractors: _.cloneDeep(expensesRows),
    otherLumpSums: _.cloneDeep(expensesRows),
    totalExpenses: _.cloneDeep(expensesRows)
  }

  // Copies by value rather than by reference
  const expensesBoxValues2 = _.cloneDeep(expensesBoxValues)
  const expensesBoxValues3 = _.cloneDeep(expensesBoxValues)
  const expensesBoxValues4 = _.cloneDeep(expensesBoxValues)
  const expensesBoxValues5 = _.cloneDeep(expensesBoxValues)

  const initialExpensesValues = {
    base: expensesBoxValues,
    targetMargin: expensesBoxValues2,
    fixedFee: expensesBoxValues3,
    client: expensesBoxValues4,
    marketRate: expensesBoxValues5
  }

  const [resourcesValues, setResourcesValues] = useState<any>({
    base: {},
    targetMargin: {},
    fixedFee: {},
    client: {},
    marketRate: {}
  })
  // Not shown in the UI but used in calculations
  // LT Cost = Recoverable
  // ! Ignoring the Direct Costs addition (original formula has an additional + Sum of on shore (or other source) direct costs term)
  const calculateLTCost = () => {
    // Formula: Sum of dedicated cost rate of each On Shore resource (total labor cost column)
    const rows = ['eyOnShore', 'gdsOffshore', 'gdsLanded', 'subcontractors']
    let desiredCost = 'totalStandardCost' // Use totalStandardCost everywhere except for direct DCR % calculations
    // Iterate through the scenarios
    for (let i = 0; i < 4; i++) {
      if (scenarios[i] === 'client') {
        // Use the specified client rate instead of the dedicated cost when the Client Rate Card is selected
        desiredCost = 'totalClientRate'
      }
      if (scenarios[i] === 'marketRate') {
        desiredCost = 'totalMarketRate'
      }

      // Iterate through the rows
      for (let j = 0; j < 4; j++) {
        let newLTCost = 0
        if (rows[j] === 'eyOnShore') {
          // Loop through each resource and check if it's of the desired source
          for (let k = 0; k < inputResources.length; k++) {
            if (inputResources[k].source === 'onshore' || inputResources[k].employee.trim() !== 'Unnamed') {
              // Check if the named resource has rank CSC or SUB (then they are a subcontractor and no longer counted as an onshore resource)
              if (
                inputResources[j].rank !== 'CSC' &&
                inputResources[j].rank !== 'SUB' &&
                inputResources[j].rank !== 'Subcontractor' &&
                inputResources[j].rank !== 'Client Serving Contractor'
              ) {
                // If desired type: add the total dedicated cost field
                newLTCost += inputResources[k][desiredCost]
                if (inputResources[k].promotedResource) {
                  newLTCost += inputResources[k].promotedResource[desiredCost]
                }
              }
            }
          }
        } else if (rows[j] === 'gdsOffshore') {
          for (let k = 0; k < inputResources.length; k++) {
            if (inputResources[k].source === 'gdsOffshore') {
              newLTCost += inputResources[k][desiredCost]
              if (inputResources[k].promotedResource) {
                newLTCost += inputResources[k].promotedResource[desiredCost]
              }
            }
          }
        } else if (rows[j] === 'gdsLanded') {
          for (let k = 0; k < inputResources.length; k++) {
            if (inputResources[k].source === 'gdsLanded') {
              newLTCost += inputResources[k][desiredCost]
              if (inputResources[k].promotedResource) {
                newLTCost += inputResources[k].promotedResource[desiredCost]
              }
            }
          }
        } else {
          for (let k = 0; k < inputResources.length; k++) {
            // Subcontractors can be named (have rank CSC or SUB) or unnamed
            if (
              inputResources[k].source === 'subcontractor' ||
              inputResources[k].rank === 'CSC' ||
              inputResources[k].rank === 'SUB' ||
              inputResources[k].rank === 'Subcontractor' ||
              inputResources[k].rank === 'Client Serving Contractor'
            ) {
              newLTCost += inputResources[k][desiredCost]
              if (inputResources[k].promotedResource) {
                newLTCost += inputResources[k].promotedResource[desiredCost]
              }
            }
          }
        }

        // TODO: Formula not known yet (29/03) - setting values to 0 (verified with Khan)
        newLTCost = 0
        resourcesValues[scenarios[i]][rows[j]].ltCost = newLTCost
        resourcesValues[scenarios[i]][rows[j]].recoverable = newLTCost

        setResourcesValues((prevResourcesValues: any) => ({
          ...prevResourcesValues,
          [scenarios[i]]: {
            ...prevResourcesValues[scenarios[i]],
            [rows[j]]: {
              ...prevResourcesValues[scenarios[i]][rows[j]],
              ltCost: newLTCost,
              recoverable: newLTCost
            }
          }
        }))
      }
    }
  }

  // Corresponds to the Labor Cost column - use .totalStandardCost with same procedure as LT Cost
  // ST Cost in the formulas
  // ! Ignoring the Direct Costs addition (original formula has an additional + Sum of on shore (or other source) direct costs term)
  const calculateLaborCost = () => {
    // Formula: Sum of standard cost rate of each onshore/GDS/subcontractor resource
    const rows = ['eyOnShore', 'gdsOffshore', 'gdsLanded', 'subcontractors']
    let desiredCost = 'totalStandardCost'
    let promotedcost = 'totalCost'
    // Iterate through the scenarios
    for (let i = 0; i < 4; i++) {
      // Iterate through the rows
      for (let j = 0; j < 4; j++) {
        let newLaborCost = 0
        if (rows[j] === 'eyOnShore') {
          // Loop through each resource and check if it's of the desired source
          for (let k = 0; k < inputResources.length; k++) {
            if (inputResources[k].source === 'onshore' || inputResources[k].employee.trim() !== 'Unnamed') {
              // Check if the named resource has rank CSC or SUB (then they are a subcontractor and no longer counted as an onshore resource)
              if (
                inputResources[k].rank !== 'CSC' &&
                inputResources[k].rank !== 'SUB' &&
                inputResources[k].rank !== 'Subcontractor' &&
                inputResources[k].rank !== 'Client Serving Contractor'
              ) {
                // If desired type: add the total standard cost field
                newLaborCost += inputResources[k][desiredCost]
                if (inputResources[k].promotedResource) {
                  // Account for promotions
                  newLaborCost += inputResources[k].promotedResource[promotedcost]
                }
              }
            }
          }
        } else if (rows[j] === 'gdsOffshore') {
          for (let k = 0; k < inputResources.length; k++) {
            if (inputResources[k].source === 'gdsOffshore') {
              newLaborCost += inputResources[k][desiredCost]
              if (inputResources[k].promotedResource) {
                newLaborCost += inputResources[k].promotedResource[promotedcost]
              }
            }
          }
        } else if (rows[j] === 'gdsLanded') {
          for (let k = 0; k < inputResources.length; k++) {
            if (inputResources[k].source === 'gdsLanded') {
              newLaborCost += inputResources[k][desiredCost]
              if (inputResources[k].promotedResource) {
                newLaborCost += inputResources[k].promotedResource[promotedcost]
              }
            }
          }
        } else {
          for (let k = 0; k < inputResources.length; k++) {
            if (
              inputResources[k].source === 'subcontractor' ||
              inputResources[k].rank === 'CSC' ||
              inputResources[k].rank === 'SUB' ||
              inputResources[k].rank === 'Subcontractor' ||
              inputResources[k].rank === 'Client Serving Contractor'
            ) {
              newLaborCost += inputResources[k][desiredCost]
              if (inputResources[k].promotedResource) {
                newLaborCost += inputResources[k].promotedResource[promotedcost]
              }
            }
          }
        }

        resourcesValues[scenarios[i]][rows[j]].laborCost = newLaborCost

        setResourcesValues((prevResourcesValues: any) => ({
          ...prevResourcesValues,
          [scenarios[i]]: {
            ...prevResourcesValues[scenarios[i]],
            [rows[j]]: {
              ...prevResourcesValues[scenarios[i]][rows[j]],
              laborCost: newLaborCost
            }
          }
        }))
      }
    }
  }
  const calculateTechUpliftCost = () => {
    const rows = ['eyOnShore', 'gdsOffshore', 'gdsLanded', 'subcontractors']
    for (let i = 0; i < 4; i++) {
      // Iterate through all scenarios
      for (let j = 0; j < 4; j++) {
        // Iterate through all rows
        const laborCost = resourcesValues[scenarios[i]][rows[j]].laborCost
        const technologyCost = formValues.technologyCost / 100

        const newValue = Math.ceil(laborCost * technologyCost)
        resourcesValues[scenarios[i]][rows[j]].TechUpliftCost = newValue

        setResourcesValues((prevResourcesValues: any) => ({
          ...prevResourcesValues,
          [scenarios[i]]: {
            ...prevResourcesValues[scenarios[i]],
            [rows[j]]: {
              ...prevResourcesValues[scenarios[i]][rows[j]],
              TechUpliftCost: newValue
            }
          }
        }))
      }
    }
  }

  const calculateContingency = () => {
    const rows = ['eyOnShore', 'gdsOffshore', 'gdsLanded', 'subcontractors']
    for (let i = 0; i < 4; i++) {
      // Iterate through all scenarios
      for (let j = 0; j < 4; j++) {
        // Iterate through all rows
        const laborCost = resourcesValues[scenarios[i]][rows[j]].laborCost
        const contingencyInput = formValues[scenarios[i]].contingency[rows[j]] / 100

        const newValue = Math.ceil(laborCost * contingencyInput)
        resourcesValues[scenarios[i]][rows[j]].contingency = newValue

        setResourcesValues((prevResourcesValues: any) => ({
          ...prevResourcesValues,
          [scenarios[i]]: {
            ...prevResourcesValues[scenarios[i]],
            [rows[j]]: {
              ...prevResourcesValues[scenarios[i]][rows[j]],
              contingency: newValue
            }
          }
        }))
      }
    }
  }

  const calculateLTMargin = () => {
    const rows = ['eyOnShore', 'gdsOffshore', 'gdsLanded', 'subcontractors']
    for (let i = 0; i < 4; i++) {
      // Iterate through all scenarios
      for (let j = 0; j < 4; j++) {
        // Iterate through all rows
        const ltCost = resourcesValues[scenarios[i]][rows[j]].ltCost
        const contingency = resourcesValues[scenarios[i]][rows[j]].contingency
        const totalFees = resourcesValues[scenarios[i]][rows[j]].totalFees

        const newValue = Math.ceil(totalFees - ltCost - contingency)
        resourcesValues[scenarios[i]][rows[j]].ltMargin = newValue

        setResourcesValues((prevResourcesValues: any) => ({
          ...prevResourcesValues,
          [scenarios[i]]: {
            ...prevResourcesValues[scenarios[i]],
            [rows[j]]: {
              ...prevResourcesValues[scenarios[i]][rows[j]],
              ltMargin: newValue
            }
          }
        }))
      }
    }
  }

  // Equivalent to Margin column
  const calculateSTMargin = () => {
    const rows = ['eyOnShore', 'gdsOffshore', 'gdsLanded', 'subcontractors']
    for (let i = 0; i < 4; i++) {
      // Iterate through all scenarios
      for (let j = 0; j < 4; j++) {
        // Iterate through all rows
        let newValue = 0

        if (scenarios[i] === 'targetMargin') {
          const totalFees = resourcesValues[scenarios[i]][rows[j]].totalFees
          const marginInput = formValues[scenarios[i]].margin[rows[j]] / 100
          newValue = Math.ceil(totalFees * marginInput)
        } else if (scenarios[i] === 'client' || scenarios[i] === 'marketRate') {
          const totalFees = resourcesValues[scenarios[i]][rows[j]].totalFees
          const laborCost = resourcesValues[scenarios[i]][rows[j]].laborCost
          const TechUpliftCost = resourcesValues[scenarios[i]][rows[j]].TechUpliftCost
          const contingency = resourcesValues[scenarios[i]][rows[j]].contingency
          newValue = Math.ceil(totalFees - (laborCost + TechUpliftCost) - contingency)
        } else if (scenarios[i] === 'fixedFee') {
          // TODO: Formula not known yet (29/03) - setting values to 0 (verified with Khan)
          newValue = 0
        }
        // else if (scenarios[i] === 'marketRate') {
        //   const totalFees = resourcesValues[scenarios[i]][rows[j]].totalFees
        //   const laborCost = resourcesValues[scenarios[i]][rows[j]].laborCost
        //   const TechUpliftCost = resourcesValues[scenarios[i]][rows[j]].TechUpliftCost
        //   const contingency = resourcesValues[scenarios[i]][rows[j]].contingency
        //   newValue = Math.ceil(totalFees - (laborCost + TechUpliftCost) - contingency)
        // }

        resourcesValues[scenarios[i]][rows[j]].stMargin = newValue

        setResourcesValues((prevResourcesValues: any) => ({
          ...prevResourcesValues,
          [scenarios[i]]: {
            ...prevResourcesValues[scenarios[i]],
            [rows[j]]: {
              ...prevResourcesValues[scenarios[i]][rows[j]],
              stMargin: newValue
            }
          }
        }))
      }
    }
  }

  // Found in the Scenarios Assumptions boxes
  // Note: GDS should be one row (i.e. have the same value regardless of landed or offshore)
  const calculateMarginPercent = () => {
    const rows = ['eyOnShore', 'gdsOffshore', 'gdsLanded', 'subcontractors']
    for (let i = 0; i < 4; i++) {
      for (let j = 0; j < 4; j++) {
        let newValue = 0
        if (scenarios[i] === 'client' || scenarios[i] === 'marketRate') {
          const margin = resourcesValues[scenarios[i]][rows[j]].stMargin
          const totalFees = resourcesValues[scenarios[i]][rows[j]].totalFees
          //newValue = Math.ceil((100 * margin) / totalFees)
          newValue = (100 * margin) / totalFees
        } else {
          newValue = formValues[scenarios[i]].margin[rows[j]] === '' ? 0 : formValues[scenarios[i]].margin[rows[j]]
        }

        if (isNaN(newValue) || !Number.isFinite(newValue)) {
          newValue = 0
        }

        resourcesValues[scenarios[i]][rows[j]].marginPercent = newValue

        setResourcesValues((prevResourcesValues: any) => ({
          ...prevResourcesValues,
          [scenarios[i]]: {
            ...prevResourcesValues[scenarios[i]],
            [rows[j]]: {
              ...prevResourcesValues[scenarios[i]][rows[j]],
              marginPercent: newValue
            }
          }
        }))

        if (rows[j] === 'gdsOffshore') {
          if (scenarios[i] === 'client' || scenarios[i] === 'marketRate') {
            const margin = resourcesValues[scenarios[i]][rows[j]].stMargin
            const totalFees = resourcesValues[scenarios[i]][rows[j]].totalFees
            //const newValue = Math.ceil((100 * margin) / totalFees)
            const newValue = ((100 * margin) / totalFees).toFixed(1)

            resourcesValues[scenarios[i]].gds.marginPercent = newValue
          } else {
            newValue = formValues[scenarios[i]].margin[rows[j]] === '' ? 0 : formValues[scenarios[i]].margin[rows[j]]
          }

          setResourcesValues((prevResourcesValues: any) => ({
            ...prevResourcesValues,
            [scenarios[i]]: {
              ...prevResourcesValues[scenarios[i]],
              gds: {
                ...prevResourcesValues[scenarios[i]].gds,
                marginPercent: newValue
              }
            }
          }))
        }
      }
    }
  }
  const calculateMarginPercentExclCont = () => {
    const rows = ['eyOnShore', 'gdsOffshore', 'gdsLanded', 'subcontractors']
    for (let i = 0; i < 4; i++) {
      for (let j = 0; j < 4; j++) {
        let newValue = 0
        // if (scenarios[i] === 'client' || scenarios[i] === 'targetMargin' || scenarios[i] === 'fixedFee') {
        const totalFees = resourcesValues[scenarios[i]][rows[j]].totalFees
        const laborCost = resourcesValues[scenarios[i]][rows[j]].laborCost
        const TechUpliftCost = resourcesValues[scenarios[i]][rows[j]].TechUpliftCost
        newValue = (100 * (totalFees - (laborCost + TechUpliftCost))) / totalFees
        // }

        if (isNaN(newValue) || !Number.isFinite(newValue)) {
          newValue = 0
        }
        resourcesValues[scenarios[i]][rows[j]].marginexclcont = newValue

        setResourcesValues((prevResourcesValues: any) => ({
          ...prevResourcesValues,
          [scenarios[i]]: {
            ...prevResourcesValues[scenarios[i]],
            [rows[j]]: {
              ...prevResourcesValues[scenarios[i]][rows[j]],
              marginexclcont: newValue
            }
          }
        }))
        if (rows[j] === 'gdsOffshore') {
          // if (scenarios[i] === 'client') {
          let newValue = 0
          const totalFees = resourcesValues[scenarios[i]][rows[j]].totalFees
          const laborCost = resourcesValues[scenarios[i]][rows[j]].laborCost
          const TechUpliftCost = resourcesValues[scenarios[i]][rows[j]].TechUpliftCost
          newValue = (100 * (totalFees - (laborCost + TechUpliftCost))) / totalFees

          if (isNaN(newValue) || !Number.isFinite(newValue)) {
            newValue = 0
          }
          resourcesValues[scenarios[i]].gds.marginexclcont = newValue
          // }
          setResourcesValues((prevResourcesValues: any) => ({
            ...prevResourcesValues,
            [scenarios[i]]: {
              ...prevResourcesValues[scenarios[i]],
              gds: {
                ...prevResourcesValues[scenarios[i]].gds,
                marginexclcont: newValue
              }
            }
          }))
        }
      }
    }
  }
  const getRange = (start: number, end: number) => {
    return Array(end - start + 1)
      .fill(0)
      .map((_, idx) => start + idx)
  }
  const calculateTotalLabourCostDtls = (
    rowData: any,
    dealStartDate: Date | null,
    dealEndDate: Date | null,
    rate: number = 0,
    fy1InflationRate: any,
    fy2InflationRate: any,
    fy3InflationRate: any,
    fy4InflationRate: any
  ) => {
    let IsweeklyTimeunits = rowData.hasOwnProperty('weeklyTimeUnits')
    const inflation: any = []
    inflation.push(0) //no inflation for current fy
    inflation.push(fy1InflationRate)
    inflation.push(fy2InflationRate)
    inflation.push(fy3InflationRate)
    inflation.push(fy4InflationRate)

    let yearArrayDetails: any = []
    let currentDate = new Date()

    let currentYear = new Date().getFullYear()
    let nextFYYear = currentYear + 1
    let prevYear = currentYear - 1
    let currentFYStartDate = moment(`${currentYear}-07-01`).format('YYYY-MM-DDT00:00:00')
    let currentFYEndDate = moment(`${currentYear}-07-01`).format('YYYY-MM-DDT00:00:00')

    let startYear = moment(dealStartDate).year() > currentYear ? currentYear : moment(dealStartDate).year()
    let yearRange = getRange(startYear, moment(dealEndDate).year())

    for (var i = 0; i < yearRange.length; i++) {
      if (i === 0) {
        if (moment(currentDate).isSameOrAfter(`${currentYear}-07-01`)) {
          currentFYStartDate = moment(`${currentYear}-07-01`).format('YYYY-MM-DDT00:00:00')
          currentFYEndDate = moment(`${nextFYYear}-06-30`).format('YYYY-MM-DDT00:00:00')
          yearArrayDetails = yearArrayDetails.concat(yearRange[i])
        } else {
          currentFYStartDate = moment(`${prevYear}-07-01`).format('YYYY-MM-DDT00:00:00')
          currentFYEndDate = moment(`${currentYear}-06-30`).format('YYYY-MM-DDT00:00:00')
          if (startYear >= currentYear) yearArrayDetails = yearArrayDetails.concat(yearRange[i])
          yearArrayDetails = yearArrayDetails.concat(yearRange[i])
        }
      } else yearArrayDetails = yearArrayDetails.concat(yearRange[i])
    }

    const yearsArray = yearArrayDetails //getRange(moment(dealStartDate).year(), moment(dealEndDate).year())
    const weeklyTimeUnitsPerYear = yearsArray?.reduce((result: any[], year: any, index: any) => {
      if (index === 0 && IsweeklyTimeunits) {
        /* result.push([
          ...rowData.weeklyTimeUnits.filter((item: any) => moment(item.startDate).isSameOrBefore(`${year}-06-30`))
        ]) */

        const resultINCurrentFY = [
          ...rowData.weeklyTimeUnits.filter((item: any) =>
            // moment(item.startDate).isSameOrAfter(currentFYStartDate) &&
            moment(item.startDate).isSameOrBefore(currentFYEndDate)
          )
        ]
        if (resultINCurrentFY.length > 0) {
          result.push(resultINCurrentFY)
        }
        if (resultINCurrentFY.length === 0) {
          result.push([])
        }
      }
      if (index > 0) {
        if (year > moment(currentFYStartDate).year()) {
          //year = moment(currentFYEndDate).year()
          let nextYear = parseInt(year) + 1
          let startRange = moment(`${nextYear - 1}-07-01`).format('YYYY-MM-DDT00:00:00')
          let endRange = moment(`${nextYear}-06-30`).format('YYYY-MM-DDT00:00:00')
          if (IsweeklyTimeunits && index > 0) {
            result.push([
              ...rowData.weeklyTimeUnits.filter(
                (item: any) =>
                  //moment(date).format('YYYY-MM-DDT00:00:00')
                  moment(item.startDate).isSameOrAfter(moment(startRange)) &&
                  moment(item.startDate).isSameOrBefore(moment(endRange))
              )
            ])
          }
        }
      }
      return result
    }, [])

    let a = rate
    let flag = false
    let temp = 0
    const totalLabourCost = weeklyTimeUnitsPerYear.reduce((result: number, item: any[], index: number) => {
      const hours = item.reduce((_result: number, _item: any) => {
        _result += _item.timeUnits
        return _result
      }, 0)
      const standardCost = hours * rate

      function transferDataCurrentFY(weeklyData: any, date: any) {
        // Create a deep copy of weeklyData
        const copiedWeeklyData = JSON.parse(JSON.stringify(weeklyData))
        const targetDate = new Date(date)

        for (let i = 1; i < copiedWeeklyData.length; i++) {
          const remainingItems = []
          const itemsToTransfer = []

          for (let j = 0; j < copiedWeeklyData[i].length; j++) {
            const item = copiedWeeklyData[i][j]
            if (new Date(item.startDate) < targetDate) {
              itemsToTransfer.push(item)
            } else {
              remainingItems.push(item)
            }
          }

          // Transfer items to the first index
          copiedWeeklyData[0].push(...itemsToTransfer)

          // Update the current index with the remaining items
          copiedWeeklyData[i] = remainingItems
        }

        return copiedWeeklyData
      }

      function transferDataNextFY(weeklyData: any, targetDate: any) {
        // Create a deep copy of weeklyData
        const copiedWeeklyData = JSON.parse(JSON.stringify(weeklyData))
        const date = new Date(targetDate)
        let shouldShift = false

        // Check if the target date falls within the week range of index 0
        for (const item of copiedWeeklyData[0]) {
          const startDate = new Date(item.startDate)
          const endDate = new Date(startDate)
          endDate.setDate(endDate.getDate() + 6) // Assuming each week is 7 days long
          if (date < startDate) {
            shouldShift = true
            break
          }
        }

        const remainingItems = []
        let itemsToTransfer = []

        if (!shouldShift) {
          if (copiedWeeklyData[0].length == 0) {
            for (let i = 1; i < copiedWeeklyData.length; i++) {
              const currentItems = copiedWeeklyData[i]
              copiedWeeklyData[i] = itemsToTransfer
              itemsToTransfer = currentItems.slice() // Create a new array for itemsToTransfer
            }

            // If there are remaining items after shifting, add them as a new index
            if (itemsToTransfer.length > 0) {
              copiedWeeklyData.push(itemsToTransfer)
            }
          }
          return copiedWeeklyData // No shift needed
        }

        // Separate items in index 0 based on the target date
        for (const item of copiedWeeklyData[0]) {
          if (new Date(item.startDate) > date) {
            itemsToTransfer.push(item)
          } else {
            remainingItems.push(item)
          }
        }

        // Update index 0 with the remaining items
        copiedWeeklyData[0] = remainingItems
        for (let i = 1; i < copiedWeeklyData.length; i++) {
          const currentItems = copiedWeeklyData[i]
          copiedWeeklyData[i] = itemsToTransfer
          itemsToTransfer = currentItems.slice() // Create a new array for itemsToTransfer
        }

        // If there are remaining items after shifting, add them as a new index
        if (itemsToTransfer.length > 0) {
          copiedWeeklyData.push(itemsToTransfer)
        }

        return copiedWeeklyData
      }

      function calculateTotalHoursAtIndex(weeklyData: any, ind: number) {
        if (ind < 0 || ind >= weeklyData.length || !Array.isArray(weeklyData[ind]) || weeklyData[ind].length === 0) {
          return 0
        }

        return weeklyData[ind].reduce((totalHours: number, item: any) => {
          return totalHours + item.timeUnits
        }, 0)
      }

      {
        let inflationRate = 0
        if (index <= 4) inflationRate = inflation[index] / 100
        else inflationRate = 0

        const standardCost = hours * rate
        let calInflationCost = index === 0 ? rate : a * (1 + inflationRate) // rowData.isGDS ? rate * Math.pow(1 + inflationRate, index):

        a = calInflationCost

        const inflationCost = inflationRate !== undefined ? hours * calInflationCost : 0 //rowData.isGDS ? hours * calInflationCost :

        const inflationCostRate = inflationCost - standardCost
        //if (rateType === 'totalClientRate') {
        //   result += standardCost
        // } else {
        result += standardCost + inflationCostRate
        // }
      }
      return result
    }, 0)
    return totalLabourCost
  }
  const getTotalClientRate = (source: string) => {
    let totalClientRate = 0
    for (let i = 0; i < inputResources.length; i++) {
      if (inputResources[i].source === source && source === 'onshore') {
        // TODO - the totalClientRate calculations from the Resources page don't match formula spreadsheet (higher than clientRateHour x totalTimeUnits)
        //totalClientRate += inputResources[i].totalClientRate
        if (
          formValues.fy1InflationAdj > 0 ||
          formValues.fy2InflationAdj > 0 ||
          formValues.fy3InflationAdj > 0 ||
          formValues.fy4InflationAdj > 0
        ) {
          const COLAClient = calculateTotalLabourCostDtls(
            inputResources[i],
            inputResources[i].startDate,
            inputResources[i].endDate,
            inputResources[i].clientRateHour,
            parseFloat(formValues.fy1InflationAdj),
            parseFloat(formValues.fy2InflationAdj),
            parseFloat(formValues.fy3InflationAdj),
            parseFloat(formValues.fy4InflationAdj)
          )
          totalClientRate += COLAClient
        } else totalClientRate += inputResources[i].totalClientRate
      } else if (inputResources[i].source === source && source !== 'onshore') {
        if (
          formValues.fy1InflationAdj > 0 ||
          formValues.fy2InflationAdj > 0 ||
          formValues.fy3InflationAdj > 0 ||
          formValues.fy4InflationAdj > 0
        ) {
          const COLAClientVal = calculateTotalLabourCostDtls(
            inputResources[i],
            inputResources[i].startDate,
            inputResources[i].endDate,
            inputResources[i].clientRateHour,
            parseFloat(formValues.fy1InflationAdj),
            parseFloat(formValues.fy2InflationAdj),
            parseFloat(formValues.fy3InflationAdj),
            parseFloat(formValues.fy4InflationAdj)
          )
          totalClientRate += COLAClientVal
        } else totalClientRate += inputResources[i].totalClientRate
      }
      if (inputResources[i].promotedResource && inputResources[i].source === source && source === 'onshore') {
        if (
          formValues.fy1InflationAdj > 0 ||
          formValues.fy2InflationAdj > 0 ||
          formValues.fy3InflationAdj > 0 ||
          formValues.fy4InflationAdj > 0
        ) {
          const COLAClientPromoted = calculateTotalLabourCostDtls(
            inputResources[i].promotedResource,
            inputResources[i].promotedResource.startDate,
            inputResources[i].promotedResource.endDate,
            inputResources[i].promotedResource.clientRateHour,
            parseFloat(formValues.fy1InflationAdj),
            parseFloat(formValues.fy2InflationAdj),
            parseFloat(formValues.fy3InflationAdj),
            parseFloat(formValues.fy4InflationAdj)
          )
          totalClientRate += COLAClientPromoted
        } else totalClientRate += inputResources[i].promotedResource.totalClientRate
      }
    }

    return Math.round(totalClientRate)
  }

  const getTotalMarketRate = (source: string) => {
    let totalMarketRate = 0
    for (let i = 0; i < inputResources.length; i++) {
      if (inputResources[i].source === source) {
        // TODO - the totalClientRate calculations from the Resources page don't match formula spreadsheet (higher than clientRateHour x totalTimeUnits)
        totalMarketRate += inputResources[i].totalMarketRate
        if (inputResources[i].promotedResource) {
          totalMarketRate += inputResources[i].promotedResource.totalMarketRate
        }
      }
    }
    return totalMarketRate
  }

  const calculateTotalFees = () => {
    const rows = ['eyOnShore', 'gdsOffshore', 'gdsLanded', 'subcontractors']
    for (let i = 0; i < 4; i++) {
      // Iterate through all scenarios
      for (let j = 0; j < 4; j++) {
        // Iterate through all rows
        let newValue = 0
        if (scenarios[i] === 'targetMargin') {
          const laborCost = resourcesValues[scenarios[i]][rows[j]].laborCost
          const TechUpliftCost = resourcesValues[scenarios[i]][rows[j]].TechUpliftCost
          const contingency = resourcesValues[scenarios[i]][rows[j]].contingency
          let marginInput = formValues[scenarios[i]].margin[rows[j]] / 100
          if (rows[j] === 'gdsLanded') {
            marginInput = formValues[scenarios[i]].margin.gdsOffshore / 100
          }

          newValue = Math.ceil((laborCost + contingency + TechUpliftCost) / (1 - marginInput))
        } else if (scenarios[i] === 'client') {
          for (let k = 0; k < inputResources.length; k++) {
            if (rows[j] === 'eyOnShore') {
              newValue = getTotalClientRate('onshore')
            } else if (rows[j] === 'gdsOffshore') {
              newValue = getTotalClientRate('gdsOffshore')
            } else if (rows[j] === 'subcontractors') {
              newValue = getTotalClientRate('subcontractor')
            }
          }
        } else if (scenarios[i] === 'marketRate') {
          for (let k = 0; k < inputResources.length; k++) {
            if (rows[j] === 'eyOnShore') {
              newValue = getTotalMarketRate('onshore')
            } else if (rows[j] === 'gdsOffshore') {
              newValue = getTotalMarketRate('gdsOffshore')
            } else if (rows[j] === 'subcontractors') {
              newValue = getTotalMarketRate('subcontractor')
            }
          }
        } else if (scenarios[i] === 'fixedFee') {
          // TODO: Fixed Fee formula not known yet (29/03) - setting values to 0 (verified with Khan)
          newValue = 0
        }

        resourcesValues[scenarios[i]][rows[j]].totalFees = newValue

        setResourcesValues((prevResourcesValues: any) => ({
          ...prevResourcesValues,
          [scenarios[i]]: {
            ...prevResourcesValues[scenarios[i]],
            [rows[j]]: {
              ...prevResourcesValues[scenarios[i]][rows[j]],
              totalFees: newValue
            }
          }
        }))
      }
    }
  }

  const calculateTotalResources = () => {
    const columns = [
      'ltCost',
      'recoverable',
      'laborCost',
      'TechUpliftCost',
      'contingency',
      'ltMargin',
      'totalFees',
      'stMargin',
      'marginPercent',
      'marginexclcont'
    ]

    for (let i = 0; i < 4; i++) {
      // Iterate through all scenarios
      for (let j = 0; j < 10; j++) {
        // Iterate through all columns
        let newValue = 0
        if (
          columns[j] !== 'totalFees' &&
          columns[j] !== 'stMargin' &&
          columns[j] !== 'marginPercent' &&
          columns[j] !== 'marginexclcont'
        ) {
          const eyOnShore = resourcesValues[scenarios[i]].eyOnShore[columns[j]]
          const gdsOffshore = resourcesValues[scenarios[i]].gdsOffshore[columns[j]]
          const gdsLanded = resourcesValues[scenarios[i]].gdsLanded[columns[j]]
          const subcontractors = resourcesValues[scenarios[i]].subcontractors[columns[j]]

          newValue = Math.ceil(eyOnShore + gdsOffshore + gdsLanded + subcontractors)
          resourcesValues[scenarios[i]].totalResources[columns[j]] = newValue
        }
        if (scenarios[i] === 'fixedFee' && columns[j] === 'totalFees') {
          const servicesFeeContingency = clientViewValues.servicesFees

          newValue = Math.ceil(servicesFeeContingency)
          resourcesValues[scenarios[i]].totalResources.totalFees = newValue
        } else if (scenarios[i] === 'fixedFee' && columns[j] === 'stMargin') {
          const totalFees = resourcesValues[scenarios[i]].totalResources.totalFees
          const laborCost = resourcesValues[scenarios[i]].totalResources.laborCost
          const contingency = resourcesValues[scenarios[i]].totalResources.contingency
          const techCost = resourcesValues[scenarios[i]].totalResources.TechUpliftCost
          newValue = Math.ceil(totalFees - (laborCost + techCost) - contingency)
          resourcesValues[scenarios[i]].totalResources.stMargin = newValue
        } else if (
          (scenarios[i] === 'targetMargin' || scenarios[i] === 'client' || scenarios[i] === 'marketRate') &&
          (columns[j] === 'totalFees' || columns[j] === 'stMargin')
        ) {
          const eyOnShore = resourcesValues[scenarios[i]].eyOnShore[columns[j]]
          const gdsOffshore = resourcesValues[scenarios[i]].gdsOffshore[columns[j]]
          const gdsLanded = resourcesValues[scenarios[i]].gdsLanded[columns[j]]
          const subcontractors = resourcesValues[scenarios[i]].subcontractors[columns[j]]

          newValue = Math.ceil(eyOnShore + gdsOffshore + gdsLanded + subcontractors)
          resourcesValues[scenarios[i]].totalResources[columns[j]] = newValue
        } else if (
          (scenarios[i] === 'fixedFee' ||
            scenarios[i] === 'targetMargin' ||
            scenarios[i] === 'client' ||
            scenarios[i] === 'marketRate') &&
          columns[j] === 'marginPercent'
        ) {
          const totalFees = resourcesValues[scenarios[i]].totalResources.totalFees
          const margin = resourcesValues[scenarios[i]].totalResources.stMargin
          newValue = parseFloat(((100 * margin) / totalFees).toFixed(1))

          if (isNaN(newValue) || !Number.isFinite(newValue)) {
            newValue = 0
          }

          resourcesValues[scenarios[i]].totalResources.marginPercent = newValue
        } else if (
          (scenarios[i] === 'fixedFee' ||
            scenarios[i] === 'targetMargin' ||
            scenarios[i] === 'client' ||
            scenarios[i] === 'marketRate') &&
          columns[j] === 'marginexclcont'
        ) {
          const totalFees = resourcesValues[scenarios[i]].totalResources.totalFees
          const laborCost = resourcesValues[scenarios[i]].totalResources.laborCost
          const techCost = resourcesValues[scenarios[i]].totalResources.TechUpliftCost
          newValue = parseFloat(((100 * (totalFees - (laborCost + techCost))) / totalFees).toFixed(1))

          if (isNaN(newValue) || !Number.isFinite(newValue)) {
            newValue = 0
          }

          resourcesValues[scenarios[i]].totalResources.marginexclcont = newValue
        }

        setResourcesValues((prevResourcesValues: any) => ({
          ...prevResourcesValues,
          [scenarios[i]]: {
            ...prevResourcesValues[scenarios[i]],
            totalResources: {
              ...prevResourcesValues[scenarios[i]].totalResources,
              [columns[j]]: newValue
            }
          }
        }))
      }
    }
  }

  const calculateTotalFullyLoadedGDSCost = () => {
    const columns = [
      'ltCost',
      'recoverable',
      'laborCost',
      'TechUpliftCost',
      'contingency',
      'ltMargin',
      'totalFees',
      'stMargin',
      'marginPercent',
      'marginexclcont'
    ]

    for (let i = 0; i < 4; i++) {
      // Iterate through all scenarios
      for (let j = 0; j < 10; j++) {
        // Iterate through all columns
        let newValue = 0
        if (columns[j] == 'laborCost') {
          const eyOnShore = resourcesValues[scenarios[i]].eyOnShore[columns[j]]
          const gdsOffshore = resourcesValues[scenarios[i]].gdsOffshore[columns[j]]

          newValue = Math.ceil(gdsOffshore / 0.8 + eyOnShore)
          resourcesValues[scenarios[i]].totalFullyLoadedGDSCost[columns[j]] = newValue
        }
        if (
          (scenarios[i] === 'fixedFee' ||
            scenarios[i] === 'targetMargin' ||
            scenarios[i] === 'client' ||
            scenarios[i] === 'marketRate') &&
          columns[j] === 'marginPercent'
        ) {
          const totalFees = resourcesValues[scenarios[i]].totalResources.totalFees
          const laborCost = resourcesValues[scenarios[i]].totalFullyLoadedGDSCost.laborCost
          const contingency = resourcesValues[scenarios[i]].totalResources.contingency
          const techCost = resourcesValues[scenarios[i]].totalResources.TechUpliftCost

          newValue = parseFloat(((100 * (totalFees - (laborCost + techCost) - contingency)) / totalFees).toFixed(1))

          if (isNaN(newValue) || !Number.isFinite(newValue)) {
            newValue = 0
          }

          resourcesValues[scenarios[i]].totalFullyLoadedGDSCost.marginPercent = newValue
        } else if (
          (scenarios[i] === 'fixedFee' ||
            scenarios[i] === 'targetMargin' ||
            scenarios[i] === 'client' ||
            scenarios[i] === 'marketRate') &&
          columns[j] === 'marginexclcont'
        ) {
          const totalFees = resourcesValues[scenarios[i]].totalResources.totalFees
          const laborCost = resourcesValues[scenarios[i]].totalFullyLoadedGDSCost.laborCost
          const techCost = resourcesValues[scenarios[i]].totalResources.TechUpliftCost
          newValue = parseFloat(((100 * (totalFees - (laborCost + techCost))) / totalFees).toFixed(1))

          if (isNaN(newValue) || !Number.isFinite(newValue)) {
            newValue = 0
          }

          resourcesValues[scenarios[i]].totalFullyLoadedGDSCost.marginexclcont = newValue
        }

        setResourcesValues((prevResourcesValues: any) => ({
          ...prevResourcesValues,
          [scenarios[i]]: {
            ...prevResourcesValues[scenarios[i]],
            totalFullyLoadedGDSCost: {
              ...prevResourcesValues[scenarios[i]].totalFullyLoadedGDSCost,
              [columns[j]]: newValue
            }
          }
        }))
      }
    }
  }

  const calculateGDS = () => {
    const columns = [
      'ltCost',
      'recoverable',
      'laborCost',
      'TechUpliftCost',
      'contingency',
      'ltMargin',
      'stMargin',
      'totalFees'
    ]
    for (let i = 0; i < 4; i++) {
      // Iterate through all scenarios
      for (let j = 0; j < 8; j++) {
        // Iterate through all columns
        const gdsOffshore = resourcesValues[scenarios[i]].gdsOffshore[columns[j]]
        const gdsLanded = resourcesValues[scenarios[i]].gdsLanded[columns[j]]

        const newValue = Math.ceil(gdsOffshore + gdsLanded)
        resourcesValues[scenarios[i]].gds[columns[j]] = newValue

        setResourcesValues((prevResourcesValues: any) => ({
          ...prevResourcesValues,
          [scenarios[i]]: {
            ...prevResourcesValues[scenarios[i]],
            totalResources: {
              ...prevResourcesValues[scenarios[i]].gds,
              [columns[j]]: newValue
            }
          }
        }))
      }
    }
  }

  return (
    <>
      <header>
        <div className='title'>Detailed Scenarios: Resources</div>
      </header>
      <div className='detailed-scenario-container'>
        <table>
          <thead>
            <tr>
              <th className='body-text'></th>
              <td className='body-text'>EY Onshore</td>
              <td className='body-text'>GDS</td>
              <td className='body-text'>Subcontractors</td>
              <td className='body-text'>Total Resources</td>
              <td className='body-text'>Total incl fully loaded GDS cost</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='body-text'>Total Fees</td>
              <td className='bold-text'>
                {scenarioName &&
                  numberWithCommas(resourcesValues[scenarioName].eyOnShore.totalFees) + ' ' + planCurrency}
              </td>
              <td className='bold-text'>
                {scenarioName && numberWithCommas(resourcesValues[scenarioName].gds.totalFees) + ' ' + planCurrency}
              </td>
              <td className='bold-text'>
                {scenarioName &&
                  numberWithCommas(resourcesValues[scenarioName].subcontractors.totalFees) + ' ' + planCurrency}
              </td>
              <td className='bold-text'>
                {scenarioName &&
                  numberWithCommas(resourcesValues[scenarioName].totalResources.totalFees) + ' ' + planCurrency}
              </td>
              <td className='bold-text'>
                {scenarioName &&
                  numberWithCommas(resourcesValues[scenarioName].totalResources.totalFees) + ' ' + planCurrency}
              </td>
            </tr>
            <tr>
              <td className='body-text'>Labor Cost</td>
              <td className='bold-text'>
                {scenarioName &&
                  numberWithCommas(resourcesValues[scenarioName].eyOnShore.laborCost) + ' ' + planCurrency}
              </td>
              <td className='bold-text'>
                {scenarioName && numberWithCommas(resourcesValues[scenarioName].gds.laborCost) + ' ' + planCurrency}
              </td>
              <td className='bold-text'>
                {scenarioName &&
                  numberWithCommas(resourcesValues[scenarioName].subcontractors.laborCost) + ' ' + planCurrency}
              </td>
              <td className='bold-text'>
                {scenarioName &&
                  numberWithCommas(resourcesValues[scenarioName].totalResources.laborCost) + ' ' + planCurrency}
              </td>
              <td className='bold-text'>
                {scenarioName &&
                  numberWithCommas(resourcesValues[scenarioName].totalFullyLoadedGDSCost.laborCost) +
                    ' ' +
                    planCurrency}
              </td>
            </tr>
            <tr>
              <td className='body-text'>Tech Uplift Cost</td>
              <td className='bold-text'>
                {scenarioName &&
                  numberWithCommas(resourcesValues[scenarioName].eyOnShore.TechUpliftCost) + ' ' + planCurrency}
              </td>
              <td className='bold-text'>
                {scenarioName &&
                  numberWithCommas(resourcesValues[scenarioName].gds.TechUpliftCost) + ' ' + planCurrency}
              </td>
              <td className='bold-text'>
                {scenarioName &&
                  numberWithCommas(resourcesValues[scenarioName].subcontractors.TechUpliftCost) + ' ' + planCurrency}
              </td>
              <td className='bold-text'>
                {scenarioName &&
                  numberWithCommas(resourcesValues[scenarioName].totalResources.TechUpliftCost) + ' ' + planCurrency}
              </td>
              <td className='bold-text'>
                {scenarioName &&
                  numberWithCommas(resourcesValues[scenarioName].totalResources.TechUpliftCost) + ' ' + planCurrency}
              </td>
            </tr>
            <tr>
              <td className='body-text'>Contingency</td>
              <td className='bold-text'>
                {scenarioName &&
                  numberWithCommas(resourcesValues[scenarioName].eyOnShore.contingency) + ' ' + planCurrency}
              </td>
              <td className='bold-text'>
                {scenarioName && numberWithCommas(resourcesValues[scenarioName].gds.contingency) + ' ' + planCurrency}
              </td>
              <td className='bold-text'>
                {scenarioName &&
                  numberWithCommas(resourcesValues[scenarioName].subcontractors.contingency) + ' ' + planCurrency}
              </td>
              <td className='bold-text'>
                {scenarioName &&
                  numberWithCommas(resourcesValues[scenarioName].totalResources.contingency) + ' ' + planCurrency}
              </td>
              <td className='bold-text'>
                {scenarioName &&
                  numberWithCommas(resourcesValues[scenarioName].totalResources.contingency) + ' ' + planCurrency}
              </td>
            </tr>
            <tr>
              <td className='body-text'>EY Margin Incl. Conti. %</td>
              <td className='bold-text'>
                {scenarioName && resourcesValues[scenarioName].eyOnShore.marginPercent.toFixed(1) + '%'}
              </td>
              <td className='bold-text'>
                {scenarioName && resourcesValues[scenarioName].gds.marginPercent.toFixed(1) + '%'}
              </td>
              <td className='bold-text'>
                {scenarioName && resourcesValues[scenarioName].subcontractors.marginPercent.toFixed(1) + '%'}
              </td>
              <td className='bold-text'>
                {scenarioName && resourcesValues[scenarioName].totalResources.marginPercent.toFixed(1) + '%'}
              </td>
              <td className='bold-text'>
                {scenarioName && resourcesValues[scenarioName].totalFullyLoadedGDSCost.marginPercent.toFixed(1) + '%'}
              </td>
            </tr>
            <tr>
              <td className='body-text'>EY Margin Excl. Conti. %</td>
              <td className='bold-text'>
                {scenarioName && resourcesValues[scenarioName].eyOnShore.marginexclcont.toFixed(1) + '%'}
              </td>
              <td className='bold-text'>
                {scenarioName && resourcesValues[scenarioName].gds.marginexclcont.toFixed(1) + '%'}
              </td>
              <td className='bold-text'>
                {scenarioName && resourcesValues[scenarioName].subcontractors.marginPercent.toFixed(1) + '%'}
              </td>
              <td className='bold-text'>
                {scenarioName && resourcesValues[scenarioName].totalResources.marginexclcont.toFixed(1) + '%'}
              </td>
              <td className='bold-text'>
                {scenarioName && resourcesValues[scenarioName].totalFullyLoadedGDSCost.marginexclcont.toFixed(1) + '%'}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}

export default DetailedScenario
