import DealDetails from './DealDetails'
import Phases from './Phases'
import Workstreams from './Workstreams'

type Props = {}

const GlobalInputs = (props: Props) => {
  return (
    <div className='global-inputs-container'>
      <h2 className='title heading-two'>Global Inputs</h2>
      <p className='title-description body-text'>
        Please complete global inputs below. Required section(s) are indicated below with an asterisk (*)
      </p>
      <p className='disclaimer-text'>
        Please do not enter any unnecessary personal information or any sensitive personal data (including tax file
        numbers or other government identifiers), client confidential information, audit secrets, state secrets,
        commercial secrets, or anything that would violate professional secrecy or confidentiality rules or that would
        be considered abusive/irrelevant.
      </p>
      <DealDetails />
      <Phases />
      <Workstreams />
    </div>
  )
}

export default GlobalInputs
